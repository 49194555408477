import React from "react";

interface Props {
    actions: string[];
    responsibilities: string[];
    timeframes: string[];
    handleActionChange: (index: number, event: React.ChangeEvent<HTMLInputElement>) => void;
    handleResponsibilityChange: (index: number, event: React.ChangeEvent<HTMLInputElement>) => void;
    handleTimeframeChange: (index: number, event: React.ChangeEvent<HTMLInputElement>) => void;
}

const EditResponsibilityTable = ({ actions, handleActionChange, handleResponsibilityChange, handleTimeframeChange, responsibilities, timeframes }: Props) => {
    return (
        <form>
            <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border border-gray-300">
                <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
                    <thead className="ltr:text-left rtl:text-right bg-[#b9b9b95e]">
                        <tr className="divide-x-2">
                            <th className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">Actions required (ref #)</th>
                            <th className="whitespace-nowrap px-4 divide-x-2 py-2 font-semibold text-gray-900">Responsibility</th>
                            <th className="whitespace-nowrap px-4 divide-x-2 py-2 font-semibold text-gray-900">Timeframe</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {actions.map((action, index) => (
                            <tr key={index} className="divide-x-2">
                                <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                                    <ol>
                                        <li>
                                            {index + 1}.
                                            <input
                                                className="w-full border border-gray-300 outline-none rounded p-1.5"
                                                type="text"
                                                value={action}
                                                onChange={(event) => handleActionChange(index, event)}
                                            />
                                        </li>
                                    </ol>
                                </td>
                                <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                                    <input
                                        className="w-full border border-gray-300 outline-none rounded p-1.5"
                                        type="text"
                                        value={responsibilities[index]}
                                        onChange={(event) => handleResponsibilityChange(index, event)}
                                    />
                                </td>
                                <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                                    <input
                                        className="w-full border border-gray-300 outline-none rounded p-1.5"
                                        type="text"
                                        value={timeframes[index]}
                                        onChange={(event) => handleTimeframeChange(index, event)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </form>
    );
};

export default EditResponsibilityTable;
