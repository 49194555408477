import React, { useEffect, useRef, useState } from "react";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import SEO from "../../../constants/SEO";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import Spinner from "../../../Components/Spinner";
import PaginationComponent from "../../../Components/CustomPagination";
import fileImg from "../../../images/icon/EmptyState.png";
import { Button, Modal } from "rsuite";
import { fetchUserTraining, userTrainingData, userTrainingLoading } from "../../../store/slices/userTrainingSlice";
import { StaffProfileTypes } from "../../../types/AuthData";
import UserTrainingCard from "./components/userTrainingCard";


const UserTrainingComponent = () => {

    const [pageNumber, setPageNumber] = useState(0);
    const staffProfileString = localStorage.getItem("staffProfile");
    const staffProfile: StaffProfileTypes = staffProfileString
        ? JSON.parse(staffProfileString)
        : null;
    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    const companyId = user?.companyId;
    useEffect(() => {
        staffProfile.staffId && dispatch(fetchUserTraining(staffProfile.staffId));
    }, [dispatch, companyId]);

    const training = useSelector(userTrainingData);
    const loadingData = useSelector(userTrainingLoading);
    const [docModal, setDocModal] = useState<boolean>(false);
    const [docUrlModal, setDocUrlModal] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const itemsPerPage = 12;
    const pageCount = Math.ceil(training.length / itemsPerPage);
    const displayData = training.slice(
        pageNumber * itemsPerPage,
        (pageNumber + 1) * itemsPerPage
    );
    const topOfPageRef = useRef<HTMLInputElement>(null);

    const handlePageClick = (selectedItem: { selected: number }) => {
        setPageNumber(selectedItem.selected);
        topOfPageRef.current?.scrollIntoView({
            behavior: "smooth",
        });
    };

    const ViewPolicy = (documentUrl: string): void => {
        setDocUrlModal(documentUrl); // Set the document URL in the state
        setIsLoading(true); // Start loading spinner
        setDocModal(true); // Open the modal
    };

    const getFileExtension = (url: string) => {
        return url.split('.').pop()?.toLowerCase(); // Get the file extension
    };


    return (
        <>
            <SEO
                title={`Training`}
                description="Company Training"
                name="Promax-Care"
            />
            <Breadcrumb showNavigation={false} />

            <div
                className="flex justify-between items-center flex-wrap"
                ref={topOfPageRef}
            >
                <h2 className="font-bold mt-5 text-2xl text-black w-auto">Training</h2>

            </div>

            {training.length <= 0 && loadingData && <Spinner color="#071952" />}
            {!loadingData && training.length <= 0 && (
                <div className=" border border-dashed flex justify-center flex-col items-center my-5 rounded py-10 space-y-2">
                    <img src={fileImg} alt="file upload icon" width={200} height={300} />

                    <strong className="text-xl">No Data Available</strong>
                </div>
            )}
            <div className="my-5">
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 ">
                    {displayData.length > 0 &&
                        displayData.map((item) => (
                            <UserTrainingCard
                                key={item.staffTrainingsId}
                                content={item.training}
                                onView={() => {
                                    ViewPolicy(item.traningCertficateUrl); // Call a function and pass the categoryCode
                                }}
                            />
                        ))}
                </div>
                {pageCount > 1 && (
                    <PaginationComponent
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                    />
                )}
                <br />
            </div>

            <Modal
                open={docModal}
                onClose={() => setDocModal(false)}
                backdrop="static"
                autoFocus
                size={"lg"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">View Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        {isLoading && (
                            <div className="text-center">
                                <Spinner /> {/* Show spinner while iframe is loading */}
                                <p>Loading document...</p>
                            </div>
                        )}

                        {getFileExtension(docUrlModal) === 'pdf' ? (
                            <iframe
                                src={docUrlModal}
                                width="100%"
                                height="500px"
                                title="PDF Viewer"
                                style={{ display: isLoading ? "none" : "block" }} // Hide iframe until loaded
                                onLoad={() => setIsLoading(false)} // Stop loading spinner when iframe loads
                            />
                        ) : (
                            <iframe
                                src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(docUrlModal)}`}
                                width="100%"
                                height="500px"
                                title="Office Online Viewer"
                                style={{ display: isLoading ? "none" : "block" }} // Hide iframe until loaded
                                onLoad={() => setIsLoading(false)} // Stop loading spinner when iframe loads
                            />
                        )}
                    </>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setDocModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default UserTrainingComponent;
