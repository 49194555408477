import React, {
    ChangeEvent,
    FormEvent,
    useEffect,
    useState,
} from "react";
import DataTable, {
    ExpanderComponentProps,
    TableColumn,
} from "react-data-table-component";
import { BsSearch } from "react-icons/bs";
import ExportDropdown from "../../../../Components/menu/ExportDropdown";
import IconButton from "../../../../Components/Buttons/IconButton";
import {
    downloadCSV,
    exportToExcel,
    handlePDFDownload,
} from "../../../../services/reusableFunc";
import { Column } from "../../../../types/DataType";
import { FaFileCsv, FaFileExcel, FaFilePdf } from "react-icons/fa";
import {
    AppDispatch,
    RootState,
    useAppDispatch,
} from "../../../../store/store";
import { useSelector } from "react-redux";
// import dayjs from "dayjs";
import { Button, Modal } from "rsuite";
import CustomInput from "../../../../Components/Input/CustomInput";
import Spinner from "../../../../Components/Spinner";
import { toast } from "react-toastify";
import axiosInstance from "../../../../store/axiosInstance";
import { isAxiosError } from "axios";
import { ZodType, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { clientRepsData, clientRepsLoading, fetchClientRep, ClientRepType } from "../../../../store/slices/ClientSlice/clientRepSlice";


interface ClientRepProps {
    uid: string; // Define the type of uid as string
}

type DataType = {
    relationship: string;
    fullName: string;
    homePhone: string;
    mobilePhone: string;
    email: string;
    position: string;
    address: string;
    organization: string;
};

const ClientRep: React.FC<ClientRepProps> = ({ uid }) => {

    const columns: TableColumn<ClientRepType>[] = [
        {
            name: "Full Name",
            selector: (row) => row.fullName,
            sortable: true,
        },
        {
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: "Address",
            selector: (row) => row.address,
            sortable: true,
        },
        {
            name: "Relationship",
            selector: (row) => row.relationship,
            sortable: true,
        },
        {
            name: "Home Phone",
            selector: (row) => row.homePhone,
            sortable: true,
        },
        {
            name: "Mobile Phone",
            selector: (row) => row.mobilePhone,
            sortable: true,
        },

        {
            name: "Position",
            selector: (row) => row.position,
            sortable: true,
        },

        {
            name: "Organization",
            selector: (row) => row.organization,
            sortable: true,
        },
        // {
        //     name: "Date Created",
        //     selector: (row) => dayjs(row.dateCreated).format("DD/MM/YYYY HH:mm:ss"),
        //     sortable: true,
        // },
    ];


    const [addModal, setAddModal] = useState<boolean>(false);
    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    const companyId = user?.companyId;
    useEffect(() => {
        if (uid) {
            dispatch(fetchClientRep(Number(uid)));
        }
    }, [dispatch, uid, companyId]);
    const representative = useSelector(clientRepsData);
    const loadingData = useSelector(clientRepsLoading);

    const [searchText, setSearchText] = useState<string>("");

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const filteredData = representative?.filter((item) =>
        item.fullName.toLowerCase().includes(searchText.toLowerCase())
    );

    interface Props extends ExpanderComponentProps<ClientRepType> {
        // currently, props that extend ExpanderComponentProps must be set to optional.
        someTitleProp?: string;
    }

    const [saveId, setSaveId] = useState<number>(0);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [repDetails, setRepDetails] = useState<ClientRepType>({
        representativeId: 0,
        profileId: 0,
        relationship: "",
        fullName: "",
        homePhone: "",
        mobilePhone: "",
        email: "",
        position: "",
        address: "",
        organization: ""
    });


    const schema: ZodType<DataType> = z.object({
        fullName: z.string().min(1, "Full Name is required"),
        address: z.string().min(1, "Address is required"),
        email: z.string().min(1, "Email is required"),
        homePhone: z.string(),
        mobilePhone: z.string().min(1, "Phone Number is required"),
        position: z.string(),
        organization: z.string(),
        relationship: z.string(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<DataType>({
        resolver: zodResolver(schema),
    });

    const handleSubmitForm = async (data: DataType) => {
        const info = {
            profileId: uid,
            relationship: data.relationship,
            fullName: data.fullName,
            homePhone: data.homePhone || "0",
            mobilePhone: data.mobilePhone,
            email: data.email,
            position: data.position,
            address: data.address,
            organization: data.organization

        };

        try {
            setLoading(true);
            const { data } = await axiosInstance.post(
                `/Representatives/add_representative`,
                info
            );

            if (data.status === "Success") {
                toast.success(data.message);
                setAddModal(false);
                uid && dispatch(fetchClientRep(Number(uid)));
            } else {
                toast.error(data.message);
            }
            setLoading(false);

        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // console.log(error);;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        const { name, value } = e.target;
        setRepDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const EditDetail = async (id: number) => {
        setSaveId(id);
        setShowModal(true);
        try {
            const { data } = await axiosInstance.get(
                `/Representatives/${id}`
            );
            setRepDetails({ ...data });

        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // console.log(error);;
            } else {
                toast.error("An error occurred");
            }
        }
    };



    const handleEdit = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        const info = {
            representativeId: repDetails.representativeId,
            profileId: uid,
            relationship: repDetails.relationship,
            fullName: repDetails.fullName,
            homePhone: repDetails.homePhone,
            mobilePhone: repDetails.mobilePhone,
            email: repDetails.email,
            position: repDetails.position,
            address: repDetails.address,
            organization: repDetails.organization

        }
        try {
            const { data } = await axiosInstance.post(
                `/Representatives/edit/${saveId}`,
                info
            );
            toast.success(data.message);
            uid && dispatch(fetchClientRep(Number(uid)));
            setShowModal(false);
            setLoading(false);
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // console.log(error);;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);
        }
    };

    const deleteRep = (id: number) => {
        setSaveId(id);
        setDeleteModal(true);
    };

    const handleDeleteRep = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            const { data } = await axiosInstance.post(
                `/Representatives/delete/${saveId}`
            );
            toast.success(data.message);
            setDeleteModal(false);
            uid && dispatch(fetchClientRep(Number(uid)));
            setLoading(false);
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // console.log(error);;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);
            setDeleteModal(false);
        } finally {
            setLoading(false);
        }
    };

    const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
        return (
            <div className="p-2 flex flex-col gap-2 text-xs">

                <span>
                    <span className="font-bold">Full Name: </span>
                    <span> {data.fullName}</span>
                </span>
                <span>
                    <span className="font-bold">Email: </span>
                    <span> {data.email}</span>
                </span>
                <span>
                    <span className="font-bold">Address: </span>
                    <span> {data.address}</span>
                </span>
                <span>
                    <span className="font-bold">Organization: </span>
                    <span> {data.organization}</span>
                </span>

                <div className="flex gap-2">
                    <span className="font-bold">Actions: </span>
                    <button
                        className="btn text-primary font-bold"
                        style={{ fontSize: "12px" }}
                        onClick={() => EditDetail(data.representativeId)}
                    >
                        Edit
                    </button>
                    |
                    <button
                        className="btn text-red-500 font-bold"
                        style={{ fontSize: "12px" }}
                        onClick={() => deleteRep(data.representativeId)}
                    >
                        Delete
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div className="mt-10">
            {representative.length <= 0 && loadingData && <Spinner color="#071952" />}
            <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
                <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
                    <h2 className="font-bold text-xl text-black w-auto">Representative</h2>

                    <div className="relative w-full lg:col-span-2">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <BsSearch />
                        </div>
                        <input
                            type="text"
                            id="simple-search"
                            onChange={handleSearch}
                            className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            placeholder="Search"
                        />
                    </div>
                </div>
                <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                    <div className="flex items-center space-x-3 w-full md:w-auto">
                        {/* <button id="actionsDropdownButton" className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                              <FaFilter />
                              Filter
                          </button> */}
                        <ExportDropdown>
                            <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                <IconButton
                                    icon={<FaFilePdf className="text-red-500" />}
                                    title="Export as PDF"
                                    onClick={() =>
                                        handlePDFDownload(
                                            representative,
                                            columns as unknown as Column<ClientRepType>[],
                                            "ClientSchedule.pdf"
                                        )
                                    }
                                />
                                <IconButton
                                    icon={<FaFileExcel className="text-green-600" />}
                                    title="Export as Excel Sheet"
                                    onClick={() =>
                                        exportToExcel(
                                            representative,
                                            columns as unknown as Column<ClientRepType>[],
                                            "ClientSchedule"
                                        )
                                    }
                                />
                                <IconButton
                                    icon={<FaFileCsv className="text-green-500" />}
                                    title="Export as CSV"
                                    onClick={() =>
                                        downloadCSV({
                                            data: representative,
                                            filename: "ClientSchedule.csv",
                                        })
                                    }
                                />
                            </div>
                        </ExportDropdown>
                    </div>
                    <button
                        type="button"
                        onClick={() => setAddModal(true)}
                        className="px-4 py-2 bg-primary text-white  disabled:bg-slate-400
      transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm 
     "
                    >
                        Add New Representative
                    </button>
                </div>
            </div>

            <DataTable
                columns={columns}
                data={filteredData}
                expandableRows
                expandableRowsComponent={ExpandableRowComponent}
                pagination
                paginationTotalRows={representative?.length}
            // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}
            />

            <Modal
                open={addModal}
                onClose={() => setAddModal(false)}
                backdrop="static"
                autoFocus
                size={"lg"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Add Representative</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form onSubmit={handleSubmit(handleSubmitForm)}>
                            <div className="space-y-1 p-3 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
                                <CustomInput
                                    label="Full Name"
                                    type="text"
                                    {...register("fullName")}
                                    error={errors?.fullName?.message}
                                />

                                <CustomInput
                                    label="Email"
                                    type="email"
                                    {...register("email")}
                                    error={errors?.email?.message}
                                />

                                <CustomInput
                                    label="Address"
                                    type="text"
                                    {...register("address")}
                                    error={errors?.address?.message}
                                />

                                <CustomInput
                                    label="Home Phone"
                                    type="number"
                                    {...register("homePhone")}
                                    error={errors?.homePhone?.message}
                                />

                                <CustomInput
                                    label="Mobile Phone"
                                    type="number"
                                    {...register("mobilePhone")}
                                    error={errors?.mobilePhone?.message}
                                />

                                <CustomInput
                                    label="Relationship"
                                    type="text"
                                    {...register("relationship")}
                                    error={errors?.relationship?.message}
                                />

                                <CustomInput
                                    label="Position"
                                    type="text"
                                    {...register("position")}
                                    error={errors?.position?.message}
                                />

                                <CustomInput
                                    label="Organization"
                                    type="text"
                                    {...register("organization")}
                                    error={errors?.organization?.message}
                                />


                            </div>

                            <div className="mx-auto text-center mt-3">
                                <button
                                    type="submit"
                                    disabled={loading}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                      font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                      dark:focus:ring-primary"
                                >
                                    {loading ? <Spinner /> : "Add"}
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>

            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                backdrop="static"
                autoFocus
                size={"lg"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Edit Representative</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form action="" onSubmit={handleEdit}>
                            <div className="space-y-1 p-3 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">


                                <CustomInput
                                    label="Full Name"
                                    type="text"
                                    name='fullName'
                                    value={repDetails.fullName || ""}
                                    onChange={handleInputChange}
                                />

                                <CustomInput
                                    label="Email"
                                    type="email"
                                    name='email'
                                    value={repDetails.email || ""}
                                    onChange={handleInputChange}
                                />

                                <CustomInput
                                    label="Address"
                                    type="text"
                                    name='address'
                                    value={repDetails.address || ""}
                                    onChange={handleInputChange}
                                />

                                <CustomInput
                                    label="Home Phone"
                                    type="number"
                                    name='homePhone'
                                    value={repDetails.homePhone || ""}
                                    onChange={handleInputChange}
                                />

                                <CustomInput
                                    label="Mobile Phone"
                                    type="number"
                                    name='mobilePhone'
                                    value={repDetails.mobilePhone || ""}
                                    onChange={handleInputChange}
                                />

                                <CustomInput
                                    label="Relationship"
                                    type="text"
                                    name='relationship'
                                    value={repDetails.relationship || ""}
                                    onChange={handleInputChange}
                                />

                                <CustomInput
                                    label="Position"
                                    type="text"
                                    name='position'
                                    value={repDetails.position || ""}
                                    onChange={handleInputChange}
                                />

                                <CustomInput
                                    label="Organization"
                                    type="text"
                                    name='organization'
                                    value={repDetails.organization || ""}
                                    onChange={handleInputChange}
                                />


                            </div>

                            <div className="mx-auto text-center mt-3">
                                <button
                                    type="submit"
                                    disabled={loading}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                      font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                      dark:focus:ring-primary"
                                >
                                    {loading ? <Spinner /> : "Update"}
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>

            <Modal
                open={deleteModal}
                onClose={() => setDeleteModal(false)}
                role="alertdialog"
                backdrop="static"
                autoFocus
                size={"xs"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Delete Representative</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <p className="mb-4 font-bold">
                            Are you sure you want to delete this Representative?
                        </p>
                        <div className="flex justify-center items-center space-x-4">
                            <Button
                                onClick={() => setDeleteModal(false)}
                                className="bg-gray-500 text-white"
                            >
                                No, cancel
                            </Button>
                            <Button
                                onClick={handleDeleteRep}
                                className="bg-red-500 text-white hover:bg-red-700 hover:text-white"
                            >
                                {loading ? <Spinner /> : "Yes, I'm sure"}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>


        </div>
    );
};


export default ClientRep;
