import React, { ReactNode } from 'react';
import Dropdown from '../dropdown';


function CreateDropdown(props: { transparent?: boolean; children: ReactNode; text: string }) {
    const { transparent, children, text } = props;
    const [open, setOpen] = React.useState(false);
    return (
        <Dropdown
            button={

                <button type="button"
                    onClick={() => setOpen(!open)}
                    className="px-4 py-2 bg-primary text-white  disabled:bg-slate-400
                 transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm 
                ">
                    Add {text}
                </button>
            }
            animation={'origin-top-right transition-all duration-300 ease-in-out'}
            classNames={`${transparent ? 'top-8' : 'top-11'} right-0 w-max`}
        >
            {children}
        </Dropdown>
    );
}

export default CreateDropdown;
