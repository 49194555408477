import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { Inbox } from "../../../../../store/slices/inboxSlice";
import parse from "html-react-parser";
import man from "../../../../../images/user/man.png";
import Spinner from "../../../../../Components/Spinner";
import { ConfirmModal } from "../../../RosteringManagement/ShiftRoster/components/ConfirmModal";
import axiosInstance from "../../../../../store/axiosInstance";
import { toast } from "react-toastify";
import fileImg from "../../../../../images/icon/EmptyState.png";
import { isAxiosError } from "axios";
import { RootState } from "../../../../../store/store";
import { useSelector } from "react-redux";

interface Props {
  loadingDetail: boolean;
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedMessage: Inbox | null;
  setLoadingDetail: React.Dispatch<React.SetStateAction<boolean>>;
  activeTab: string;
  fetchData: () => void;
}

const Detaills = ({
  sidebarOpen,
  setSidebarOpen,
  selectedMessage,
  loadingDetail,
  setLoadingDetail,
  activeTab,
  fetchData,
}: Props) => {
  const user = useSelector((state: RootState) => state?.auth?.user);
  const [deleteModal, setDeleteModal] = useState(false);
  const handleDelete = async () => {
    setLoadingDetail(true);
    try {
      const { data } = await axiosInstance.post(
        `/Messages/delete/${selectedMessage?.messageId}`
      );
      if (data.status === "Success") {
        toast.success(data.message);
        fetchData();
        setLoadingDetail(false);
        setDeleteModal(false);
      } else {
        toast.error(data.message);
        setLoadingDetail(false);
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("Error deleting shift");
      }

      setLoadingDetail(false);
    }
  };

  const [loading, setLoading] = useState(false);

  const [file, setFile] = React.useState<File | null>(null);
  const [replyValue, setReplyValue] = useState("");
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Handler for file input changes
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const SendReply = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("MessageId", selectedMessage?.messageId.toString() || "");
    formData.append("Reply", replyValue);
    file && formData.append("FormFile", file);
    try {
      const { data } = await axiosInstance.post(
        `/Messages/reply_message?userId=${user?.uid}`,
        formData
      );
      // console.log(data);
      toast.success(data.message);
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      } else {
        toast.error("Error Marking Attendance");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section
        className={`sidebar absolute left-0 top-0 !z-10 duration-300  
          p-2  rounded-r-lg lg:col-span-5   overflow-y-auto shadow-md w-full
          flex h-screen flex-col overflow-hidden  bg-white rounded-l-lg ease-linear dark:bg-slate-900 lg:static
          scroll-smooth scrollbar-hide
          lg:translate-x-0 ${sidebarOpen ? "translate-x-0" : "-translate-x-full"
          } `}
      >
        {selectedMessage ? (
          <>
            <div className="lg:hidden block">
              <button
                className="p-1 rounded bg-white border inline-flex gap-2 items-center  border-gray-200 transition duration-300 transform active:scale-95 ease-in-out"
                onClick={() => setSidebarOpen(false)}
              >
                <FaTimes className="text-red-600  text-xl" />
                Close
              </button>
            </div>
            <div className="flex justify-between items-center h-48 border-b-2 py-4 mb-8 relative">
              {loadingDetail && (
                <div className="p-4 top-0 right-0 z-10 absolute bg-white rounded-md flex justify-center items-center">
                  <Spinner color="#000" />
                </div>
              )}
              <div className="flex space-x-4 items-center">
                <div className="h-12 w-12 rounded-full overflow-hidden">
                  <img
                    src={man}
                    loading="lazy"
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="flex flex-col truncate overflow-clip">
                  <h3 className="font-semibold text-lg capitalize">
                    {selectedMessage.userCreated || "User"}
                  </h3>
                  {activeTab === "inbox" ? (
                    <p className="text-light text-gray-400 w-48 md:w-full truncate">
                      {selectedMessage.emailFrom}
                    </p>
                  ) : (
                    <p className="text-light text-gray-400 w-48 md:w-full truncate">
                      {selectedMessage.emailTo}
                    </p>
                  )}
                </div>
              </div>
              <div>
                <ul
                  className="flex text-red-500 space-x-4 cursor-pointer"
                  onClick={() => setDeleteModal(true)}
                >
                  <li className="w-6 h-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </li>
                </ul>
              </div>
            </div>
            <section>
              <h1 className="font-bold text-sm md:text-base xl:text-lg truncate">
                {selectedMessage.subject}
              </h1>
              <article className="mt-8 text-gray-500 bg-slate-50 p-2 overflow-y-auto h-[50vh]">
                {parse(selectedMessage.content)}
              </article>
            </section>
            {activeTab === "inbox" && (
              <form
                className="mt-6 border rounded-xl bg-gray-50 mb-3"
                onSubmit={SendReply}
              >
                <textarea
                  className="w-full bg-gray-50 p-2 rounded"
                  placeholder="Type your reply here..."
                  rows={4}
                  required
                  minLength={4}
                  value={replyValue}
                  onChange={(e) => setReplyValue(e.target.value)}
                />

                <div className="flex items-center justify-between p-2">
                  <input
                    type="file"
                    onChange={onFileChange}
                    style={{ display: "none" }}
                    id="fileInput"
                    accept=".jpg, .png, .jpeg"
                  />
                  <label
                    className="h-6 w-6 text-gray-400 cursor-pointer"
                    htmlFor="fileInput"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                      />
                    </svg>
                  </label>
                  {file && (
                    <div className="bg-white flex justify-center items-center p-2 rounded relative">
                      <button
                        onClick={() => setFile(null)}
                        className="absolute w-5 h-5  rounded-full bg-red-500 text-white -top-3 -right-2"
                      >
                        &times;
                      </button>
                      <p>Image uploaded: {file.name}</p>
                    </div>
                  )}

                  <button className="bg-primary text-white px-8 py-3 rounded">
                    {loading ? <Spinner /> : "Reply"}
                  </button>
                </div>
              </form>
            )}
            <ConfirmModal
              open={deleteModal}
              onClose={() => setDeleteModal(false)}
              onConfirm={() => handleDelete()}
              title="Delete Message"
              content={
                <p className="mb-4 font-bold text-center">
                  Are you sure about deleting this mesage
                </p>
              }
              isLoading={loadingDetail}
            />
          </>
        ) : (
          <div className=" flex justify-center flex-col items-center my-20 rounded py-10  space-y-2">
            <img
              src={fileImg}
              alt="file upload icon"
              width={200}
              height={300}
            />

            <strong className="text-xl break-words text-center">
              Click on a message, the details will be displayed here
            </strong>
          </div>
        )}
      </section>
    </>
  );
};

export default Detaills;
