import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

export interface UserTraining {
    companyId: number;
    dateCreated: string;
    dateModified: string;
    endDate: string;
    others: string;
    report: string;
    staff: {
        fullName: string;
    },
    staffId: number;
    staffTrainingsId: number;
    startDate: string;
    training: string;
    traningCertficateUrl: string;
    traningCertficateUrlFile: string;
    userCreated: string;
    userModified: string;
}

export interface UserTrainingState {
    loading: boolean;
    data: Array<UserTraining>;
    error: string | undefined;
}
const initialState: UserTrainingState = {
    loading: false,
    data: [],
    error: undefined,
};
export const fetchUserTraining = createAsyncThunk(
    "UserTraining/fetchUserTraining",
    async (stafId: string | number) => {
        try {
            const response = await api.fetchUserTraining(stafId);
            return response;
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                console.log(error);
            } else {
                console.log(error);
            }
        }
    }
);

const userTrainingSlice = createSlice({
    name: "userTraining",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchUserTraining.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            fetchUserTraining.fulfilled,
            (state, action: PayloadAction<Array<UserTraining>>) => {
                state.loading = false;
                state.data = action.payload;
            }
        );
        builder.addCase(fetchUserTraining.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
    reducers: {},
});
export const userSelector = (state: RootState) => state.userTraining;
export default userTrainingSlice.reducer;

export const userTrainingData = (state: RootState) =>
    state.userTraining.data;
export const userTrainingLoading = (state: RootState) =>
    state.userTraining.loading;
export const userTrainingError = (state: RootState) =>
    state.userTraining.error;
