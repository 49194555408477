// import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { RootState } from "../store";
// import api from "../api";
// import { isAxiosError } from "axios";

// interface Policy {
//     category: string;
//     categoryCode: string;
//     company: string;
//     companyId: number;
//     dateCreated: string;
//     dateModified: string;
//     isClient: boolean;
//     isGeneral: boolean;
//     isStaff: boolean;
//     policyDocumentId: number;
//     policyName: string;
//     policyUrl: string;
//     policyUrlFile: string;
//     userCreated: string;
//     userModified: string;
// }

// export interface PolicyState {
//     loading: boolean;
//     data: Array<Policy>;
//     error: string | undefined;
// }
// const initialState: PolicyState = {
//     loading: false,
//     data: [],
//     error: undefined,
// };
// export const fetchCompanyPolicy = createAsyncThunk(
//     "Policy/fetchCompanyPolicy",
//     async (companyId: number) => {
//         try {
//             const response = await api.fetchCompanyPolicy(companyId);
//             return response;
//         } catch (error: unknown) {
//             if (isAxiosError(error)) {
//                 console.log(error);
//             } else {
//                 console.log(error);
//             }
//         }
//     }
// );

// const policySlice = createSlice({
//     name: "policy",
//     initialState,
//     extraReducers: (builder) => {
//         builder.addCase(fetchCompanyPolicy.pending, (state) => {
//             state.loading = true;
//         });
//         builder.addCase(
//             fetchCompanyPolicy.fulfilled,
//             (state, action: PayloadAction<Array<Policy>>) => {
//                 state.loading = false;
//                 state.data = action.payload;
//             }
//         );
//         builder.addCase(fetchCompanyPolicy.rejected, (state, action) => {
//             state.loading = false;
//             state.data = [];
//             state.error = action.error.message;
//         });
//     },
//     reducers: {},
// });
// export const userSelector = (state: RootState) => state.policy;
// export default policySlice.reducer;

// export const policyData = (state: RootState) => state.policy.data;
// export const policyLoading = (state: RootState) => state.policy.loading;
// export const policyError = (state: RootState) => state.policy.error;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

export interface Policy {
    category: string;
    categoryCode: string;
    company: string;
    companyId: number;
    dateCreated: string;
    dateModified: string;
    isClient: boolean;
    isGeneral: boolean;
    isStaff: boolean;
    policyDocumentId: number;
    policyName: string;
    policyUrl: string;
    policyUrlFile: string;
    userCreated: string;
    userModified: string;
}

export interface PolicyState {
    loading: boolean;
    data: Policy[][]; // Array of arrays of Policy
    error: string | undefined;
}

const initialState: PolicyState = {
    loading: false,
    data: [], // Initially an empty array of arrays
    error: undefined,
};

export const fetchCompanyPolicy = createAsyncThunk(
    "Policy/fetchCompanyPolicy",
    async (companyId: number) => {
        try {
            const response = await api.fetchCompanyPolicy(companyId);
            return response.policies; // Assuming this returns an array of arrays
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                throw new Error(error.response?.data?.message || error.message);
            }
            throw error;
        }
    }
);

const policySlice = createSlice({
    name: "policy",
    initialState,
    reducers: {}, // Add reducers here if needed
    extraReducers: (builder) => {
        builder.addCase(fetchCompanyPolicy.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            fetchCompanyPolicy.fulfilled,
            (state, action: PayloadAction<Policy[][]>) => {
                state.loading = false;
                state.data = action.payload; // Assuming the payload is an array of arrays
            }
        );
        builder.addCase(fetchCompanyPolicy.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
});

export const userSelector = (state: RootState) => state.policy;
export default policySlice.reducer;

export const policyData = (state: RootState) => state.policy.data;
export const policyLoading = (state: RootState) => state.policy.loading;
export const policyError = (state: RootState) => state.policy.error;

