import React from "react";
import { Representative } from "../../../types/DataType";
interface Prop {
  clientRep: Representative | null
}

const ParticipantDetailForm = ({ clientRep }: Prop) => {
  return (
    <div className="overflow-x-auto rounded-lg max-[1024px]:w-[100%] border mt-9 border-gray-300">
      <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">


        <tbody className="divide-y divide-gray-200">
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Representative name:
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none" >{clientRep?.fullName}</td>
          </tr>

          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Relationship to the participant::
            </td>
            <td className=" px-4 py-2 text-gray-700 w-96 outline-none">
              <p className="flex gap-2">Plan Nominee: <input type="number" className="w-[100%] outline-none border-gray-300 border p-1" /></p>
              <p className="flex gap-2">Legally appointed Guardian: <input type="text" className="w-[100%] outline-none border-gray-300 border p-1" /></p>
            </td>
          </tr>

          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Phone:
            </td>
            <td className=" px-4 py-2 text-gray-700 w-96 outline-none">
              <p className="flex gap-2">Mobile: <input type="text " value={clientRep?.mobilePhone} readOnly className="w-[100%] outline-none border-gray-300 border p-1" /></p>
              <p className="flex gap-2">Other: <input type="text" value={clientRep?.homePhone} readOnly className="w-[100%] outline-none border-gray-300 border p-1" /></p>
            </td>
          </tr>

          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Email:
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none" > {clientRep?.email}</td>
          </tr>
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Address:
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">{clientRep?.address}</td>
          </tr>
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Alternative Contact:
            </td>
            <td className=" px-4 py-2 text-gray-700 w-96 outline-none">
              <p className="flex gap-2">Please provide details: <input type="number" className="w-[100%] outline-none border-gray-300 border p-1" /></p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ParticipantDetailForm;
