import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { fetchCompany } from "../../services/fetchServices";
import { RootState } from "../../store/store";
import { CompanyDetail } from "../../types/AuthData";

const PackageChecker = () => {
  const [isPackageExpired, setIsPackageExpired] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state?.auth?.user);

  useEffect(() => {
    const checkPackageStatus = async () => {
      if (user?.companyId) {
        const company: CompanyDetail = await fetchCompany(user.companyId);
        if (company?.expirationDate) {
          const companyExpirationDate = new Date(company.expirationDate);
          // const companyExpirationDate = new Date('2023-01-01');
          // Get current date in Australia (assuming UTC+10 for Australian Eastern Standard Time)
          const australiaTimeZoneOffset = 10;
          const currentDateInAustralia = new Date();
          currentDateInAustralia.setHours(
            currentDateInAustralia.getHours() + australiaTimeZoneOffset
          );

          // Compare dates
          setIsPackageExpired(currentDateInAustralia > companyExpirationDate);
        }
      }
    };

    checkPackageStatus();
  }, [user?.companyId]);

  return isPackageExpired;
};

export default PackageChecker;
