import React, { ChangeEvent } from 'react'
import { FaPlus } from 'react-icons/fa';


interface Props {
    supportRequired: string[];
    handleSupportInputChange: (index: number, value: string) => void;
    handleSupportAddRow: () => void;
}

const Support: React.FC<Props> = ({ supportRequired, handleSupportAddRow,
    handleSupportInputChange }) => {
    return (
        <div>
            <table className="w-full mb-6">
                <tbody>
                    <tr>
                        <td className="border p-2">Support requirements:</td>
                        {supportRequired.map((value, index) => (
                            <td key={index} className="whitespace-nowrap border flex gap-3 px-4 py-2 text-gray-700 w-[100%] outline-none">
                                <span>{index + 1}.</span>
                                <input
                                    className="w-[100%] h-[20px] outline-none"
                                    type="text"
                                    value={value}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleSupportInputChange(index, e.target.value)}
                                />
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <td className=""></td>
                        <td className="flex justify-end">
                            <button
                                type="button"
                                onClick={handleSupportAddRow}
                                className="mt-4 w-10 h-10  bg-gray-200 text-white rounded-full flex justify-center items-center"
                            >
                                <FaPlus className="text-lg text-black" />
                            </button>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    )
}

export default Support