import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

export interface ClientShift {
  activities: string;
  appointment: string;
  attendId: number;
  attendance: boolean;
  cancellationDate: string;
  clients: string;
  companyID: number;
  dateCreated: string;
  dateFrom: string;
  dateModified: string;
  dateTo: string;
  isCurrent: boolean;
  isEnded: boolean;
  isExceptionalShift: boolean;
  isNightShift: boolean;
  profileId: number;
  progressNoteId: number;
  reason: string;
  repeat: boolean;
  requestCancellation: boolean;
  staffId: number;
  shiftRosterId: number;
  status: string;
  userCreated: string;
  userModified: string;
  staff: {
    staffId: number;
    maxStaffId: string | null;
    firstName: string;
    surName: string;
    middleName: string | null;
    aboutMe: string | null;
    address: string;
    postcode: string | null;
    email: string;
    phoneNumber: string;
    profession: string | null;
    gender: string;
    imageUrl: string | null;
    imageFile: string | null;
    country: string;
    state: string | null;
    city: string | null;
    isCompleted: boolean;
    isActive: boolean;
    dateOfBirth: string;
    facebook: string | null;
    twitter: string | null;
    instagram: string | null;
    youtube: string | null;
    linkedIn: string | null;
    github: string | null;
    employmentType: string | null;
    taxFile: string | null;
    position: string | null;
    level: string | null;
    australianCitizen: boolean | null;
    dateJoined: string | null;
    salary: number;
    payDay: number;
    payRate: string | null;
    bankName: string | null;
    bsb: string | null;
    branch: string | null;
    accountName: string | null;
    accountNumber: string | null;
    nextOfKin: string | null;
    relationship: string | null;
    kinPostcode: string | null;
    kinAddress: string | null;
    kinCountry: string | null;
    kinCity: string | null;
    kinEmail: string | null;
    suburb: string | null;
    kinState: string | null;
    kinPhoneNumber: string | null;
    isDocumentUploaded: boolean;
    isAdmin: boolean;
    adm_DesignationsId: number | null;
    adm_Designations: string | null;
    offerLetter: string | null;
    handbook: string | null;
    superForm: string | null;
    supportPosition: string | null;
    companyId: number;
    company: string | null;
    fullName: string;
    dateCreated: string;
    userCreated: string | null;
    dateModified: string;
    userModified: string | null;
  };
  profile: {
    profileId: number;
    firstName: string;
    surName: string;
    middleName: string | null;
    clientId: string | null;
    address: string;
    email: string;
    phoneNumber: string;
    gender: string;
    imageUrl: string | null;
    imageFile: string | null;
    country: string;
    state: string | null;
    city: string | null;
    isCompleted: boolean;
    isActive: boolean;
    dateOfBirth: string;
    homePhone: string | null;
    workPhone: string | null;
    profession: string | null;
    culturalBackground: string;
    preferredLanguage: string;
    requireInterpreter: string;
    indigenousSatatus: string;
    ndisPlan: string;
    ndisPlanNote: string;
    privacyPreferences: string;
    financialArrangement: string;
    ndisNo: string | null;
    agreementStartDate: string | null;
    agreementEndDate: string | null;
    nextOfKin: string;
    relationship: string | null;
    kinPostcode: string;
    kinAddress: string | null;
    kinCountry: string;
    kinCity: string | null;
    kinEmail: string;
    suburb: string | null;
    kinState: string | null;
    kinPhoneNumber: string | null;
    companyId: number;
    company: string | null;
    fullName: string;
    dateCreated: string;
    userCreated: string | null;
    dateModified: string;
    userModified: string | null;
  };
}

export interface ClientShiftState {
  loading: boolean;
  data: Array<ClientShift>;
  error: string | undefined;
}
const initialState: ClientShiftState = {
  loading: false,
  data: [],
  error: undefined,
};
export const fetchClientShift = createAsyncThunk(
  "ClientShift/fetchClientShift",
  async (uid: number) => {
    try {
      const response = await api.fetchClientShift(uid);
      return response.shiftRoster;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

const clientShiftSlice = createSlice({
  name: "clientShift",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchClientShift.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchClientShift.fulfilled,
      (state, action: PayloadAction<Array<ClientShift>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(fetchClientShift.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {},
});
export const userSelector = (state: RootState) => state.clientShift;
export default clientShiftSlice.reducer;

// Define selectors to access the profile slice state
export const clientShiftData = (state: RootState) => state.clientShift.data;
export const clientShiftLoading = (state: RootState) =>
  state.clientShift.loading;
export const clientShiftError = (state: RootState) => state.clientShift.error;
