import React, { FormEvent, useRef } from "react";
import PrintComponent from "../PrintTab";
import { CompanyDetail } from "../../types/AuthData";
import { Client } from "../../store/slices/clientSlice";
import { Representative, SupportPlaData } from "../../types/DataType";
import Spinner from "../../Components/Spinner";
import EditSupportFormComp from "./__component/EditSupportForm";
import EditDate from "./__component/EditDate";

interface Props {
  companyOne: CompanyDetail | null | undefined;
  clientDetail: Client | null;
  careManager: Representative | null;
  editAttendee: string[];
  supportForm: SupportPlaData;
  handleInputDataEditChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  handleInputEditChange: (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleAddRowEdit: () => void;
  handleRadioDataChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleEditSubmit: (e: FormEvent) => Promise<void>;
  loading: boolean;
  handleInputEditChangeSupport: (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleAddRowEditSupport: () => void;
  editSupport: string[];
}

const EditSupportPlanForm = ({
  companyOne,
  careManager,
  clientDetail,
  editAttendee,
  handleAddRowEdit,
  handleInputDataEditChange,
  handleEditSubmit,
  loading,
  handleInputEditChange,
  handleRadioDataChange,
  supportForm,
  editSupport,
  handleAddRowEditSupport,
  handleInputEditChangeSupport,
}: Props) => {
  const componentRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <form onSubmit={handleEditSubmit}>
        <PrintComponent
          componentRef={componentRef}
          triggerLabel="Print / Download"
          documentTitle="Support Plan Form"
        />
        <div
          ref={componentRef}
          className="mx-auto  max-w-3xl p-4 md:p-4 lg:p-8 bg-white"
        >
          <div className="flex justify-end">
            <img
              className="w-24  mb-4 lg:mb-4"
              src={companyOne?.companyLogo}
              alt="Company Logo"
            />
          </div>

          <EditSupportFormComp
            supportForm={supportForm}
            handleInputDataEditChange={handleInputDataEditChange}
            editAttendee={editAttendee}
            handleAddRowEdit={handleAddRowEdit}
            handleInputEditChange={handleInputEditChange}
            handleRadioDataChange={handleRadioDataChange}
            careManager={careManager}
            clientDetail={clientDetail}
            handleAddRowEditSupport={handleAddRowEditSupport}
            handleInputEditChangeSupport={handleInputEditChangeSupport}
            editSupport={editSupport}
            companyOne={companyOne}
          />
          <EditDate
            supportForm={supportForm}
            handleInputDataEditChange={handleInputDataEditChange}
          />
        </div>
        <div className="mx-auto text-center mt-3 lg:col-span-2">
          <button
            type="submit"
            disabled={loading}
            className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"
          >
            {loading ? <Spinner /> : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditSupportPlanForm;
