import React from "react";
import { StaffShift } from "../../../../store/slices/staffShiftSlice";
import dayjs from "dayjs";
interface Props {
  filteredAndSortedShifts: StaffShift[];
  handleShiftClick: (shift: StaffShift) => void;
  displayNumber: number;
}
const Upcoming = ({
  filteredAndSortedShifts,
  handleShiftClick,
  displayNumber,
}: Props) => {
  return (
    <div className="border rounded-2xl bg-white shadow-sm">
      <div className="bg-gray-100 text-center p-2 flex justify-between items-center text-sm font-semibold  rounded-t-xl ">
        <span>Upcoming Care Shift</span>{" "}
      </div>
      <div className="flex gap-2 flex-col   px-2 py-4 overflow-y-auto border-t border-b">
        <div className="text-sm flex justify-between items-center">
          <span className="font-bold">
            {" "}
            {filteredAndSortedShifts[displayNumber]
              ? dayjs(filteredAndSortedShifts[displayNumber]?.dateFrom).format(
                  "dddd, MMMM D, YYYY"
                )
              : "-- : --"}
          </span>
          {filteredAndSortedShifts[displayNumber] && (
            <button
              type="button"
              onClick={() =>
                handleShiftClick(filteredAndSortedShifts[displayNumber])
              }
              className="text-white bg-primary hover:bg-primary  
font-medium rounded-full text-xs  px-3 py-1  "
            >
              View Shift
            </button>
          )}
        </div>
        <div className="text-sm flex gap-10 items-center">
          <span className="font-bold">Client:</span>
          <span>{filteredAndSortedShifts[displayNumber]?.clients}</span>
        </div>
        <div className="text-sm flex gap-10 items-center">
          <span className="font-bold">Start Time:</span>
          <span>
            {filteredAndSortedShifts[displayNumber]
              ? dayjs(filteredAndSortedShifts[displayNumber]?.dateFrom).format(
                  "hh:mm A"
                )
              : "--"}
          </span>
        </div>
        <div className="text-sm flex gap-10 items-center">
          <span className="font-bold">End Time:</span>
          <span>
            {filteredAndSortedShifts[displayNumber]
              ? dayjs(filteredAndSortedShifts[displayNumber]?.dateTo).format(
                  "hh:mm A"
                )
              : "--"}
          </span>
        </div>
      </div>

      {/* <div className="flex gap-2 flex-col   px-2 py-4 overflow-y-auto border-t border-b">
                <div className='text-sm flex justify-between items-center'>
                    <span className='font-bold'> {filteredAndSortedShifts[1] ? dayjs(filteredAndSortedShifts[1]?.dateFrom).format('dddd, MMMM D, YYYY') : '-- : --'}</span>
                    {filteredAndSortedShifts[1] &&
                        <button type="button"
                            onClick={() => handleShiftClick(filteredAndSortedShifts[1])}
                            className="text-white bg-primary hover:bg-primary  
font-medium rounded-full text-xs  px-3 py-1  ">
                            View Shift

                        </button>
                    }
                </div>
                <div className='text-sm flex gap-10 items-center'>
                    <span className='font-bold'>Client:</span>
                    <span >{filteredAndSortedShifts[1]?.clients}</span>
                </div>
                <div className='text-sm flex gap-10 items-center'>
                    <span className='font-bold'>Start Time:</span>
                    <span >{filteredAndSortedShifts[1] ? dayjs(filteredAndSortedShifts[1]?.dateFrom).format('hh:mm A') : '--'}</span>
                </div>
                <div className='text-sm flex gap-10 items-center'>
                    <span className='font-bold'>End Time:</span>
                    <span >{filteredAndSortedShifts[1] ? dayjs(filteredAndSortedShifts[1]?.dateTo).format('hh:mm A') : '--'}</span>
                </div>
            </div> */}

      {/* <div className="flex gap-2 flex-col   px-2 py-4 overflow-y-auto border-t border-b">
                <div className='text-sm flex justify-between items-center'>
                    <span className='font-bold'> {filteredAndSortedShifts[2] ? dayjs(filteredAndSortedShifts[2]?.dateFrom).format('dddd, MMMM D, YYYY') : '-- : --'}</span>
                    {filteredAndSortedShifts[2] && <button type="button"

                        className="text-white bg-primary hover:bg-primary  
font-medium rounded-full text-xs  px-3 py-1  ">
                        View Shift

                    </button>}
                </div>
                <div className='text-sm flex gap-10 items-center'>
                    <span className='font-bold'>Client:</span>
                    <span >{filteredAndSortedShifts[2]?.clients}</span>
                </div>
                <div className='text-sm flex gap-10 items-center'>
                    <span className='font-bold'>Start Time:</span>
                    <span >{filteredAndSortedShifts[2] ? dayjs(filteredAndSortedShifts[2]?.dateFrom).format('hh:mm A') : '--'}</span>
                </div>
                <div className='text-sm flex gap-10 items-center'>
                    <span className='font-bold'>End Time:</span>
                    <span >{filteredAndSortedShifts[2] ? dayjs(filteredAndSortedShifts[2]?.dateTo).format('hh:mm A') : '--'}</span>
                </div>
            </div> */}
    </div>
  );
};

export default Upcoming;
