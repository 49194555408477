import React from 'react';
import { CompanyDetail } from '../../../types/AuthData';
import { ParticipantConsentForm } from '../../../types/DataType';

interface Props {
    companyOne: CompanyDetail | null | undefined;
    participantConsentForm: ParticipantConsentForm;
    handleCheckboxChangeBox: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const EditBox2: React.FC<Props> = ({ companyOne, participantConsentForm, handleCheckboxChangeBox }) => {
    return (
        <div>
            <div className="max-[1024px]:w-[100%] p-4  my-5 border border-black ">
                <p className="text-[16px] mb-3">
                    Consent for {companyOne?.companyName} to collect information from you:
                </p>
                <div className="flex items-start gap-3 mt-4 ">
                    <input
                        type="checkbox"
                        name="consent_to_Collect_Information"
                        checked={participantConsentForm.consent_to_Collect_Information}
                        onChange={handleCheckboxChangeBox}
                    />
                    <p className="text-[16px]">
                        To collect information about your disability, medical history,
                        living arrangements, support arrangements, and other information
                        such as your cultural needs, so we can continue to deliver services
                        that enhance your well-being.
                    </p>
                </div>
                <div className="flex items-start mb-4 gap-3 mt-4 ">
                    <input
                        type="checkbox"
                        name="consent_to_Share_Information"
                        checked={participantConsentForm.consent_to_Share_Information}
                        onChange={handleCheckboxChangeBox}
                    />
                    <p className="text-[16px]">
                        Permission to take photos of you for the purposes of identification
                        in your files.
                    </p>
                </div>
                <strong className="">
                    How we will secure your information: we will store that information in
                    secure electronic files and in locked paper storage. Only people
                    authorised to access your information can do so.
                </strong>
                <br />
                <br />
                <strong>How do I access my information?</strong>
                <p className="mt-2">
                    You can ask to see what information we hold about you at any time,
                    including how we store your information. If you want to see your
                    information or if want to make changes or update your information,
                    please contact (insert contact details)
                </p>
            </div>
        </div>
    );
};

export default EditBox2;
