import React, { FormEvent, useEffect, useRef, useState } from "react";
import ReferralForme from "./__component/ReferralForm";
import PrintComponent from "../PrintTab";
import Breadcrumb from "../../Components/label/BreadCrumb";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchClientInfo,
  fetchClientRepresentativeData,
  fetchCompany,
  fetchReferraldoc,
} from "../../services/fetchServices";
import { Client } from "../../store/slices/clientSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { CompanyDetail } from "../../types/AuthData";
import Spinner from "../../Components/Spinner";
import Date from "./__component/Date";
import axiosInstance from "../../store/axiosInstance";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import { ReferralFormData, Representative } from "../../types/DataType";
import EditReferralForm from "./EditReferalForm";

const ReferralForm = () => {
  const componentRef = useRef<HTMLDivElement>(null);
  const { uid } = useParams();
  const [clientDetail, setClientDetail] = useState<Client | null>(null);
  const [careManager, setCareManager] = useState<Representative | null>(null);
  const [loadingClient, setLoadingClient] = useState<boolean>(true);
  const user = useSelector((state: RootState) => state?.auth?.user);
  const [companyOne, setCompanyOne] = useState<
    CompanyDetail | null | undefined
  >(null);

  useEffect(() => {
    fetchClientData();
    handleCompanyFetch();
    fetchClientManager();
    fetchDoc();
  }, [uid, clientDetail?.careManager]);

  const handleCompanyFetch = async () => {
    // setLoading(true);
    if (user?.companyId) {
      const data = await fetchCompany(user?.companyId);
      setCompanyOne(data);
    }
    // setLoading(false);
  };
  const fetchClientData = async () => {
    const data = await fetchClientInfo(uid);
    setClientDetail(data);
    setLoadingClient(false);
  };

  const fetchClientManager = async () => {
    const data = await fetchClientRepresentativeData(Number(uid));
    setCareManager(data[0]);
  };

  const [values, setValues] = useState({
    disability: "",
    secondaryDisability: "",
    meetingDate: "",
    staffingRequest: "",
  });

  const handleInputDataChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [checker, setChecker] = useState<ReferralFormData | null>(null);
  const [referralForm, setReferalForm] = useState<ReferralFormData>({
    participantReferralFormId: 0,
    attendees: "",
    meetingDate: "",
    disability: "",
    secondaryDisability: "",
    isNDISPlanAttached: false,
    isConsentSigned: false,
    staffingRequest: "",
    profileId: 0,
    document_Name: "",
    implementationDate: "",
    expirationDate: "",
    companyId: 0,
  });
  const [editAttendee, setEditAttendee] = useState<string[]>([]);

  const fetchDoc = async () => {
    const data = await fetchReferraldoc(
      Number(uid),
      Number(user?.companyId),
      "PCD-103 P04 Referral form"
    );
    setReferalForm({ ...data });
    setChecker(data);
    data?.attendees &&
      setEditAttendee(
        data?.attendees.split(",").map((activity: string) => activity.trim())
      );
  };

  const handleInputEditChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newActivities = [...editAttendee];
    newActivities[index] = event.target.value;
    setEditAttendee(newActivities);
  };

  const handleAddRowEdit = () => {
    setEditAttendee([...editAttendee, ""]);
  };

  const handleRadioDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setReferalForm((prevState) => ({
      ...prevState,
      [name]: value === "true",
    }));
  };

  const handleInputDataEditChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setReferalForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [loading, setLoading] = useState<boolean>(false);
  const ImplementationDate = useRef<HTMLInputElement>(null);
  const ExpirationDate = useRef<HTMLInputElement>(null);
  const [attendeeValues, setAttendeeValues] = useState<string[]>([""]);
  const navigate = useNavigate();
  const handleInputChange = (index: number, value: string) => {
    const newValues = [...attendeeValues];
    newValues[index] = value;
    setAttendeeValues(newValues);
  };

  const handleAddRow = () => {
    setAttendeeValues([...attendeeValues, ""]);
  };

  const [otherValues, setOtherValues] = useState({
    isNDISPlanAttached: false,
    isConsentSigned: false,
    staffingRequest: false,
  });

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setOtherValues((prevState) => ({
      ...prevState,
      [name]: value === "true",
    }));
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const expirationDateValue = ExpirationDate.current?.value;

    const info = {
      attendees: attendeeValues.join(", "),
      disability: values.disability,
      meetingDate: values.meetingDate,
      secondaryDisability: values.secondaryDisability,
      isNDISPlanAttached: otherValues.isNDISPlanAttached,
      isConsentSigned: otherValues.isConsentSigned,
      staffingRequest: values.staffingRequest,
      profileId: uid,
      document_Name: "PCD-103 P04 Referral form",
      implementationDate: ImplementationDate.current?.value,
      ...(expirationDateValue ? { expirationDate: expirationDateValue } : {}),
      companyId: user?.companyId,
    };

    try {
      setLoading(true);

      const { data } = await axiosInstance.post(
        `/ParticipantReferralForms/fill_form?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      setLoading(false);
      navigate(
        `/app/user/clients/${clientDetail?.profileId}/${clientDetail?.firstName}`
      );
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleEditSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const info = {
      participantReferralFormId: referralForm.participantReferralFormId,
      attendees: editAttendee.join(", "),
      disability: referralForm.disability,
      meetingDate: referralForm.meetingDate,
      secondaryDisability: referralForm.secondaryDisability,
      isNDISPlanAttached: referralForm.isNDISPlanAttached,
      isConsentSigned: referralForm.isConsentSigned,
      staffingRequest: referralForm.staffingRequest,
      profileId: uid,
      document_Name: "PCD-103 P04 Referral form",
      implementationDate: referralForm.implementationDate,
      expirationDate: referralForm.expirationDate,
      companyId: user?.companyId,
    };

    try {
      setLoading(true);

      const { data } = await axiosInstance.post(
        `/ParticipantReferralForms/edit/${referralForm.participantReferralFormId}?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      setLoading(false);
      navigate(
        `/app/user/clients/${clientDetail?.profileId}/${clientDetail?.firstName}`
      );
    } catch (error: unknown) {
      console.log(error);
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Breadcrumb />
      {loadingClient && (
        <div className="fixed  inset-0 z-[9999]  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-auto max-w-lg mx-auto">
            <div
              className="relative z-[999] flex justify-center items-center p-4  duration-500 ease-in-out bg-white
border-0 rounded-lg shadow-lg outline-none focus:outline-none"
            >
              <Spinner color="#071952" />
            </div>
          </div>

          <div className="fixed inset-0 z-40 bg-gray-300 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
        </div>
      )}

      {checker ? (
        <EditReferralForm
          companyOne={companyOne}
          clientDetail={clientDetail}
          careManager={careManager}
          handleAddRowEdit={handleAddRowEdit}
          handleInputEditChange={handleInputEditChange}
          handleRadioDataChange={handleRadioDataChange}
          handleEditSubmit={handleEditSubmit}
          loading={loading}
          editAttendee={editAttendee}
          referralForm={referralForm}
          handleInputDataEditChange={handleInputDataEditChange}
        />
      ) : (
        <form onSubmit={handleSubmit}>
          <PrintComponent
            componentRef={componentRef}
            triggerLabel="Print / Download"
            documentTitle="Refferal Form"
          />
          <div
            ref={componentRef}
            className="mx-auto  max-w-3xl p-4 md:p-4 lg:p-8 bg-white"
          >
            <div className="flex justify-end">
              <img
                className="w-24  mb-4 lg:mb-4"
                src={companyOne?.companyLogo}
                alt="Company Logo"
              />
            </div>

            <ReferralForme
              clientDetail={clientDetail}
              careManager={careManager}
              values={values}
              handleInputDataChange={handleInputDataChange}
              handleInputChange={handleInputChange}
              attendeeValues={attendeeValues}
              handleAddRow={handleAddRow}
              otherValues={otherValues}
              handleRadioChange={handleRadioChange}
            />
            <Date
              ExpirationDate={ExpirationDate}
              ImplementationDate={ImplementationDate}
            />
          </div>
          <div className="mx-auto text-center mt-3 lg:col-span-2">
            <button
              type="submit"
              disabled={loading}
              className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"
            >
              {loading ? <Spinner /> : "Submit"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ReferralForm;
