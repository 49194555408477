import React, { ChangeEvent } from 'react';
import { FaPlus } from "react-icons/fa";
import { Client } from '../../../store/slices/clientSlice';
import { Staff } from '../../../store/slices/staffSlice';

type RowKeys = "workingWell" | "notWorkingWell";

interface Row {
  workingWell: string;
  notWorkingWell: string;
}

interface Props {
  attendeeValues: string[];
  handleInputChange: (index: number, value: string) => void;
  handleAddRow: () => void;
  clientDetail: Client | null;
  careManager: Staff | null;
  rows: Row[];
  allValue: {
    value1: string;
    value2: string;
    value3: string;
    value4: string;
    value5: string;
    value6: string;
  };
  handleInputDataChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
  handleCellChange: (rowIndex: number, cellName: RowKeys, value: string) => void;
}

const SupportPlanReview: React.FC<Props> = ({ attendeeValues, handleInputChange,
  careManager, allValue, handleCellChange, handleInputDataChange, rows,
  handleAddRow, clientDetail }) => {

  return (
    <div className="p-4">
      <div className='flex justify-between items-center'>
        <h1 className="text-2xl font-bold mb-4">Support plan review meeting</h1>
        {/* <img src="/logo192.png" alt="" /> */}
      </div>
      <table className="w-full border-collapse">
        <tbody>
          <tr>
            <td className="border p-2">Meeting date:</td>
            <td className="border p-2">
              <input
                type="date"
                className="w-full outline-none"
                name="value1"
                value={allValue.value1}
                onChange={handleInputDataChange}
              />
            </td>
          </tr>
          <tr>
            <td className="border p-2">Attendees:</td>
            {attendeeValues.map((value, index) => (
              <td key={index} className="whitespace-nowrap border flex gap-3 px-4 py-2 text-gray-700 w-[100%] outline-none">
                <span>{index + 1}.</span>
                <input
                  className="w-[100%] h-[20px] outline-none"
                  type="text"
                  value={value}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(index, e.target.value)}
                />
              </td>
            ))}
          </tr>

          <tr>
            <td className=""></td>
            <td className="flex justify-end">
              <button
                type="button"
                onClick={handleAddRow}
                className="mt-4 w-10 h-10  bg-gray-200 text-white rounded-full flex justify-center items-center"
              >
                <FaPlus className="text-lg text-black" />
              </button>
            </td>
          </tr>

          <tr>
            <td className="border p-2">Participant details</td>
            <td className="border p-2"></td>
          </tr>
          <tr>
            <td className="border p-2">Last name:</td>
            <td className="border p-2">{clientDetail?.surName}</td>
          </tr>
          <tr>
            <td className="border p-2">First name:</td>
            <td className="border p-2">{clientDetail?.firstName}</td>
          </tr>
          <tr>
            <td className="border p-2">Preferred name:</td>
            <td className="border p-2">
              {clientDetail?.middleName}
            </td>
          </tr>
          <tr>
            <td className="border p-2">Date of birth:</td>
            <td className="border p-2">{clientDetail?.dateOfBirth}</td>
          </tr>
          <tr>
            <td className="border p-2">Date of support plan:</td>
            <td className="border p-2">
              <input
                type="date"
                className="w-full outline-none"
                name="value3"
                value={allValue.value3}
                onChange={handleInputDataChange}
              />
            </td>
          </tr>
          <tr className=' bg-gray-200 font-semibold'>
            <td className="border p-2">What’s working well with the plan?</td>
            <td className="border p-2">What’s not working well with the plan?</td>
          </tr>
          {rows.map((row, index) => (
            <tr key={index} className="divide-x-2">
              <td className="whitespace-nowrap border p-2 text-gray-700 w-[40%]">
                <input
                  type="text"
                  value={row.workingWell}
                  onChange={(e) => handleCellChange(index, "workingWell", e.target.value)}
                  className="border-none text-gray-900 text-sm outline-none block w-full bg-white h-full px-2.5"
                />
              </td>
              <td className="whitespace-nowrap border p-2 text-gray-700 w-full">
                <input
                  type="text"
                  value={row.notWorkingWell}
                  onChange={(e) => handleCellChange(index, "notWorkingWell", e.target.value)}
                  className="border-none text-gray-900 text-sm outline-none block w-full bg-white h-full px-2.5"
                />
              </td>
            </tr>
          ))}
          <tr>
            <td className="border p-2">Goals I have achieved:</td>
            <td className="border p-2 flex gap-2 ">
              <input
                className="w-[100%] h-[20px] outline-none"
                type="text"
                name="value4"
                value={allValue.value4}
                onChange={handleInputDataChange}
              />
            </td>
          </tr>
          <tr>
            <td className="border p-2">Goals still in progress:</td>
            <td className="border p-2 flex gap-2 ">
              <input
                className="w-[100%] h-[20px] outline-none"
                type="text"
                name="value5"
                value={allValue.value5}
                onChange={handleInputDataChange}
              />
            </td>
          </tr>
          <tr>
            <td className="border p-2">New goals I want to achieve:</td>
            <td className="border p-2 flex gap-2 ">
              <input
                className="w-[100%] h-[20px] outline-none"
                type="text"
                name="value6"
                value={allValue.value6}
                onChange={handleInputDataChange}
              />
            </td>
          </tr>
          <tr>
            <td className="border p-2" colSpan={2}>
              <i>If changes are required to the support plan please complete a new Support Plan template and mark the previous plan as ‘superseded’.</i>
            </td>
          </tr>
          <strong className='mt-8 text-[16px]'>Agreements</strong>
          <tr>
            <td className="border p-2 font-semibold">Participant or representative</td>
            <td className="border p-2 font-semibold">Provider representative</td>
          </tr>
          <tr>
            <td className="border p-2" >Name: {clientDetail?.fullName}</td>
            <td className="border p-2" >Name: {careManager?.fullName}</td>
          </tr>

          <tr>
            <td className="border p-2" >Signature: {clientDetail?.signature && <div>
              {clientDetail?.signature && (
                <img
                  src={clientDetail?.signature}
                  alt="Participant Signature"
                  className="img-thumbnail block"
                  style={{
                    display: "block",
                    width: "150px",
                  }}
                />
              )}
            </div>}</td>
            <td className="border p-2" >Signature: {careManager?.signatureUrl && <div>
              {careManager?.signatureUrl && (
                <img
                  src={careManager?.signatureUrl}
                  alt="Rep Signature"
                  className="img-thumbnail block"
                  style={{
                    display: "block",
                    width: "150px",
                  }}
                />
              )}
            </div>}</td>
          </tr>
          <tr>
            <td className="border p-2" >Date of birth: {clientDetail?.dateOfBirth}</td>
            <td className="border p-2" >Date of birth: {careManager?.dateOfBirth}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SupportPlanReview;


