import React from "react";
import { FormDataType } from "..";
import { Representative } from "../../../types/DataType";

interface Props {
  careManager: Representative | null;
  formData: FormDataType;
  handleInputFormChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  handleRadioFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RepContact: React.FC<Props> = ({
  careManager,
  formData,
  handleInputFormChange,
  handleRadioFormChange,
}) => {
  return (
    <div>
      <table className="w-full mb-6">
        <tbody>
          <tr>
            <td className="border px-4 py-2 font-bold">Name:</td>
            <td className="border px-4 py-2">
              <input
                type="text"
                name="fullName"
                className="w-full p-2 border border-gray-300 rounded"
                value={careManager?.fullName}
              />
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2 font-bold">Relationship:</td>
            <td className="border px-4 py-2">
              <input
                type="text"
                name="relationship"
                className="w-full p-2 border border-gray-300 rounded"
                value={careManager?.relationship}
              />
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2 font-bold">Contact number:</td>
            <td className="border px-4 py-2">
              <input
                type="tel"
                name="phoneNumber"
                className="w-full p-2 border border-gray-300 rounded"
                value={careManager?.mobilePhone}
              />
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2 font-bold">Email:</td>
            <td className="border px-4 py-2">
              <input
                type="email"
                name="email"
                className="w-full p-2 border border-gray-300 rounded"
                value={careManager?.email}
              />
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2 font-bold">
              In what situations does this person need to be contacted:
            </td>
            <td className="border px-4 py-2">
              <textarea
                name="contactSituations"
                className="w-full p-2 border border-gray-300 rounded mt-2"
                value={formData.contactSituations}
                onChange={handleInputFormChange}
              />
            </td>
          </tr>

          <tr>
            <td className="border px-4 py-2 font-bold">
              Is this person an appointed Guardian?
            </td>
            <td className="border px-4 py-2">
              <div className="flex items-center">
                <label className="mr-4">
                  <input
                    type="radio"
                    name="isGuardian"
                    className="mr-2"
                    value="true"
                    checked={formData.isGuardian === true}
                    onChange={handleRadioFormChange}
                  />{" "}
                  Yes
                </label>
                <label>
                  <input
                    type="radio"
                    name="isGuardian"
                    className="mr-2"
                    value="false"
                    checked={formData.isGuardian === false}
                    onChange={handleRadioFormChange}
                  />{" "}
                  No
                </label>
              </div>
            </td>
          </tr>
          {formData.isGuardian && (
            <tr>
              <td className="border px-4 py-2 font-bold">
                If yes, what are the functions of decision making:
              </td>
              <td className="border px-4 py-2">
                <textarea
                  className="w-full p-2 border border-gray-300 rounded"
                  name="decisionMakingFunctions"
                  value={formData.decisionMakingFunctions}
                  onChange={handleInputFormChange}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RepContact;
