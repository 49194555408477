import React, { FormEvent, useEffect, useRef, useState } from "react";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import SEO from "../../../constants/SEO";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import Spinner from "../../../Components/Spinner";
import PaginationComponent from "../../../Components/CustomPagination";
import { useNavigate } from "react-router-dom";
import fileImg from "../../../images/icon/EmptyState.png";
import { fetchCompanyPolicy, policyData, Policy, policyLoading } from "../../../store/slices/companyPolicySlice";
import PolicyCard from "./components";
import { InputPicker, Modal } from "rsuite";
import CustomInput from "../../../Components/Input/CustomInput";
import { toast } from "react-toastify";
import axiosInstance from "../../../store/axiosInstance";
import { isAxiosError } from "axios";

interface Category {
    label: string;
    value: string;
}
const CompanyPolicy = () => {

    const [pageNumber, setPageNumber] = useState(0);
    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    const companyId = user?.companyId;
    useEffect(() => {
        dispatch(fetchCompanyPolicy(Number(companyId)));
        FetchCategory()
    }, [dispatch, companyId]);

    const [categor, setCategor] = useState<Category[]>([]);

    const FetchCategory = async () => {

        try {
            const { data } = await axiosInstance.get(
                `/PolicyDocuments/get_company_categories?companyId=${companyId}`
            );
            const formattedData = data.categories.map((item: { category: string }) => {
                const match = item.category.match(/(.*) \((.*)\)/);
                return {
                    label: match ? match[1] : item.category, // Extracts 'Right & Responsibility' part
                    value: match ? match[2] : item.category, // Extracts 'C1' part
                };
            });
            const finalData = [
                ...formattedData,
                { label: "Others", value: "Others" }
            ];
            setCategor(finalData)
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // console.log(error);;
            } else {
                toast.error("An error occurred");
            }
        }
    };

    // console.log(categor)
    const policy = useSelector(policyData);
    const loadingData = useSelector(policyLoading);
    const navigate = useNavigate();
    const [addModal, setAddModal] = useState<boolean>(false)

    const itemsPerPage = 12;
    const pageCount = Math.ceil(policy.length / itemsPerPage);
    const displayData = policy.slice(
        pageNumber * itemsPerPage,
        (pageNumber + 1) * itemsPerPage
    );
    const topOfPageRef = useRef<HTMLInputElement>(null);

    const handlePageClick = (selectedItem: { selected: number }) => {
        setPageNumber(selectedItem.selected);
        topOfPageRef.current?.scrollIntoView({
            behavior: "smooth",
        });
    };

    const handleViewCategory = (categoryCode: string) => {
        navigate(`/app/hr/policy/doc/${categoryCode}`);
        // You can now handle this categoryCode, maybe use it in another action
    };
    const [loading1, setLoading1] = useState<boolean>(false);
    const [category, setCategory] = useState('');
    const [categoryCode, setCategoryCode] = useState('');
    const [policyName, setPolicyName] = useState('');
    const [policyDocument, setPolicyDocument] = useState<File | null>(null);
    const [isClientPolicy, setIsClientPolicy] = useState<boolean>(false);
    const [isStaffPolicy, setIsStaffPolicy] = useState<boolean>(false)
    const [isGeneralPolicy, setIsGeneralPolicy] = useState<boolean>(false)

    const [showCustomCategory, setShowCustomCategory] = useState<boolean>(false);

    const handleCategoryChange = (value: string) => {
        if (value === "Others") {
            // Show input for custom category and allow custom category code
            setShowCustomCategory(true);
            setCategory("");
            setCategoryCode("");
        } else {
            // Extract the category code from the selected value
            const selectedCategory = categor.find(item => item.value === value);
            setCategory(selectedCategory?.label || "");
            setCategoryCode(selectedCategory?.value || ""); // Set the category code
            setShowCustomCategory(false);
        }
    };
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setPolicyDocument(file);
        }
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (
            category === "" ||
            categoryCode === "" ||
            policyName === "" ||
            !isGeneralPolicy && !isClientPolicy && !isStaffPolicy
        ) {
            return toast.error("Marked Fields must be filled and select at least one policy type.");
        }

        const formData = new FormData();
        formData.append("CompanyId", companyId?.toString() ?? ""); // Using optional chaining and nullish coalescing
        formData.append("Category", category);
        formData.append("CategoryCode", categoryCode);
        formData.append("PolicyName", policyName);
        formData.append("IsGeneral", isGeneralPolicy.toString());
        formData.append("IsStaff", isStaffPolicy.toString());
        formData.append("IsClient", isClientPolicy.toString());
        policyDocument && formData.append("PolicyUrlFile", policyDocument);

        try {
            setLoading1(true);
            const { data } = await axiosInstance.post(
                `/PolicyDocuments/add_policy?userId=${user?.uid}`,
                formData
            );
            toast.success(data.message);
            setLoading1(false);
            companyId && dispatch(fetchCompanyPolicy(companyId));
            setAddModal(false);
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // console.log(error);;
            } else {
                toast.error("An error occurred");
            }
            setLoading1(false);
            setAddModal(false);
        } finally {
            setLoading1(false);
            setAddModal(false);
        }
    };

    return (
        <>
            <SEO
                title={`Policies`}
                description="Company Policies"
                name="Promax-Care"
            />
            <Breadcrumb showNavigation={false} />

            <div
                className="flex justify-between items-center flex-wrap"
                ref={topOfPageRef}
            >
                <h2 className="font-bold mt-5 text-2xl text-black w-auto">Policies</h2>
                <button
                    onClick={() => setAddModal(true)}
                    type="button"
                    className="px-4 py-2 bg-primary text-white  disabled:bg-slate-400
                            transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm 
                           "
                >
                    Add New Policy
                </button>
            </div>

            {policy.length <= 0 && loadingData && <Spinner color="#071952" />}
            {!loadingData && policy.length <= 0 && (
                <div className=" border border-dashed flex justify-center flex-col items-center my-5 rounded py-10 space-y-2">
                    <img src={fileImg} alt="file upload icon" width={200} height={300} />

                    <strong className="text-xl">No Data Available</strong>
                </div>
            )}
            <div className="my-5">
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 ">

                    {displayData.map((subArray: Policy[], index: number) => {
                        const category = subArray.length > 0 ? subArray[0].category : "No category"; // Assign default text if no category exists
                        const categoryCode = subArray.length > 0 ? subArray[0].categoryCode : "No code";
                        return (
                            <PolicyCard
                                key={index} // Using index or some other unique identifier if available
                                content={category} // Pass the category as content
                                onView={() => {
                                    handleViewCategory(categoryCode); // Call a function and pass the categoryCode
                                }}
                            />
                        );
                    })}
                </div>
                {pageCount > 1 && (
                    <PaginationComponent
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                    />
                )}
                <br />
            </div>

            <Modal
                open={addModal}
                onClose={() => setAddModal(false)}
                backdrop="static"
                autoFocus
                size={"lg"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Add New Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form
                            action=""
                            className="p-3 grid grid-cols-1 gap-3 lg:grid-cols-2"
                            onSubmit={handleSubmit}
                        >
                            <div>
                                <label className="text-xs text-gray-600 font-semibold">
                                    Select Category
                                </label>
                                <InputPicker
                                    size="lg"
                                    onChange={handleCategoryChange}
                                    data={categor}
                                    className="w-full focus:outline-none focus:border-primary text-gray-600"
                                    appearance="subtle"
                                />
                                {showCustomCategory && (
                                    <CustomInput
                                        label="Custom Category"
                                        type="text"
                                        onChange={(e) => setCategory(e.target.value)}
                                        value={category}
                                        required
                                    />
                                )}
                            </div>

                            <CustomInput
                                label="Category Code"
                                type="text"
                                onChange={(e) => setCategoryCode(e.target.value)}
                                value={categoryCode}
                                readOnly={!showCustomCategory} // Editable only when "Others" is selected
                                required
                            />

                            <CustomInput
                                label="Policy Name"
                                type="text"
                                onChange={(e) => setPolicyName(e.target.value)}
                                value={policyName}
                                required
                            />

                            <CustomInput
                                label="Policy File"
                                type="file"
                                onChange={handleFileChange}
                            />


                            <div className="flex items-center">
                                <input
                                    id="default-checkbox"
                                    checked={isGeneralPolicy}
                                    onChange={() => setIsGeneralPolicy(!isGeneralPolicy)}
                                    type="checkbox"
                                    className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
                                />
                                <label
                                    htmlFor="default-checkbox"
                                    className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    {" "}
                                    Is General Policy
                                </label>
                            </div>
                            <div className="flex items-center">
                                <input
                                    id="default-checkbox"
                                    checked={isClientPolicy}
                                    onChange={() => setIsClientPolicy(!isClientPolicy)}
                                    type="checkbox"
                                    className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
                                />
                                <label
                                    htmlFor="default-checkbox"
                                    className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    {" "}
                                    Is Client Policy
                                </label>
                            </div>
                            <div className="flex items-center">
                                <input
                                    id="default-checkbox"
                                    checked={isStaffPolicy}
                                    onChange={() => setIsStaffPolicy(!isStaffPolicy)}
                                    type="checkbox"
                                    className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
                                />
                                <label
                                    htmlFor="default-checkbox"
                                    className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    {" "}
                                    Is Staff Policy
                                </label>
                            </div>



                            <div className="mx-auto text-center mt-3 lg:col-span-2">
                                <button
                                    type="submit"
                                    disabled={loading1}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary transition duration-300 transform active:scale-95 ease-in-out"
                                >
                                    {loading1 ? <Spinner /> : " Submit"}
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CompanyPolicy;
