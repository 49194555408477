import React, {
    ChangeEvent,
    FormEvent,
    useEffect,
    useState,
} from "react";
import DataTable, {
    ExpanderComponentProps,
    TableColumn,
} from "react-data-table-component";
import { BsSearch } from "react-icons/bs";
import ExportDropdown from "../../../../Components/menu/ExportDropdown";
import IconButton from "../../../../Components/Buttons/IconButton";
import {
    downloadCSV,
    exportToExcel,
    handlePDFDownload,
} from "../../../../services/reusableFunc";
import { Column } from "../../../../types/DataType";
import { FaFileCsv, FaFileExcel, FaFilePdf } from "react-icons/fa";
import {
    AppDispatch,
    RootState,
    useAppDispatch,
} from "../../../../store/store";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Button, InputPicker, Modal } from "rsuite";
import CustomInput from "../../../../Components/Input/CustomInput";
import Spinner from "../../../../Components/Spinner";
import { toast } from "react-toastify";
import axiosInstance from "../../../../store/axiosInstance";
import { isAxiosError } from "axios";
import { fetchStaff, staffData } from "../../../../store/slices/staffSlice";
import { ZodType, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { expenditureData, fetchExpenditure, ExpenditureType, expenditureLoading } from "../../../../store/slices/expenditureSlice";


interface ClientExpenditureProps {
    uid: string; // Define the type of uid as string
}

type DataType = {
    PurchaseDate: string;
    AmountProvidedByParticipant: string;
    AmountReturnedToParticipant: string;
    PurchasedItem: string;
    AmountSpent: string;
    ReasonIfNoReciept: string;
};

const ParticipantExpenditure: React.FC<ClientExpenditureProps> = ({ uid }) => {

    const columns: TableColumn<ExpenditureType>[] = [
        {
            name: "Staff",
            selector: (row) => row.staff.fullName,
            sortable: true,
        },
        {
            name: "Amount Provided By Participant",
            selector: (row) => row.amountProvidedByParticipant,
            sortable: true,
        },
        {
            name: "Amount Returned To Participant",
            selector: (row) => row.amountReturnedToParticipant,
            sortable: true,
        },
        {
            name: "Purchased Item",
            selector: (row) => row.purchasedItem,
            sortable: true,
        },
        {
            name: "AmountSpent",
            selector: (row) => row.amountSpent,
            sortable: true,
        },
        {
            name: "Date Created",
            selector: (row) => dayjs(row.dateCreated).format("DD/MM/YYYY HH:mm:ss"),
            sortable: true,
        },
    ];


    const [addModal, setAddModal] = useState<boolean>(false);
    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    const companyId = user?.companyId;
    useEffect(() => {
        if (uid) {
            dispatch(fetchExpenditure(Number(uid)));
            companyId && dispatch(fetchStaff(companyId));
        }
    }, [dispatch, uid, companyId]);
    const expenditure = useSelector(expenditureData);
    const loadingData = useSelector(expenditureLoading);
    const staff = useSelector(staffData);

    const [searchText, setSearchText] = useState<string>("");

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const filteredData = expenditure?.filter((item) =>
        item.purchasedItem.toLowerCase().includes(searchText.toLowerCase())
    );

    interface Props extends ExpanderComponentProps<ExpenditureType> {
        // currently, props that extend ExpanderComponentProps must be set to optional.
        someTitleProp?: string;
    }

    const [saveId, setSaveId] = useState<number>(0);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [receiptModal, setReceiptModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [expenditureDetails, setExpenditureDetails] = useState<ExpenditureType>({
        amountProvidedByParticipant: 0,
        amountReturnedToParticipant: 0,
        amountSpent: 0,
        companyId: 0,
        dateCreated: "",
        dateModified: "",
        participantExpenditureId: 0,
        profile: {
            profileId: 0,
            firstName: "",
            surName: "",
            middleName: "",
            fullName: "",
        },
        staff: {
            staffId: 0,
            firstName: "",
            surName: "",
            middleName: "",
            fullName: "",
        },
        profileId: 0,
        purchaseDate: "",
        purchasedItem: "",
        reasonIfNoReciept: "",
        receiptFile: "",
        receiptUrl: "",
        staffId: 0,
        userCreated: "",
        userModified: "",
    });

    const [users, setUsers] = useState<string>("");
    const [receiptFile, setReceiptFile] = useState<File | null>(null);
    const [selectedStaff, setSelectedStaff] = useState<number>(0);
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setReceiptFile(file);
        }
    };

    const schema: ZodType<DataType> = z.object({
        AmountProvidedByParticipant: z
            .string()
            .regex(/^\d+(\.\d+)?$/, "Must be a number")
            .min(1, "Amount Provided By Participant is required"),
        AmountReturnedToParticipant: z
            .string()
            .regex(/^\d+(\.\d+)?$/, "Must be a number")
            .min(1, "Amount Returned To Participant is required"),
        AmountSpent: z
            .string()
            .regex(/^\d+(\.\d+)?$/, "Must be a number")
            .min(1, "Amount Spent is required"),
        PurchaseDate: z.string(),
        PurchasedItem: z.string(),
        ReasonIfNoReciept: z.string(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<DataType>({
        resolver: zodResolver(schema),
    });

    const handleSubmitForm = async (data: DataType) => {

        if (!users) {
            toast.error("Select Staff")
            return
        }

        const formData = new FormData();
        formData.append("CompanyId", companyId?.toString() ?? "");
        formData.append("AmountProvidedByParticipant", data.AmountProvidedByParticipant);
        formData.append("AmountReturnedToParticipant", data.AmountReturnedToParticipant);
        formData.append("AmountSpent", data.AmountSpent);
        formData.append("PurchaseDate", data.PurchaseDate);
        formData.append("PurchasedItem", data.PurchasedItem);
        formData.append("ReasonIfNoReciept", data.ReasonIfNoReciept);
        formData.append("StaffId", users);
        formData.append("ProfileId", uid);
        receiptFile && formData.append("ReceiptFile", receiptFile);

        try {
            setLoading(true);
            const { data } = await axiosInstance.post(
                `/ParticipantExpenditures/add_expenditure?userId=${user?.uid}`,
                formData
            );

            if (data.status === "Success") {
                toast.success(data.message);
                setAddModal(false);
                uid && dispatch(fetchExpenditure(Number(uid)));
            } else {
                toast.error(data.message);
            }
            setLoading(false);

        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // console.log(error);;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        const { name, value } = e.target;
        setExpenditureDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const EditDetail = async (id: number) => {
        setSaveId(id);
        setShowModal(true);
        try {
            const { data } = await axiosInstance.get(
                `/ParticipantExpenditures/get_expenditure_details/${id}`
            );
            setExpenditureDetails({ ...data });
            setSelectedStaff(data.staff.staffId);

        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // console.log(error);;
            } else {
                toast.error("An error occurred");
            }
        }
    };

    const ViewReceiptDetail = async (id: number) => {
        setSaveId(id);
        setReceiptModal(true);
        try {
            const { data } = await axiosInstance.get(
                `/ParticipantExpenditures/get_expenditure_details/${id}`
            );
            setExpenditureDetails({ ...data });

        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // console.log(error);;
            } else {
                toast.error("An error occurred");
            }
        }
    };

    const handleEdit = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append("ParticipantExpenditureId", saveId.toString());
        formData.append("CompanyId", companyId?.toString() ?? "");
        formData.append("AmountProvidedByParticipant", expenditureDetails.amountProvidedByParticipant.toString());
        formData.append("AmountReturnedToParticipant", expenditureDetails.amountReturnedToParticipant.toString());
        formData.append("AmountSpent", expenditureDetails.amountSpent.toString());
        formData.append("PurchaseDate", expenditureDetails.purchaseDate);
        formData.append("PurchasedItem", expenditureDetails.purchasedItem);
        formData.append("ReasonIfNoReciept", expenditureDetails.reasonIfNoReciept);
        formData.append("StaffId", selectedStaff.toString());
        formData.append("ProfileId", uid);
        receiptFile && formData.append("ReceiptFile", receiptFile);
        try {
            const { data } = await axiosInstance.post(
                `/ParticipantExpenditures/edit/${saveId}?userId=${user?.uid}`,
                formData
            );
            toast.success(data.message);
            uid && dispatch(fetchExpenditure(Number(uid)));
            setShowModal(false);
            setLoading(false);
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // console.log(error);;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);
        }
    };

    const deleteSchedule = (id: number) => {
        setSaveId(id);
        setDeleteModal(true);
    };

    const handleDeleteSchedule = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            const { data } = await axiosInstance.post(
                `/ParticipantExpenditures/delete/${saveId}?userId=${user?.uid}`
            );
            toast.success(data.message);
            setDeleteModal(false);
            uid && dispatch(fetchExpenditure(Number(uid)));
            setLoading(false);
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // console.log(error);;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);
            setDeleteModal(false);
        } finally {
            setLoading(false);
        }
    };

    const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
        return (
            <div className="p-2 flex flex-col gap-2 text-xs">

                <span>
                    <span className="font-bold">Amount Provided By Participant: </span>
                    <span> {data.amountProvidedByParticipant}</span>
                </span>
                <span>
                    <span className="font-bold">Amount Returned To Participant: </span>
                    <span> {data.amountReturnedToParticipant}</span>
                </span>
                <span>
                    <span className="font-bold">Amount Spent: </span>
                    <span> {data.amountSpent}</span>
                </span>
                <span>
                    <span className="font-bold">Reason If No Receipt: </span>
                    <span> {data.reasonIfNoReciept}</span>
                </span>
                <span>
                    <span className="font-bold">Purchased Item: </span>
                    <span> {data.purchasedItem}</span>
                </span>
                <span>
                    <span className="font-bold">PurchaseDate: </span>
                    <span> {dayjs(data.purchaseDate).format("DD/MM/YYYY")}</span>
                </span>
                <span>
                    <span className="font-bold">Date Created: </span>
                    <span>{dayjs(data.dateCreated).format("DD/MM/YYYY HH:mm:ss")}</span>
                </span>
                <span>
                    <span className="font-bold">Date Modified: </span>
                    <span>{dayjs(data.dateModified).format("DD/MM/YYYY HH:mm:ss")}</span>
                </span>
                <div className="flex gap-2">
                    <span className="font-bold">Actions: </span>
                    <button
                        className="btn text-primary font-bold"
                        style={{ fontSize: "12px" }}
                        onClick={() => EditDetail(data.participantExpenditureId)}
                    >
                        Edit
                    </button>
                    |
                    <button
                        className="btn text-red-500 font-bold"
                        style={{ fontSize: "12px" }}
                        onClick={() => deleteSchedule(data.participantExpenditureId)}
                    >
                        Delete
                    </button>
                    |
                    {data.receiptUrl && <button
                        className="btn text-primary font-bold"
                        style={{ fontSize: "12px" }}
                        onClick={() => ViewReceiptDetail(data.participantExpenditureId)}
                    >
                        View Receipt
                    </button>}
                </div>
            </div>
        );
    };

    return (
        <div className="mt-10">
            {expenditure.length <= 0 && loadingData && <Spinner color="#071952" />}
            <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
                <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
                    <h2 className="font-bold text-xl text-black w-auto">Expenditure</h2>

                    <div className="relative w-full lg:col-span-2">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <BsSearch />
                        </div>
                        <input
                            type="text"
                            id="simple-search"
                            onChange={handleSearch}
                            className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            placeholder="Search"
                        />
                    </div>
                </div>
                <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                    <div className="flex items-center space-x-3 w-full md:w-auto">
                        {/* <button id="actionsDropdownButton" className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                              <FaFilter />
                              Filter
                          </button> */}
                        <ExportDropdown>
                            <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                <IconButton
                                    icon={<FaFilePdf className="text-red-500" />}
                                    title="Export as PDF"
                                    onClick={() =>
                                        handlePDFDownload(
                                            expenditure,
                                            columns as unknown as Column<ExpenditureType>[],
                                            "ClientSchedule.pdf"
                                        )
                                    }
                                />
                                <IconButton
                                    icon={<FaFileExcel className="text-green-600" />}
                                    title="Export as Excel Sheet"
                                    onClick={() =>
                                        exportToExcel(
                                            expenditure,
                                            columns as unknown as Column<ExpenditureType>[],
                                            "ClientSchedule"
                                        )
                                    }
                                />
                                <IconButton
                                    icon={<FaFileCsv className="text-green-500" />}
                                    title="Export as CSV"
                                    onClick={() =>
                                        downloadCSV({
                                            data: expenditure,
                                            filename: "ClientSchedule.csv",
                                        })
                                    }
                                />
                            </div>
                        </ExportDropdown>
                    </div>
                    <button
                        type="button"
                        onClick={() => setAddModal(true)}
                        className="px-4 py-2 bg-primary text-white  disabled:bg-slate-400
      transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm 
     "
                    >
                        Add New Expenditure
                    </button>
                </div>
            </div>

            <DataTable
                columns={columns}
                data={filteredData}
                expandableRows
                expandableRowsComponent={ExpandableRowComponent}
                pagination
                paginationTotalRows={expenditure?.length}
            // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}
            />

            <Modal
                open={addModal}
                onClose={() => setAddModal(false)}
                backdrop="static"
                autoFocus
                size={"lg"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Add Expenditure</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form onSubmit={handleSubmit(handleSubmitForm)}>
                            <div className="space-y-1 p-3 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
                                <div>
                                    <label className=" text-xs text-gray-600 font-semibold">
                                        Select Staff
                                    </label>
                                    <InputPicker
                                        size="lg"
                                        placeholder="--All Staff--"
                                        onChange={(value) => setUsers(value)}
                                        data={staff.map((user) => ({
                                            label: user.fullName,
                                            value: user.staffId,
                                        }))}
                                        className="w-full focus:outline-none focus:border-primary text-gray-600"
                                        appearance="subtle"
                                        value={users}
                                    />
                                </div>

                                <CustomInput
                                    label="Purchase Date"
                                    type="date"
                                    {...register("PurchaseDate")}
                                    error={errors?.PurchaseDate?.message}
                                />

                                <CustomInput
                                    label="Amount Provided By Participant"
                                    type="text"
                                    {...register("AmountProvidedByParticipant")}
                                    error={errors?.AmountProvidedByParticipant?.message}
                                />

                                <CustomInput
                                    label="Amount Returned To Participant"
                                    type="text"
                                    {...register("AmountReturnedToParticipant")}
                                    error={errors?.AmountReturnedToParticipant?.message}
                                />

                                <CustomInput
                                    label="Purchased Item"
                                    type="text"
                                    {...register("PurchasedItem")}
                                    error={errors?.PurchasedItem?.message}
                                />

                                <CustomInput
                                    label="Amount Spent"
                                    type="text"
                                    {...register("AmountSpent")}
                                    error={errors?.AmountSpent?.message}
                                />

                                <CustomInput
                                    label="Upload Receipt"
                                    type="file"
                                    accept=".jpg, .jpeg, .png"
                                    onChange={handleFileChange}
                                />

                                <CustomInput
                                    label="Reason If No Receipt"
                                    type="text"
                                    {...register("ReasonIfNoReciept")}
                                    error={errors?.ReasonIfNoReciept?.message}
                                />

                            </div>

                            <div className="mx-auto text-center mt-3">
                                <button
                                    type="submit"
                                    disabled={loading}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                      font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                      dark:focus:ring-primary"
                                >
                                    {loading ? <Spinner /> : "Add"}
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>

            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                backdrop="static"
                autoFocus
                size={"lg"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Edit Expenditure</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form action="" onSubmit={handleEdit}>
                            <div className="space-y-1 p-3 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
                                <div>
                                    <label className=" text-xs text-gray-600 font-semibold">
                                        Select Staff
                                    </label>
                                    <InputPicker
                                        size="lg"
                                        placeholder="--All Staff--"
                                        onChange={(value) => setSelectedStaff(value)}
                                        data={staff.map((user) => ({
                                            label: user.fullName,
                                            value: user.staffId,
                                        }))}
                                        className="w-full focus:outline-none focus:border-primary text-gray-600"
                                        appearance="subtle"
                                        value={selectedStaff}
                                    />
                                </div>

                                <CustomInput
                                    label="Purchase Date"
                                    type="date"
                                    name='purchaseDate'
                                    value={expenditureDetails.purchaseDate || ""}
                                    onChange={handleInputChange}
                                />

                                <CustomInput
                                    label="Amount Provided By Participant"
                                    type="text"
                                    name='amountProvidedByParticipant'
                                    value={expenditureDetails.amountProvidedByParticipant || ""}
                                    onChange={handleInputChange}
                                />

                                <CustomInput
                                    label="Amount Returned To Participant"
                                    type="text"
                                    name='amountReturnedToParticipant'
                                    value={expenditureDetails.amountReturnedToParticipant || ""}
                                    onChange={handleInputChange}
                                />

                                <CustomInput
                                    label="Purchased Item"
                                    type="text"
                                    name='purchaseDate'
                                    value={expenditureDetails.purchaseDate || ""}
                                    onChange={handleInputChange}
                                />

                                <CustomInput
                                    label="Amount Spent"
                                    type="text"
                                    name='amountSpent'
                                    value={expenditureDetails.amountSpent || ""}
                                    onChange={handleInputChange}
                                />

                                <CustomInput
                                    label="Reason If No Receipt"
                                    type="text"
                                    name='reasonIfNoReciept'
                                    value={expenditureDetails.reasonIfNoReciept || ""}
                                    onChange={handleInputChange}
                                />

                                <CustomInput
                                    label="ReceiptFile"
                                    type="file"
                                    accept=".jpg, .jpeg, .png"
                                    onChange={handleFileChange}
                                />
                            </div>

                            <div className="mx-auto text-center mt-3">
                                <button
                                    type="submit"
                                    disabled={loading}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                      font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                      dark:focus:ring-primary"
                                >
                                    {loading ? <Spinner /> : "Update"}
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>

            <Modal
                open={deleteModal}
                onClose={() => setDeleteModal(false)}
                role="alertdialog"
                backdrop="static"
                autoFocus
                size={"xs"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Delete Expenditure</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <p className="mb-4 font-bold">
                            Are you sure you want to delete this Expenditure?
                        </p>
                        <div className="flex justify-center items-center space-x-4">
                            <Button
                                onClick={() => setDeleteModal(false)}
                                className="bg-gray-500 text-white"
                            >
                                No, cancel
                            </Button>
                            <Button
                                onClick={handleDeleteSchedule}
                                className="bg-red-500 text-white hover:bg-red-700 hover:text-white"
                            >
                                {loading ? <Spinner /> : "Yes, I'm sure"}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>

            <Modal
                open={receiptModal}
                onClose={() => setReceiptModal(false)}
                backdrop="static"
                autoFocus
                size={"sm"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Receipt</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <div >
                            <div className='font-bold text-black'>
                                <img
                                    src={expenditureDetails.receiptUrl}
                                    alt="Signature"
                                    className="block border rounded border-gray-300 bg-gray-200 p-1 overflow-hidden"

                                />
                            </div>
                        </div>
                    </div>
                    {/* )} */}
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        </div>
    );
};


export default ParticipantExpenditure;
