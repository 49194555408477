import React, { FormEvent, useRef } from "react";
import Hero from "./__component/Hero";
import PrintComponent from "../PrintTab";
import { CompanyDetail } from "../../types/AuthData";
import { Client } from "../../store/slices/clientSlice";
import { SkillDevPlan } from "../../types/DataType";
import EditSkillForm from "./__component/EditSkillForm";
import Spinner from "../../Components/Spinner";
import EditDate from "./__component/EditDate";

interface Props {
  companyOne: CompanyDetail | null | undefined;
  clientDetail: Client | null;
  participantSkillDevForm: SkillDevPlan;
  formData: {
    goals: string[];
    supports: string[];
    deadlines: string[];
    completionDates: string[];
    workingOnIt: boolean[];
    canDo: boolean[];
    comments: string[];
  };
  handleTextAreaEditChange: (
    value: string,
    index: number,
    field:
      | "comments"
      | "workingOnIt"
      | "canDo"
      | "completionDates"
      | "deadlines"
      | "goals"
      | "supports"
  ) => void;
  handleCheckboxEditChange: (
    checked: boolean,
    index: number,
    field: "workingOnIt" | "canDo"
  ) => void;
  handleDateEditChange: (value: string, index: number) => void;
  handleEditSubmit: (e: FormEvent) => Promise<void>;
  loading: boolean;
  handleInputDataChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
}
const EditSkillDevelopment = ({
  clientDetail,
  companyOne,
  participantSkillDevForm,
  formData,
  handleCheckboxEditChange,
  handleDateEditChange,
  handleTextAreaEditChange,
  handleEditSubmit,
  loading,
  handleInputDataChange,
}: Props) => {
  const componentRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <form onSubmit={handleEditSubmit}>
        <PrintComponent
          componentRef={componentRef}
          triggerLabel="Print / Download"
          documentTitle="Skill Development Form"
        />
        <div
          ref={componentRef}
          className="mx-auto  max-w-4xl p-4 md:p-4 lg:p-8 bg-white"
        >
          <Hero clientDetail={clientDetail} companyOne={companyOne} />
          <div>
            <div className="flex items-center h-10 bg-gray-200  font-bold divide-x divide-gray-300">
              <div className="text-lg px-6">Daily care or life skill goal </div>
              <div className="text-lg px-6">I want to: </div>
            </div>
            <div className="flex items-center justify-between h-8 bg-gray-100  font-bold divide-x divide-gray-400">
              <div className=" px-2">
                Steps I need to take to achieve my goal
              </div>
              <div className=" px-2">Progress on achieving goal</div>
              <div className=" px-2">Comments on progress</div>
            </div>
            <EditSkillForm
              participantSkillDevForm={participantSkillDevForm}
              formData={formData}
              handleCheckboxEditChange={handleCheckboxEditChange}
              handleDateEditChange={handleDateEditChange}
              handleTextAreaEditChange={handleTextAreaEditChange}
            />
            <EditDate
              handleInputDataChange={handleInputDataChange}
              participantSkillDevForm={participantSkillDevForm}
            />
          </div>
        </div>
        <div className="mx-auto text-center mt-3 lg:col-span-2">
          <button
            type="submit"
            disabled={loading}
            className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"
          >
            {loading ? <Spinner /> : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditSkillDevelopment;
