import React from 'react'
import { Button, Modal } from 'rsuite'

interface Props {
    secretIDModal: boolean
    setSecretIDModal: React.Dispatch<React.SetStateAction<boolean>>
    clientIDModal: boolean
    setClientIDModal: React.Dispatch<React.SetStateAction<boolean>>
    scopeModal: boolean
    setScopeModal: React.Dispatch<React.SetStateAction<boolean>>
    responseModal: boolean
    setResponseModal: React.Dispatch<React.SetStateAction<boolean>>
}

const SetupModals = ({ secretIDModal, setSecretIDModal, clientIDModal, setClientIDModal, scopeModal, setScopeModal, responseModal, setResponseModal }: Props) => {


    return (
        <>


            <Modal open={secretIDModal} onClose={() => setSecretIDModal(false)} backdrop="static" >
                <Modal.Header>
                    <Modal.Title className='font-bold'>What is Secret ID during Xero Integration</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='indent-8'>
                        In the context of Xero integration, a secret ID is typically a unique identifier or token used for authentication and authorization purposes. This secret ID is generated by Xero and provided to the application or service that is integrating with Xero's API (Application Programming Interface).
                        When setting up an integration with Xero, you may be required to provide this secret ID along with other credentials to authenticate your application or service and establish a secure connection with Xero's API. The secret ID helps verify the identity of your application or service and ensures that only authorized access is granted to Xero's data and functionalities.
                    </p>

                </Modal.Body>
                <Modal.Footer>

                    <Button onClick={() => setSecretIDModal(false)} className='bg-red-500 text-white'>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal open={clientIDModal} onClose={() => setClientIDModal(false)} backdrop="static" >
                <Modal.Header>
                    <Modal.Title className='font-bold'>What is Client ID during Xero Integration</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='indent-8'>
                        In Xero integration, a client ID is a unique identifier assigned to the application or service that is integrating with Xero's API (Application Programming Interface).
                        When setting up an integration with Xero, developers typically register their application or service on the Xero Developer Platform to obtain a client ID. This client ID is then used as part of the authentication process when the application or service requests access to Xero's data and functionalities.
                        The client ID serves as a way for Xero to identify the integrating application or service and ensure that only authorized applications are allowed to connect to Xero's API. It is an important component of the OAuth 2.0 authentication process used by Xero to securely authenticate and authorize access to its API.
                    </p>

                </Modal.Body>
                <Modal.Footer>

                    <Button onClick={() => setClientIDModal(false)} className='bg-red-500 text-white'>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Scope Modal */}
            <Modal open={scopeModal} onClose={() => setScopeModal(false)} backdrop="static" >
                <Modal.Header>
                    <Modal.Title className='font-bold'>What is a scope during Xero Integration</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='indent-8'>
                        In the context of Xero integration, "scope" refers to the specific permissions or access levels that an application or service requests when connecting to Xero's API (Application Programming Interface).
                        When setting up an integration with Xero, the application or service may specify the scope of access it requires to interact with Xero's data and functionalities. This could include permissions to read, write, or modify certain types of data, such as invoices, contacts, or bank transactions.
                        By defining the scope of access, Xero ensures that the integration only has access to the data and functionalities necessary for its intended purpose, while also maintaining security and protecting the privacy of Xero users' data. Users typically authorize the requested scope of access during the integration setup process to grant the necessary permissions to the integrating application or service. </p>

                </Modal.Body>
                <Modal.Footer>

                    <Button onClick={() => setScopeModal(false)} className='bg-red-500 text-white'>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Response URL Modal */}
            <Modal open={responseModal} onClose={() => setResponseModal(false)} backdrop="static" >
                <Modal.Header>
                    <Modal.Title className='font-bold'>What is a Response URL during Xero Integration</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='indent-8'>
                        In Xero integration, a response URL, also known as a redirect URI or callback URL, is a specific web address where Xero redirects the user after they have authorized the integration.
                        During the authentication process, when a user grants permission for an application to access their Xero data, Xero redirects the user back to the response URL provided by the application. This redirect includes an authorization code or access token, which the application can then use to complete the authentication process and establish a secure connection with Xero's API.
                        The response URL is specified by the application during the integration setup process and must be registered with Xero to ensure security and prevent unauthorized access. It typically points to a specific endpoint or route within the application's server, where the authentication code or token can be processed and exchanged for access to Xero's data and functionalities.
                    </p>
                </Modal.Body>
                <Modal.Footer>

                    <Button onClick={() => setResponseModal(false)} className='bg-red-500 text-white'>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    )
}

export default SetupModals;