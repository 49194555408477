import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import { Area, Point } from "react-easy-crop/types";
import { Modal } from "rsuite";
import getCroppedImg from "./utils/cropImage"; // A utility function to get the cropped image blob

const CropTest: React.FC = () => {
  const [isFileModalOpen, setFileModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [croppedImage, setCroppedImage] = useState<Blob | null>(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setSelectedFile(reader.result as string);
        setFileModalOpen(true);
      };
    }
  };

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const handleSave = async () => {
    if (selectedFile && croppedAreaPixels) {
      const croppedImageBlob = await getCroppedImg(
        selectedFile,
        croppedAreaPixels
      );
      setCroppedImage(croppedImageBlob);
    }
  };

  const submitData = async () => {
    try {
      setLoading(true);
      if (croppedImage) {
        const formData = new FormData();
        formData.append("file", croppedImage, "cropped_image.jpg");

        // Replace with your endpoint
        const response = await fetch("/your-endpoint", {
          method: "POST",
          body: formData,
        });

        const data = await response.json();
        console.log(data);
      }
      setLoading(false);
      setFileModalOpen(false);
    } catch (error) {
      console.error("Error uploading image:", error);
      setLoading(false);
    }
  };

  return (
    <div>
      <input
        type="file"
        accept=".pdf,.doc,.docx,.jpg,.jpeg"
        onChange={handleFileChange}
        required
      />

      <Modal
        open={isFileModalOpen}
        onClose={() => setFileModalOpen(false)}
        backdrop="static"
        autoFocus
        size="xs"
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedFile && !croppedImage && (
            <div className="crop-container">
              <Cropper
                image={selectedFile}
                crop={crop}
                zoom={zoom}
                aspect={4 / 3}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
          )}
          {selectedFile && !croppedImage && (
            <div className="controls">
              <input
                type="range"
                min="1"
                max="3"
                step="0.1"
                value={zoom}
                onChange={(e) => setZoom(Number(e.target.value))}
                className="slider"
              />
            </div>
          )}
          {croppedImage && (
            <div className="cropped-image-preview">
              <img
                src={URL.createObjectURL(croppedImage)}
                alt="Cropped Preview"
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!croppedImage ? (
            <button
              type="button"
              onClick={handleSave}
              className="text-white bg-primary hover:bg-primary disabled:bg-gray-500 font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2"
            >
              Save
            </button>
          ) : (
            <button
              type="button"
              onClick={submitData}
              disabled={loading}
              className="text-white bg-primary hover:bg-primary disabled:bg-gray-500 font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2"
            >
              {loading ? "Loading..." : "Upload"}
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CropTest;
