import React, {
    ChangeEvent,
    FormEvent,
    useEffect,
    useState,
} from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import DataTable, {
    TableColumn,
    ExpanderComponentProps,
} from "react-data-table-component";
import { FaEye, FaFileCsv, FaFileExcel, FaFilePdf, FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import ExportDropdown from "../../../Components/menu/ExportDropdown";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import Spinner from "../../../Components/Spinner";
import axiosInstance from "../../../store/axiosInstance";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import CustomInput from "../../../Components/Input/CustomInput";
import { Button, Modal, Tooltip, Whisper } from "rsuite";
import IconButton from "../../../Components/Buttons/IconButton";
import {
    downloadCSV,
    exportToExcel,
    handlePDFDownload,
} from "../../../services/reusableFunc";
import { Column } from "../../../types/DataType";
import { fetchStaffTraining, StaffTraining, staffTrainingData, staffTrainingLoading } from "../../../store/slices/staffTrainingSlice";
import dayjs from "dayjs";
import { fetchStaff, staffData } from "../../../store/slices/staffSlice";
import CustomSelect from "../../../Components/Input/CustomSelect";



type DataOption = {
    label: string;
    value: string;
};

const StaffTrainingCom = () => {
    const columns: TableColumn<StaffTraining>[] = [
        {
            name: "Staff",
            selector: (row) => row.staff.fullName,
            sortable: true,
        },
        {
            name: "Training",
            selector: (row) => row.training,
            sortable: true,
        },
        {
            name: "Start Date",
            selector: (row) => row.startDate,
            cell: (row) => (
                <span className="font-bold text-start text-gray-500 overflow-hidden">
                    {dayjs(row.startDate).format("D MMM, YYYY")}
                </span>
            ),
        },
        {
            name: "End Date",
            selector: (row) => row.endDate,
            cell: (row) => (
                <span className="font-bold text-start text-gray-500 overflow-hidden">
                    {dayjs(row.endDate).format("D MMM, YYYY")}
                </span>
            ),
        },
        {
            name: "Actions",
            cell: (row) => (
                <span className="flex gap-4">
                    <div>
                        <Whisper
                            placement="top"
                            controlId="control-id-hover"
                            trigger="hover"
                            speaker={<Tooltip>Edit Staff Training</Tooltip>}
                        >
                            <button
                                onClick={() => handleEditClick(row.staffTrainingsId)}
                                className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
                            >
                                <FaRegEdit className="text-base cursor-pointer" />
                            </button>
                        </Whisper>
                    </div>
                    <div>
                        <Whisper
                            placement="auto"
                            controlId="control-id-hover"
                            trigger="hover"
                            speaker={<Tooltip>View Training</Tooltip>}
                        >
                            <button
                                onClick={() => ViewTraining(row.traningCertficateUrl)}
                                className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
                            >
                                <FaEye className="text-base cursor-pointer" />
                            </button>
                        </Whisper>
                    </div>
                    <div>
                        <Whisper
                            placement="top"
                            controlId="control-id-hover"
                            trigger="hover"
                            speaker={<Tooltip>Delete Training</Tooltip>}
                        >
                            <button
                                onClick={() => delPolicy(row.staffTrainingsId)}
                                className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
                            >
                                <FaRegTrashAlt className="text-base text-red-500" />
                            </button>
                        </Whisper>
                    </div>
                </span>
            ),
        },
    ];

    const [loading1, setLoading1] = useState<boolean>(false);
    const [addUserModal, setAddUserModal] = useState<boolean>(false);
    const [editModal, setEditModal] = useState<boolean>(false);
    const [details, setDetails] = useState<StaffTraining>({
        companyId: 0,
        dateCreated: "",
        dateModified: "",
        endDate: "",
        others: "",
        report: "",
        staff: {
            fullName: "",
        },
        staffId: 0,
        staffTrainingsId: 0,
        startDate: "",
        training: "",
        traningCertficateUrl: "",
        traningCertficateUrlFile: "",
        userCreated: "",
        userModified: "",
    });
    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    const companyId = user?.companyId;
    useEffect(() => {
        if (companyId) {
            dispatch(fetchStaffTraining(companyId));
            dispatch(fetchStaff(companyId));
        }
    }, [dispatch, companyId]);
    const staffTrain = useSelector(staffTrainingData);
    const loading = useSelector(staffTrainingLoading);
    const staff = useSelector(staffData);

    const [reason, setReason] = useState<string>("");
    const [selectedTraining, setSelectedTraining] = useState("");
    const [showCustomTraining, setShowCustomTraining] = useState<boolean>(false);

    // Handle option change
    const handleTrainingChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        if (value === "Others") {
            setShowCustomTraining(true)
            setSelectedTraining("");
        } else {
            setSelectedTraining(value)
            setShowCustomTraining(false)
        }

    };



    const [saveIds, setSaveIds] = useState<number>(0);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [docModal, setDocModal] = useState<boolean>(false);
    const [docUrlModal, setDocUrlModal] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [users, setUsers] = useState<string>("");
    const [dateFrom, setDateFrom] = useState<string>("");
    const [dateTo, setDateTo] = useState<string>("");
    interface Props extends ExpanderComponentProps<StaffTraining> {
        // currently, props that extend ExpanderComponentProps must be set to optional.
        someTitleProp?: string;
    }

    const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
        return (
            <div className="p-2 flex flex-col gap-2 text-xs">
                <div>
                    <span className="font-bold">Training: </span> {data.training}
                </div>
            </div>
        );
    };

    const handleEditClick = async (id: number) => {
        setEditModal(true);
        try {
            const { data } = await axiosInstance.get(
                `/StaffTrainings/details/${id}`
            );
            setDetails({ ...data });
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // console.log(error);;
            } else {
                toast.error("An error occurred");
            }
        }
    };

    const staffList = staff.map((item) => ({
        label: item.fullName,
        value: item.staffId,
    }));

    const ViewTraining = (documentUrl: string): void => {
        setDocUrlModal(documentUrl); // Set the document URL in the state
        setIsLoading(true); // Start loading spinner
        setDocModal(true); // Open the modal
    };


    const handleInputChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        const { name, value } = e.target;
        setDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const dataOption: DataOption[] = [
        'NDIS Quality and Safeguards Commission – Quality, Safety and You',
        'National Disability Services (NDS) – Human Rights and You eLearning Program',
        'NDS – Understanding Abuse eLearning Program',
        'La Trobe University – Support for Decision Making',
        'Australian Commission on Safety and Quality in Health Care – Infection Prevention and Control eLearning',
        'Department of Health – COVID-19 infection control training',
        'NDS – Infection prevention and control',
        'NDS – Manual Handling for Disability Support Workers',
        'Mental health – MHPOD',
        'Others',
    ].map((item) => ({ label: item, value: item }));

    const [trainingDoc, setTrainingDoc] = useState<File | null>(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setTrainingDoc(file);
        }
    };

    const [searchText, setSearchText] = useState<string>("");

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const filteredData = staffTrain?.filter(
        (item) =>
            item?.staff.fullName?.toLowerCase().includes(searchText.toLowerCase()) ||
            item?.endDate?.toLowerCase().includes(searchText.toLowerCase()) ||
            item?.startDate?.toLowerCase().includes(searchText.toLowerCase())
    );

    const getFileExtension = (url: string) => {
        return url.split('.').pop()?.toLowerCase(); // Get the file extension
    };

    const HandleEdit = async (e: FormEvent) => {
        e.preventDefault();
        setLoading1(true);

        const formData = new FormData();

        trainingDoc && formData.append("TraningCertficateUrlFile", trainingDoc);

        for (const key in details) {
            const value = details[key as keyof typeof details];
            if (value === null) {
                formData.append(key, "");
            } else {
                formData.append(key, value.toString());
            }
        }

        try {
            const { data } = await axiosInstance.post(
                `/StaffTrainings/edit/${details.staffTrainingsId}?userId=${user?.uid}`,
                formData
            );
            toast.success(data.message);
            companyId && dispatch(fetchStaffTraining(companyId));
            setEditModal(false);
            setLoading1(false);
        } catch (error: unknown) {
            console.log(error)
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // console.log(error);;
            } else {
                toast.error("An error occurred");
            }
            setLoading1(false);
        }
    };



    const delPolicy = (id: number) => {
        setSaveIds(id);
        setDeleteModal(true);
    };

    const handleDelete = async (e: FormEvent) => {
        e.preventDefault();
        setLoading1(true);
        try {
            const { data } = await axiosInstance.post(
                `/StaffTrainings/delete/${saveIds}?userId=${user?.uid}`
            );
            toast.success(data.message);
            setDeleteModal(false);
            companyId && dispatch(fetchStaffTraining(companyId));
            setLoading1(false);
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // console.log(error);;
            } else {
                toast.error("An error occurred");
            }
            setLoading1(false);
            setDeleteModal(false);
        } finally {
            setLoading1(false);
        }
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (
            selectedTraining === "" ||
            dateFrom === "" ||
            dateTo === ""
        ) {
            return toast.error("Marked Fields must be filled");
        }

        setLoading1(true);

        const formData = new FormData();
        formData.append("CompanyId", companyId?.toString() ?? "");
        formData.append("Training", selectedTraining);
        formData.append("StartDate", dateFrom);
        formData.append("EndDate", dateTo);
        formData.append("StaffId", users);
        formData.append("Report", reason);
        trainingDoc && formData.append("TraningCertficateUrlFile", trainingDoc);

        try {
            const { data } = await axiosInstance.post(
                `/StaffTrainings/add_training?userId=${user?.uid}`,
                formData
            );
            toast.success(data.message);
            setLoading1(false);
            companyId && dispatch(fetchStaffTraining(companyId));
            setAddUserModal(false);
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // console.log(error);;
            } else {
                toast.error("An error occurred");
            }
            setLoading1(false);
            // setAddUserModal(false);
        } finally {
            setLoading1(false);
        }
    };

    return (
        <>
            <SEO
                title="Staff Trainings"
                description="Staff Training"
                name="Promax-Care"
            />
            <Breadcrumb />
            <div className="mt-10">
                {staffTrain.length <= 0 && loading && <Spinner color="#071952" />}
                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
                    <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
                        <h2 className="font-bold text-xl text-black w-auto">
                            Staff Training
                        </h2>

                        <div className="relative w-full lg:col-span-2">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <BsSearch />
                            </div>
                            <input
                                type="text"
                                id="simple-search"
                                onChange={handleSearch}
                                className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                placeholder="Search By Staff or Start Date or End Date"
                            />
                        </div>
                    </div>
                    <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                        <div className="flex items-center space-x-3 w-full md:w-auto">
                            {/* <button id="actionsDropdownButton" className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                                  <FaFilter />
                                  Filter
                              </button> */}
                            <ExportDropdown>
                                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                    <IconButton
                                        icon={<FaFilePdf className="text-red-500" />}
                                        title="Export as PDF"
                                        onClick={() =>
                                            handlePDFDownload(
                                                staffTrain,
                                                columns as unknown as Column<StaffTraining>[],
                                                "staffTraining.pdf"
                                            )
                                        }
                                    />
                                    <IconButton
                                        icon={<FaFileExcel className="text-green-600" />}
                                        title="Export as Excel Sheet"
                                        onClick={() =>
                                            exportToExcel(
                                                staffTrain,
                                                columns as unknown as Column<StaffTraining>[],
                                                "staffTraining"
                                            )
                                        }
                                    />
                                    <IconButton
                                        icon={<FaFileCsv className="text-green-500" />}
                                        title="Export as CSV"
                                        onClick={() =>
                                            downloadCSV({
                                                data: staffTrain,
                                                filename: "staffTraining.csv",
                                            })
                                        }
                                    />
                                </div>
                            </ExportDropdown>
                        </div>
                        <button
                            type="button"
                            onClick={() => setAddUserModal(true)}
                            className="px-4 py-2 bg-primary text-white  disabled:bg-slate-400
                              transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm 
                             "
                        >
                            Add Training
                        </button>
                    </div>
                </div>

                <DataTable
                    columns={columns}
                    data={filteredData}
                    expandableRows
                    expandableRowsComponent={ExpandableRowComponent}
                    pagination
                    paginationTotalRows={staffTrain?.length}
                // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}
                />
            </div>

            <Modal
                open={docModal}
                onClose={() => setDocModal(false)}
                backdrop="static"
                autoFocus
                size={"lg"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">View Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        {isLoading && (
                            <div className="text-center">
                                <Spinner /> {/* Show spinner while iframe is loading */}
                                <p>Loading document...</p>
                            </div>
                        )}

                        {getFileExtension(docUrlModal) === 'pdf' ? (
                            <iframe
                                src={docUrlModal}
                                width="100%"
                                height="500px"
                                title="PDF Viewer"
                                style={{ display: isLoading ? "none" : "block" }} // Hide iframe until loaded
                                onLoad={() => setIsLoading(false)} // Stop loading spinner when iframe loads
                            />
                        ) : (
                            <iframe
                                src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(docUrlModal)}`}
                                width="100%"
                                height="500px"
                                title="Office Online Viewer"
                                style={{ display: isLoading ? "none" : "block" }} // Hide iframe until loaded
                                onLoad={() => setIsLoading(false)} // Stop loading spinner when iframe loads
                            />
                        )}
                    </>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setDocModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                open={deleteModal}
                onClose={() => setDeleteModal(false)}
                role="alertdialog"
                backdrop="static"
                autoFocus
                size={"xs"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Delete Training</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <p className="mb-4 font-bold">
                            Are you sure you want to delete this training?
                        </p>
                        <div className="flex justify-center items-center space-x-4">
                            <Button
                                onClick={handleDelete}
                                className="bg-red-500 text-white hover:bg-red-700 hover:text-white"
                            >
                                {loading1 ? <Spinner /> : "Yes, I'm sure"}
                            </Button>
                            <Button
                                onClick={() => setDeleteModal(false)}
                                className="bg-gray-500 text-white"
                            >
                                No, cancel
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>

            <Modal
                open={addUserModal}
                onClose={() => setAddUserModal(false)}
                backdrop="static"
                autoFocus
                size={"lg"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Add Staff Training</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form action="" onSubmit={handleSubmit}>
                            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                                <CustomSelect
                                    label="Select Staffs"
                                    options={staffList}
                                    onChange={(e) => setUsers(e.target.value)}
                                    firstOption={"All Staff"}
                                    value={users}
                                />

                                <CustomInput
                                    label="Training File"
                                    type="file"
                                    onChange={handleFileChange}
                                />

                                <CustomInput
                                    label="Start Date"
                                    type="date"
                                    name="dateFrom"
                                    onChange={(e) => setDateFrom(e.target.value)}
                                />
                                <CustomInput
                                    label="End Date"
                                    type="date"
                                    name="dateTo"
                                    onChange={(e) => setDateTo(e.target.value)}
                                />

                                <div className="col-span-2">
                                    <CustomSelect
                                        label="Select Training"
                                        options={dataOption}
                                        onChange={handleTrainingChange}
                                    />
                                </div>

                                {/* Conditionally render the "Other" input field */}
                                {showCustomTraining && (
                                    <div className="col-span-2">
                                        <CustomInput
                                            label="Other Training"
                                            type="text"
                                            value={selectedTraining}
                                            onChange={(e) => setSelectedTraining(e.target.value)}
                                        />
                                    </div>
                                )}
                                <div className="col-span-2">
                                    <label htmlFor="report" className="font-bold">
                                        Report{" "}
                                    </label>
                                    <textarea
                                        id="report"
                                        rows={3}
                                        autoComplete="off"
                                        onChange={(e) => setReason(e.target.value)}
                                        className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                        defaultValue={""}
                                    />
                                </div>
                            </div>

                            <div className="mx-auto text-center mt-3">
                                <button
                                    type="submit"
                                    disabled={loading1}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                          font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                          dark:focus:ring-primary"
                                >
                                    {loading1 ? <Spinner /> : "Create"}
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>


            <Modal
                open={editModal}
                onClose={() => setEditModal(false)}
                backdrop="static"
                autoFocus
                size={"lg"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">
                        Edit Staff Training Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form action="" onSubmit={HandleEdit}>
                            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                                <CustomSelect
                                    label="Select Staffs"
                                    options={staffList}
                                    onChange={(e) => setUsers(e.target.value)}
                                    firstOption={"All Staff"}
                                    value={details.staffId}
                                />

                                <CustomInput
                                    label="Training File"
                                    type="file"
                                    onChange={handleFileChange}
                                />

                                <CustomInput
                                    label="Start Date"
                                    type="datetime-local"
                                    name="startDate"
                                    value={details.startDate}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="End Date"
                                    type="datetime-local"
                                    name="endDate"
                                    value={details.endDate}
                                    onChange={handleInputChange}
                                />

                                <div className="col-span-2">
                                    <CustomSelect
                                        label="Select Training"
                                        options={dataOption}
                                        onChange={handleTrainingChange}
                                        firstOption={details.training}
                                    />
                                </div>

                                {/* Conditionally render the "Other" input field */}
                                {showCustomTraining && (
                                    <div className="col-span-2">
                                        <CustomInput
                                            label="Other Training"
                                            type="text"
                                            value={selectedTraining}
                                            onChange={(e) => setSelectedTraining(e.target.value)}
                                        />
                                    </div>
                                )}

                                <div className="col-span-2">
                                    <label htmlFor="report" className="font-bold">
                                        Report{" "}
                                    </label>
                                    <textarea
                                        id="report"
                                        rows={3}
                                        autoComplete="off"
                                        name="report"
                                        className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                        value={details.report}
                                        onChange={handleInputChange}
                                    />
                                </div>

                            </div>



                            <div className="mx-auto text-center mt-3">
                                <button
                                    type="submit"
                                    disabled={loading1}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
  font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
  dark:focus:ring-primary"
                                >
                                    {loading1 ? <Spinner /> : "Update"}
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        </>
    );
};

export default StaffTrainingCom;
