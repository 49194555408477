// import React from 'react'
// import { CompanyDetail } from '../../../types/AuthData';

// interface Props {
//     companyOne: CompanyDetail | null | undefined;
// }

// const Section2: React.FC<Props> = ({ companyOne }) => {
//     return (
//         <div className=" p-4 max-[1024px]:w-[100%]  my-3 border border-black ">
//             <strong>Services and community access</strong>
//             <div className='my-6'>
//                 <p>I give my consent for the participant named above to attend services provided by Insert name. I understand that a degree of risk is involved with the provision of support. I understand that while staff will take the utmost care, they will not accept any personal responsibility for any personal illness or accident nor accept any responsibility for loss/damage of property that may occur:</p>
//                 <div className='flex my-2 items-center gap-20'>
//                     <p className='flex items-center gap-2'>
//                         <input id='ye' type="checkbox" />
//                         <label htmlFor="ye">Yes</label>
//                     </p>
//                     <p className='flex items-center gap-2'>
//                         <input id='nop' type="checkbox" />
//                         <label htmlFor="nop">No</label>
//                     </p>
//                 </div>
//             </div>

//             <div className='my-6'>
//                 <p>I give consent for {companyOne?.companyName} to obtain medical treatment for the participant in the event of any emergency:</p>
//                 <div className='flex my-2 items-center gap-20'>
//                     <p className='flex items-center gap-2'>
//                         <input id='ye' type="checkbox" />
//                         <label htmlFor="ye">Yes</label>
//                     </p>
//                     <p className='flex items-center gap-2'>
//                         <input id='nop' type="checkbox" />
//                         <label htmlFor="nop">No</label>
//                     </p>
//                 </div>
//             </div>

//             <div className='my-6'>
//                 <p>I give consent for the participant to travel in the worker’s insured vehicle, Maxicare Plus owned vehicles as well as public transport alternatives:</p>
//                 <div className='flex my-2 items-center gap-20'>
//                     <p className='flex items-center gap-2'>
//                         <input id='ye' type="checkbox" />
//                         <label htmlFor="ye">Yes</label>
//                     </p>
//                     <p className='flex items-center gap-2'>
//                         <input id='nop' type="checkbox" />
//                         <label htmlFor="nop">No</label>
//                     </p>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default Section2

import React from 'react';
import { CompanyDetail } from '../../../types/AuthData';

interface Props {
    companyOne: CompanyDetail | null | undefined;
    formDataYesNo: {
        [key: string]: boolean;
    };
    handleRadioChangeYesNo: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Section2: React.FC<Props> = ({ companyOne, formDataYesNo, handleRadioChangeYesNo }) => {
    return (
        <div className="p-4 max-[1024px]:w-[100%] my-3 border border-black">
            <strong>Services and community access</strong>
            <div className='my-6'>
                <p>I give my consent for the participant named above to attend services provided by Insert name. I understand that a degree of risk is involved with the provision of support. I understand that while staff will take the utmost care, they will not accept any personal responsibility for any personal illness or accident nor accept any responsibility for loss/damage of property that may occur:</p>
                <div className='flex my-2 items-center gap-20'>
                    <p className='flex items-center gap-2'>
                        <input
                            type="radio"
                            id="serviceYes"
                            name="serviceConsent"
                            value="Yes"
                            checked={formDataYesNo.serviceConsent === true}
                            onChange={handleRadioChangeYesNo}
                        />
                        <label htmlFor="serviceYes">Yes</label>
                    </p>
                    <p className='flex items-center gap-2'>
                        <input
                            type="radio"
                            id="serviceNo"
                            name="serviceConsent"
                            value="No"
                            checked={formDataYesNo.serviceConsent === false}
                            onChange={handleRadioChangeYesNo}
                        />
                        <label htmlFor="serviceNo">No</label>
                    </p>
                </div>
            </div>

            <div className='my-6'>
                <p>I give consent for {companyOne?.companyName} to obtain medical treatment for the participant in the event of any emergency:</p>
                <div className='flex my-2 items-center gap-20'>
                    <p className='flex items-center gap-2'>
                        <input
                            type="radio"
                            id="medicalYes"
                            name="medicalConsent"
                            value="Yes"
                            checked={formDataYesNo.medicalConsent === true}
                            onChange={handleRadioChangeYesNo}
                        />
                        <label htmlFor="medicalYes">Yes</label>
                    </p>
                    <p className='flex items-center gap-2'>
                        <input
                            type="radio"
                            id="medicalNo"
                            name="medicalConsent"
                            value="No"
                            checked={formDataYesNo.medicalConsent === false}
                            onChange={handleRadioChangeYesNo}
                        />
                        <label htmlFor="medicalNo">No</label>
                    </p>
                </div>
            </div>

            <div className='my-6'>
                <p>I give consent for the participant to travel in the worker’s insured vehicle, Maxicare Plus owned vehicles as well as public transport alternatives:</p>
                <div className='flex my-2 items-center gap-20'>
                    <p className='flex items-center gap-2'>
                        <input
                            type="radio"
                            id="travelYes"
                            name="travelConsent"
                            value="Yes"
                            checked={formDataYesNo.travelConsent === true}
                            onChange={handleRadioChangeYesNo}
                        />
                        <label htmlFor="travelYes">Yes</label>
                    </p>
                    <p className='flex items-center gap-2'>
                        <input
                            type="radio"
                            id="travelNo"
                            name="travelConsent"
                            value="No"
                            checked={formDataYesNo.travelConsent === false}
                            onChange={handleRadioChangeYesNo}
                        />
                        <label htmlFor="travelNo">No</label>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Section2;

