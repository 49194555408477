import React from 'react';
import { SupportPlaData } from '../../../types/DataType';
import EditOthersForm from './EditOthersForm';

interface Props {
    supportForm: SupportPlaData;
    handleInputFormChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
}

const EditRisk: React.FC<Props> = ({ supportForm, handleInputFormChange }) => {
    return (
        <div className="p-4">
            <table className="w-full border-collapse mb-4">
                <tbody>
                    <tr className="bg-gray-200 font-semibold">
                        <td className="border p-2">Risk area</td>
                        <td className="border p-2">Strategy to manage the risk</td>
                    </tr>
                    {[...Array(5)].map((_, index) => {
                        const riskFieldName = `risk${index + 1}` as keyof SupportPlaData;
                        const strategyFieldName = `strategiesToManageRisk${index + 1}` as keyof SupportPlaData;

                        return (
                            <tr key={index} className="divide-x-2">
                                <td className="whitespace-nowrap border p-2 text-gray-700 w-[40%]">
                                    <input
                                        type="text"
                                        name={riskFieldName}
                                        value={supportForm[riskFieldName] as string || ''}
                                        onChange={handleInputFormChange}
                                        className="border-none text-gray-900 text-sm outline-none block w-full bg-white h-full px-2.5"
                                    />
                                </td>
                                <td className="whitespace-nowrap border p-2 text-gray-700 w-full">
                                    <input
                                        type="text"
                                        name={strategyFieldName}
                                        value={supportForm[strategyFieldName] as string || ''}
                                        onChange={handleInputFormChange}
                                        className="border-none text-gray-900 text-sm outline-none block w-full bg-white h-full px-2.5"
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>


            <EditOthersForm handleInputFormChange={handleInputFormChange} supportForm={supportForm} />

        </div>
    );
};

export default EditRisk;
