import React, { FormEvent, useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { BsSearch } from "react-icons/bs";
import { useSelector } from "react-redux";
import {
  FaFileCsv,
  FaFileExcel,
  FaFilePdf,
  FaRegFileAlt,
  FaRegTrashAlt,
} from "react-icons/fa";
import { Button, IconButton, Modal, Tooltip, Whisper } from "rsuite";
import Breadcrumb from "../../../../../Components/label/BreadCrumb";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../../../store/store";
import SEO from "../../../../../constants/SEO";
import Spinner from "../../../../../Components/Spinner";
import ExportDropdown from "../../../../../Components/menu/ExportDropdown";
import {
  downloadCSV,
  exportToExcel,
  handlePDFDownload,
} from "../../../../../services/reusableFunc";
import { Column } from "../../../../../types/DataType";
import {
  Ticket,
  fetchTicket,
  ticketData,
  ticketLoading,
} from "../../../../../store/slices/ticketSlice";
import axiosInstance from "../../../../../store/axiosInstance";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

const ViewTicket = () => {
  const columns: TableColumn<Ticket>[] = [
    // {
    //     name: '',
    //     // selector: row => row.itemNumber,
    //     cell: (row) => (
    //         <div className='flex justify-center items-center w-full'>

    //             <button
    //                 className="w-full md:w-auto flex items-center justify-center py-1 px-3 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button"

    //                 onClick={() => EditDetail(row.progressNoteId)}>
    //                 view
    //             </button>
    //         </div>
    //     )
    // },
    {
      name: "Subject",
      selector: (row) => row.subject,
      sortable: true,
      // cell: (row) => <Link
      //     to={`/app/support/ticket-details/${row.ticketId}`}
      //     className="font-bold text-dark" style={{ overflow: "hidden" }}
      // > {row.subject}</Link>
    },
    {
      name: "User",
      selector: (row) => row.user,
      sortable: true,
    },
    {
      name: "Date Created",
      selector: (row) => dayjs(row.dateCreated).format("D MMMM, YYYY h:mm A"),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.isOpen,
      sortable: true,
      cell: (row) => (
        <span
          className={`${
            row.isOpen ? "bg-secondary" : "bg-primary text-white"
          } px-4 py-1 rounded font-bold`}
        >
          {row.isOpen ? "open" : "closed"}
        </span>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-2">
          {/* <Link
                        to={`/app/support/ticket-details/${row.ticketId}`}
                        className='btn'
                        title='Details'

                    >
                        <FaRegFileAlt />
                    </Link> */}
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Details</Tooltip>}
          >
            <Link
              to={`/app/communication/view-ticket/${row.ticketId}`}
              className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
            >
              <FaRegFileAlt />
            </Link>
          </Whisper>
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Delete</Tooltip>}
          >
            <button
              onClick={() => delTicket(row.ticketId)}
              className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
            >
              <FaRegTrashAlt className="text-red-500" />
            </button>
          </Whisper>
        </div>
      ),
    },
  ];

  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();
  const companyId = user?.companyId;
  useEffect(() => {
    if (companyId) {
      dispatch(fetchTicket(companyId));
    }
  }, [dispatch, companyId]);
  const ticketList = useSelector(ticketData);
  const ticket = ticketList
    .slice()
    .sort(
      (a, b) =>
        new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
    );
  const loadingData = useSelector(ticketLoading);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [saveId, setSaveId] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  // const [searchText, setSearchText] = useState<string>("");
  // const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
  //   setSearchText(event.target.value);
  // };

  // const filteredData = ticket.filter((item) =>
  //   item && item?.subject.toLowerCase().includes(searchText.toLowerCase())
  // );

  const delTicket = (id: number) => {
    setSaveId(id);
    setDeleteModal(true);
  };

  const handleDeleteTicket = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axiosInstance.post(
        `/Tickets/delete/${saveId}?userId=${user?.uid}`
      );
      toast.success(data.message);
      setDeleteModal(false);
      setLoading(false);
      companyId && dispatch(fetchTicket(companyId));
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
      setDeleteModal(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SEO title="View Ticket" description="View Ticket" name="Promax-Care" />
      <Breadcrumb />

      <div className="mt-10">
        {loadingData && <Spinner color="#071952" />}
        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
          <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
            <h2 className="font-bold text-xl text-black w-auto">View Ticket</h2>

            <div className="relative w-full lg:col-span-2">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <BsSearch />
              </div>
              <input
                type="text"
                id="simple-search"
                // onChange={handleSearch}
                className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Search"
              />
            </div>
          </div>
          <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
            <div className="flex items-center space-x-3 w-full md:w-auto">
              <ExportDropdown>
                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <IconButton
                    icon={<FaFilePdf className="text-red-500" />}
                    title="Export as PDF"
                    onClick={() =>
                      handlePDFDownload(
                        ticket,
                        columns as unknown as Column<Ticket>[],
                        "ViewTicket.pdf"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileExcel className="text-green-600" />}
                    title="Export as Excel Sheet"
                    onClick={() =>
                      exportToExcel(
                        ticket,
                        columns as unknown as Column<Ticket>[],
                        "ViewTicket"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileCsv className="text-green-500" />}
                    title="Export as CSV"
                    onClick={() =>
                      downloadCSV({ data: ticket, filename: "ViewTicket.csv" })
                    }
                  />
                </div>
              </ExportDropdown>
            </div>
          </div>
        </div>

        <DataTable
          columns={columns}
          data={ticket}
          pagination
          paginationTotalRows={ticket?.length}
        />
      </div>

      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold text-sm">Delete Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="mb-4 font-bold text-lg">
              Are you sure you want to delete this Ticket ?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <Button
                onClick={handleDeleteTicket}
                className="bg-red-500 text-white hover:bg-red-700 hover:text-white"
              >
                {loading ? <Spinner /> : "Yes, I'm sure"}
              </Button>
              <Button
                onClick={() => setDeleteModal(false)}
                className="bg-gray-500 text-white hover:text-white hover:bg-gray-400"
              >
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewTicket;
