import React, { FC } from "react";

type Props = {
    activeTab: string;
    handleTabChange: (tab: string) => void;
}
const PricingControl: FC<Props> = ({ activeTab, handleTabChange }) => {
    return (
        <div className="flex justify-center">

            <div className="bg-gray-200 px-1 py-1 rounded-md flex items-center gap-2">
                <small className={`${activeTab === "monthly" ? "bg-white  rounded-md" : ""} cursor-pointer p-2`} onClick={() => handleTabChange('monthly')}>Bill Monthly</small>
                <small className={`${activeTab === "yearly" ? "bg-white  rounded-md" : ""} cursor-pointer p-2`} onClick={() => handleTabChange('yearly')}>Bill Yearly </small>
            </div>


        </div>
    )
}

export default PricingControl