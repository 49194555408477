import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import DataTable, {
  TableColumn,
  ExpanderComponentProps,
} from "react-data-table-component";
import {
  FaFileCsv,
  FaFileExcel,
  FaFilePdf,
  FaFilter,
  FaRegEdit,
} from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import ExportDropdown from "../../../Components/menu/ExportDropdown";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import {
  adminData,
  adminLoading,
  fetchAdmin,
  filterAdmin,
} from "../../../store/slices/adminSlice";
import { Link } from "react-router-dom";
import Spinner from "../../../Components/Spinner";
import { InputPicker, Modal, Tooltip, Whisper } from "rsuite";
import {
  downloadCSV,
  exportToExcel,
  handlePDFDownload,
} from "../../../services/reusableFunc";
import { Column } from "../../../types/DataType";
import IconButton from "../../../Components/Buttons/IconButton";
import CreateDropdown from "../../../Components/menu/CreateDropDown";
import { claimsTypes } from "../../../types/AuthData";

interface DataRow {
  administratorId: number;
  firstName: string;
  surName: string;
  middleName: string;
  maxStaffId: string;
  address: string;
  email: string;
  phoneNumber: string;
  postcode: string | null;
  profession: string | null;
  gender: string | null;
  imageUrl: string | null;
  imageFile: string | null;
  country: string | null;
  state: string | null;
  city: string | null;
  isCompleted: boolean;
  isActive: boolean;
  isDocumentUploaded: boolean;
  dateOfBirth: string | null; // You may want to use Date type if it's a date string
  facebook: string | null;
  twitter: string | null;
  instagram: string | null;
  youtube: string | null;
  linkedIn: string | null;
  github: string | null;
  employmentType: string | null;
  taxFile: string | null;
  position: string | null;
  australianCitizen: boolean | null;
  dateJoined: string | null; // You may want to use Date type if it's a date string
  salary: number;
  payDay: number;
  payRate: number | null;
  offerLetter: string | null;
  bankName: string | null;
  bsb: string | null;
  branch: string | null;
  accountName: string | null;
  accountNumber: string | null;
  nextOfKin: string | null;
  relationship: string | null;
  kinPostcode: string | null;
  kinAddress: string | null;
  kinCountry: string | null;
  kinCity: string | null;
  kinEmail: string | null;
  suburb: string | null;
  kinState: string | null;
  kinPhoneNumber: string | null;
  adm_DesignationsId: number | null;
  adm_Designations: string; // You may want to create an interface for this if it has a fixed structure
  fullName: string;
  companyId: number;
  company: string; // You may want to create an interface for this if it has a fixed structure
  dateCreated: string; // You may want to use Date type if it's a date string
  userCreated: string; // You may want to create an interface for this if it has a fixed structure
  dateModified: string; // You may want to use Date type if it's a date string
  userModified: string; // You may want to create an interface for this if it has a fixed structure
}

const Admin = () => {
  const staffClaimsString = localStorage.getItem("claims");
  const claims: claimsTypes[] = staffClaimsString
    ? JSON.parse(staffClaimsString)
    : [];
  const hasRequiredClaims = (claimType: string): boolean => {
    return claims.some((claim) => claim.value === claimType);
  };

  const columns: TableColumn<DataRow>[] = [
    {
      name: "Administrator ID",
      selector: (row) => row.maxStaffId,
      sortable: true,
    },
    {
      name: "Full Name",
      selector: (row) => row.fullName,
      sortable: true,
      cell: (row) => (
        <Link
          style={{ overflow: "hidden" }}
          to={`/app/user/administrators/${row.administratorId}/${row.firstName}`}
          className="font-bold text-black"
        >
          {row.firstName} {row.surName}
        </Link>
      ),
    },
    {
      name: "Address",
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phoneNumber,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-1" style={{ overflow: "hidden" }}>
          {user?.role === "CompanyAdmin" ||
          hasRequiredClaims("Edit Administrator") ? (
            <Whisper
              placement="top"
              controlId="control-id-hover"
              trigger="hover"
              speaker={<Tooltip>Edit Admin</Tooltip>}
            >
              <button className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline">
                <Link
                  to={`/app/user/administrators/${row.administratorId}/${row.firstName}`}
                >
                  <FaRegEdit className="text-base" />
                </Link>
              </button>
            </Whisper>
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];

  const [loading1, setLoading1] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");
  const [filterModal, setFilterModal] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();
  const companyId = user?.companyId;
  useEffect(() => {
    if (companyId) {
      dispatch(fetchAdmin(companyId));
    }
  }, [dispatch, companyId]);
  const admin = useSelector(adminData);
  const loading = useSelector(adminLoading);

  const filterActivity = (e: FormEvent) => {
    e.preventDefault();
    setLoading1(true);

    if (companyId && status) {
      dispatch(
        filterAdmin({
          companyId: companyId,
          status: status,
        })
      );
    }
    setLoading1(false);
    setFilterModal(false);
    setStatus("");
  };

  const resetFilter = (e: FormEvent) => {
    e.preventDefault();
    companyId && dispatch(fetchAdmin(companyId));
    setFilterModal(false);
    setStatus("");
  };

  interface Props extends ExpanderComponentProps<DataRow> {
    // currently, props that extend ExpanderComponentProps must be set to optional.
    someTitleProp?: string;
  }

  const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
    return (
      <div className="p-2 flex flex-col gap-2 text-xs">
        <div>
          <span className="font-bold">Full Name: </span> {data.fullName}
        </div>
        <div>
          <span className="font-bold">Email: </span> {data.email}
        </div>
      </div>
    );
  };

  const [searchText, setSearchText] = useState<string>("");

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredData = admin?.filter(
    (item) =>
      item?.fullName?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.email?.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      <SEO
        title="Administrators"
        description="Administrator"
        name="Promax-Care"
      />
      <Breadcrumb />
      <div className="mt-10">
        {admin?.length <= 0 && loading && <Spinner color="#071952" />}
        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
          <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
            <h2 className="font-bold text-xl text-black w-auto">
              Administrators
            </h2>

            <div className="relative w-full lg:col-span-2">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <BsSearch />
              </div>
              <input
                type="text"
                id="simple-search"
                onChange={handleSearch}
                className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Search By FullName or Email"
              />
            </div>
          </div>
          <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
            <div className="flex items-center space-x-3 w-full md:w-auto">
              <button
                onClick={() => setFilterModal(true)}
                id="actionsDropdownButton"
                className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                type="button"
              >
                <FaFilter />
                Filter
              </button>
              <ExportDropdown>
                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <IconButton
                    icon={<FaFilePdf className="text-red-500" />}
                    title="Export as PDF"
                    onClick={() =>
                      handlePDFDownload(
                        admin,
                        columns as unknown as Column<DataRow>[],
                        "Admin.pdf"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileExcel className="text-green-600" />}
                    title="Export as Excel Sheet"
                    onClick={() =>
                      exportToExcel(
                        admin,
                        columns as unknown as Column<DataRow>[],
                        "Admin"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileCsv className="text-green-500" />}
                    title="Export as CSV"
                    onClick={() =>
                      downloadCSV({ data: admin, filename: "Admin.csv" })
                    }
                  />
                </div>
              </ExportDropdown>
            </div>
            {user?.role === "CompanyAdmin" ||
            hasRequiredClaims("Add Administrator") ? (
              <CreateDropdown text="New Admin">
                <div className="z-[999] w-max rounded bg-white  p-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <Link
                    to={"/app/forms/create-admin"}
                    className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
                  >
                    Create New Admin
                  </Link>
                  <Link
                    to={"/app/forms/upload-admin-via-excel"}
                    className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
                  >
                    Upload Via Excel
                  </Link>
                  <Link
                    to={"/app/forms/upload-admin-via-xero"}
                    className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
                  >
                    Import From Xero
                  </Link>
                </div>
              </CreateDropdown>
            ) : (
              ""
            )}
          </div>
        </div>

        <DataTable
          columns={columns}
          data={filteredData}
          expandableRows
          expandableRowsComponent={ExpandableRowComponent}
          pagination
          paginationTotalRows={admin?.length}
          // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}
        />
      </div>

      <Modal
        open={filterModal}
        onClose={() => setFilterModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form action="" className="space-y-2">
              <div>
                <label className=" text-xs text-gray-600 font-semibold">
                  Select Admin
                </label>
                <InputPicker
                  size="lg"
                  placeholder="--All Admin--"
                  // onChange={value => setUsers(value)}
                  data={admin.map((user) => ({
                    label: user.fullName,
                    value: user.administratorId,
                  }))}
                  className="w-full focus:outline-none focus:border-primary text-gray-600"
                  appearance="subtle"
                />
              </div>

              <div>
                <label className=" text-xs text-gray-600 font-semibold">
                  Select Status
                </label>
                <InputPicker
                  size="lg"
                  placeholder="--Select Status--"
                  onChange={(value) => setStatus(value)}
                  data={[
                    { label: "Active", value: "true" },
                    { label: "InActive", value: "false" },
                  ]}
                  className="w-full focus:outline-none focus:border-primary text-gray-600"
                  appearance="subtle"
                />
              </div>

              <div className="flex items-center justify-center mt-3">
                <div className="text-center">
                  <button
                    type="submit"
                    onClick={resetFilter}
                    className="text-black bg-white border border-primary hover:bg-white/45 focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-8 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                  >
                    Reset Filter
                  </button>
                </div>

                <div className="text-center">
                  <button
                    type="submit"
                    onClick={filterActivity}
                    disabled={loading1 ? true : false}
                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                  >
                    {loading1 ? <Spinner /> : "Load"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default Admin;
