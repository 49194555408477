import React, { useEffect, useState } from "react";
import Hero from "./__component/Hero";
import NDISForm from "./__component/NDISForm";
import About from "./__component/About";
import Pricing from "./__component/Pricing";
import Payments from "./__component/Payments";
import Attachment from "./__component/Attachment";
import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Client } from "../../store/slices/clientSlice";
import {
  fetchClientInfo,
  fetchClientManagerProfle,
  fetchClientRepresentativeData,
  fetchCompany,
  fetchNDISdoc,
} from "../../services/fetchServices";
import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../../store/store";
import { CompanyDetail } from "../../types/AuthData";
import { getAppointmentText, getPaymentText } from "./data";
import Spinner from "../../Components/Spinner";
import axiosInstance from "../../store/axiosInstance";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import {
  clientScheduleSupportData,
  fetchClientSupportSchedule,
} from "../../store/slices/ClientSlice/clientSupport";
import { Staff } from "../../store/slices/staffSlice";
import { NdisForm, Representative } from "../../types/DataType";
import Breadcrumb from "../../Components/label/BreadCrumb";
import EditServiceAgreement from "./EditServiceAgreement";
import PrintComponent from "../PrintTab";

const ServiceAgreement = () => {
  const { uid } = useParams();
  const dispatch: AppDispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state?.auth?.user);
  const [clientDetail, setClientDetail] = useState<Client | null>(null);
  const [careManager, setCareManager] = useState<Staff | null>(null);
  const [careManagerName, setCareManagerName] = useState<string>("");
  const [ndisForm, setNdisForm] = useState<NdisForm | null>(null);
  const [clientRep, setClientRep] = useState<Representative | null>(null);
  const [companyOne, setCompanyOne] = useState<
    CompanyDetail | null | undefined
  >(null);
  const [loadingClient, setLoadingClient] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    dispatch(fetchClientSupportSchedule(Number(uid)));
  }, [dispatch, uid]);
  const clientSupportSchedule = useSelector(clientScheduleSupportData);

  const handleCompanyFetch = async () => {
    // setLoading(true);
    if (user?.companyId) {
      const data = await fetchCompany(user?.companyId);
      setCompanyOne(data);
    }
    // setLoading(false);
  };

  useEffect(() => {
    fetchClientData();
    handleCompanyFetch();
    fetchNDIS();
    fetchClientManager();
    fetchClientRep();
  }, [uid, clientDetail?.careManager]);

  const fetchClientData = async () => {
    const data = await fetchClientInfo(uid);
    setClientDetail(data);
    setLoadingClient(false);
  };
  const fetchClientRep = async () => {
    const data = await fetchClientRepresentativeData(Number(uid));
    setClientRep(data[0]);
  };

  const fetchNDIS = async () => {
    const data = await fetchNDISdoc(
      Number(uid),
      Number(user?.companyId),
      "PCD-100 P01 Service agreement"
    );
    setNdisForm(data);
  };
  const fetchClientManager = async () => {
    if (clientDetail?.careManager) {
      const data = await fetchClientManagerProfle(
        Number(user?.companyId),
        clientDetail?.careManager
      );
      setCareManager(data);
      setCareManagerName(data.fullName || "");
    }
  };

  const Provider_Name = useRef<HTMLTableCellElement>(null);
  const Provider_Reg_No = useRef<HTMLTableCellElement>(null);
  const ImplementationDate = useRef<HTMLInputElement>(null);
  const ExpirationDate = useRef<HTMLInputElement>(null);

  const appointmentText = getAppointmentText(companyOne?.companyName);
  const paymentText = getPaymentText(companyOne?.companyName);

  const [appointmentOptions, setAppointmentOptions] = useState<string[]>([]);
  const [paymentOptions, setPaymentOptions] = useState<string[]>([]);
  const handleAppointmentChange = (option: string) => {
    const isOptionSelected = appointmentOptions.includes(option);

    if (isOptionSelected) {
      setAppointmentOptions(
        appointmentOptions.filter((item) => item !== option)
      );
    } else {
      setAppointmentOptions([...appointmentOptions, option]);
    }
  };
  const handlePaymentChange = (option: string) => {
    const isOptionSelected = paymentOptions.includes(option);

    if (isOptionSelected) {
      setPaymentOptions(paymentOptions.filter((item) => item !== option));
    } else {
      setPaymentOptions([...paymentOptions, option]);
    }
  };

  const [fundingAvailability, setFundingAvailability] = useState(false);

  const [signature, setSignature] = useState<Blob | null>(null);

  const handleSignatureSave = (signatureBlob: Blob) => {
    setSignature(signatureBlob);
  };

  const componentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // if (!signature) {
    //   return toast.error("Signature is required");
    // }

    const formData = new FormData();
    formData.append("Provider_Name", Provider_Name.current?.innerText || "");
    formData.append(
      "Provider_Reg_No",
      Provider_Reg_No.current?.innerText || ""
    );
    formData.append(
      "ImplementationDate",
      ImplementationDate.current?.value || ""
    );
    formData.append("ExpirationDate", ExpirationDate.current?.value || "");
    appointmentOptions &&
      formData.append("Appointment_Option", appointmentOptions.join(", "));
    paymentOptions &&
      formData.append("Payment_Option", paymentOptions.join(", "));
    formData.append("Document_Name", "PCD-100 P01 Service agreement");
    formData.append("Cancellation_Option", "false");
    formData.append("RepresentativeName", careManagerName);
    uid && formData.append("ProfileId", uid);
    formData.append("CompanyId", user?.companyId.toString() || "");
    formData.append("Funding_Availabilty", fundingAvailability.toString());
    if (signature) {
      formData.append("SignatureFile", signature, "signature.png"); // Append the blob, specify filename
    }

    try {
      setLoading(true);

      const { data } = await axiosInstance.post(
        `/NDISForms/fill_ndis_form?userId=${user?.uid}`,
        formData
      );

      if (data.status === "Success") {
        toast.success(data.message);
        setLoading(false);
        fetchNDIS();
        navigate(
          `/app/user/clients/${clientDetail?.profileId}/${clientDetail?.firstName}`
        );
      } else {
        toast.error(data.message);
        setLoading(false);
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Breadcrumb />
      {loadingClient && (
        <div className="fixed  inset-0 z-[9999]  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-auto max-w-lg mx-auto">
            <div
              className="relative z-[999] flex justify-center items-center p-4  duration-500 ease-in-out bg-white
border-0 rounded-lg shadow-lg outline-none focus:outline-none"
            >
              <Spinner color="#071952" />
            </div>
          </div>

          <div className="fixed inset-0 z-40 bg-gray-300 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
        </div>
      )}

      {/* className="mx-auto  max-w-2xl" */}

      {ndisForm ? (
        <EditServiceAgreement
          companyOne={companyOne}
          uid={uid}
          ndisForm={ndisForm}
          clientDetail={clientDetail}
          appointmentText={appointmentText}
          fundingAvailability={fundingAvailability}
          setFundingAvailability={setFundingAvailability}
          paymentText={paymentText}
          clientRep={clientRep}
          careManager={careManager}
          handleSignatureSave={handleSignatureSave}
          setSignature={setSignature}
          signature={signature}
          clientSupportSchedule={clientSupportSchedule}
        />
      ) : (
        <>
          <PrintComponent
            componentRef={componentRef}
            triggerLabel="Print / Download"
            documentTitle="NDIS Service Agreement"
          />

          <form onSubmit={handleSubmit}>
            <div
              className="mx-auto  max-w-3xl p-4 md:p-4 lg:p-8 bg-white mt-10"
              // className="p-7 md:p-8 lg:p-12 xl:p-16 bg-white min-h-screen"
            >
              <Hero companyOne={companyOne} />
              <NDISForm
                clientDetail={clientDetail}
                Provider_Name={Provider_Name}
                Provider_Reg_No={Provider_Reg_No}
                companyOne={companyOne}
                ExpirationDate={ExpirationDate}
                ImplementationDate={ImplementationDate}
              />
              <About
                appointmentText={appointmentText}
                appointmentOptions={appointmentOptions}
                handleAppointmentChange={handleAppointmentChange}
                companyOne={companyOne}
              />
              <Pricing
                fundingAvailability={fundingAvailability}
                setFundingAvailability={setFundingAvailability}
                companyOne={companyOne}
              />
              <Payments
                paymentText={paymentText}
                handlePaymentChange={handlePaymentChange}
                paymentOptions={paymentOptions}
                handleSignatureSave={handleSignatureSave}
                signature={signature}
                clientDetail={clientDetail}
                setSignature={setSignature}
                ExpirationDate={ExpirationDate}
                ImplementationDate={ImplementationDate}
                careManager={careManager}
                careManagerName={careManagerName}
                setCareManagerName={setCareManagerName}
                companyOne={companyOne}
                clientRep={clientRep}
              />
              <Attachment clientSupportSchedule={clientSupportSchedule} />
            </div>
            <div className="mx-auto text-center mt-3 lg:col-span-2">
              <button
                type="submit"
                disabled={loading}
                className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"
              >
                {loading ? <Spinner /> : " Submit"}
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default ServiceAgreement;
