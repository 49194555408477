import React, { ChangeEvent } from "react";
import { Plan } from "..";


interface Props {
  handleInputChange: (index: number, event: ChangeEvent<HTMLInputElement>) => void;
  handlePlanCheckboxChange: (index: number, event: ChangeEvent<HTMLInputElement>) => void;
  plans: Plan[];
}

const PlanForm = ({ handleInputChange, handlePlanCheckboxChange, plans }: Props) => {

  // console.log(`plan_`, plans[0].name);

  return (
    <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border mt-9 border-gray-300">
      <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
        <thead className="ltr:text-left rtl:text-right bg-[#b9b9b95e]">
          <tr className="divide-x-2">
            <th className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
              Name of plan
            </th>
            <th className=" px-4 divide-x-2 py-2 font-semibold text-gray-900">
              Tick to indicate plan has been sighted, signed and is current
            </th>
          </tr>
        </thead>

        <tbody className="divide-y divide-gray-200">
          {plans.map((plan, index) => (
            <tr key={index} className="divide-x-2">
              <td className="flex gap-2 items-center px-4 py-2 text-gray-700 w-[100%] outline-none">
                <span>{index + 1}.</span>
                <input
                  id={`plan_${index + 1}`}
                  className="w-[100%] outline-none"
                  type="text"
                  value={plan.name}
                  onChange={(event) => handleInputChange(index, event)}
                />
              </td>
              <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                <input
                  id={`check_Plan_${index + 1}`}
                  type="checkbox"
                  checked={plan.checked}
                  onChange={(event) => handlePlanCheckboxChange(index, event)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PlanForm;

