import React from "react";
import { Navigate, Route, Routes } from "react-router";
import CreateAdmin from "./CreateAdmin";
import CreateClient from "./CreateClient";
import CreateStaff from "./CreateStaff";
import UploadClientViaExcel from "./UploadClientViaExcel";
import UploadClientViaXero from "./UploadClientViaXero";
import UploadStaffViaExcel from "./UploadStaffViaExcel";
import UploadStaffViaXero from "./UploadStaffViaXero";
import UploadViaExcel from "./UploadViaExcel";
import UploadViaXero from "./UploadViaXero";

const FormLayout = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Navigate to="/app" />} />
        <Route path="/create-admin" element={<CreateAdmin />} />
        <Route path="/create-staff" element={<CreateStaff />} />
        <Route path="/create-client" element={<CreateClient />} />
        <Route path="/upload-admin-via-excel" element={<UploadViaExcel />} />
        <Route
          path="/upload-staff-via-excel"
          element={<UploadStaffViaExcel />}
        />
        <Route
          path="/upload-client-via-excel"
          element={<UploadClientViaExcel />}
        />
        <Route path="/upload-admin-via-xero" element={<UploadViaXero />} />
        <Route path="/upload-staff-via-xero" element={<UploadStaffViaXero />} />
        <Route
          path="/upload-client-via-xero"
          element={<UploadClientViaXero />}
        />
      </Routes>
    </div>
  );
};

export default FormLayout;
