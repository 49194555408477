import React from "react";
import { Shift } from "../../../../../store/slices/shiftRosterSlice";
import dayjs from "dayjs";
import { CompanyDetail } from "../../../../../types/AuthData";

interface Props {
    shiftDetail: Shift | null;
    companyOne: CompanyDetail | null | undefined
}
const FormHero = ({ shiftDetail, companyOne }: Props) => {
    return (
        <>
            <div className=" lg:space-x-4 lg:items-start text-right flex justify-end ">
                <img
                    className="w-24 "
                    src={companyOne?.companyLogo}
                    alt="Company Logo"
                />
            </div>
            <table className=" divide-y-2 divide-gray-300 bg-white  text-sm rounded-lg border  border-gray-300 my-6">
                <tbody className="divide-y divide-gray-200">
                    <tr className="divide-x-2">
                        <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                            Staff Name:
                        </td>
                        <td
                            className="whitespace-nowrap px-4 py-2 text-gray-700 w-full outline-none"

                        >
                            {shiftDetail?.staff.fullName}
                        </td>
                    </tr>

                    <tr className="divide-x-2">
                        <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                            Client(s) Name:
                        </td>
                        <td
                            className="whitespace-nowrap px-4 py-2 text-gray-700 w-full outline-none"

                        > {shiftDetail?.clients}</td>
                    </tr>
                    <tr className="divide-x-2">
                        <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                            Shift Start Time:
                        </td>
                        <td
                            className="whitespace-nowrap px-4 py-2 text-gray-700 w-full outline-none"

                        > {dayjs(shiftDetail?.dateFrom).format("D MMMM, YYYY h:mm A")}</td>
                    </tr>
                    <tr className="divide-x-2">
                        <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                            Shift End Time:
                        </td>
                        <td
                            className="whitespace-nowrap px-4 py-2 text-gray-700 w-full outline-none"

                        > {dayjs(shiftDetail?.dateTo).format("D MMMM, YYYY h:mm A")}</td>
                    </tr>

                    <tr className="divide-x-2">
                        <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                            Date:
                        </td>
                        <td
                            className="whitespace-nowrap px-4 py-2 text-gray-700 w-full outline-none"

                        >
                            {dayjs(shiftDetail?.dateCreated).format('DD/MM/YYYY')}
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};

export default FormHero;
