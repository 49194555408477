import React, { FormEvent, useRef, useState } from "react";
import Hero from "./___component/Hero";
import PrintComponent from "../PrintTab";
import { CompanyDetail } from "../../types/AuthData";
import { Client } from "../../store/slices/clientSlice";
import { SupportGoalPlanData } from "../../types/DataType";
import EditGoalForm from "./___component/EditGoalForm";
import EditSection1 from "./___component/EditSection1";
import EditResponsibilityTable from "./___component/EditResponsibilityTable";
import EditProgress from "./___component/EditProgress";
import EditDate from "./___component/EditDate";
import { toast } from "react-toastify";
import axiosInstance from "../../store/axiosInstance";
import { isAxiosError } from "axios";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import Spinner from "../../Components/Spinner";
import { useNavigate } from "react-router-dom";

interface Row {
  date: string;
  actionRef: string;
  progressUpdate: string;
}

type RowKeys = "date" | "actionRef" | "progressUpdate";

interface Props {
  companyOne: CompanyDetail | null | undefined;
  clientDetail: Client | null;
  details: SupportGoalPlanData;
  handleInputDataChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  handleInputDChange: (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  actions: string[];
  responsibilities: string[];
  timeframes: string[];
  handleActionChange: (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleResponsibilityChange: (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleTimeframeChange: (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  rows: Row[];
  handleCellEditChange: (
    rowIndex: number,
    cellName: RowKeys,
    value: string
  ) => void;
}
const EditSupportGoal = ({
  companyOne,
  clientDetail,
  handleInputDataChange,
  handleInputDChange,
  details,
  actions,
  handleActionChange,
  handleResponsibilityChange,
  handleTimeframeChange,
  responsibilities,
  timeframes,
  handleCellEditChange,
  rows,
}: Props) => {
  const user = useSelector((state: RootState) => state?.auth?.user);
  const componentRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const info = {
      supportGoalPlanId: details.supportGoalPlanId,
      attendees: details.attendees,
      goal: details.goal,
      strategies: details.strategies,
      goalAction: [
        {
          action_Required: actions
            .filter((strategy) => strategy.trim())
            .join(", "),
          responsibility: responsibilities
            .filter((strategy) => strategy.trim())
            .join(", "),
          timeframe: timeframes
            .filter((strategy) => strategy.trim())
            .join(", "),
        },
      ],
      goalProgress: rows.map((row) => ({
        date: row.date,
        action: row.actionRef,
        progress_Update: row.progressUpdate,
      })),
      profileId: details.profileId,
      document_Name: "PCD-108 P09 Support Goal Plan",
      implementationDate: details.implementationDate,
      expirationDate: details.expirationDate,
      companyId: details.companyId,
    };

    try {
      const { data } = await axiosInstance.post(
        `/SupportGoalPlans/edit/${details.supportGoalPlanId}?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      setLoading(false);
      navigate(
        `/app/user/clients/${clientDetail?.profileId}/${clientDetail?.firstName}`
      );
    } catch (error: unknown) {
      console.log(error);
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <PrintComponent
          componentRef={componentRef}
          triggerLabel="Print / Download"
          documentTitle="Consent Form"
        />
        <div
          ref={componentRef}
          className="mx-auto  max-w-3xl p-4 md:p-4 lg:p-8 bg-white"
        >
          <Hero companyOne={companyOne} />
          <EditGoalForm
            clientDetail={clientDetail}
            details={details}
            handleInputDChange={handleInputDChange}
          />
          <EditSection1
            details={details}
            handleInputDChange={handleInputDChange}
            handleInputDataChange={handleInputDataChange}
          />
          <EditResponsibilityTable
            actions={actions}
            handleActionChange={handleActionChange}
            handleResponsibilityChange={handleResponsibilityChange}
            handleTimeframeChange={handleTimeframeChange}
            responsibilities={responsibilities}
            timeframes={timeframes}
          />
          <EditProgress
            handleCellEditChange={handleCellEditChange}
            rows={rows}
          />
          <EditDate
            handleInputDataChange={handleInputDataChange}
            details={details}
          />
        </div>
        <div className="mx-auto text-center mt-3 lg:col-span-2">
          <button
            type="submit"
            disabled={loading}
            className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"
          >
            {loading ? <Spinner /> : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditSupportGoal;
