import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { BsSearch } from "react-icons/bs";
import ExportDropdown from "../../../../Components/menu/ExportDropdown";
import IconButton from "../../../../Components/Buttons/IconButton";
import {
  downloadCSV,
  exportToExcel,
  handlePDFDownload,
} from "../../../../services/reusableFunc";
import { Column } from "../../../../types/DataType";
import { FaFileCsv, FaFileExcel, FaFilePdf } from "react-icons/fa";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../../store/store";
import { useSelector } from "react-redux";
import {
  clientProgressNoteData,
  clientProgressNoteLoading,
  fetchClientProgressNote,
} from "../../../../store/slices/clientProgressNoteSlice";
import dayjs from "dayjs";
import Spinner from "../../../../Components/Spinner";
import axiosInstance from "../../../../store/axiosInstance";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import { Modal } from "rsuite";

interface DataRow {
  companyID: number;
  date: string;
  dateCreated: string;
  dateModified: string;
  endKm: number;
  followUp: string;
  imageFIle: string;
  imageURL: string;
  isCompleted: boolean;
  position: string;
  profile: {
    profileId: number;
    firstName: string;
    surName: string;
    middleName: string;
    clientId: number;
    email: string;
    phoneNumber: string;
    gender: string;
    fullName: string;
  };
  profileId: number;
  progress: string;
  progressNoteId: number;
  report: string;
  staff: string;
  staffId: number;
  startKm: number;
  userCreated: string;
  userModified: string;
}

interface ClientProgressNoteProps {
  uid: string; // Define the type of uid as string
}

const ClientProgressReport: React.FC<ClientProgressNoteProps> = ({ uid }) => {
  const columns: TableColumn<DataRow>[] = [
    {
      name: "",
      cell: (row) => (
        <div className="flex justify-center w-full text-primary cursor-pointer">
          <button
            onClick={() => EditDetail(row.progressNoteId)}
            className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
          >
            view
          </button>
        </div>
        // <div className='flex justify-center w-full text-primary cursor-pointer'
        //     onClick={() => EditDetail(row.progressNoteId)}
        // >
        //     view
        // </div>
      ),
    },
    {
      name: "Staff",
      selector: (row) => row.staff,
      sortable: true,
    },
    // {
    //     name: 'Client',
    //     selector: row => row.profile.fullName,
    //     sortable: true
    // },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      cell: (row) => (
        <span style={{ overflow: "hidden" }}>
          {" "}
          {dayjs(row.date).format("ddd, MMM DD, h:mm A")}
        </span>
      ),
    },
  ];

  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();
  const companyId = user?.companyId;
  useEffect(() => {
    if (uid) {
      dispatch(fetchClientProgressNote(Number(uid)));
    }
  }, [dispatch, companyId, uid]);
  const clientProgressNote = useSelector(clientProgressNoteData);
  const loadingData = useSelector(clientProgressNoteLoading);
  const [searchText, setSearchText] = useState<string>("");
  const [saveId, setSaveId] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const [progressDetails, setProgressDetails] = useState<DataRow>({
    companyID: 0,
    date: "",
    dateCreated: "",
    dateModified: "",
    endKm: 0,
    followUp: "",
    imageFIle: "",
    imageURL: "",
    isCompleted: false,
    position: "",
    profileId: 0,
    progress: "",
    progressNoteId: 0,
    report: "",
    staff: "",
    staffId: 0,
    startKm: 0,
    userCreated: "",
    userModified: "",
    profile: {
      profileId: 0,
      firstName: "",
      surName: "",
      middleName: "",
      clientId: 0,
      email: "",
      phoneNumber: "",
      gender: "",
      fullName: "",
    },
  });

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setProgressDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const EditDetail = async (id: number) => {
    setSaveId(id);
    setShowModal(true);
    try {
      const { data } = await axiosInstance.get(`/ProgressNotes/${id}`);
      setProgressDetails({ ...data });
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const info = {
      ...progressDetails,
    };

    try {
      const { data } = await axiosInstance.post(
        `/ProgressNotes/edit/${saveId}?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      setLoading(false);
      uid && dispatch(fetchClientProgressNote(Number(uid)));
      setShowModal(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const filteredData = clientProgressNote.filter((item) =>
    item.staff.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div className="mt-10">
      {clientProgressNote.length <= 0 && loadingData && (
        <Spinner color="#071952" />
      )}
      <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
        <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
          <h2 className="font-bold text-xl text-black w-auto">
            Progress Report{" "}
          </h2>

          <div className="relative w-full lg:col-span-2">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <BsSearch />
            </div>
            <input
              type="text"
              id="simple-search"
              onChange={handleSearch}
              className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Search"
            />
          </div>
        </div>
        <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
          <div className="flex items-center space-x-3 w-full md:w-auto">
            {/* <button id="actionsDropdownButton" className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                            <FaFilter />
                            Filter
                        </button> */}
            <ExportDropdown>
              <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                <IconButton
                  icon={<FaFilePdf className="text-red-500" />}
                  title="Export as PDF"
                  onClick={() =>
                    handlePDFDownload(
                      clientProgressNote,
                      columns as unknown as Column<DataRow>[],
                      "ClientProgess.pdf"
                    )
                  }
                />
                <IconButton
                  icon={<FaFileExcel className="text-green-600" />}
                  title="Export as Excel Sheet"
                  onClick={() =>
                    exportToExcel(
                      clientProgressNote,
                      columns as unknown as Column<DataRow>[],
                      "ClientProgess"
                    )
                  }
                />
                <IconButton
                  icon={<FaFileCsv className="text-green-500" />}
                  title="Export as CSV"
                  onClick={() =>
                    downloadCSV({
                      data: clientProgressNote,
                      filename: "ClientProgess.csv",
                    })
                  }
                />
              </div>
            </ExportDropdown>
          </div>
        </div>
      </div>

      <DataTable
        columns={columns}
        data={filteredData}
        pagination
        paginationTotalRows={clientProgressNote?.length}
        // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}
      />

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Progress Note Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form action="">
              <div className="px-4 py-2">
                <label htmlFor="report" className="font-bold">
                  Report{" "}
                </label>
                <textarea
                  id="report"
                  rows={3}
                  name="report"
                  autoComplete="off"
                  value={progressDetails.report || ""}
                  onChange={handleInputChange}
                  className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                  defaultValue={""}
                />
              </div>
              <div className="px-4 py-2">
                <label htmlFor="progress" className="font-bold">
                  Progress{" "}
                </label>
                <textarea
                  id="progress"
                  rows={3}
                  name="progress"
                  autoComplete="off"
                  value={progressDetails.progress || ""}
                  onChange={handleInputChange}
                  className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                  defaultValue={""}
                />
              </div>
              <div className="px-4 py-2">
                <label htmlFor="followUp" className="font-bold">
                  Follow Up{" "}
                </label>
                <textarea
                  id="followUp"
                  rows={3}
                  name="followUp"
                  autoComplete="off"
                  value={progressDetails.followUp || ""}
                  onChange={handleInputChange}
                  className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                  defaultValue={""}
                />
              </div>
            </form>
            <div className="mx-auto text-center mt-3">
              <button
                type="submit"
                onClick={handleSubmit}
                disabled={loading ? true : false}
                className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                    font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                    dark:focus:ring-primary"
              >
                {loading ? <Spinner /> : "Update"}
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default ClientProgressReport;
