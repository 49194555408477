import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isAxiosError } from "axios";
import { Shift } from "../shiftRosterSlice";
import api from "../../api";
import { RootState } from "../../store";

export interface ShiftReport {
  shiftReportId: number;
  urgentMatters: string;
  medicationGiven: string;
  medicationSigned: string;
  medicationAvailable: string;
  medicatioErrors: string;
  isMealManagementPlan: boolean;
  details_IfNotMealMaganementPlan: string;
  isDrinkingProblem: boolean;
  details_IfProblemExist: string;
  isHealthIssues: true;
  details_IfHealthIssuesExist: string;
  goal_Progress: string;
  contactFamily: string;
  isIncident: boolean;
  details_IfIsIncident: string;
  isBehaviourConcerned: boolean;
  details_ifIsBehaviourConcerned: string;
  shiftRosterId: number;
  shiftRoster?: Shift;
  companyID: number;
  dateCreated: string;
  userCreated: string;
  dateModified: string;
  userModified: string | null;
}

export interface StaffShiftReportState {
  loading: boolean;
  data: Array<ShiftReport>;
  error: string | undefined;
}
const initialState: StaffShiftReportState = {
  loading: false,
  data: [],
  error: undefined,
};
export const fetchStaffShiftReport = createAsyncThunk(
  "StaffShiftReport/fetchStaffShiftReport",
  async (staffId: number) => {
    try {
      const response = await api.fetchStaffShiftReport(staffId);
      return response.sort(
        (a: ShiftReport, b: ShiftReport) =>
          new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
      );
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

const staffShiftReportSlice = createSlice({
  name: "staffShiftReport",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchStaffShiftReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchStaffShiftReport.fulfilled,
      (state, action: PayloadAction<Array<ShiftReport>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(fetchStaffShiftReport.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {},
});
export const userSelector = (state: RootState) => state.shiftReport;
export default staffShiftReportSlice.reducer;

export const staffShiftReportData = (state: RootState) =>
  state.staffShiftReport.data;
export const staffShiftReportLoading = (state: RootState) =>
  state.staffShiftReport.loading;
export const staffShiftReportError = (state: RootState) =>
  state.staffShiftReport.error;
