import React, { FormEvent } from 'react';
import Spinner from '../../../../../Components/Spinner';
import ClientTabForm from "../../components/ClientTabForm";
import { EditBehavoirSupportDataType } from '../BehaviorSupport';

interface Props {
    editBehaviorSupport: EditBehavoirSupportDataType;
    handleInputDataChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    handleSubmitEdit: (e: FormEvent) => Promise<void>;
    loading: boolean;
}

const EditBehaviorSupportCom: React.FC<Props> = ({ editBehaviorSupport, handleSubmitEdit, loading, handleInputDataChange }) => {

    return (
        <form className='mt-10' onSubmit={handleSubmitEdit}>
            <h2 className='font-bold text-xl text-black w-auto'>Behaviour Support Needs</h2>
            <div>
                <div className="mt-5">
                    <ClientTabForm title='Behaviour Support Needs'>
                        <div className='grid grid-cols-1 gap-16 lg:grid-cols-2'>
                            {/* Each textarea is linked to a specific property of editBehaviorSupport */}
                            <div>
                                <label htmlFor="additionalSupportDetail" className="font-bold">
                                    Describe how you would react if someone you lived with did something you found disruptive or upsetting
                                </label>
                                <textarea
                                    id="additionalSupportDetail"
                                    name="question1"
                                    rows={3}
                                    autoComplete="off"
                                    value={editBehaviorSupport.question1}
                                    onChange={handleInputDataChange}
                                    className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                />
                            </div>
                            <div>
                                <label htmlFor="transportationDetails" className="font-bold">
                                    Do you have any behaviours of concern that require specific support? If so, please provide detail
                                </label>
                                <textarea
                                    id="transportationDetails"
                                    name="question2"
                                    rows={3}
                                    autoComplete="off"
                                    value={editBehaviorSupport.question2}
                                    onChange={handleInputDataChange}
                                    className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                />
                            </div>
                            <div>
                                <label htmlFor="transportType" className="font-bold">
                                    Do you do anything that others might find disruptive
                                </label>
                                <textarea
                                    id="transportType"
                                    name="question3"
                                    rows={3}
                                    autoComplete="off"
                                    value={editBehaviorSupport.question3}
                                    onChange={handleInputDataChange}
                                    className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                />
                            </div>
                            <div>
                                <label htmlFor="activitiesDetail" className="font-bold">
                                    Behaviour Plan
                                </label>
                                <textarea
                                    id="activitiesDetail"
                                    name="behaviourPlan"
                                    rows={3}
                                    autoComplete="off"
                                    value={editBehaviorSupport.behaviourPlan}
                                    onChange={handleInputDataChange}
                                    className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                />
                            </div>
                            <div>
                                <label htmlFor="accessAssistanceDetail" className="font-bold">
                                    Risk Assessment
                                </label>
                                <textarea
                                    id="accessAssistanceDetail"
                                    name="riskAssessment"
                                    rows={3}
                                    autoComplete="off"
                                    value={editBehaviorSupport.riskAssessment}
                                    onChange={handleInputDataChange}
                                    className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                />
                            </div>
                        </div>
                    </ClientTabForm>
                </div>
                <div className="mx-auto text-center mt-3">
                    <button
                        type="submit"
                        disabled={loading}
                        className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                               font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                               dark:focus:ring-primary"
                    >
                        {loading ? <Spinner /> : "Update"}
                    </button>
                </div>
            </div>
        </form>
    );
};

export default EditBehaviorSupportCom;
