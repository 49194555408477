import React, { ChangeEvent, useEffect, useState } from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import DataTable, {
  TableColumn,
  ExpanderComponentProps,
} from "react-data-table-component";
// import { FaFilter } from 'react-icons/fa';
import { BsSearch } from "react-icons/bs";
import ExportDropdown from "../../../Components/menu/ExportDropdown";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  fetchSupportType,
  supportTypeLoading,
  supportTypelateData,
} from "../../../store/slices/supportTypeSlice";
import Spinner from "../../../Components/Spinner";
import IconButton from "../../../Components/Buttons/IconButton";
import { FaFileCsv, FaFileExcel, FaFilePdf } from "react-icons/fa";
import {
  downloadCSV,
  exportToExcel,
  handlePDFDownload,
} from "../../../services/reusableFunc";
import { Column } from "../../../types/DataType";
import { Button, Modal } from "rsuite";
import CustomInput from "../../../Components/Input/CustomInput";
import axiosInstance from "../../../store/axiosInstance";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";

interface DataRow {
  dateCreated: string;
  dateModified: string;
  itemName: string;
  itemNumber: string;
  national: number;
  ndiA_DPAId: number;
  remote: number;
  unit: string;
  userCreated: string;
  userModified: string;
  veryRemote: number;
}

const SupportType = () => {
  const columns: TableColumn<DataRow>[] = [
    {
      name: "Item Number",
      selector: (row) => row.itemNumber,
    },
    {
      name: "Item Name",
      selector: (row) => row.itemName,
      sortable: true,
      cell: (row) => (
        <span
          className="truncate"
          style={{ overflow: "hidden", cursor: "pointer" }}
          data-toggle="tooltip"
          data-placement="top"
          title={`${row.itemName}`}
        >
          {row.itemName}
        </span>
      ),
    },

    {
      name: "Unit",
      selector: (row) => row.unit,
    },
    {
      name: "National",
      selector: (row) => row.national,
    },
    {
      name: "Remote",
      selector: (row) => row.remote,
    },
    {
      name: "Very Remote",
      selector: (row) => row.veryRemote,
    },
  ];

  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();
  const companyId = user?.companyId;
  useEffect(() => {
    if (companyId) {
      dispatch(fetchSupportType(companyId));
    }
  }, []);
  const supportType = useSelector(supportTypelateData);
  const loadingData = useSelector(supportTypeLoading);
  const [loading, setLoading] = useState<boolean>(false);
  const [addModal, setAddModal] = useState<boolean>(false);
  const [itemNumber, setItemNumber] = useState<string>("");
  const [itemName, setItemName] = useState<string>("");
  const [unit, setUnit] = useState<string>("");
  const [national, setNational] = useState<string>("");
  const [remote, setRemote] = useState<string>("");
  const [veryRemote, setVeryRemote] = useState<string>("");
  const [saveId, setSaveId] = useState<number>(0);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [supportDetails, setSupportDetails] = useState<DataRow>({
    dateCreated: "",
    dateModified: "",
    itemName: "",
    itemNumber: "",
    national: 0,
    ndiA_DPAId: 0,
    remote: 0,
    unit: "",
    userCreated: "",
    userModified: "",
    veryRemote: 0,
  });

  interface Props extends ExpanderComponentProps<DataRow> {
    // currently, props that extend ExpanderComponentProps must be set to optional.
    someTitleProp?: string;
  }

  const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
    return (
      <div className="p-2 flex flex-col gap-2 text-xs">
        <span>
          <span className="fw-bold">Item Name: </span>
          <span> {data.itemName}</span>
        </span>

        <span>
          <span className="fw-bold">Date Created: </span>
          <span>{dayjs(data.dateCreated).format("DD/MM/YYYY HH:mm:ss")}</span>
        </span>
        <span>
          <span className="fw-bold">Date Modified: </span>
          <span>{dayjs(data.dateModified).format("DD/MM/YYYY HH:mm:ss")}</span>
        </span>
        <div className="flex gap-2">
          <span className="font-bold">Actions: </span>{" "}
          <button
            className="btn text-primary"
            style={{ fontSize: "12px" }}
            onClick={() => EditSupportType(data.ndiA_DPAId)}
          >
            Edit
          </button>
          |
          <button
            className="btn text-red-500 font-bold"
            style={{ fontSize: "12px" }}
            onClick={() => delHoliday(data.ndiA_DPAId)}
          >
            Delete
          </button>
        </div>
      </div>
    );
  };

  const EditSupportType = async (id: number) => {
    setSaveId(id);
    setEditModal(true);
    try {
      const { data } = await axiosInstance.get(`/Invoice/details/${id}`);
      setSupportDetails({ ...data });
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const delHoliday = (id: number) => {
    setSaveId(id);
    setDeleteModal(true);
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setSupportDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [searchText, setSearchText] = useState<string>("");

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredData = supportType?.filter((item) =>
    item?.itemName?.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (itemName.trim() === "" || itemNumber.trim() === "") {
      return toast.error("Input field can't be empty");
    }
    const info = {
      itemNumber: itemNumber,
      itemName: itemName,
      unit: unit,
      national: national,
      remote: remote,
      veryRemote: veryRemote,
      comapnyId: companyId,
    };
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        `/Invoice/add_new_supporttype?userId=${user?.uid}`,
        info
      );

      if (data.status === "Success") {
        toast.success(data.message);
        setAddModal(false);
        companyId && dispatch(fetchSupportType(companyId));
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitEdit = async (e: React.FormEvent) => {
    e.preventDefault();

    const info = {
      ndiA_DPAId: saveId,
      itemNumber: supportDetails.itemNumber,
      itemName: supportDetails.itemName,
      unit: supportDetails.unit,
      national: supportDetails.national,
      remote: supportDetails.remote,
      veryRemote: supportDetails.veryRemote,
      comapnyId: companyId,
    };
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        `/Invoice/edit_supporttype/${saveId}?userId=${user?.uid}`,
        info
      );

      if (data.status === "Success") {
        toast.success(data.message);
        setEditModal(false);
        companyId && dispatch(fetchSupportType(companyId));
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteHoliday = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.post(
        `/Invoice/delete_supporttype/${saveId}?userId=${user?.uid}`
      );
      toast.success(data.message);
      setDeleteModal(false);
      companyId && dispatch(fetchSupportType(companyId));
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
      setDeleteModal(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SEO
        title="Support Type - Promax Care"
        description="Support Type"
        name="Promax-Care"
      />
      <Breadcrumb />
      <div className="mt-10">
        {supportType.length <= 0 && loadingData && <Spinner color="#071952" />}
        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
          <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
            <h2 className="font-bold text-xl text-black w-auto">
              Support Type
            </h2>

            <div className="relative w-full lg:col-span-2">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <BsSearch />
              </div>
              <input
                type="text"
                id="simple-search"
                onChange={handleSearch}
                className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Search By itemName"
              />
            </div>
          </div>
          <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
            <div className="flex items-center space-x-3 w-full md:w-auto">
              {/* <button id="actionsDropdownButton" className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                                <FaFilter />
                                Filter
                            </button> */}
              <ExportDropdown>
                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <IconButton
                    icon={<FaFilePdf className="text-red-500" />}
                    title="Export as PDF"
                    onClick={() =>
                      handlePDFDownload(
                        supportType,
                        columns as unknown as Column<DataRow>[],
                        "SupportType.pdf"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileExcel className="text-green-600" />}
                    title="Export as Excel Sheet"
                    onClick={() =>
                      exportToExcel(
                        supportType,
                        columns as unknown as Column<DataRow>[],
                        "SupportType"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileCsv className="text-green-500" />}
                    title="Export as CSV"
                    onClick={() =>
                      downloadCSV({
                        data: supportType,
                        filename: "SupportType.csv",
                      })
                    }
                  />
                </div>
              </ExportDropdown>
            </div>
            <button
              onClick={() => setAddModal(true)}
              type="button"
              className="px-4 py-2 bg-primary text-white  disabled:bg-slate-400
                            transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm 
                           "
            >
              Add Support type
            </button>
          </div>
        </div>

        <DataTable
          columns={columns}
          data={filteredData}
          expandableRows
          expandableRowsComponent={ExpandableRowComponent}
          pagination
          paginationTotalRows={supportType?.length}
          // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}
        />
      </div>

      <Modal
        open={addModal}
        onClose={() => setAddModal(false)}
        backdrop="static"
        autoFocus
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Create a Support Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={handleSubmit}>
              <div className="space-y-1 p-3 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
                <CustomInput
                  label="Item Name"
                  type="text"
                  onChange={(e) => setItemName(e.target.value)}
                  required
                  placeholder="Item Name"
                />

                <CustomInput
                  label="Item Number"
                  type="text"
                  required
                  onChange={(e) => setItemNumber(e.target.value)}
                  placeholder="Item Number"
                />

                <CustomInput
                  label="Unit"
                  type="text"
                  required
                  onChange={(e) => setUnit(e.target.value)}
                  placeholder="Unit"
                />
                <CustomInput
                  label="National"
                  type="text"
                  required
                  onChange={(e) => setNational(e.target.value)}
                  placeholder="National"
                />
                <CustomInput
                  label="Remote"
                  type="text"
                  onChange={(e) => setRemote(e.target.value)}
                  placeholder="Remote"
                />
                <CustomInput
                  label="Very Remote"
                  type="text"
                  onChange={(e) => setVeryRemote(e.target.value)}
                  placeholder="Very Remote"
                />
              </div>
              <div className="mx-auto text-center mt-3">
                <button
                  type="submit"
                  disabled={loading}
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                    font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                    dark:focus:ring-primary"
                >
                  {loading ? <Spinner /> : "Create"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={editModal}
        onClose={() => setEditModal(false)}
        backdrop="static"
        autoFocus
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Edit Support Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitEdit}>
            <div className="space-y-1 p-3 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
              <CustomInput
                label="Item Name"
                type="text"
                name="itemName"
                value={supportDetails.itemName}
                onChange={handleInputChange}
                required
                placeholder="Item Name"
              />

              <CustomInput
                label="Item Number"
                type="text"
                required
                name="itemNumber"
                value={supportDetails.itemNumber}
                onChange={handleInputChange}
                placeholder="Item Number"
              />

              <CustomInput
                label="Unit"
                type="text"
                required
                name="unit"
                value={supportDetails.unit}
                onChange={handleInputChange}
                placeholder="Unit"
              />
              <CustomInput
                label="National"
                type="text"
                required
                name="national"
                value={supportDetails.national}
                onChange={handleInputChange}
                placeholder="National"
              />
              <CustomInput
                label="Remote"
                type="text"
                name="remote"
                value={supportDetails.remote}
                onChange={handleInputChange}
                placeholder="Remote"
              />
              <CustomInput
                label="Very Remote"
                type="text"
                name="veryRemote"
                value={supportDetails.veryRemote}
                onChange={handleInputChange}
                placeholder="Very Remote"
              />

              <div className="mx-auto text-center mt-3 col-span-2">
                <button
                  type="submit"
                  disabled={loading}
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                    font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                    dark:focus:ring-primary"
                >
                  {loading ? <Spinner /> : "Update"}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Delete Support Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="mb-4 font-bold">
              Are you sure you want to delete this Support Type?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <Button
                onClick={() => setDeleteModal(false)}
                className="bg-gray-500 text-white"
              >
                No, cancel
              </Button>
              <Button
                onClick={handleDeleteHoliday}
                className="bg-red-500 text-white hover:bg-red-700 hover:text-white"
              >
                {loading ? <Spinner /> : "Yes, I'm sure"}
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default SupportType;
