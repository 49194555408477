import React from 'react';
import CustomSelect from "../../../../../Components/Input/CustomSelect";
import ClientTabForm from "../../components/ClientTabForm";


interface Props {
    uid: string; // Define the type of uid as string
    setMobility: React.Dispatch<React.SetStateAction<{
        needMobilityAssistance: string;
        mobilityDescription: string;
    }>>
    mobility: {
        needMobilityAssistance: string;
        mobilityDescription: string;
    }
    setHearing: React.Dispatch<React.SetStateAction<{
        hearingIssues: string;
        hearingDescription: string;
    }>>
    hearing: {
        hearingIssues: string;
        hearingDescription: string;
    }
    setVision: React.Dispatch<React.SetStateAction<{
        visionIssues: string;
        visionDescription: string;
    }>>
    vision: {
        visionIssues: string;
        visionDescription: string;
    }
    setMemory: React.Dispatch<React.SetStateAction<{
        memoryIssues: string;
        memoryDescription: string;
    }>>
    memory: {
        memoryIssues: string;
        memoryDescription: string;
    }
    setCommunication: React.Dispatch<React.SetStateAction<{
        needCommunicationAssistance: string;
        communicationPreferences: string;
        communicationDescription: string;
    }>>
    communication: {
        needCommunicationAssistance: string;
        communicationPreferences: string;
        communicationDescription: string;
    }

}

const DisabilitySupportCom: React.FC<Props> = ({ communication, hearing, memory, mobility, setCommunication,
    setHearing, setMemory, setMobility, setVision, vision }) => {

    return (
        <div className='mt-10'
        // onSubmit={handleSubmit}
        >
            <h2 className='font-bold text-xl text-black w-auto'>Disability Support Needs</h2>
            <div className='mt-5 grid grid-cols-1 gap-16 lg:grid-cols-2'>
                <ClientTabForm title='Mobility Related Issues'>
                    <CustomSelect
                        label="Need Mobility Assistance?"
                        options={[
                            { label: "Need Mobility Assistance", value: "Need Mobility Assistance" },
                            { label: "Mobility Independency", value: "Mobility Independency" }
                        ]}
                        onChange={(e) => setMobility({ ...mobility, needMobilityAssistance: e.target.value })}
                        required
                    />
                    <label htmlFor="mobilityDescription" className="font-bold">Mobility Description</label>
                    <textarea
                        id="mobilityDescription"
                        rows={3}
                        autoComplete="off"
                        onChange={(e) => setMobility({ ...mobility, mobilityDescription: e.target.value })}
                        className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                        value={mobility.mobilityDescription}
                    />
                </ClientTabForm>

                <ClientTabForm title='Hearing Related Issues'>
                    <CustomSelect
                        label="Hearing Issues"
                        options={[
                            { label: "No Issues", value: "No Issues" },
                            { label: "Some Issues", value: "Some Issues" },
                            { label: "Hearing Impaired", value: "Hearing Impaired" }
                        ]}
                        onChange={(e) => setHearing({ ...hearing, hearingIssues: e.target.value })}
                        required
                    />
                    <label htmlFor="hearingDescription" className="font-bold">Hearing Description</label>
                    <textarea
                        id="hearingDescription"
                        rows={3}
                        autoComplete="off"
                        onChange={(e) => setHearing({ ...hearing, hearingDescription: e.target.value })}
                        className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                        value={hearing.hearingDescription}
                    />
                </ClientTabForm>

                <ClientTabForm title='Vision Related Issues'>
                    <CustomSelect
                        label="Vision Related Issues"
                        options={[
                            { label: "No Issues", value: "No Issues" },
                            { label: "Some Issues", value: "Some Issues" },
                            { label: "Vision Impaired", value: "Vision Impaired" }
                        ]}
                        onChange={(e) => setVision({ ...vision, visionIssues: e.target.value })}
                        required
                    />
                    <label htmlFor="visionDescription" className="font-bold">Vision Description</label>
                    <textarea
                        id="visionDescription"
                        rows={3}
                        autoComplete="off"
                        onChange={(e) => setVision({ ...vision, visionDescription: e.target.value })}
                        className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                        value={vision.visionDescription}
                    />
                </ClientTabForm>

                <ClientTabForm title='Memory Related Issues'>
                    <CustomSelect
                        label="Memory Related Issues"
                        options={[
                            { label: "No Issues", value: "No Issues" },
                            { label: "Some Issues", value: "Some Issues" },
                            { label: "Memory Impaired", value: "Memory Impaired" }
                        ]}
                        onChange={(e) => setMemory({ ...memory, memoryIssues: e.target.value })}
                        required
                    />
                    <label htmlFor="memoryDescription" className="font-bold">Memory Description</label>
                    <textarea
                        id="memoryDescription"
                        rows={3}
                        autoComplete="off"
                        onChange={(e) => setMemory({ ...memory, memoryDescription: e.target.value })}
                        className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                        value={memory.memoryDescription}
                    />
                </ClientTabForm>

                <ClientTabForm title='Communication'>
                    <CustomSelect
                        label="Need Communication Assistance?"
                        options={[
                            { label: "Need Communication Assistance", value: "Need Communication Assistance" }
                        ]}
                        onChange={(e) => setCommunication({ ...communication, needCommunicationAssistance: e.target.value })}
                        required
                    />
                    <CustomSelect
                        label="Communication Preferences"
                        options={[
                            { label: "Verbally", value: "Verbally" },
                            { label: "Ausian", value: "Ausian" },
                            { label: "Makaton", value: "Makaton" },
                            { label: "Combination of Ausian / Makaton", value: "Combination of Ausian / Makaton" }
                        ]}
                        onChange={(e) => setCommunication({ ...communication, communicationPreferences: e.target.value })}
                        required
                    />
                    <label htmlFor="communicationDescription" className="font-bold">Communication Description</label>
                    <textarea
                        id="communicationDescription"
                        rows={3}
                        autoComplete="off"
                        onChange={(e) => setCommunication({ ...communication, communicationDescription: e.target.value })}
                        className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                        value={communication.communicationDescription}
                    />
                </ClientTabForm>
            </div>
        </div>
    );
};

export default DisabilitySupportCom;