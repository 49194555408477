import React from 'react'
import { Client } from '../../../store/slices/clientSlice';
import { SupportPlaData } from '../../../types/DataType';

interface Props {
    clientDetail: Client | null;
    supportForm: SupportPlaData;
    handleInputDataEditChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
}

const EditDetails: React.FC<Props> = ({ clientDetail }) => {


    return (
        <div>
            <table className="w-full mb-6">
                <tbody>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Last name:</td>
                        <td className="border px-4 py-2"><input type="text"
                            className="w-full p-2 border border-gray-300 rounded"
                            value={clientDetail?.surName}
                        /></td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 font-bold">First name:</td>
                        <td className="border px-4 py-2"><input type="text"
                            className="w-full p-2 border border-gray-300 rounded"
                            value={clientDetail?.firstName}
                        /></td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Preferred name:</td>
                        <td className="border px-4 py-2"><input type="text"
                            className="w-full p-2 border border-gray-300 rounded"
                            value={clientDetail?.middleName}
                        /></td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Date of birth:</td>
                        <td className="border px-4 py-2"><input type="date"
                            className="w-full p-2 border border-gray-300 rounded"
                            value={clientDetail?.dateOfBirth}
                        /></td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Country of birth:</td>
                        <td className="border px-4 py-2"><input type="text"
                            className="w-full p-2 border border-gray-300 rounded"
                            value={clientDetail?.country}
                        /></td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Gender:</td>
                        <td className="border px-4 py-2">
                            <td className="border px-4 py-2"><input type="text"
                                className="w-full p-2 border border-gray-300 rounded"
                                value={clientDetail?.gender}
                            /></td>
                            {/* <div className="flex items-center">
                <label className="mr-4">
                  <input type="radio" name="gender" className="mr-2" value="Male" /> Male
                </label>
                <label className="mr-4">
                  <input type="radio" name="gender" className="mr-2" value="Female" /> Female
                </label>
                <label>
                  <input type="radio" name="gender" className="mr-2" value="Other" /> Other
                </label>
              </div> */}
                        </td>
                    </tr>
                    {/* <tr>
                        <td className="border px-4 py-2 font-bold">Other identity:</td>
                        <td className="border px-4 py-2">
                            <label>
                                <input type="checkbox" className="mr-2" /> LGBTQI+
                            </label>
                        </td>
                    </tr> */}
                    <tr>
                        <td className="border px-4 py-2 font-bold">Phone number:</td>
                        <td className="border px-4 py-2"><input type="tel"
                            className="w-full p-2 border border-gray-300 rounded"
                            value={clientDetail?.phoneNumber}
                        /></td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Mobile number:</td>
                        <td className="border px-4 py-2"><input type="tel"
                            className="w-full p-2 border border-gray-300 rounded"
                            value={clientDetail?.phoneNumber}
                        /></td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Email address:</td>
                        <td className="border px-4 py-2"><input type="email"
                            className="w-full p-2 border border-gray-300 rounded"
                            value={clientDetail?.email}
                        /></td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Preferred language or mode of communication:</td>
                        <td className="border px-4 py-2"><input type="text" className="w-full p-2 border border-gray-300 rounded"
                            value={clientDetail?.preferredLanguage}
                        /></td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Cultural / religious / beliefs preferences and requirements:</td>
                        <td className="border px-4 py-2"><input type="text" className="w-full p-2 border border-gray-300 rounded"
                            value={clientDetail?.culturalBackground}
                        /></td>
                    </tr>
                    {/* <tr>
                        <td className="border px-4 py-2 font-bold">Disability:</td>
                        <td className="border px-4 py-2"><input type="text" className="w-full p-2 border border-gray-300 rounded"
                            name='disability'
                            value={supportForm.disability}
                            onChange={handleInputDataEditChange}
                        /></td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Secondary disability:</td>
                        <td className="border px-4 py-2"><input type="text" className="w-full p-2 border border-gray-300 rounded"
                            name='secondaryDisability'
                            value={supportForm.secondaryDisability}
                            onChange={handleInputDataEditChange}
                        /></td>
                    </tr> */}
                </tbody>
            </table>
        </div>
    )
}

export default EditDetails