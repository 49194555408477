import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import fileImg from "../../../images/icon/EmptyState.png";
import { AppDispatch, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import Spinner from "../../../Components/Spinner";
import PaginationComponent from "../../../Components/CustomPagination";
import axiosInstance from "../../../store/axiosInstance";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import { Modal } from "rsuite";
import CustomInput from "../../../Components/Input/CustomInput";
import ClientRepCard from "./component/Card";
import {
  clientRepData,
  clientRepLoading,
  fetchClientRepresentative,
} from "../../../store/slices/ClientSlice/clientRepresentative";
import { Client } from "../../../store/slices/clientSlice";
import { ConfirmModal } from "../../Admin/RosteringManagement/ShiftRoster/components/ConfirmModal";

const ClientRep = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [loading1, setLoading1] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const fullName = useRef<HTMLInputElement>(null);
  const position = useRef<HTMLInputElement>(null);
  const email = useRef<HTMLInputElement>(null);
  const phoneNumber = useRef<HTMLInputElement>(null);
  const [editFullName, setEditFullName] = useState<string>("");
  const [editPositon, setEditPosition] = useState<string>("");
  const [editEmail, setEditEmail] = useState<string>("");
  const [editPhoneNumber, setEditPhoneNumber] = useState<string>("");
  const [idSave, setIdSave] = useState<number>(0);
  const clientProfileString = localStorage.getItem("clientProfile");
  const clientProfile: Client = clientProfileString
    ? JSON.parse(clientProfileString)
    : null;
  const dispatch: AppDispatch = useAppDispatch();
  // const companyId = user?.companyId;
  useEffect(() => {
    dispatch(fetchClientRepresentative(clientProfile.profileId));
  }, [dispatch, clientProfile.profileId]);

  const [editModal, setEditModal] = useState<boolean>(false);

  const handleDetails = async (id: number) => {
    setIdSave(id);
    setDeleteModal(true);
  };

  const handleEditReport = async (id: number) => {
    setIdSave(id);
    setLoading(true);
    setEditModal(true);
    try {
      const { data } = await axiosInstance.get(`/Assistances/${id}`);
      setEditFullName(data.fullName || "");
      setEditPosition(data.personel || "");
      setEditEmail(data.email || "");
      setEditPhoneNumber(data.phone || "");
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
        setLoading(false);
      } else {
        toast.error("An error occurred");
        setLoading(false);
      }
    } finally {
      setLoading(false);
    }
  };

  const loadingData = useSelector(clientRepLoading);
  const clientrep = useSelector(clientRepData);

  const itemsPerPage = 12;
  const pageCount = Math.ceil(clientrep.length / itemsPerPage);
  const displayData = clientrep.slice(
    pageNumber * itemsPerPage,
    (pageNumber + 1) * itemsPerPage
  );

  const topOfPageRef = useRef<HTMLInputElement>(null);

  const handlePageClick = (selectedItem: { selected: number }) => {
    setPageNumber(selectedItem.selected);
    topOfPageRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (
      fullName?.current?.value.trim() === null ||
      phoneNumber?.current?.value.trim() === null ||
      email?.current?.value.trim() === null
    ) {
      toast.error("Input Fields cannot be empty");
      return;
    }

    setLoading1(true);

    const info = {
      profileId: clientProfile.profileId,
      personel: position?.current?.value,
      fullName: fullName?.current?.value,
      phone: phoneNumber?.current?.value,
      email: email?.current?.value,
    };
    try {
      const { data } = await axiosInstance.post(`/Assistances`, info);
      toast.success(data.message);
      dispatch(fetchClientRepresentative(clientProfile.profileId));
      setLoading1(false);
      setShowModal(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
    } finally {
      setLoading1(false);
    }
  };

  const handleEditSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (
      editFullName.trim() === "" ||
      editPhoneNumber.trim() === "" ||
      editEmail.trim() === ""
    ) {
      toast.error("Input Fields cannot be empty");
      return;
    }

    setLoading1(true);

    const info = {
      assistanceId: idSave,
      profileId: clientProfile.profileId,
      personel: editPositon,
      fullName: editFullName,
      phone: editPhoneNumber,
      email: editEmail,
    };
    try {
      const { data } = await axiosInstance.post(
        `/Assistances/edit/${idSave}`,
        info
      );
      toast.success(data.message);
      dispatch(fetchClientRepresentative(clientProfile.profileId));
      setLoading1(false);
      setEditModal(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
    } finally {
      setLoading1(false);
    }
  };

  const handleDelete = async () => {
    setLoading1(true);
    try {
      const { data } = await axiosInstance.post(
        `/Assistances/delete/${idSave}`
      );
      toast.success(data.message);
      dispatch(fetchClientRepresentative(clientProfile.profileId));
      setLoading1(false);
      setDeleteModal(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
    } finally {
      setLoading1(false);
    }
  };

  return (
    <>
      <SEO title={` Repesentative`} description="Client" name="Promax-Care" />
      <Breadcrumb showNavigation={false} />

      <div className="flex justify-between">
        <div
          className="flex justify-between items-center flex-wrap"
          ref={topOfPageRef}
        >
          <h2 className="font-bold text-2xl text-black w-auto">
            Repesentative
          </h2>
        </div>
        <div>
          <button
            onClick={() => setShowModal(true)}
            className="bg-primary px-6 py-3 rounded-md text-white text-xs fornt-normal"
          >
            Add Representatives
          </button>
        </div>
      </div>

      {clientrep.length <= 0 && loadingData && <Spinner color="#071952" />}

      {!loadingData && clientrep.length <= 0 && (
        <div className=" border border-dashed flex justify-center flex-col items-center my-5 rounded py-10 space-y-2">
          <img src={fileImg} alt="file upload icon" width={200} height={300} />

          <strong className="text-xl">No Data Available</strong>
        </div>
      )}
      <div className="my-5">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 ">
          {clientrep.length > 0 &&
            displayData.map((item) => (
              <ClientRepCard
                key={item.assistanceId}
                date={item.dateCreated}
                email={item.email}
                fullName={item.fullName}
                phone={item.phone}
                position={item.personel}
                onDetails={() => handleDetails(item.assistanceId)}
                onAddReport={() => handleEditReport(item.assistanceId)}
              />
            ))}
        </div>

        {pageCount > 1 && (
          <PaginationComponent
            pageCount={pageCount}
            onPageChange={handlePageClick}
          />
        )}
        <br />
      </div>

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Add Representative</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={handleSubmit}>
              <div className="p-2 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
                <CustomInput
                  label="FullName"
                  type="text"
                  ref={fullName}
                  required
                />

                <CustomInput label="Email" type="email" ref={email} required />

                <CustomInput
                  label="Mobile Phone"
                  type="text"
                  ref={phoneNumber}
                  required
                />

                <CustomInput label="Position" type="text" ref={position} />
              </div>
              <div className="mx-auto text-center mt-3">
                <button
                  type="submit"
                  disabled={loading1}
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                    font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                    dark:focus:ring-primary"
                >
                  {loading1 ? <Spinner /> : "Create"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={editModal}
        onClose={() => setEditModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Edit Representative</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Spinner />
          ) : (
            <div>
              <form action="" onSubmit={handleEditSubmit}>
                <div className="p-2 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
                  <CustomInput
                    label="FullName"
                    type="text"
                    value={editFullName}
                    onChange={(e) => setEditFullName(e.target.value)}
                    required
                  />

                  <CustomInput
                    label="Email"
                    type="email"
                    value={editEmail}
                    onChange={(e) => setEditEmail(e.target.value)}
                    required
                  />

                  <CustomInput
                    label="Mobile Phone"
                    type="text"
                    value={editPhoneNumber}
                    onChange={(e) => setEditPhoneNumber(e.target.value)}
                    required
                  />

                  <CustomInput
                    label="Position"
                    type="text"
                    value={editPositon}
                    onChange={(e) => setEditPosition(e.target.value)}
                  />
                </div>
                <div className="mx-auto text-center mt-3">
                  <button
                    type="submit"
                    disabled={loading1}
                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                  >
                    {loading1 ? <Spinner /> : "Update"}
                  </button>
                </div>
              </form>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <ConfirmModal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        onConfirm={handleDelete}
        title=""
        content={
          <p className="mb-4 font-bold text-center">
            Are you sure you want to delete this?
          </p>
        }
        isLoading={loading1}
      />
    </>
  );
};

export default ClientRep;
