import React from "react";
// import ServiceTable from './ServiceTable';
import FirstForm from "./FirstForm";
import Details from "./Details";
import RepContact from "./RepContact";
// import Summary from './Summary';
import { Client } from "../../../store/slices/clientSlice";
// import { Staff } from '../../../store/slices/staffSlice';
import CourtOrder from "./CourtOrder";
import Communication from "./Communication";
import Cultural from "./Cultural";
import Decision from "./Decision";
import Support from "./Support";
import Risk from "./Risk";
import { FormDataType, Row, RowKeys } from "..";
import AgreementSignature from "./Agreement";
import { CompanyDetail } from "../../../types/AuthData";
import { Representative } from "../../../types/DataType";

interface Props {
  clientDetail: Client | null;
  careManager: Representative | null;
  values: {
    disability: string;
    secondaryDisability: string;
    meetingDate: string;
    staffingRequest: string;
  };
  handleInputDataChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  attendeeValues: string[];
  handleInputChange: (index: number, value: string) => void;
  handleAddRow: () => void;
  otherValues: {
    isNDISPlanAttached: boolean;
    isConsentSigned: boolean;
    staffingRequest: boolean;
  };
  handleRadioChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  formData: FormDataType;
  handleInputFormChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  handleRadioFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  supportRequired: string[];
  handleSupportInputChange: (index: number, value: string) => void;
  handleSupportAddRow: () => void;
  rows: Row[];
  handleCellChange: (
    rowIndex: number,
    cellName: RowKeys,
    value: string
  ) => void;
  companyOne: CompanyDetail | null | undefined;
}

const SupportPlan: React.FC<Props> = ({
  clientDetail,
  values,
  handleInputDataChange,
  handleRadioFormChange,
  attendeeValues,
  handleAddRow,
  handleInputChange,
  careManager,
  formData,
  handleInputFormChange,
  handleSupportAddRow,
  handleSupportInputChange,
  supportRequired,
  rows,
  handleCellChange,
  companyOne,
}) => {
  return (
    <div className="max-w-6xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Support Plan Form</h1>

      <FirstForm
        values={values}
        handleAddRow={handleAddRow}
        handleInputChange={handleInputChange}
        handleInputDataChange={handleInputDataChange}
        attendeeValues={attendeeValues}
      />

      <h2 className="text-xl font-bold mb-4">Participant Details</h2>

      <Details
        clientDetail={clientDetail}
        values={values}
        handleInputDataChange={handleInputDataChange}
      />

      <h2 className="text-xl font-bold">
        Primary participant family or representative details (emergency contact)
      </h2>

      <RepContact
        careManager={careManager}
        formData={formData}
        handleInputFormChange={handleInputFormChange}
        handleRadioFormChange={handleRadioFormChange}
      />

      <h2 className="text-xl font-bold">Court orders</h2>
      <CourtOrder
        formData={formData}
        handleInputFormChange={handleInputFormChange}
        handleRadioFormChange={handleRadioFormChange}
      />
      <h2 className="text-xl font-bold">Communication</h2>
      <Communication
        formData={formData}
        handleInputFormChange={handleInputFormChange}
      />
      <h2 className="text-xl font-bold">
        Cultural and spiritual considerations
      </h2>
      <Cultural
        formData={formData}
        handleInputFormChange={handleInputFormChange}
      />
      <h2 className="text-xl font-bold">Decision making</h2>
      <Decision
        formData={formData}
        handleInputFormChange={handleInputFormChange}
      />
      <h2 className="text-xl font-bold">Support requirements</h2>
      <Support
        handleSupportAddRow={handleSupportAddRow}
        handleSupportInputChange={handleSupportInputChange}
        supportRequired={supportRequired}
      />
      <h2 className="text-xl font-bold">
        Risk management (for any risk identified when completing this form)
      </h2>
      <Risk
        handleCellChange={handleCellChange}
        rows={rows}
        formData={formData}
        handleInputFormChange={handleInputFormChange}
      />
      <AgreementSignature
        careManager={careManager}
        handleSignatureSave={() => ""}
        signature={null}
        clientDetail={clientDetail}
        setSignature={() => ""}
        clientRep={null}
        companyOne={companyOne}
        careManagerName={""}
        setCareManagerName={() => ""}
      />
    </div>
  );
};

export default SupportPlan;
