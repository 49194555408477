import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import DataTable, {
  TableColumn,
  ExpanderComponentProps,
} from "react-data-table-component";
import { FaFileCsv, FaFileExcel, FaFilePdf, FaRegEdit } from "react-icons/fa";
import { FaUsers } from "react-icons/fa6";
import { BsSearch } from "react-icons/bs";
import ExportDropdown from "../../../Components/menu/ExportDropdown";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import {
  fetchServiceProvider,
  serviceProviderData,
  serviceProviderLoading,
} from "../../../store/slices/serviceProviderSlice";
import { MdLibraryAdd } from "react-icons/md";
import Spinner from "../../../Components/Spinner";
import axiosInstance from "../../../store/axiosInstance";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import CustomInput from "../../../Components/Input/CustomInput";
import { InputPicker, Modal, Tooltip, Whisper } from "rsuite";
import IconButton from "../../../Components/Buttons/IconButton";
import {
  downloadCSV,
  exportToExcel,
  handlePDFDownload,
} from "../../../services/reusableFunc";
import { Column } from "../../../types/DataType";
import { clientData, fetchClient } from "../../../store/slices/clientSlice";
import { Link } from "react-router-dom";

interface DataRow {
  address: string;
  company: string;
  companyId: number;
  email: string;
  fullName: string;
  lastName: string;
  name: string;
  phone: number;
  referralCompany: string;
  serviceProvidersId: string;
}

const ServiceProvider = () => {
  const columns: TableColumn<DataRow>[] = [
    {
      name: "Organization",
      selector: (row) => row.referralCompany,
      sortable: true,
    },
    {
      name: "FullName",
      selector: (row) => row.fullName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },

    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },

    {
      name: "Actions",
      cell: (row) => (
        <span className="flex gap-4">
          <div>
            <Whisper
              placement="top"
              controlId="control-id-hover"
              trigger="hover"
              speaker={<Tooltip>Edit Service Provider Details</Tooltip>}
            >
              <button
                onClick={() => handleEditClick(row.serviceProvidersId)}
                className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
              >
                <FaRegEdit className="text-base cursor-pointer" />
              </button>
            </Whisper>
          </div>

          <div>
            <Whisper
              placement="top"
              controlId="control-id-hover"
              trigger="hover"
              speaker={<Tooltip>Assign Client to Service Provider</Tooltip>}
            >
              <button
                onClick={() => handleAssignClick(row.serviceProvidersId)}
                className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
              >
                <MdLibraryAdd className="text-base cursor-pointer" />
              </button>
            </Whisper>
          </div>

          <div>
            <Whisper
              placement="top"
              controlId="control-id-hover"
              trigger="hover"
              speaker={<Tooltip>View Client</Tooltip>}
            >
              <Link to={`/app/user/provider-client/${row.email}/${"referral"}`}>
                <button className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline">
                  <FaUsers className="text-base cursor-pointer" />
                </button>
              </Link>
            </Whisper>
          </div>
        </span>
      ),
    },
  ];

  const [loading1, setLoading1] = useState<boolean>(false);
  const organisation = useRef<HTMLInputElement>(null);
  const firstName = useRef<HTMLInputElement>(null);
  const lastName = useRef<HTMLInputElement>(null);
  const email = useRef<HTMLInputElement>(null);
  const phoneNumber = useRef<HTMLInputElement>(null);
  const address = useRef<HTMLInputElement>(null);
  const [addUserModal, setAddUserModal] = useState<boolean>(false);
  const [serviceProModal, setServiceProModal] = useState<boolean>(false);
  const [saveId, setSaveId] = useState<string>("");
  const [profileIds, setProfileId] = useState<number>(0);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [details, setDetails] = useState<DataRow>({
    address: "",
    company: "",
    companyId: 0,
    email: "",
    fullName: "",
    lastName: "",
    name: "",
    phone: 0,
    referralCompany: "",
    serviceProvidersId: "",
  });
  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();
  const companyId = user?.companyId;
  useEffect(() => {
    if (companyId) {
      dispatch(fetchServiceProvider(companyId));
      dispatch(fetchClient(companyId));
    }
  }, [dispatch, companyId]);
  const service = useSelector(serviceProviderData);
  const client = useSelector(clientData);
  const loading = useSelector(serviceProviderLoading);
  interface Props extends ExpanderComponentProps<DataRow> {
    // currently, props that extend ExpanderComponentProps must be set to optional.
    someTitleProp?: string;
  }

  const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
    return (
      <div className="p-2 flex flex-col gap-2 text-xs">
        <div>
          <span className="font-bold">Email: </span> {data.email}
        </div>
        <div>
          <span className="font-bold">Phone: </span> {data.phone}
        </div>
      </div>
    );
  };

  const handleEditClick = async (id: string) => {
    setEditModal(true);
    try {
      const { data } = await axiosInstance.get(
        `/ClientReferrals/get_service_provider/${id}`
      );
      setDetails({ ...data });
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
    }
  };
  const handleAssignClick = async (id: string) => {
    setSaveId(id);
    setServiceProModal(true);
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [searchText, setSearchText] = useState<string>("");

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredData = service?.filter(
    (item) =>
      item?.fullName?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.email?.toLowerCase().includes(searchText.toLowerCase())
  );

  const HandleEdit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading1(true);
    const info = {
      ...details,
      companyId: companyId,
    };
    try {
      const { data } = await axiosInstance.post(
        `/ClientReferrals/edit_service_provider/${details.serviceProvidersId}?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      companyId && dispatch(fetchServiceProvider(companyId));
      setEditModal(false);
      setLoading1(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
    }
  };

  const handleServiceProSave = async (e: FormEvent) => {
    e.preventDefault();
    setLoading1(true);

    const info = {
      serviceProvidersId: saveId,
      clientId: profileIds,
      companyId: companyId,
    };
    try {
      const { data } = await axiosInstance.post(
        `/ClientServiceProviders/assign_client_serviceprovider?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      setServiceProModal(false);
      // FetchClient();
      setLoading1(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
      setServiceProModal(false);
    } finally {
      setLoading1(false);
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (
      organisation?.current?.value === "" ||
      firstName?.current?.value === "" ||
      phoneNumber?.current?.value === "" ||
      email?.current?.value === ""
    ) {
      return toast.error("Marked Fields must be filled");
    }

    setLoading1(true);

    const info = {
      name: firstName?.current?.value,
      lastName: lastName.current?.value,
      email: email?.current?.value,
      phone: phoneNumber?.current?.value,
      address: address.current?.value,
      referralCompany: organisation?.current?.value,
      companyId: companyId,
    };

    try {
      const { data } = await axiosInstance.post(
        `/ClientReferrals/add_serviceprovider?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      setLoading1(false);
      companyId && dispatch(fetchServiceProvider(companyId));
      setAddUserModal(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
      setAddUserModal(false);
    } finally {
      setLoading1(false);
    }
  };

  return (
    <>
      <SEO
        title="Service Providers"
        description="Service Provider"
        name="Promax-Care"
      />
      <Breadcrumb />
      <div className="mt-10">
        {service.length <= 0 && loading && <Spinner color="#071952" />}
        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
          <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
            <h2 className="font-bold text-xl text-black w-auto">
              Service Provider
            </h2>

            <div className="relative w-full lg:col-span-2">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <BsSearch />
              </div>
              <input
                type="text"
                id="simple-search"
                onChange={handleSearch}
                className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Search By Full Name or Email"
              />
            </div>
          </div>
          <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
            <div className="flex items-center space-x-3 w-full md:w-auto">
              {/* <button id="actionsDropdownButton" className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                                <FaFilter />
                                Filter
                            </button> */}
              <ExportDropdown>
                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <IconButton
                    icon={<FaFilePdf className="text-red-500" />}
                    title="Export as PDF"
                    onClick={() =>
                      handlePDFDownload(
                        service,
                        columns as unknown as Column<DataRow>[],
                        "ServiceProvider.pdf"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileExcel className="text-green-600" />}
                    title="Export as Excel Sheet"
                    onClick={() =>
                      exportToExcel(
                        service,
                        columns as unknown as Column<DataRow>[],
                        "ServiceProvider"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileCsv className="text-green-500" />}
                    title="Export as CSV"
                    onClick={() =>
                      downloadCSV({
                        data: service,
                        filename: "ServiceProvider.csv",
                      })
                    }
                  />
                </div>
              </ExportDropdown>
            </div>
            <button
              type="button"
              onClick={() => setAddUserModal(true)}
              className="px-4 py-2 bg-primary text-white  disabled:bg-slate-400
                            transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm 
                           "
            >
              Add Service Provider
            </button>
          </div>
        </div>

        <DataTable
          columns={columns}
          data={filteredData}
          expandableRows
          expandableRowsComponent={ExpandableRowComponent}
          pagination
          paginationTotalRows={service?.length}
          // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}
        />
      </div>

      <Modal
        open={serviceProModal}
        onClose={() => setServiceProModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
        overflow
      >
        <Modal.Header>
          <Modal.Title className="font-bold">
            Assign Client to Service Provider
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form action="" className="space-y-2">
              <div>
                <label className=" text-xs text-gray-600 font-semibold">
                  Client
                </label>
                <InputPicker
                  size="lg"
                  placeholder={"--Select a Client--"}
                  onChange={(value) => setProfileId(value)}
                  data={client.map((client) => ({
                    label: client.fullName,
                    value: client.profileId,
                  }))}
                  className="w-full focus:outline-none focus:border-primary text-gray-600"
                  appearance="subtle"
                />
              </div>

              <div className="mx-auto text-center mt-3">
                <button
                  type="submit"
                  onClick={handleServiceProSave}
                  disabled={loading ? true : false}
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                >
                  {loading ? <Spinner /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={addUserModal}
        onClose={() => setAddUserModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Add Service Provider</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form action="" onSubmit={handleSubmit}>
              <div className="p-2 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
                <CustomInput
                  label="Organisation"
                  type="text"
                  ref={organisation}
                  required
                  placeholder="Organisation"
                />

                <CustomInput
                  label="First Name"
                  type="text"
                  ref={firstName}
                  required
                  placeholder="First Name"
                />

                <CustomInput
                  label="Last Name"
                  type="text"
                  ref={lastName}
                  placeholder="Last Name"
                />
                <CustomInput
                  label="Email"
                  type="email"
                  ref={email}
                  required
                  placeholder="Email"
                />

                <CustomInput
                  label="Phone Number"
                  type="tel"
                  ref={phoneNumber}
                  required
                  placeholder="Phone Number"
                />

                <CustomInput
                  label="Address"
                  type="text"
                  ref={address}
                  placeholder="Address"
                />
              </div>

              <div className="mx-auto text-center mt-3">
                <button
                  type="submit"
                  disabled={loading1 ? true : false}
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                    font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                    dark:focus:ring-primary"
                >
                  {loading1 ? <Spinner /> : "Create"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={editModal}
        onClose={() => setEditModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">
            Edit Service Provider Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form action="" onSubmit={HandleEdit}>
              <div className="p-2 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
                <CustomInput
                  label="Organisation"
                  type="text"
                  name="referralCompany"
                  value={details?.referralCompany}
                  onChange={handleInputChange}
                  required
                  placeholder="Organisation"
                />

                <CustomInput
                  label="First Name"
                  type="text"
                  name="name"
                  value={details?.name}
                  onChange={handleInputChange}
                  required
                  placeholder="First Name"
                />

                <CustomInput
                  label="Last Name"
                  type="text"
                  name="lastName"
                  value={details?.lastName}
                  onChange={handleInputChange}
                  placeholder="Last Name"
                />
                <CustomInput
                  label="Email"
                  type="email"
                  name="email"
                  value={details?.email}
                  onChange={handleInputChange}
                  required
                  placeholder="Email"
                />

                <CustomInput
                  label="Phone Number"
                  type="tel"
                  name="phone"
                  value={details?.phone}
                  onChange={handleInputChange}
                  required
                  placeholder="Phone Number"
                />

                <CustomInput
                  label="Address"
                  type="text"
                  name="address"
                  value={details?.address}
                  onChange={handleInputChange}
                  placeholder="Address"
                />
              </div>

              <div className="mx-auto text-center mt-3">
                <button
                  type="submit"
                  disabled={loading1 ? true : false}
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                >
                  {loading1 ? <Spinner /> : "Update"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default ServiceProvider;
