import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import DataTable, {
  TableColumn,
  ExpanderComponentProps,
} from "react-data-table-component";
import { BsSearch } from "react-icons/bs";
import ExportDropdown from "../../../Components/menu/ExportDropdown";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import Spinner from "../../../Components/Spinner";
import { FaFileCsv, FaFileExcel, FaFilePdf } from "react-icons/fa";
import IconButton from "../../../Components/Buttons/IconButton";
// import { Tooltip, Whisper } from 'rsuite';
import axiosInstance from "../../../store/axiosInstance";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import {
  downloadCSV,
  exportToExcel,
  handlePDFDownload,
} from "../../../services/reusableFunc";
import { Column } from "../../../types/DataType";
import { fetchClient } from "../../../store/slices/clientSlice";
import { useParams } from "react-router-dom";
import { Button, Modal } from "rsuite";
import CustomInput from "../../../Components/Input/CustomInput";
import {
  fetchSupportType,
  supportTypelateData,
} from "../../../store/slices/supportTypeSlice";

interface DataRow {
  description: string;
  itemNumber: number;
  day: string;
  unitPrice: string;
  ndiA_DPA: {
    dateCreated: string;
    dateModified: string;
    itemName: string;
    itemNumber: string;
    national: number;
    ndiA_DPAId: number;
    remote: number;
    unit: string;
    userCreated: string;
    userModified: string;
    veryRemote: number;
  };
  ndiA_DPAId: number;
  agreedDuration: number;
  amount: number;
  companyID: number;
  date: string;
  dateCreated: string;
  dateFrom: string;
  dateModified: string;
  dateTo: string;
  duration: number;
  invoicesId: number;
  profileId: number;
  shiftPeriod: string;
  totalKm: number;
  userCreated: string;
  userModified: string;
}

interface Total {
  xeroUploadLink: string;
  xeroAgreedUploadLink: string;
  xeroGroupUploadLink: string;
  xeroAgreedGroupUploadLink: string;
  totalKm: number;
}

function formatDuration(duration: number | string): string {
  const durationInTicks = BigInt(duration);
  const durationInMilliseconds = Number(durationInTicks) / 10000; // Convert ticks to milliseconds

  const durationInSeconds = Math.floor(durationInMilliseconds / 1000); // Convert to seconds
  const totalMinutes = Math.floor(durationInSeconds / 60);
  const totalHours = Math.floor(totalMinutes / 60);
  const remainingMinutes = totalMinutes % 60;

  let result = "";

  if (totalHours > 0) {
    result += `${totalHours} Hrs`;
  }

  if (remainingMinutes > 0) {
    result += ` ${remainingMinutes} Min`;
  }

  return result.trim(); // Remove trailing space
}

const ViewSavedInvoice = () => {
  const columns: TableColumn<DataRow>[] = [
    {
      name: "Description",
      selector: (row) => row?.ndiA_DPA?.itemName,
      sortable: true,
    },
    {
      name: "Day/Date",
      selector: (row) =>
        row.date ? dayjs(row.date).format("ddd, MMM D, YYYY") : row.day,
      sortable: true,
    },
    {
      name: "Actual Hours",
      selector: (row) => formatDuration(row.duration),
      sortable: true,
    },
    {
      name: "Agreed Hours",
      selector: (row) => row.agreedDuration,
      sortable: true,
    },
    {
      name: "Unit Price",
      selector: (row) => row.ndiA_DPA?.national,
      sortable: true,
    },
    {
      name: "Amount ($)",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Total Km",
      selector: (row) => row.totalKm,
      sortable: true,
    },
  ];

  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();
  const companyId = user?.companyId;
  useEffect(() => {
    if (companyId) {
      dispatch(fetchClient(companyId));
      dispatch(fetchSupportType(companyId));
    }
  }, [dispatch, companyId]);

  const supportType = useSelector(supportTypelateData);
  const [loading1, setLoading1] = useState<boolean>(false);
  const [total, setTotal] = useState<Total | null>(null);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [totalAgreed, setTotalAgreed] = useState<number>(0);
  const [totalDuration, setTotalDuration] = useState<number>(0);
  const { from, to, uid } = useParams();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<DataRow[]>([]);
  const [editModal, setEditModal] = useState<boolean>(false);

  const FetchInvoice = async () => {
    setLoading1(true);
    try {
      const { data } = await axiosInstance.get(
        `/Invoice/list_invoices?fromDate=${from}&toDate=${to}&clientId=${uid}`
      );
      // console.log(data);
      setInvoice(data?.invoices);
      setTotal(data);
      setTotalAgreed(data?.totalAgreedDuration);
      setTotalAmount(data?.totalAmount);
      setTotalDuration(data?.totalDuration);
      setLoading1(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      }
      setLoading1(false);
    } finally {
      setLoading1(false);
    }
  };

  useEffect(() => {
    FetchInvoice();
  }, []);

  interface Props extends ExpanderComponentProps<DataRow> {
    // currently, props that extend ExpanderComponentProps must be set to optional.
    someTitleProp?: string;
  }

  const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
    return (
      <div className="p-2 flex flex-col gap-2 text-xs">
        <span>
          <span className="font-bold">Description: </span>
          <span> {data?.ndiA_DPA?.itemName}</span>
        </span>
        <div>
          <button
            className="btn text-primary font-bold"
            title="Edit"
            onClick={() => EditInvoice(data.invoicesId)}
          >
            Edit
          </button>
        </div>
      </div>
    );
  };

  const [searchText, setSearchText] = useState<string>("");

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredData = invoice.filter((item) =>
    item.dateFrom?.toLowerCase().includes(searchText.toLowerCase())
  );
  const [selectData, setSelectData] = useState<DataRow>({
    agreedDuration: 0,
    amount: 0,
    companyID: 0,
    date: "",
    dateCreated: "",
    dateFrom: "",
    dateModified: "",
    dateTo: "",
    day: "",
    description: "",
    duration: 0,
    invoicesId: 0,
    itemNumber: 0,
    ndiA_DPA: {
      dateCreated: "",
      dateModified: "",
      itemName: "",
      itemNumber: "",
      national: 0,
      ndiA_DPAId: 0,
      remote: 0,
      unit: "",
      userCreated: "",
      userModified: "",
      veryRemote: 0,
    },
    ndiA_DPAId: 0,
    profileId: 0,
    shiftPeriod: "",
    totalKm: 0,
    unitPrice: "",
    userCreated: "",
    userModified: "",
  });
  const EditInvoice = async (id: number) => {
    // setSaveId(id);
    setEditModal(true);
    try {
      const { data } = await axiosInstance.get(
        `/Invoice/get_invoicedetails/${id}`
      );
      setSelectData(data);
      // setHoliDetails({ ...data });
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
    }
  };

  const [amount, setAmount] = useState<number>(selectData?.amount || 0);
  const [ndiA, setNdiA] = useState<number>(0);

  // Handler for changes in the description dropdown or similar input
  const handleDescriptionChange = (value: number) => {
    // Adjust type based on actual value type
    // Find the selected description based on the value
    const selectedDescription = supportType.find(
      (data) => data.ndiA_DPAId === value
    );

    // Update the state variables related to the description
    setNdiA(value); // Ensure `setNdiA` is defined and typed correctly

    // Update the amount based on the selected description
    setAmount(selectedDescription ? selectedDescription.national : 0);
  };

  // Effect to update amount when selectData changes
  useEffect(() => {
    setAmount(selectData?.amount || 0);
  }, [selectData]);

  const HandleEdit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading1(true);
    const info = {
      invoicesId: selectData.invoicesId,
      ndiA_DPAId: ndiA,
      duration: selectData.duration,
      totalKm: selectData.totalKm,
      agreedDuration: selectData.agreedDuration,
      amount: amount,
      date: selectData.date,
      dateFrom: selectData.dateFrom,
      dateTo: selectData.dateTo,
      shiftPeriod: selectData.shiftPeriod,
      profileId: selectData.profileId,
      companyID: selectData.companyID,
    };
    try {
      const { data } = await axiosInstance.post(
        `/Invoice/edit_invoice/${selectData.invoicesId}?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      FetchInvoice();
      setEditModal(false);
      setLoading1(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
    }
  };

  const handleDelete = async (e: FormEvent) => {
    e.preventDefault();
    setLoading1(true);
    try {
      const { data } = await axiosInstance.get(
        `/Invoice/delete_invoices?userId=${user?.uid}&fromDate=${from}&toDate=${to}&clientId=${uid}`
      );
      toast.success(data.message);
      setDeleteModal(false);
      FetchInvoice();
      setLoading1(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
      setDeleteModal(false);
    } finally {
      setLoading1(false);
    }
  };

  const [loading2, setLoading2] = useState<boolean>(false);
  const generateUngroupedPdf = () => {
    setLoading2(true);
    from && localStorage.setItem("invoiceDateFrom", from);
    to && localStorage.setItem("invoiceDateTo", to);
    uid && localStorage.setItem("invoicePro", uid);
    uid && localStorage.setItem("ttp", "Yes");
    setTimeout(() => {
      const url = `/pdf/loadUngroupedInvoice-pdf/gen_rate`;
      window.open(url, "_blank");
      setLoading2(false);
    }, 2000);
  };

  return (
    <>
      <SEO title="All Invoice" description="All Invoice" name="Promax-Care" />
      <Breadcrumb />
      <div className="mt-10">
        {loading1 && <Spinner color="#071952" />}
        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
          <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
            <h2 className="font-bold text-xl text-black w-auto">Invoice</h2>

            <div className="relative w-full lg:col-span-2">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <BsSearch />
              </div>
              <input
                type="text"
                id="simple-search"
                onChange={handleSearch}
                className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Search"
              />
            </div>
          </div>
          <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
            <div className="flex items-center space-x-3 w-full md:w-auto">
              <ExportDropdown>
                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <IconButton
                    icon={<FaFilePdf className="text-red-500" />}
                    title="Export as PDF"
                    onClick={() =>
                      handlePDFDownload(
                        invoice,
                        columns as unknown as Column<DataRow>[],
                        "Invoice.pdf"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileExcel className="text-green-600" />}
                    title="Export as Excel Sheet"
                    onClick={() =>
                      exportToExcel(
                        invoice,
                        columns as unknown as Column<DataRow>[],
                        "Invoice"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileCsv className="text-green-500" />}
                    title="Export as CSV"
                    onClick={() =>
                      downloadCSV({ data: invoice, filename: "Invoice.csv" })
                    }
                  />
                </div>
              </ExportDropdown>
            </div>
          </div>
        </div>
        {invoice.length > 0 && (
          <div className="flex gap-2 justify-end flex-wrap">
            <div>
              <a
                href={total?.xeroUploadLink}
                type="submit"
                target="_blank"
                rel="noreferrer"
                className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-bold rounded-md text-md px-6 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
              >
                Post Actual Hours to Xero
              </a>
            </div>
            <div>
              <a
                href={total?.xeroAgreedUploadLink}
                type="submit"
                target="_blank"
                rel="noreferrer"
                className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-bold rounded-md text-md px-6 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
              >
                Post Agreed Hours to Xero
              </a>
            </div>
            <div>
              <button
                onClick={generateUngroupedPdf}
                disabled={loading2 ? true : false}
                className="text-white bg-gray-500 hover:bg-gray-500 focus:ring-4 focus:ring-gray-500 
font-bold rounded-md text-md px-6 py-2.5 me-2 mt-2 dark:bg-gray-500 dark:hover:bg-gray-500 focus:outline-none
dark:focus:ring-primary"
              >
                {loading2 ? <Spinner /> : "Generate PDF"}
              </button>
            </div>
            <div>
              <button
                onClick={() => setDeleteModal(true)}
                // disabled={loading ? true : false}
                className="text-white bg-red-500 hover:bg-red-500 focus:ring-4 focus:ring-red-500 
font-bold rounded-md text-md px-6 py-2.5 me-2 mt-2 dark:bg-red-500 dark:hover:bg-red-500 focus:outline-none
dark:focus:ring-primary"
              >
                {loading1 ? <Spinner /> : "Delete Invoice"}
              </button>
            </div>
          </div>
        )}
        <div className="flex px-2 py-3 justify-evenly items-center">
          <div>
            <span>Total Duration: </span>
            <span className="font-bold ">{formatDuration(totalDuration)}</span>
          </div>
          <div>
            <span>Total Amount: </span>
            <span className="font-bold ">{totalAmount}</span>
          </div>
          <div>
            <span>Total Agreed Duration: </span>
            <span className="font-bold ">{totalAgreed}</span>
          </div>
          <div>
            <span>Total Km: </span>
            <span className="font-bold ">{total?.totalKm}</span>
          </div>
        </div>
        <DataTable
          columns={columns}
          data={filteredData}
          expandableRows
          expandableRowsComponent={ExpandableRowComponent}
          pagination
          paginationTotalRows={filteredData?.length}
          // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}
        />
      </div>

      <Modal
        open={editModal}
        onClose={() => setEditModal(false)}
        backdrop="static"
        autoFocus
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Edit Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form action="">
              <div className="space-y-1 p-2 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
                <div>
                  <label className=" mb-2 text-xs flex gap-1 text-gray-600 font-semibold dark:text-white">
                    Description
                  </label>
                  <div className="relative block overflow-hidden  border border-gray-300 h-11  otline-none rounded">
                    <select
                      className="border-none text-gray-900 text-sm outline-none   block bg-white w-full h-full px-2.5"
                      onChange={(e) =>
                        handleDescriptionChange(Number(e.target.value))
                      }
                    >
                      <option value={""}>
                        {selectData?.ndiA_DPA?.itemName
                          ? selectData?.ndiA_DPA?.itemName
                          : "--Select Activities--"}
                      </option>
                      {supportType.map((data, index) => (
                        <option value={data.ndiA_DPAId} key={index}>
                          {data.itemName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <CustomInput
                  label="Actual Duration"
                  type="text"
                  name="duration"
                  value={formatDuration(selectData?.duration || 0)}
                  readOnly
                  placeholder="Actual Duration"
                />

                <CustomInput
                  label="Total Km"
                  type="text"
                  placeholder="Total Km"
                  name="totalKm"
                  value={selectData?.totalKm || 0}
                  readOnly
                />

                <CustomInput
                  label="Agreed Duration"
                  type="text"
                  name="agreedDuration"
                  value={selectData?.agreedDuration || 0}
                  placeholder="Agreed Duration"
                  readOnly
                />

                <CustomInput
                  label="Unit Price"
                  type="text"
                  name="unit_price"
                  value={amount}
                  placeholder="Unit Price"
                  readOnly
                />

                <CustomInput
                  label="Date"
                  type="text"
                  name="date"
                  value={
                    selectData?.date
                      ? dayjs(selectData.date).format("ddd, MMM D, YYYY")
                      : ""
                  }
                  placeholder="Date"
                  readOnly
                />

                <div className="col-span-2">
                  <CustomInput
                    label="Amount ($)"
                    type="text"
                    name="date"
                    value={(
                      amount * Number(selectData?.agreedDuration || 0)
                    ).toFixed(2)}
                    readOnly
                  />
                </div>
              </div>
            </form>
            <div className="mx-auto text-center mt-3">
              <button
                type="submit"
                onClick={HandleEdit}
                disabled={loading1 ? true : false}
                className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                    font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                    dark:focus:ring-primary"
              >
                {loading1 ? <Spinner /> : "Update"}
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Delete Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="mb-4 font-bold">
              Are you sure you want to delete this Invoice?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <Button
                onClick={() => setDeleteModal(false)}
                className="bg-gray-500 text-white"
              >
                No, cancel
              </Button>
              <Button
                onClick={handleDelete}
                className="bg-red-500 text-white hover:bg-red-700 hover:text-white"
              >
                {loading1 ? <Spinner /> : "Yes, I'm sure"}
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewSavedInvoice;
