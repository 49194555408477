import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import WeekView from "./Components/WeekView";
import { useSelector } from "react-redux";
import { AppDispatch, useAppDispatch } from "../../../store/store";
import SEO from "../../../constants/SEO";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import Spinner from "../../../Components/Spinner";
import { Client } from "../../../store/slices/clientSlice";
import {
  clientShiftData,
  clientShiftLoading,
  fetchClientShift,
} from "../../../store/slices/clientShiftSlice";
import DayView from "./Components/DayView";

const ClientShiftRoster = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault("Australia/Sydney");
  const dispatch: AppDispatch = useAppDispatch();

  const [today] = useState<string>(dayjs().tz().format("ddd, MMM D, YYYY"));
  //weekView...
  const [currentDate, setCurrentDate] = useState(dayjs().tz());
  const daysOfWeek = [
    // currentDate.subtract(3, 'day'),
    // currentDate.subtract(2, 'day'),
    // currentDate.subtract(1, 'day'),
    currentDate,
    currentDate.add(1, "day"),
    currentDate.add(2, "day"),
    currentDate.add(3, "day"),
    currentDate.add(4, "day"),
    currentDate.add(5, "day"),
    currentDate.add(6, "day"),
  ];
  const clientProfileString = localStorage.getItem("clientProfile");
  const clientProfile: Client = clientProfileString
    ? JSON.parse(clientProfileString)
    : null;
  // const user = useSelector((state: RootState) => state?.auth?.user);

  useEffect(() => {
    dispatch(fetchClientShift(clientProfile.profileId));
  }, [dispatch]);

  const shift = useSelector(clientShiftData);
  const loading = useSelector(clientShiftLoading);

  const handleNextClick = () => {
    setCurrentDate(currentDate.add(6, "day"));
  };

  const handlePrevClick = () => {
    setCurrentDate(currentDate.subtract(6, "day"));
  };

  const handleShiftClick = () => {
    console.log("");
  };

  return (
    <>
      <SEO
        title={` Client shift Roster`}
        description="Staff"
        name="Promax-Care"
      />
      <Breadcrumb showNavigation={false} />

      <div className={`border rounded-lg my-5`}>
        <div className="">
          {shift.length <= 0 && loading && (
            <div className="bg-white p-2">
              <Spinner color="#071952" />
              <p className="text-center"> Loading Shifts</p>
            </div>
          )}
        </div>

        <div className="bg-white flex justify-between items-center flex-wrap p-2 ">
          <div className=" text-center flex gap-4 items-center text-sm font-bold text-black rounded-t-lg flex-wrap mb-2">
            {/* <span className={`rounded py-1 px-2 font-normal text-black border border-gray-200 inline-flex gap-1 items-center ${calendarView === "month" && "bg-gray-200"} !capitalize cursor-pointer`} onClick={() => setCalendarView('month')}><MdCalendarMonth /> Month</span>
                <span className={`rounded py-1 px-2 font-normal text-black border border-gray-200 inline-flex gap-1 items-center ${calendarView === "week" && "bg-gray-200"} !capitalize cursor-pointer`} onClick={() => setCalendarView('week')}><MdCalendarMonth /> Week</span>
                 */}
            <span className="border  flex justify-between gap-3 items-center bg-gray-100 rounded">
              <button
                onClick={handlePrevClick}
                className="p-2  bg-gray-800 text-white font-bold"
              >
                <FaChevronLeft />
              </button>
              {currentDate.format("MMM YYYY")}
              <button
                onClick={handleNextClick}
                className="p-2  bg-gray-800 text-white font-bold"
              >
                <FaChevronRight />
              </button>
            </span>
          </div>
        </div>
        <div className="hidden lg:block  ">
          <WeekView
            shift={shift}
            daysOfWeek={daysOfWeek}
            today={today}
            handleShiftClick={handleShiftClick}
          />
        </div>
        <div className="block lg:hidden">
          <DayView shift={shift} daysOfWeek={daysOfWeek} today={today} />
        </div>
      </div>
    </>
  );
};

export default ClientShiftRoster;
