import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import Breadcrumb from '../../../Components/label/BreadCrumb'
import SEO from '../../../constants/SEO'
import DataTable, { TableColumn, ExpanderComponentProps } from 'react-data-table-component';
import { FaEye, FaFileCsv, FaFileExcel, FaFilePdf, FaFilter } from 'react-icons/fa';
import { BsSearch } from 'react-icons/bs';
import ExportDropdown from '../../../Components/menu/ExportDropdown';
import { AppDispatch, RootState, useAppDispatch } from '../../../store/store';
import { useSelector } from 'react-redux';
import Spinner from '../../../Components/Spinner';
import { InputPicker, Modal, Tooltip, Whisper } from 'rsuite';
import IconButton from '../../../Components/Buttons/IconButton';
import { downloadCSV, exportToExcel, handlePDFDownload } from '../../../services/reusableFunc';
import { Column } from '../../../types/DataType';
import dayjs from 'dayjs';
import { fetchShiftReport, shiftReportData, ShiftReport, shiftReportLoading, filterShiftReport } from '../../../store/slices/shiftReportSlice';
import { useNavigate } from 'react-router-dom';
import { fetchStaff, staffData } from '../../../store/slices/staffSlice';
import { clientData, fetchClient } from '../../../store/slices/clientSlice';
import CustomInput from '../../../Components/Input/CustomInput';

const StaffShiftReport = () => {

    const columns: TableColumn<ShiftReport>[] = [
        {
            name: 'Staff',
            selector: row => row.shiftRoster.staff.fullName,
            sortable: true,
            cell: (row) => <span className="font-bold truncate" style={{ overflow: "hidden", cursor: "pointer" }}
            >{row.shiftRoster.staff.fullName}</span>

        },
        {
            name: 'Client',
            selector: row => row.shiftRoster.profile.fullName,
            sortable: true,
            cell: (row) => <span className="font-bold truncate" style={{ overflow: "hidden", cursor: "pointer" }}
            >{row.shiftRoster.profile.fullName}</span>

        },
        {

            name: 'Start Time',
            selector: row => dayjs(row.shiftRoster.dateFrom).format('h:mm A'),
            sortable: true,

        },
        {
            name: 'End Date',
            selector: row => dayjs(row.shiftRoster.dateTo).format('h:mm A'),
            sortable: true,
        },
        {

            name: 'Date',
            selector: row => dayjs(row.shiftRoster.dateFrom).format('ddd, MMMM DD YYYY'),
            sortable: true

        },
        // {
        //     name: 'Duration',
        //     selector: row => formatDuration(row.duration),
        //     sortable: true,

        // }

        {
            name: "Actions",
            cell: (row) => (
                <span className="flex gap-4">

                    <div>
                        <Whisper placement="auto" controlId="control-id-hover" trigger="hover" speaker={
                            <Tooltip>
                                View Shift Report Form
                            </Tooltip>
                        }>
                            <button
                                onClick={() => FillReport(row.shiftReportId, row.shiftRosterId)}
                                className='flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline'>
                                <FaEye className="text-base cursor-pointer" />
                            </button>
                        </Whisper>
                    </div>

                </span>
            ),
        }

    ];

    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    const companyId = user?.companyId;
    useEffect(() => {
        if (companyId) {
            dispatch(fetchShiftReport(companyId));
            dispatch(fetchStaff(companyId));
            dispatch(fetchClient(companyId));
        }
    }, [dispatch, companyId]);
    const staffShiftReports = useSelector(shiftReportData);

    const staff = useSelector(staffData);
    const clients = useSelector(clientData);
    const [loading1, setLoading1] = useState<boolean>(false)
    const [users, setUsers] = useState<string>("")
    const [client, setClient] = useState<string>("")
    const [dateFrom, setDateFrom] = useState<string>("")
    const [dateTo, setDateTo] = useState<string>("")
    const [filterModal, setFilterModal] = useState<boolean>(false);

    const navigate = useNavigate()

    const FillReport = (shiftId: number, id: number) => {
        navigate(`/app/reports/view-shift-report/${shiftId}/${id}`)
    }

    const loading = useSelector(shiftReportLoading);
    interface Props extends ExpanderComponentProps<ShiftReport> {
        // currently, props that extend ExpanderComponentProps must be set to optional.
        someTitleProp?: string;
    }

    const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
        return (
            <div className="p-2 flex flex-col gap-2 text-xs">
                <span>
                    <span className='font-bold'>Staff: </span>
                    <span> {data.shiftRoster.staff.fullName}</span>
                </span>
                <span>
                    <span className='font-bold'>Client: </span>
                    <span> {data.shiftRoster.profile.fullName}</span>
                </span>

                <span>
                    <span className='font-bold'>Date Created: </span>
                    <span>
                        {dayjs(data.dateCreated).format('DD/MM/YYYY h:mm A')}
                    </span>
                </span>
                <span>
                    <span className='font-bold'>Date Modified: </span>
                    <span>
                        {dayjs(data.dateModified).format('DD/MM/YYYY h:mm A')}
                    </span>
                </span>

            </div>
        )
    }

    const [searchText, setSearchText] = useState<string>("");

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const filteredData = staffShiftReports?.filter((item) =>
        item?.shiftRoster?.staff?.fullName?.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.shiftRoster?.profile?.fullName?.toLowerCase().includes(searchText.toLowerCase())
    );

    const resetFilter = (e: FormEvent) => {
        e.preventDefault();
        companyId && dispatch(fetchShiftReport(companyId));
        setUsers("");
        setClient("");
        setDateFrom("");
        setDateTo("");
        setFilterModal(false);
    }

    const filterActivity = (e: FormEvent) => {
        e.preventDefault();
        setLoading1(true);

        if (companyId && dateFrom && dateTo) {
            dispatch(filterShiftReport({
                companyId: companyId,
                dateFrom: dateFrom,
                dateTo: dateTo,
                staffId: users,
                clientId: client
            }));
        }
        setLoading1(false);
        setFilterModal(false);
    }

    return (
        <>
            <SEO
                title='Shift Report'
                description='Shift Report'
                name='Promax-Care'
            />
            <Breadcrumb />
            <div className='mt-10'>
                {staffShiftReports.length <= 0 && loading && <Spinner color='#071952' />}
                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
                    <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">

                        <h2 className='font-bold text-xl text-black w-auto'>Shift Report</h2>

                        <div className="relative w-full lg:col-span-2">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <BsSearch />
                            </div>
                            <input type="text" id="simple-search" onChange={handleSearch}
                                className="bg-gray-200 border outline-none border-gray-300 text-gray-900 
                                text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2
                                 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white
                                 dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search By Staff or Client Name" />
                        </div>
                    </div>
                    <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">

                        <div className="flex items-center space-x-3 w-full md:w-auto">

                            <button onClick={() => setFilterModal(true)} id="actionsDropdownButton" className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                                <FaFilter />
                                Filter
                            </button>
                            <ExportDropdown >
                                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                    <IconButton
                                        icon={<FaFilePdf className='text-red-500' />}
                                        title="Export as PDF"
                                        onClick={() => handlePDFDownload(staffShiftReports, columns as unknown as Column<ShiftReport>[], "ShiftReport.pdf")}

                                    />
                                    <IconButton
                                        icon={<FaFileExcel className='text-green-600' />}
                                        title="Export as Excel Sheet"

                                        onClick={() => exportToExcel(staffShiftReports, columns as unknown as Column<ShiftReport>[], "ShiftReport")}

                                    />
                                    <IconButton
                                        icon={<FaFileCsv className='text-green-500' />}
                                        title="Export as CSV"
                                        onClick={() => downloadCSV({ data: staffShiftReports, filename: 'ShiftReport.csv' })}

                                    />
                                </div>
                            </ExportDropdown>

                        </div>
                    </div>
                </div>

                <DataTable
                    columns={columns}
                    data={filteredData}
                    expandableRows
                    expandableRowsComponent={ExpandableRowComponent}
                    pagination
                    paginationTotalRows={staffShiftReports?.length}
                // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}

                />

            </div>

            <Modal open={filterModal} onClose={() => setFilterModal(false)} backdrop="static" autoFocus size={"sm"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form action="" className='space-y-2' onSubmit={filterActivity}>

                            <div>
                                <label className=" text-xs text-gray-600 font-semibold">Select Staff</label>
                                <InputPicker size="lg" placeholder="--All Staff--"
                                    onChange={value => setUsers(value)}
                                    data={staff.map(user => ({ label: user.fullName, value: user.staffId }))}
                                    value={users}
                                    className='w-full focus:outline-none focus:border-primary text-gray-600'
                                    appearance='subtle'
                                />
                            </div>

                            <div>
                                <label className=" text-xs text-gray-600 font-semibold">Select Client</label>
                                <InputPicker size="lg" placeholder="--All Client--"
                                    onChange={value => setClient(value)}
                                    data={clients.map(user => ({ label: user.fullName, value: user.profileId }))}
                                    value={client}
                                    className='w-full focus:outline-none focus:border-primary text-gray-600'
                                    appearance='subtle'
                                />
                            </div>

                            <CustomInput
                                label='Start Date'
                                type='datetime-local'
                                name="dateFrom"
                                onChange={e => setDateFrom(e.target.value)}
                                value={dateFrom}
                                required
                            />
                            <CustomInput
                                label='End Date'
                                type='datetime-local'
                                name="dateTo"
                                onChange={e => setDateTo(e.target.value)}
                                value={dateTo}
                                required
                            />

                            <div className='flex items-center justify-center mt-3 flex-wrap'>

                                <div className='text-center'>
                                    <button type="submit"
                                        onClick={resetFilter}
                                        className="text-black bg-white border border-primary hover:bg-white/45 focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-8 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">
                                        Reset Filter
                                    </button>
                                </div>

                                <div className='text-center'>
                                    <button type="submit"
                                        disabled={loading1}
                                        className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">{
                                            loading1 ? <Spinner /> : "Load"
                                        }</button>
                                </div>
                            </div>
                        </form>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default StaffShiftReport