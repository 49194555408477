import React, { ChangeEvent } from "react";

interface Props {
    activities: string[];
    dislike: string[];
    handleInputDislikeChange: (index: number, event: React.ChangeEvent<HTMLInputElement>) => void;
    handleInputEditChange: (index: number, event: React.ChangeEvent<HTMLInputElement>) => void;
}

const EditPreference: React.FC<Props> = ({ dislike, handleInputDislikeChange, handleInputEditChange, activities }) => {


    return (
        <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border mt-9 border-gray-300 ">
            <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
                <thead className="ltr:text-left rtl:text-right bg-[#b9b9b95e]">
                    <tr className="divide-x-2">
                        <th className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
                            Preferred activities
                        </th>
                        <th className="whitespace-nowrap px-4 divide-x-2 py-2 font-semibold text-gray-900">
                            Things I don’t like
                        </th>
                    </tr>
                </thead>

                <tbody className="divide-y divide-gray-200">
                    <tr className="divide-x-2">
                        {activities.map((value, index) => (
                            <tr key={index} className="divide-x-2 border">
                                <td className="whitespace-nowrap flex gap-3 px-4 py-2 text-gray-900 w-[100%] outline-none">
                                    <span>{index + 1}.</span>
                                    <input
                                        className="w-[100%] h-[20px] outline-none px-5"
                                        type="text"
                                        name="preferred_Activities"
                                        value={value}
                                        onChange={(event) => handleInputEditChange(index, event)}
                                    />
                                </td>
                            </tr>
                        ))}
                        <td className="whitespace-nowrap  px-4 py-2 text-gray-700 w-96 outline-none">
                            {dislike.map((value, index) => (
                                <tr key={index} className="divide-x-2 border">
                                    <td className="whitespace-nowrap flex gap-3 px-4 py-2 text-gray-900 w-[100%] outline-none">
                                        <span>{index + 1}.</span>
                                        <input
                                            className="w-[100%] h-[20px] outline-none px-5"
                                            type="text"
                                            value={value}
                                            onChange={(event) => handleInputDislikeChange(index, event)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default EditPreference;
