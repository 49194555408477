import React from "react";
import { Client } from "../../../store/slices/clientSlice";
import { ParticipantConsentForm } from "../../../types/DataType";

interface Props {
    clientDetail: Client | null;
    participantConsentForm: ParticipantConsentForm;
    handleInputDataChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    handleRadioDataChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const EditForm = ({ clientDetail, participantConsentForm, handleInputDataChange, handleRadioDataChange }: Props) => {

    return (
        <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border mt-5 border-gray-300">
            <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
                <tbody className="divide-y divide-gray-200">
                    <tr className="divide-x-2">
                        <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                            Date:
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">
                            <input
                                type="date"
                                name="date"
                                value={participantConsentForm.date || ""}
                                onChange={handleInputDataChange}
                                className="w-full outline-none"
                            />
                        </td>
                    </tr>

                    <tr className="divide-x-2">
                        <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                            Name of participant
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">
                            {clientDetail?.fullName}
                        </td>
                    </tr>

                    <tr className="divide-x-2">
                        <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                            Is the participant providing consent
                        </td>
                        <td className="whitespace-nowrap items-center px-4 py-2 flex gap-5 text-gray-700 w-96 outline-none">
                            <p className="flex items-center gap-2">
                                <input
                                    type="radio"
                                    name="isParticipantProvidingConsent"
                                    value="true"
                                    checked={participantConsentForm.isParticipantProvidingConsent === true}
                                    onChange={handleRadioDataChange}
                                />
                                <label>Yes</label>
                            </p>
                            <p className="flex items-center gap-2">
                                <input
                                    type="radio"
                                    name="isParticipantProvidingConsent"
                                    value="false"
                                    checked={participantConsentForm.isParticipantProvidingConsent === false}
                                    onChange={handleRadioDataChange}
                                />
                                <label>No</label>
                            </p>
                        </td>
                    </tr>

                    <tr className="divide-x-2">
                        <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                            Is an authorised person providing consent?
                        </td>
                        <td className="whitespace-nowrap items-center px-4 py-2 flex gap-5 text-gray-700 w-96 outline-none">
                            <p className="flex items-center gap-2">
                                <input
                                    type="radio"
                                    name="isAuthorisedPersonProvidingConsent"
                                    value="true"
                                    checked={participantConsentForm.isAuthorisedPersonProvidingConsent === true}
                                    onChange={handleRadioDataChange}
                                />
                                <label>Yes</label>
                            </p>
                            <p className="flex items-center gap-2">
                                <input
                                    type="radio"
                                    name="isAuthorisedPersonProvidingConsent"
                                    value="false"
                                    checked={participantConsentForm.isAuthorisedPersonProvidingConsent === false}
                                    onChange={handleRadioDataChange}
                                />
                                <label>No</label>
                            </p>
                        </td>
                    </tr>
                    <tr className="divide-x-2">
                        <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                            If yes, name of authorised person
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">
                            <input
                                type="text"
                                name="authorisedPerson_Name"
                                value={participantConsentForm.authorisedPerson_Name}
                                onChange={handleInputDataChange}
                                className="w-full outline-none"
                            />
                        </td>
                    </tr>
                    <tr className="divide-x-2">
                        <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                            Relationship to the participant
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">
                            <input
                                type="text"
                                name="relationship"
                                value={participantConsentForm.relationship}
                                onChange={handleInputDataChange}
                                className="w-full outline-none"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default EditForm;

