import React, { ChangeEvent } from "react";


interface Props {
  prefValues: string[];
  handleInputChang: (index: number, value: string) => void;
  disLikeValues: string[];
  handleInputChan: (index: number, value: string) => void;
}

const Preference: React.FC<Props> = ({ prefValues, handleInputChang, disLikeValues, handleInputChan }) => {
  return (
    <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border mt-9 border-gray-300 ">
      <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
        <thead className="ltr:text-left rtl:text-right bg-[#b9b9b95e]">
          <tr className="divide-x-2">
            <th className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
              Preferred activities
            </th>
            <th className="whitespace-nowrap px-4 divide-x-2 py-2 font-semibold text-gray-900">
              Things I don’t like
            </th>
          </tr>
        </thead>

        <tbody className="divide-y divide-gray-200">
          <tr className="divide-x-2">
            {/* {prefValues.map <td className="whitespace-nowrap flex gap-3 px-4 py-2 font-medium text-gray-900">
              <span>1.</span>
              <input className="w-[100%]" type="text" />
            </td>} */}
            {prefValues.map((value, index) => (
              <tr key={index} className="divide-x-2 border">
                <td className="whitespace-nowrap flex gap-3 px-4 py-2 text-gray-900 w-[100%] outline-none">
                  <span>{index + 1}.</span>
                  <input
                    className="w-[100%] h-[20px] outline-none px-5"
                    type="text"
                    value={value}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChang(index, e.target.value)}
                  />
                </td>
              </tr>
            ))}
            <td className="whitespace-nowrap  px-4 py-2 text-gray-700 w-96 outline-none">
              {disLikeValues.map((value, index) => (
                <tr key={index} className="divide-x-2 border">
                  <td className="whitespace-nowrap flex gap-3 px-4 py-2 text-gray-900 w-[100%] outline-none">
                    <span>{index + 1}.</span>
                    <input
                      className="w-[100%] h-[20px] outline-none px-5"
                      type="text"
                      value={value}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChan(index, e.target.value)}
                    />
                  </td>
                </tr>
              ))}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Preference;
