import React, { ChangeEvent, useEffect, useState } from 'react'
import DataTable, { TableColumn, ExpanderComponentProps } from 'react-data-table-component';
import { FaEye, FaFileCsv, FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { BsSearch } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { Tooltip, Whisper } from 'rsuite';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, useAppDispatch } from '../../../../store/store';
import ExportDropdown from '../../../../Components/menu/ExportDropdown';
import IconButton from '../../../../Components/Buttons/IconButton';
import { downloadCSV, exportToExcel, handlePDFDownload } from '../../../../services/reusableFunc';
import { Column } from '../../../../types/DataType';
import Spinner from '../../../../Components/Spinner';
import { clientShiftReportData, ShiftReportDataType, fetchClientShiftReport, clientShiftReportLoading } from '../../../../store/slices/ClientShiftReportSlice';

interface ShiftProps {
    uid: string; // Define the type of uid as string
}

const ClientShiftReport: React.FC<ShiftProps> = ({ uid }) => {

    const columns: TableColumn<ShiftReportDataType>[] = [
        {
            name: 'Staff',
            selector: row => row.shiftRoster.staff.fullName,
            sortable: true,
            cell: (row) => <span className="font-bold truncate" style={{ overflow: "hidden", cursor: "pointer" }}
            >{row.shiftRoster.staff.fullName}</span>

        },
        {

            name: 'Start Time',
            selector: row => dayjs(row.shiftRoster.dateFrom).format('h:mm A'),
            sortable: true,

        },
        {
            name: 'End Date',
            selector: row => dayjs(row.shiftRoster.dateTo).format('h:mm A'),
            sortable: true,
        },
        {

            name: 'Date',
            selector: row => dayjs(row.shiftRoster.dateFrom).format('ddd, MMMM DD YYYY'),
            sortable: true

        },
        {
            name: "Actions",
            cell: (row) => (
                <span className="flex gap-4">

                    <div>
                        <Whisper placement="auto" controlId="control-id-hover" trigger="hover" speaker={
                            <Tooltip>
                                View Shift Report Form
                            </Tooltip>
                        }>
                            <button
                                onClick={() => FillReport(row.shiftReportId, row.shiftRosterId)}
                                className='flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline'>
                                <FaEye className="text-base cursor-pointer" />
                            </button>
                        </Whisper>
                    </div>

                </span>
            ),
        }

    ];

    const dispatch: AppDispatch = useAppDispatch();
    useEffect(() => {
        if (uid) {
            dispatch(fetchClientShiftReport(Number(uid)));
        }
    }, [dispatch, uid]);
    const clientShiftReports = useSelector(clientShiftReportData);

    const navigate = useNavigate()

    const FillReport = (shiftId: number, id: number) => {
        navigate(`/app/reports/view-shift-report/${shiftId}/${id}`)
    }

    const loading = useSelector(clientShiftReportLoading);
    interface Props extends ExpanderComponentProps<ShiftReportDataType> {
        // currently, props that extend ExpanderComponentProps must be set to optional.
        someTitleProp?: string;
    }

    const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
        return (
            <div className="p-2 flex flex-col gap-2 text-xs">
                <span>
                    <span className='font-bold'>Staff: </span>
                    <span> {data.shiftRoster.staff.fullName}</span>
                </span>
                <span>
                    <span className='font-bold'>Client: </span>
                    <span> {data.shiftRoster.profile.fullName}</span>
                </span>

                <span>
                    <span className='font-bold'>Date Created: </span>
                    <span>
                        {dayjs(data.dateCreated).format('DD/MM/YYYY h:mm A')}
                    </span>
                </span>
                <span>
                    <span className='font-bold'>Date Modified: </span>
                    <span>
                        {dayjs(data.dateModified).format('DD/MM/YYYY h:mm A')}
                    </span>
                </span>

            </div>
        )
    }

    const [searchText, setSearchText] = useState<string>("");

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const filteredData = clientShiftReports?.filter((item) =>
        item?.shiftRoster?.staff?.fullName?.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.shiftRoster?.profile?.fullName?.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <>
            <div className='mt-10'>
                {clientShiftReports.length <= 0 && loading && <Spinner color='#071952' />}
                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
                    <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">

                        <h2 className='font-bold text-xl text-black w-auto'>Shift Report</h2>

                        <div className="relative w-full lg:col-span-2">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <BsSearch />
                            </div>
                            <input type="text" id="simple-search" onChange={handleSearch}
                                className="bg-gray-200 border outline-none border-gray-300 text-gray-900 
                                text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2
                                 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white
                                 dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search By Staff or Client Name" />
                        </div>
                    </div>
                    <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">

                        <div className="flex items-center space-x-3 w-full md:w-auto">


                            <ExportDropdown >
                                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                    <IconButton
                                        icon={<FaFilePdf className='text-red-500' />}
                                        title="Export as PDF"
                                        onClick={() => handlePDFDownload(clientShiftReports, columns as unknown as Column<ShiftReportDataType>[], "ShiftReportDataType.pdf")}

                                    />
                                    <IconButton
                                        icon={<FaFileExcel className='text-green-600' />}
                                        title="Export as Excel Sheet"

                                        onClick={() => exportToExcel(clientShiftReports, columns as unknown as Column<ShiftReportDataType>[], "ShiftReportDataType")}

                                    />
                                    <IconButton
                                        icon={<FaFileCsv className='text-green-500' />}
                                        title="Export as CSV"
                                        onClick={() => downloadCSV({ data: clientShiftReports, filename: 'ShiftReportDataType.csv' })}

                                    />
                                </div>
                            </ExportDropdown>

                        </div>
                    </div>
                </div>

                <DataTable
                    columns={columns}
                    data={filteredData}
                    expandableRows
                    expandableRowsComponent={ExpandableRowComponent}
                    pagination
                    paginationTotalRows={clientShiftReports?.length}
                // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}

                />

            </div>


        </>
    )
}

export default ClientShiftReport