import React from 'react'
import { ReferralFormData } from '../../../types/DataType';



interface Props {
    referralForm: ReferralFormData;
    handleInputDataEditChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    handleRadioDataChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const EditSummary: React.FC<Props> = ({
    handleInputDataEditChange, referralForm, handleRadioDataChange
}) => {
    return (
        <div>
            <table className="w-full mb-6">
                <tbody>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Is a copy of the participant’s NDIS Plan attached?</td>
                        <td className="border px-4 py-2">
                            <div className="flex items-center">
                                <label className="mr-4">
                                    <input type="radio" name="isNDISPlanAttached" className="mr-2" value="true"
                                        checked={referralForm.isNDISPlanAttached === true}
                                        onChange={handleRadioDataChange}
                                    /> Yes
                                </label>
                                <label>
                                    <input type="radio" name="isNDISPlanAttached" className="mr-2" value="false"
                                        checked={referralForm.isNDISPlanAttached === false}
                                        onChange={handleRadioDataChange}
                                    /> No
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Has the consent form to obtain and share information been signed?</td>
                        <td className="border px-4 py-2">
                            <div className="flex items-center">
                                <label className="mr-4">
                                    <input type="radio" name="isConsentSigned" className="mr-2" value="true"
                                        checked={referralForm.isConsentSigned === true}
                                        onChange={handleRadioDataChange}
                                    /> Yes
                                </label>
                                <label>
                                    <input type="radio" name="isConsentSigned" className="mr-2" value="false"
                                        checked={referralForm.isConsentSigned === false}
                                        onChange={handleRadioDataChange}
                                    /> No
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Are there staffing requests or other requirements?</td>
                        <td className="border px-4 py-2">
                            <div className="flex items-center">
                                <label className="mr-4">
                                    <input type="radio" name="staffingRequest" className="mr-2" value="true"
                                        // checked={referralForm.staffingRequest === true}
                                        onChange={handleRadioDataChange}
                                    /> Yes
                                </label>
                                <label>
                                    <input type="radio" name="staffingRequest" className="mr-2" value="false"
                                        // checked={referralForm.staffingRequest === false}
                                        onChange={handleRadioDataChange}
                                    /> No
                                </label>
                            </div>
                            {referralForm.staffingRequest &&
                                <textarea className="w-full p-2 border border-gray-300 rounded mt-2"
                                    placeholder="If yes, please list"
                                    name="staffingRequest"
                                    value={referralForm.staffingRequest}
                                    onChange={handleInputDataEditChange}>
                                </textarea>}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default EditSummary