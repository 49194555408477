// import React from "react";

// const Progress = () => {
//   return (
//     <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border  border-gray-300">
//       <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
//         <thead className="ltr:text-left rtl:text-right bg-[#b9b9b95e]">
//           <tr className="divide-x-2">
//             <th className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
//               Date
//             </th>
//             <th className="whitespace-nowrap px-4 divide-x-2 py-2 font-semibold text-gray-900">
//               Action ref #
//             </th>
//             <th className="whitespace-nowrap px-4 divide-x-2 py-2 font-semibold text-gray-900">
//               Update on progress
//             </th>
//           </tr>
//         </thead>

//         <tbody className="divide-y divide-gray-200">
//           <tr className="divide-x-2">
//             <td
//               className="whitespace-nowrap px-4 py-1 text-gray-700 w-[25%] outline-none"
//               contentEditable
//             ></td>
//             <td
//               className="whitespace-nowrap px-4 py-1 text-gray-700 w-[40%] outline-none"
//               contentEditable
//             ></td>
//             <td
//               className="whitespace-nowrap px-4 py-1 text-gray-700 w-96 outline-none"
//               contentEditable
//             ></td>
//           </tr>
//           <tr className="divide-x-2">
//             <td
//               className="whitespace-nowrap px-4 py-1 text-gray-700 w-[25%] outline-none"
//               contentEditable
//             ></td>
//             <td
//               className="whitespace-nowrap px-4 py-1 text-gray-700 w-[40%] outline-none"
//               contentEditable
//             ></td>
//             <td
//               className="whitespace-nowrap px-4 py-1 text-gray-700 w-96 outline-none"
//               contentEditable
//             ></td>
//           </tr>
//           <tr className="divide-x-2">
//             <td
//               className="whitespace-nowrap px-4 py-1 text-gray-700 w-[25%] outline-none"
//               contentEditable
//             ></td>
//             <td
//               className="whitespace-nowrap px-4 py-1 text-gray-700 w-[40%] outline-none"
//               contentEditable
//             ></td>
//             <td
//               className="whitespace-nowrap px-4 py-1 text-gray-700 w-96 outline-none"
//               contentEditable
//             ></td>
//           </tr>
//           <tr className="divide-x-2">
//             <td
//               className="whitespace-nowrap px-4 py-1 text-gray-700 w-[25%] outline-none"
//               contentEditable
//             ></td>
//             <td
//               className="whitespace-nowrap px-4 py-1 text-gray-700 w-[40%] outline-none"
//               contentEditable
//             ></td>
//             <td
//               className="whitespace-nowrap px-4 py-1 text-gray-700 w-96 outline-none"
//               contentEditable
//             ></td>
//           </tr>
//           <tr className="divide-x-2">
//             <td
//               className="whitespace-nowrap px-4 py-1 text-gray-700 w-[25%] outline-none"
//               contentEditable
//             ></td>
//             <td
//               className="whitespace-nowrap px-4 py-1 text-gray-700 w-[40%] outline-none"
//               contentEditable
//             ></td>
//             <td
//               className="whitespace-nowrap px-4 py-1 text-gray-700 w-96 outline-none"
//               contentEditable
//             ></td>
//           </tr>

//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default Progress;

// import React from "react";

// // Define the type for the keys of the row object
// type RowKeys = "date" | "actionRef" | "progressUpdate";

// interface Row {
//   date: string;
//   actionRef: string;
//   progressUpdate: string;
// }

// interface Props {
//   rows: Row[];
//   handleCellChange: (rowIndex: number, cellName: RowKeys, value: string) => void;
// }

// const Progress = ({ rows, handleCellChange }: Props) => {




//   return (
//     <form>
//       <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border  border-gray-300">
//         <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
//           <thead className="ltr:text-left rtl:text-right bg-[#b9b9b95e]">
//             <tr className="divide-x-2">
//               <th className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
//                 Date
//               </th>
//               <th className="whitespace-nowrap px-4 divide-x-2 py-2 font-semibold text-gray-900">
//                 Action ref #
//               </th>
//               <th className="whitespace-nowrap px-4 divide-x-2 py-2 font-semibold text-gray-900">
//                 Update on progress
//               </th>
//             </tr>
//           </thead>

//           <tbody className="divide-y divide-gray-200">
//             {rows.map((row, index) => (
//               <tr key={index} className="divide-x-2">
//                 <td className="whitespace-nowrap px-4 py-1 text-gray-700 w-[25%] outline-none">
//                   <input
//                     type="date"
//                     value={row.date}
//                     onChange={(e) =>
//                       handleCellChange(index, "date", e.target.value)
//                     }
//                     className="border-none text-gray-900 text-sm outline-none block w-full bg-white h-full px-2.5"
//                   />
//                 </td>
//                 <td
//                   className="whitespace-nowrap px-4 py-1 text-gray-700 w-[40%] outline-none"
//                   contentEditable
//                   onInput={(e) =>
//                     handleCellChange(index, "actionRef", e.currentTarget.textContent || "")
//                   }
//                 >{row.actionRef}</td>
//                 <td
//                   className="whitespace-nowrap px-4 py-1 text-gray-700 w-96 outline-none"
//                   contentEditable
//                   onInput={(e) =>
//                     handleCellChange(index, "progressUpdate", e.currentTarget.textContent || "")
//                   }
//                 >{row.progressUpdate}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </form>
//   );
// };

// export default Progress;

import React from "react";

// Define the type for the keys of the row object
type RowKeys = "date" | "actionRef" | "progressUpdate";

interface Row {
  date: string;
  actionRef: string;
  progressUpdate: string;
}

interface Props {
  rows: Row[];
  handleCellChange: (rowIndex: number, cellName: RowKeys, value: string) => void;
}

const Progress = ({ rows, handleCellChange }: Props) => {
  return (
    <form>
      <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border border-gray-300">
        <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
          <thead className="ltr:text-left rtl:text-right bg-[#b9b9b95e]">
            <tr className="divide-x-2">
              <th className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
                Date
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
                Action ref #
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
                Update on progress
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {rows.map((row, index) => (
              <tr key={index} className="divide-x-2">
                <td className="whitespace-nowrap px-4 py-1 text-gray-700 w-[25%]">
                  <input
                    type="date"
                    value={row.date}
                    onChange={(e) => handleCellChange(index, "date", e.target.value)}
                    className="border-none text-gray-900 text-sm outline-none block w-full bg-white h-full px-2.5"
                  />
                </td>
                <td className="whitespace-nowrap px-4 py-1 text-gray-700 w-[40%]">
                  <input
                    type="text"
                    value={row.actionRef}
                    onChange={(e) => handleCellChange(index, "actionRef", e.target.value)}
                    className="border-none text-gray-900 text-sm outline-none block w-full bg-white h-full px-2.5"
                  />
                </td>
                <td className="whitespace-nowrap px-4 py-1 text-gray-700 w-96">
                  <input
                    type="text"
                    value={row.progressUpdate}
                    onChange={(e) => handleCellChange(index, "progressUpdate", e.target.value)}
                    className="border-none text-gray-900 text-sm outline-none block w-full bg-white h-full px-2.5"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </form>
  );
};

export default Progress;



