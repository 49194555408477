{/* <button className='py-1 px-2 rounded text-xs bg-white'>Edit</button> */ }
import React, { ReactNode } from 'react';
import Dropdown from '../dropdown';


function EditDropDown(props: { transparent?: boolean; children: ReactNode; }) {
    const { transparent, children } = props;
    const [open, setOpen] = React.useState(false);
    return (
        <Dropdown
            button={

                <button type="button"
                    onClick={() => setOpen(!open)}
                    className="py-1 px-2 rounded text-xs bg-white
                 transition duration-300 transform active:scale-95 ease-in-out  
                ">
                    Edit
                </button>
            }
            animation={'origin-top-right transition-all duration-300 ease-in-out'}
            classNames={`${transparent ? 'top-8' : 'top-11'} right-0 w-max`}
        >
            {children}
        </Dropdown>
    );
}

export default EditDropDown;
