import React from "react";
import { Shift } from "../../../../../store/slices/shiftRosterSlice";
import { ShiftReport } from "../../../../../store/slices/StaffSlices/staffReportSlice";
interface Props {
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
    shiftReportDetail: ShiftReport;
    shiftDetail: Shift | null
}

const FormPartB = ({
    handleInputChange,
    shiftReportDetail,
    shiftDetail
}: Props) => {

    return (
        <table className=" divide-y-2 divide-gray-300 bg-white text-sm  w-full rounded-lg border  border-gray-300 mb-6">
            <tbody className="divide-y divide-gray-200 w-full py-2">
                <tr className="flex flex-col w-full ">
                    <td className="px-4 py-2 font-medium text-gray-900">
                        <span className="font-bold text-black">Meals:</span> Were they given
                        in line with the participant’s mealtime management plan?
                    </td>
                    <td className="px-4 py-2 text-gray-700 outline-none flex gap-4 items-center">
                        <label className="flex gap-1 items-center text-sm">
                            <input
                                type="checkbox"
                                // name="isMealManagementPlan"
                                checked={shiftReportDetail.isMealManagementPlan}
                                onChange={handleInputChange}
                                className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
                            />
                            Yes
                        </label>
                        <label className="flex gap-1 items-center text-sm">
                            <input
                                type="checkbox"
                                // name="isMealManagementPlan"
                                checked={!shiftReportDetail.isMealManagementPlan}
                                onChange={handleInputChange}
                                className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
                            />
                            No
                        </label>
                        <span>If No, Provide Details:</span>
                    </td>
                    {!shiftReportDetail.isMealManagementPlan && (
                        <td className="px-4 py-2 text-gray-700 outline-none flex gap-4 items-center">
                            {/* <textarea
                                // name="details_IfNotMealMaganementPlan" 
                                value={shiftReportDetail.details_IfNotMealMaganementPlan}
                                required={!shiftReportDetail.isMealManagementPlan}
                                onChange={handleInputChange}
                                className="border w-full p-2"></textarea> */}

                            <div
                                className="border w-full p-2"
                            >
                                {shiftReportDetail.details_IfNotMealMaganementPlan}
                            </div>
                        </td>
                    )}
                </tr>
                <tr className="flex flex-col w-full ">
                    <td className=" px-4 py-2 font-medium  text-gray-900">
                        Did the participant experience any eating or drinking problems?
                    </td>
                    <td className=" px-4 py-2 text-gray-700 outline-none flex gap-4 items-center">
                        <label className={`flex gap-1 items-center text-sm  `}>
                            <input
                                type="checkbox"
                                // name="isDrinkingProblem"
                                checked={shiftReportDetail.isDrinkingProblem}
                                onChange={handleInputChange}
                                className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
                            />
                            Yes
                        </label>
                        <label className={`flex gap-1 items-center text-sm  `}>
                            <input
                                type="checkbox"
                                checked={!shiftReportDetail.isDrinkingProblem}
                                // onChange={() => setIsDrinkingProblem(false)}
                                className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
                            />
                            No
                        </label>
                        <span>If Yes, Provide Details:</span>
                    </td>
                    {shiftReportDetail.isDrinkingProblem && <td className="px-4 py-2 text-gray-700 outline-none flex gap-4 items-center">
                        {/* <textarea
                            // name="details_IfProblemExist" 
                            id=""
                            value={shiftReportDetail.details_IfProblemExist}
                            required={shiftReportDetail.isDrinkingProblem}
                            onChange={handleInputChange}
                            className="border w-full p-2"></textarea> */}

                        <div className="border w-full p-2">
                            {shiftReportDetail.details_IfProblemExist}
                        </div>
                    </td>}
                </tr>
                <tr className="flex flex-col w-full ">
                    <td className=" px-4 py-2 font-medium  text-gray-900">
                        Health and well-being issues:
                    </td>
                    <td className=" px-4 py-2 text-gray-700 outline-none flex gap-4 items-center">
                        <label className={`flex gap-1 items-center text-sm  `}>
                            <input
                                type="checkbox"
                                // name="isHealthIssues"
                                checked={shiftReportDetail.isHealthIssues}
                                onChange={handleInputChange}
                                className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
                            />
                            Yes
                        </label>
                        <label className={`flex gap-1 items-center text-sm  `}>
                            <input
                                type="checkbox"
                                checked={!shiftReportDetail.isHealthIssues}
                                // onChange={() => setIsHealthIssues(false)}
                                className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
                            />
                            No
                        </label>
                        <span>If Yes, Provide Details:</span>
                    </td>
                    {shiftReportDetail.isHealthIssues && <td className="px-4 py-2 text-gray-700 outline-none flex gap-4 items-center">
                        {/* <textarea
                            // name="details_IfHealthIssuesExist"
                            id="" className="border w-full p-2"
                            value={shiftReportDetail.details_IfHealthIssuesExist}
                            required={shiftReportDetail.isHealthIssues}
                            onChange={handleInputChange}
                        ></textarea> */}
                        <div className="border w-full p-2">
                            {shiftReportDetail.details_IfHealthIssuesExist}
                        </div>
                    </td>}
                </tr>
                <tr className="flex flex-col w-full ">
                    <td className=" px-4 py-2 font-medium  text-gray-900">
                        Support plan progress and activities (provide details of goal
                        progress and activities)
                    </td>

                    <td className="px-4 py-2 text-gray-700 outline-none flex gap-4 items-center">
                        {/* <textarea
                            value={shiftReportDetail.goal_Progress}
                            // name="goal_Progress"
                            onChange={handleInputChange}
                            id="" className="border w-full p-2"></textarea> */}
                        <div className="border w-full p-2">
                            {shiftReportDetail.goal_Progress}
                        </div>
                    </td>
                </tr>
                <tr className="flex flex-col w-full ">
                    <td className=" px-4 py-2 font-medium  text-gray-900">
                        Provide any details of contact with family and friends
                    </td>

                    <td className="px-4 py-2 text-gray-700 outline-none flex gap-4 items-center">
                        {/* <textarea
                            // name="contactFamily"
                            id="" className="border w-full p-2"
                            value={shiftReportDetail.contactFamily}
                            onChange={handleInputChange}
                        ></textarea> */}
                        <div className="border w-full p-2">
                            {shiftReportDetail.contactFamily}
                        </div>
                    </td>
                </tr>
                <tr className="flex flex-col w-full ">
                    <td className=" px-4 py-2 font-medium  text-gray-900">Incidents</td>
                    <td className=" px-4 py-2 text-gray-700 outline-none flex gap-4 items-center">
                        <label className={`flex gap-1 items-center text-sm  `}>
                            <input
                                type="checkbox"
                                // name="isIncident"
                                checked={shiftReportDetail.isIncident}
                                onChange={handleInputChange}
                                className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
                            />
                            Yes
                        </label>
                        <label className={`flex gap-1 items-center text-sm  `}>
                            <input
                                type="checkbox"
                                checked={!shiftReportDetail.isIncident}
                                // onChange={() => setIsIncident(false)}
                                className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
                            />
                            No
                        </label>
                        <span>If Yes, Provide Details:</span>
                    </td>
                    {shiftReportDetail.isIncident && <td className="px-4 py-2 text-gray-700 outline-none flex gap-4 items-center">
                        {/* <textarea
                            // name="details_IfIsIncident"
                            id="" className="border w-full p-2"
                            value={shiftReportDetail.details_IfIsIncident}
                            required={shiftReportDetail.isIncident}
                            onChange={handleInputChange}
                        ></textarea> */}
                        <div className="border w-full p-2">
                            {shiftReportDetail.details_IfIsIncident}
                        </div>
                    </td>}
                </tr>
                <tr className="flex flex-col w-full ">
                    <td className=" px-4 py-2 font-medium  text-gray-900">
                        Behaviours of concern?
                    </td>
                    <td className=" px-4 py-2 text-gray-700 outline-none flex gap-4 items-center">
                        <label className={`flex gap-1 items-center text-sm  `}>
                            <input
                                type="checkbox"
                                // name="isBehaviourConcerned"
                                checked={shiftReportDetail.isBehaviourConcerned}
                                onChange={handleInputChange}
                                className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
                            />
                            Yes
                        </label>
                        <label className={`flex gap-1 items-center text-sm  `}>
                            <input
                                type="checkbox"
                                checked={!shiftReportDetail.isBehaviourConcerned}
                                // onChange={() => setIsBehaviourConcerned(false)}
                                className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
                            />
                            No
                        </label>
                        <span>If Yes, Provide Details:</span>
                    </td>
                    {shiftReportDetail.isBehaviourConcerned && <td className="px-4 py-2 text-gray-700 outline-none flex gap-4 items-center">
                        {/* <textarea
                            value={shiftReportDetail.details_ifIsBehaviourConcerned}
                            required={shiftReportDetail.isBehaviourConcerned}
                            name="details_ifIsBehaviourConcerned"
                            onChange={handleInputChange}
                            id="" className="border w-full p-2"></textarea> */}
                        <div className="border w-full p-2">
                            {shiftReportDetail.details_ifIsBehaviourConcerned}
                        </div>
                    </td>}
                </tr>

                <tr className="flex flex-col w-full py-4">
                    <td className=" px-4 py-1 font-medium flex gap-4 items-center text-gray-900">
                        Staff Signature
                    </td>

                    <td className="px-4 py-1 text-gray-700 outline-none w-full">
                        {shiftDetail?.staff.signatureUrl && (
                            <img
                                src={shiftDetail?.staff.signatureUrl}
                                alt="Signature"
                                className="img-thumbnail  block border rounded bg-light  border-gray-300 bg-gray-100 p-1"
                                style={{
                                    display: "block",
                                    width: "120px",
                                }}
                            />
                        )}
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default FormPartB;
