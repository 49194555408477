import React, { ChangeEvent, useEffect, useState } from 'react'
import DataTable, { ExpanderComponentProps, TableColumn } from 'react-data-table-component';
import { BsSearch } from 'react-icons/bs';
import ExportDropdown from '../../../../Components/menu/ExportDropdown';
import IconButton from '../../../../Components/Buttons/IconButton';
import { downloadCSV, exportToExcel, handlePDFDownload } from '../../../../services/reusableFunc';
import { Column } from '../../../../types/DataType';
import { FaFileCsv, FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState, useAppDispatch } from '../../../../store/store';
import { clientShiftData, clientShiftLoading, fetchClientShift } from '../../../../store/slices/clientShiftSlice';
import dayjs from 'dayjs';
import Spinner from '../../../../Components/Spinner';

interface DataRow {
    activities: string;
    appointment: string;
    attendId: number;
    attendance: boolean;
    cancellationDate: string;
    clients: string;
    companyID: number;
    dateCreated: string;
    dateFrom: string;
    dateModified: string;
    dateTo: string;
    isCurrent: boolean;
    isEnded: boolean;
    isExceptionalShift: boolean;
    isNightShift: boolean;
    profileId: number;
    progressNoteId: number;
    reason: string;
    repeat: boolean;
    requestCancellation: boolean;
    staffId: number;
    status: string;
    userCreated: string;
    userModified: string;
    staff: {
        staffId: number;
        maxStaffId: string | null;
        firstName: string;
        surName: string;
        middleName: string | null;
        aboutMe: string | null;
        address: string;
        postcode: string | null;
        email: string;
        phoneNumber: string;
        profession: string | null;
        gender: string;
        imageUrl: string | null;
        imageFile: string | null;
        country: string;
        state: string | null;
        city: string | null;
        isCompleted: boolean;
        isActive: boolean;
        dateOfBirth: string;
        facebook: string | null;
        twitter: string | null;
        instagram: string | null;
        youtube: string | null;
        linkedIn: string | null;
        github: string | null;
        employmentType: string | null;
        taxFile: string | null;
        position: string | null;
        level: string | null;
        australianCitizen: boolean | null;
        dateJoined: string | null;
        salary: number;
        payDay: number;
        payRate: string | null;
        bankName: string | null;
        bsb: string | null;
        branch: string | null;
        accountName: string | null;
        accountNumber: string | null;
        nextOfKin: string | null;
        relationship: string | null;
        kinPostcode: string | null;
        kinAddress: string | null;
        kinCountry: string | null;
        kinCity: string | null;
        kinEmail: string | null;
        suburb: string | null;
        kinState: string | null;
        kinPhoneNumber: string | null;
        isDocumentUploaded: boolean;
        isAdmin: boolean;
        adm_DesignationsId: number | null;
        adm_Designations: string | null;
        offerLetter: string | null;
        handbook: string | null;
        superForm: string | null;
        supportPosition: string | null;
        companyId: number;
        company: string | null;
        fullName: string;
        dateCreated: string;
        userCreated: string | null;
        dateModified: string;
        userModified: string | null;
    };
    profile: {
        profileId: number;
        firstName: string;
        surName: string;
        middleName: string | null;
        clientId: string | null;
        address: string;
        email: string;
        phoneNumber: string;
        gender: string;
        imageUrl: string | null;
        imageFile: string | null;
        country: string;
        state: string | null;
        city: string | null;
        isCompleted: boolean;
        isActive: boolean;
        dateOfBirth: string;
        homePhone: string | null;
        workPhone: string | null;
        profession: string | null;
        culturalBackground: string;
        preferredLanguage: string;
        requireInterpreter: string;
        indigenousSatatus: string;
        ndisPlan: string;
        ndisPlanNote: string;
        privacyPreferences: string;
        financialArrangement: string;
        ndisNo: string | null;
        agreementStartDate: string | null;
        agreementEndDate: string | null;
        nextOfKin: string;
        relationship: string | null;
        kinPostcode: string;
        kinAddress: string | null;
        kinCountry: string;
        kinCity: string | null;
        kinEmail: string;
        suburb: string | null;
        kinState: string | null;
        kinPhoneNumber: string | null;
        companyId: number;
        company: string | null;
        fullName: string;
        dateCreated: string;
        userCreated: string | null;
        dateModified: string;
        userModified: string | null;
    };

}


interface ClientShitProps {
    uid: string; // Define the type of uid as string
}


const ClientShiftRoster: React.FC<ClientShitProps> = ({ uid }) => {

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Staff',
            selector: row => row.staff?.fullName || 'N/A',
            sortable: true
        },
        {
            name: 'Date',
            selector: row => dayjs(row.dateFrom).format('DD-MM-YYYY'),
            sortable: true,
        },
        {
            name: 'Start Time',
            selector: row => dayjs(row.dateFrom).format('hh:mm A'),
            sortable: true
        },
        {
            name: 'End Time',
            selector: row => dayjs(row.dateTo).format('hh:mm A'),
            sortable: true
        },
        {
            name: 'Activities',
            selector: row => row.activities,
            sortable: true
        },


    ];

    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    const companyId = user?.companyId;
    useEffect(() => {
        if (companyId) {
            dispatch(fetchClientShift(Number(uid)));
        }
    }, [dispatch, companyId, uid]);
    const clientShift = useSelector(clientShiftData);
    const loadingData = useSelector(clientShiftLoading);

    const [searchText, setSearchText] = useState<string>("");

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const filteredData = clientShift.filter((item) =>
        item.activities?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.staff?.fullName.toLowerCase().includes(searchText.toLowerCase()) ||
        item.dateFrom?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.dateTo?.toLowerCase().includes(searchText.toLowerCase())
    );

    interface Props extends ExpanderComponentProps<DataRow> {
        // currently, props that extend ExpanderComponentProps must be set to optional.
        someTitleProp?: string;
    }


    const ExpandableRowComponent: React.FC<Props> = ({ data }) => {

        return (
            <div className="p-2 flex flex-col gap-2 text-xs">
                <div ><span className='font-bold'>Staff: </span>{data.staff.fullName}</div>
                <div><span className='font-bold'>Client: </span>{data.profile.fullName}</div>
                <div><span className='font-bold'>Activities: </span>{data.activities}</div>

            </div>
        )
    }


    return (
        <div className='mt-10'>
            {clientShift.length <= 0 && loadingData && <Spinner color='#071952' />}
            <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
                <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">

                    <h2 className='font-bold text-xl text-black w-auto'>Shift Roster</h2>

                    <div className="relative w-full lg:col-span-2">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <BsSearch />
                        </div>
                        <input type="text" id="simple-search" onChange={handleSearch} className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search By Staff Name or Activities" />
                    </div>
                </div>
                <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">

                    <div className="flex items-center space-x-3 w-full md:w-auto">

                        {/* <button id="actionsDropdownButton" className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                            <FaFilter />
                            Filter
                        </button> */}
                        <ExportDropdown >
                            <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                <IconButton
                                    icon={<FaFilePdf className='text-red-500' />}
                                    title="Export as PDF"
                                    onClick={() => handlePDFDownload(clientShift, columns as unknown as Column<DataRow>[], "ClientRoster.pdf")}

                                />
                                <IconButton
                                    icon={<FaFileExcel className='text-green-600' />}
                                    title="Export as Excel Sheet"

                                    onClick={() => exportToExcel(clientShift, columns as unknown as Column<DataRow>[], "ClientRoster")}

                                />
                                <IconButton
                                    icon={<FaFileCsv className='text-green-500' />}
                                    title="Export as CSV"
                                    onClick={() => downloadCSV({ data: clientShift, filename: 'ClientRoster.csv' })}

                                />
                            </div>
                        </ExportDropdown>


                    </div>


                </div>
            </div>

            <DataTable
                columns={columns}
                data={filteredData}
                expandableRows
                expandableRowsComponent={ExpandableRowComponent}
                pagination
                paginationTotalRows={clientShift?.length}
            // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}

            />

        </div>
    )
}

export default ClientShiftRoster