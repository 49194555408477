import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

export interface PaymentDataType {
  paymentId: string;
  totalAmount: number;
  noOfUser: number;
  payment_DateTime: string;
  payment_Method: string;
  transaction_ID: number;
  payment_Response: string;
  payment_Description: string;
  paymentStatus: string;
  authorization_url: string;
  session_Id: string;
  companyId: string;
  company: {
    subscribtionDate: string;
    expirationDate: string;
  };
  expirationDate: string;
  dateCreated: string;
  userCreated: string;
  dateModified: string;
  userModified: string;
}

export interface PaymentState {
  loading: boolean;
  data: Array<PaymentDataType>;
  error: string | undefined;
}
const initialState: PaymentState = {
  loading: false,
  data: [],
  error: undefined,
};
export const fetchPaymentHistroy = createAsyncThunk(
  "PaymentDataType/fetchPaymentHistroy",
  async (companyId: number) => {
    try {
      const response = await api.fetchPaymentHistroy(companyId);
      return response;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

export const filterPaymentHistory = createAsyncThunk(
  "PaymentDataType/filterPaymentHistory",
  async ({
    companyId,
    dateFrom,
    dateTo,
    status,
  }: {
    companyId: number;
    dateFrom: string;
    dateTo: string;
    status: string;
  }) => {
    try {
      const response = await api.filterPaymentHistroy(
        companyId,
        dateFrom,
        dateTo,
        status
      );
      return response;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

const paymentHistroySlice = createSlice({
  name: "paymentHistroy",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentHistroy.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchPaymentHistroy.fulfilled,
      (state, action: PayloadAction<Array<PaymentDataType>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(fetchPaymentHistroy.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(filterPaymentHistory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      filterPaymentHistory.fulfilled,
      (state, action: PayloadAction<Array<PaymentDataType>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(filterPaymentHistory.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {},
});
export const userSelector = (state: RootState) => state.paymentHistroy;
export default paymentHistroySlice.reducer;

export const paymentHistroyData = (state: RootState) =>
  state.paymentHistroy.data;
export const paymentHistroyLoading = (state: RootState) =>
  state.paymentHistroy.loading;
export const paymentHistroyError = (state: RootState) =>
  state.paymentHistroy.error;
