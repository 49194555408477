import React from "react";
import { Navigate, Route, Routes } from "react-router";
import ServiceProviderClient from "./Clients";
import ClientDetails from "./Clients/Components/profile";

const ProviderLayout = () => {
    return (
        <div>
            <Routes>
                <Route
                    path="/"
                    element={<Navigate to="/app/provider/clients" replace />}
                />
                <Route path="/clients/:uid/:id" element={<ServiceProviderClient />} />
                <Route path="/clients-profile/:uid/:id" element={<ClientDetails />} />

            </Routes>
        </div>
    );
};

export default ProviderLayout;
