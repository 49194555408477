import React from "react";
import { Navigate, Route, Routes } from "react-router";
import UserDashboard from "./Dashboard";

const OtherUserLayout = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={<Navigate to="/app/users/dashboard" replace />}
        />
        <Route path="/dashboard" element={<UserDashboard />} />
      </Routes>
    </div>
  );
};

export default OtherUserLayout;
