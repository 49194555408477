import React, { useState } from "react";
import { CheckPicker, Modal } from "rsuite";
import Editor from "../../../../../Components/Editor/ReactQuill";
import CustomInput from "../../../../../Components/Input/CustomInput";
import { User } from "../../../../../store/slices/userSlice";
import Spinner from "../../../../../Components/Spinner";
import { RootState } from "../../../../../store/store";
import { useSelector } from "react-redux";
import axiosInstance from "../../../../../store/axiosInstance";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";

interface Props {
  addModal: boolean;
  setAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  userDat: User[];
  fetchData: () => void;
}

// Define the type for the checkbox states with an index signature
interface CheckboxStates {
  [key: string]: boolean; // Any string key returns a boolean value
}

const AddMessage = ({ addModal, setAddModal, userDat, fetchData }: Props) => {
  const user = useSelector((state: RootState) => state?.auth?.user);
  const [editorValue, setEditorValue] = useState<string>("");
  const [checkboxStates, setCheckboxStates] = useState<CheckboxStates>({
    allAdmins: false,
    allStaffs: false,
    allClients: false,
    sendAsSMS: false,
  });
  const [selectedUser, setSelectedUser] = useState<string[]>([]);

  const handleEditorChange = (value: string) => {
    setEditorValue(value);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setCheckboxStates((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };
  const [smsContent, setSmsContent] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const handleSmsContentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    let content = event.target.value;
    if (content.length > 306) {
      content = content.slice(0, 306);
    }
    setSmsContent(content);
  };

  const [info, setInfo] = useState({
    phoneNumber: "",
    subject: "",
  });

  const sanitizeInput = (name: string, value: string) => {
    switch (name) {
      case "phoneNumber": {
        // Allow only numeric characters (0-9)
        return value.replace(/[^0-9]/g, "");
      }
      case "subject": {
        // Allow only alphabetic characters
        return value.replace(/[^a-zA-Z\s]/g, "");
      }
      default: {
        // For other fields, allow alphanumeric characters and specific special characters
        const allowedPattern =
          /^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\/\-=|"']+$/;
        return value.replace(
          new RegExp(`[^${allowedPattern.source}]`, "g"),
          ""
        );
      }
    }
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;

    setInfo({ ...info, [name]: sanitizeInput(name, value) });
  };

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (checkboxStates.sendAsSMS) {
      // Logic for sending SMS
      const payload = {
        content: smsContent,
        subject: "",
        emailTo: "",
        status: true,
        emailFrom: user?.email, // Replace with the appropriate sender email
        admin: checkboxStates.allAdmins,
        staff: checkboxStates.allStaffs,
        client: checkboxStates.allClients,
        phoneNumber: info.phoneNumber,
        sms: checkboxStates.sendAsSMS,
        companyId: user?.companyId, // Replace with the actual companyId
      };
      //
      try {
        setLoading(true);
        const { data } = await axiosInstance.post(
          `/Messages/send_message?userId=${user?.uid}`,
          payload
        );
        toast.success(data.message);
        fetchData();
        setLoading(false);
        setAddModal(false);
      } catch (error: unknown) {
        if (isAxiosError(error)) {
          toast.error(error.response?.data?.message);
          toast.error(error.response?.data?.title);
        } else {
          toast.error("Error sending message");
        }
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    // else Send as normal email
    else {
      const payload = {
        content: editorValue,
        subject: info.subject,
        emailTo: selectedUser.join(", "),
        status: true,
        emailFrom: user?.email, // Replace with the appropriate sender email
        admin: checkboxStates.allAdmins,
        staff: checkboxStates.allStaffs,
        client: checkboxStates.allClients,
        sms: checkboxStates.sendAsSMS,
        companyId: user?.companyId, // Replace with the actual companyId
      };
      try {
        setLoading(true);
        const { data } = await axiosInstance.post(
          `/Messages/send_message?userId=${user?.uid}`,
          payload
        );
        toast.success(data.message);
        setEditorValue("");
        setSelectedUser([]);
        fetchData();
        setAddModal(false);

        setLoading(false);
      } catch (error: unknown) {
        if (isAxiosError(error)) {
          toast.error(error.response?.data?.message);
          toast.error(error.response?.data?.title);
        } else {
          toast.error("Error Marking Attendance");
        }
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      open={addModal}
      onClose={() => setAddModal(false)}
      backdrop="static"
      autoFocus
      size={"lg"}
    >
      <Modal.Header>
        <Modal.Title className="font-bold text-sm">New Message</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          className="p-2 grid grid-cols-1 gap-4"
          onSubmit={handleSendMessage}
        >
          {user?.role === "CompanyAdmin" ||
            user?.role === "Administrator" ? <div className="p-2 border-t drop-shadow-sm">
            <div className="flex gap-2 items-center horizontal-scroll scroll-smooth scrollbar-hide md:px-0">
              {[
                { label: "To All Admins", name: "allAdmins" },
                { label: "To All Staffs", name: "allStaffs" },
                { label: "To All Clients", name: "allClients" },
                { label: "Send As SMS", name: "sendAsSMS" },
              ].map((item) => (
                <label
                  key={item.name}
                  className="bg-gray-100 flex gap-2 items-center shadow-sm border rounded text-xs px-2 py-2 whitespace-nowrap transition duration-300 transform active:scale-95 ease-in-out"
                >
                  <input
                    type="checkbox"
                    name={item.name}
                    checked={checkboxStates[item.name]}
                    onChange={handleCheckboxChange}
                    className="accent-brand"
                  />
                  <span className="font-semibold">{item.label}</span>
                </label>
              ))}
            </div>
          </div> : null}
          {!checkboxStates.sendAsSMS && (
            <>
              <div>
                <div>
                  <label className="text-xs text-gray-600 font-semibold">
                    Select Recepient
                  </label>
                  <CheckPicker
                    data={userDat.map((user) => ({
                      label: user.email,
                      value: user.email,
                    }))} // Ensure this is an array of objects with 'label' and 'value' keys
                    onChange={setSelectedUser} // Directly pass setSelectedClients here
                    size="lg"
                    placeholder="Select recipient"
                    className="w-full focus:outline-none focus:border-primary text-gray-600"
                    value={selectedUser} // Control the component with the selectedClients state
                  />
                </div>
              </div>
              <div>
                <CustomInput
                  label="Subject"
                  value={info.subject}
                  name="subject"
                  onChange={handleChange}
                  minLength={4}
                  required={!checkboxStates.sendAsSMS}
                />
              </div>
              <Editor
                placeholder="Write something..."
                onChange={handleEditorChange as unknown as () => void}
                value={editorValue}
              ></Editor>
              <br />
              <br />
            </>
          )}
          {checkboxStates.sendAsSMS && (
            <>
              <div>
                <CustomInput
                  label="Recipient Number without the '+'"
                  value={info.phoneNumber}
                  name="phoneNumber"
                  onChange={handleChange}
                  required={checkboxStates.sendAsSMS}
                />
              </div>

              <div>
                <label
                  htmlFor="message"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your Message{" "}
                  <small className="text-red-500">
                    (A page is 153 Character)
                  </small>
                </label>
                <textarea
                  id="message"
                  rows={10}
                  required={checkboxStates.sendAsSMS}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Write your message here..."
                  value={smsContent}
                  minLength={10}
                  onChange={handleSmsContentChange}
                />

                <div className="mt-1">
                  {smsContent.length}/306 characters remaining
                </div>
              </div>
            </>
          )}
          <div className="mx-auto text-center mt-3">
            <button
              type="submit"
              disabled={loading}
              className="text-white bg-primary disabled:bg-gray-400 hover:bg-primary/80 focus:ring-4 focus:ring-primary font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-nonedark:focus:ring-primary"
            >
              {loading ? <Spinner /> : "Submit"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddMessage;
