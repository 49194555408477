import React from 'react'

const ManagementPlan = () => {
    return (
        <div className=' mx-auto my-9 text-black'>
            <p className='font-semibold text-[25px] text-black'>6.	Step 3: Safety Management Plan</p>



            <div className="overflow-x-auto border border-gray-300">
                <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
                    <thead className="ltr:text-left rtl:text-right">
                        <tr className='divide-gray-200 divide-x-2 bg-gray-500'>
                            <th className="px-4 py-2 text-white font-semibold">Risk Area (Refer to Safety Checklist above for all identified risks)</th>
                            <th className="px-4 py-2 text-white font-semibold">Risk category (if unmanaged)</th>
                            <th className="px-4 py-2 text-white font-semibold">How will the risk be managed? Include details of relevant support plans (include dates and location of plan). Examples of support plans may include: Positive behaviour support plan, Epilepsy management plan, Eating and drinking, or dietary plan, Manual handling plan, Community support plan etc.</th>
                            <th className="px-4 py-2 text-white font-semibold">Risk category if the risk is managed i.e. when plans are in place and followed</th>
                        </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200">
                        <tr className='divide-gray-200 divide-x-2'>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                        </tr>
                        <tr className='divide-gray-200 divide-x-2'>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                        </tr>
                        <tr className='divide-gray-200 divide-x-2'>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                        </tr>
                        <tr className='divide-gray-200 divide-x-2'>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                        </tr>
                        <tr className='divide-gray-200 divide-x-2'>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                        </tr>
                        <tr className='divide-gray-200 divide-x-2'>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                        </tr>
                        <tr className='divide-gray-200 divide-x-2'>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                        </tr>
                        <tr className='divide-gray-200 divide-x-2'>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                        </tr>
                        <tr className='divide-gray-200 divide-x-2'>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                        </tr>
                        <tr className='divide-gray-200 divide-x-2'>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                        </tr>


                    </tbody>
                </table>
            </div>



        </div>
    )
}

export default ManagementPlan