import React from "react";
import { Shift } from "../../../../../store/slices/shiftRosterSlice";
import dayjs from "dayjs";

interface Props {
  shiftDetail: Shift | null
}
const FormHero = ({ shiftDetail }: Props) => {
  return (
    <table className=" divide-y-2 divide-gray-300 bg-white text-sm rounded-lg border  border-gray-300 mb-6">
      <tbody className="divide-y divide-gray-200">
        <tr className="divide-x-2">
          <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
            Staff Name:
          </td>
          <td
            className="md:whitespace-nowrap px-4 py-2 text-gray-700 w-full outline-none"

          >
            {shiftDetail?.staff.fullName}
          </td>
        </tr>

        <tr className="divide-x-2">
          <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
            Client(s) Name:
          </td>
          <td
            className="md:whitespace-nowrap px-4 py-2 text-gray-700 w-full outline-none"

          > {shiftDetail?.clients}</td>
        </tr>
        <tr className="divide-x-2">
          <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
            Shift Start Time:
          </td>
          <td
            className="whitespace-nowrap px-4 py-2 text-gray-700 w-full outline-none"

          > {dayjs(shiftDetail?.dateFrom).format("D MMMM, YYYY h:mm A")}</td>
        </tr>
        <tr className="divide-x-2">
          <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
            Shift End Time:
          </td>
          <td
            className="whitespace-nowrap px-4 py-2 text-gray-700 w-full outline-none"

          > {dayjs(shiftDetail?.dateTo).format("D MMMM, YYYY h:mm A")}</td>
        </tr>
        <tr className="divide-x-2">
          <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
            Date:
          </td>
          <td
            className="md:whitespace-nowrap px-4 py-2 text-gray-700 w-full outline-none"

          >
            {dayjs(shiftDetail?.dateCreated).format('DD/MM/YYYY')}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default FormHero;
