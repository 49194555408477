import React from "react";
import { Navigate, Route, Routes } from "react-router";
import ClientAuditor from "./Clients";
import StaffAuditor from "./Staffs";

const AuditorLayout = () => {
    return (
        <div>
            <Routes>
                <Route
                    path="/"
                    element={<Navigate to="/app/auditor/clients" replace />}
                />
                <Route path="/clients" element={<ClientAuditor />} />
                <Route path="/staffs" element={<StaffAuditor />} />
            </Routes>
        </div>
    );
};

export default AuditorLayout;
