export const getAppointmentText = (companyName: string | undefined) => ([
    "Allocate the appointment to another of my regular workers.",
    "Change my appointment time to when one of my other regular workers is available.",
    `Allocate my appointment to a worker who has not been introduced to me but has been inducted into ${companyName} and has undertaken general training in disability supports.`,
    `Engage a worker from a ${companyName} partner organisation.`,
    "I understand that I will incur the short notice cancellation fee if I choose any of the above option but cancel within 48 hours of the scheduled appointment as per the Cancellation Policy.",
    "Cancel the appointment (Cancellation Policy applies).",
]);

export const getPaymentText = (companyName: string | undefined) => ([
    "Self-managed: Fees are payable by EFT bank transfer; credit card; direct debit; Payments are due within X days of the service being provided.",
    "NDIA managed: The participant has nominated the NDIA to manage the funding for supports provided under this Service Agreement. You agree to give authority to us to claim payment after each session.",
    `Plan managed: After each session; ${companyName} will claim payment from the following Plan Management Provider:`,
]);