import React from "react";
// import ServiceTable from './ServiceTable';
// import RepContact from './RepContact';
import { Client } from "../../../store/slices/clientSlice";
import { CompanyDetail } from "../../../types/AuthData";
// import EditFirstForm from './EditFirstForm';
import { Representative, SupportPlaData } from "../../../types/DataType";
import AgreementSignature from "./Agreement";
import EditCommunication from "./EditCommunication";
import EditCourtOrder from "./EditCourtOrder";
import EditCultural from "./EditCultural";
import EditDecision from "./EditDecison";
import EditDetails from "./EditDetails";
// import EditSummary from './EditSummary';
import EditFirstForm from "./EditFirstForm";
import EditRepContact from "./EditRepContact";
import EditRisk from "./EditRisk";
import EditSupport from "./EditSupport";

interface Props {
  clientDetail: Client | null;
  careManager: Representative | null;
  editAttendee: string[];
  supportForm: SupportPlaData;
  handleInputDataEditChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  handleInputEditChange: (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleAddRowEdit: () => void;
  handleRadioDataChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleInputEditChangeSupport: (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleAddRowEditSupport: () => void;
  editSupport: string[];
  companyOne: CompanyDetail | null | undefined;
}

const EditSupportFormComp: React.FC<Props> = ({
  clientDetail,
  handleRadioDataChange,
  handleAddRowEditSupport,
  handleInputEditChangeSupport,
  supportForm,
  handleInputDataEditChange,
  editAttendee,
  careManager,
  editSupport,
  handleAddRowEdit,
  handleInputEditChange,
  companyOne,
}) => {
  return (
    <div className="max-w-6xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Support Plan Form</h1>

      <EditFirstForm
        editAttendee={editAttendee}
        handleAddRowEdit={handleAddRowEdit}
        handleInputDataEditChange={handleInputDataEditChange}
        handleInputEditChange={handleInputEditChange}
        supportForm={supportForm}
      />

      <h2 className="text-xl font-bold mb-4">Participant Details</h2>

      <EditDetails
        clientDetail={clientDetail}
        handleInputDataEditChange={handleInputDataEditChange}
        supportForm={supportForm}
      />
      <h2 className="text-xl font-bold">
        Primary participant family or representative details (emergency contact)
      </h2>

      <EditRepContact
        careManager={careManager}
        supportForm={supportForm}
        handleInputFormChange={handleInputDataEditChange}
        handleRadioFormChange={handleRadioDataChange}
      />

      <h2 className="text-xl font-bold">Court orders</h2>
      <EditCourtOrder
        supportForm={supportForm}
        handleInputFormChange={handleInputDataEditChange}
        handleRadioFormChange={handleRadioDataChange}
      />
      <h2 className="text-xl font-bold">Communication</h2>
      <EditCommunication
        supportForm={supportForm}
        handleInputFormChange={handleInputDataEditChange}
      />
      <h2 className="text-xl font-bold">
        Cultural and spiritual considerations
      </h2>
      <EditCultural
        supportForm={supportForm}
        handleInputFormChange={handleInputDataEditChange}
      />
      <h2 className="text-xl font-bold">Decision making</h2>
      <EditDecision
        supportForm={supportForm}
        handleInputFormChange={handleInputDataEditChange}
      />
      <h2 className="text-xl font-bold">Support requirements</h2>
      <EditSupport
        handleAddRowEditSupport={handleAddRowEditSupport}
        handleInputEditChangeSupport={handleInputEditChangeSupport}
        editSupport={editSupport}
      />
      <h2 className="text-xl font-bold">
        Risk management (for any risk identified when completing this form)
      </h2>
      <EditRisk
        supportForm={supportForm}
        handleInputFormChange={handleInputDataEditChange}
      />
      <AgreementSignature
        careManager={careManager}
        handleSignatureSave={() => ""}
        signature={null}
        clientDetail={clientDetail}
        setSignature={() => ""}
        clientRep={null}
        companyOne={companyOne}
        careManagerName={""}
        setCareManagerName={() => ""}
      />
    </div>
  );
};

export default EditSupportFormComp;
