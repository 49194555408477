import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

export interface Inbox {
  company: string;
  companyId: string;
  content: string;
  dateCreated: string;
  dateModified: string;
  emailFrom: string;
  emailTo: string;
  file: string;
  formFIle: string;
  messageId: number;
  phoneNumber: number;
  status: boolean;
  subject: string;
  userCreated: string;
  userModified: string;
}

export interface InboxState {
  loading: boolean;
  data: Array<Inbox>;
  error: string | undefined;
}
const initialState: InboxState = {
  loading: false,
  data: [],
  error: undefined,
};
export const fetchInbox = createAsyncThunk(
  "Inbox/fetchInbox",
  async (user: string) => {
    try {
      const response = await api.fetchInboxData(user);
      return response.message;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

const inboxSlice = createSlice({
  name: "inbox",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchInbox.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchInbox.fulfilled,
      (state, action: PayloadAction<Array<Inbox>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(fetchInbox.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {},
});
export const userSelector = (state: RootState) => state.inbox;
export default inboxSlice.reducer;

export const inboxData = (state: RootState) => state.inbox.data;
export const inboxLoading = (state: RootState) => state.inbox.loading;
export const inboxError = (state: RootState) => state.inbox.error;
