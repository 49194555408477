import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import DataTable, { TableColumn } from "react-data-table-component";
import { BsSearch } from "react-icons/bs";
import ExportDropdown from "../../../Components/menu/ExportDropdown";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import Spinner from "../../../Components/Spinner";
import {
  FaEye,
  FaFileCsv,
  FaFileExcel,
  FaFilePdf,
  FaFilter,
} from "react-icons/fa";
import IconButton from "../../../Components/Buttons/IconButton";
import { InputPicker, Modal, Tooltip, Whisper } from "rsuite";
import axiosInstance from "../../../store/axiosInstance";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import {
  downloadCSV,
  exportToExcel,
  handlePDFDownload,
} from "../../../services/reusableFunc";
import { Column } from "../../../types/DataType";
import dayjs from "dayjs";
import {
  fetchPaymentHistroy,
  paymentHistroyData,
  paymentHistroyLoading,
  PaymentDataType,
  filterPaymentHistory,
} from "../../../store/slices/paymentHistroySlice";
import CustomInput from "../../../Components/Input/CustomInput";

const PaymentHistroy = () => {
  const columns: TableColumn<PaymentDataType>[] = [
    {
      name: "Payment ID",
      selector: (row) => row.paymentId,
    },
    {
      name: "Package",
      selector: (row) => row.payment_Description,
    },
    {
      name: "Amount($)",
      selector: (row) => row.totalAmount,
      cell: (row) => (
        <span className="font-bold text-start text-dark overflow-hidden">
          {row.totalAmount}
        </span>
      ),
    },
    {
      name: "Number OF Users",
      selector: (row) => row.noOfUser,
      cell: (row) => (
        <span className="font-bold text-start text-dark overflow-hidden">
          {row.noOfUser}
        </span>
      ),
    },
    {
      name: "Payment Status",
      selector: (row) => row.paymentStatus,
      cell: (row) => (
        <small
          className={`${
            row.paymentStatus === "Pending"
              ? "bg-[#6226EF]/20 text-[#6226EF]"
              : row.paymentStatus === "Successful"
              ? "bg-[#00B69B]/15 text-[#00B69B]"
              : "bg-[#EF3826]/20 text-[#EF3826]"
          } text-start text-dark overflow-hidden px-1.5 py-0.5  rounded
              
              `}
        >
          {row.paymentStatus}
        </small>
      ),
    },
    {
      name: "Payment Date",
      selector: (row) => row.payment_DateTime,
      cell: (row) => (
        <span className="font-bold text-start text-gray-500 overflow-hidden">
          {dayjs(row.payment_DateTime).format("D MMM, YYYY") || ""}
        </span>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <span className="flex gap-4">
          <div>
            <Whisper
              placement="auto"
              controlId="control-id-hover"
              trigger="hover"
              speaker={<Tooltip>View Payment Details</Tooltip>}
            >
              <button
                onClick={() => EditDetail(row.paymentId)}
                className="flex justify-between items-center px-4 py-2 border
                                 rounded border-brand bg-gray-100 text-brand no-underline 
                                 hover:no-underline transition duration-300 
                                 transform active:scale-95 ease-in-out"
              >
                <FaEye className="text-base cursor-pointer" />
              </button>
            </Whisper>
          </div>
        </span>
      ),
    },
  ];

  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();
  const companyId = user?.companyId;
  useEffect(() => {
    if (companyId) {
      dispatch(fetchPaymentHistroy(companyId));
    }
  }, []);
  const paymentHistroy = useSelector(paymentHistroyData);
  const loadingData = useSelector(paymentHistroyLoading);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const [loading1, setLoading1] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");
  const [filterModal, setFilterModal] = useState<boolean>(false);

  const [paymentDetails, setPaymentDetails] = useState<PaymentDataType>({
    paymentId: "",
    totalAmount: 0,
    noOfUser: 0,
    payment_DateTime: "",
    payment_Method: "",
    transaction_ID: 0,
    payment_Response: "",
    payment_Description: "",
    paymentStatus: "",
    authorization_url: "",
    session_Id: "",
    companyId: "",
    expirationDate: "",
    company: {
      subscribtionDate: "",
      expirationDate: "",
    },
    dateCreated: "",
    userCreated: "",
    dateModified: "",
    userModified: "",
  });
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredData = paymentHistroy?.filter((item) =>
    item?.paymentStatus?.toLowerCase().includes(searchText.toLowerCase())
  );

  const EditDetail = async (id: string) => {
    // setSaveId(id);
    setShowModal(true);
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(`/Payments/details/${id}`);
      setPaymentDetails({ ...data });
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    }
  };

  const resetFilter = (e: FormEvent) => {
    e.preventDefault();
    companyId && dispatch(fetchPaymentHistroy(companyId));
    setStatus("");
    setDateFrom("");
    setDateTo("");
    setFilterModal(false);
  };

  const filterActivity = (e: FormEvent) => {
    e.preventDefault();
    setLoading1(true);

    if (companyId && status) {
      dispatch(
        filterPaymentHistory({
          companyId: companyId,
          dateFrom: dateFrom,
          dateTo: dateTo,
          status: status,
        })
      );
    }
    setLoading1(false);
    setFilterModal(false);
  };

  return (
    <>
      <SEO
        title="Payment Histories"
        description="Payment Histories"
        name="Promax-Care"
      />
      <Breadcrumb />

      <div className="mt-10">
        {paymentHistroy.length <= 0 && loadingData && (
          <Spinner color="#071952" />
        )}
        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
          <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
            <h2 className="font-bold text-xl text-black w-auto">
              Payment Histories
            </h2>

            <div className="relative w-full lg:col-span-2">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <BsSearch />
              </div>
              <input
                type="text"
                id="simple-search"
                onChange={handleSearch}
                className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder=""
              />
            </div>
          </div>
          <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
            <div className="flex items-center space-x-3 w-full md:w-auto">
              <button
                onClick={() => setFilterModal(true)}
                id="actionsDropdownButton"
                className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                type="button"
              >
                <FaFilter />
                Filter
              </button>
              <ExportDropdown>
                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <IconButton
                    icon={<FaFilePdf className="text-red-500" />}
                    title="Export as PDF"
                    onClick={() =>
                      handlePDFDownload(
                        paymentHistroy,
                        columns as unknown as Column<PaymentDataType>[],
                        "Payment.pdf"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileExcel className="text-green-600" />}
                    title="Export as Excel Sheet"
                    onClick={() =>
                      exportToExcel(
                        paymentHistroy,
                        columns as unknown as Column<PaymentDataType>[],
                        "Payment"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileCsv className="text-green-500" />}
                    title="Export as CSV"
                    onClick={() =>
                      downloadCSV({
                        data: paymentHistroy,
                        filename: "Payment.csv",
                      })
                    }
                  />
                </div>
              </ExportDropdown>
            </div>
          </div>
        </div>

        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          paginationTotalRows={paymentHistroy?.length}
        />
      </div>

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Payment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Spinner />
          ) : (
            <div className="">
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Number Of Users:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {" "}
                  {paymentDetails.noOfUser}
                </td>
              </tr>
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Payment Description:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {" "}
                  {paymentDetails.payment_Description}
                </td>
              </tr>
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Total Amount:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {" "}
                  {paymentDetails.totalAmount}
                </td>
              </tr>
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Total Method:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {" "}
                  {paymentDetails.payment_Method}
                </td>
              </tr>
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Payment Status:
                </td>
                <td
                  className={` ${
                    paymentDetails.paymentStatus === "Pending"
                      ? "bg-[#6226EF]/20 text-[#6226EF]"
                      : paymentDetails.paymentStatus === "Successful"
                      ? "bg-[#00B69B]/15 text-[#00B69B]"
                      : "bg-[#EF3826]/20 text-[#EF3826]"
                  } text-start text-dark overflow-hidden px-1.5 py-0.5  rounded
              
              `}
                >
                  {" "}
                  {paymentDetails.paymentStatus}
                </td>
              </tr>
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Payment Date Time:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {" "}
                  {dayjs(paymentDetails.payment_DateTime).format(
                    "D MMM, YYYY"
                  ) || ""}
                </td>
              </tr>
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Date Created:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {dayjs(paymentDetails.dateCreated).format(
                    "MMMM D, YYYY h:mm A"
                  )}
                </td>
              </tr>
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Transaction ID:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {paymentDetails.transaction_ID}
                </td>
              </tr>
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Payment ID:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {paymentDetails.paymentId}
                </td>
              </tr>
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  User Created:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {paymentDetails.userCreated}
                </td>
              </tr>
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Date Modified
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {dayjs(paymentDetails.dateModified).format(
                    "MMMM D, YYYY h:mm A"
                  )}
                </td>
              </tr>
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  User Modified
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {paymentDetails.userModified}
                </td>
              </tr>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={filterModal}
        onClose={() => setFilterModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form action="" className="space-y-2">
              <div>
                <label className=" text-xs text-gray-600 font-semibold">
                  Select Status
                </label>
                <InputPicker
                  size="lg"
                  placeholder="--Select Status--"
                  onChange={(value) => setStatus(value)}
                  data={[
                    { label: "Successful", value: "Successful" },
                    { label: "Pending", value: "Pending" },
                    { label: "Failed", value: "Failed" },
                  ]}
                  className="w-full focus:outline-none focus:border-primary text-gray-600"
                  appearance="subtle"
                />
              </div>

              <CustomInput
                label="Start Date"
                type="datetime-local"
                name="dateFrom"
                onChange={(e) => setDateFrom(e.target.value)}
                value={dateFrom}
                required
              />
              <CustomInput
                label="End Date"
                type="datetime-local"
                name="dateTo"
                onChange={(e) => setDateTo(e.target.value)}
                value={dateTo}
                required
              />

              <div className="flex items-center justify-center mt-3">
                <div className="text-center">
                  <button
                    type="submit"
                    onClick={resetFilter}
                    className="text-black bg-white border border-primary hover:bg-white/45 focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-8 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                  >
                    Reset Filter
                  </button>
                </div>

                <div className="text-center">
                  <button
                    type="submit"
                    onClick={filterActivity}
                    disabled={loading1}
                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                  >
                    {loading1 ? <Spinner /> : "Load"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default PaymentHistroy;
