import React, { FormEvent, useRef, useState } from "react";
import SEO from "../../../constants/SEO";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import CustomInput from "../../../Components/Input/CustomInput";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axiosInstance from "../../../store/axiosInstance";
import { isAxiosError } from "axios";
import Spinner from "../../../Components/Spinner";
import { useNavigate } from "react-router-dom";

const CreateRoles = () => {
  const role = useRef<HTMLInputElement>(null);
  const user = useSelector((state: RootState) => state?.auth?.user);
  const [loading1, setLoading1] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (role.current?.value === "") {
      return toast.error("Enter a Role Name");
    }
    setLoading1(true);

    const info = {
      companyId: user?.companyId,
      role: role.current?.value,
    };

    try {
      const { data } = await axiosInstance.post(
        `/Account/create_role?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      setLoading1(false);
      navigate(`/app/user/all-roles`);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
    } finally {
      setLoading1(false);
    }
  };

  return (
    <>
      <SEO title="Create Roles" description="Roles" name="Promax-Care" />
      <Breadcrumb />

      <div className="mt-10">
        <div className="">
          <h2 className="font-bold text-2xl text-black w-auto">
            Create Roles{" "}
          </h2>
        </div>
        <div className="mt-5">
          <CustomInput
            label="Enter Role Name"
            type="text"
            ref={role}
            required
            placeholder="First Name"
          />
        </div>

        <div className="mx-auto text-center mt-3 lg:col-span-2">
          <button
            type="submit"
            onClick={handleSubmit}
            className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"
          >
            {" "}
            {loading1 ? <Spinner /> : "Submit"}
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateRoles;
