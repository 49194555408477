import React from "react";
import Authority from "./Authority";
import { CompanyDetail } from "../../../types/AuthData";
import { ParticipantConsentForm } from "../../../types/DataType";
import EditCheck from "./EditCheck";
import EditCheck2 from "./EditCheck2";

interface Props {
    companyOne: CompanyDetail | null | undefined;
    participantConsentForm: ParticipantConsentForm;
    handleCheckboxDataChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const EditSection3: React.FC<Props> = ({ companyOne, participantConsentForm, handleCheckboxDataChange }) => {


    return (
        <div>
            <div className="p-4 max-[1024px]:w-[100%]  my-3 border border-black ">
                <strong>
                    Support with managing money or property (please do not tick if it
                    doesn’t apply)
                </strong>
                <EditCheck companyOne={companyOne} handleCheckboxDataChange={handleCheckboxDataChange} participantConsentForm={participantConsentForm} />
                <EditCheck2 companyOne={companyOne} handleCheckboxDataChange={handleCheckboxDataChange} participantConsentForm={participantConsentForm} />
                <div>
                    <p className="flex items-center my-4 gap-3">
                        <input
                            type="checkbox"
                            name="consent_to_Manage_Money"
                            checked={participantConsentForm.consent_to_Manage_Money}
                            onChange={handleCheckboxDataChange}
                        />
                        <label htmlFor="clr">
                            I have been given a copy of the Participant Welcome Handbook and
                            have been advised of the policies and procedures that will be
                            followed in relation to managing my money and property.
                        </label>
                    </p>
                    <p className="font-semibold">
                        Please note that in providing your consent to receive the above
                        assistance with money or property, you will be supported by us to
                        access and spend your own money as you determine. We will not give
                        you financial advice or information other than that which would
                        reasonably be required under your plan.
                    </p>
                </div>
            </div>

            <Authority companyOne={companyOne} />

            <div className="my-5">
                <p className="font-semibold">How do I withdraw my consent or authority?</p>
                <p>It’s important to know that this consent can be withdrawn at any time. You can withdraw your consent by writing to us. You can do this by sending us an email, a letter, or a text.</p>
            </div>

            <div className="my-5">
                <p className="font-semibold">How long will this consent or authority last?</p>
                <p>This consent form will last for one (1) year unless you choose to withdraw consent.</p>
            </div>
        </div>
    );
};

export default EditSection3;
