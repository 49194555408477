import React, { FormEvent } from 'react'
import { Modal } from 'rsuite';
import CustomInput from '../../../../Components/Input/CustomInput';
import CustomSelect from '../../../../Components/Input/CustomSelect';
import Spinner from '../../../../Components/Spinner';
import { AdminDataRow } from '../../../../types/AuthData';
interface Props {
    employModal: boolean;
    setEmployModal: React.Dispatch<React.SetStateAction<boolean>>
    editedProfile: AdminDataRow
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
    submitData: (e: FormEvent) => Promise<void>
    loading1: boolean;
}
const AdminEmployComp = ({ employModal, setEmployModal, editedProfile, handleInputChange, submitData, loading1 }: Props) => {
    return (
        <>
            <Modal
                open={employModal}
                onClose={() => setEmployModal(false)}
                backdrop="static"
                autoFocus
                size={"lg"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Edit Employment Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form action="" onSubmit={submitData}>
                            <div className="p-2 grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8 ">
                                <CustomSelect
                                    label="Employment Type"
                                    name="employmentType"
                                    options={[
                                        { value: '', label: 'Select Employment Type' },
                                        { value: 'Full Time', label: 'Full Time' },
                                        { value: 'Part Time', label: 'Part Time' },
                                        { value: 'Causual', label: 'Causual' },
                                        { value: 'Contract', label: 'Contract' }
                                    ]}
                                    value={editedProfile.employmentType || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomSelect
                                    label="PayRate"
                                    name="payRate"
                                    options={[
                                        { value: '', label: 'Select Pay Rate' },
                                        { value: 'Annual', label: 'Annual' },
                                        { value: 'Monthly', label: 'Monthly' },
                                        { value: 'Fortnightly', label: 'Fortnightly' },
                                        { value: 'Hourly', label: 'Hourly' }
                                    ]}
                                    value={editedProfile.payRate || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="Salary"
                                    type="text"
                                    name='salary'
                                    value={editedProfile.salary || ""}
                                    onChange={handleInputChange}
                                />

                            </div>

                            <div className='flex items-center justify-center'>
                                <button type="submit"
                                    disabled={loading1 ? true : false}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">{
                                        loading1 ? <Spinner /> : "Update"
                                    }</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AdminEmployComp