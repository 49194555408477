import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import DataTable, {
  TableColumn,
  ExpanderComponentProps,
} from "react-data-table-component";
import {
  FaEye,
  FaFileCsv,
  FaFileExcel,
  FaFilePdf,
  FaRegEdit,
} from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import ExportDropdown from "../../../Components/menu/ExportDropdown";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import Spinner from "../../../Components/Spinner";
import { Button, Modal, Tooltip, Whisper } from "rsuite";
import {
  downloadCSV,
  exportToExcel,
  handlePDFDownload,
} from "../../../services/reusableFunc";
import { Column } from "../../../types/DataType";
import IconButton from "../../../Components/Buttons/IconButton";
import {
  documentData,
  documentLoading,
  filterDocument,
} from "../../../store/slices/documentSlice";
import moment from "moment";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import axiosInstance from "../../../store/axiosInstance";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import CustomInput from "../../../Components/Input/CustomInput";

interface DataRow {
  company: string;
  companyId: number;
  dateCreated: string;
  dateModified: string;
  documentFile: string;
  documentId: number;
  documentName: string;
  documentUrl: string;
  expirationDate: string;
  implementationDate: string;
  isArchived: boolean;
  rejectDeadline: string;
  rejectReason: string;
  status: string;
  user: string;
  userCreated: string;
  userId: number;
  userModified: string;
  userRole: string;
  verify: boolean;
}

const AdminDocument = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault("Australia/Sydney");
  const nowInAustraliaTime = dayjs().tz().format("YYYY-MM-DD");

  const columns: TableColumn<DataRow>[] = [
    {
      name: "User",
      selector: (row) => row.user,
      sortable: true,
    },
    {
      name: "Document",
      selector: (row) => row.documentName,
      sortable: true,
      cell: (row) => (
        <div
          className="truncate flex flex-col gap-1 p-2"
          style={{ overflow: "hidden" }}
        >
          <Whisper
            placement="auto"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>{row.documentName}</Tooltip>}
          >
            <div
              className="flex flex-col gap-1 p-2"
              style={{ overflow: "hidden" }}
            >
              <span> {row.documentName}</span>

              {/* <span className='flex'>
                                <span className='bg-primary text-white pointer px-2 py-1 rounded d-flex justify-content-center align-items-center'
                                    title='View'

                                    onClick={() => handleView(row.documentUrl)}
                                >

                                    <FaEye />
                                </span>
                            </span> */}
            </div>
          </Whisper>
        </div>
      ),
    },
    {
      name: "Implementation Date",
      selector: (row) =>
        !row.implementationDate
          ? "No Implementation Date"
          : moment(row.implementationDate).format("ll"),
      cell: (row) => (
        <span>
          {!row.implementationDate
            ? "Not Applicable"
            : moment(row.implementationDate).format("ll")}
        </span>
      ),
    },
    {
      name: "Expiration Date",
      selector: (row) =>
        !row.expirationDate
          ? "Not Applicable"
          : moment(row.expirationDate).format("ll"),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => {
        const isExpired =
          dayjs(row.expirationDate).format("YYYY-MM-DD") < nowInAustraliaTime;
        let status;
        let statusClasses = "px-8 py-2 rounded font-bold";

        if (isExpired) {
          status = "Expired";
          statusClasses += " bg-red-500 text-white";
        } else if (row.status === "Rejected") {
          status = "Rejected";
          statusClasses += " bg-gray-300 text-black";
        } else if (row.status === "Pending") {
          status = "Pending";
          statusClasses += " bg-secondary text-black";
        } else if (row.status === "Accepted") {
          status = "Accepted";
          statusClasses += " bg-green-500 text-white";
        } else {
          status = row.status;
          statusClasses += " bg-transparent";
        }

        return (
          <span
            className={statusClasses}
            style={{ fontSize: "10px", whiteSpace: "nowrap" }}
          >
            {status}
          </span>
        );
      },
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-2 justify-center">
          <Whisper
            placement="auto"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Preview Document</Tooltip>}
          >
            <button
              onClick={() => handleView(row.documentUrl)}
              className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
            >
              <FaEye className="text-base" />
            </button>
          </Whisper>
          <Whisper
            placement="auto"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Edit Document</Tooltip>}
          >
            <button
              onClick={() => handleEdit(row.documentId)}
              className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
            >
              <FaRegEdit className="text-base" />
            </button>
          </Whisper>
        </div>
      ),
    },
  ];

  const handleView = (documentUrl: string): void => {
    window.open(documentUrl, "_blank");
  };

  const [loading1, setLoading1] = useState<boolean>(false);
  const [saveId, setSaveId] = useState<number>(0);
  const [editDocModal, setEditDocModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [acceptModal, setAcceptModal] = useState<boolean>(false);
  const [rejectModal, setRejectModal] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");
  const deadline = useRef<HTMLInputElement>(null);
  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();
  const companyId = user?.companyId;
  useEffect(() => {
    if (companyId) {
      dispatch(
        filterDocument({
          companyId: companyId,
          dateFrom: "",
          dateTo: "",
          sta: "",
          admin: "",
          status: "",
          role: "Admin",
          archive: false,
        })
      );
    }
  }, [dispatch, companyId]);
  const adminDoc = useSelector(documentData);
  const loading = useSelector(documentLoading);
  const [docDetails, setDocDetails] = useState<DataRow>({
    company: "",
    companyId: 0,
    dateCreated: "",
    dateModified: "",
    documentFile: "",
    documentId: 0,
    documentName: "",
    documentUrl: "",
    expirationDate: "",
    implementationDate: "",
    isArchived: false,
    rejectDeadline: "",
    rejectReason: "",
    status: "",
    user: "",
    userCreated: "",
    userId: 0,
    userModified: "",
    userRole: "",
    verify: false,
  });

  const handleDel = (id: number) => {
    setSaveId(id);
    setDeleteModal(true);
  };

  const handleAccept = (id: number) => {
    setSaveId(id);
    setAcceptModal(true);
  };

  const handleReject = (id: number) => {
    setSaveId(id);
    setRejectModal(true);
  };

  const handleEdit = async (id: number) => {
    setSaveId(id);
    setEditDocModal(true);
    try {
      const { data } = await axiosInstance.get(`/Documents/get_document/${id}`);
      setDocDetails({ ...data.staffDocument });
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
    }
  };

  const [upLoadDoc, setUpLoadDoc] = useState<File | null>(null);
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setUpLoadDoc(file);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setDocDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  interface Props extends ExpanderComponentProps<DataRow> {
    // currently, props that extend ExpanderComponentProps must be set to optional.
    someTitleProp?: string;
  }

  const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
    return (
      <div className="p-2 flex flex-col gap-2 text-xs">
        <div>
          <span className="font-bold">Documnet Name: </span> {data.documentName}
        </div>
        <div>
          <span className="font-bold">Date Created: </span>{" "}
          {moment(data.dateCreated).format("lll")}
        </div>
        <div>
          <span className="font-bold">Date Modified: </span>
          {moment(data.dateModified).format("lll")}
        </div>

        <div className="flex gap-2">
          <span className="font-bold">Actions: </span>
          {/* <button className="text-secondary font-bold" style={{ fontSize: "12px" }}
                        onClick={() => handleEdit(data.documentId)}
                    >
                        Edit
                    </button> | */}
          <button
            onClick={() => handleDel(data.documentId)}
            className="text-red-500 font-bold"
            style={{ fontSize: "12px" }}
          >
            Delete
          </button>{" "}
          |
          {data.status === "Pending" && (
            <div>
              <button
                onClick={() => handleAccept(data.documentId)}
                className="text-secondary font-bold"
                style={{ fontSize: "12px" }}
              >
                Accept
              </button>{" "}
              |
              <button
                onClick={() => handleReject(data.documentId)}
                className="text-primary font-bold"
                style={{ fontSize: "12px" }}
              >
                Reject
              </button>
            </div>
          )}
          {data.status === "Rejected" && (
            <button
              onClick={() => handleAccept(data.documentId)}
              className="text-primary font-bold"
              style={{ fontSize: "12px" }}
            >
              Accept
            </button>
          )}
          {data.status === "Accepted" && (
            <button
              onClick={() => handleReject(data.documentId)}
              className="text-primary font-bold"
              style={{ fontSize: "12px" }}
            >
              Reject
            </button>
          )}
        </div>
      </div>
    );
  };

  const handleDeleteDoc = async (e: FormEvent) => {
    e.preventDefault();
    setLoading1(true);
    try {
      const { data } = await axiosInstance.post(`/Documents/delete/${saveId}`);
      toast.success(data.message);
      setDeleteModal(false);
      companyId &&
        dispatch(
          filterDocument({
            companyId: companyId,
            dateFrom: "",
            dateTo: "",
            sta: "",
            admin: "",
            status: "",
            role: "Admin",
            archive: false,
          })
        );
      setLoading1(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
      setDeleteModal(false);
    } finally {
      setLoading1(false);
    }
  };
  const handleAcceptDoc = async (e: FormEvent) => {
    e.preventDefault();
    setLoading1(true);
    try {
      const { data } = await axiosInstance.get(
        `/Documents/accept_document?userId=${user?.uid}&id=${saveId}`
      );
      toast.success(data.message);
      setAcceptModal(false);
      companyId &&
        dispatch(
          filterDocument({
            companyId: companyId,
            dateFrom: "",
            dateTo: "",
            sta: "",
            admin: "",
            status: "",
            role: "Admin",
            archive: false,
          })
        );
      setLoading1(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
      setAcceptModal(false);
    } finally {
      setLoading1(false);
    }
  };
  const handleRejectDoc = async (e: FormEvent) => {
    e.preventDefault();
    if (reason.trim() === "") {
      toast.error("provide valid reason");
    }
    setLoading1(true);
    try {
      const { data } = await axiosInstance.post(
        `/Documents/reject_document?userId=${user?.uid}&docid=${saveId}&reason=${reason}&deadline=${deadline.current?.value}`
      );
      toast.success(data.message);
      setRejectModal(false);
      companyId &&
        dispatch(
          filterDocument({
            companyId: companyId,
            dateFrom: "",
            dateTo: "",
            sta: "",
            admin: "",
            status: "",
            role: "Admin",
            archive: false,
          })
        );
      setLoading1(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
      setRejectModal(false);
    } finally {
      setLoading1(false);
    }
  };

  const handleEditSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading1(true);
    const formData = new FormData();

    if (upLoadDoc) {
      formData.append("DocumentFile", upLoadDoc);
    }

    for (const key in docDetails) {
      const value = docDetails[key as keyof typeof docDetails];
      if (value === null) {
        formData.append(key, ""); // Pass empty string if value is null
      } else {
        formData.append(key, value.toString());
      }
    }

    try {
      const { data } = await axiosInstance.post(
        `/Documents/edit/${saveId}?userId=${user?.uid}`,
        formData
      );
      toast.success(data.message);
      setLoading1(false);
      setEditDocModal(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
    } finally {
      setLoading1(false);
    }
  };

  const [searchText, setSearchText] = useState<string>("");

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredData = adminDoc?.filter(
    (item) =>
      item?.user?.toLowerCase().includes(searchText?.toLowerCase()) ||
      item?.documentName?.toLowerCase().includes(searchText?.toLowerCase())
  );

  return (
    <>
      <SEO title="Document" description="Document" name="Promax-Care" />
      <Breadcrumb />
      <div className="mt-10">
        {adminDoc.length <= 0 && loading && <Spinner color="#071952" />}
        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
          <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
            <h2 className="font-bold text-xl text-black w-auto">
              Admin Document
            </h2>

            <div className="relative w-full lg:col-span-2">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <BsSearch />
              </div>
              <input
                type="text"
                id="simple-search"
                onChange={handleSearch}
                className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Search By Admin Name or Document Name"
              />
            </div>
          </div>
          <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
            <div className="flex items-center space-x-3 w-full md:w-auto">
              {/* <button onClick={() => setFilterModal(true)} id="actionsDropdownButton" className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                                <FaFilter />
                                Filter
                            </button> */}
              <ExportDropdown>
                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <IconButton
                    icon={<FaFilePdf className="text-red-500" />}
                    title="Export as PDF"
                    onClick={() =>
                      handlePDFDownload(
                        adminDoc,
                        columns as unknown as Column<DataRow>[],
                        "AdminDocument.pdf"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileExcel className="text-green-600" />}
                    title="Export as Excel Sheet"
                    onClick={() =>
                      exportToExcel(
                        adminDoc,
                        columns as unknown as Column<DataRow>[],
                        "AdminDocument"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileCsv className="text-green-500" />}
                    title="Export as CSV"
                    onClick={() =>
                      downloadCSV({
                        data: adminDoc,
                        filename: "AdminDocument.csv",
                      })
                    }
                  />
                </div>
              </ExportDropdown>
            </div>
            {/* <button type="button"

                            className="px-4 py-2 bg-primary text-white  disabled:bg-slate-400
transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm 
">

                            Add New Document
                        </button> */}
          </div>
        </div>

        <DataTable
          columns={columns}
          data={filteredData}
          expandableRows
          expandableRowsComponent={ExpandableRowComponent}
          pagination
          paginationTotalRows={adminDoc?.length}
          // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}
        />
      </div>

      <Modal
        open={acceptModal}
        onClose={() => setAcceptModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="mb-4 font-bold">Accept This Document ?</p>
            <div className="flex justify-center items-center space-x-4">
              <Button
                onClick={() => setAcceptModal(false)}
                className="bg-gray-500 text-white"
              >
                No, cancel
              </Button>
              <Button
                onClick={handleAcceptDoc}
                className="bg-primary text-white hover:bg-primary hover:text-white"
              >
                {loading1 ? <Spinner /> : "Yes, I'm sure"}
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={rejectModal}
        onClose={() => setRejectModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Reject Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="" className="space-y-2">
            <div className="">
              <label htmlFor="reason" className="font-bold">
                Please provide reasons for rejecting document{" "}
              </label>
              <textarea
                id="reason"
                rows={3}
                autoComplete="off"
                onChange={(e) => setReason(e.target.value)}
                className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                defaultValue={""}
              />
            </div>
            <CustomInput
              label="Set a new deadline"
              type="date"
              ref={deadline}
              placeholder="Set a new deadline"
            />
            <div className="mx-auto text-center mt-3">
              <button
                type="submit"
                onClick={handleRejectDoc}
                disabled={loading1 ? true : false}
                className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
              >
                {loading1 ? <Spinner /> : "Reject Document"}
              </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Delete Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="mb-4 font-bold">
              Are you sure you want to delete this Document ?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <Button
                onClick={() => setDeleteModal(false)}
                className="bg-gray-500 text-white"
              >
                No, cancel
              </Button>
              <Button
                onClick={handleDeleteDoc}
                className="bg-red-500 text-white hover:bg-red-700 hover:text-white"
              >
                {loading1 ? <Spinner /> : "Yes, I'm sure"}
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={editDocModal}
        onClose={() => setEditDocModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Edit Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form action="" className="space-y-2">
              <CustomInput
                label="Document Name"
                type="text"
                name="documentName"
                value={docDetails?.documentName}
                onChange={handleInputChange}
              />
              <CustomInput
                label="Implementation Date"
                type="date"
                name="implementationDate"
                value={docDetails?.implementationDate}
                onChange={handleInputChange}
              />
              <CustomInput
                label="Expiration Date"
                type="date"
                name="expirationDate"
                value={docDetails?.expirationDate}
                onChange={handleInputChange}
              />
              <CustomInput
                label="Upload Document"
                type="file"
                accept=".pdf,.doc,.docx"
                onChange={handleFileChange}
              />

              <div className="mx-auto text-center mt-3">
                <button
                  type="submit"
                  onClick={handleEditSubmit}
                  disabled={loading ? true : false}
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                >
                  {loading1 ? <Spinner /> : "Update"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      {/* <Modal open={filterModal} onClose={() => setFilterModal(false)} backdrop="static" autoFocus size={"sm"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form action="" className='space-y-2'>

                            <div>
                                <label className=" text-xs text-gray-600 font-semibold">Select Admin</label>
                                <InputPicker size="lg" placeholder="--All Admin--"
                                    // onChange={value => setUsers(value)}
                                    data={adminDoc.map(user => ({ label: user.documentName, value: user.companyId }))}
                                    className='w-full focus:outline-none focus:border-primary text-gray-600'
                                    appearance='subtle'
                                />
                            </div>

                            <div>
                                <label className=" text-xs text-gray-600 font-semibold">Select Status</label>
                                <InputPicker size="lg" placeholder="--Select Status--"
                                    onChange={value => setStatus(value)}
                                    data={[
                                        { label: 'Active', value: "true" },
                                        { label: 'InActive', value: "false" }
                                    ]}
                                    className='w-full focus:outline-none focus:border-primary text-gray-600'
                                    appearance='subtle'
                                />
                            </div>

                            <div className='flex items-center justify-center mt-3'>

                                <div className='text-center'>
                                    <button type="submit"
                                        onClick={resetFilter}
                                        className="text-black bg-white border border-primary hover:bg-white/45 focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-8 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">
                                        Reset Filter
                                    </button>
                                </div>

                                <div className='text-center'>
                                    <button type="submit"
                                        onClick={filterActivity}
                                        disabled={loading1 ? true : false}
                                        className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">{
                                            loading1 ? <Spinner /> : "Load"
                                        }</button>
                                </div>
                            </div>
                        </form>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal> */}
    </>
  );
};

export default AdminDocument;
