import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

export interface NotSpitted {
  attendanceSplits: ShiftAttendance;
  staffTimeSheet: string;
  staff: boolean;
  client: boolean;
  totalDuration: number;
  totalAgreedDuration: number;
  totalAmount: number;
  normalDuration: number;
  eveningDuration: number;
  exceptionalDuration: number;
  satDuration: number;
  sunDuration: number;
  phDuration: number;
  totalKm: number;
  nightShift: number;
  staffName: string;
  fromDate: string;
  toDate: string;
  clientName: string;
  xeroUploadLink: string;
}

export interface ShiftAttendance {
  attendanceId: number;
  attendanceSplitId: number;
  clockIn: string;
  clockInCheck: boolean;
  clockOut: string;
  clockOutCheck: boolean;
  companyID: number;
  dateCreated: string;
  dateModified: string;
  duration: number;
  eveningDuration: number;
  exceptionalDuration: number;
  nightShift: number;
  normalDuration: number;
  phDuration: number;
  satDuration: number;
  shift: string;
  shiftId: number;
  staffId: number;
  sunDuration: number;
  totalAgreedDuration: number;
  totalKm: number;
  userCreated: string;
  userModified: string;
  shiftRoster: {
    activities: string;
    appointment: string;
    attendId: number;
    attendance: boolean;
    cancellationDate: string;
    clients: string;
    companyID: number;
    dateCreated: string;
    dateFrom: string;
    dateModified: string;
    dateTo: string;
    isCurrent: boolean;
    isEnded: boolean;
    isExceptionalShift: boolean;
    isNightShift: boolean;
    status: string;
    profile: {
      profileId: number;
      firstName: string;
      surName: string;
      middleName: string;
      clientId: number;
      contactId: string;
      address: string;
      email: string;
      phoneNumber: string;
      gender: string;
      imageUrl: string;
      imageFile: string;
      country: string;
      state: string;
      city: string;
      isCompleted: boolean;
      isActive: boolean;
      dateOfBirth: string; // You may want to use Date type if it's a date string
      homePhone: string;
      workPhone: string;
      profession: string;
      culturalBackground: string;
      preferredLanguage: string;
      requireInterpreter: boolean;
      indigenousSatatus: string;
      ndisPlan: string;
      ndisPlanNote: string;
      privacyPreferences: string;
      financialArrangement: string;
      ndisNo: string;
      agreementStartDate: string; // You may want to use Date type if it's a date string
      agreementEndDate: string; // You may want to use Date type if it's a date string
      nextOfKin: string;
      relationship: string;
      kinPostcode: string;
      kinAddress: string;
      kinCountry: string;
      kinCity: string;
      kinEmail: string;
      suburb: string;
      kinState: string;
      kinPhoneNumber: string;
      fullName: string;
      dateCreated: string; // You may want to use Date type if it's a date string
      userCreated: string;
      dateModified: string; // You may want to use Date type if it's a date string
      userModified: string;
    };
    staff: {
      staffId: number;
      maxStaffId: string;
      firstName: string;
      surName: string;
      middleName: string;
      aboutMe: string;
      address: string;
      postcode: string;
      email: string;
      employeeId: string;
      phoneNumber: string;
      profession: string;
      gender: string;
      imageUrl: string;
      imageFile: string;
      country: string;
      state: string;
      city: string;
      isCompleted: boolean;
      isActive: boolean;
      dateOfBirth: string; // You may want to use Date type if it's a date string
      facebook: string;
      twitter: string;
      instagram: string;
      youtube: string;
      linkedIn: string;
      github: string;
      employmentType: string;
      taxFile: string;
      position: string;
      australianCitizen: boolean;
      dateJoined: string; // You may want to use Date type if it's a date string
      salary: number;
      payDay: number;
      payRate: string;
      bankName: string;
      bsb: string;
      branch: string;
      accountName: string;
      accountNumber: string;
      nextOfKin: string;
      relationship: string;
      kinPostcode: string;
      kinAddress: string;
      kinCountry: string;
      kinCity: string;
      kinEmail: string;
      suburb: string;
      kinState: string;
      kinPhoneNumber: string;
      isDocumentUploaded: boolean;
      isAdmin: boolean;
      adm_DesignationsId: number;
      adm_Designations: string; // You may want to define a type for this
      offerLetter: string;
      handbook: string;
      superForm: string;
      supportPosition: string;
      fullName: string;
      dateCreated: string; // You may want to use Date type if it's a date string
      userCreated: string;
      dateModified: string; // You may want to use Date type if it's a date string
      userModified: string;
    };
  };
}

export interface ShiftAttendanceState {
  loading: boolean;
  data: NotSpitted | null; // Adjusted to single object, not an array
  attendanceSplits: Array<ShiftAttendance>;
  error: string | undefined;
}

const initialState: ShiftAttendanceState = {
  loading: false,
  data: null, // Initialize to null
  attendanceSplits: [],
  error: undefined,
};

export const fetchShiftAttendance = createAsyncThunk(
  "ShiftAttendance/fetchShiftAttendance",
  async (companyId: number, { rejectWithValue }) => {
    try {
      const response = await api.fetchShiftAttendance(companyId);
      return {
        shiftAttendance: response.shiftAttendance,
        attendanceSplits: response.shiftAttendance?.attendanceSplits || [],
      };
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue("An unknown error occurred");
    }
  }
);

export const filterShiftAttendance = createAsyncThunk(
  "ShiftAttendance/filterShiftAttendance",
  async (
    {
      companyId,
      dateFrom,
      dateTo,
      staff,
      client,
      type,
    }: {
      companyId: number;
      dateFrom: string;
      dateTo: string;
      staff: string;
      client: string;
      type: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.filterShiftAttendance(
        companyId,
        dateFrom,
        dateTo,
        staff,
        client,
        type
      );
      return {
        shiftAttendance: response.shiftAttendance,
        attendanceSplits: response.shiftAttendance?.attendanceSplits || [],
      };
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue("An unknown error occurred");
    }
  }
);

const shiftAttendanceSlice = createSlice({
  name: "shiftAttendance",
  initialState,
  reducers: {}, // Add an empty object for reducers
  extraReducers: (builder) => {
    builder
      .addCase(fetchShiftAttendance.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchShiftAttendance.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.shiftAttendance; // Set shiftAttendance data
        // You may want to set attendanceSplits as well
        state.attendanceSplits = action.payload.attendanceSplits;
      })
      .addCase(fetchShiftAttendance.rejected, (state, action) => {
        state.loading = false;
        state.data = null; // Reset data to null
        state.error = action.error.message;
      });
    builder
      .addCase(filterShiftAttendance.pending, (state) => {
        state.loading = true;
      })
      .addCase(filterShiftAttendance.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.shiftAttendance; // Set shiftAttendance data
        // You may want to set attendanceSplits as well
        state.attendanceSplits = action.payload.attendanceSplits;
      })
      .addCase(filterShiftAttendance.rejected, (state, action) => {
        state.loading = false;
        state.data = null; // Reset data to null
        state.error = action.error.message;
      });
  },
});

export const userSelector = (state: RootState) => state.shiftAttendance;
export default shiftAttendanceSlice.reducer;

export const shiftAttendanceData = (state: RootState) =>
  state.shiftAttendance.data;
export const shiftAttendanceLoading = (state: RootState) =>
  state.shiftAttendance.loading;
export const shiftAttendanceError = (state: RootState) =>
  state.shiftAttendance.error;
export const shiftAttendanceSplits = (state: RootState) =>
  state.shiftAttendance.attendanceSplits;
