// import React from 'react'

// const CourtOrder = () => {
//     return (
//         <div>
//             <table className="w-full mb-6">
//                 <tbody>
//                     <tr>
//                         <td className="border px-4 py-2 font-bold">Are there any court orders in place e.g. family
//                             court orders? what do we need to know to keep you safe?</td>
//                         <td className="border px-4 py-2">
//                             <div className="flex items-center">
//                                 <label className="mr-4">
//                                     <input type="radio" name="staffingRequest" className="mr-2" value="true"

//                                     /> Yes
//                                 </label>
//                                 <label>
//                                     <input type="radio" name="staffingRequest" className="mr-2" value="false"

//                                     /> No
//                                 </label>
//                             </div>

//                             {/* <textarea className="w-full p-2 border border-gray-300 rounded mt-2"
//                   placeholder="If yes, please list"
//                   name="staffingRequest"
//                   value={values.staffingRequest}
//                   onChange={handleInputDataChange}>
//                 </textarea> */}

//                         </td>
//                     </tr>
//                 </tbody>
//             </table>
//         </div>
//     )
// }

// export default CourtOrder

import React from 'react';
import { FormDataType } from '..';


interface Props {
    formData: FormDataType;
    handleInputFormChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    handleRadioFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}


const CourtOrder: React.FC<Props> = ({ formData, handleInputFormChange, handleRadioFormChange }) => {


    return (
        <div>
            <table className="w-full mb-6">
                <tbody>
                    <tr>
                        <td className="border px-4 py-2 font-bold">
                            Are there any court orders in place e.g. family court orders? What do we need to know to keep you safe?
                        </td>
                        <td className="border px-4 py-2">
                            <div className="flex items-center">
                                <label className="mr-4">
                                    <input
                                        type="radio"
                                        name="hasCourtOrder"
                                        className="mr-2"
                                        value="true"
                                        checked={formData.hasCourtOrder === true}
                                        onChange={handleRadioFormChange}
                                    />{' '}
                                    Yes
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="hasCourtOrder"
                                        className="mr-2"
                                        value="false"
                                        checked={formData.hasCourtOrder === false}
                                        onChange={handleRadioFormChange}
                                    />{' '}
                                    No
                                </label>
                            </div>

                            {formData.hasCourtOrder && (
                                <textarea
                                    className="w-full p-2 border border-gray-300 rounded mt-2"
                                    placeholder="If yes, provide details"
                                    name="details"
                                    value={formData.details}
                                    onChange={handleInputFormChange}
                                ></textarea>
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default CourtOrder;
