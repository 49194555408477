import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

export interface KnowledgeBaseType {
    subject: string;
    vote: number;
    userCreated: string;
    userModified: string;
    dateCreated: string;
    knowledgeBaseId: number;
    description: string;
    videoUrl: string;
    dateModified: string;
}

export interface KnowledgeBaseState {
    loading: boolean;
    data: Array<KnowledgeBaseType>;
    error: string | undefined;
}
const initialState: KnowledgeBaseState = {
    loading: false,
    data: [],
    error: undefined,
};
export const fetchKnowledgebase = createAsyncThunk(
    "KnowledgeBase/fetchKnowledgebase",
    async () => {
        try {
            const response = await api.fetchKnowledgebase();
            return response;
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                console.log(error);
            } else {
                console.log(error);
            }
        }
    }
);

const knowledgeBaseSlice = createSlice({
    name: "knowledgeBase",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchKnowledgebase.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            fetchKnowledgebase.fulfilled,
            (state, action: PayloadAction<Array<KnowledgeBaseType>>) => {
                state.loading = false;
                state.data = action.payload;
            }
        );
        builder.addCase(fetchKnowledgebase.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
    reducers: {},
});
export const userSelector = (state: RootState) => state.knowledgeBase;
export default knowledgeBaseSlice.reducer;

export const knowledgeBaseData = (state: RootState) => state.knowledgeBase.data;
export const knowledgeBaseLoading = (state: RootState) => state.knowledgeBase.loading;
export const knowledgeBaseError = (state: RootState) => state.knowledgeBase.error;
