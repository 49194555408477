import React, { FormEvent, useEffect, useState } from 'react';
import axiosInstance from '../../../../store/axiosInstance';
import { toast } from 'react-toastify';
import { isAxiosError } from 'axios';
import Spinner from '../../../../Components/Spinner';
import DisabilitySupportCom from './Components/DisabilitySupport';
import EditDisabilitySupportCom from './Components/EditDisabilitySupport';

interface Props {
    uid: string; // Define the type of uid as string
}

export interface DisabilityDataType {
    communicationAssistance: boolean;
    communicationAttachment: string;
    communicationAttachmentFile: string;
    communicationDescription: string;
    communicationMeans: string;
    dateCreated: string;
    dateModified: string;
    disabilityId: number;
    hearingDescription: string;
    hearingIssues: string;
    memoryDescription: string;
    memoryIssues: string;
    mobilityAssistance: boolean
    mobilityDescription: string;
    mobilityIndependent: string;
    profileId: number;
    userCreated: string;
    userModified: string;
    visionDescription: string;
    visionIssues: string;
}


const DisabilitySupport: React.FC<Props> = ({ uid }) => {
    const [mobility, setMobility] = useState({
        needMobilityAssistance: '',
        mobilityDescription: ''
    });

    const [hearing, setHearing] = useState({
        hearingIssues: '',
        hearingDescription: ''
    });

    const [vision, setVision] = useState({
        visionIssues: '',
        visionDescription: ''
    });

    const [memory, setMemory] = useState({
        memoryIssues: '',
        memoryDescription: ''
    });

    const [communication, setCommunication] = useState({
        needCommunicationAssistance: '',
        communicationPreferences: '',
        communicationDescription: ''
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingClient, setLoadingClient] = useState<boolean>(true);
    const [checker, setChecker] = useState<DisabilityDataType | null>(null)
    const [editDisabilitySupport, setEditDisabilitySupport] = useState<DisabilityDataType>({
        communicationAssistance: false,
        communicationAttachment: "",
        communicationAttachmentFile: "",
        communicationDescription: "",
        communicationMeans: "",
        dateCreated: "",
        dateModified: "",
        disabilityId: 0,
        hearingDescription: "",
        hearingIssues: "",
        memoryDescription: "",
        memoryIssues: "",
        mobilityAssistance: false,
        mobilityDescription: "",
        mobilityIndependent: "",
        profileId: 0,
        userCreated: "",
        userModified: "",
        visionDescription: "",
        visionIssues: "",
    })

    const FetchData = async () => {
        // setSaveId(id);
        // setReceiptModal(true);
        setLoadingClient(true)
        try {
            const { data } = await axiosInstance.get(
                `/Disabilities/get_all?clientId=${uid}`
            );

            if (data && data.length > 0) {
                setChecker(data)
                const disabilityId = data[0].disabilityId;
                const { data: secondData } = await axiosInstance.get(`/Disabilities/${disabilityId}`);
                //  console.log(secondData);
                setEditDisabilitySupport(secondData)
            }
            setLoadingClient(false)
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // console.log(error);;
            } else {
                toast.error("An error occurred");
            }
            setLoadingClient(false)
        }
    };

    useEffect(() => {
        FetchData()
    }, [uid]);

    const handleInputDataChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        const { name, value } = e.target;
        setEditDisabilitySupport((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const info = {
            profileId: uid,
            mobilityAssistance: mobility.needMobilityAssistance === "Need Mobility Assistance",
            // mobilityIndependent: mobility.needMobilityAssistance === "Mobility Independency",
            mobilityDescription: mobility.mobilityDescription,
            hearingIssues: hearing.hearingIssues,
            hearingDescription: hearing.hearingDescription,
            visionIssues: vision.visionIssues,
            visionDescription: vision.visionDescription,
            memoryIssues: memory.memoryIssues,
            memoryDescription: memory.memoryDescription,
            communicationAssistance: communication.needCommunicationAssistance === "Need Communication Assistance",
            communicationMeans: communication.communicationPreferences,
            communicationDescription: communication.communicationDescription,
            // communicationAttachmentFile: "string"
        };
        try {
            setLoading(true);
            const { data } = await axiosInstance.post(`/Disabilities`, info);

            if (data.status === "Success") {
                toast.success(data.message);
            } else {
                toast.error(data.message);
            }
            setLoading(false);
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);
        }
    };

    const handleSubmitEdit = async (e: FormEvent) => {
        e.preventDefault();
        const info = {
            disabilityId: editDisabilitySupport.disabilityId,
            profileId: uid,
            mobilityAssistance: editDisabilitySupport.mobilityAssistance,
            mobilityDescription: editDisabilitySupport.mobilityDescription,
            hearingIssues: editDisabilitySupport.hearingIssues,
            hearingDescription: editDisabilitySupport.hearingDescription,
            visionIssues: editDisabilitySupport.visionIssues,
            visionDescription: editDisabilitySupport.visionDescription,
            memoryIssues: editDisabilitySupport.memoryIssues,
            memoryDescription: editDisabilitySupport.memoryDescription,
            communicationAssistance: true,
            communicationMeans: editDisabilitySupport.communicationMeans,
            communicationDescription: editDisabilitySupport.communicationDescription
        };
        try {
            setLoading(true);
            const { data } = await axiosInstance.post(`Disabilities/edit/${editDisabilitySupport.disabilityId}`, info);

            if (data.status === "Success") {
                toast.success(data.message);
            } else {
                toast.error(data.message);
            }
            setLoading(false);
        } catch (error: unknown) {
            console.log(error);
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);
        }
    };

    return (
        <div>

            {loadingClient && (
                <div className="fixed  inset-0 z-[9999]  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                    <div className="relative w-auto max-w-lg mx-auto">
                        <div
                            className="relative z-[999] flex justify-center items-center p-4  duration-500 ease-in-out bg-white
border-0 rounded-lg shadow-lg outline-none focus:outline-none"
                        >
                            <Spinner color="#071952" />
                        </div>
                    </div>

                    <div className="fixed inset-0 z-40 bg-gray-300 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
                </div>
            )}
            {checker ? (
                <EditDisabilitySupportCom loading={loading} editDisabilitySupport={editDisabilitySupport} handleSubmitEdit={handleSubmitEdit} handleInputDataChange={handleInputDataChange} />
            ) : (
                <form className='mt-10' onSubmit={handleSubmit}>
                    <DisabilitySupportCom communication={communication} hearing={hearing} memory={memory} mobility={mobility}
                        setCommunication={setCommunication} setHearing={setHearing} setMemory={setMemory} setMobility={setMobility}
                        setVision={setVision} uid={uid} vision={vision}
                    />
                    <div className="mx-auto text-center mt-3">
                        <button
                            type="submit"
                            disabled={loading}
                            className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                               font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                               dark:focus:ring-primary"
                        >
                            {loading ? <Spinner /> : "Add"}
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default DisabilitySupport;


