import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

interface AllAdminAttendance {
  clockIn: string;
  clockInCheck: boolean;
  clockOut: string;
  clockOutCheck: boolean;
  companyId: number;
  dateCreated: string;
  dateModified: string;
  duration: number;
  endKm: number;
  imageFIle: string;
  imageURL: string;
  inLatitude: number;
  inLongitude: number;
  report: string;
  adminAttendanceId: number;
  staffId: number;
  startKm: number;
  userCreated: string;
  userModified: string;
  administrator: {
    fullName: string;
  };
}

export interface AllAdminAttendanceState {
  loading: boolean;
  data: Array<AllAdminAttendance>;
  error: string | undefined;
}
const initialState: AllAdminAttendanceState = {
  loading: false,
  data: [],
  error: undefined,
};
export const fetchAllAdminAttendance = createAsyncThunk(
  "AllAdminAttendance/fetchAllAdminAttendance",
  async (companyId: number) => {
    try {
      const response = await api.fetchAllAdminAttendances(companyId);
      return response;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

const allAdminAttendanceSlice = createSlice({
  name: "allAdminAttendance",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchAllAdminAttendance.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchAllAdminAttendance.fulfilled,
      (state, action: PayloadAction<Array<AllAdminAttendance>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(fetchAllAdminAttendance.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {},
});
export const userSelector = (state: RootState) => state.allAdminAttendance;
export default allAdminAttendanceSlice.reducer;

export const allAdminAttendanceData = (state: RootState) =>
  state.allAdminAttendance.data;
export const allAdminAttendanceLoading = (state: RootState) =>
  state.allAdminAttendance.loading;
export const allAdminAttendanceError = (state: RootState) =>
  state.allAdminAttendance.error;
