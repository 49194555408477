import React from "react";
import { Navigate, Route, Routes } from "react-router";
import AllUserPolic from "./AllUserPolicy";
import CompanyPolicy from "./Policy";
import PolicyTable from "./PolicyTable";
import StaffTrainingCom from "./StaffTrainings";
import UserPolicyComponent from "./UserPolicy";
import UserTrainingComponent from "./UserTraining";

const HrLayout = () => {
    return (
        <div>
            <Routes>
                <Route
                    path="/"
                    element={<Navigate to="/app/hr/policy" replace />}
                />
                <Route path="/policy" element={<CompanyPolicy />} />
                <Route path="/policy/doc/:uid" element={<PolicyTable />} />
                <Route path="/all-policies" element={<AllUserPolic />} />
                <Route path="/user-policies" element={<UserPolicyComponent />} />
                <Route path="/staff-training" element={<StaffTrainingCom />} />
                <Route path="/user-training" element={<UserTrainingComponent />} />

            </Routes>
        </div>
    );
};

export default HrLayout;
