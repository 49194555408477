import React from 'react'
import { FormDataType } from '..';


interface Props {
    formData: FormDataType;
    handleInputFormChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
}

const Communication: React.FC<Props> = (
    { formData, handleInputFormChange }
) => {


    return (
        <div>
            <table className="w-full mb-6">
                <tbody>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Other communication: e.g. Auslan, Compic, AAC device, body language:</td>
                        <td className="border px-4 py-2">

                            <textarea className="w-full p-2 border border-gray-300 rounded"
                                name="communication"
                                value={formData.communication}
                                onChange={handleInputFormChange}
                            />
                        </td>
                    </tr>

                </tbody>
            </table>
        </div >
    )
}

export default Communication