import React from "react";
import { CompanyDetail } from "../../../types/AuthData";
interface Props {
  companyOne: CompanyDetail | null | undefined
}
const Hero = ({ companyOne }: Props) => {
  return (
    <div className=" lg:space-x-4 lg:items-start text-right flex justify-end ">
      <img
        className="w-24 "
        src={companyOne?.companyLogo}
        alt="Company Logo"
      />
    </div>
  );
};

export default Hero;
