import React from "react";
import SEO from "../../../constants/SEO";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import Draggable from "react-draggable";

const DragTest = () => {
  return (
    <>
      <SEO title="Create Roles" description="Roles" name="Promax-Care" />
      <Breadcrumb />

      <Draggable>
        <div>I can now be moved around!</div>
      </Draggable>
    </>
  );
};

export default DragTest;
