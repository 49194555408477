import React, { FormEvent, useRef } from "react";
import Spinner from "../../Components/Spinner";
import { Client } from "../../store/slices/clientSlice";
import { CompanyDetail } from "../../types/AuthData";
import { ParticipantForm } from "../../types/DataType";
import PrintComponent from "../PrintTab";
import EditAlertTable from "./__component/EditAlertTable";
import EditDate from "./__component/EditDate";
import EditPreference from "./__component/EditPreference";
import EditSummarySupport from "./__component/EditSummarySupport";
import Hero from "./__component/Hero";

interface Props {
  clientDetail: Client | null;
  companyOne: CompanyDetail | null | undefined;
  participantForm: ParticipantForm | null;
  handleAddRow: () => void;
  handleEditSubmit: (e: FormEvent) => Promise<void>;
  loading: boolean;
  activities: string[];
  dislike: string[];
  important: string[];
  handleInputImportantChange: (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleInputDislikeChange: (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleInputEditChange: (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleInputDataChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
}

const EditParticipantProfile: React.FC<Props> = ({
  companyOne,
  handleInputImportantChange,
  important,
  handleInputDislikeChange,
  dislike,
  clientDetail,
  handleInputEditChange,
  activities,
  loading,
  handleEditSubmit,
  handleAddRow,
  handleInputDataChange,
  participantForm,
}) => {
  //   const user = useSelector((state: RootState) => state?.auth?.user);
  const componentRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div>
        <form onSubmit={handleEditSubmit}>
          <PrintComponent
            componentRef={componentRef}
            triggerLabel="Print / Download"
            documentTitle="Participant Profile"
          />
          <div
            ref={componentRef}
            className="mx-auto  max-w-3xl p-4 md:p-4 lg:p-8 bg-white"
          >
            <Hero companyOne={companyOne} clientDetail={clientDetail} />
            <EditSummarySupport
              handleInputDataChange={handleInputDataChange}
              participantForm={participantForm}
            />
            <EditAlertTable
              handleAddRow={handleAddRow}
              handleInputImportantChange={handleInputImportantChange}
              important={important}
            />
            <EditPreference
              activities={activities}
              dislike={dislike}
              handleInputDislikeChange={handleInputDislikeChange}
              handleInputEditChange={handleInputEditChange}
            />
            <EditDate
              handleInputDataChange={handleInputDataChange}
              participantForm={participantForm}
            />
          </div>
          <div className="mx-auto text-center mt-3 lg:col-span-2">
            <button
              type="submit"
              disabled={loading}
              className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"
            >
              {loading ? <Spinner /> : " Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditParticipantProfile;
