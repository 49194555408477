import dayjs from "dayjs";
import React from "react";
import { Shift } from "../../../../store/slices/shiftRosterSlice";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../../Components/Spinner";

interface CardProps {
  // task: string;
  // onEdit: () => void; // Function to call when Edit is clicked
  getActivityStatus(activity: Shift): string;
  activity: Shift;
  onShiftClick: () => void;
  GetDetail: (shiftId: number) => Promise<void>;
  handleClockIn: (shift: Shift) => Promise<void>;
  loading: boolean;
  ClockOut: (shiftId: number) => Promise<void>;
}

const Card = ({
  //   onEdit,
  activity,
  onShiftClick,
  getActivityStatus,
  GetDetail,
  handleClockIn,
  loading,
  ClockOut,
}: CardProps) => {
  const navigate = useNavigate();

  const FillReport = (shiftId: number) => {
    navigate(`/app/staff/report-form/${shiftId}`);
  };

  return (
    <div
      className={`border-2 cursor-default border-gray-200  ${
        activity.status === "Pending"
          ? "bg-secondary "
          : activity.status === "Cancelled"
          ? "bg-[#f62d51] text-white"
          : "bg-white text-black"
      } rounded-lg px-1 py-2 w-full flex flex-col relative `}
    >
      <div onClick={onShiftClick} className="mb-2">
        <small
          className={`absolute top-2 w-2 h-2 right-2 items-center text-sm hover:cursor-pointer border  p-1  rounded-full             
                ${
                  getActivityStatus(activity) === "Present"
                    ? "bg-green-500"
                    : getActivityStatus(activity) === "Absent"
                    ? "bg-red-500"
                    : "bg-secondary"
                }
                
                `}
        ></small>
        <span className="text-xs font-bold">
          {dayjs(activity.dateFrom).format("h:mm A")} -{" "}
          {dayjs(activity.dateTo).format("h:mm A")}
        </span>
        <div className="truncate ... ">
          <small className="font-bold">Staff:</small>
          &nbsp;
          <small>{activity.staff?.fullName}</small>
        </div>
        <div className="truncate ... ">
          <small className="font-bold">Client(s):</small>
          &nbsp;
          <small>{activity.clients}</small>
        </div>
      </div>

      {activity.status !== "Cancelled" &&
      getActivityStatus(activity) === "Clock-In" ? (
        <div className="flex gap-2 flex-wrap justify-center">
          <button
            type="submit"
            onClick={() => handleClockIn(activity)}
            className={`text-white hover:bg-primary cursor-pointer
                        font-medium rounded-full text-xs px-5 py-1.5 ${
                          loading ? "bg-secondary" : "bg-primary"
                        }`}
          >
            {loading ? <Spinner /> : "Clock in"}
          </button>
          <button
            type="button"
            onClick={() => GetDetail(activity?.shiftRosterId)}
            className="text-primary hover:bg-gray-200 cursor-pointer
                             font-medium rounded-full text-xs px-5 py-1.5 bg-gray-300"
          >
            cancel shift
          </button>
        </div>
      ) : (
        <div className="d-flex gap-2 flex-wrap">
          <button
            className={`font-medium rounded-full text-xs px-5 py-1.5 ${
              getActivityStatus(activity) === "Cancelled"
                ? "bg-red-500 text-white hidden"
                : getActivityStatus(activity) === "Absent"
                ? "hidden"
                : getActivityStatus(activity) === "In Shift"
                ? "bg-gray-400 text-white"
                : getActivityStatus(activity) === "Present"
                ? "hidden"
                : getActivityStatus(activity) === "Upcoming"
                ? "hidden"
                : ""
            }`}
          >
            {getActivityStatus(activity)}
          </button>
          {activity.status === "Cancelled" ? (
            <small className="bg-secondary p-1 block rounded my-1">
              Shift Cancelled
            </small>
          ) : null}

          {activity.status !== "Cancelled" &&
            getActivityStatus(activity) === "Upcoming" && (
              <button
                type="button"
                onClick={() => GetDetail(activity?.shiftRosterId)}
                className="text-primary hover:bg-gray-200 cursor-pointer
                             font-medium rounded-full text-xs px-5 py-1.5 bg-gray-300 mb-2"
              >
                cancel shift
              </button>
            )}

          {activity.status !== "Cancelled" &&
            getActivityStatus(activity) === "Present" &&
            !activity.isShiftReportSigned && (
              <button
                type="button"
                onClick={() => FillReport(activity.shiftRosterId)}
                className="text-black bg-secondary hover:bg-secondary/70  
                                 font-medium rounded-full text-xs px-5 py-1.5 "
              >
                Fill Report
              </button>
            )}

          {getActivityStatus(activity) === "In Shift" && (
            <>
              <button
                type="button"
                onClick={() => FillReport(activity.shiftRosterId)}
                className="text-white bg-secondary hover:bg-secondary  
                                 font-medium rounded-full text-xs px-5 py-1.5 "
              >
                Fill Report
              </button>
              {activity?.isShiftReportSigned && (
                <button
                  type="button"
                  onClick={() => ClockOut(activity.shiftRosterId)}
                  className="text-white bg-primary hover:bg-primary  
                                font-medium rounded-full text-xs px-5 py-1.5 "
                >
                  Clock Out
                </button>
              )}
            </>
          )}
        </div>
      )}

      {getActivityStatus(activity) === "Upcoming" && (
        <div className="flex justify-end absolute bottom-0 left-0">
          <span className="text-[7px] font-normal bg-secondary rounded-es-md rounded-se-md p-0.5">
            Upcoming
          </span>
        </div>
      )}
    </div>
  );
};

export default Card;
