import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import DataTable, {
  TableColumn,
  ExpanderComponentProps,
} from "react-data-table-component";
import { BsSearch } from "react-icons/bs";
import ExportDropdown from "../../../Components/menu/ExportDropdown";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import Spinner from "../../../Components/Spinner";
import { FaFileCsv, FaFileExcel, FaFilePdf } from "react-icons/fa";
import IconButton from "../../../Components/Buttons/IconButton";
import { Tooltip, Whisper } from "rsuite";
import axiosInstance from "../../../store/axiosInstance";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import CustomInput from "../../../Components/Input/CustomInput";
import {
  downloadCSV,
  exportToExcel,
  handlePDFDownload,
} from "../../../services/reusableFunc";
import { Column } from "../../../types/DataType";
import { clientData, fetchClient } from "../../../store/slices/clientSlice";
import moment from "moment";

interface DataRow {
  description: string;
  itemNumber: number;
  date: string;
  day: string;
  duration: number;
  agreedDuration: number;
  unitPrice: string;
  amount: number;
  totalKm: number;
}
interface DataRow2 {
  description: string;
  itemNumber: number;
  quantity: number;
  duration: number;
  agreedDuration: number;
  unitPrice: string;
  amount: number;
  totalKm: number;
}

interface Total {
  xeroUploadLink: string;
  xeroAgreedUploadLink: string;
  xeroGroupUploadLink: string;
  xeroAgreedGroupUploadLink: string;
  totalKm: number;
}
interface Profile {
  fullName: string;
}

function formatDuration(duration: number | string): string {
  const durationInTicks = BigInt(duration);
  const durationInMilliseconds = Number(durationInTicks) / 10000; // Convert ticks to milliseconds

  const durationInSeconds = Math.floor(durationInMilliseconds / 1000); // Convert to seconds
  const totalMinutes = Math.floor(durationInSeconds / 60);
  const totalHours = Math.floor(totalMinutes / 60);
  const remainingMinutes = totalMinutes % 60;

  let result = "";

  if (totalHours > 0) {
    result += `${totalHours} Hrs`;
  }

  if (remainingMinutes > 0) {
    result += ` ${remainingMinutes} Min`;
  }

  return result.trim(); // Remove trailing space
}

interface InvoiceItem {
  date: string;
}

const Invoice = () => {
  const [users, setUsers] = useState<string>("");
  const [ttp, setTtp] = useState<string>("Yes");
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");

  const columns: TableColumn<DataRow>[] = [
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      cell: (row) => (
        <Whisper
          placement="top"
          controlId="control-id-hover"
          trigger="hover"
          speaker={
            <Tooltip>{`${row.description} (${row.itemNumber})`}</Tooltip>
          }
        >
          <span
            className="truncate"
            style={{ overflow: "hidden", cursor: "pointer" }}
            data-toggle="tooltip"
            data-placement="top"
            title={""}
          >
            {row.description} ({row.itemNumber})
          </span>
        </Whisper>
      ),
    },
    {
      name: "Day/Date",
      selector: (row) =>
        row.date ? dayjs(row.date).format("ddd, MMM D, YYYY") : row.day,
      sortable: true,
    },
    {
      name: "Actual Hours",
      selector: (row) => formatDuration(row.duration),
      sortable: true,
    },
    {
      name: "Agreed Hours",
      selector: (row) => row.agreedDuration,
      sortable: true,
    },
    {
      name: "Unit Price",
      selector: (row) => row.unitPrice,
      sortable: true,
    },
    {
      name: "Amount ($)",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Total Km",
      selector: (row) => row.totalKm,
      sortable: true,
    },
  ];

  const columnsTwo: TableColumn<DataRow2>[] = [
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      cell: (row) => (
        <Whisper
          placement="top"
          controlId="control-id-hover"
          trigger="hover"
          speaker={
            <Tooltip>{`${row.description} (${row.itemNumber})`}</Tooltip>
          }
        >
          <span
            className="truncate"
            style={{ overflow: "hidden", cursor: "pointer" }}
            data-toggle="tooltip"
            data-placement="top"
            title={""}
          >
            {row.description} ({row.itemNumber})
          </span>
        </Whisper>
      ),
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: "Actual Hours",
      selector: (row) => formatDuration(row.duration),
      sortable: true,
    },
    {
      name: "Agreed Hours",
      selector: (row) => row.agreedDuration,
      sortable: true,
    },
    {
      name: "Unit Price",
      selector: (row) => row.unitPrice,
      sortable: true,
    },
    {
      name: "Amount ($)",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Total Km",
      selector: (row) => row.totalKm,
      sortable: true,
    },
  ];

  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();
  const companyId = user?.companyId;
  useEffect(() => {
    if (companyId) {
      dispatch(fetchClient(companyId));
    }
  }, [dispatch, companyId]);
  const client = useSelector(clientData);

  const [loading1, setLoading1] = useState<boolean>(false);
  const [total, setTotal] = useState<Total | null>(null);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [totalAgreed, setTotalAgreed] = useState<number>(0);
  const [totalDuration, setTotalDuration] = useState<number>(0);
  const [showInvoice, setShowInvoice] = useState<boolean>(true);
  const [name, setName] = useState<Profile | null>(null);
  const [invoice, setInvoice] = useState<DataRow[]>([]);
  const [grouped, setGrouped] = useState<DataRow2[]>([]);

  const FetchInvoice = async (e: FormEvent) => {
    e.preventDefault();
    setLoading1(true);

    try {
      const { data } = await axiosInstance.get(
        `/Invoice/load_invoice?userId=${user?.uid}&fromDate=${dateFrom}&toDate=${dateTo}&clientId=${users}&type=No&withTTP=${ttp}`
      );

      if (data.status === "Success") {
        toast.success(data.message);
        setTotal(data?.invoiceItems);
        setTotalAgreed(data?.invoiceItems?.totalAgreedDuration);
        setTotalAmount(data?.invoiceItems?.totalAmount);
        setTotalDuration(data?.invoiceItems?.totalDuration);

        const sortedInvoiceItems = data.invoiceItems.grouped_Agreed_Actual.sort(
          (a: InvoiceItem, b: InvoiceItem) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB.getTime() - dateA.getTime(); // More robust handling of date sorting
          }
        );
        setInvoice(sortedInvoiceItems);
        setGrouped(data?.invoiceItems?.groupInvoice);
        setName(data?.invoiceItems.profile);
      }
      setLoading1(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      }
      setLoading1(false);
    } finally {
      setLoading1(false);
    }
  };

  interface Props extends ExpanderComponentProps<DataRow> {
    // currently, props that extend ExpanderComponentProps must be set to optional.
    someTitleProp?: string;
  }

  const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
    return (
      <div className="p-2 flex flex-col gap-2 text-xs">
        <span>
          <span className="font-bold">Description: </span>
          <span>
            {" "}
            {data.description} ({data.itemNumber})
          </span>
        </span>
      </div>
    );
  };

  interface Props2 extends ExpanderComponentProps<DataRow2> {
    // currently, props that extend ExpanderComponentProps must be set to optional.
    someTitleProp?: string;
  }

  const ExpandableRowComponentTwo: React.FC<Props2> = ({ data }) => {
    return (
      <div className="p-2 flex flex-col gap-2 text-xs">
        <span>
          <span className="font-bold">Description: </span>
          <span>
            {" "}
            {data.description} ({data.itemNumber})
          </span>
        </span>
      </div>
    );
  };

  const [searchText, setSearchText] = useState<string>("");

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredData = invoice?.filter((item) =>
    item?.description?.toLowerCase().includes(searchText.toLowerCase())
  );

  const filteredData2 = grouped?.filter((item) =>
    item?.description?.toLowerCase().includes(searchText.toLowerCase())
  );

  const [loading2, setLoading2] = useState<boolean>(false);
  const generateUngroupedPdf = () => {
    setLoading2(true);
    localStorage.setItem("invoiceDateFrom", dateFrom);
    localStorage.setItem("invoiceDateTo", dateTo);
    localStorage.setItem("invoicePro", users);
    localStorage.setItem("ttp", ttp);
    setTimeout(() => {
      const url = `/pdf/loadUngroupedInvoice-pdf/gen_rate`;
      window.open(url, "_blank");
      setLoading2(false);
    }, 2000);
  };

  const generateGroupedPdf = () => {
    setLoading2(true);
    localStorage.setItem("invoiceDateFrom", dateFrom);
    localStorage.setItem("invoiceDateTo", dateTo);
    localStorage.setItem("invoicePro", users);
    localStorage.setItem("ttp", ttp);
    setTimeout(() => {
      const url = `/pdf/loadGroupedInvoice-pdf/gen_rate`;
      window.open(url, "_blank");
      setLoading2(false);
    }, 2000);
  };

  return (
    <>
      <SEO title="Invoice" description="Invoice" name="Promax-Care" />
      <Breadcrumb />

      <div className="mt-10">
        <div>
          <form
            action=""
            onSubmit={FetchInvoice}
            className="space-y-1 p-4  items-center shadow-md rounded-md lg:gap-8 bg-white"
          >
            <div className="p-4 grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8">
              <div>
                <label className=" mb-2 text-xs flex gap-1 text-gray-600 font-semibold dark:text-white">
                  Select Client
                </label>
                <div className="relative block overflow-hidden  border border-gray-300 h-11  otline-none rounded">
                  <select
                    className="border-none text-gray-900 text-sm outline-none   block bg-white w-full h-full px-2.5"
                    onChange={(e) => setUsers(e.target.value)}
                  >
                    <option value={""}>All Client</option>
                    {client.map((data, index) => (
                      <option value={data.profileId} key={index}>
                        {data.fullName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <CustomInput
                label="Start Date"
                type="datetime-local"
                name="dateFrom"
                onChange={(e) => setDateFrom(e.target.value)}
                required
                // ref={dateFrom}
              />
              <CustomInput
                label="End Date"
                type="datetime-local"
                name="dateTo"
                onChange={(e) => setDateTo(e.target.value)}
                required
              />
              <div>
                <label className="mb-2 text-xs flex gap-1 text-gray-600 font-semibold dark:text-white">
                  Load with TTP
                </label>
                <div className="relative block overflow-hidden  border border-gray-300 h-11  otline-none rounded">
                  <select
                    className="border-none text-gray-900 text-sm outline-none   block bg-white w-full h-full px-2.5"
                    onChange={(e) => setTtp(e.target.value)}
                  >
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap">
              <button
                type="submit"
                disabled={loading1}
                className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none dark:focus:ring-primary"
              >
                {loading1 ? (
                  <div className="flex gap-2">
                    <Spinner /> Please wait...This might take a while..
                  </div>
                ) : (
                  "Load Invoice"
                )}
              </button>

              <div>
                {invoice.length <= 0 ? (
                  ""
                ) : (
                  <button
                    type="button"
                    onClick={() => setShowInvoice(!showInvoice)}
                    className="text-white bg-gray-500 hover:bg-gray-500 focus:ring-4 focus:ring-gray-500 
font-bold rounded-md text-md px-6 py-2.5 me-2 mt-2 dark:bg-gray-500 dark:hover:bg-gray-500 focus:outline-none
dark:focus:ring-primary"
                  >
                    {showInvoice
                      ? "Group Invoice by item Number"
                      : "Ungroup Invoice by item number"}
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>

        <div className="mt-4 font-bold">
          {invoice.length <= 0 ? (
            ""
          ) : (
            <div className="text-center">
              {" "}
              <h5>
                Invoice for {!name?.fullName ? "All" : name?.fullName} from
                <span> {moment(dateFrom).format("LLL")} </span> to{" "}
                <span> {moment(dateTo).format("LLL")} </span>
              </h5>
            </div>
          )}
        </div>

        {invoice.length > 0 && showInvoice && (
          <div className="mt-4">
            <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
              <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
                <h2 className="font-bold text-xl text-black w-auto">Invoice</h2>

                <div className="relative w-full lg:col-span-2">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <BsSearch />
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    onChange={handleSearch}
                    className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Search"
                  />
                </div>
              </div>
              <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                <div className="flex items-center space-x-3 w-full md:w-auto">
                  <ExportDropdown>
                    <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                      <IconButton
                        icon={<FaFilePdf className="text-red-500" />}
                        title="Export as PDF"
                        onClick={() =>
                          handlePDFDownload(
                            invoice,
                            columns as unknown as Column<DataRow>[],
                            "Invoice.pdf"
                          )
                        }
                      />
                      <IconButton
                        icon={<FaFileExcel className="text-green-600" />}
                        title="Export as Excel Sheet"
                        onClick={() =>
                          exportToExcel(
                            invoice,
                            columns as unknown as Column<DataRow>[],
                            "Invoice"
                          )
                        }
                      />
                      <IconButton
                        icon={<FaFileCsv className="text-green-500" />}
                        title="Export as CSV"
                        onClick={() =>
                          downloadCSV({
                            data: invoice,
                            filename: "Invoice.csv",
                          })
                        }
                      />
                    </div>
                  </ExportDropdown>
                </div>
              </div>
            </div>
            <div className="flex gap-2 justify-end flex-wrap">
              <div>
                <a
                  href={total?.xeroUploadLink}
                  type="submit"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-bold rounded-md text-md px-6 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                >
                  Post Actual Hours to Xero
                </a>
              </div>
              <div>
                <a
                  href={total?.xeroAgreedUploadLink}
                  type="submit"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-bold rounded-md text-md px-6 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                >
                  Post Agreed Hours to Xero
                </a>
              </div>
              <div>
                <button
                  onClick={generateUngroupedPdf}
                  disabled={loading2}
                  className="text-white bg-gray-500 hover:bg-gray-500 focus:ring-4 focus:ring-gray-500 
font-bold rounded-md text-md px-6 py-2.5 me-2 mt-2 dark:bg-gray-500 dark:hover:bg-gray-500 focus:outline-none
dark:focus:ring-primary"
                >
                  {loading2 ? <Spinner /> : "Generate PDF"}
                </button>
              </div>
            </div>
            <div className="flex px-2 py-3 justify-evenly items-center">
              <div>
                <span>Total Duration: </span>
                <span className="font-bold">
                  {formatDuration(totalDuration)}
                </span>
              </div>
              <div>
                <span>Total Amount: </span>
                <span className="font-bold ">{totalAmount}</span>
              </div>
              <div>
                <span>Total Agreed Duration: </span>
                <span className="font-bold">{totalAgreed}</span>
              </div>
              <div>
                <span>Total Km: </span>
                <span className="font-bold ">{total?.totalKm}</span>
              </div>
            </div>
            <DataTable
              columns={columns}
              data={filteredData}
              expandableRows
              expandableRowsComponent={ExpandableRowComponent}
              pagination
              paginationTotalRows={filteredData?.length}
              // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}
            />
          </div>
        )}

        {!showInvoice && (
          <div className="mt-4">
            <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
              <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
                <h2 className="font-bold text-xl text-black w-auto">Invoice</h2>

                <div className="relative w-full lg:col-span-2">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <BsSearch />
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    onChange={handleSearch}
                    className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Search"
                  />
                </div>
              </div>
              <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                <div className="flex items-center space-x-3 w-full md:w-auto">
                  <ExportDropdown>
                    <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                      <IconButton
                        icon={<FaFilePdf className="text-red-500" />}
                        title="Export as PDF"
                        onClick={() =>
                          handlePDFDownload(
                            invoice,
                            columns as unknown as Column<DataRow>[],
                            "Invoice.pdf"
                          )
                        }
                      />
                      <IconButton
                        icon={<FaFileExcel className="text-green-600" />}
                        title="Export as Excel Sheet"
                        onClick={() =>
                          exportToExcel(
                            invoice,
                            columns as unknown as Column<DataRow>[],
                            "Invoice"
                          )
                        }
                      />
                      <IconButton
                        icon={<FaFileCsv className="text-green-500" />}
                        title="Export as CSV"
                        onClick={() =>
                          downloadCSV({
                            data: invoice,
                            filename: "Invoice.csv",
                          })
                        }
                      />
                    </div>
                  </ExportDropdown>
                </div>
              </div>
            </div>
            <div className="flex gap-2 justify-end flex-wrap">
              <div>
                <a
                  href={total?.xeroGroupUploadLink}
                  type="submit"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-bold rounded-md text-md px-6 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                >
                  Post Grouped Actual Hours to Xero
                </a>
              </div>
              <div>
                <a
                  href={total?.xeroAgreedGroupUploadLink}
                  type="submit"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-bold rounded-md text-md px-6 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                >
                  Post Grouped Agreed Hours to Xero
                </a>
              </div>
              <div>
                <button
                  onClick={generateGroupedPdf}
                  disabled={loading2 ? true : false}
                  className="text-white bg-gray-500 hover:bg-gray-500 focus:ring-4 focus:ring-gray-500 
font-bold rounded-md text-md px-6 py-2.5 me-2 mt-2 dark:bg-gray-500 dark:hover:bg-gray-500 focus:outline-none
dark:focus:ring-primary"
                >
                  {loading2 ? <Spinner /> : "Generate PDF"}
                </button>
              </div>
            </div>
            <div className="flex px-2 py-3 justify-evenly items-center">
              <div>
                <span>Total Duration: </span>
                <span className="font-bold ">
                  {formatDuration(totalDuration)}
                </span>
              </div>
              <div>
                <span>Total Amount: </span>
                <span className="font-bold ">{totalAmount}</span>
              </div>
              <div>
                <span>Total Agreed Duration: </span>
                <span className="font-bold ">{totalAgreed}</span>
              </div>
            </div>
            <DataTable
              columns={columnsTwo}
              data={filteredData2}
              expandableRows
              expandableRowsComponent={ExpandableRowComponentTwo}
              pagination
              paginationTotalRows={filteredData2?.length}
              // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Invoice;
