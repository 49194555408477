import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import DataTable, {
  TableColumn,
  ExpanderComponentProps,
} from "react-data-table-component";
import {
  FaFileCsv,
  FaFileExcel,
  FaFilePdf,
  // FaFilter,
  FaRegEdit,
  FaRegTrashAlt,
} from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import ExportDropdown from "../../../Components/menu/ExportDropdown";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import {
  fetchIntegration,
  integrationData,
  integrationLoading,
} from "../../../store/slices/integrationSlice";
import { Button, InputPicker, Modal, Tooltip, Whisper } from "rsuite";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import axiosInstance from "../../../store/axiosInstance";
import Spinner from "../../../Components/Spinner";
import SetupModals from "../../../Components/Modals/SetupModals";
import CustomInput from "../../../Components/Input/CustomInput";
import IconButton from "../../../Components/Buttons/IconButton";
import {
  downloadCSV,
  exportToExcel,
  handlePDFDownload,
} from "../../../services/reusableFunc";
import { Column } from "../../../types/DataType";

interface DataRow {
  clientID: string;
  company: string;
  companyId: number;
  dateCreated: string;
  dateModified: string;
  integrationId: string;
  responseUrl: string;
  scope: string;
  secretID: string;
  thirdParty: string;
  userCreated: string;
  userModified: string;
}

type DataOption = {
  label: string;
  value: string;
};

const ThirdParty = () => {
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [addInterModal, setAddInterModal] = useState<boolean>(false);
  const [editInterModal, setEditInterModal] = useState<boolean>(false);
  const [saveId, setSaveId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [secretIDModal, setSecretIDModal] = useState<boolean>(false);
  const [clientIDModal, setClientIDModal] = useState<boolean>(false);
  const [scopeModal, setScopeModal] = useState<boolean>(false);
  const [responseModal, setResponseModal] = useState<boolean>(false);
  const [party, setParty] = useState<string>("");
  const secretID = useRef<HTMLInputElement>(null);
  const clientID = useRef<HTMLInputElement>(null);
  const [scope, setScope] = useState<string>(
    "accounting.transactions accounting.settings accounting.contacts payroll.employees payroll.timesheets payroll.settings"
  );
  const [responseUrl, setResponseUrl] = useState<string>(
    "https://app.promaxcare.com.au/app/report/auth"
  );
  const [editThirdParty, setEditThirdParty] = useState<string>("");
  const [editSecretID, setEditSecretID] = useState<string>("");
  const [editClientID, setEditClientID] = useState<string>("");
  const [editScope, setEditScope] = useState<string>("");
  const [editResponseUrl, setEditResponseUrl] = useState<string>("");
  const [editId, setEditId] = useState<number>(0);

  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();
  const companyId = user?.companyId;
  useEffect(() => {
    if (companyId) {
      dispatch(fetchIntegration(companyId));
    }
  }, [dispatch, companyId]);
  const integration = useSelector(integrationData);
  const loadingData = useSelector(integrationLoading);

  const columns: TableColumn<DataRow>[] = [
    {
      name: "Third Party",
      selector: (row) => row.thirdParty,
    },
    {
      name: "Secret ID",
      selector: (row) => row.secretID,
    },
    {
      name: "Client ID",
      selector: (row) => row.clientID,
    },

    {
      name: "Actions",
      cell: (row: DataRow) => (
        <div className="flex gap-4  justify-center ">
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Edit</Tooltip>}
          >
            <button
              onClick={() => EditInte(row.integrationId)}
              className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
            >
              <FaRegEdit className="text-base" />
            </button>
          </Whisper>
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Delete</Tooltip>}
          >
            <button
              onClick={() => delInte(row.integrationId)}
              className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
            >
              <FaRegTrashAlt className="text-base text-red-500" />
            </button>
          </Whisper>
        </div>
      ),
    },
  ];

  const delInte = (id: string) => {
    setSaveId(id);
    setDeleteModal(true);
  };
  const EditInte = async (id: string) => {
    setEditInterModal(true);
    try {
      const { data } = await axiosInstance.get(
        `/Integrations/integration_details/${id}`
      );
      setEditThirdParty(data.thirdParty);
      setEditSecretID(data.secretID || "");
      setEditClientID(data.clientID || "");
      setEditScope(data.scope || "");
      setEditResponseUrl(data.responseUrl || "");
      setEditId(data.integrationId);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const HandleEdit = async (e: FormEvent) => {
    e.preventDefault();

    setLoading(true);

    const info = {
      integrationId: Number(editId),
      thirdParty: editThirdParty,
      secretID: editSecretID,
      clientID: editClientID,
      scope: editScope,
      responseUrl: editResponseUrl,
      companyId: companyId,
    };
    try {
      const { data } = await axiosInstance.post(
        `/Integrations/edit/${editId}`,
        info
      );
      toast.success(data.message);
      companyId && dispatch(fetchIntegration(companyId));
      setEditInterModal(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    }
  };

  const data: DataOption[] = ["Xero"].map((item) => ({
    label: item,
    value: item,
  }));

  const handleDeleteIntegration = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axiosInstance.post(
        `/Integrations/delete/${saveId}`
      );
      toast.success(data.message);
      setDeleteModal(false);
      companyId && dispatch(fetchIntegration(companyId));
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
      setDeleteModal(false);
    } finally {
      setLoading(false);
    }
  };

  const HandleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (
      party.trim() === "" ||
      secretID.current?.value === "" ||
      clientID.current?.value === ""
    ) {
      return toast.error("Form is not complete");
    }
    setLoading(true);

    const info = {
      thirdParty: party,
      secretID: secretID?.current?.value,
      clientID: clientID?.current?.value,
      scope: scope,
      responseUrl: responseUrl,
      companyId: companyId,
    };
    try {
      const { data } = await axiosInstance.post(
        `/Integrations/add_integration`,
        info
      );
      toast.success(data.message);
      setAddInterModal(false);
      companyId && dispatch(fetchIntegration(companyId));
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  interface Props extends ExpanderComponentProps<DataRow> {
    // currently, props that extend ExpanderComponentProps must be set to optional.
    someTitleProp?: string;
  }

  const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
    return (
      <div className="p-2 flex flex-col gap-2 text-xs">
        <span>
          <span className="font-bold">Sercet ID: </span>
          <span> {data.secretID}</span>
        </span>
        <span>
          <span className="font-bold">Client ID: </span>
          <span> {data.clientID}</span>
        </span>
        <span>
          <span className="font-bold">Scope: </span>
          <span> {data.scope}</span>
        </span>
        <span>
          <span className="font-bold">Response URL: </span>
          <span> {data.responseUrl}</span>
        </span>

        <div>
          <a
            href={`
                        https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${data.clientID}&redirect_uri=${data.responseUrl}&scope=${data.scope}&state=test
                        
                        `}
            className="text-green-500 font-bold text-xs"
          >
            Test Connection
          </a>
        </div>
      </div>
    );
  };

  const [searchText, setSearchText] = useState<string>("");

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredData = integration?.filter((item) =>
    item?.thirdParty?.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      <SEO
        title="Third Party Integration - Promax Care"
        description="Third Party"
        name="Promax-Care"
      />
      <Breadcrumb />
      <div className="mt-10">
        {integration.length <= 0 && loadingData && <Spinner color="#071952" />}
        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
          <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
            <h2 className="font-bold text-xl text-black w-auto">
              Third Party Integration
            </h2>

            <div className="relative w-full lg:col-span-2">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <BsSearch />
              </div>
              <input
                type="text"
                id="simple-search"
                onChange={handleSearch}
                className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Search By ThirdParty"
              />
            </div>
          </div>
          <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
            <div className="flex items-center space-x-3 w-full md:w-auto">
              {/* <button id="actionsDropdownButton" className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                                <FaFilter />
                                Filter
                            </button> */}
              <ExportDropdown>
                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <IconButton
                    icon={<FaFilePdf className="text-red-500" />}
                    title="Export as PDF"
                    onClick={() =>
                      handlePDFDownload(
                        integration,
                        columns as unknown as Column<DataRow>[],
                        "ThirdParty.pdf"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileExcel className="text-green-600" />}
                    title="Export as Excel Sheet"
                    onClick={() =>
                      exportToExcel(
                        integration,
                        columns as unknown as Column<DataRow>[],
                        "ThirdParty"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileCsv className="text-green-500" />}
                    title="Export as CSV"
                    onClick={() =>
                      downloadCSV({
                        data: integration,
                        filename: "ThirdParty.csv",
                      })
                    }
                  />
                </div>
              </ExportDropdown>
            </div>
            <button
              type="button"
              onClick={() => setAddInterModal(true)}
              className="px-4 py-2 bg-primary text-white  disabled:bg-slate-400
                            transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm 
                           "
            >
              Add New Integration
            </button>
          </div>
        </div>

        <DataTable
          columns={columns}
          data={filteredData}
          expandableRows
          expandableRowsComponent={ExpandableRowComponent}
        />
      </div>

      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold text-sm">
            Delete Third Party Integration
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="mb-4 font-bold text-lg">
              Are you sure you want to delete this Third Party Integration ?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <Button
                onClick={handleDeleteIntegration}
                className="bg-red-500 text-white hover:bg-red-700 hover:text-white"
              >
                {loading ? <Spinner /> : "Yes, I'm sure"}
              </Button>
              <Button
                onClick={() => setDeleteModal(false)}
                className="bg-gray-500 text-white hover:text-white hover:bg-gray-400"
              >
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={editInterModal}
        onClose={() => setEditInterModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Edit Integration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <form action="" className="space-y-1 p-2">
              <div>
                <label className=" text-xs text-gray-600 font-semibold">
                  Third Party
                </label>
                <div className="lg:col-span-2">
                  <InputPicker
                    size="lg"
                    placeholder="E.g Xero"
                    data={data}
                    className="w-full focus:outline-none focus:border-primary"
                    appearance="subtle"
                    value={editThirdParty}
                    // onChange={e => setEditThirdParty(e.target.value)}
                    onChange={(value) => setEditThirdParty(value)}
                  />

                  <div className="flex justify-end mt-1"></div>
                </div>
              </div>
              <div>
                <CustomInput
                  label="Secret ID"
                  type="text"
                  value={editSecretID}
                  onChange={(e) => setEditSecretID(e.target.value)}
                  placeholder="Secret ID"
                />
                <div className="flex justify-end mt-1">
                  <small
                    className="text-right text-brand underline cursor-pointer"
                    onClick={() => setSecretIDModal(true)}
                  >
                    What is Secret ID?
                  </small>
                </div>
              </div>
              <div>
                <CustomInput
                  label="Client ID"
                  type="text"
                  value={editClientID}
                  onChange={(e) => setEditClientID(e.target.value)}
                  placeholder="Client ID"
                />
                <div className="flex justify-end mt-1">
                  <small
                    className="text-right text-brand underline cursor-pointer"
                    onClick={() => setClientIDModal(true)}
                  >
                    What is Client ID?
                  </small>
                </div>
              </div>
              <div>
                <CustomInput
                  label="Scope"
                  type="text"
                  value={editScope}
                  onChange={(e) => setEditScope(e.target.value)}
                  placeholder="Scope"
                  readOnly
                />
                <div className="flex justify-end mt-1">
                  <small
                    className="text-right text-brand underline cursor-pointer"
                    onClick={() => setScopeModal(true)}
                  >
                    What is Scope?
                  </small>
                </div>
              </div>
              <div>
                <CustomInput
                  label="Response Url"
                  type="text"
                  value={editResponseUrl}
                  onChange={(e) => setEditResponseUrl(e.target.value)}
                  placeholder="Response Url"
                  readOnly
                />
                <div className="flex justify-end mt-1">
                  <small
                    className="text-right text-brand underline cursor-pointer"
                    onClick={() => setResponseModal(true)}
                  >
                    What is Response URL?
                  </small>
                </div>
              </div>

              <div className="mx-auto text-center mt-3">
                <button
                  type="submit"
                  onClick={HandleEdit}
                  disabled={loading ? true : false}
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                >
                  {loading ? <Spinner /> : "Update"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={addInterModal}
        onClose={() => setAddInterModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Add Integration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <form action="" onSubmit={HandleSubmit} className="space-y-1 p-2">
              <div>
                <label className=" text-xs text-gray-600 font-semibold">
                  Third Party
                </label>
                <InputPicker
                  size="lg"
                  placeholder="E.g Xero"
                  data={data}
                  className="w-full focus:outline-none focus:border-primary text-gray-600"
                  value={party}
                  appearance="subtle"
                  onChange={(e: string) => setParty(e)}
                />
              </div>
              <div>
                <CustomInput
                  label="Secret ID"
                  type="text"
                  ref={secretID}
                  placeholder="Secret ID"
                />
                <div className="flex justify-end mt-1">
                  <small
                    className="text-right text-brand underline cursor-pointer"
                    onClick={() => setSecretIDModal(true)}
                  >
                    What is Secret ID?
                  </small>
                </div>
              </div>
              <div>
                <CustomInput
                  label="Client ID"
                  type="text"
                  ref={clientID}
                  placeholder="Client ID"
                />
                <div className="flex justify-end mt-1">
                  <small
                    className="text-right text-brand underline cursor-pointer"
                    onClick={() => setClientIDModal(true)}
                  >
                    What is Client ID?
                  </small>
                </div>
              </div>
              <div>
                <CustomInput
                  label="Scope"
                  type="text"
                  value={scope}
                  onChange={(e) => setScope(e.target.value)}
                  placeholder="Scope"
                  readOnly
                />
                <div className="flex justify-end mt-1">
                  <small
                    className="text-right text-brand underline cursor-pointer"
                    onClick={() => setScopeModal(true)}
                  >
                    What is Scope?
                  </small>
                </div>
              </div>
              <div>
                <CustomInput
                  label="Response Url"
                  type="text"
                  value={responseUrl}
                  onChange={(e) => setResponseUrl(e.target.value)}
                  placeholder="Response Url"
                  readOnly
                />
                <div className="flex justify-end mt-1">
                  <small
                    className="text-right text-brand underline cursor-pointer"
                    onClick={() => setResponseModal(true)}
                  >
                    What is Response URL?
                  </small>
                </div>
              </div>

              <div className="mx-auto text-center mt-3">
                <button
                  type="submit"
                  disabled={loading}
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                >
                  {loading ? <Spinner /> : "Add"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <SetupModals
        secretIDModal={secretIDModal}
        setSecretIDModal={setSecretIDModal}
        clientIDModal={clientIDModal}
        setClientIDModal={setClientIDModal}
        responseModal={responseModal}
        scopeModal={scopeModal}
        setResponseModal={setResponseModal}
        setScopeModal={setScopeModal}
      />
    </>
  );
};

export default ThirdParty;
