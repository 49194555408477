import React, { ChangeEvent, FormEvent } from 'react';
import CustomSelect from "../../../../../Components/Input/CustomSelect";
import ClientTabForm from "../../components/ClientTabForm";
import { EditHealthSupportDataType } from '../HealthSupport';
import Spinner from '../../../../../Components/Spinner';

interface Props {
    editHealthSupport: EditHealthSupportDataType
    handleChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>) => void;
    handleInputDataChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    handleSubmitEdit: (e: FormEvent) => Promise<void>;
    loading: boolean;
}

const EditHealthSupportCom: React.FC<Props> = ({ editHealthSupport, loading, handleSubmitEdit, handleInputDataChange }) => {


    return (
        <form className='mt-10' onSubmit={handleSubmitEdit}>
            <h2 className='font-bold text-xl text-black w-auto'>Health Support Needs</h2>
            <div className="mt-5">
                <ClientTabForm title='Health Support Needs'>
                    <div className='grid grid-cols-1 gap-16 lg:grid-cols-2'>
                        <CustomSelect
                            label="Medication Required"
                            options={[
                                { label: "Yes", value: "Yes" },
                                { label: "No", value: "No" }
                            ]}
                            required
                            name="requireMedication"
                            // value={editHealthSupport.requireMedication}
                            onChange={handleInputDataChange}
                        />
                        <CustomSelect
                            label="How Often do you require medication"
                            options={[
                                { label: "Prompt Required", value: "Prompt Required" },
                                { label: "Assistance Required", value: "Assistance Required" },
                                { label: "Administration Required", value: "Administration Required" }
                            ]}
                            required
                            name="healthPlan"
                            value={editHealthSupport.healthPlan || ""}
                            onChange={handleInputDataChange}
                        />
                        <div>
                            <label htmlFor="support" className="font-bold">Is additional support for these issues? If so, please provide detail</label>
                            <textarea
                                id="support"
                                name="support"
                                rows={3}
                                autoComplete="off"
                                className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                value={editHealthSupport.support || ""}
                                onChange={handleInputDataChange}
                            />
                        </div>
                        <div>
                            <label htmlFor="supportDetails" className="font-bold">Provide details of your medication and treatment plan</label>
                            <textarea
                                id="supportDetails"
                                name="supportDetails"
                                rows={3}
                                autoComplete="off"
                                className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                value={editHealthSupport.supportDetails || ""}
                                onChange={handleInputDataChange}
                            />
                        </div>
                        <div>
                            <label htmlFor="healthIssues" className="font-bold">Describe any ongoing health issues you have, including mental health issues</label>
                            <textarea
                                id="healthIssues"
                                name="healthIssues"
                                rows={3}
                                autoComplete="off"
                                className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                value={editHealthSupport.healthIssues || ""}
                                onChange={handleInputDataChange}
                            />
                        </div>
                    </div>
                </ClientTabForm>
            </div>
            <div className="mx-auto text-center mt-3">
                <button
                    type="submit"
                    disabled={loading}
                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                               font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                               dark:focus:ring-primary"
                >
                    {loading ? <Spinner /> : "Update"}
                </button>
            </div>
        </form>
    );
};

export default EditHealthSupportCom;
