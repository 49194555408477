import React from "react";
import ServiceTable from "./ServiceTable";
import RepContact from "./RepContact";
import { Client } from "../../../store/slices/clientSlice";
import EditFirstForm from "./EditFirstForm";
import { ReferralFormData, Representative } from "../../../types/DataType";
import EditDetails from "./EditDetails";
import EditSummary from "./EditSummary";

interface Props {
  clientDetail: Client | null;
  careManager: Representative | null;
  editAttendee: string[];
  referralForm: ReferralFormData;
  handleInputDataEditChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  handleInputEditChange: (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleAddRowEdit: () => void;
  handleRadioDataChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const EditReferralFormComp: React.FC<Props> = ({
  clientDetail,
  careManager,
  handleRadioDataChange,
  referralForm,
  handleInputDataEditChange,
  editAttendee,
  handleAddRowEdit,
  handleInputEditChange,
}) => {
  return (
    <div className="max-w-6xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Referral Form</h1>

      <EditFirstForm
        editAttendee={editAttendee}
        handleInputDataEditChange={handleInputDataEditChange}
        referralForm={referralForm}
        handleAddRowEdit={handleAddRowEdit}
        handleInputEditChange={handleInputEditChange}
      />

      <h2 className="text-xl font-bold mb-4">Participant Details</h2>

      <EditDetails
        clientDetail={clientDetail}
        handleInputDataEditChange={handleInputDataEditChange}
        referralForm={referralForm}
      />

      <h2 className="text-xl font-bold mb-4">
        Primary Family or Representative Contact
      </h2>

      <RepContact careManager={careManager} />

      <h2 className="text-xl font-bold mb-4">
        Summary of Registration Groups Requested
      </h2>

      <EditSummary
        handleRadioDataChange={handleRadioDataChange}
        handleInputDataEditChange={handleInputDataEditChange}
        referralForm={referralForm}
      />

      <h2 className="text-xl font-bold mb-4">Services</h2>

      {clientDetail?.profileId && (
        <ServiceTable uid={clientDetail?.profileId.toString()} />
      )}
    </div>
  );
};

export default EditReferralFormComp;
