import React from "react";
import { CompanyDetail } from "../../../types/AuthData";
interface Props {
    companyOne: CompanyDetail | null | undefined;
}
const Hero = ({ companyOne }: Props) => {
    return (
        <div>
            <div className="flex justify-end">
                <img
                    className="w-24  mb-4 lg:mb-4"
                    src={companyOne?.companyLogo}
                    alt="Company Logo"
                />
            </div>
        </div>
    );
};

export default Hero;
