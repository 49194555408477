import React from 'react';
import { FormDataType } from '..';

interface Props {
    formData: FormDataType
    handleInputFormChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;

}

const Cultural: React.FC<Props> = ({ formData, handleInputFormChange }) => {


    return (
        <div>
            <table className="w-full mb-6">
                <tbody>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Cultural identity:</td>
                        <td className="border px-4 py-2">
                            <div className="flex flex-wrap">
                                <label className="mr-4">
                                    <input
                                        type="radio"
                                        name="identity"
                                        className="mr-2"
                                        value="Aboriginal or Torres Strait Islander"
                                        checked={formData.identity === 'Aboriginal or Torres Strait Islander'}
                                        onChange={handleInputFormChange}
                                    />{' '}
                                    Aboriginal or Torres Strait Islander
                                </label>
                                <label className="mr-4">
                                    <input
                                        type="radio"
                                        name="identity"
                                        className="mr-2"
                                        value="Culturally and linguistically diverse background (CALD)"
                                        checked={formData.identity === 'Culturally and linguistically diverse background (CALD)'}
                                        onChange={handleInputFormChange}
                                    />{' '}
                                    Culturally and linguistically diverse background (CALD)
                                </label>
                                <label className="mr-4">
                                    <input
                                        type="radio"
                                        name="identity"
                                        className="mr-2"
                                        value=""
                                        checked={formData.identity !== 'Aboriginal or Torres Strait Islander' && formData.identity !== 'Culturally and linguistically diverse background (CALD)' && formData.identity !== ''}
                                        onChange={handleInputFormChange}
                                    />{' '}
                                    Other, please describe:
                                </label>
                            </div>

                            {formData.identity !== 'Aboriginal or Torres Strait Islander' && formData.identity !== 'Culturally and linguistically diverse background (CALD)' && (
                                <textarea
                                    className="w-full p-2 border border-gray-300 rounded mt-2"
                                    placeholder="Please describe"
                                    name="identity"
                                    value={formData.identity}
                                    onChange={handleInputFormChange}
                                />
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Religious or spiritual needs:</td>
                        <td className="border px-4 py-2">
                            <input
                                type="text"
                                name="religiousNeeds"
                                className="w-full p-2 border border-gray-300 rounded"
                                value={formData.religiousNeeds}
                                onChange={handleInputFormChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Cultural needs or preferences:</td>
                        <td className="border px-4 py-2">
                            <input
                                type="text"
                                name="culturalNeeds"
                                className="w-full p-2 border border-gray-300 rounded"
                                value={formData.culturalNeeds}
                                onChange={handleInputFormChange}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Cultural;

