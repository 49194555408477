import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import DataTable, {
  ExpanderComponentProps,
  TableColumn,
} from "react-data-table-component";
import { BsSearch } from "react-icons/bs";
import ExportDropdown from "../../../../Components/menu/ExportDropdown";
import IconButton from "../../../../Components/Buttons/IconButton";
import {
  downloadCSV,
  exportToExcel,
  handlePDFDownload,
} from "../../../../services/reusableFunc";
import { Column } from "../../../../types/DataType";
import { FaFileCsv, FaFileExcel, FaFilePdf } from "react-icons/fa";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../../store/store";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Button, Modal } from "rsuite";
import CustomInput from "../../../../Components/Input/CustomInput";
import Spinner from "../../../../Components/Spinner";
import { toast } from "react-toastify";
import axiosInstance from "../../../../store/axiosInstance";
import { isAxiosError } from "axios";
import {
  clientAidAndEquipData,
  clientAidAndEquipLoading,
  fetchClientAidAndEquip,
} from "../../../../store/slices/clientAidAndEquipSlice";
import { MultiSelect } from "react-multi-select-component";

interface DataRow {
  aidsId: number;
  dateCreated: string;
  dateModified: string;
  equipmentAssistance: boolean;
  equipmentUsed: string;
  profile: {
    profileId: number;
    firstName: string;
    surName: string;
    middleName: string;
    clientId: number;
    email: string;
    phoneNumber: string;
    gender: string;
    fullName: string;
  };
  profileId: number;
  requireStaffTraining: boolean;
  userCreated: string;
  userModified: string;
}

interface ClientAidAndEquipProps {
  uid: string; // Define the type of uid as string
}

type DataOption = {
  label: string;
  value: string;
};

const ClientAidAndEquip: React.FC<ClientAidAndEquipProps> = ({ uid }) => {
  const columns: TableColumn<DataRow>[] = [
    {
      name: "Equipment Used",
      selector: (row) => row.equipmentUsed,
      sortable: true,
    },
    {
      name: "Equipment Require Assistance",
      selector: (row) => row.equipmentAssistance,
      cell: (row) => (
        <input type="checkbox" checked={row.equipmentAssistance} readOnly />
      ),
    },
    {
      name: "Equipment Require Staff Training",
      selector: (row) => row.requireStaffTraining,
      cell: (row) => (
        <input type="checkbox" checked={row.requireStaffTraining} readOnly />
      ),
    },
    {
      name: "Date Created",
      selector: (row) => dayjs(row.dateCreated).format("DD/MM/YYYY HH:mm:ss"),
      sortable: true,
    },
  ];

  const [addModal, setAddModal] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();
  const companyId = user?.companyId;
  useEffect(() => {
    if (uid) {
      dispatch(fetchClientAidAndEquip(Number(uid)));
    }
  }, [dispatch, companyId, uid]);
  const clientAid = useSelector(clientAidAndEquipData);
  const loadingData = useSelector(clientAidAndEquipLoading);

  const [searchText, setSearchText] = useState<string>("");

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredData = clientAid.filter((item) =>
    item.equipmentUsed.toLowerCase().includes(searchText.toLowerCase())
  );

  interface Props extends ExpanderComponentProps<DataRow> {
    // currently, props that extend ExpanderComponentProps must be set to optional.
    someTitleProp?: string;
  }

  const [infoS, setInfo] = useState<{
    equipmentAssistance: boolean;
    requireStaffTraining: boolean;
  }>({
    equipmentAssistance: false,
    requireStaffTraining: false,
  });

  const [selected, setSelected] = useState<DataOption[]>([]);

  const options: DataOption[] = [
    {
      label: "Equipment Require Assistance",
      value: "Equipment Require Assistance",
    },
    {
      label: "Equipment Require Staff Training",
      value: "Equipment Require Staff Training",
    },
  ];

  const handleSelected = (selectedOptions: DataOption[]) => {
    const updatedInfo = {
      equipmentAssistance: selectedOptions.some(
        (option) => option.value === "Equipment Require Assistance"
      ),
      requireStaffTraining: selectedOptions.some(
        (option) => option.value === "Equipment Require Staff Training"
      ),
    };

    setInfo(updatedInfo);
    setSelected(selectedOptions);
  };

  const equipment = useRef<HTMLInputElement>(null);
  const [saveId, setSaveId] = useState<number>(0);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [aidDetails, setAidDetails] = useState<DataRow>({
    aidsId: 0,
    dateCreated: "",
    dateModified: "",
    equipmentAssistance: false,
    equipmentUsed: "",
    profile: {
      profileId: 0,
      firstName: "",
      surName: "",
      middleName: "",
      clientId: 0,
      email: "",
      phoneNumber: "",
      gender: "",
      fullName: "",
    },
    profileId: 0,
    requireStaffTraining: false,
    userCreated: "",
    userModified: "",
  });

  const handleSubmit = async () => {
    const info = {
      profileId: uid,
      equipmentUsed: equipment.current?.value,
      equipmentAssistance: infoS.equipmentAssistance,
      requireStaffTraining: infoS.requireStaffTraining,
    };
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(`/Aids`, info);

      if (data.status === "Success") {
        toast.success(data.message);
        setAddModal(false);
        uid && dispatch(fetchClientAidAndEquip(Number(uid)));
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setAidDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [selectedActivities, setSelectedActivities] = useState<DataOption[]>(
    []
  );
  const EditDetail = async (id: number) => {
    setSaveId(id);
    setShowModal(true);
    try {
      const { data } = await axiosInstance.get(`/Aids/${id}`);
      const { equipmentAssistance, requireStaffTraining } = data;
      setAidDetails({ ...data });
      const selectedValues = [];
      if (equipmentAssistance) {
        selectedValues.push({
          label: "Equipment Require Assistance",
          value: "Equipment Require Assistance",
        });
      }
      if (requireStaffTraining) {
        selectedValues.push({
          label: "Equipment Require Staff Training",
          value: "Equipment Require Staff Training",
        });
      }

      setSelectedActivities(selectedValues);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
    }
  };

  const handleActivityChange = (selected: DataOption[]) => {
    setSelectedActivities(selected);
  };

  const handleEdit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const info = {
      ...aidDetails,
      profileId: uid,
      equipmentAssistance:
        selectedActivities.find(
          (option) => option.value === "Equipment Require Assistance"
        ) !== undefined,
      requireStaffTraining:
        selectedActivities.find(
          (option) => option.value === "Equipment Require Staff Training"
        ) !== undefined,
    };
    try {
      const { data } = await axiosInstance.post(`/Aids/edit/${saveId}`, info);
      toast.success(data.message);
      uid && dispatch(fetchClientAidAndEquip(Number(uid)));
      setShowModal(false);
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    }
  };

  const deleteAid = (id: number) => {
    setSaveId(id);
    setDeleteModal(true);
  };

  const handleDeleteAid = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axiosInstance.post(`/Aids/delete/${saveId}`);
      toast.success(data.message);
      setDeleteModal(false);
      uid && dispatch(fetchClientAidAndEquip(Number(uid)));
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
      setDeleteModal(false);
    } finally {
      setLoading(false);
    }
  };

  const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
    return (
      <div className="p-2 flex flex-col gap-2 text-xs">
        <span>
          <span className="font-bold">Equipment Used: </span>
          <span> {data.equipmentUsed}</span>
        </span>
        <span>
          <span className="font-bold">Date Created: </span>
          <span>{dayjs(data.dateCreated).format("DD/MM/YYYY HH:mm:ss")}</span>
        </span>
        <span>
          <span className="font-bold">Date Modified: </span>
          <span>{dayjs(data.dateModified).format("DD/MM/YYYY HH:mm:ss")}</span>
        </span>
        <div className="flex gap-2">
          <span className="font-bold">Actions: </span>
          <button
            className="btn text-primary font-bold"
            style={{ fontSize: "12px" }}
            onClick={() => EditDetail(data.aidsId)}
          >
            Edit
          </button>
          |
          <button
            className="btn text-red-500 font-bold"
            style={{ fontSize: "12px" }}
            onClick={() => deleteAid(data.aidsId)}
          >
            Delete
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="mt-10">
      {clientAid.length <= 0 && loadingData && <Spinner color="#071952" />}
      <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
        <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
          <h2 className="font-bold text-xl text-black w-auto">
            Aids & Equipments
          </h2>

          <div className="relative w-full lg:col-span-2">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <BsSearch />
            </div>
            <input
              type="text"
              id="simple-search"
              onChange={handleSearch}
              className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Search"
            />
          </div>
        </div>
        <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
          <div className="flex items-center space-x-3 w-full md:w-auto">
            {/* <button id="actionsDropdownButton" className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                            <FaFilter />
                            Filter
                        </button> */}
            <ExportDropdown>
              <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                <IconButton
                  icon={<FaFilePdf className="text-red-500" />}
                  title="Export as PDF"
                  onClick={() =>
                    handlePDFDownload(
                      clientAid,
                      columns as unknown as Column<DataRow>[],
                      "ClientAidAndEquip.pdf"
                    )
                  }
                />
                <IconButton
                  icon={<FaFileExcel className="text-green-600" />}
                  title="Export as Excel Sheet"
                  onClick={() =>
                    exportToExcel(
                      clientAid,
                      columns as unknown as Column<DataRow>[],
                      "ClientAidAndEquip"
                    )
                  }
                />
                <IconButton
                  icon={<FaFileCsv className="text-green-500" />}
                  title="Export as CSV"
                  onClick={() =>
                    downloadCSV({
                      data: clientAid,
                      filename: "ClientAidAndEquip.csv",
                    })
                  }
                />
              </div>
            </ExportDropdown>
          </div>
          <button
            type="button"
            onClick={() => setAddModal(true)}
            className="px-4 py-2 bg-primary text-white  disabled:bg-slate-400
    transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm 
   "
          >
            Add New Aids & Equipments
          </button>
        </div>
      </div>

      <DataTable
        columns={columns}
        data={filteredData}
        expandableRows
        expandableRowsComponent={ExpandableRowComponent}
        pagination
        paginationTotalRows={clientAid?.length}
        // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}
      />

      <Modal
        open={addModal}
        onClose={() => setAddModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Add Aids & Equipments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form action="" onSubmit={handleSubmit}>
              <div className="space-y-2">
                <CustomInput
                  label="Equipment Used"
                  type="text"
                  ref={equipment}
                  required
                />

                <div>
                  <label
                    htmlFor="support"
                    className="block mb-2 text-xs  text-gray-600 font-semibold dark:text-white"
                  >
                    {" "}
                  </label>
                  <MultiSelect
                    className="rounded-none h-11 multi-select"
                    options={options}
                    value={selected}
                    onChange={handleSelected}
                    labelledBy={"Select multiple"}
                    isCreatable={true}
                  />
                </div>
              </div>
              <div className="mx-auto text-center mt-3">
                <button
                  type="submit"
                  disabled={loading}
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                    font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                    dark:focus:ring-primary"
                >
                  {loading ? <Spinner /> : "Add"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">
            Edit Aids & Equipments
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form action="" onSubmit={handleEdit}>
              <div className="space-y-2">
                <CustomInput
                  label="Equipment Used"
                  type="text"
                  name="equipmentUsed"
                  value={aidDetails.equipmentUsed || ""}
                  onChange={handleInputChange}
                  required
                />

                <div>
                  <label
                    htmlFor="support"
                    className="block mb-2 text-xs  text-gray-600 font-semibold dark:text-white"
                  >
                    {" "}
                  </label>
                  <MultiSelect
                    className="rounded-none h-11 multi-select"
                    options={options}
                    value={selectedActivities}
                    onChange={handleActivityChange}
                    labelledBy={"Select multiple"}
                    isCreatable={true}
                  />
                </div>
              </div>

              <div className="mx-auto text-center mt-3">
                <button
                  type="submit"
                  disabled={loading}
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                    font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                    dark:focus:ring-primary"
                >
                  {loading ? <Spinner /> : "Update"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">
            Delete Aids & Equipments
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="mb-4 font-bold">
              Are you sure you want to delete this?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <Button
                onClick={() => setDeleteModal(false)}
                className="bg-gray-500 text-white"
              >
                No, cancel
              </Button>
              <Button
                onClick={handleDeleteAid}
                className="bg-red-500 text-white hover:bg-red-700 hover:text-white"
              >
                {loading ? <Spinner /> : "Yes, I'm sure"}
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default ClientAidAndEquip;
