import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

export interface PolicyDetails {
    category: string;
    categoryCode: string;
    company: string;
    companyId: number;
    dateCreated: string;
    dateModified: string;
    isClient: boolean;
    isGeneral: boolean;
    isStaff: boolean;
    policyDocumentId: number;
    policyName: string;
    policyUrl: string;
    policyUrlFile: string;
    userCreated: string;
    userModified: string;
}

export interface PolicyState {
    loading: boolean;
    data: Array<PolicyDetails>;
    error: string | undefined;
}
const initialState: PolicyState = {
    loading: false,
    data: [],
    error: undefined,
};
export const fetchCompanyPolicyDetails = createAsyncThunk(
    "PolicyDetails/fetchCompanyPolicyDetails",
    async ({
        companyId,
        uid
    }: {
        companyId: number,
        uid: string
    }) => {
        try {
            const response = await api.fetchCompanyPolicyDetails(uid, companyId);
            return response;
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                console.log(error);
            } else {
                console.log(error);
            }
        }
    }
);

const policyDetailsSlice = createSlice({
    name: "policyDetails",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchCompanyPolicyDetails.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            fetchCompanyPolicyDetails.fulfilled,
            (state, action: PayloadAction<Array<PolicyDetails>>) => {
                state.loading = false;
                state.data = action.payload;
            }
        );
        builder.addCase(fetchCompanyPolicyDetails.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
    reducers: {},
});
export const userSelector = (state: RootState) => state.policyDetails;
export default policyDetailsSlice.reducer;

export const policyDetailsData = (state: RootState) => state.policyDetails.data;
export const policyDetailsLoading = (state: RootState) => state.policyDetails.loading;
export const policyDetailsError = (state: RootState) => state.policyDetails.error;