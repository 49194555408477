export const DocumentList = [
  {
    docName: "PCD-100 P01 Service agreement",
    docUrl: "service-agreement",
    isAvailable: true,
  },
  {
    docName: "PCD-101 P02 Page Participant profile",
    docUrl: "page-participant-profile",
    isAvailable: true,
  },
  {
    docName: "PCD-102 P03 Participant file checklist",
    docUrl: "participant-checklist",
    isAvailable: true,
  },
  {
    docName: "PCD-103 P04 Referral form",
    docUrl: "referral-form",
    isAvailable: true,
  },
  {
    docName: "PCD-104 P05 Participant Safety Plan",
    docUrl: "safety-plan",
    isAvailable: false,
  },
  {
    docName: "PCD-105 P06 Participant consent",
    docUrl: "consent-form",
    isAvailable: true,
  },
  {
    docName: "PCD-106 P07 Support plan",
    docUrl: "support-plan",
    isAvailable: true,
  },
  {
    docName: "PCD-107 P08 Skill development Plan",
    docUrl: "skill-development",
    isAvailable: true,
  },
  {
    docName: "PCD-108 P09 Support Goal Plan",
    docUrl: "goal-plan",
    isAvailable: true,
  },
  {
    docName: "PCD-109 P10 Support plan review",
    docUrl: "support-plan-review",
    isAvailable: true,
  },
  {
    docName: "PCD-110 P11 Mealtime Management",
    docUrl: "mealtime-management",
    isAvailable: false,
  },

  // "PCD-107 P08 Participant Transition Plan",
  // "PCD-108 P09 Shift report",
  // "PCD-109 P10 Participant staff orientation checklist",
  // "PCD-110 P11 Participant expenditure form",
  // "PCD-111 P12 Medication Management Plan",
  // "PCD-112 P13 Medication Chart",
  // "PCD-113 P14 PRN Medication Chart",
  // "PCD-114 P15 Participant information form",
  // "PCD-115 P16 Skill development Plan",
  // "PCD-116 P17 Support Goal Plan",
  // "PCD-117 P18 Support plan review",
];
