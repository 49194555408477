import React from 'react'
import { CompanyDetail } from '../../../types/AuthData';
import { ParticipantConsentForm } from '../../../types/DataType';


interface Props {
  companyOne: CompanyDetail | null | undefined;
  participantConsentForm: ParticipantConsentForm;
  handleCheckboxDataChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const EditCheck: React.FC<Props> = ({ companyOne, participantConsentForm, handleCheckboxDataChange }) => {
  return (
    <div>
      <div className="my-5">
        <p className="flex items-center my-2 gap-3">
          <input
            type="checkbox"
            name="consent_to_Manage_Property"
            checked={participantConsentForm.consent_to_Manage_Property}
            onChange={handleCheckboxDataChange}
          />
          <label htmlFor="supp">
            <strong>I give consent for</strong> {companyOne?.companyName} to provide the
            following assistance to help me with my money:
          </label>
        </p>
        <div className="flex items-center my-2">
          <span>1.</span>
          <input
            className="w-[100%] outline-none p-1 border border-gray-300"
            type="text"
          />
        </div>
        <div className="flex items-center my-2">
          <span>2.</span>
          <input
            className="w-[100%] outline-none p-1 border border-gray-300"
            type="text"
          />
        </div>
        <div className="flex items-center my-2">
          <span>3.</span>
          <input
            className="w-[100%] outline-none p-1 border border-gray-300"
            type="text"
          />
        </div>
      </div>
    </div>
  )
}

export default EditCheck