import React, { FormEvent } from 'react'
import { Modal } from 'rsuite';
import CustomInput from '../../../../Components/Input/CustomInput';
import Spinner from '../../../../Components/Spinner';
import { AdminDataRow } from '../../../../types/AuthData';
interface Props {
    emergencyModal: boolean;
    setEmergencyModal: React.Dispatch<React.SetStateAction<boolean>>
    editedProfile: AdminDataRow
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
    submitData: (e: FormEvent) => Promise<void>
    loading1: boolean;
}
const AdminEmergComp = ({ emergencyModal, setEmergencyModal, editedProfile, handleInputChange, submitData, loading1 }: Props) => {
    return (
        <>
            <Modal
                open={emergencyModal}
                onClose={() => setEmergencyModal(false)}
                backdrop="static"
                autoFocus
                size={"lg"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Edit Emergency Contact</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form action="" onSubmit={submitData}>
                            <div className="p-2 grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8 ">
                                <CustomInput
                                    label="Emergency Contact FullName"
                                    type="text"
                                    name='nextOfKin'
                                    value={editedProfile.nextOfKin || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="Relationship"
                                    type="text"
                                    name='relationship'
                                    value={editedProfile.relationship || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="Email"
                                    type="email"
                                    name='kinEmail'
                                    value={editedProfile.kinEmail || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="Address"
                                    type="text"
                                    name='kinAddress'
                                    value={editedProfile.kinAddress || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="Phone Number"
                                    type="text"
                                    name='kinPhoneNumber'
                                    value={editedProfile.kinPhoneNumber || ""}
                                    required
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="Country"
                                    type="text"
                                    name='kinCountry'
                                    value={editedProfile.kinCountry || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="State"
                                    type="text"
                                    name='kinState'
                                    value={editedProfile.kinState || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="City"
                                    type="text"
                                    name='kinCity'
                                    value={editedProfile.kinCity || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="Postal Code"
                                    type="text"
                                    name='postcode'
                                    value={editedProfile.postcode || ""}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className='flex items-center justify-center'>
                                <button type="submit"
                                    disabled={loading1 ? true : false}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">{
                                        loading1 ? <Spinner /> : "Update"
                                    }</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AdminEmergComp