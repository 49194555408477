import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

export interface Staff {
  staffId: number;
  maxStaffId: string;
  firstName: string;
  surName: string;
  middleName: string | null;
  aboutMe: string | null;
  address: string;
  postcode: string | null;
  email: string;
  employeeId: string;
  phoneNumber: string;
  profession: string | null;
  gender: string;
  imageUrl: string | null;
  imageFile: string | null;
  country: string;
  state: string;
  city: string;
  auditApproved: boolean;
  isCompleted: boolean;
  isActive: boolean;
  dateOfBirth: string; // You may want to use Date type if it's a date string
  facebook: string | null;
  twitter: string | null;
  instagram: string | null;
  youtube: string | null;
  linkedIn: string | null;
  github: string | null;
  employmentType: string | null;
  taxFile: string | null;
  position: string | null;
  australianCitizen: boolean | null;
  dateJoined: string | null; // You may want to use Date type if it's a date string
  salary: number;
  payDay: number;
  payRate: string;
  bankName: string;
  bsb: string;
  branch: string;
  accountName: string;
  accountNumber: string;
  nextOfKin: string;
  relationship: string;
  kinPostcode: string;
  kinAddress: string;
  kinCountry: string;
  kinCity: string;
  kinEmail: string;
  suburb: string;
  kinState: string;
  kinPhoneNumber: string;
  isDocumentUploaded: boolean;
  isAdmin: boolean;
  adm_DesignationsId: number;
  adm_Designations: string | null; // You may want to define a type for this
  offerLetter: string | null;
  handbook: string | null;
  superForm: string | null;
  supportPosition: string | null;
  fullName: string;
  dateCreated: string; // You may want to use Date type if it's a date string
  userCreated: string;
  dateModified: string; // You may want to use Date type if it's a date string
  userModified: string;
  signatureUrl: string | null;
  signatureFile: string | null;
}

export interface StaffState {
  loading: boolean;
  data: Array<Staff>;
  error: string | undefined;
}
const initialState: StaffState = {
  loading: false,
  data: [],
  error: undefined,
};
export const fetchStaff = createAsyncThunk(
  "Staff/fetchStaff",
  async (companyId: string | number) => {
    try {
      const response = await api.fetchStaffData(companyId);
      return response
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

export const filterStaff = createAsyncThunk(
  "Staff/filterStaff",
  async ({ companyId, status }: { companyId: number; status: string }) => {
    try {
      const response = await api.filterStaffData(companyId, status);
      return response;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

const staffSlice = createSlice({
  name: "staff",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchStaff.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchStaff.fulfilled,
      (state, action: PayloadAction<Array<Staff>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(fetchStaff.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(filterStaff.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      filterStaff.fulfilled,
      (state, action: PayloadAction<Array<Staff>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(filterStaff.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {},
});
export const userSelector = (state: RootState) => state.staff;
export default staffSlice.reducer;

// Define selectors to access the profile slice state
export const staffData = (state: RootState) => state.staff.data;
export const staffLoading = (state: RootState) => state.staff.loading;
export const staffError = (state: RootState) => state.staff.error;
