import React from "react";

interface Props {
  paymentText: string[];
  handlePaymentChange: (option: string) => void
  paymentOptions: string[]
}

const PaymentTable = ({ paymentText, handlePaymentChange, paymentOptions }: Props) => {
  return (
    <fieldset className="mt-5">
      <legend className="sr-only">Payment Options</legend>
      <div className="space-y-4">
        {paymentText.map((item, i) => {
          return (
            <div key={i}>
              <input type="checkbox" id={`checkbox-${i}`}
                value={paymentText}
                onChange={() => handlePaymentChange(item)}
                checked={(paymentOptions || []).includes(item)}
                className="mr-2" />
              <label className="cursor-pointer" htmlFor={`checkbox-${i}`}>
                {item}
              </label>
            </div>
          );
        })}

      </div>
    </fieldset>
  );
};

export default PaymentTable;
