import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

export interface StaffTraining {
    companyId: number;
    dateCreated: string;
    dateModified: string;
    endDate: string;
    others: string;
    report: string;
    staff: {
        fullName: string;
    },
    staffId: number;
    staffTrainingsId: number;
    startDate: string;
    training: string;
    traningCertficateUrl: string;
    traningCertficateUrlFile: string;
    userCreated: string;
    userModified: string;
}

export interface StaffTrainingState {
    loading: boolean;
    data: Array<StaffTraining>;
    error: string | undefined;
}
const initialState: StaffTrainingState = {
    loading: false,
    data: [],
    error: undefined,
};
export const fetchStaffTraining = createAsyncThunk(
    "StaffTraining/fetchStaffTraining",
    async (companyId: number) => {
        try {
            const response = await api.fetchStaffTraining(companyId);
            return response;
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                console.log(error);
            } else {
                console.log(error);
            }
        }
    }
);

const staffTrainingSlice = createSlice({
    name: "staffTraining",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchStaffTraining.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            fetchStaffTraining.fulfilled,
            (state, action: PayloadAction<Array<StaffTraining>>) => {
                state.loading = false;
                state.data = action.payload;
            }
        );
        builder.addCase(fetchStaffTraining.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
    reducers: {},
});
export const userSelector = (state: RootState) => state.staffTraining;
export default staffTrainingSlice.reducer;

export const staffTrainingData = (state: RootState) =>
    state.staffTraining.data;
export const staffTrainingLoading = (state: RootState) =>
    state.staffTraining.loading;
export const staffTrainingError = (state: RootState) =>
    state.staffTraining.error;
