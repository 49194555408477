import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

export interface UserPolicy {
    consent: boolean;
    policyDocument: {
        category: string;
        categoryCode: string;
        companyId: number;
        policyDocumentId: number;
        policyName: string;
        policyUrl: string;
        policyUrlFile: string;
    }
    policyDocumentId: number;
    user: {
        fullName: string;
        id: string;
        role: string;
        userName: string;
    }
    userCreated: string;
    userId: string;
    userModified: string;
    userPolicyConsentId: number;
}

export interface PolicyState {
    loading: boolean;
    data: Array<UserPolicy>;
    error: string | undefined;
}
const initialState: PolicyState = {
    loading: false,
    data: [],
    error: undefined,
};
export const fetchUserPolicy = createAsyncThunk(
    "UserPolicy/fetchUserPolicy",
    async ({
        companyId,
        uid,
        consent
    }: {
        companyId: number,
        uid: string, consent: boolean
    }) => {
        try {
            const response = await api.fetchUserPolicy(companyId, uid, consent);
            return response;
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                console.log(error);
            } else {
                console.log(error);
            }
        }
    }
);

const userPolicySlice = createSlice({
    name: "userPolicy",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchUserPolicy.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            fetchUserPolicy.fulfilled,
            (state, action: PayloadAction<Array<UserPolicy>>) => {
                state.loading = false;
                state.data = action.payload;
            }
        );
        builder.addCase(fetchUserPolicy.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
    reducers: {},
});
export const userSelector = (state: RootState) => state.userPolicy;
export default userPolicySlice.reducer;

export const userPolicyData = (state: RootState) => state.userPolicy.data;
export const userPolicyLoading = (state: RootState) => state.userPolicy.loading;
export const userPolicyError = (state: RootState) => state.userPolicy.error;