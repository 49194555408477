import React from "react";
import { FaPlus } from "react-icons/fa";
import { SupportPlaData } from "../../../types/DataType";

interface Props {
  editAttendee: string[];
  supportForm: SupportPlaData;
  handleInputDataEditChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  handleInputEditChange: (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleAddRowEdit: () => void;
}

const EditFirstForm: React.FC<Props> = ({
  handleInputDataEditChange,
  supportForm,
  editAttendee,
  handleAddRowEdit,
  handleInputEditChange,
}) => {
  return (
    <div>
      <table className="w-full mb-6">
        <tbody>
          <tr>
            <td className="border p-2">Date:</td>
            <td className="border p-2">
              <input
                type="date"
                className="w-full outline-none"
                name="meetingDate"
                value={supportForm.meetingDate}
                onChange={handleInputDataEditChange}
              />
            </td>
          </tr>
          <tr>
            <td className="border p-2">Meeting Attendees:</td>
            {editAttendee.map((value, index) => (
              <td
                key={index}
                className="whitespace-nowrap border flex gap-3 px-4 py-2 text-gray-700 w-[100%] outline-none"
              >
                <span>{index + 1}.</span>
                <input
                  className="w-[100%] h-[20px] outline-none"
                  type="text"
                  value={value}
                  onChange={(event) => handleInputEditChange(index, event)}
                />
              </td>
            ))}
          </tr>

          <tr>
            <td className=""></td>
            <td className="flex justify-end">
              <button
                type="button"
                onClick={handleAddRowEdit}
                className="mt-4 w-10 h-10  bg-gray-200 text-white rounded-full flex justify-center items-center"
              >
                <FaPlus className="text-lg text-black" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default EditFirstForm;
