import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

interface ClientProgressNote {
  companyID: number;
  date: string;
  dateCreated: string;
  dateModified: string;
  endKm: number;
  followUp: string;
  imageFIle: string;
  imageURL: string;
  isCompleted: boolean;
  position: string;
  profile: {
    profileId: number;
    firstName: string;
    surName: string;
    middleName: string;
    clientId: number;
    email: string;
    phoneNumber: string;
    gender: string;
    fullName: string;
  };
  profileId: number;
  progress: string;
  progressNoteId: number;
  report: string;
  staff: string;
  staffId: number;
  startKm: number;
  userCreated: string;
  userModified: string;
}

export interface ClientProgressNoteState {
  loading: boolean;
  data: Array<ClientProgressNote>;
  error: string | undefined;
}
const initialState: ClientProgressNoteState = {
  loading: false,
  data: [],
  error: undefined,
};
export const fetchClientProgressNote = createAsyncThunk(
  "ClientProgressNote/fetchClientProgressNote",
  async (uid: number) => {
    try {
      const response = await api.fetchClientProgressNote(uid);
      return response.progressNote;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

const clientProgressNoteSlice = createSlice({
  name: "clientProgressNote",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchClientProgressNote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchClientProgressNote.fulfilled,
      (state, action: PayloadAction<Array<ClientProgressNote>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(fetchClientProgressNote.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {},
});
export const userSelector = (state: RootState) => state.clientProgressNote;
export default clientProgressNoteSlice.reducer;

// Define selectors to access the profile slice state
export const clientProgressNoteData = (state: RootState) =>
  state.clientProgressNote.data;
export const clientProgressNoteLoading = (state: RootState) =>
  state.clientProgressNote.loading;
export const clientProgressNoteError = (state: RootState) =>
  state.clientProgressNote.error;
