import React, { FormEvent, useRef } from "react";
import PrintComponent from "../PrintTab";
import EditHero from "./___component/EditHero";
import { Client } from "../../store/slices/clientSlice";
import { CompanyDetail } from "../../types/AuthData";
import { ParticipantConsentForm, Representative } from "../../types/DataType";
import EditSection from "./___component/EditSection";
import EditSection2 from "./___component/EditSection2";
import EditSection3 from "./___component/EditSection3";
import EditDate from "./___component/EditDate";
import Spinner from "../../Components/Spinner";
import { Staff } from "../../store/slices/staffSlice";
import EditSignatureForm from "./___component/EditSignatureForm";

interface Props {
  clientDetail: Client | null;
  companyOne: CompanyDetail | null | undefined;
  participantConsentForm: ParticipantConsentForm;
  handleInputDataChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  handleRadioDataChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCheckboxDataChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddRowEdit: () => void;
  handleInputImportantChange: (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  important: string[];
  handleEditSubmit: (e: FormEvent) => Promise<void>;
  loading: boolean;
  handleInputNotChangeEdit: (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleAddNotRowEdit: () => void;
  notImportant: string[];
  setSignature: React.Dispatch<React.SetStateAction<Blob | null>>;
  handleSignatureSave: (signatureBlob: Blob) => void;
  signature: Blob | null;
  clientRep: Representative | null;
  careManager: Staff | null;
  handleRepSignatureSave: (signatureBlob: Blob) => void;
  setRepSignature: React.Dispatch<React.SetStateAction<Blob | null>>;
  signatureRep: Blob | null;
  setRepName: React.Dispatch<React.SetStateAction<string>>;
  repName: string;
}
const EditConsentForm: React.FC<Props> = ({
  companyOne,
  clientDetail,
  participantConsentForm,
  important,
  handleInputImportantChange,
  handleInputDataChange,
  handleRadioDataChange,
  handleCheckboxDataChange,
  handleInputNotChangeEdit,
  handleAddNotRowEdit,
  handleAddRowEdit,
  loading,
  handleEditSubmit,
  notImportant,
  handleRepSignatureSave,
  repName,
  setRepName,
  setRepSignature,
  careManager,
  clientRep,
  handleSignatureSave,
  setSignature,
  signature,
  signatureRep,
}) => {
  const componentRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <form onSubmit={handleEditSubmit}>
        <PrintComponent
          componentRef={componentRef}
          triggerLabel="Print / Download"
          documentTitle="Consent Form"
        />
        <div
          ref={componentRef}
          className="mx-auto  max-w-3xl p-4 md:p-4 lg:p-8 bg-white"
        >
          <EditHero
            companyOne={companyOne}
            clientDetail={clientDetail}
            participantConsentForm={participantConsentForm}
            handleInputDataChange={handleInputDataChange}
            handleRadioDataChange={handleRadioDataChange}
          />
          <EditSection
            participantConsentForm={participantConsentForm}
            alertValues={important}
            handleAddNotRowEdit={handleAddNotRowEdit}
            handleInputNotChangeEdit={handleInputNotChangeEdit}
            companyOne={companyOne}
            notImportant={notImportant}
            handleCheckboxChange={handleCheckboxDataChange}
            handleInputImportantChange={handleInputImportantChange}
            handleRadioDataChange={handleRadioDataChange}
            handleAddRow={handleAddRowEdit}
            handleInputDataChange={handleInputDataChange}
          />
          <EditSection2
            companyOne={companyOne}
            participantConsentForm={participantConsentForm}
            handleRadioDataChange={handleRadioDataChange}
          />
          <EditSection3
            companyOne={companyOne}
            handleCheckboxDataChange={handleCheckboxDataChange}
            participantConsentForm={participantConsentForm}
          />
          <EditSignatureForm
            companyOne={companyOne}
            clientDetail={clientDetail}
            careManager={careManager}
            participantConsentForm={participantConsentForm}
            handleSignatureSave={handleSignatureSave}
            handleRepSignatureSave={handleRepSignatureSave}
            setSignature={setSignature}
            setRepSignature={setRepSignature}
            handleInputDataChange={handleInputDataChange}
            signatureRep={signatureRep}
            signature={signature}
            clientRep={clientRep}
            setRepName={setRepName}
            repName={repName}
          />
          <EditDate
            handleInputDataChange={handleInputDataChange}
            participantConsentForm={participantConsentForm}
          />
          <div className="my-5">
            <p className="font-semibold">Other ways of seeking your consent</p>
            <p className="my-3">
              Please note that there are other ways we might seek your consent
              e.g. verbally via email, or text. Examples include:
            </p>
            <ul>
              <li>
                • Seeking consent verbally before commencing personal care
                routines
              </li>
              <li>
                • Seeking consent over email or text to share your information
                with another provider that is not listed on the above form.
              </li>
            </ul>
            <p className="my-3">
              In the instance we obtain your consent verbally via email or text
              we will record your consent in your progress notes.
            </p>
          </div>
        </div>
        <div className="mx-auto text-center mt-3 lg:col-span-2">
          <button
            type="submit"
            disabled={loading}
            className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"
          >
            {loading ? <Spinner /> : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditConsentForm;
