import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import DataTable, { TableColumn } from "react-data-table-component";
import { BsSearch } from "react-icons/bs";
import ExportDropdown from "../../../Components/menu/ExportDropdown";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import Spinner from "../../../Components/Spinner";
import {
  FaEye,
  FaFileCsv,
  FaFileExcel,
  FaFilePdf,
  FaFilter,
} from "react-icons/fa";
import IconButton from "../../../Components/Buttons/IconButton";
import {
  fetchProgressNote,
  filterProgressNote,
  progressNoteData,
  progressNoteLoading,
} from "../../../store/slices/progressNoteSlice";
import { InputPicker, Modal, Tooltip, Whisper } from "rsuite";
import axiosInstance from "../../../store/axiosInstance";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import CustomInput from "../../../Components/Input/CustomInput";
import { fetchStaff, staffData } from "../../../store/slices/staffSlice";
import { clientData, fetchClient } from "../../../store/slices/clientSlice";
import {
  downloadCSV,
  exportToExcel,
  handlePDFDownload,
} from "../../../services/reusableFunc";
import { Column } from "../../../types/DataType";

interface DataRow {
  companyID: number;
  date: string;
  dateCreated: string;
  dateModified: string;
  endKm: number;
  followUp: string;
  imageFIle: string;
  imageURL: string;
  isCompleted: boolean;
  position: string;
  profile: {
    profileId: number;
    firstName: string;
    surName: string;
    middleName: string;
    clientId: number;
    email: string;
    phoneNumber: string;
    gender: string;
    fullName: string;
  };
  profileId: number;
  progress: string;
  progressNoteId: number;
  report: string;
  staff: string;
  staffId: number;
  startKm: number;
  userCreated: string;
  userModified: string;
}

const ProgressReport = () => {
  const columns: TableColumn<DataRow>[] = [
    // {
    //     name: '',
    //     // selector: row => row.itemNumber,
    //     cell: (row) => (
    //         <div className='flex justify-center items-center w-full'>

    //             <button
    //                 className="w-full md:w-auto flex items-center justify-center py-1 px-3 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button"

    //                 onClick={() => EditDetail(row.progressNoteId)}>
    //                 view
    //             </button>
    //         </div>
    //     )
    // },
    {
      name: "Staff",
      selector: (row) => row.staff,
      sortable: true,
    },
    {
      name: "Client",
      selector: (row) => row.profile.fullName,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      cell: (row) => (
        <span style={{ overflow: "hidden" }}>
          {" "}
          {dayjs(row.date).format("ddd, MMM DD, h:mm A")}
        </span>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <span className="flex gap-4">
          <div>
            <Whisper
              placement="auto"
              controlId="control-id-hover"
              trigger="hover"
              speaker={<Tooltip>View Progress Report</Tooltip>}
            >
              <button
                onClick={() => EditDetail(row.progressNoteId)}
                className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
              >
                <FaEye className="text-base cursor-pointer" />
              </button>
            </Whisper>
          </div>
        </span>
      ),
    },
  ];

  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();
  const companyId = user?.companyId;
  useEffect(() => {
    if (companyId) {
      dispatch(fetchProgressNote(companyId));
      dispatch(fetchStaff(companyId));
      dispatch(fetchClient(companyId));
    }
  }, []);
  const progressNote = useSelector(progressNoteData);
  const loadingData = useSelector(progressNoteLoading);
  const staff = useSelector(staffData);
  const clients = useSelector(clientData);
  const [saveId, setSaveId] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [users, setUsers] = useState<string>("");
  const [client, setClient] = useState<string>("");
  const dateFrom = useRef<HTMLInputElement>(null);
  const dateTo = useRef<HTMLInputElement>(null);
  const [filterModal, setFilterModal] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  const [progressDetails, setProgressDetails] = useState<DataRow>({
    companyID: 0,
    date: "",
    dateCreated: "",
    dateModified: "",
    endKm: 0,
    followUp: "",
    imageFIle: "",
    imageURL: "",
    isCompleted: false,
    position: "",
    profileId: 0,
    progress: "",
    progressNoteId: 0,
    report: "",
    staff: "",
    staffId: 0,
    startKm: 0,
    userCreated: "",
    userModified: "",
    profile: {
      profileId: 0,
      firstName: "",
      surName: "",
      middleName: "",
      clientId: 0,
      email: "",
      phoneNumber: "",
      gender: "",
      fullName: "",
    },
  });
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredData = progressNote?.filter(
    (item) =>
      item?.staff?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.profile?.fullName?.toLowerCase().includes(searchText.toLowerCase())
  );

  const resetFilter = (e: FormEvent) => {
    e.preventDefault();
    companyId && dispatch(fetchProgressNote(companyId));
    setFilterModal(false);
    setUsers("");
    setClient("");
  };

  const filterActivity = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    if (users && client) {
      dispatch(
        filterProgressNote({
          staff: users,
          client: client,
        })
      );
    }
    setLoading(false);
    setFilterModal(false);
    setUsers("");
    setClient("");
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setProgressDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const EditDetail = async (id: number) => {
    setSaveId(id);
    setShowModal(true);
    try {
      const { data } = await axiosInstance.get(`/ProgressNotes/${id}`);
      setProgressDetails({ ...data });
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const info = {
      ...progressDetails,
    };

    try {
      const { data } = await axiosInstance.post(
        `/ProgressNotes/edit/${saveId}?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      setLoading(false);
      companyId && dispatch(fetchProgressNote(companyId));
      setShowModal(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SEO
        title="Progress Report"
        description="Progress Report"
        name="Promax-Care"
      />
      <Breadcrumb />

      <div className="mt-10">
        {progressNote.length <= 0 && loadingData && <Spinner color="#071952" />}
        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
          <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
            <h2 className="font-bold text-xl text-black w-auto">
              Progress Reports
            </h2>

            <div className="relative w-full lg:col-span-2">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <BsSearch />
              </div>
              <input
                type="text"
                id="simple-search"
                onChange={handleSearch}
                className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Search By Staff or Client Name"
              />
            </div>
          </div>
          <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
            <div className="flex items-center space-x-3 w-full md:w-auto">
              <button
                onClick={() => setFilterModal(true)}
                id="actionsDropdownButton"
                className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                type="button"
              >
                <FaFilter />
                Filter
              </button>
              <ExportDropdown>
                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <IconButton
                    icon={<FaFilePdf className="text-red-500" />}
                    title="Export as PDF"
                    onClick={() =>
                      handlePDFDownload(
                        progressNote,
                        columns as unknown as Column<DataRow>[],
                        "ProgressNote.pdf"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileExcel className="text-green-600" />}
                    title="Export as Excel Sheet"
                    onClick={() =>
                      exportToExcel(
                        progressNote,
                        columns as unknown as Column<DataRow>[],
                        "ProgressNote"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileCsv className="text-green-500" />}
                    title="Export as CSV"
                    onClick={() =>
                      downloadCSV({
                        data: progressNote,
                        filename: "ProgressNote.csv",
                      })
                    }
                  />
                </div>
              </ExportDropdown>
            </div>
          </div>
        </div>

        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          paginationTotalRows={progressNote?.length}
        />
      </div>

      <Modal
        open={filterModal}
        onClose={() => setFilterModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form action="" className="space-y-2">
              <div>
                <label className=" text-xs text-gray-600 font-semibold">
                  Select Staff
                </label>
                <InputPicker
                  size="lg"
                  placeholder="--All Staff--"
                  onChange={(value) => setUsers(value)}
                  data={staff.map((user) => ({
                    label: user.fullName,
                    value: user.fullName,
                  }))}
                  className="w-full focus:outline-none focus:border-primary text-gray-600"
                  appearance="subtle"
                />
              </div>

              <div>
                <label className=" text-xs text-gray-600 font-semibold">
                  Select Client
                </label>
                <InputPicker
                  size="lg"
                  placeholder="--All Client--"
                  onChange={(value) => setClient(value)}
                  data={clients.map((user) => ({
                    label: user.fullName,
                    value: user.profileId,
                  }))}
                  className="w-full focus:outline-none focus:border-primary text-gray-600"
                  appearance="subtle"
                />
              </div>

              <CustomInput
                label="Start Date"
                type="date"
                name="dateFrom"
                ref={dateFrom}
              />
              <CustomInput
                label="End Date"
                type="date"
                name="dateTo"
                ref={dateTo}
              />

              <div className="flex items-center justify-center mt-3">
                <div className="text-center">
                  <button
                    type="submit"
                    onClick={resetFilter}
                    className="text-black bg-white border border-primary hover:bg-white/45 focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-8 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                  >
                    Reset Filter
                  </button>
                </div>

                <div className="text-center">
                  <button
                    type="submit"
                    onClick={filterActivity}
                    disabled={loading ? true : false}
                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                  >
                    {loading ? <Spinner /> : "Load"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Progress Note Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form action="">
              <div className="py-2">
                <label htmlFor="report" className="font-bold">
                  Report{" "}
                </label>
                <textarea
                  id="report"
                  rows={3}
                  name="report"
                  autoComplete="off"
                  value={progressDetails.report || ""}
                  onChange={handleInputChange}
                  className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                  defaultValue={""}
                />
              </div>
              <div className="py-2">
                <label htmlFor="progress" className="font-bold">
                  Progress{" "}
                </label>
                <textarea
                  id="progress"
                  rows={3}
                  name="progress"
                  autoComplete="off"
                  value={progressDetails.progress || ""}
                  onChange={handleInputChange}
                  className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                  defaultValue={""}
                />
              </div>
              <div className="py-2">
                <label htmlFor="followUp" className="font-bold">
                  Follow Up{" "}
                </label>
                <textarea
                  id="followUp"
                  rows={3}
                  name="followUp"
                  autoComplete="off"
                  value={progressDetails.followUp || ""}
                  onChange={handleInputChange}
                  className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                  defaultValue={""}
                />
              </div>
            </form>
            <div className="mx-auto text-center mt-3">
              <button
                type="submit"
                onClick={handleSubmit}
                disabled={loading ? true : false}
                className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                    font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                    dark:focus:ring-primary"
              >
                {loading ? <Spinner /> : "Update"}
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default ProgressReport;
