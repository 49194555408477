import React from "react";
import calendarIcon from "../../../../images/icon/calendar.png";

const EmptyShift = () => {
  return (
    <div className="border-2 rounded-2xl bg-white shadow  ">
      <div className="bg-gray-100 text-center p-2 flex justify-between items-center text-sm font-semibold rounded-t-xl ">
        <span>No Shift Available</span>{" "}
      </div>
      <div className="flex flex-col gap-2 items-center justify-center mx-auto pt-14 px-4 md:px-4 xl:px-10 my-3">
        <img src={calendarIcon} alt="notification icon" width={120} />

        <strong className="text-center">
          No Shift available today.
        </strong>
      </div>
    </div>
  );
};

export default EmptyShift;
