import React from "react";
import ReactDOM from "react-dom/client";
import "rsuite/dist/rsuite.min.css";
import "./index.css";
import App from "./App";
import { ToastContainer } from "react-toastify";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { CustomProvider } from "rsuite";
import ErrorBoundary from "./error";

const helmetContext = {};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router basename="/">
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <HelmetProvider context={helmetContext}>
            <ToastContainer position="top-right" theme="dark" />
            <CustomProvider theme="light">
              <ErrorBoundary>
                <App />
              </ErrorBoundary>
            </CustomProvider>
          </HelmetProvider>
        </PersistGate>
      </Provider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
