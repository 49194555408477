import React from "react";
import { Navigate, Route, Routes } from "react-router";
import { Loader } from "rsuite";

const CalendarLayout = () => {
  const ShiftRoster = React.lazy(() => import("./ShiftRoster"));
  return (
    <div>
      <React.Suspense
        fallback={
          <div className="flex justify-center items-center p-4 ">
            <Loader size="md" />
          </div>
        }
      >
        <Routes>
          <Route
            path="/"
            element={<Navigate to="/app/shift/roster" replace />}
          />
          <Route path="/roster" element={<ShiftRoster />} />
        </Routes>
      </React.Suspense>
    </div>
  );
};

export default CalendarLayout;
