import React, { ChangeEvent } from "react";
import { FaPlus } from "react-icons/fa";

interface Props {
  alertValues: string[];
  handleInputChange: (index: number, value: string) => void;
  handleAddRow: () => void
}
const AlertTable: React.FC<Props> = ({ alertValues, handleInputChange, handleAddRow }) => {


  return (
    <div>
      <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border mt-9 border-gray-300">
        <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
          <thead className="ltr:text-left rtl:text-right bg-[#b9b9b95e]">
            <tr className="divide-x-2">
              <th className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
                TOP 5 ALERTS – These are the most important things to know about me
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {alertValues.map((value, index) => (
              <tr key={index} className="divide-x-2">
                <td className="whitespace-nowrap flex gap-3 px-4 py-2 text-gray-700 w-[100%] outline-none">
                  <span>{index + 1}.</span>
                  <input
                    className="w-[100%] h-[20px] outline-none"
                    type="text"
                    required
                    value={value}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(index, e.target.value)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button
        type="button"
        onClick={handleAddRow}
        className="mt-4 w-10 h-10  bg-gray-200 text-white rounded-full flex justify-center items-center"
      >
        <FaPlus className="text-lg text-black" />
      </button>

    </div>
  );
};

export default AlertTable;
