import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";
import { Shift } from "./shiftRosterSlice";

export interface ShiftReportDataType {
  shiftReportId: number;
  urgentMatters: string;
  medicationGiven: string;
  medicationSigned: string;
  medicationAvailable: string;
  medicatioErrors: string;
  isMealManagementPlan: boolean;
  details_IfNotMealMaganagementPlan: string;
  isDrinkingProblem: boolean;
  details_IfProblemExist: string;
  isHealthIssues: true;
  details_IfHealthIssuesExist: string;
  goal_Progress: string;
  contactFamily: string;
  isIncident: boolean;
  details_IfIsIncipient: string;
  isBehaviourConcerned: boolean;
  details_ifIsBehaviourConcerned: string;
  shiftRosterId: number;
  shiftRoster: Shift;
  companyID: number;
  dateCreated: string;
  userCreated: string;
  dateModified: string;
  userModified: string | null;
}

export interface ClientShiftReportState {
  loading: boolean;
  data: Array<ShiftReportDataType>;
  error: string | undefined;
}
const initialState: ClientShiftReportState = {
  loading: false,
  data: [],
  error: undefined,
};
export const fetchClientShiftReport = createAsyncThunk(
  "ShiftReportDataType/fetchClientShiftReport",
  async (cliId: number) => {
    try {
      const response = await api.fetchClientShiftReport(cliId);
      return response?.sort(
        (a: ShiftReportDataType, b: ShiftReportDataType) =>
          new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
      );
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

const clientShiftReportSlice = createSlice({
  name: "clientShiftReport",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchClientShiftReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchClientShiftReport.fulfilled,
      (state, action: PayloadAction<Array<ShiftReportDataType>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(fetchClientShiftReport.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {},
});
export const userSelector = (state: RootState) => state.clientShiftReport;
export default clientShiftReportSlice.reducer;

export const clientShiftReportData = (state: RootState) => state.clientShiftReport.data;
export const clientShiftReportLoading = (state: RootState) =>
  state.clientShiftReport.loading;
export const clientShiftReportError = (state: RootState) => state.clientShiftReport.error;
