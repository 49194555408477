// import React from 'react'

// const Consent = () => {
//   return (
//     <div>
//       <div className="p-4 max-[1024px]:w-[100%]  my-3 border border-black ">
//         <p className="text-[16px] mb-3">
//           Consent to take photos and videos, and provide feedback to external
//           auditors:
//         </p>
//         <div className="flex items-start gap-3 mt-4 ">
//           <input type="checkbox" />
//           <p className=" text-[16px]">
//             Consent to record you on audio or visual devices - we will inform
//             you if we want to do so each time. Photos and videos may be used for
//             promotional purposes. For example, we may publish your photo or
//             video on social media or our website
//           </p>
//         </div>
//         <div className="flex items-start gap-3 mt-4 ">
//           <input type="checkbox" />
//           <p className=" text-[16px]">
//             Consent to provide feedback to other people about the services you
//             receive from us. This includes NDIS auditors. You may say no at any
//             time, and that will be documented in your file.
//           </p>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Consent

import React from 'react';

interface ConsentProps {
  formDataConsent: {
    consentAudioVisual: boolean;
    consentFeedback: boolean;
  }
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Consent: React.FC<ConsentProps> = ({ formDataConsent, handleCheckboxChange }) => {
  return (
    <div>
      <div className="p-4 max-[1024px]:w-[100%]  my-3 border border-black ">
        <p className="text-[16px] mb-3">
          Consent to take photos and videos, and provide feedback to external
          auditors:
        </p>
        <div className="flex items-start gap-3 mt-4 ">
          <input
            type="checkbox"
            name="consentAudioVisual"
            checked={formDataConsent.consentAudioVisual}
            onChange={handleCheckboxChange}
          />
          <p className=" text-[16px]">
            Consent to record you on audio or visual devices - we will inform
            you if we want to do so each time. Photos and videos may be used for
            promotional purposes. For example, we may publish your photo or
            video on social media or our website
          </p>
        </div>
        <div className="flex items-start gap-3 mt-4 ">
          <input
            type="checkbox"
            name="consentFeedback"
            checked={formDataConsent.consentFeedback}
            onChange={handleCheckboxChange}
          />
          <p className=" text-[16px]">
            Consent to provide feedback to other people about the services you
            receive from us. This includes NDIS auditors. You may say no at any
            time, and that will be documented in your file.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Consent;
