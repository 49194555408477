import React from 'react'


interface Props {
    content: string;
    onView: () => void;
}
const UserTrainingCard = ({ onView, content }: Props) => {


    return (
        <div className=" bg-white rounded-md shadow p-2 border-s-4 border-brand overflow-clip">

            {/* <p className='font-bold text-lg'>{content}</p> */}
            <tr className='text-sm truncate ...'>
                <td className="whitespace-nowrap px-2 py-1  inline-flex items-center gap-1 font-bold">
                    Training Name:
                </td>
                <td className="whitespace-nowrap px-2 py-1 text-gray-700 font-bold">{content}</td>

            </tr>
            <div className='flex gap-3 items-center py-2 pl-2'>
                <button className='bg-gray-300 px-6 py-2 rounded-md text-primary border border-primary text-xs font-normal 
                    transition duration-300 transform active:scale-95 ease-in-out
                ' onClick={onView} >Read</button>

            </div>

        </div>
    )
}

export default UserTrainingCard