import React, {
    ChangeEvent,
    useEffect,
    useState,
    FormEvent
} from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import DataTable, { TableColumn } from "react-data-table-component";
import { BsSearch } from "react-icons/bs";
import ExportDropdown from "../../../Components/menu/ExportDropdown";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import Spinner from "../../../Components/Spinner";
import {
    FaEye,
    FaFileCsv,
    FaFileExcel,
    FaFilePdf,
    FaRegEdit,
    FaRegTrashAlt,
} from "react-icons/fa";
import IconButton from "../../../Components/Buttons/IconButton";
import { Tooltip, Whisper, Modal, Button, InputPicker } from "rsuite";
import {
    downloadCSV,
    exportToExcel,
    handlePDFDownload,
} from "../../../services/reusableFunc";
import { Column } from "../../../types/DataType";
import { fetchCompanyPolicyDetails, policyDetailsData, PolicyDetails, policyDetailsLoading } from "../../../store/slices/policyDetailsSlice";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../store/axiosInstance";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import CustomInput from "../../../Components/Input/CustomInput";

interface Category {
    label: string;
    value: string;
}
const PolicyTable = () => {
    const columns: TableColumn<PolicyDetails>[] = [
        {
            name: "Policy Name",
            selector: (row) => row.policyName,
            sortable: true,
        },
        {
            name: "Category",
            selector: (row) => row.category,
            sortable: true,
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className="flex gap-3 overflow-hidden cursor-pointer">
                    <Whisper
                        placement="top"
                        controlId="control-id-hover"
                        trigger="hover"
                        speaker={<Tooltip>Edit Policy</Tooltip>}
                    >
                        <button
                            onClick={() => FetchDetails(row.policyDocumentId)}
                            className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
                        >
                            <FaRegEdit className="text-base" />
                        </button>
                    </Whisper>
                    <Whisper
                        placement="auto"
                        controlId="control-id-hover"
                        trigger="hover"
                        speaker={<Tooltip>View Policy</Tooltip>}
                    >
                        <button
                            onClick={() => ViewPolicy(row.policyUrl)}
                            className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
                        >
                            <FaEye className="text-base cursor-pointer" />
                        </button>
                    </Whisper>
                    <Whisper
                        placement="top"
                        controlId="control-id-hover"
                        trigger="hover"
                        speaker={<Tooltip>Delete Policy</Tooltip>}
                    >
                        <button
                            onClick={() => delPolicy(row.policyDocumentId)}
                            className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
                        >
                            <FaRegTrashAlt className="text-base text-red-500" />
                        </button>
                    </Whisper>
                </div>
            ),
        },
    ];

    const { uid } = useParams()
    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    const company = user?.companyId;
    useEffect(() => {
        if (company && uid) {
            dispatch(fetchCompanyPolicyDetails({ companyId: company, uid: uid }));
            FetchCategory()
        }
    }, [dispatch, company, uid]);
    const policyDetails = useSelector(policyDetailsData);
    const loadingData = useSelector(policyDetailsLoading);
    const [searchText, setSearchText] = useState<string>("");
    const [loading1, setLoading1] = useState<boolean>(false);
    const [saveIds, setSaveIds] = useState<number>(0);
    const [editModal, setEditModal] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [docModal, setDocModal] = useState<boolean>(false);
    const [docUrlModal, setDocUrlModal] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const filteredData = policyDetails?.filter(
        (item) =>
            item?.category?.toLowerCase().includes(searchText.toLowerCase())
    );

    const [pDetails, setPDetails] = useState<PolicyDetails>({
        category: "",
        categoryCode: "",
        company: "",
        companyId: 0,
        dateCreated: "",
        dateModified: "",
        isClient: false,
        isGeneral: false,
        isStaff: false,
        policyDocumentId: 0,
        policyName: "",
        policyUrl: "",
        policyUrlFile: "",
        userCreated: "",
        userModified: "",
    });

    const [categor, setCategor] = useState<Category[]>([]);

    const FetchCategory = async () => {

        try {
            const { data } = await axiosInstance.get(
                `/PolicyDocuments/get_company_categories?companyId=${company}`
            );
            const formattedData = data.categories.map((item: { category: string }) => {
                const match = item.category.match(/(.*) \((.*)\)/);
                return {
                    label: match ? match[1] : item.category, // Extracts 'Right & Responsibility' part
                    value: match ? match[2] : item.category, // Extracts 'C1' part
                };
            });
            const finalData = [
                ...formattedData,
                { label: "Others", value: "Others" }
            ];
            setCategor(finalData)
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // console.log(error);;
            } else {
                toast.error("An error occurred");
            }
        }
    };

    const [category, setCategory] = useState('');
    const [categoryCode, setCategoryCode] = useState('');
    const [showCustomCategory, setShowCustomCategory] = useState<boolean>(false);

    const handleCategoryChange = (value: string) => {
        const selectedCategory = categor.find(cat => cat.value === value);
        if (selectedCategory) {
            // Update both category and the PolicyDetails state.
            setCategory(selectedCategory.label);
            setCategoryCode(selectedCategory.value);
            setPDetails(prevState => ({
                ...prevState,
                category: selectedCategory.label, // Update policy details category
                categoryCode: selectedCategory.value
            }));
        }

        // If "Others" is selected, enable custom category input
        if (value === "Others") {
            setShowCustomCategory(true);
            setCategory(""); // Reset category for custom input
            setCategoryCode(""); // Reset category code for custom input
        } else {
            setShowCustomCategory(false);
        }
    };

    // Handle changes in the custom category input
    const handleCustomCategoryChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCategory(e.target.value);
        setCategoryCode(""); // Custom categories won't have a predefined code
    };


    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setPDetails((prevState) => ({
            ...prevState,
            [name]: checked,
        }));
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setPDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const delPolicy = (id: number) => {
        setSaveIds(id);
        setDeleteModal(true);
    };

    const ViewPolicy = (documentUrl: string): void => {
        setDocUrlModal(documentUrl); // Set the document URL in the state
        setIsLoading(true); // Start loading spinner
        setDocModal(true); // Open the modal
    };

    const getFileExtension = (url: string) => {
        return url.split('.').pop()?.toLowerCase(); // Get the file extension
    };

    const FetchDetails = async (id: number) => {
        setSaveIds(id);
        setEditModal(true);
        try {
            const { data } = await axiosInstance.get(
                `/PolicyDocuments/details/${id}`
            );
            setPDetails({ ...data });
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // console.log(error);
            } else {
                toast.error("An error occurred");
            }
            setLoading1(false);
        } finally {
            setLoading1(false);
        }
    };

    const [policyDocument, setPolicyDocument] = useState<File | null>(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setPolicyDocument(file);
        }
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const formData = new FormData();

        policyDocument && formData.append("PolicyUrlFile", policyDocument);

        for (const key in pDetails) {
            const value = pDetails[key as keyof typeof pDetails];
            if (value === null) {
                formData.append(key, ""); // Pass empty string if value is null
            } else {
                formData.append(key, value.toString());
            }
        }

        try {
            setLoading1(true);
            const { data } = await axiosInstance.post(
                `/PolicyDocuments/edit/${pDetails.policyDocumentId}?userId=${user?.uid}`,
                formData
            );
            toast.success(data.message);
            setLoading1(false);
            company && uid && dispatch(fetchCompanyPolicyDetails({ companyId: company, uid: uid }));
            setEditModal(false);
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // console.log(error);;
            } else {
                toast.error("An error occurred");
            }
            setLoading1(false);
            setEditModal(false);
        } finally {
            setLoading1(false);
            setEditModal(false);
        }
    };

    const handleDelete = async (e: FormEvent) => {
        e.preventDefault();
        setLoading1(true);
        try {
            const { data } = await axiosInstance.post(
                `/PolicyDocuments/delete/${saveIds}?userId=${user?.uid}`
            );
            toast.success(data.message);
            setDeleteModal(false);
            company && uid && dispatch(fetchCompanyPolicyDetails({ companyId: company, uid: uid }));
            setLoading1(false);
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // console.log(error);;
            } else {
                toast.error("An error occurred");
            }
            setLoading1(false);
            setDeleteModal(false);
        } finally {
            setLoading1(false);
        }
    };



    return (
        <>
            <SEO
                title="Policy Documents"
                description="Policy Documents"
                name="Promax-Care"
            />
            <Breadcrumb />

            <div className="mt-10">
                {policyDetails.length <= 0 && loadingData && <Spinner color="#071952" />}
                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
                    <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
                        <h2 className="font-bold text-xl text-black w-auto">
                            Policy Documents
                        </h2>

                        <div className="relative w-full lg:col-span-2">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <BsSearch />
                            </div>
                            <input
                                type="text"
                                id="simple-search"
                                onChange={handleSearch}
                                className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                placeholder="Search By Policy Name"
                            />
                        </div>
                    </div>
                    <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                        <div className="flex items-center space-x-3 w-full md:w-auto">

                            <ExportDropdown>
                                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                    <IconButton
                                        icon={<FaFilePdf className="text-red-500" />}
                                        title="Export as PDF"
                                        onClick={() =>
                                            handlePDFDownload(
                                                policyDetails,
                                                columns as unknown as Column<PolicyDetails>[],
                                                "Policies.pdf"
                                            )
                                        }
                                    />
                                    <IconButton
                                        icon={<FaFileExcel className="text-green-600" />}
                                        title="Export as Excel Sheet"
                                        onClick={() =>
                                            exportToExcel(
                                                policyDetails,
                                                columns as unknown as Column<PolicyDetails>[],
                                                "Policies"
                                            )
                                        }
                                    />
                                    <IconButton
                                        icon={<FaFileCsv className="text-green-500" />}
                                        title="Export as CSV"
                                        onClick={() =>
                                            downloadCSV({
                                                data: policyDetails,
                                                filename: "Policies.csv",
                                            })
                                        }
                                    />
                                </div>
                            </ExportDropdown>
                        </div>
                    </div>
                </div>

                <DataTable
                    columns={columns}
                    data={filteredData}
                    pagination
                    paginationTotalRows={policyDetails?.length}
                />
            </div>

            <Modal
                open={editModal}
                onClose={() => setEditModal(false)}
                backdrop="static"
                autoFocus
                size={"lg"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Edit Policy Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form
                            action=""
                            className="p-3 grid grid-cols-1 gap-3 lg:grid-cols-2"
                            onSubmit={handleSubmit}
                        >
                            <div>
                                <label className="text-xs text-gray-600 font-semibold">
                                    Select Category
                                </label>
                                <InputPicker
                                    size="lg"
                                    onChange={handleCategoryChange}
                                    data={categor}
                                    className="w-full focus:outline-none focus:border-primary text-gray-600"
                                    appearance="subtle"
                                    value={pDetails.category || "Others"}
                                />
                                {showCustomCategory && (
                                    <CustomInput
                                        label="Custom Category"
                                        type="text"
                                        onChange={handleCustomCategoryChange}
                                        value={category}
                                        required
                                    />
                                )}
                            </div>

                            <CustomInput
                                label="Category Code"
                                type="text"
                                onChange={(e) => setCategoryCode(e.target.value)}
                                value={categoryCode || pDetails.categoryCode}
                                readOnly={!showCustomCategory} // Editable only when "Others" is selected
                                required
                            />

                            <CustomInput
                                label="Policy Name"
                                type="text"
                                name="policyName"
                                value={pDetails.policyName}
                                onChange={handleInputChange}
                                required
                            />

                            <CustomInput
                                label="Policy File"
                                type="file"
                                onChange={handleFileChange}
                            />


                            <div className="flex items-center">
                                <input
                                    id="isGeneralPolicy"
                                    name="isGeneral"
                                    checked={pDetails.isGeneral}
                                    onChange={handleCheckboxChange}
                                    type="checkbox"
                                    className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
                                />
                                <label
                                    htmlFor="isGeneralPolicy"
                                    className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Is General Policy
                                </label>
                            </div>

                            <div className="flex items-center">
                                <input
                                    id="isClientPolicy"
                                    name="isClient"
                                    checked={pDetails.isClient}
                                    onChange={handleCheckboxChange}
                                    type="checkbox"
                                    className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
                                />
                                <label
                                    htmlFor="isClientPolicy"
                                    className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Is Client Policy
                                </label>
                            </div>

                            <div className="flex items-center">
                                <input
                                    id="isStaffPolicy"
                                    name="isStaff"
                                    checked={pDetails.isStaff}
                                    onChange={handleCheckboxChange}
                                    type="checkbox"
                                    className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
                                />
                                <label
                                    htmlFor="isStaffPolicy"
                                    className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Is Staff Policy
                                </label>
                            </div>

                            <div className="mx-auto text-center mt-3 lg:col-span-2">
                                <button
                                    type="submit"
                                    disabled={loading1}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary transition duration-300 transform active:scale-95 ease-in-out"
                                >
                                    {loading1 ? <Spinner /> : " Submit"}
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>

            <Modal
                open={docModal}
                onClose={() => setDocModal(false)}
                backdrop="static"
                autoFocus
                size={"lg"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">View Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        {isLoading && (
                            <div className="text-center">
                                <Spinner /> {/* Show spinner while iframe is loading */}
                                <p>Loading document...</p>
                            </div>
                        )}

                        {getFileExtension(docUrlModal) === 'pdf' ? (
                            <iframe
                                src={docUrlModal}
                                width="100%"
                                height="500px"
                                title="PDF Viewer"
                                style={{ display: isLoading ? "none" : "block" }} // Hide iframe until loaded
                                onLoad={() => setIsLoading(false)} // Stop loading spinner when iframe loads
                            />
                        ) : (
                            <iframe
                                src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(docUrlModal)}`}
                                width="100%"
                                height="500px"
                                title="Office Online Viewer"
                                style={{ display: isLoading ? "none" : "block" }} // Hide iframe until loaded
                                onLoad={() => setIsLoading(false)} // Stop loading spinner when iframe loads
                            />
                        )}
                    </>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setDocModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>


            <Modal
                open={deleteModal}
                onClose={() => setDeleteModal(false)}
                role="alertdialog"
                backdrop="static"
                autoFocus
                size={"xs"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Delete Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <p className="mb-4 font-bold">
                            Are you sure you want to delete this policy?
                        </p>
                        <div className="flex justify-center items-center space-x-4">
                            <Button
                                onClick={handleDelete}
                                className="bg-red-500 text-white hover:bg-red-700 hover:text-white"
                            >
                                {loading1 ? <Spinner /> : "Yes, I'm sure"}
                            </Button>
                            <Button
                                onClick={() => setDeleteModal(false)}
                                className="bg-gray-500 text-white"
                            >
                                No, cancel
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        </>
    );
};

export default PolicyTable;
