import React, { ChangeEvent } from 'react';
import CustomSelect from "../../../../../Components/Input/CustomSelect";
import ClientTabForm from "../../components/ClientTabForm";
import { HealthSupportDataType } from '../HealthSupport';


interface Props {
    healthSupport: HealthSupportDataType;
    handleChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>) => void;
}

const HealthSupportCom: React.FC<Props> = ({ handleChange, healthSupport }) => {




    return (
        <div className='mt-10'>
            <h2 className='font-bold text-xl text-black w-auto'>Health Support Needs</h2>
            <div className="mt-5">
                <ClientTabForm title='Health Support Needs'>
                    <div className='grid grid-cols-1 gap-16 lg:grid-cols-2'>
                        <CustomSelect
                            label="Medication Required"
                            options={[
                                { label: "Yes", value: "Yes" },
                                { label: "No", value: "No" }
                            ]}
                            required
                            name="medicationRequired"
                            onChange={handleChange}
                        />
                        <CustomSelect
                            label="How Often do you require medication"
                            options={[
                                { label: "Prompt Required", value: "Prompt Required" },
                                { label: "Assistance Required", value: "Assistance Required" },
                                { label: "Administration Required", value: "Administration Required" }
                            ]}
                            required
                            name="medicationFrequency"
                            onChange={handleChange}
                        />
                        <div>
                            <label htmlFor="additionalSupportDetail" className="font-bold">Is additional support for these issues? If so, please provide detail</label>
                            <textarea
                                id="additionalSupportDetail"
                                name="additionalSupportDetail"
                                rows={3}
                                autoComplete="off"
                                className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                value={healthSupport.additionalSupportDetail}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label htmlFor="medicationDetails" className="font-bold">Provide details of your medication and treatment plan</label>
                            <textarea
                                id="medicationDetails"
                                name="medicationDetails"
                                rows={3}
                                autoComplete="off"
                                className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                value={healthSupport.medicationDetails}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label htmlFor="healthIssues" className="font-bold">Describe any ongoing health issues you have, including mental health issues</label>
                            <textarea
                                id="healthIssues"
                                name="healthIssues"
                                rows={3}
                                autoComplete="off"
                                className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                value={healthSupport.healthIssues}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </ClientTabForm>
            </div>
        </div>
    );
};

export default HealthSupportCom;
