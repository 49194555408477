import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

interface ClientAidAndEquip {
  aidsId: number;
  dateCreated: string;
  dateModified: string;
  equipmentAssistance: boolean;
  equipmentUsed: string;
  profile: {
    profileId: number;
    firstName: string;
    surName: string;
    middleName: string;
    clientId: number;
    email: string;
    phoneNumber: string;
    gender: string;
    fullName: string;
  };
  profileId: number;
  requireStaffTraining: boolean;
  userCreated: string;
  userModified: string;
}

export interface ClientAidAndEquipState {
  loading: boolean;
  data: Array<ClientAidAndEquip>;
  error: string | undefined;
}
const initialState: ClientAidAndEquipState = {
  loading: false,
  data: [],
  error: undefined,
};
export const fetchClientAidAndEquip = createAsyncThunk(
  "ClientAidAndEquip/fetchClientAidAndEquip",
  async (uid: number) => {
    try {
      const response = await api.fetchClientAidAndEquip(uid);
      return response;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

const clientAidAndEquipSlice = createSlice({
  name: "clientAidAndEquip",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchClientAidAndEquip.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchClientAidAndEquip.fulfilled,
      (state, action: PayloadAction<Array<ClientAidAndEquip>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(fetchClientAidAndEquip.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {},
});
export const userSelector = (state: RootState) => state.clientAidAndEquip;
export default clientAidAndEquipSlice.reducer;

// Define selectors to access the profile slice state
export const clientAidAndEquipData = (state: RootState) =>
  state.clientAidAndEquip.data;
export const clientAidAndEquipLoading = (state: RootState) =>
  state.clientAidAndEquip.loading;
export const clientAidAndEquipError = (state: RootState) =>
  state.clientAidAndEquip.error;
