import React from "react";
import dayjs from "dayjs";
import { StaffShift } from "../../../../store/slices/staffShiftSlice";
import Spinner from "../../../../Components/Spinner";
import { useNavigate } from "react-router-dom";
interface Props {
  activity: StaffShift;
  getActivityStatus(activity: StaffShift): string;
  handleClockIn: (e: StaffShift) => void;
  isLoading: boolean;
  loading: boolean;
  EditDetail: (shiftId: number) => Promise<void>;
  ClockOut: (shiftId: number) => Promise<void>;
  handleSetKm: (shiftId: number) => void;
}

const TodayShift = ({
  activity,
  getActivityStatus,
  handleClockIn,
  isLoading,
  EditDetail,
  ClockOut,
  handleSetKm,
}: Props) => {
  const navigate = useNavigate();

  const FillReport = (shiftId: number) => {
    navigate(`/app/staff/report-form/${shiftId}`);
  };

  const ViewClient = (id: number, fname: string) => {
    navigate(`/app/staff/client-profile/${id}/${fname}`);
  };

  return (
    <div className="border rounded-2xl bg-white shadow-sm">
      <div className="bg-blue-50 text-center p-2 flex justify-between items-center text-sm font-semibold rounded-t-xl ">
        <span>Care Shift</span>{" "}
        <span className={`rounded p-1 text-xs !capitalize `}>
          {dayjs(activity.dateFrom).format("dddd, MMMM D, YYYY")}
        </span>
      </div>
      <div className="flex gap-2 flex-col   px-2 py-4 overflow-y-auto">
        <div className="text-sm flex gap-10 items-center truncate ...">
          <span className="font-bold">Client:</span>
          <span
            onClick={() =>
              ViewClient(activity.profileId, activity.profile.firstName)
            }
            className="font-bold underline cursor-pointer"
          >
            {activity.clients}
          </span>
        </div>
        <div className="text-sm flex gap-10 items-center">
          <span className="font-bold">Start Time:</span>
          <span>{dayjs(activity?.dateFrom).format("hh:mm A")}</span>
        </div>
        <div className="text-sm flex gap-10 items-center">
          <span className="font-bold">End Time:</span>
          <span>{dayjs(activity?.dateTo).format("hh:mm A")}</span>
        </div>
      </div>
      <div className="p-2 font-bold bg-gray-100">
        <span>Care Appointment</span>
      </div>
      <div className="p-2">
        <div className="p-2 border border-dotted h-20">
          <span>
            {activity.appointment ? (
              activity.appointment.substring(0, 120)
            ) : (
              <span>No Appointment at the moment</span>
            )}
          </span>
        </div>
      </div>
      <div className="relative">
        <div className="p-2 font-bold bg-gray-100">
          <span>Activities</span>
        </div>
        <div className="p-2">
          <ul className="space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
            {activity.activities.split(",").map((activity, index) => (
              <li key={index}>
                {activity.trim()} {/* Trimming to remove any extra spaces */}
              </li>
            ))}
          </ul>
        </div>

        {getActivityStatus(activity) === "Shift In progress" && (
          <div className="flex justify-end absolute bottom-0 right-0">
            <span className="text-[10px] font-normal bg-brand text-white rounded-es-md p-0.5">
              Shift In progress
            </span>
          </div>
        )}
      </div>

      <div className="text-center mt-3 p-2 ">
        <div>
          {activity ? (
            <>
              {getActivityStatus(activity) === "Upcoming" ? (
                <span className="font-bold text-secondary cursor-none">
                  Upcoming
                </span>
              ) : getActivityStatus(activity) === "Clock-In" ? (
                <div className="flex gap-2 flex-wrap justify-center">
                  <button
                    type="button"
                    onClick={() => EditDetail(activity?.shiftRosterId)}
                    className="text-white hover:bg-red-600 cursor-pointer
                                                            font-medium rounded-full text-xs px-16 py-2 bg-red-500"
                  >
                    Request to cancel shift
                  </button>
                  <button
                    type="submit"
                    onClick={() => handleClockIn(activity)}
                    className={`text-white hover:bg-green-800 cursor-pointer
                                                            font-medium rounded-full text-xs px-16 py-2 ${isLoading
                        ? "bg-secondary"
                        : "bg-green-700"
                      }`}
                  >
                    {isLoading ? (<div className="flex gap-2">
                      <Spinner /> Getting location, please wait...
                    </div>) : ("Clock in")}


                  </button>
                </div>
              ) : (
                <div className="flex gap-2 flex-wrap justify-center">
                  <button
                    type="button"
                    className={`font-medium rounded-full text-xs px-16 py-1 ${getActivityStatus(activity) === "Upcoming"
                      ? "bg-secondary"
                      : getActivityStatus(activity) === "Absent"
                        ? "bg-red-500 text-white cursor-none"
                        : getActivityStatus(activity) === "Shift In progress"
                          ? "hidden"
                          : getActivityStatus(activity) === "Present"
                            ? "bg-green-500 text-white"
                            : ""
                      }`}
                  >
                    {getActivityStatus(activity)}
                  </button>

                  {getActivityStatus(activity) === "Shift In progress" && (
                    <div>
                      <button
                        type="button"
                        onClick={() => FillReport(activity.shiftRosterId)}
                        className="text-black bg-secondary hover:bg-secondary  
                                                         font-medium rounded-full text-xs px-16 py-2 mb-2"
                      >
                        {isLoading ? <Spinner /> : "Fill Shift Report Form"}
                      </button>
                      <button
                        type="button"
                        onClick={() => handleSetKm(activity?.shiftRosterId)}
                        className="text-white bg-black hover:bg-black/80  
                                                         font-medium rounded-full text-xs px-16 py-2 mb-2"
                      >
                        {isLoading ? <Spinner /> : "Update Mileage Details"}
                      </button>

                      {activity?.isShiftReportSigned && (
                        <button
                          type="button"
                          onClick={() => ClockOut(activity?.shiftRosterId)}
                          className="text-white bg-primary hover:bg-primary  
                                                         font-medium rounded-full text-xs px-16 py-2 "
                        >
                          Clock Out
                        </button>
                      )}
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            <span>No Shift Today</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default TodayShift;
