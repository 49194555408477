import React, { FormEvent } from 'react'
import { Modal } from 'rsuite';
import CustomInput from '../../../../Components/Input/CustomInput';
import CustomSelect from '../../../../Components/Input/CustomSelect';
import Spinner from '../../../../Components/Spinner';
import { ClientDataRow } from '../../../../types/AuthData';
interface Props {
    othersModal: boolean;
    setOthersModal: React.Dispatch<React.SetStateAction<boolean>>;
    editedProfile: ClientDataRow
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
    submitData: (e: FormEvent) => Promise<void>
    loading1: boolean;
}
const ClientOtherComp = ({ othersModal, setOthersModal, editedProfile, handleInputChange, submitData, loading1 }: Props) => {
    return (
        <>
            <Modal
                open={othersModal}
                onClose={() => setOthersModal(false)}
                backdrop="static"
                autoFocus
                size={"lg"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Edit Others</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form action="" onSubmit={submitData}>
                            <div className="p-2 grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8 ">
                                <CustomInput
                                    label="Cultural Background"
                                    type="text"
                                    name='culturalBackground'
                                    value={editedProfile.culturalBackground || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="Preferred Language"
                                    type="text"
                                    name='preferredLanguage'
                                    value={editedProfile.preferredLanguage || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomSelect
                                    label="Do you have an NDIS plan?"
                                    options={[
                                        { label: "Yes", value: "Yes" },
                                        { label: "No", value: "No" }
                                    ]}
                                    name="ndisPlan"
                                    value={editedProfile.ndisPlan || ''}
                                    onChange={handleInputChange}
                                />
                                <CustomSelect
                                    label="Interpreter Required?"
                                    options={[
                                        { label: "Yes", value: "Yes" },
                                        { label: "No", value: "No" }
                                    ]}
                                    name="requireInterpreter"
                                    // value={editedProfile.requireInterpreter || ''}
                                    onChange={handleInputChange}
                                />
                                <CustomSelect
                                    label="Indigenous Satatus"
                                    options={[
                                        { label: "Aboriginal", value: "Aboriginal" },
                                        { label: "Torres Strait Islander", value: "Torres Strait Islander" },
                                        { label: "Aboriginal and Torres Strait Islander", value: "Aboriginal and Torres Strait Islander" },
                                        { label: "Not Aboriginal or Torres Strait Islander", value: "Not Aboriginal or Torres Strait Islander" },
                                    ]}
                                    name="indigenousSatatus"
                                    value={editedProfile.indigenousSatatus || ''}
                                    onChange={handleInputChange}
                                />
                                <div className="">
                                    <label htmlFor="privacyPreferences" className="font-bold">
                                        Privacy Preferences{" "}
                                    </label>
                                    <textarea
                                        id="privacyPreferences"
                                        rows={2}
                                        autoComplete="off"
                                        name="privacyPreferences"
                                        value={editedProfile.privacyPreferences || ""}
                                        onChange={handleInputChange}
                                        className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                        defaultValue={""}
                                    />
                                </div>
                                <div className="">
                                    <label htmlFor="financialArrangement" className="font-bold">
                                        Financial Arrangement{" "}
                                    </label>
                                    <textarea
                                        id="financialArrangement"
                                        rows={2}
                                        autoComplete="off"
                                        name="financialArrangement"
                                        value={editedProfile.financialArrangement || ""}
                                        onChange={handleInputChange}
                                        className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                        defaultValue={""}
                                    />
                                </div>
                                <div className="lg:col-span-2">
                                    <label htmlFor="ndisPlanNote" className="font-bold">
                                        NDIS Plan Notes If Yes above, Include Plan approval Date and if No, State reason (e.g waiting for plan approval or plan review){" "}
                                    </label>
                                    <textarea
                                        id="ndisPlanNote"
                                        rows={2}
                                        autoComplete="off"
                                        name="ndisPlanNote"
                                        value={editedProfile.ndisPlanNote || ""}
                                        onChange={handleInputChange}
                                        className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                        defaultValue={""}
                                    />
                                </div>


                            </div>

                            <div className='flex items-center justify-center'>
                                <button type="submit"
                                    disabled={loading1 ? true : false}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">{
                                        loading1 ? <Spinner /> : "Update"
                                    }</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ClientOtherComp