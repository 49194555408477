import React, { useEffect, useRef, useState } from "react";
import MainLink from "./MainLink";
import { NavigationProps } from "../../types/Navigation";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import Spinner from "../Spinner";
import { fetchCompany } from "../../services/fetchServices";
import { CompanyDetail } from "../../types/AuthData";
import { GrPlan } from "react-icons/gr";

const EmptySideBar = ({ sidebarOpen, setSidebarOpen, open }: NavigationProps) => {
    const user = useSelector((state: RootState) => state?.auth?.user);

    const trigger = useRef<HTMLButtonElement>(null);
    const sidebar = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const clickHandler = ({ target }: { target: EventTarget | null }) => {
            if (!sidebar.current || !trigger.current) return;
            if (
                !sidebarOpen ||
                sidebar.current.contains(target as Node) ||
                trigger.current.contains(target as Node)
            )
                return;
            setSidebarOpen(false);
        };
        document.addEventListener("click", clickHandler);
        return () => document.removeEventListener("click", clickHandler);
    });

    const [companyOne, setCompanyOne] = useState<
        CompanyDetail | null | undefined
    >(null);
    const [loading, setLoading] = useState<boolean>(false);

    const handleCompanyFetch = async () => {
        setLoading(true);
        if (user?.companyId) {
            const data = await fetchCompany(user?.companyId);
            setCompanyOne(data);
        }
        setLoading(false);
    };
    useEffect(() => {
        handleCompanyFetch();
    }, []);

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }: { keyCode: number }) => {
            if (!sidebarOpen || keyCode !== 27) return;
            setSidebarOpen(false);
        };
        document.addEventListener("keydown", keyHandler);
        return () => document.removeEventListener("keydown", keyHandler);
    });



    return (
        <aside
            ref={sidebar}
            className={`sidebar absolute left-0 top-0 !z-30 duration-300  py-5 px-2  ${open ? "w-60" : "w-12"
                }
        md:!z-30 lg:!z-30 xl:!z-0  flex h-screen flex-col overflow-hidden  bg-brand ease-linear dark:bg-slate-900 lg:static
        lg:translate-x-0 ${sidebarOpen ? "translate-x-0" : "-translate-x-full"
                } `}
        >
            <div className="shadow-md py-8 lg:py-2">
                {loading && <Spinner />}
                <div className="lg:flex items-center justify-start gap-2 hidden">
                    {/* <AiFillHeart
                        className={` text-red-500 text-3xl rounded cursor-pointer block float-left mr-2 duration-500 ${open && "rotate-[360deg]"}`}
                    /> */}
                    {companyOne?.companyLogo && (
                        <img
                            src={companyOne?.companyLogo || "/promax care fav1 white.png"}
                            width={40}
                            alt="logo"
                            className="bg-white p-1 rounded"
                        />
                    )}
                    <h1
                        className={`text-white origin-left font-semibold text-base duration-300 ${!open && "hidden"
                            }`}
                    >
                        {companyOne?.companyName || "Promax Care"}
                    </h1>
                </div>


            </div>

            <ul className="pt-2 overflow-y-auto scroll-smooth scrollbar-hide ">
                <li
                    className={`text-gray-300 gap-x-4  p-2 font-medium text-sm ${!open && "hidden"
                        }`}
                >
                    Menu <span className="text-secondary">v 2.1.8</span>
                </li>


                {user?.role === "CompanyAdmin" ||
                    user?.role === "Administrator" ?
                    <MainLink
                        setSidebarOpen={setSidebarOpen}
                        icon={
                            <GrPlan />
                        }
                        title={
                            "Subscription"
                        }
                        link={`/app/setup/change-plan/${companyOne?.companyId}`}
                        open={open}
                    />
                    :
                    ""}

            </ul>
        </aside>
    );
};

export default EmptySideBar;
