import React, { FC, PropsWithChildren } from 'react'


type Props = PropsWithChildren<
    {
        title: string;
        subtext: string;
        price: number;
        duration: string;
        bg?: string;
        col?: string;
        icon: string;
        key: number

    }>

const PricingCard: FC<Props> = ({ title, subtext, key, price, duration, children, bg = 'lightbg', col, icon }) => {
    return (

        <div key={key} className={`flex flex-col p-2  mx-auto px-4  text-center text-gray-900 bg-${bg} text-${col} rounded-lg border
         border-gray-100 shadow dark:border-gray-600 xl:p-4 dark:bg-gray-800 dark:text-white`}>
            <span className='bg-white  flex justify-center items-center rounded-full mx-auto w-12 h-12 mb-2 border'>
                <img src={icon} alt="" className='w-full h-full p-2' />
            </span>
            <h3 className="mb-2 text-xl font-semibold">{title}</h3>
            <div className="flex justify-center items-baseline ">
                <span className="mr-2 text-3xl font-extrabold">${price}</span>
                <span className="text-gray-400 text-xs dark:text-gray-400">per {duration}</span>
            </div>
            <div className='my-2 py-3 border-t border-b'>
                <p className={`font-light text-${col} text-xs dark:text-gray-400`}>{subtext}</p>

            </div>            {/* List */}
            <ul role="list" className="mb-8 mt-2 space-y-4 text-left ">
                {children}
            </ul>



        </div>
    )
}

export default PricingCard