import React, { ChangeEvent, useEffect, useState, FormEvent } from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import { isAxiosError } from "axios";
import DataTable, {
  TableColumn,
  ExpanderComponentProps,
} from "react-data-table-component";
import {
  FaFileCsv,
  FaFileExcel,
  FaFilePdf,
  FaRegEdit,
  FaRegTrashAlt,
} from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import { MdOutlineManageAccounts } from "react-icons/md";
import ExportDropdown from "../../../Components/menu/ExportDropdown";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import {
  fetchUser,
  userData,
  userLoading,
} from "../../../store/slices/userSlice";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { MdOutlineLockReset } from "react-icons/md";
import Spinner from "../../../Components/Spinner";
import { Button, InputPicker, Modal, Tooltip, Whisper } from "rsuite";
import CustomInput from "../../../Components/Input/CustomInput";
import { fetchRole, roleData } from "../../../store/slices/roleSlice";
import axiosInstance from "../../../store/axiosInstance";
import IconButton from "../../../Components/Buttons/IconButton";
import {
  downloadCSV,
  exportToExcel,
  handlePDFDownload,
} from "../../../services/reusableFunc";
import { Column } from "../../../types/DataType";

interface DataRow {
  companyID: number;
  email: string;
  firstName: string;
  fullName: string;
  id: string;
  lastName: string;
  phoneNumber: string;
  role: string;
  userName: string;
}

const User = () => {
  const columns: TableColumn<DataRow>[] = [
    {
      name: "Priviledges",
      cell: (row) => (
        <>
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Manage User Priviledges</Tooltip>}
          >
            <Link
              to={`/app/user/claims/${row.id}`}
              className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
            >
              <MdOutlineManageAccounts className="text-base" />
            </Link>
          </Whisper>
        </>
      ),
    },
    {
      name: "Full Name",
      selector: (row) => row.fullName,
      sortable: true,
      cell: (row) => (
        <Link
          to={`/app/user/claims/${row.id}`}
          className="font-bold text-black overflow-hidden"
        >
          {row.fullName}
        </Link>
      ),
    },

    {
      name: "Role",
      selector: (row) => row.role,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-3 overflow-hidden cursor-pointer">
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Edit User</Tooltip>}
          >
            <button
              onClick={() => FetchUserDetails(row.id)}
              className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
            >
              <FaRegEdit className="text-base" />
            </button>
          </Whisper>
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Reset User Password</Tooltip>}
          >
            <button
              onClick={() => handleResetPass(row.email)}
              className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
            >
              <MdOutlineLockReset className="text-base" />
            </button>
          </Whisper>
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Delete User</Tooltip>}
          >
            <button
              onClick={() => delUser(row.id)}
              className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
            >
              <FaRegTrashAlt className="text-base text-red-500" />
            </button>
          </Whisper>
        </div>
      ),
    },
  ];

  const [loading1, setLoading1] = useState<boolean>(false);
  const [userRole, setUserRole] = useState<string>("");
  const [editUserModal, setEditUserModal] = useState<boolean>(false);
  const [addUserModal, setAddUserModal] = useState<boolean>(false);
  const [saveIds, setSaveIds] = useState<string>("");
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [resetPassModal, setResetPassModal] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();
  const companyId = user?.companyId;
  useEffect(() => {
    if (companyId) {
      dispatch(fetchUser(companyId));
      dispatch(fetchRole(companyId));
    }
  }, [dispatch, companyId]);
  const userDat = useSelector(userData);
  const roles = useSelector(roleData);
  const loading = useSelector(userLoading);

  const delUser = (id: string) => {
    setSaveIds(id);
    setDeleteModal(true);
  };

  const handleResetPass = (id: string) => {
    setSaveIds(id);
    setResetPassModal(true);
  };

  interface Props extends ExpanderComponentProps<DataRow> {
    // currently, props that extend ExpanderComponentProps must be set to optional.
    someTitleProp?: string;
  }

  const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
    return (
      <div className="p-2 flex flex-col gap-2 text-xs">
        <div>
          <span className="font-bold">Full Name: </span> {data.fullName}
        </div>
        <div>
          <span className="font-bold">Email: </span> {data.email}
        </div>
      </div>
    );
  };

  const [searchText, setSearchText] = useState<string>("");

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredData = userDat?.filter(
    (item) =>
      item?.fullName?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.email?.toLowerCase().includes(searchText.toLowerCase())
  );

  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const sanitizeInput = (name: string, value: string) => {
    switch (name) {
      case "phoneNumber": {
        // Allow only numeric characters (0-9)
        return value.replace(/[^0-9]/g, "");
      }
      case "firstName":
      case "lastName": {
        // Allow only alphabetic characters
        return value.replace(/[^a-zA-Z]/g, "");
      }
      default: {
        // For other fields, allow alphanumeric characters and specific special characters
        const allowedPattern =
          /^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\/\-=|"']+$/;
        return value.replace(
          new RegExp(`[^${allowedPattern.source}]`, "g"),
          ""
        );
      }
    }
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;

    setUserInfo({ ...userInfo, [name]: sanitizeInput(name, value) });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (
      userInfo.firstName.trim() === "" ||
      userInfo.lastName.trim() === "" ||
      userInfo.email.trim() === "" ||
      userInfo.phoneNumber.trim() === "" ||
      userRole.trim() === ""
    ) {
      toast.error("Marked Fields must be filled");
      return;
    }

    if (userInfo.confirmPassword !== userInfo.password) {
      toast.error("Password does not match");
      return;
    }
    setLoading1(true);

    const info = {
      ...userInfo,
      role: userRole,
    };

    try {
      const { data } = await axiosInstance.post(
        `/Account/add_user?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      setLoading1(false);
      companyId && dispatch(fetchUser(companyId));
      setAddUserModal(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
    } finally {
      setLoading1(false);
    }
  };

  const handleDelete = async (e: FormEvent) => {
    e.preventDefault();
    setLoading1(true);
    try {
      const { data } = await axiosInstance.get(
        `/Account/delete_user?id=${saveIds}&userId=${user?.uid}`
      );
      toast.success(data.message);
      setDeleteModal(false);
      companyId && dispatch(fetchUser(companyId));
      setLoading1(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
      setDeleteModal(false);
    } finally {
      setLoading1(false);
    }
  };

  const handleResetPassword = async (e: FormEvent) => {
    e.preventDefault();
    setLoading1(true);
    try {
      const { data } = await axiosInstance.get(
        `/Account/reset_user_password?userId=${user?.uid}&email=${saveIds}`
      );
      toast.success(data.message);
      setResetPassModal(false);
      companyId && dispatch(fetchUser(companyId));
      setLoading1(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
      setResetPassModal(false);
    } finally {
      setLoading1(false);
    }
  };

  const [userDetails, setUserDetails] = useState<DataRow>({
    firstName: "",
    email: "",
    userName: "",
    lastName: "",
    phoneNumber: "",
    companyID: 0,
    fullName: "",
    id: "",
    role: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const FetchUserDetails = async (id: string) => {
    setSaveIds(id);
    setEditUserModal(true);
    try {
      const { data } = await axiosInstance.get(
        `/Account/get_a_user?userId=${id}`
      );
      setUserDetails({ ...data });
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
    } finally {
      setLoading1(false);
    }
  };

  const HandleSaveEdit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading1(true);
    const info = {
      ...userDetails,
    };
    try {
      const { data } = await axiosInstance.post(
        `/Account/user_edit/${saveIds}?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      companyId && dispatch(fetchUser(companyId));
      setEditUserModal(false);
      setLoading1(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
    }
  };

  return (
    <>
      <SEO title="Users List" description="User" name="Promax-Care" />
      <Breadcrumb />
      <div className="mt-10">
        {userDat.length <= 0 && loading && <Spinner color="#071952" />}

        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
          <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
            <h2 className="font-bold text-xl text-black w-auto">All Users</h2>

            <div className="relative w-full lg:col-span-2">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <BsSearch />
              </div>
              <input
                type="text"
                id="simple-search"
                onChange={handleSearch}
                className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Search By FullName or Email"
              />
            </div>
          </div>
          <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
            <div className="flex items-center space-x-3 w-full md:w-auto">
              {/* <button id="actionsDropdownButton" className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                                <FaFilter />
                                Filter
                            </button> */}
              <ExportDropdown>
                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <IconButton
                    icon={<FaFilePdf className="text-red-500" />}
                    title="Export as PDF"
                    onClick={() =>
                      handlePDFDownload(
                        userDat,
                        columns as unknown as Column<DataRow>[],
                        "UserList.pdf"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileExcel className="text-green-600" />}
                    title="Export as Excel Sheet"
                    onClick={() =>
                      exportToExcel(
                        userDat,
                        columns as unknown as Column<DataRow>[],
                        "UserList"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileCsv className="text-green-500" />}
                    title="Export as CSV"
                    onClick={() =>
                      downloadCSV({ data: userDat, filename: "UserList.csv" })
                    }
                  />
                </div>
              </ExportDropdown>
            </div>
            <button
              onClick={() => setAddUserModal(true)}
              type="button"
              className="px-4 py-2 bg-primary text-white  disabled:bg-slate-400
                            transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm 
                           "
            >
              Add New User
            </button>
          </div>
        </div>

        <DataTable
          data={filteredData}
          columns={columns}
          expandableRows
          expandableRowsComponent={ExpandableRowComponent}
          pagination
          paginationTotalRows={userDat?.length}
          // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}
        />
      </div>

      <Modal
        open={addUserModal}
        onClose={() => setAddUserModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Add New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form action="" onSubmit={handleSubmit}>
              <div className="p-2 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
                <CustomInput
                  label="First Name"
                  type="text"
                  value={userInfo.firstName}
                  name="firstName"
                  onChange={handleChange}
                  required
                  placeholder="First Name"
                  autoComplete="off"
                />

                <CustomInput
                  label="Last Name"
                  type="text"
                  value={userInfo.lastName}
                  name="lastName"
                  onChange={handleChange}
                  required
                  placeholder="Last Name"
                  autoComplete="off"
                />

                <CustomInput
                  label="Email"
                  type="email"
                  value={userInfo.email}
                  name="email"
                  onChange={handleChange}
                  required
                  placeholder="Email"
                  autoComplete="off"
                />

                <CustomInput
                  label="Phone Number"
                  type="tel"
                  value={userInfo.phoneNumber}
                  name="phoneNumber"
                  onChange={handleChange}
                  required
                  placeholder="Phone Number"
                  autoComplete="off"
                />

                <CustomInput
                  label="Password"
                  type="password"
                  showPasswordIcon
                  value={userInfo.password}
                  name="password"
                  onChange={handleChange}
                  placeholder="Password"
                  required
                  minLength={6}
                  autoComplete="off"
                />

                <CustomInput
                  label="Confirm Password"
                  type="password"
                  value={userInfo.confirmPassword}
                  name="confirmPassword"
                  onChange={handleChange}
                  showPasswordIcon
                  placeholder="Confirm Password"
                  required
                  minLength={6}
                  autoComplete="off"
                />
                <div className="lg:col-span-2">
                  <label className=" text-xs text-gray-600 font-semibold">
                    Role <span className="text-red-500">*</span>
                  </label>
                  <InputPicker
                    size="lg"
                    placeholder="--Select a role--"
                    onChange={(value) => setUserRole(value)}
                    data={roles.map((role) => ({
                      label: role.role,
                      value: role.role,
                    }))}
                    className="w-full focus:outline-none focus:border-primary text-gray-600"
                    appearance="subtle"
                  />
                </div>
              </div>
              <div className="mx-auto text-center mt-3 lg:col-span-2">
                <button
                  type="submit"
                  disabled={loading1 ? true : false}
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                    font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                    dark:focus:ring-primary"
                >
                  {loading1 ? <Spinner /> : "Create"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="mb-4 font-bold">
              Are you sure you want to delete this user?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <Button
                onClick={handleDelete}
                className="bg-red-500 text-white hover:bg-red-700 hover:text-white"
              >
                {loading1 ? <Spinner /> : "Yes, I'm sure"}
              </Button>
              <Button
                onClick={() => setDeleteModal(false)}
                className="bg-gray-500 text-white"
              >
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={resetPassModal}
        onClose={() => setResetPassModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Reset User Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="mb-4 font-bold">
              Are you sure you want to reset this user Password?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <Button
                onClick={handleResetPassword}
                className="bg-red-500 text-white hover:bg-red-700 hover:text-white"
              >
                {loading1 ? <Spinner /> : "Yes, I'm sure"}
              </Button>
              <Button
                onClick={() => setResetPassModal(false)}
                className="bg-gray-500 text-white"
              >
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={editUserModal}
        onClose={() => setEditUserModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Edit User Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form action="" className="space-y-2">
              <div className="p-2 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
                <CustomInput
                  label="First Name"
                  type="text"
                  name="firstName"
                  value={userDetails?.firstName}
                  onChange={handleInputChange}
                />

                <CustomInput
                  label="Last Name"
                  type="text"
                  name="lastName"
                  value={userDetails?.lastName}
                  onChange={handleInputChange}
                />

                <CustomInput
                  label="Email"
                  type="email"
                  name="email"
                  value={userDetails?.email}
                  onChange={handleInputChange}
                />

                <CustomInput
                  label="Phone Number"
                  type="text"
                  name="phoneNumber"
                  value={userDetails?.phoneNumber}
                  onChange={handleInputChange}
                />
              </div>

              <div className="mx-auto text-center mt-3">
                <button
                  type="submit"
                  onClick={HandleSaveEdit}
                  disabled={loading1 ? true : false}
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                >
                  {loading1 ? <Spinner /> : "Update"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default User;
