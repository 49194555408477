import React from "react";
import { ParticipantForm } from "../../../types/DataType";
interface Props {
    // dietary_Needs: React.RefObject<HTMLTableCellElement>
    // mobility_Needs: React.RefObject<HTMLTableCellElement>
    // communication_Preferences: React.RefObject<HTMLTableCellElement>
    // medication: React.RefObject<HTMLTableCellElement>
    participantForm: ParticipantForm | null;
    handleInputDataChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
}

const EditSummarySupport = ({ handleInputDataChange, participantForm }: Props) => {
    return (
        <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border mt-9 border-gray-300">
            <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
                <thead className="ltr:text-left rtl:text-right bg-[#b9b9b95e]">
                    <tr className="divide-x-2">
                        <th className=" px-4 py-2 font-semibold text-gray-900">
                            Summary of support needs and preferences
                        </th>
                        <th className=" px-4 divide-x-2 py-2 font-medium text-gray-900"></th>
                    </tr>
                </thead>

                <tbody className="divide-y divide-gray-200">
                    <tr className="divide-x-2">
                        <td className=" px-4 py-2 font-medium text-gray-900">
                            Dietary and health needs:
                        </td>
                        {/* <td className=" px-4 py-2 text-gray-700 w-96 outline-none break-words"
                            defaultValue={participantForm?.communication_Preferences}
                            onChange={handleInputDataChange}
                            contentEditable></td> */}
                        <input
                            type="text"
                            name="dietary_Needs"
                            value={participantForm?.dietary_Needs}
                            onChange={handleInputDataChange}
                            className="px-4 py-2 text-gray-700 w-96 outline-none break-words"
                        />
                    </tr>

                    <tr className="divide-x-2">
                        <td className=" px-4 py-2 font-medium text-gray-900">
                            Mobility needs:
                        </td>
                        {/* <td className=" px-4 py-2 text-gray-700 w-96 outline-none break-words"
                            ref={mobility_Needs}
                            contentEditable></td> */}
                        <input
                            type="text"
                            name="mobility_Needs"
                            value={participantForm?.mobility_Needs}
                            onChange={handleInputDataChange}
                            className="px-4 py-2 text-gray-700 w-96 outline-none break-words"
                        />
                    </tr>

                    <tr className="divide-x-2">
                        <td className=" px-4 py-2 font-medium text-gray-900">
                            Communication preferences:
                        </td>
                        {/* <td className=" px-4 py-2 text-gray-700 w-96 outline-none break-words"
                            ref={communication_Preferences}
                            contentEditable></td> */}
                        <input
                            type="text"
                            name="communication_Preferences"
                            value={participantForm?.communication_Preferences}
                            onChange={handleInputDataChange}
                            className="px-4 py-2 text-gray-700 w-96 outline-none break-words"
                        />
                    </tr>
                    <tr className="divide-x-2">
                        <td className=" px-4 py-2 font-medium text-gray-900">
                            Medications:
                        </td>
                        {/* <td className=" px-4 py-2 text-gray-700 w-96 outline-none break-words"
                            ref={medication}
                            contentEditable></td> */}
                        <input
                            type="text"
                            name="medication"
                            value={participantForm?.medication}
                            onChange={handleInputDataChange}
                            className="px-4 py-2 text-gray-700 w-96 outline-none break-words"
                        />
                    </tr>

                </tbody>
            </table>
        </div>
    );
};

export default EditSummarySupport;
