import React from 'react'
type LinkTitleProps = {
    title: string;
    open: boolean
}
const LinkTitle = ({ open, title }: LinkTitleProps) => {
    return (
        <li className={`text-gray-300 gap-x-4 cursor-pointer p-2 font-medium text-sm ${!open && 'hidden'}`}>
            {title}
        </li>
    )
}

export default LinkTitle