import React from "react";
import Contact from "./Contact";
import Consent from "./Consent";
import Box2 from "./Box2";
import Form2 from "./Form2";
import { CompanyDetail } from "../../../types/AuthData";

interface SectionProps {
  formDataConsent: {
    consentAudioVisual: boolean;
    consentFeedback: boolean;
  }
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  companyOne: CompanyDetail | null | undefined;
  formDataBox: {
    consentInfoCollection: boolean;
    consentPhotoId: boolean;
  }
  handleCheckboxChangeBox: (e: React.ChangeEvent<HTMLInputElement>) => void;
  formDataShare: {
    consentShareInfo: boolean;
    noConsentShareInfo: boolean;
  }
  handleRadioChangeShare: (e: React.ChangeEvent<HTMLInputElement>) => void;
  alertValues: string[];
  handleInputChange: (index: number, value: string) => void;
  handleAddRow: () => void;
  notPerson: string[];
  handleInputNotChange: (index: number, value: string) => void;
  handleAddNotRow: () => void;
}

const Section: React.FC<SectionProps> = ({ formDataConsent, handleCheckboxChange, handleRadioChangeShare, notPerson,
  companyOne, formDataBox, formDataShare, handleCheckboxChangeBox, handleInputNotChange, handleAddNotRow,
  alertValues, handleInputChange, handleAddRow }) => {
  return (
    <div className="mt-5">
      <strong className="italic">
        Please tick the following boxes to indicate where you give your consent
      </strong>
      <Consent formDataConsent={formDataConsent} handleCheckboxChange={handleCheckboxChange} />

      <Box2 companyOne={companyOne} formDataBox={formDataBox} handleCheckboxChangeBox={handleCheckboxChangeBox} />

      <div className=" p-4 max-[1024px]:w-[100%]  my-5 border border-black ">
        <p className="text-[16px] mb-3">
          Consent for us to share information about you to other providers or
          individuals
        </p>
        <div className="flex items-center gap-3 mt-4">
          <input
            type="radio"
            id="consentShareInfo"
            name="consentOption"
            value="consentShareInfo"
            checked={formDataShare.consentShareInfo}
            onChange={handleRadioChangeShare}
          />
          <label htmlFor="consentShareInfo" className="text-[16px]">
            <strong>I give consent</strong> for {companyOne?.companyName} to share my
            information with the following people or services. I understand that
            sharing information may include discussing my support needs, sharing
            support plans and reviewing any incidents that have occurred.
          </label>
        </div>
        <Contact alertValues={alertValues} handleAddRow={handleAddRow} handleInputChange={handleInputChange} />

        <div className="flex items-center gap-3 mt-4">
          <input
            type="radio"
            id="noConsentShareInfo"
            name="consentOption"
            value="noConsentShareInfo"
            checked={formDataShare.noConsentShareInfo}
            onChange={handleRadioChangeShare}
          />
          <label htmlFor="noConsentShareInfo" className="text-[16px]">
            <strong>I do not give consent</strong> for {companyOne?.companyName} to share my
            information with the following people or services:
          </label>
        </div>
        <Form2 alertValues={notPerson} handleAddRow={handleAddNotRow} handleInputChange={handleInputNotChange} />
        <br />
        <br />
        <strong className="my-5">
          Note that may have to disclose some information without your permission when we are required to by law. For example, when we need to ensure your safety or that of others, or when directed to do so by a court of law.
        </strong>
      </div>
    </div>
  );
};

export default Section;
