import React from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import { Client } from "../../../store/slices/clientSlice";
import DisabilitySupport from "../../Admin/Profiles/clientTab/DisabilitySupport";

const DisableNeeds = () => {
    const clientProfileString = localStorage.getItem("clientProfile");
    const clientProfile: Client = clientProfileString
        ? JSON.parse(clientProfileString)
        : null;

    return (
        <>
            <SEO title={` Disability Needs`} description="Client" name="Promax-Care" />
            <Breadcrumb showNavigation={false} />

            <div>
                <DisabilitySupport uid={clientProfile.profileId.toString()} />
            </div>

        </>
    );
};

export default DisableNeeds;
