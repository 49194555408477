import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

export interface UserTicket {
  ticketId: number;
  ticketType: string;
  subject: string;
  description: string;
  imageURL: string;
  imageFile: string;
  user: string;
  isOpen: boolean;
  isClosed: boolean;
  excalate: boolean;
  companyId: number;
  company: {
    companyId: number;
    companyName: string;
    companyEmail: string;
    companyAddress: string;
    companyPhone: string;
    companyState: string;
    companyDetails: string;
    companyLogo: string;
    companyLogoFile: string;
    companyHead: string;
    isApproved: boolean;
    packagesId: number;
    packages: string;
    subscribtionDate: string;
    expirationDate: string;
    dateCreated: string;
    userCreated: string;
    dateModified: string;
    userModified: string;
  };
  dateCreated: string;
  userCreated: string;
  dateModified: string;
  userModified: string;
}

export interface UserTicketState {
  loading: boolean;
  data: Array<UserTicket>;
  error: string | undefined;
}
const initialState: UserTicketState = {
  loading: false,
  data: [],
  error: undefined,
};
export const fetchUserTicket = createAsyncThunk(
  "UserTicket/fetchUserTicket",
  async (uid: string) => {
    try {
      const response = await api.fetchUserTicket(uid);

      return response;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

const userTicketSlice = createSlice({
  name: "userTicket",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchUserTicket.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchUserTicket.fulfilled,
      (state, action: PayloadAction<Array<UserTicket>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(fetchUserTicket.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {},
});
export const userSelector = (state: RootState) => state.userTicket;
export default userTicketSlice.reducer;

// Define selectors to access the profile slice state
export const userTicketData = (state: RootState) => state.userTicket.data;
export const userTicketLoading = (state: RootState) => state.userTicket.loading;
export const userTicketError = (state: RootState) => state.userTicket.error;
