import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../api";
import { isAxiosError } from "axios";
import { RootState } from "../../store";

export interface ClientRepType {
    representativeId: number;
    profileId: number;
    relationship: string;
    fullName: string;
    homePhone: string;
    mobilePhone: string;
    email: string;
    position: string;
    address: string;
    organization: string;
}

export interface ClientRepState {
    loading: boolean;
    data: Array<ClientRepType>;
    error: string | undefined;
}
const initialState: ClientRepState = {
    loading: false,
    data: [],
    error: undefined,
};
export const fetchClientRep = createAsyncThunk(
    "ClientRep/fetchClientRep",
    async (clientId: number) => {
        try {
            const response = await api.fetchClientRep(clientId);
            return response;
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                console.log(error);
            } else {
                console.log(error);
            }
        }
    }
);

const clientRepreSlice = createSlice({
    name: "clientReps",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchClientRep.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            fetchClientRep.fulfilled,
            (state, action: PayloadAction<Array<ClientRepType>>) => {
                state.loading = false;
                state.data = action.payload;
            }
        );
        builder.addCase(fetchClientRep.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
    reducers: {},
});
export const userSelector = (state: RootState) => state.clientReps;
export default clientRepreSlice.reducer;

export const clientRepsData = (state: RootState) => state.clientReps.data;
export const clientRepsLoading = (state: RootState) => state.clientReps.loading;
export const clientRepsError = (state: RootState) => state.clientReps.error;
