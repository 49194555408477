import React from 'react'
import dayjs from 'dayjs'
import { ClientShift } from '../../../../store/slices/clientShiftSlice';
import { Tooltip, Whisper } from 'rsuite';
import { FaRegEdit } from 'react-icons/fa';
import { MdLibraryAdd } from 'react-icons/md';
import { ImCancelCircle } from 'react-icons/im';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

interface Props {
    activity: ClientShift;
    handleEdit: (shiftId: number) => void;
    requestCancel: (shiftId: number) => Promise<void>;
    addAppointMent: (shiftId: number) => Promise<void>;
}

const TodayShift = ({ activity, handleEdit, requestCancel, addAppointMent }: Props) => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.tz.setDefault("Australia/Sydney");

    return (
        <div className="border-2 rounded-2xl bg-white shadow" >
            <div className="bg-blue-50 text-center p-2 flex justify-between items-center text-sm font-semibold rounded-t-xl ">
                <span>
                    Care Shift
                </span>{" "}
                <span className={`rounded p-1 text-xs !capitalize `}>
                    {dayjs(activity.dateFrom).format('dddd, MMMM D, YYYY')}
                </span>
            </div>
            <div className="flex gap-2 flex-col   px-2 py-4 overflow-y-auto">
                <div className='text-sm flex gap-10 items-center truncate ...'>
                    <span className='font-bold'>Staff:</span>
                    <span
                        className='font-bold'
                    >{activity.staff.fullName}</span>
                </div>
                <div className='text-sm flex gap-10 items-center'>
                    <span className='font-bold'>Start Time:</span>
                    <span >{dayjs(activity?.dateFrom).format('hh:mm A')}</span>
                </div>
                <div className='text-sm flex gap-10 items-center'>
                    <span className='font-bold'>End Time:</span>
                    <span >{dayjs(activity?.dateTo).format('hh:mm A')}</span>
                </div>
            </div>
            <div className='p-2 font-bold bg-gray-100'>
                <span >Care Appointment</span>
            </div>
            <div className='p-2'>
                <div className='p-2 border border-dotted h-20'>
                    <span>{activity.appointment ? (activity.appointment).substring(0, 120) : <span>No Appointment at the moment</span>}</span>
                </div>
            </div>
            <div className='relative'>
                <div className='p-2 font-bold bg-gray-100'>
                    <span>Activities</span>
                </div>
                <div className='p-2'>
                    <ul className="space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
                        {activity.activities.split(',').map((activity, index) => (
                            <li key={index}>
                                {activity.trim()} {/* Trimming to remove any extra spaces */}
                            </li>
                        ))}
                    </ul>
                </div>
                {activity.status === 'Cancelled' && <div className="flex justify-end absolute bottom-0 right-0">

                    <span className="text-[10px] font-normal bg-red-500 text-white rounded-es-md p-0.5 mb-2">Shift Canlled</span>
                </div>}
            </div>

            {activity.status !== 'Cancelled' && <div>
                {
                    dayjs(activity.dateTo).format('YYYY-MM-DD HH:mm:ss') >
                        dayjs().tz().format('YYYY-MM-DD HH:mm:ss') ? (
                        <div className="flex gap-2 justify-center text-center mt-3 p-2">

                            <Whisper
                                placement="top"
                                controlId="control-id-hover"
                                trigger="hover"
                                speaker={<Tooltip>Edit Care Activities</Tooltip>}
                            >
                                <button
                                    onClick={() => handleEdit(activity.shiftRosterId)}
                                    className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
                                >
                                    <FaRegEdit className="text-base" />
                                </button>
                            </Whisper>

                            <Whisper
                                placement="top"
                                controlId="control-id-hover"
                                trigger="hover"
                                speaker={<Tooltip>Add Appointment</Tooltip>}
                            >
                                <button
                                    onClick={() => addAppointMent(activity.shiftRosterId)}
                                    className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
                                >
                                    <MdLibraryAdd className="text-base" />
                                </button>
                            </Whisper>

                            <Whisper
                                placement="top"
                                controlId="control-id-hover"
                                trigger="hover"
                                speaker={<Tooltip>Cancel shift</Tooltip>}
                            >
                                <button
                                    onClick={() => requestCancel(activity?.shiftRosterId)}
                                    className="flex justify-between items-center px-4 py-2 border rounded border-white bg-red-500 text-white no-underline hover:no-underline"
                                >
                                    <ImCancelCircle className="text-base" />
                                </button>
                            </Whisper>
                        </div>) : null}
            </div>}
        </div>
    )
}

export default TodayShift