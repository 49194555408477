import React from 'react';
import { Checkboxes } from '..';

interface Props {
  checkboxes: Checkboxes;
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Active = ({ handleCheckboxChange, checkboxes }: Props) => {

  return (
    <div>
      <p className="mt-5 text-black">
        <span className="underline">Active files</span> – tick to indicate the document has been sighted and is current:
      </p>
      <div>
        {[
          { id: 'sign', label: 'Signed service agreement' },
          { id: 'consent', label: 'Consent Form' },
          { id: 'support', label: 'Support plan' },
          { id: 'safety', label: 'Safety Plan' },
          { id: 'manage', label: 'Medication Management Plan (if applicable)' },
          { id: 'chart', label: 'Medication Chart (if applicable)' },
          { id: 'plan', label: 'Other supporting plans – refer table below' }
        ].map(({ id, label }) => (
          <p key={id} className="flex gap-2 items-center my-3 text-black">
            <input type="checkbox" id={id} checked={checkboxes[id]} onChange={handleCheckboxChange} />
            <label htmlFor={id}>{label}</label>
          </p>
        ))}
      </div>
    </div>
  );
};

export default Active;

