import React, { FormEvent, useEffect, useRef, useState } from "react";
import Breadcrumb from "../../Components/label/BreadCrumb";
import PrintComponent from "../PrintTab";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchClientInfo,
  fetchCompany,
  fetchSupportRoutinedoc,
} from "../../services/fetchServices";
import { CompanyDetail } from "../../types/AuthData";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Client } from "../../store/slices/clientSlice";
import Spinner from "../../Components/Spinner";
import axiosInstance from "../../store/axiosInstance";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import { SupportRoutineData } from "../../types/DataType";
import Hero from "./_component/Hero";
import Main from "./_component/Main";
import EditSupportRoutine from "./EditSupportRoutine";

export interface RowData {
  days: string;
  time: string;
  ndisGoal: string;
  supportProvided: string;
}

const SupportRoutine = () => {
  const { uid } = useParams();
  const [clientDetail, setClientDetail] = useState<Client | null>(null);
  const [loadingClient, setLoadingClient] = useState<boolean>(true);
  const user = useSelector((state: RootState) => state?.auth?.user);
  const [companyOne, setCompanyOne] = useState<
    CompanyDetail | null | undefined
  >(null);
  const [checker, setChecker] = useState<SupportRoutineData | null>(null);

  useEffect(() => {
    fetchClientData();
    handleCompanyFetch();
    fetchDoc();
  }, [uid, clientDetail?.careManager]);

  const handleCompanyFetch = async () => {
    // setLoading(true);
    if (user?.companyId) {
      const data = await fetchCompany(user?.companyId);
      setCompanyOne(data);
    }
    // setLoading(false);
  };
  const fetchClientData = async () => {
    const data = await fetchClientInfo(uid);
    setClientDetail(data);
    setLoadingClient(false);
  };

  const fetchDoc = async () => {
    const data = await fetchSupportRoutinedoc(Number(uid));
    setChecker(data);
  };

  const componentRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // Initialize state as an array of RowData objects
  const [alertValues, setAlertValues] = useState<RowData[]>([
    { days: "", time: "", ndisGoal: "", supportProvided: "" },
  ]);

  // Handle adding a new row
  const handleAddRow = () => {
    setAlertValues([
      ...alertValues,
      { days: "", time: "", ndisGoal: "", supportProvided: "" },
    ]);
  };

  // Handle input changes for specific fields in a row
  const handleInputChange = (
    index: number,
    field: keyof RowData,
    value: string
  ) => {
    const newValues = [...alertValues];
    newValues[index][field] = value;
    setAlertValues(newValues);
  };

  const navigate = useNavigate();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    setLoading(true); // Start loading before the loop

    try {
      // Loop through each row and send the data
      for (const row of alertValues) {
        const info = {
          companyId: user?.companyId,
          profileId: uid,
          days: row.days,
          time: row.time,
          ndisGoal: row.ndisGoal,
          supportProvided: row.supportProvided,
        };

        // Send the data to the endpoint
        await axiosInstance.post(`/SupportRoutines/add_support_routine`, info);
      }

      toast.success("Record Created Successfully");
      navigate(
        `/app/user/clients/${clientDetail?.profileId}/${clientDetail?.firstName}`
      );
    } catch (error: unknown) {
      console.log(error);
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      } else {
        toast.error("An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Breadcrumb />
      {loadingClient && (
        <div className="fixed  inset-0 z-[9999]  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-auto max-w-lg mx-auto">
            <div
              className="relative z-[999] flex justify-center items-center p-4  duration-500 ease-in-out bg-white
border-0 rounded-lg shadow-lg outline-none focus:outline-none"
            >
              <Spinner color="#071952" />
            </div>
          </div>

          <div className="fixed inset-0 z-40 bg-gray-300 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
        </div>
      )}

      {checker ? (
        <EditSupportRoutine companyOne={companyOne} />
      ) : (
        <form onSubmit={handleSubmit}>
          <PrintComponent
            componentRef={componentRef}
            triggerLabel="Print / Download"
            documentTitle="Support Routine"
          />
          <div
            ref={componentRef}
            className="mx-auto  max-w-3xl p-4 md:p-4 lg:p-8 bg-white"
          >
            <Hero companyOne={companyOne} />

            <Main
              alertValues={alertValues}
              handleAddRow={handleAddRow}
              handleInputChange={handleInputChange}
            />

            <div className="mx-auto text-center mt-3 lg:col-span-2">
              <button
                type="submit"
                disabled={loading}
                className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"
              >
                {loading ? <Spinner /> : " Submit"}
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default SupportRoutine;
