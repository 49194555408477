import { isAxiosError } from 'axios';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { FaPlus } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from '../../../Components/Spinner';
import axiosInstance from '../../../store/axiosInstance';
import { Client } from '../../../store/slices/clientSlice';
import { Staff } from '../../../store/slices/staffSlice';
import { RootState } from '../../../store/store';
import { SupportPlanReviewData } from '../../../types/DataType';
import EditDate from './EditDate';

interface Props {
    editAttendee: string[];
    handleInputEditChange: (index: number, event: React.ChangeEvent<HTMLInputElement>) => void;
    handleAddRowEdit: () => void;
    clientDetail: Client | null;
    careManager: Staff | null;
    handleInputDataEditChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    planReviewForm: SupportPlanReviewData;
}


interface Row {
    workingWell: string;
    notWorkingWell: string;
}

const EditSupportPlanReview: React.FC<Props> = ({ editAttendee, handleAddRowEdit, planReviewForm, handleInputDataEditChange,
    handleInputEditChange, careManager, clientDetail }) => {

    const [rows, setRows] = useState<Row[]>([]);
    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {
        const initialWorkingWell = planReviewForm.workingWell.split(',');
        const initialNotWorkingWell = planReviewForm.notWorkingWell.split(',');
        const rowCount = 5; // Default to 5 rows

        const initialRows = Array.from({ length: rowCount }).map((_, index) => ({
            workingWell: initialWorkingWell[index] || '', // Use the value or an empty string if undefined
            notWorkingWell: initialNotWorkingWell[index] || '' // Use the value or an empty string if undefined
        }));

        setRows(initialRows);
    }, [planReviewForm]);

    const user = useSelector((state: RootState) => state?.auth?.user);
    const handleCellChange = (rowIndex: number, cellName: keyof Row, value: string) => {
        const updatedRows = rows.map((row, index) =>
            index === rowIndex ? { ...row, [cellName]: value } : row
        );
        setRows(updatedRows);
    };

    const getWorkingWellValues = () => {
        return rows.map(row => row.workingWell).join(', ');
    };

    const getNotWorkingWellValues = () => {
        return rows.map(row => row.notWorkingWell).join(', ');
    };

    const navigate = useNavigate()

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const workingWellString = getWorkingWellValues();
        const notWorkingWellString = getNotWorkingWellValues();

        const info = {
            supportPlanReviewId: planReviewForm.supportPlanReviewId,
            attendees: editAttendee.join(", "),
            meetingDate: planReviewForm.meetingDate,
            supportPlanDate: planReviewForm.supportPlanDate,
            workingWell: workingWellString,
            notWorkingWell: notWorkingWellString,
            achievedGoals: planReviewForm.achievedGoals,
            goalsInProgress: planReviewForm.goalsInProgress,
            newGoals: planReviewForm.newGoals,
            profileId: planReviewForm.profileId,
            document_Name: "PCD-109 P10 Support plan review",
            implementationDate: planReviewForm.implementationDate,
            expirationDate: planReviewForm.expirationDate,
            companyId: planReviewForm.companyId
        }

        try {
            setLoading(true);
            const { data } = await axiosInstance.post(
                `/SupportPlanReviews/edit/${planReviewForm.supportPlanReviewId}?userId=${user?.uid}`,
                info
            );
            toast.success(data.message);
            setLoading(false);
            navigate(`/app/user/clients/${clientDetail?.profileId}/${clientDetail?.firstName}`)
        } catch (error: unknown) {

            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="p-4">
            <div className='flex justify-between items-center'>
                <h1 className="text-2xl font-bold mb-4">Support plan review meeting</h1>
                {/* <img src="/logo192.png" alt="" /> */}
            </div>
            <table className="w-full border-collapse">
                <tbody>
                    <tr>
                        <td className="border p-2">Meeting date:</td>
                        <td className="border p-2">
                            <input
                                type="date"
                                className="w-full outline-none"
                                name="meetingDate"
                                value={planReviewForm.meetingDate}
                                onChange={handleInputDataEditChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-2">Attendees:</td>
                        {editAttendee.map((value, index) => (
                            <td key={index} className="whitespace-nowrap border flex gap-3 px-4 py-2 text-gray-700 w-[100%] outline-none">
                                <span>{index + 1}.</span>
                                <input
                                    className="w-[100%] h-[20px] outline-none"
                                    type="text"
                                    required
                                    value={value}
                                    onChange={(event) => handleInputEditChange(index, event)}
                                />
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <td className=""></td>
                        <td className="flex justify-end">
                            <button
                                type="button"
                                onClick={handleAddRowEdit}
                                className="mt-4 w-10 h-10  bg-gray-200 text-white rounded-full flex justify-center items-center"
                            >
                                <FaPlus className="text-lg text-black" />
                            </button>
                        </td>
                    </tr>

                    <tr>
                        <td className="border p-2">Participant details</td>
                        <td className="border p-2"></td>
                    </tr>
                    <tr>
                        <td className="border p-2">Last name:</td>
                        <td className="border p-2">{clientDetail?.surName}</td>
                    </tr>
                    <tr>
                        <td className="border p-2">First name:</td>
                        <td className="border p-2">{clientDetail?.firstName}</td>
                    </tr>
                    <tr>
                        <td className="border p-2">Preferred name:</td>
                        <td className="border p-2">{clientDetail?.middleName}
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-2">Date of birth:</td>
                        <td className="border p-2">{clientDetail?.dateOfBirth}</td>
                    </tr>
                    <tr>
                        <td className="border p-2">Date of support plan:</td>
                        <td className="border p-2">
                            <input
                                type="date"
                                name="supportPlanDate"
                                value={planReviewForm.supportPlanDate}
                                onChange={handleInputDataEditChange}
                                className="w-full outline-none"
                            />
                        </td>
                    </tr>
                    <tr className=' bg-gray-200 font-semibold'>
                        <td className="border p-2">What’s working well with the plan?</td>
                        <td className="border p-2">What’s not working well with the plan?</td>
                    </tr>
                    {rows.map((row, index) => (
                        <tr key={index} className="divide-x-2">
                            <td className="whitespace-nowrap border p-2 text-gray-700 w-[40%]">
                                <input
                                    type="text"
                                    value={row.workingWell}
                                    onChange={(e) => handleCellChange(index, "workingWell", e.target.value)}
                                    className="border-none text-gray-900 text-sm outline-none block w-full bg-white h-full px-2.5"
                                />
                            </td>
                            <td className="whitespace-nowrap border p-2 text-gray-700 w-full">
                                <input
                                    type="text"
                                    value={row.notWorkingWell}
                                    onChange={(e) => handleCellChange(index, "notWorkingWell", e.target.value)}
                                    className="border-none text-gray-900 text-sm outline-none block w-full bg-white h-full px-2.5"
                                />
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td className="border p-2">Goals I have achieved:</td>
                        <td className="border p-2 flex gap-2 ">
                            <input
                                className="w-[100%] h-[20px] outline-none"
                                type="text"
                                name="achievedGoals"
                                value={planReviewForm.achievedGoals}
                                onChange={handleInputDataEditChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-2">Goals still in progress:</td>
                        <td className="border p-2 flex gap-2 ">
                            <input
                                className="w-[100%] h-[20px] outline-none"
                                type="text"
                                name="goalsInProgress"
                                value={planReviewForm.goalsInProgress}
                                onChange={handleInputDataEditChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-2">New goals I want to achieve:</td>
                        <td className="border p-2 flex gap-2 ">
                            <input
                                className="w-[100%] h-[20px] outline-none"
                                type="text"
                                name="newGoals"
                                value={planReviewForm.newGoals}
                                onChange={handleInputDataEditChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-2" colSpan={2}>
                            <i>If changes are required to the support plan please complete a new Support Plan template and mark the previous plan as ‘superseded’.</i>
                        </td>
                    </tr>
                    <strong className='mt-8 text-[16px]'>Agreements</strong>
                    <tr>
                        <td className="border p-2 font-semibold">Participant or representative</td>
                        <td className="border p-2 font-semibold">Provider representative</td>
                    </tr>
                    <tr>
                        <td className="border p-2" >Name: {clientDetail?.fullName}</td>
                        <td className="border p-2" >Name: {careManager?.fullName}</td>
                    </tr>

                    <tr>
                        <td className="border p-2" >Signature: {clientDetail?.signature && <div>
                            {clientDetail?.signature && (
                                <img
                                    src={clientDetail?.signature}
                                    alt="Participant Signature"
                                    className="img-thumbnail block"
                                    style={{
                                        display: "block",
                                        width: "150px",
                                    }}
                                />
                            )}
                        </div>}</td>
                        <td className="border p-2" >Signature: {careManager?.signatureUrl && <div>
                            {careManager?.signatureUrl && (
                                <img
                                    src={careManager?.signatureUrl}
                                    alt="Rep Signature"
                                    className="img-thumbnail block"
                                    style={{
                                        display: "block",
                                        width: "150px",
                                    }}
                                />
                            )}
                        </div>}</td>
                    </tr>
                    <tr>
                        <td className="border p-2" >Date of birth: {clientDetail?.dateOfBirth}</td>
                        <td className="border p-2" >Date of birth: {careManager?.dateOfBirth}</td>
                    </tr>
                </tbody>
            </table>

            <EditDate
                planReviewForm={planReviewForm}
                handleInputDataEditChange={handleInputDataEditChange}
            />
            <div className="mx-auto text-center mt-3 lg:col-span-2">
                <button
                    type="button"
                    onClick={handleSubmit}
                    disabled={loading}
                    className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"
                >
                    {loading ? <Spinner /> : "Update"}
                </button>
            </div>
        </div>
    );
};

export default EditSupportPlanReview;

