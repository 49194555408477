import React, { useEffect, useState, useRef } from "react";
import SEO from "../../../../../constants/SEO";
import Breadcrumb from "../../../../../Components/label/BreadCrumb";
import { useNavigate, useParams } from "react-router-dom";
import { fetchTicketDetails } from "../../../../../services/fetchServices";
import { Ticket } from "../../../../../store/slices/ticketSlice";
import avatar from "../../../../../images/user/man.png";
import dayjs from "dayjs";
import parse from "html-react-parser";
import { TicketReplyType } from "../../../../../types/DataType";
import axiosInstance from "../../../../../store/axiosInstance";
import { RootState } from "../../../../../store/store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Spinner from "../../../../../Components/Spinner";
import { ConfirmModal } from "../../../RosteringManagement/ShiftRoster/components/ConfirmModal";
import { isAxiosError } from "axios";
import { Modal } from "rsuite";
import Editor from "../../../../../Components/Editor/ReactQuill";
import CustomInput from "../../../../../Components/Input/CustomInput";

const TicketDetails = () => {
  const { uid } = useParams();
  const user = useSelector((state: RootState) => state?.auth?.user);
  const navigate = useNavigate();
  const [ticketDetail, setTicketDetail] = useState<Ticket | null>(null);
  const [ticketReply, setTicketReply] = useState<TicketReplyType[]>([]);
  const [loadingData, setLoadingData] = useState(true);

  const handleFetchDetails = async () => {
    const data = await fetchTicketDetails(uid as string);
    setTicketDetail(data.ticket);
    setTicketReply(data.ticketReplies);
    setLoadingData(false);
  };

  useEffect(() => {
    handleFetchDetails();
  }, [uid]);

  const [loading2, setLoading2] = useState(false);
  const handleEscalate = async () => {
    setLoading2(true);
    try {
      const { data } = await axiosInstance.get(
        `/Tickets/excalate/${ticketDetail?.ticketId}?userId=${user?.uid}`
      );
      if (data.status === "Success") {
        toast.success(data.message);
      }
      setLoading2(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading2(false);
    }
  };

  const [closeModal, setCloseModal] = useState(false);
  const [loadingClose, setLoadingClose] = useState(false);

  const handleClose = async () => {
    setLoadingClose(true);
    try {
      const { data } = await axiosInstance.get(
        `/Tickets/close_ticket/${uid}?userId=${user?.uid}`
      );
      if (data.status === "Success") {
        toast.success(data.message);
        setCloseModal(true);
        navigate("/app/communication/view-ticket");
      } else {
        toast.error(data.message);
        setLoadingClose(false);
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      } else {
        toast.error("Error Marking Attendance");
      }
      setLoadingClose(false);
    } finally {
      setLoadingClose(false);
    }
  };

  const [replyModal, setReplyModal] = useState(false);
  const [upLoadFile, setUpLoadFile] = useState<File | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setUpLoadFile(file);
    }
  };

  const [editorValue, setEditorValue] = useState<string>("");
  const [loadingReply, setLoadingReply] = useState(false);
  const handleEditorChange = (value: string) => {
    setEditorValue(value);
  };

  const submitReply = async (e: React.FormEvent) => {
    e.preventDefault();
    if (editorValue.trim() === "") {
      return toast.error("All Fields must be filled");
    }
    const formData = new FormData();
    formData.append("Reply", editorValue);
    upLoadFile && formData.append("ImageFIle", upLoadFile);
    formData.append("TicketId", uid as string);

    try {
      setLoadingReply(true);
      const { data } = await axiosInstance.post(
        `/Tickets/reply_ticket/${uid}?userId=${user?.uid}`,
        formData
      );
      toast.success(data.message);
      handleFetchDetails();
      setEditorValue("");
      setReplyModal(false);
      setLoadingReply(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      } else {
        toast.error("Error Sending Reply");
      }
      setLoadingReply(false);
    } finally {
      setLoadingReply(false);
    }
  };

  const repliesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (repliesEndRef.current) {
      repliesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [ticketReply]);

  const getInitials = (fullName: string | undefined): string => {
    return fullName
      ? fullName
          .split(" ")
          .map((name: string) => name.charAt(0))
          .join("")
      : "";
  };

  return (
    <>
      <SEO title="Ticket Details" description="Ticket" name="Promax-Care" />
      <Breadcrumb />

      <div className="mt-5">
        <div className="flex justify-between items-center flex-wrap py-4">
          <h2 className="font-bold text-2xl text-black w-auto">Ticket Trail</h2>
        </div>

        {loadingData ? (
          <Spinner color="#071952" />
        ) : (
          <div className="w-full border px-6 py-4 rounded-lg mb-4 bg-gray-50 divide-y shadow-lg">
            <div className="flex items-center mb-6">
              <img
                src={avatar}
                alt="Avatar"
                className="w-12 h-12 rounded-full mr-4"
              />
              <div>
                <div className="text-lg font-bold text-gray-800">
                  {ticketDetail?.user}
                </div>
                <div className="text-gray-500">
                  {dayjs(ticketDetail?.dateCreated).format(
                    "D MMMM, YYYY h:mm A"
                  )}
                </div>
              </div>
            </div>
            <p className="font-semibold text-lg">
              <span className="font-bold text-gray-800">Title: </span>{" "}
              {ticketDetail?.subject}
            </p>
            <p className="text-base leading-relaxed mb-6">
              <span className="font-bold text-gray-800">Description: </span>
              <span className="font-serif">
                {" "}
                {parse(ticketDetail?.description || "")}
              </span>
            </p>
            {ticketDetail?.isOpen ? (
              <div className="flex gap-4 items-center">
                {(user?.role === "CompanyAdmin" ||
                  user?.role === "Administrator") && (
                  <div className="flex gap-2">
                    <button
                      className="bg-red-500 px-4 py-1.5 text-white rounded"
                      onClick={handleEscalate}
                    >
                      {loading2 ? <Spinner /> : "Escalate"}
                    </button>
                    <button
                      className="bg-primary px-4 py-1.5 text-white rounded"
                      onClick={() => setCloseModal(true)}
                    >
                      Close Ticket
                    </button>
                  </div>
                )}
                <button
                  className="bg-gray-500 px-4 py-1.5 text-white rounded"
                  onClick={() => setReplyModal(true)}
                >
                  Reply Ticket
                </button>
              </div>
            ) : (
              <div className="bg-gray-200 p-2 text-gray-500 font-semibold text-center">
                <p>Ticket has been closed</p>
              </div>
            )}
          </div>
        )}

        <div className="flex flex-col w-full bg-white rounded-lg">
          <div className="bg-gray-200 text-center p-2 flex justify-between items-center text-lg font-semibold rounded-t-xl">
            <span>Replies</span>
          </div>
          <div className="flex flex-col flex-grow p-4 overflow-auto">
            {ticketReply.length > 0 ? (
              ticketReply.map((d, i) => (
                <div
                  key={i}
                  className={`flex w-full mt-2 space-x-3 max-w-md ${
                    d.user !== ticketDetail?.user ? "" : "ml-auto justify-end"
                  }`}
                >
                  {d.user !== ticketDetail?.user && (
                    <div className="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300 text-lg flex justify-center items-center font-bold text-center">
                      {getInitials(d?.user)}
                    </div>
                  )}
                  <div>
                    <div
                      className={`p-3 ${
                        d.user !== ticketDetail?.user
                          ? "bg-gray-300 rounded-r-lg rounded-bl-lg"
                          : "bg-brand text-white rounded-l-lg rounded-br-lg"
                      }`}
                    >
                      <p className="text-sm">{parse(d.reply)}</p>
                    </div>
                    <span className="text-xs text-gray-500 leading-none">
                      By {d.user} on{" "}
                      {dayjs(d?.dateCreated).format("D MMMM, YYYY h:mm A")}
                    </span>
                  </div>
                  {d.user === ticketDetail?.user && (
                    <div className="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300 text-lg flex justify-center items-center font-bold text-center">
                      {getInitials(d?.user)}
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="text-center text-lg">
                <p>No Replies Yet</p>
              </div>
            )}
            <div ref={repliesEndRef} />
          </div>
        </div>
      </div>

      <ConfirmModal
        open={closeModal}
        onClose={() => setCloseModal(false)}
        onConfirm={() => handleClose()}
        title="Close Ticket"
        content={
          <p className="mb-4 font-bold text-center">
            You're about closing this ticket by {ticketDetail?.user}
          </p>
        }
        isLoading={loadingClose}
      />

      <Modal
        open={replyModal}
        onClose={() => setReplyModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold text-sm">Reply Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="p-2 grid grid-cols-1 gap-4" onSubmit={submitReply}>
            <div>
              <Editor
                placeholder="Write something..."
                onChange={handleEditorChange as unknown as () => void}
                value={editorValue}
              ></Editor>
              <br />
            </div>
            <CustomInput
              label=""
              type="file"
              accept=".pdf,.doc,.docx"
              onChange={handleFileChange}
            />
            <div className="mx-auto text-center mt-3">
              <button
                type="submit"
                disabled={loadingReply || editorValue.trim() === ""}
                className="text-white bg-primary disabled:bg-gray-400 hover:bg-primary focus:ring-4 focus:ring-primary font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-nonedark:focus:ring-primary"
              >
                {loadingReply ? <Spinner /> : "Submit"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TicketDetails;
