import React from "react";
import { Client } from "../../../store/slices/clientSlice";
import { CompanyDetail } from "../../../types/AuthData";
interface Props {
  companyOne: CompanyDetail | null | undefined;
  clientDetail: Client | null;
}
const Hero = ({ companyOne, clientDetail }: Props) => {
  return (
    <div>
      <div className="flex justify-end w-[100%]">
        <img
          className="w-24 "
          src={companyOne?.companyLogo}
          alt="Company Logo"
        />
      </div>
      <p className=" text-2xl font-semibold">Skill development plan</p>
      <p className="text-lg">
        Transfer the skill goal from the participant’s completed support plan.
        Complete a new sheet for each identified goal. Add more steps as
        necessary. Progress towards achieving goals is to be reviewed at the
        support plan review meeting.
      </p>
      <div className="flex items-center my-4 gap-10">
        <p className="text-[18px]">Participant name: </p>
        <div className="w-full">
          <input
            className="border outline-none border-gray-300 px-3 py-2 w-full"
            type="text"
            value={clientDetail?.fullName}
            readOnly
          />
        </div>
      </div>
      <div className="flex items-center my-4 gap-10">
        <p className="text-[18px]">Date Completed: </p>
        <div className="w-full">
          <input
            className="border outline-none border-gray-300 px-3 py-2 w-full"
            type="date"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
