import React, { ButtonHTMLAttributes } from 'react';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>


const PriceButton: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <button
      {...props}
      className={`w-full  hover:bg-primary-700 
    focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5
    text-center dark:text-white  dark:focus:ring-primary-900 ${props.className || ''}`}>{children}</button>
  )
}

export default PriceButton