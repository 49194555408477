import React, { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";

type SubLinkProps = {
  title: string;
  link: string;
  open: boolean;
};

const SubLink: FC<SubLinkProps> = ({ link, title, open }) => {
  const location = useLocation();
  const { pathname } = location;
  return (
    <li className={`duration-500 ${!open && "hidden"}`}>
      <NavLink
        to={link}
        className={`text-gray-400 text-xs flex items-center gap-x-4
        cursor-pointer p-2 px-5 hover:text-white rounded  duration-500 
        hover:no-underline focus:no-underline 
        ${pathname && pathname === link && " text-white"}
        `}
      >
        {title}
      </NavLink>
    </li>
  );
};

export default SubLink;
