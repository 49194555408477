import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import FallBack from '../FallBack';
import PageNotFound from '../PageNotFound';


// Import other admin routes as needed
const AuthLayout = () => {


    const LoginPage = React.lazy(() => import("./LoginPage"));
    const CompanyRegister = React.lazy(() => import("./CompanyRegistration"));
    const ForgotPassword = React.lazy(() => import("./ForgotPassword"));
    const ResetPassword = React.lazy(() => import("./ResetPassword"));
    const OTP = React.lazy(() => import("./OTP"));
    const SignUpCongrat = React.lazy(() => import("./SignupCongrat"));
    return (
        <div>
            {/* Auth layout components */}
            <React.Suspense fallback={<FallBack />}>
                <Routes>
                    <Route path="/" element={<Navigate to="/auth/sign-in" replace />} errorElement={<PageNotFound />} />
                    <Route path='/sign-in' element={<LoginPage />} />
                    <Route path='/company-registration' element={<CompanyRegister />} />
                    <Route path='/email-verification' element={<OTP />} />
                    <Route path='/successful-verification' element={<SignUpCongrat />} />
                    <Route path='/forgot-password' element={<ForgotPassword />} />
                    <Route path='/reset-password' element={<ResetPassword />} />
                </Routes>
            </React.Suspense>
        </div>
    );
};

export default AuthLayout;
