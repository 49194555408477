import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "rsuite";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import AuthButton from "../../Components/Buttons/AuthButton";

const CancelPayment = () => {
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state?.auth?.user);
    const [cancelModal, setCancelModal] = useState<boolean>(true);
    const compId = localStorage.getItem("companyId");

    const cancelGoTo = () => {
        setCancelModal(false)
        if (user) {
            navigate(`/app/setup/change-plan/${user.companyId}`);
        } else {
            navigate(`/subscription/${compId}`);
        }
    }

    return (
        <>
            <Modal
                open={cancelModal}
                size={"sm"}
            // onHide={() => setModal(false)}
            >
                <Modal.Header>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="px-2 py-8 sm:px-6 ">
                        <div className="mx-auto max-w-xl shadow-sm  flex flex-col items-center gap-2 bg-white lg:px-10 px-4 py-8 sm:px-6 text-center">
                            <div>
                                {/* <img src="/congrats.svg" alt="" className="w-28" /> */}

                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                                    <path fill="#f44336" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path><path fill="#fff" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"></path><path fill="#fff" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"></path>
                                </svg>

                            </div>
                            <h1 className="text-2xl font-medium">Payment Cancelled</h1>
                            {/* <p className="text-sm mt-4">
                                Thank you for your payment. your plan has been upgraded!
                                Please check your email for a payment
                                confirmation $ Invoice
                            </p> */}

                            <div className="w-full mt-4">
                                <AuthButton type="button"
                                    onClick={cancelGoTo}
                                >
                                    Continue
                                </AuthButton>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CancelPayment;
