import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";
import { Inbox } from "./inboxSlice";

export interface SentState {
  loading: boolean;
  data: Array<Inbox>;
  error: string | undefined;
}
const initialState: SentState = {
  loading: false,
  data: [],
  error: undefined,
};
export const fetchSent = createAsyncThunk(
  "Sent/fetchSent",
  async (user: string) => {
    try {
      const response = await api.fetchSentMessage(user);
      return response.message;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

const sentSlice = createSlice({
  name: "sent",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchSent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchSent.fulfilled,
      (state, action: PayloadAction<Array<Inbox>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(fetchSent.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {},
});
export const userSelector = (state: RootState) => state.sent;
export default sentSlice.reducer;

export const sentData = (state: RootState) => state.sent.data;
export const sentLoading = (state: RootState) => state.sent.loading;
export const sentError = (state: RootState) => state.sent.error;
