import React, { useState } from "react";
import { Modal } from "rsuite";
import CustomInput from "../../../../Components/Input/CustomInput";
import { CompanyDetail, PackageProps } from "../../../../types/AuthData";
import CustomSelect from "../../../../Components/Input/CustomSelect";
import axiosInstance from "../../../../store/axiosInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import Spinner from "../../../../Components/Spinner";
import SignatureComponent from "../../../../Components/Signature";
import { FaTimes } from "react-icons/fa";
interface Props {
  infoModal: boolean;
  setInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleInputChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  companyOne: CompanyDetail;
  packages: PackageProps[];
  signature: Blob | null;
  handleCompanyFetch: () => Promise<void>;
  handleSignatureSave: (signatureBlob: Blob) => void;
  setSignature: React.Dispatch<React.SetStateAction<Blob | null>>;
}
const EditCompanyProfile = ({
  infoModal,
  setInfoModal,
  companyOne,
  handleInputChange,
  packages,
  signature,
  handleCompanyFetch,
  handleSignatureSave,
  setSignature,
}: Props) => {
  const user = useSelector((state: RootState) => state?.auth?.user);

  const packageList = packages.map((item) => ({
    label: item.package,
    value: item.packagesId,
  }));
  const [upLoadLogo, setUpLoadLogo] = useState<File | null>(null);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // if (!signature) {
    //     return toast.error("Signature is required");
    // }
    const formData = new FormData();

    formData.append("CompanyId", user?.companyId.toString() || "");

    if (signature) {
      formData.append("SignatureFile", signature, "signature.png"); // Append the blob, specify filename
    }
    upLoadLogo && formData.append("CompanyLogoFile", upLoadLogo);
    for (const key in companyOne) {
      const value = companyOne[key as keyof typeof companyOne];
      if (value === null) {
        formData.append(key, ""); // Pass empty string if value is null
      } else {
        formData.append(key, value.toString());
      }
    }

    // for (var pair of formData.entries()) {
    //     console.log(pair[0] + ', ' + pair[1]);
    // }
    try {
      setLoading(true);

      const { data } = await axiosInstance.post(
        `/Companies/edit/${user?.companyId}`,
        formData
      );

      if (data.status === "Success") {
        toast.success(data.message);
        setLoading(false);
        setInfoModal(false);
        setSignature(null);
        setUpLoadLogo(null);
        handleCompanyFetch();
      } else {
        toast.error(data.message);
        setLoading(false);
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setUpLoadLogo(file);
    }
  };
  return (
    <>
      <Modal
        open={infoModal}
        onClose={() => setInfoModal(false)}
        backdrop="static"
        autoFocus
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Edit Company Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="p-2 grid grid-cols-1 gap-4 lg:grid-cols-2  ">
              <div className="lg:col-span-2 flex justify-center flex-col items-center">
                <div className="flex gap-2 ">
                  {!upLoadLogo && (
                    <img
                      src={companyOne.companyLogo}
                      alt="Company logo"
                      className="img-thumbnail  block "
                      style={{
                        display: "block",
                        width: "150px",
                      }}
                    />
                  )}

                  {!upLoadLogo && !companyOne.companyLogo && (
                    <CustomInput
                      label=""
                      type="file"
                      accept="image/jpeg, image/png"
                      onChange={handleFileChange}
                    />
                  )}
                </div>
                <div className="flex gap-2 items-center">
                  {upLoadLogo && (
                    <img
                      src={URL.createObjectURL(upLoadLogo)}
                      alt="Company Logo"
                      className="img-thumbnail  block "
                      style={{
                        display: "block",
                        width: "150px",
                      }}
                    />
                  )}
                  {upLoadLogo && (
                    <button
                      className=" text-base text-red-600"
                      type="button"
                      onClick={() => setUpLoadLogo(null)}
                    >
                      <FaTimes className="text-xl border p-1" />
                    </button>
                  )}
                </div>
                {companyOne.companyLogo && (
                  <CustomInput
                    label=""
                    type="file"
                    accept="image/jpeg, image/png"
                    onChange={handleFileChange}
                  />
                )}
              </div>

              <CustomInput
                label="Company Head"
                type="text"
                name="companyHead"
                value={companyOne.companyHead}
                onChange={handleInputChange}
              />
              <CustomInput
                label="Company Name"
                type="text"
                name="companyName"
                value={companyOne.companyName}
                onChange={handleInputChange}
              />
              <CustomInput
                label="Company Email"
                type="email"
                name="companyEmail"
                value={companyOne.companyEmail}
                onChange={handleInputChange}
              />
              <CustomInput
                label="Company Address"
                type="text"
                name="companyAddress"
                value={companyOne.companyAddress}
                onChange={handleInputChange}
              />
              <CustomInput
                label="Company Phone"
                type="text"
                name="companyPhone"
                value={companyOne.companyPhone}
                onChange={handleInputChange}
              />
              <CustomInput
                label="Company Website"
                type="text"
                name="website"
                value={companyOne.website}
                onChange={handleInputChange}
              />
              <CustomInput
                label="Payroll Email"
                type="text"
                name="payrollEmail"
                value={companyOne.payrollEmail}
                onChange={handleInputChange}
              />
              <CustomInput
                label="Support Email"
                type="text"
                name="supportEmail"
                value={companyOne.supportEmail}
                onChange={handleInputChange}
              />
              <CustomInput
                label="General Email"
                type="text"
                name="generalEmail"
                value={companyOne.generalEmail}
                onChange={handleInputChange}
              />
              <CustomInput
                label="NDIS Provider Number"
                type="text"
                name="providerNumber"
                value={companyOne.providerNumber}
                onChange={handleInputChange}
              />

              <div className="lg:col-span-2">
                <CustomSelect
                  label="Packages"
                  name="packagesId"
                  firstOption={companyOne.packages.package}
                  options={packageList}
                  value={companyOne.packagesId}
                  disabled
                />
              </div>

              <div className="lg:col-span-2">
                <p>Signature </p>
                <div className="flex gap-2 ">
                  {!signature && (
                    <img
                      src={companyOne.signature}
                      alt="Company Signature"
                      className="img-thumbnail  block "
                      style={{
                        display: "block",
                        width: "150px",
                      }}
                    />
                  )}

                  {!signature && !companyOne.signature && (
                    <SignatureComponent
                      onSignatureSave={handleSignatureSave}
                      text="Sign here"
                    />
                  )}
                </div>
                <div className="flex gap-2 items-center">
                  {signature && (
                    <img
                      src={URL.createObjectURL(signature)}
                      alt="Company Signature"
                      className="img-thumbnail  block "
                      style={{
                        display: "block",
                        width: "150px",
                      }}
                    />
                  )}
                  {signature && (
                    <button
                      className=" text-base text-red-600"
                      type="button"
                      onClick={() => setSignature(null)}
                    >
                      <FaTimes className="text-lg border p-1" />
                    </button>
                  )}
                </div>
                {companyOne.signature && (
                  <SignatureComponent
                    onSignatureSave={handleSignatureSave}
                    text="Change Signature"
                  />
                )}
              </div>
              <div className="flex items-center justify-center lg:col-span-2">
                <button
                  type="submit"
                  disabled={loading ? true : false}
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                >
                  {loading ? <Spinner /> : "Update"}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditCompanyProfile;
