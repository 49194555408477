import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import DataTable, {
  ExpanderComponentProps,
  TableColumn,
} from "react-data-table-component";
import { BsSearch } from "react-icons/bs";
import ExportDropdown from "../../../../Components/menu/ExportDropdown";
import IconButton from "../../../../Components/Buttons/IconButton";
import {
  downloadCSV,
  exportToExcel,
  handlePDFDownload,
} from "../../../../services/reusableFunc";
import { Column } from "../../../../types/DataType";
import { FaFileCsv, FaFileExcel, FaFilePdf } from "react-icons/fa";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../../store/store";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import Spinner from "../../../../Components/Spinner";
import { toast } from "react-toastify";
import axiosInstance from "../../../../store/axiosInstance";
import { isAxiosError } from "axios";
import {
  fetchStaffProgressNote,
  staffProgessNoteData,
  staffProgessNoteLoading,
} from "../../../../store/slices/staffProgresNoteSlice";
import { Modal } from "rsuite";

interface DataRow {
  date: string;
  dateCreated: string;
  dateModified: string;
  followUp: string;
  progress: string;
  report: string;
  progressNoteId: string;
  profile: {
    profileId: number;
    firstName: string;
    surName: string;
    middleName: string;
    email: string;
    phoneNumber: string;
    gender: string;
    imageUrl: string;
    imageFile: string;
    fullName: string;
    dateCreated: string;
    userCreated: string;
    dateModified: string;
  };
}

interface ShiftProps {
  fullName: string;
  uid: string; // Define the type of uid as string
}

const ShiftReport: React.FC<ShiftProps> = ({ uid, fullName }) => {
  const columns: TableColumn<DataRow>[] = [
    {
      name: "Client",
      selector: (row) => row.profile.fullName,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "DateCreated",
      selector: (row) => dayjs(row.dateCreated).format("YYYY-MM-DD"),
      sortable: true,
    },
    {
      name: "DateModified",
      selector: (row) => dayjs(row.dateModified).format("DD/MM/YYYY HH:mm:ss"),
      sortable: true,
    },
  ];

  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();
  useEffect(() => {
    if (fullName) {
      dispatch(fetchStaffProgressNote(fullName));
    }
  }, [dispatch, uid, fullName]);
  const staffReport = useSelector(staffProgessNoteData);
  const loadingData = useSelector(staffProgessNoteLoading);

  const [searchText, setSearchText] = useState<string>("");

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredData = staffReport?.filter((item) =>
    item?.profile?.fullName?.toLowerCase().includes(searchText.toLowerCase())
  );

  interface Props extends ExpanderComponentProps<DataRow> {
    // currently, props that extend ExpanderComponentProps must be set to optional.
    someTitleProp?: string;
  }

  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [details, setDetails] = useState<DataRow>({
    date: "",
    dateCreated: "",
    dateModified: "",
    followUp: "",
    progress: "",
    report: "",
    progressNoteId: "",
    profile: {
      profileId: 0,
      firstName: "",
      surName: "",
      middleName: "",
      email: "",
      phoneNumber: "",
      gender: "",
      imageUrl: "",
      imageFile: "",
      fullName: "",
      dateCreated: "",
      userCreated: "",
      dateModified: "",
    },
  });

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const EditDetail = async (id: string) => {
    setShowModal(true);
    try {
      const { data } = await axiosInstance.get(`/ProgressNotes/${id}`);
      setDetails({ ...data });
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
    }
  };

  const handleEdit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const info = {
      ...details,
      staffId: uid,
    };
    try {
      const { data } = await axiosInstance.post(
        `/ProgressNotes/edit/${details.progressNoteId}?userId=${
          user?.uid
        }&shiftId=${""}`,
        info
      );
      toast.success(data.message);
      uid && dispatch(fetchStaffProgressNote(fullName));
      setShowModal(false);
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    }
  };

  const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
    return (
      <div className="p-2 flex flex-col gap-2 text-xs">
        <span>
          <span className="font-bold">FollowUp: </span>
          <span> {data.followUp}</span>
        </span>
        <span>
          <span className="font-bold">Progress: </span>
          <span>{data.progress}</span>
        </span>
        <span>
          <span className="font-bold">Report: </span>
          <span>{data.report}</span>
        </span>
        <div className="flex gap-2">
          <span className="font-bold">Actions: </span>
          <button
            className="btn text-primary font-bold"
            style={{ fontSize: "12px" }}
            onClick={() => EditDetail(data.progressNoteId)}
          >
            Edit
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="mt-10">
      {loadingData && <Spinner color="#071952" />}
      <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
        <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
          <h2 className="font-bold text-xl text-black w-auto">Shift Report</h2>

          <div className="relative w-full lg:col-span-2">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <BsSearch />
            </div>
            <input
              type="text"
              id="simple-search"
              onChange={handleSearch}
              className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Search By Client Name"
            />
          </div>
        </div>
        <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
          <div className="flex items-center space-x-3 w-full md:w-auto">
            <ExportDropdown>
              <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                <IconButton
                  icon={<FaFilePdf className="text-red-500" />}
                  title="Export as PDF"
                  onClick={() =>
                    handlePDFDownload(
                      staffReport,
                      columns as unknown as Column<DataRow>[],
                      "ShiftReport.pdf"
                    )
                  }
                />
                <IconButton
                  icon={<FaFileExcel className="text-green-600" />}
                  title="Export as Excel Sheet"
                  onClick={() =>
                    exportToExcel(
                      staffReport,
                      columns as unknown as Column<DataRow>[],
                      "ShiftReport"
                    )
                  }
                />
                <IconButton
                  icon={<FaFileCsv className="text-green-500" />}
                  title="Export as CSV"
                  onClick={() =>
                    downloadCSV({
                      data: staffReport,
                      filename: "ShiftReport.csv",
                    })
                  }
                />
              </div>
            </ExportDropdown>
          </div>
        </div>
      </div>

      <DataTable
        columns={columns}
        data={filteredData}
        expandableRows
        expandableRowsComponent={ExpandableRowComponent}
        pagination
        paginationTotalRows={staffReport?.length}
        // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}
      />

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Edit Shift Report </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form action="">
              <div className="space-y-2">
                <div className="">
                  <label htmlFor="followUp" className="font-bold">
                    FollowUp:{" "}
                  </label>
                  <textarea
                    id="followUp"
                    rows={2}
                    autoComplete="off"
                    name="followUp"
                    value={details.followUp || ""}
                    onChange={handleInputChange}
                    className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                    defaultValue={""}
                  />
                </div>
                <div className="">
                  <label htmlFor="progress" className="font-bold">
                    Progress:{" "}
                  </label>
                  <textarea
                    id="progress"
                    rows={2}
                    autoComplete="off"
                    name="progress"
                    value={details.progress || ""}
                    onChange={handleInputChange}
                    className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                    defaultValue={""}
                  />
                </div>
                <div className="">
                  <label htmlFor="report" className="font-bold">
                    Report:{" "}
                  </label>
                  <textarea
                    id="report"
                    rows={2}
                    autoComplete="off"
                    name="report"
                    value={details.report || ""}
                    onChange={handleInputChange}
                    className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                    defaultValue={""}
                  />
                </div>
              </div>
            </form>
            <div className="mx-auto text-center mt-3">
              <button
                type="submit"
                onClick={handleEdit}
                disabled={loading ? true : false}
                className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                    font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                    dark:focus:ring-primary"
              >
                {loading ? <Spinner /> : "Update"}
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default ShiftReport;
