import React from "react";
import { Link } from "react-router-dom";
import { Client } from "../../../../store/slices/clientSlice";
interface Props {
  profile: Client | null;
  percentage: number;
  bgColor: string
}

const ProfileCard = ({ profile, bgColor, percentage }: Props) => {
  return (
    <div className="bg-white relative shadow rounded-2xl p-2">
      <div className="border border-primary rounded-2xl border-dashed ">
        <div className="flex justify-center">
          <img
            src={profile?.imageUrl}
            alt="img"
            className="rounded-full mx-auto absolute -top-12 w-24 h-24 shadow-md border-4 border-white transition duration-200 transform hover:scale-110"
          />
        </div>
        <div className="mt-10 p-2">
          <h1 className="font-bold text-center text-xl text-gray-900">
            {profile?.fullName}
          </h1>
          <p className="text-center text-sm text-gray-400 font-medium">
            Email: {profile?.email}
          </p>


          <div className="px-2 py-8 ">
            <div className="w-full  bg-gray-200 rounded-full dark:bg-gray-700">
              <div className={`${bgColor} text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full`} style={{ width: '45%' }}>{percentage}% </div>
            </div>
          </div>

          <div className="text-center my-3 ">
            <Link
              to="/app/client/profile"
              className="text-white bg-primary hover:bg-primary hover:text-white
font-medium rounded-full text-xs px-16 py-2  "
            >
              View Profile
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
