import React from 'react';
import { CompanyDetail } from '../../../types/AuthData';
import { ParticipantConsentForm } from '../../../types/DataType';

interface Props {
    companyOne: CompanyDetail | null | undefined;
    participantConsentForm: ParticipantConsentForm;
    handleRadioDataChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const EditSection2: React.FC<Props> = ({ companyOne, participantConsentForm, handleRadioDataChange }) => {
    return (
        <div className="p-4 max-[1024px]:w-[100%] my-3 border border-black">
            <strong>Services and community access</strong>
            <div className='my-6'>
                <p>I give my consent for the participant named above to attend services provided by Insert name. I understand that a degree of risk is involved with the provision of support. I understand that while staff will take the utmost care, they will not accept any personal responsibility for any personal illness or accident nor accept any responsibility for loss/damage of property that may occur:</p>
                <div className='flex my-2 items-center gap-20'>
                    <p className='flex items-center gap-2'>
                        <input
                            type="radio"
                            id="serviceYes"
                            name="consent_for_Services"
                            value="Yes"
                            checked={participantConsentForm.consent_for_Services === true}
                            onChange={handleRadioDataChange}
                        />
                        <label htmlFor="serviceYes">Yes</label>
                    </p>
                    <p className='flex items-center gap-2'>
                        <input
                            type="radio"
                            id="serviceNo"
                            name="consent_for_Services"
                            value="No"
                            checked={participantConsentForm.consent_for_Services === false}
                            onChange={handleRadioDataChange}
                        />
                        <label htmlFor="serviceNo">No</label>
                    </p>
                </div>
            </div>

            <div className='my-6'>
                <p>I give consent for {companyOne?.companyName} to obtain medical treatment for the participant in the event of any emergency:</p>
                <div className='flex my-2 items-center gap-20'>
                    <p className='flex items-center gap-2'>
                        <input
                            type="radio"
                            id="medicalYes"
                            name="consent_for_Medical_Treatment"
                            value="Yes"
                            checked={participantConsentForm.consent_for_Medical_Treatment === true}
                            onChange={handleRadioDataChange}
                        />
                        <label htmlFor="medicalYes">Yes</label>
                    </p>
                    <p className='flex items-center gap-2'>
                        <input
                            type="radio"
                            id="medicalNo"
                            name="consent_for_Medical_Treatment"
                            value="No"
                            checked={participantConsentForm.consent_for_Medical_Treatment === false}
                            onChange={handleRadioDataChange}
                        />
                        <label htmlFor="medicalNo">No</label>
                    </p>
                </div>
            </div>

            <div className='my-6'>
                <p>I give consent for the participant to travel in the worker’s insured vehicle, Maxicare Plus owned vehicles as well as public transport alternatives:</p>
                <div className='flex my-2 items-center gap-20'>
                    <p className='flex items-center gap-2'>
                        <input
                            type="radio"
                            id="travelYes"
                            name="consent_to_Travel"
                            value="Yes"
                            checked={participantConsentForm.consent_to_Travel === true}
                            onChange={handleRadioDataChange}
                        />
                        <label htmlFor="travelYes">Yes</label>
                    </p>
                    <p className='flex items-center gap-2'>
                        <input
                            type="radio"
                            id="travelNo"
                            name="consent_to_Travel"
                            value="No"
                            checked={participantConsentForm.consent_to_Travel === false}
                            onChange={handleRadioDataChange}
                        />
                        <label htmlFor="travelNo">No</label>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default EditSection2;

