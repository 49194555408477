import React from "react";
import { Client } from "../../../store/slices/clientSlice";

interface Props {
  clientDetail: Client | null;
  formData: {
    date: string;
    participantProvidingConsent: boolean;
    authorisedPersonProvidingConsent: boolean;
    nameOfAuthorisedPerson: string;
    relationshipToParticipant: string;
  }
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleRadioChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Form = ({ clientDetail, formData, handleChange, handleRadioChange }: Props) => {

  return (
    <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border mt-5 border-gray-300">
      <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
        <tbody className="divide-y divide-gray-200">
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Date:
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                className="w-full outline-none"
              />
            </td>
          </tr>

          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Name of participant
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">
              {clientDetail?.fullName}
            </td>
          </tr>

          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Is the participant providing consent
            </td>
            <td className="whitespace-nowrap items-center px-4 py-2 flex gap-5 text-gray-700 w-96 outline-none">
              <p className="flex items-center gap-2">
                <input
                  type="radio"
                  name="participantProvidingConsent"
                  value="true"
                  checked={formData.participantProvidingConsent === true}
                  onChange={handleRadioChange}
                />
                <label>Yes</label>
              </p>
              <p className="flex items-center gap-2">
                <input
                  type="radio"
                  name="participantProvidingConsent"
                  value="false"
                  checked={formData.participantProvidingConsent === false}
                  onChange={handleRadioChange}
                />
                <label>No</label>
              </p>
            </td>
          </tr>

          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Is an authorised person providing consent?
            </td>
            <td className="whitespace-nowrap items-center px-4 py-2 flex gap-5 text-gray-700 w-96 outline-none">
              <p className="flex items-center gap-2">
                <input
                  type="radio"
                  name="authorisedPersonProvidingConsent"
                  value="true"
                  checked={formData.authorisedPersonProvidingConsent === true}
                  onChange={handleRadioChange}
                />
                <label>Yes</label>
              </p>
              <p className="flex items-center gap-2">
                <input
                  type="radio"
                  name="authorisedPersonProvidingConsent"
                  value="false"
                  checked={formData.authorisedPersonProvidingConsent === false}
                  onChange={handleRadioChange}
                />
                <label>No</label>
              </p>
            </td>
          </tr>
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              If yes, name of authorised person
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">
              <input
                type="text"
                name="nameOfAuthorisedPerson"
                value={formData.nameOfAuthorisedPerson}
                onChange={handleChange}
                className="w-full outline-none"
              />
            </td>
          </tr>
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Relationship to the participant
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">
              <input
                type="text"
                name="relationshipToParticipant"
                value={formData.relationshipToParticipant}
                onChange={handleChange}
                className="w-full outline-none"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Form;

