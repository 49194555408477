import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

export interface ServiceProviderClient {
  email: string;
  fullName: string;
  gender: string;
  contactNumber: number;
  isAccepted: boolean;
  name: string;
}

export interface ServiceProviderClientState {
  loading: boolean;
  data: Array<ServiceProviderClient>;
  error: string | undefined;
}
const initialState: ServiceProviderClientState = {
  loading: false,
  data: [],
  error: undefined,
};
export const fetchServiceProviderClient = createAsyncThunk(
  "ServiceProviderClient/fetchServiceProviderClient",
  async (uid: string) => {
    try {
      const response = await api.fetchServiceProviderClient(uid);
      return response;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

const serviceProviderClientSlice = createSlice({
  name: "serviceProviderClient",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchServiceProviderClient.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchServiceProviderClient.fulfilled,
      (state, action: PayloadAction<Array<ServiceProviderClient>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(fetchServiceProviderClient.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {},
});
export const userSelector = (state: RootState) => state.serviceProviderClient;
export default serviceProviderClientSlice.reducer;

export const serviceProviderClientData = (state: RootState) =>
  state.serviceProviderClient.data;
export const serviceProviderClientLoading = (state: RootState) =>
  state.serviceProviderClient.loading;
export const serviceProviderClientError = (state: RootState) =>
  state.serviceProviderClient.error;
