import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store/store";
import DropdownUserLink from "./DropdownUserLink";
import { Button, Modal } from "rsuite";
import { logout } from "../../store/slices/authSlice";
import { StaffProfileTypes } from "../../types/AuthData";
import { Client } from "../../store/slices/clientSlice";
import { emptyCache } from "../../hooks/cacheUtils";

const DropdownUser = () => {
  const user = useSelector((state: RootState) => state?.auth?.user);
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const trigger = useRef<HTMLDivElement>(null);
  const dropdown = useRef<HTMLDivElement>(null);
  const [logoutModal, setLogoutModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const staffProfileString = localStorage.getItem("staffProfile");
  const staffProfile: StaffProfileTypes = staffProfileString
    ? JSON.parse(staffProfileString)
    : null;
  const clientProfileString = localStorage.getItem("clientProfile");
  const clientProfile: Client = clientProfileString
    ? JSON.parse(clientProfileString)
    : null;
  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  const handleCloseDropdown = () => {
    setDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdown.current &&
        !dropdown.current.contains(event.target as Node) &&
        trigger.current &&
        !trigger.current.contains(event.target as Node)
      ) {
        setDropdownOpen(!dropdown);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const fullName = user?.fullName;
  const initials = fullName
    ? fullName
      .split(" ")
      .map((name: string) => name.charAt(0))
      .join("")
    : "";

  return (
    <div className="relative">
      <div
        ref={trigger}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="flex items-center gap-4 cursor-pointer"
      >
        {user?.role === "CompanyAdmin" && (
          <span className="h-10 w-10 flex justify-center items-center rounded-full bg-primary text-white font-bold">
            {initials}
          </span>
        )}

        {user?.role !== "Administrator" &&
          user?.role !== "CompanyAdmin" &&
          user?.role !== "Staff" &&
          user?.role !== "Client" && (
            <span className="h-10 w-10 flex justify-center items-center rounded-full bg-primary text-white font-bold">
              {initials}
            </span>
          )}
        {user?.role === "Administrator" && (
          <span className="h-10 w-10 flex justify-center items-center rounded-full bg-primary text-white font-bold">
            {initials}
          </span>
        )}
        {user?.role === "Staff" && (
          <>
            {staffProfile.imageUrl ? (
              <span className="h-10 w-10 flex justify-center items-center rounded-full bg-transparent  overflow-hidden">
                <img
                  src={staffProfile.imageUrl}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </span>
            ) : (
              <span className="h-10 w-10 flex justify-center items-center rounded-full bg-primary text-white font-bold">
                {initials}
              </span>
            )}
          </>
        )}
        {user?.role === "Client" && (
          <>
            {clientProfile.imageUrl ? (
              <span className="h-10 w-10 flex justify-center items-center rounded-full bg-transparent  overflow-hidden">
                <img
                  src={clientProfile.imageUrl}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </span>
            ) : (
              <span className="h-10 w-10 flex justify-center items-center rounded-full bg-primary text-white font-bold">
                {initials}
              </span>
            )}
          </>
        )}
      </div>

      {dropdownOpen && (
        <div
          ref={dropdown}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="absolute right-0 top-10 flex flex-col px-2 py-2 w-44 bg-white shadow-md rounded animate__fadeInTopLeft"
        >
          <span className="text-left lg:block border-b space-y-1 py-2">
            <span className="block text-xs font-semibold capitalize text-black truncate">
              {user?.fullName}
            </span>

            <button className="px-3 py-1 text-xs bg-gray-200 rounded block cursor-none">
              {user?.role}
            </button>
          </span>

          {user?.role === "Administrator" && (
            <DropdownUserLink
              handleClose={handleCloseDropdown}
              title="Profile"
              url="/app/user/administrator/profile"
            />
          )}
          {user?.role === "Staff" ? (
            ""
          ) : (
            <DropdownUserLink
              handleClose={handleCloseDropdown}
              title="Change Password"
              url="/app/setup/change-password"
            />
          )}
          {user?.role === "Staff" && (
            <DropdownUserLink
              handleClose={handleCloseDropdown}
              title="Profile"
              url="/app/staff/profile"
            />
          )}
          {user?.role === "Client" && (
            <DropdownUserLink
              handleClose={handleCloseDropdown}
              title="Profile"
              url="/app/client/profile"
            />
          )}
          {user?.role === "Staff" && (
            <DropdownUserLink
              handleClose={handleCloseDropdown}
              title="Change Password"
              url="/app/staff/change-password"
            />
          )}

          {user?.role === "CompanyAdmin" && (
            <DropdownUserLink
              handleClose={handleCloseDropdown}
              title="Company Profile"
              url="/app/setup/company-profile"
            />
          )}

          {user?.role === "CompanyAdmin" && (
            <DropdownUserLink
              handleClose={handleCloseDropdown}
              title="Manage Subscription"
              url={`/app/setup/change-plan/${user?.companyId}`}
            />
          )}


          <button
            onClick={emptyCache}
            className="bg-gray-200 text-left p-2 text-sm inline-flex gap-2 items-center font-semibold text-slate-500 hover:text-primary"
          >
            Update App{" "}
            <small className="w-3 h-3 bg-secondary rounded-full"></small>
          </button>
          <button
            onClick={() => setLogoutModal(true)}
            className="hover:bg-gray-100 text-left p-2 text-sm font-semibold text-gray-500 hover:text-primary"
          >
            Log Out
          </button>
        </div>
      )}

      <Modal
        open={logoutModal}
        onClose={() => setLogoutModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold text-sm">
            Confirm Logout
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="mb-4 font-bold text-lg">
              Are you sure you want to logout?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <Button
                onClick={() => setLogoutModal(false)}
                className="bg-gray-500 hover:bg-gray-400 text-white hover:text-white"
              >
                No, Cancel
              </Button>
              <Button
                onClick={() => {
                  handleLogout();
                  setLogoutModal(false);
                }}
                className="bg-red-500 text-white hover:bg-red-500/80 hover:text-white"
              >
                Confirm
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default DropdownUser;
