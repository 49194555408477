import React, { FormEvent } from "react";
import { Modal } from "rsuite";
import CustomInput from "../../../../Components/Input/CustomInput";
import CustomSelect from "../../../../Components/Input/CustomSelect";
import Spinner from "../../../../Components/Spinner";
import { ClientDataRow } from "../../../../types/AuthData";
// import { LoadScript } from "@react-google-maps/api";
// import AutocompleteLocation from "../../../../Components/Autocomplete";
import { Coordinates } from "../../../../types/DataType";
import AddressComponent from "../../../../Components/Address";
interface Props {
  personalInfoModal: boolean;
  setPersonalInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
  editedProfile: ClientDataRow;
  handleInputChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  submitData: (e: FormEvent) => Promise<void>;
  loading1: boolean;
  address: string;
  setAddress: React.Dispatch<React.SetStateAction<string>>;
  setCoordinates?: React.Dispatch<React.SetStateAction<Coordinates | null>>;
}

const ClientEditComp = ({
  personalInfoModal,
  setPersonalInfoModal,
  editedProfile,
  handleInputChange,
  submitData,
  loading1,
  address,
  setAddress,
  setCoordinates,
}: Props) => {
  // const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;
  // const libraries: "places"[] = ["places"];
  // const [isApiLoaded, setIsApiLoaded] = useState(false);

  // const handleLoad = () => {
  //   setIsApiLoaded(true);
  // };

  return (
    <>
      <Modal
        open={personalInfoModal}
        onClose={() => setPersonalInfoModal(false)}
        backdrop="static"
        autoFocus
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">
            Edit Personal Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form action="" onSubmit={submitData}>
              <div className="p-2 grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8 ">
                <CustomInput
                  label="Last Name"
                  type="text"
                  value={editedProfile.surName}
                  readOnly
                />
                <CustomInput
                  label="First Name"
                  type="text"
                  value={editedProfile.firstName}
                  readOnly
                />
                <CustomInput
                  label="Middle Name"
                  type="text"
                  name="middleName"
                  value={editedProfile.middleName || ""}
                  onChange={handleInputChange}
                />
                <CustomInput
                  label="Phone Number"
                  type="text"
                  name="phoneNumber"
                  value={editedProfile.phoneNumber || ""}
                  required
                  onChange={handleInputChange}
                />
                <CustomInput
                  label="Date Of Birth"
                  type="date"
                  name="dateOfBirth"
                  value={editedProfile.dateOfBirth || ""}
                  onChange={handleInputChange}
                />
                <CustomInput
                  label="Email"
                  type="email"
                  name="email"
                  value={editedProfile.email || ""}
                  readOnly
                  onChange={handleInputChange}
                />
                <CustomSelect
                  label="Gender"
                  name="gender"
                  options={[
                    { value: "", label: "Select Gender" },
                    { value: "Male", label: "Male" },
                    { value: "Female", label: "Female" },
                    { value: "Transgender", label: "Transgender" },
                    {
                      value: "Non-binary/non-conforming",
                      label: "Non-binary/non-conforming",
                    },
                    {
                      value: "Prefer not to respond",
                      label: "Prefer not to respond",
                    },
                  ]}
                  value={editedProfile.gender || ""}
                  onChange={handleInputChange}
                />
                <CustomInput
                  label="Country"
                  type="text"
                  name="country"
                  value={editedProfile.country || ""}
                  onChange={handleInputChange}
                />
                <CustomInput
                  label="State"
                  type="text"
                  name="state"
                  value={editedProfile.state || ""}
                  onChange={handleInputChange}
                />
                {/* <CustomInput
                  label="City"
                  type="text"
                  name="city"
                  value={editedProfile.city || ""}
                  onChange={handleInputChange}
                /> */}
                <CustomInput
                  label="Agreement Start Date"
                  type="date"
                  name="agreementStartDate"
                  value={editedProfile.agreementStartDate || ""}
                  onChange={handleInputChange}
                />
                <CustomInput
                  label="Agreement End Date"
                  type="date"
                  name="agreementEndDate"
                  value={editedProfile.agreementEndDate || ""}
                  onChange={handleInputChange}
                />
                <CustomInput
                  label="Suburb"
                  type="text"
                  name="suburb"
                  value={editedProfile.suburb || ""}
                  onChange={handleInputChange}
                />
                {/* <div className="col-span-1"> */}
                <CustomInput
                  label="Ndis No"
                  type="text"
                  name="ndisNo"
                  value={editedProfile.ndisNo || ""}
                  onChange={handleInputChange}
                />
                {/* </div> */}

                {/* {!isApiLoaded && <div className="col-span-3">
                  <LoadScript
                    googleMapsApiKey={googleMapsApiKey}
                    libraries={libraries}
                    onLoad={handleLoad}
                  >
                    <AutocompleteLocation
                      address={address}
                      setAddress={setAddress}
                      setCoordinates={setCoordinates}
                    />
                  </LoadScript>
                </div>} */}

                {/* {isApiLoaded && ( */}
                <div className="col-span-2">
                  {/* <LoadScriptWrapper>

                    <AutocompleteLocation
                      address={address}
                      setAddress={setAddress}
                      setCoordinates={setCoordinates}
                    />

                  </LoadScriptWrapper> */}
                  <AddressComponent
                    address={address}
                    setAddress={setAddress}
                    setCoordinates={setCoordinates}
                  />
                </div>
                {/* )} */}


              </div>

              <div className="flex items-center justify-center">
                <button
                  type="submit"
                  disabled={loading1}
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                >
                  {loading1 ? <Spinner /> : "Update"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ClientEditComp;
