import React, { useRef } from "react";
import { Client } from "../../store/slices/clientSlice";
import { Staff } from "../../store/slices/staffSlice";
import { CompanyDetail } from "../../types/AuthData";
import { SupportPlanReviewData } from "../../types/DataType";
import PrintComponent from "../PrintTab";
import EditSupportPlanReview from "./__component/EditMain";
import Hero from "./__component/Hero";

interface Props {
  companyOne: CompanyDetail | null | undefined;
  clientDetail: Client | null;
  handleInputDataEditChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  planReviewForm: SupportPlanReviewData;
  careManager: Staff | null;
  editAttendee: string[];
  handleInputEditChange: (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleAddRowEdit: () => void;
}

const EditSupportPlan = ({
  companyOne,
  careManager,
  clientDetail,
  editAttendee,
  handleInputEditChange,
  handleAddRowEdit,
  planReviewForm,
  handleInputDataEditChange,
}: Props) => {
  const componentRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <form>
        <PrintComponent
          componentRef={componentRef}
          triggerLabel="Print / Download"
          documentTitle="Support Plan Review Form"
        />
        <div
          ref={componentRef}
          className="mx-auto  max-w-3xl p-4 md:p-4 lg:p-8 bg-white"
        >
          <Hero companyOne={companyOne} />
          <EditSupportPlanReview
            careManager={careManager}
            clientDetail={clientDetail}
            editAttendee={editAttendee}
            handleInputEditChange={handleInputEditChange}
            handleAddRowEdit={handleAddRowEdit}
            planReviewForm={planReviewForm}
            handleInputDataEditChange={handleInputDataEditChange}
          />
        </div>
      </form>
    </div>
  );
};

export default EditSupportPlan;
