import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

interface StaffProgressNote {
  date: string;
  dateCreated: string;
  dateModified: string;
  followUp: string;
  progress: string;
  report: string;
  progressNoteId: string;
  profile: {
    profileId: number;
    firstName: string;
    surName: string;
    middleName: string;
    email: string;
    phoneNumber: string;
    gender: string;
    imageUrl: string;
    imageFile: string;
    fullName: string;
    dateCreated: string;
    userCreated: string;
    dateModified: string;
  };
}

export interface StaffProgressNoteState {
  loading: boolean;
  data: Array<StaffProgressNote>;
  error: string | undefined;
}
const initialState: StaffProgressNoteState = {
  loading: false,
  data: [],
  error: undefined,
};
export const fetchStaffProgressNote = createAsyncThunk(
  "StaffProgressNote/fetchStaffProgressNote",
  async (fName: string) => {
    try {
      const response = await api.fetchStaffProgressNote(fName);
      return response.progressNote;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

const staffProgressNoteSlice = createSlice({
  name: "staffProgessNote",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchStaffProgressNote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchStaffProgressNote.fulfilled,
      (state, action: PayloadAction<Array<StaffProgressNote>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(fetchStaffProgressNote.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {},
});
export const userSelector = (state: RootState) => state.staffProgessNote;
export default staffProgressNoteSlice.reducer;

// Define selectors to access the profile slice state
export const staffProgessNoteData = (state: RootState) =>
  state.staffProgessNote.data;
export const staffProgessNoteLoading = (state: RootState) =>
  state.staffProgessNote.loading;
export const staffProgessNoteError = (state: RootState) =>
  state.staffProgessNote.error;
