// import React from 'react';
// // import { Checkboxes } from '..';
// import { ParticipantCheckList } from '../../../types/DataType';

// interface Props {
//     // editCheckboxes: Checkboxes;
//     // handleEditCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
//     participantCheck: ParticipantCheckList
// }

// const EditActive = ({ participantCheck }: Props) => {

//     return (
//         <div>
//             <p className="mt-5 text-black">
//                 <span className="underline">Active files</span> – tick to indicate the document has been sighted and is current:
//             </p>
//             <div>
//                 {[
//                     { id: 'sign', label: 'Signed service agreement' },
//                     { id: 'consent', label: 'Consent Form' },
//                     { id: 'support', label: 'Support plan' },
//                     { id: 'safety', label: 'Safety Plan' },
//                     { id: 'manage', label: 'Medication Management Plan (if applicable)' },
//                     { id: 'chart', label: 'Medication Chart (if applicable)' },
//                     { id: 'plan', label: 'Other supporting plans – refer table below' }
//                 ].map(({ id, label }) => (
//                     <p key={id} className="flex gap-2 items-center my-3 text-black">
//                         <input type="checkbox" id={id}
//                         // onChange={handleEditCheckboxChange}
//                         />
//                         <label htmlFor={id}>{label}</label>
//                     </p>
//                 ))}
//             </div>
//         </div>
//     );
// };

// export default EditActive;

import React from 'react';
// import { ParticipantCheckList } from '../../../types/DataType';

interface Props {
    editCheckboxes: {
        sign: boolean;
        consent: boolean;
        support: boolean;
        safety: boolean;
        manage: boolean;
        chart: boolean;
        plan: boolean;
    }
    handleEditCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const EditActive = ({ editCheckboxes, handleEditCheckboxChange }: Props) => {


    const checkboxItems = [
        { id: 'sign', label: 'Signed service agreement' },
        { id: 'consent', label: 'Consent Form' },
        { id: 'support', label: 'Support plan' },
        { id: 'safety', label: 'Safety Plan' },
        { id: 'manage', label: 'Medication Management Plan (if applicable)' },
        { id: 'chart', label: 'Medication Chart (if applicable)' },
        { id: 'plan', label: 'Other supporting plans – refer table below' }
    ];

    return (
        <div>
            <p className="mt-5 text-black">
                <span className="underline">Active files</span> – tick to indicate the document has been sighted and is current:
            </p>
            <div>
                {checkboxItems.map(({ id, label }) => (
                    <p key={id} className="flex gap-2 items-center my-3 text-black">
                        <input
                            type="checkbox"
                            id={id}
                            checked={editCheckboxes[id as keyof typeof editCheckboxes]}
                            onChange={handleEditCheckboxChange}
                        />
                        <label htmlFor={id}>{label}</label>
                    </p>
                ))}
            </div>
        </div>
    );
};

export default EditActive;



