import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

interface Holiday {
  company: string;
  companyId: number;
  date: string;
  dateCreated: string;
  dateModified: string;
  holidayId: string;
  name: string;
  userCreated: string;
  userModified: string;
}

export interface HolidayState {
  loading: boolean;
  data: Array<Holiday>;
  error: string | undefined;
}
const initialState: HolidayState = {
  loading: false,
  data: [],
  error: undefined,
};
export const fetchHoliday = createAsyncThunk(
  "Holiday/fetchHoliday",
  async (companyId: number) => {
    try {
      const response = await api.fetchHoliday(companyId);
      return response;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

const holidaySlice = createSlice({
  name: "holiday",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchHoliday.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchHoliday.fulfilled,
      (state, action: PayloadAction<Array<Holiday>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(fetchHoliday.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {},
});
export const userSelector = (state: RootState) => state.holiday;
export default holidaySlice.reducer;

export const holidayData = (state: RootState) => state.holiday.data;
export const holidayLoading = (state: RootState) => state.holiday.loading;
export const holidayError = (state: RootState) => state.holiday.error;
