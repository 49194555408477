import React, { useRef } from "react";
import Hero from "./__component/Hero";
import Section2 from "./__component/Section2";
import Instructions from "./__component/Instructions";
import RiskCategory from "./__component/RiskCategory";
import GuidedQuestions from "./__component/GuidedQuestions";
import SafetyCheckliste from "./__component/SafetyCheckliste";
import Participant from "./__component/Participant";
import HomeVisit from "./__component/HomeVisit";
import ManagementPlan from "./__component/ManagementPlan";
import PrintComponent from "../PrintTab";
import Breadcrumb from "../../Components/label/BreadCrumb";

const SafetyPlan = () => {
  const componentRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <Breadcrumb />
      <PrintComponent
        componentRef={componentRef}
        triggerLabel="Print / Download"
        documentTitle="Refferal Form"
      />
      <div
        ref={componentRef}
        className="mx-auto  max-w-3xl p-4 md:p-4 lg:p-8 bg-white"
      >
        <Hero />
        <Section2 />
        <Instructions />
        <RiskCategory />
        <GuidedQuestions />
        <SafetyCheckliste />
        <Participant />
        <HomeVisit />
        <ManagementPlan />
      </div>
    </div>
  );
};

export default SafetyPlan;
