import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

interface Integration {
  clientID: string;
  company: string;
  companyId: number;
  dateCreated: string;
  dateModified: string;
  integrationId: string;
  responseUrl: string;
  scope: string;
  secretID: string;
  thirdParty: string;
  userCreated: string;
  userModified: string;
}

export interface IntegrationState {
  loading: boolean;
  data: Array<Integration>;
  error: string | undefined;
}
const initialState: IntegrationState = {
  loading: false,
  data: [],
  error: undefined,
};
export const fetchIntegration = createAsyncThunk(
  "Integration/fetchIntegration",
  async (companyId: number) => {
    try {
      const response = await api.fetchIntegration(companyId);
      return response;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

const integrationSlice = createSlice({
  name: "integration",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchIntegration.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchIntegration.fulfilled,
      (state, action: PayloadAction<Array<Integration>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(fetchIntegration.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {},
});
export const userSelector = (state: RootState) => state.integration;
export default integrationSlice.reducer;

export const integrationData = (state: RootState) => state.integration.data;
export const integrationLoading = (state: RootState) =>
  state.integration.loading;
export const integrationError = (state: RootState) => state.integration.error;
