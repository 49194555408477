import React from "react";
import { Client } from "../../../store/slices/clientSlice";
interface Props {
  clientDetail: Client | null
}
const ContactDetails = ({ clientDetail }: Props) => {
  return (
    <div className="w-full rounded-lg border mt-5 border-gray-300">
      <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
        <tbody className="divide-y divide-gray-200">
          <tr className="divide-x-2">
            <td className="Md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Participant name:
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none" >
              {clientDetail?.fullName}
            </td>
          </tr>

          <tr className="divide-x-2">
            <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Phone:
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">
              {clientDetail?.phoneNumber}
            </td>
          </tr>

          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Email:
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">
              {clientDetail?.email}
            </td>
          </tr>
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Address:
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">
              {clientDetail?.address}
            </td>
          </tr>
          <tr className="divide-x-2">
            <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Alternative Contact:
            </td>
            <td className=" px-4 py-2 text-gray-700 w-96 outline-none">
              <p className="flex gap-2">Mobile: <input type="number" className="w-[100%] outline-none border-gray-300 border p-1"
                value={clientDetail?.phoneNumber} readOnly
              />

              </p>
              <p className="flex gap-2">Other: <input type="text" className="w-[100%] outline-none border-gray-300 border p-1" /></p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ContactDetails;
