import React from 'react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
type Props = {
    videoId: string;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    description: string;
}

const VideoPopup = ({ videoId, isOpen, setIsOpen, description }: Props) => {
    return (
        <>
            <ModalVideo

                channel='custom' isOpen={isOpen} url={videoId}
                onClose={() => setIsOpen(false)}
            />
            {isOpen && (
                <div className="video-description">
                    <p>{description}</p>
                </div>
            )}
        </>
    );
};

export default VideoPopup;