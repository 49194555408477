import React from 'react'


interface Props {
    content: string;
    onView: () => void;
}
const PolicyCard = ({ onView, content }: Props) => {


    return (
        <div className=" bg-white rounded-md shadow p-2 border-s-4 border-brand overflow-clip">

            <p className='font-bold text-lg'>{content}</p>
            <div className='flex gap-3 items-center py-2 pl-2'>
                <button className='bg-gray-300 px-6 py-2 rounded-md text-primary border border-primary text-xs font-normal 
                    transition duration-300 transform active:scale-95 ease-in-out
                ' onClick={onView} >View Policy</button>

            </div>

        </div>
    )
}

export default PolicyCard