import React from 'react'
import { CompanyDetail } from '../../../types/AuthData';

interface Props {
  companyOne: CompanyDetail | null | undefined;
}
const Authority: React.FC<Props> = ({ companyOne }) => {
  return (
    <div>
      <div className=" p-4 max-[1024px]:w-[100%]  my-3 border border-black ">
        <p className="font-semibold">
          Authority for {companyOne?.companyName} to hold house key or access code
        </p>
        <p className="flex items-center my-4 gap-3">
          <input id="clre" type="checkbox" />
          <label htmlFor="clre">
            <strong>I give consent</strong> for {companyOne?.companyName} to access my home
            using my house key or access code.
          </label>
        </p>
      </div>
    </div>
  )
}

export default Authority