import dayjs from 'dayjs';
import React from 'react'
import { FiThumbsUp, FiThumbsDown } from "react-icons/fi";
import Spinner from '../../../../../Components/Spinner';

interface Props {
    subject: string;
    vote: number;
    dateCreated: string;
    loading: boolean;
    loading1: boolean;
    onAddReport?: () => void;
    onDetails: () => void;
    onUpVote: () => void;
    onDownVote: () => void;
}
const KnowledgeCard = ({ dateCreated, subject, vote, loading, loading1, onUpVote, onDownVote, onDetails, onAddReport }: Props) => {

    return (
        <div className=" bg-white rounded-md shadow p-2 border-s-4 border-brand overflow-x-hidden">
            <table>

                <tbody>

                    <tr className='text-sm truncate ...'>
                        <td className="whitespace-nowrap px-2 py-1  inline-flex items-center gap-1 font-bold">
                            Subject:
                        </td>
                        <td className="whitespace-normal px-2 py-1 text-gray-700">{subject}</td>

                    </tr>

                    {/* <tr className='text-sm truncate ...'>
                        <td className="whitespace-nowrap px-2 py-1  inline-flex items-center gap-1 font-bold">
                            Description:
                        </td>
                        <td className="whitespace-normal px-2 py-1 text-gray-700">{description?.replace(/<\/?[^>]+(>|$)|&gt;&gt;|&nbsp;/g, '')}
                        </td>

                    </tr> */}
                    <tr className='text-sm truncate ...'>
                        <td className="whitespace-nowrap px-2 py-1  inline-flex items-center gap-1 font-bold">
                            Likes:
                        </td>
                        <td className="whitespace-nowrap px-2 py-1 text-gray-700">{vote}
                        </td>

                    </tr>
                    <tr className='text-sm truncate ...'>
                        <td className="whitespace-nowrap px-2 py-1  inline-flex items-center gap-1 font-bold">
                            Date Created:
                        </td>
                        <td className="whitespace-nowrap px-2 py-1 text-gray-700">{dayjs(dateCreated).format('DD-MM-YYYY')}
                        </td>

                    </tr>
                </tbody>
            </table>
            <div className='flex  gap-3 items-center py-2 pl-2'>
                <button className='bg-gray-300 px-6 py-2 rounded-md text-primary border border-primary text-xs font-normal' onClick={onDetails} >Details</button>
                {onAddReport
                    &&
                    <button className='bg-primary px-6 py-2 rounded-md text-white text-xs fornt-normal' onClick={onAddReport}> Play </button>}
                <button className='bg-primary px-6 py-2 rounded-md text-white text-xs fornt-normal' onClick={onUpVote} >
                    {loading ? <Spinner color="#071952" /> : <FiThumbsUp size={15} />}
                </button>
                <button className='bg-gray-300 px-6 py-2 rounded-md text-primary border border-primary text-xs font-normal' onClick={onDownVote} >
                    {loading1 ? <Spinner color="#071952" /> : <FiThumbsDown size={15} />}
                </button>
            </div>

        </div>
    )
}

export default KnowledgeCard