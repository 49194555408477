import React from "react";
import SignatureComponent from "../../../Components/Signature";
import { Client } from "../../../store/slices/clientSlice";
import dayjs from "dayjs";
import { FaTimes } from "react-icons/fa";
import { CompanyDetail } from "../../../types/AuthData";
import { Representative } from "../../../types/DataType";
import { Staff } from "../../../store/slices/staffSlice";

interface Props {
  clientDetail: Client | null;
  setSignature: React.Dispatch<React.SetStateAction<Blob | null>>;
  setRepSignature: React.Dispatch<React.SetStateAction<Blob | null>>;
  handleSignatureSave: (signatureBlob: Blob) => void;
  handleRepSignatureSave: (signatureBlob: Blob) => void;
  signature: Blob | null;
  signatureRep: Blob | null;
  companyOne: CompanyDetail | null | undefined;
  clientRep: Representative | null;
  careManager: Staff | null;
  setRepName: React.Dispatch<React.SetStateAction<string>>;
  repName: string;
}

const SignatureForm = ({
  handleSignatureSave,
  signature,
  clientDetail,
  setSignature,
  companyOne,
  // careManager,
  setRepSignature,
  signatureRep,
  handleRepSignatureSave,
  setRepName,
  repName
}: Props) => {
  return (
    <div className="rounded-lg mt-7">

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border mt-5 p-2">
        <div>
          <p>Name of participant / authorised representative</p>
          <p>
            <input
              type="text"
              value={clientDetail?.fullName || ""}
              className="w-80 border-b border-0 border-b-black text-black font-bold outline-none"
              readOnly
            />
            Date: {dayjs().format("D, MMM YYYY")}
          </p>
        </div>
        <div>
          <p>Signature of participant / authorised representative</p>
          {!signature && (
            <SignatureComponent
              onSignatureSave={handleSignatureSave}
              text="Click to sign here"
            />
          )}
          <div className="flex gap-2">
            {signature && (
              <img
                src={URL.createObjectURL(signature)}
                alt="Participant Signature"
                className="img-thumbnail block"
                style={{
                  display: "block",
                  width: "150px",
                }}
              />
            )}
            {signature && (
              <button
                className="text-base text-red-600"
                type="button"
                onClick={() => setSignature(null)}
              >
                <FaTimes className="text-lg border p-1" />
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border mt-5 p-2">
        <div>
          <p>Name of representative</p>
          <p>
            <input
              type="text"
              // value={careManager?.fullName || ""}
              value={repName}
              onChange={(e) => setRepName(e.target.value)}
              className="w-80 border-b border-0 border-b-black text-black font-bold outline-none"
            // readOnly
            />
            Date: {dayjs().format("D, MMM YYYY")}
          </p>
        </div>
        <div>
          <p>Signature, representative, {companyOne?.companyName}</p>
          {/* <div className="flex gap-2">
            {companyOne?.signature && (
              <img
                src={careManager?.signatureUrl as string}
                alt="CompanyRep Signature"
                className="img-thumbnail block"
                style={{
                  display: "block",
                  width: "150px",
                }}
              />
            )}
          </div> */}
          {!signatureRep && (
            <SignatureComponent
              onSignatureSave={handleRepSignatureSave}
              text="Click to sign here"
            />
          )}
          <div className="flex gap-2">
            {signatureRep && (
              <img
                src={URL.createObjectURL(signatureRep)}
                alt="Participant Signature"
                className="img-thumbnail block"
                style={{
                  display: "block",
                  width: "150px",
                }}
              />
            )}
            {signatureRep && (
              <button
                className="text-base text-red-600"
                type="button"
                onClick={() => setRepSignature(null)}
              >
                <FaTimes className="text-lg border p-1" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignatureForm;

