import React, { FormEvent, useEffect, useRef, useState } from "react";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import SEO from "../../../constants/SEO";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import Spinner from "../../../Components/Spinner";
import PaginationComponent from "../../../Components/CustomPagination";
import fileImg from "../../../images/icon/EmptyState.png";
import UserPolicyCard from "./components/userPolicyCard";
import { fetchUserPolicy, userPolicyData, userPolicyLoading } from "../../../store/slices/userPolicySlice";
import { Button, Modal } from "rsuite";
import axiosInstance from "../../../store/axiosInstance";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";


const UserPolicyComponent = () => {

    const [pageNumber, setPageNumber] = useState(0);
    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    const companyId = user?.companyId;
    useEffect(() => {
        user?.uid && dispatch(fetchUserPolicy({ companyId: Number(companyId), uid: user?.uid, consent: false }));
    }, [dispatch, companyId]);

    const policy = useSelector(userPolicyData);
    const loadingData = useSelector(userPolicyLoading);
    const [docModal, setDocModal] = useState<boolean>(false);
    const [docUrlModal, setDocUrlModal] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const itemsPerPage = 12;
    const pageCount = Math.ceil(policy.length / itemsPerPage);
    const displayData = policy.slice(
        pageNumber * itemsPerPage,
        (pageNumber + 1) * itemsPerPage
    );
    const topOfPageRef = useRef<HTMLInputElement>(null);

    const handlePageClick = (selectedItem: { selected: number }) => {
        setPageNumber(selectedItem.selected);
        topOfPageRef.current?.scrollIntoView({
            behavior: "smooth",
        });
    };

    const ViewPolicy = (documentUrl: string): void => {
        setDocUrlModal(documentUrl); // Set the document URL in the state
        setIsLoading(true); // Start loading spinner
        setDocModal(true); // Open the modal
    };

    const getFileExtension = (url: string) => {
        return url.split('.').pop()?.toLowerCase(); // Get the file extension
    };

    const [loading1, setLoading1] = useState<boolean>(false);
    const [modal, setModal] = useState<boolean>(false);
    const [consent, setConsent] = useState<boolean>(false)
    const [approve, setApprove] = useState<boolean>(false);
    const [saveId, setSaveId] = useState<number>(0)

    // const Agreed = (id: number) => {
    //     setSaveId(id)
    //     setModal(true)
    // }

    const Agreed = (id: number, currentConsent: boolean) => {
        setSaveId(id);
        setConsent(currentConsent); // Set the consent state based on the current agreed value
        setModal(true);
    };

    const handleChangeConsent = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value === "true";
        setApprove(value);
        if (user?.uid) {
            dispatch(fetchUserPolicy({ companyId: Number(companyId), uid: user?.uid, consent: value }));
        }
    }

    const handleAgreed = async (e: FormEvent) => {
        e.preventDefault();
        setLoading1(true);
        try {
            const { data } = await axiosInstance.get(
                `/PolicyDocuments/consent?userpolicyId=${saveId}&userId=${user?.uid}&consent=${consent}`
            );
            toast.success(data.message);
            setModal(false);
            user?.uid && dispatch(fetchUserPolicy({ companyId: Number(companyId), uid: user?.uid, consent: false }));
            setLoading1(false);
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // console.log(error);;
            } else {
                toast.error("An error occurred");
            }
            setLoading1(false);
            setModal(false);
        } finally {
            setLoading1(false);
        }
    };

    return (
        <>
            <SEO
                title={`Policies`}
                description="Company Policies"
                name="Promax-Care"
            />
            <Breadcrumb showNavigation={false} />

            <div
                className="flex justify-between items-center flex-wrap"
                ref={topOfPageRef}
            >
                <h2 className="font-bold mt-5 text-2xl text-black w-auto">Policies</h2>
                <div className=" relative">
                    <label htmlFor="Consent" className="sr-only">
                        Consent
                    </label>
                    <select
                        id="Consent"
                        value={approve.toString()}
                        onChange={handleChangeConsent}
                        className="p-2 rounded-md w-40 border focus-within:border-primary focus-within:ring-1 focus-within:ring-primary outline-none border-gray-300 text-gray-700 sm:text-sm"
                    >
                        <option value="false">Not Agreed Policy</option>
                        <option value="true">Agreed Policy</option>

                    </select>
                </div>
            </div>

            {policy.length <= 0 && loadingData && <Spinner color="#071952" />}
            {!loadingData && policy.length <= 0 && (
                <div className=" border border-dashed flex justify-center flex-col items-center my-5 rounded py-10 space-y-2">
                    <img src={fileImg} alt="file upload icon" width={200} height={300} />

                    <strong className="text-xl">No Data Available</strong>
                </div>
            )}
            <div className="my-5">
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 ">
                    {displayData.length > 0 &&
                        displayData.map((item) => (
                            <UserPolicyCard
                                key={item.policyDocumentId}
                                content={item.policyDocument.policyName}
                                category={item.policyDocument.category}
                                agreed={item.consent}
                                onView={() => {
                                    ViewPolicy(item.policyDocument.policyUrl); // Call a function and pass the categoryCode
                                }}
                                onAgreed={() => Agreed(item.userPolicyConsentId, item.consent)}
                            />
                        ))}
                </div>
                {pageCount > 1 && (
                    <PaginationComponent
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                    />
                )}
                <br />
            </div>

            <Modal
                open={modal}
                onClose={() => setModal(false)}
                role="alertdialog"
                backdrop="static"
                autoFocus
                size={"xs"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">I Agree and Consent to this Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <div className="mt-2 flex items-center gap-1">
                            <input
                                type="checkbox"
                                checked={consent}
                                onChange={() => setConsent(!consent)}
                                className=" border-b border-0 border-b-black outline-none"
                            />
                            <p className="text-black leading-8">
                                I have read and agreed to the policy statement.
                            </p>
                        </div>

                        <div className="mt-2 flex justify-center items-center">
                            <Button
                                onClick={handleAgreed}
                                className="bg-primary px-6 py-2 rounded-md text-white border border-gray-300 text-xs fornt-normal
                                transition duration-300 transform active:scale-95 ease-in-out"
                            >
                                {loading1 ? <Spinner /> : "Submit"}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>

            <Modal
                open={docModal}
                onClose={() => setDocModal(false)}
                backdrop="static"
                autoFocus
                size={"lg"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">View Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        {isLoading && (
                            <div className="text-center">
                                <Spinner /> {/* Show spinner while iframe is loading */}
                                <p>Loading document...</p>
                            </div>
                        )}

                        {getFileExtension(docUrlModal) === 'pdf' ? (
                            <iframe
                                src={docUrlModal}
                                width="100%"
                                height="500px"
                                title="PDF Viewer"
                                style={{ display: isLoading ? "none" : "block" }} // Hide iframe until loaded
                                onLoad={() => setIsLoading(false)} // Stop loading spinner when iframe loads
                            />
                        ) : (
                            <iframe
                                src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(docUrlModal)}`}
                                width="100%"
                                height="500px"
                                title="Office Online Viewer"
                                style={{ display: isLoading ? "none" : "block" }} // Hide iframe until loaded
                                onLoad={() => setIsLoading(false)} // Stop loading spinner when iframe loads
                            />
                        )}
                    </>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setDocModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default UserPolicyComponent;
