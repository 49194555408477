import React from 'react';
import { SupportPlaData } from '../../../types/DataType';

interface Props {
    supportForm: SupportPlaData;
    handleInputFormChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;

}

const EditDecision: React.FC<Props> = ({ supportForm, handleInputFormChange }) => {

    return (
        <div>
            <table className="w-full mb-6">
                <tbody>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Independent</td>
                        <td className="border px-4 py-2">
                            <div className="flex items-center">
                                <label className="mr-4">
                                    <input
                                        type="radio"
                                        name="independent"
                                        className="mr-2"
                                        value={supportForm.independent}
                                        checked={supportForm.independent !== ''}
                                        onChange={handleInputFormChange}
                                    /> Yes
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="independent"
                                        className="mr-2"
                                        value=""
                                        checked={supportForm.independent === ''}
                                        onChange={handleInputFormChange}
                                    /> No
                                </label>
                            </div>
                            {supportForm.independent && (
                                <textarea
                                    className="w-full p-2 border border-gray-300 rounded mt-2"
                                    placeholder="If yes, provide details"
                                    name="independent"
                                    value={supportForm.independent}
                                    onChange={handleInputFormChange}
                                />
                            )}
                        </td>
                    </tr>

                    <tr>
                        <td className="border px-4 py-2 font-bold">Supported</td>
                        <td className="border px-4 py-2">
                            <div className="flex items-center">
                                <label className="mr-4">
                                    <input
                                        type="radio"
                                        name="supported"
                                        className="mr-2"
                                        value={supportForm.supported}
                                        checked={supportForm.supported !== ''}
                                        onChange={handleInputFormChange}
                                    /> Yes
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="supported"
                                        className="mr-2"
                                        value=""
                                        checked={supportForm.supported === ''}
                                        onChange={handleInputFormChange}
                                    /> No
                                </label>
                            </div>
                            {supportForm.supported && (
                                <textarea
                                    className="w-full p-2 border border-gray-300 rounded mt-2"
                                    placeholder="If yes, provide details"
                                    name="supported"
                                    value={supportForm.supported}
                                    onChange={handleInputFormChange}
                                />
                            )}
                        </td>
                    </tr>

                    <tr>
                        <td className="border px-4 py-2 font-bold">Guardianship</td>
                        <td className="border px-4 py-2">
                            <div className="flex items-center">
                                <label className="mr-4">
                                    <input
                                        type="radio"
                                        name="guardianship"
                                        className="mr-2"
                                        value={supportForm.guardianship}
                                        checked={supportForm.guardianship !== ''}
                                        onChange={handleInputFormChange}
                                    /> Yes
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="guardianship"
                                        className="mr-2"
                                        value=""
                                        checked={supportForm.guardianship === ''}
                                        onChange={handleInputFormChange}
                                    /> No
                                </label>
                            </div>
                            {supportForm.guardianship && (
                                <textarea
                                    className="w-full p-2 border border-gray-300 rounded mt-2"
                                    placeholder="If yes, provide details"
                                    name="guardianship"
                                    value={supportForm.guardianship}
                                    onChange={handleInputFormChange}
                                />
                            )}
                        </td>
                    </tr>

                    <tr>
                        <td className="border px-4 py-2 font-bold">Financial</td>
                        <td className="border px-4 py-2">
                            <div className="flex flex-wrap">
                                <label className="mr-4">
                                    <input
                                        type="radio"
                                        name="financial"
                                        className="mr-2"
                                        value="Self-managed"
                                        checked={supportForm.financial === 'Self-managed'}
                                        onChange={handleInputFormChange}
                                    /> Self-managed
                                </label>
                                <label className="mr-4">
                                    <input
                                        type="radio"
                                        name="financial"
                                        className="mr-2"
                                        value="Financial trustee"
                                        checked={supportForm.financial === 'Financial trustee'}
                                        onChange={handleInputFormChange}
                                    /> Financial trustee (if ticked provide details)
                                </label>
                                <label className="mr-4">
                                    <input
                                        type="radio"
                                        name="financial"
                                        className="mr-2"
                                        value="Support from family"
                                        checked={supportForm.financial === 'Support from family'}
                                        onChange={handleInputFormChange}
                                    /> Support from family member or carer (if ticked provide details)
                                </label>
                            </div>
                            {(supportForm.financial === 'Financial trustee' || supportForm.financial === 'Support from family') && (
                                <textarea
                                    className="w-full p-2 border border-gray-300 rounded mt-2"
                                    placeholder="If yes, provide details"
                                    name="financial"
                                    value={supportForm.financial}
                                    onChange={handleInputFormChange}
                                />
                            )}

                            {(supportForm.financial !== 'Financial trustee' &&
                                supportForm.financial !== 'Support from family' &&
                                supportForm.financial !== 'Self-managed'

                            ) && (
                                    <textarea
                                        className="w-full p-2 border border-gray-300 rounded mt-2"
                                        placeholder="If yes, provide details"
                                        name="financial"
                                        value={supportForm.financial}
                                        onChange={handleInputFormChange}
                                    />
                                )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default EditDecision;
