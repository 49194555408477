import React, { useEffect } from "react";
import SEO from "../../../constants/SEO";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import FeaturedDocument from "../../Admin/Profiles/staffTab/Document";
import { StaffProfileTypes } from "../../../types/AuthData";
import { AppDispatch, useAppDispatch } from "../../../store/store";
import {
  fetchStaffProfile,
  selectProfileData,
  selectProfileLoading,
} from "../../../store/slices/staffProfileSlice";
import { useSelector } from "react-redux";
import { Loader } from "rsuite";

const StaffDocument = () => {
  const staffProfileString = localStorage.getItem("staffProfile");
  const staffProfile: StaffProfileTypes = staffProfileString
    ? JSON.parse(staffProfileString)
    : null;
  const dispatch: AppDispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchStaffProfile(staffProfile?.staffId.toString()));
  }, []);

  const profile = useSelector(selectProfileData);
  const loadingData = useSelector(selectProfileLoading);
  return (
    <>
      <SEO
        title={` Staff Progress Note`}
        description="Staff"
        name="Promax-Care"
      />
      <Breadcrumb showNavigation={false} />

      <div>
        {loadingData ? (
          <div className="mx-auto justify-center items-center flex">
            <Loader size="lg" />
          </div>
        ) : (
          <FeaturedDocument
            uid={staffProfile?.staffId.toString()}
            staffName={profile?.fullName as string}
          />
        )}
      </div>
    </>
  );
};

export default StaffDocument;
