import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

export interface Ticket {
  ticketId: number;
  ticketType: string;
  subject: string;
  description: string;
  imageURL: string;
  imageFile: string;
  user: string;
  isOpen: boolean;
  isClosed: boolean;
  excalate: boolean;
  companyId: number;
  company: {
    companyId: number;
    companyName: string;
    companyEmail: string;
    companyAddress: string;
    companyPhone: string;
    companyState: string;
    companyDetails: string;
    companyLogo: string;
    companyLogoFile: string;
    companyHead: string;
    isApproved: boolean;
    packagesId: number;
    packages: string;
    subscribtionDate: string;
    expirationDate: string;
    dateCreated: string;
    userCreated: string;
    dateModified: string;
    userModified: string;
  };
  dateCreated: string;
  userCreated: string;
  dateModified: string;
  userModified: string;
}

export interface TicketState {
  loading: boolean;
  data: Array<Ticket>;
  error: string | undefined;
}
const initialState: TicketState = {
  loading: false,
  data: [],
  error: undefined,
};
export const fetchTicket = createAsyncThunk(
  "Ticket/fetchTicket",
  async (companyId: string | number) => {
    try {
      const response = await api.fetchTicket(companyId);
      return response;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

const ticketSlice = createSlice({
  name: "ticket",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchTicket.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchTicket.fulfilled,
      (state, action: PayloadAction<Array<Ticket>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(fetchTicket.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {},
});
export const userSelector = (state: RootState) => state.ticket;
export default ticketSlice.reducer;

// Define selectors to access the profile slice state
export const ticketData = (state: RootState) => state.ticket.data;
export const ticketLoading = (state: RootState) => state.ticket.loading;
export const ticketError = (state: RootState) => state.ticket.error;
