import React from "react";
import { CompanyDetail } from "../../../types/AuthData";
import { Client } from "../../../store/slices/clientSlice";
// import { Checkboxes } from "..";
import EditForm from "./EditForm";
import EditActive from "./EditActive";
import { ParticipantCheckList } from "../../../types/DataType";
interface Props {
    companyOne: CompanyDetail | null | undefined;
    clientDetail: Client | null;
    participantCheck: ParticipantCheckList
    editCheckboxes: {
        sign: boolean;
        consent: boolean;
        support: boolean;
        safety: boolean;
        manage: boolean;
        chart: boolean;
        plan: boolean;
    }
    handleEditCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleInputDataChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
}

const EditHero = ({ companyOne, clientDetail, handleInputDataChange, participantCheck, editCheckboxes, handleEditCheckboxChange }: Props) => {
    return (
        <div>
            <div className="flex justify-end">
                <img
                    className="w-24  mb-4 lg:mb-4"
                    src={companyOne?.companyLogo}
                    alt="Company Logo"
                />
            </div>
            <div>
                <p className="text-[20px] text-black font-semibold">Participant file checklist</p>
                <EditForm clientDetail={clientDetail} participantCheck={participantCheck} handleInputDataChange={handleInputDataChange} dateChecked={""} />
                <p className="mt-3 text-black">Instructions: current participant records are to be stored in their active paper-based and e-folders. Documents that have been superseded are to be marked ‘superseded’. Hard copies of superseded documents are to be destroyed once an e-copy has been uploaded into the participant’s e-file.</p>
                <EditActive handleEditCheckboxChange={handleEditCheckboxChange}
                    editCheckboxes={editCheckboxes} />
            </div>
        </div>
    );
};

export default EditHero;
