import React from 'react';
import { SupportPlaData } from '../../../types/DataType';

interface Props {
    supportForm: SupportPlaData;
    handleInputFormChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;

}

const EditCultural: React.FC<Props> = ({ supportForm, handleInputFormChange }) => {


    return (
        <div>
            <table className="w-full mb-6">
                <tbody>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Cultural Identity:</td>
                        <td className="border px-4 py-2">
                            <div className="flex flex-wrap">
                                <label className="mr-4">
                                    <input
                                        type="radio"
                                        name="culturalIdentity"
                                        className="mr-2"
                                        value="Aboriginal or Torres Strait Islander"
                                        checked={supportForm.culturalIdentity === 'Aboriginal or Torres Strait Islander'}
                                        onChange={handleInputFormChange}
                                    />{' '}
                                    Aboriginal or Torres Strait Islander
                                </label>
                                <label className="mr-4">
                                    <input
                                        type="radio"
                                        name="culturalIdentity"
                                        className="mr-2"
                                        value="Culturally and linguistically diverse background (CALD)"
                                        checked={supportForm.culturalIdentity === 'Culturally and linguistically diverse background (CALD)'}
                                        onChange={handleInputFormChange}
                                    />{' '}
                                    Culturally and linguistically diverse background (CALD)
                                </label>
                                <label className="mr-4">
                                    <input
                                        type="radio"
                                        name="culturalIdentity"
                                        className="mr-2"
                                        value=""
                                        checked={supportForm.culturalIdentity !== 'Aboriginal or Torres Strait Islander' && supportForm.culturalIdentity !== 'Culturally and linguistically diverse background (CALD)' && supportForm.culturalIdentity !== ''}
                                        onChange={handleInputFormChange}
                                    />{' '}
                                    Other, please describe:
                                </label>
                            </div>

                            {supportForm.culturalIdentity !== 'Aboriginal or Torres Strait Islander' && supportForm.culturalIdentity !== 'Culturally and linguistically diverse background (CALD)' && (
                                <textarea
                                    className="w-full p-2 border border-gray-300 rounded mt-2"
                                    placeholder="Please describe"
                                    name="culturalIdentity"
                                    value={supportForm.culturalIdentity}
                                    onChange={handleInputFormChange}
                                />
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Religious or spiritual needs:</td>
                        <td className="border px-4 py-2">
                            <input
                                type="text"
                                name="religiousNeeds"
                                className="w-full p-2 border border-gray-300 rounded"
                                value={supportForm.religiousNeeds}
                                onChange={handleInputFormChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Cultural needs or preferences:</td>
                        <td className="border px-4 py-2">
                            <input
                                type="text"
                                name="culturalNeeds"
                                className="w-full p-2 border border-gray-300 rounded"
                                value={supportForm.culturalNeeds}
                                onChange={handleInputFormChange}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default EditCultural;

