import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../api";
import { isAxiosError } from "axios";
import { Client } from "../clientSlice";
import { RootState } from "../../store";

export interface Schedule {
  scheduleId: number;
  profileId: number;
  profile: Client;
  supportType: string;
  quantity: number;
  cost: number;
  frequency: string;
  itemNumber: string;
  companyID: number;
  dateCreated: string;
  userCreated: string | null;
  dateModified: string;
  userModified: string | null;
}

export interface ClientSupportScheduleState {
  loading: boolean;
  data: Array<Schedule>;
  error: string | undefined;
}
const initialState: ClientSupportScheduleState = {
  loading: false,
  data: [],
  error: undefined,
};
export const fetchClientSupportSchedule = createAsyncThunk(
  "ClientSupportSchedule/fetchClientSupportSchedule",
  async (clientId: number) => {
    try {
      const response = await api.fetchClientSupportSchedule(clientId);
      return response;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

const clientSupportScheduleSlice = createSlice({
  name: "clientSchedule",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchClientSupportSchedule.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchClientSupportSchedule.fulfilled,
      (state, action: PayloadAction<Array<Schedule>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(fetchClientSupportSchedule.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {},
});
export const userSelector = (state: RootState) => state.clientSupportSchedule;
export default clientSupportScheduleSlice.reducer;

export const clientScheduleSupportData = (state: RootState) =>
  state.clientSupportSchedule.data;
export const clientScheduleSupportLoading = (state: RootState) =>
  state.clientSupportSchedule.loading;
export const clientScheduleSupportError = (state: RootState) =>
  state.clientSupportSchedule.error;
