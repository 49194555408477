import React from "react";
import { CompanyDetail } from "../../../types/AuthData";
import { Client } from "../../../store/slices/clientSlice";
import { ParticipantConsentForm } from "../../../types/DataType";
import EditForm from "./EditForm";

interface Props {
    companyOne: CompanyDetail | null | undefined;
    clientDetail: Client | null;
    participantConsentForm: ParticipantConsentForm;
    handleInputDataChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    handleRadioDataChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const EditHero = ({ companyOne, clientDetail, participantConsentForm, handleInputDataChange, handleRadioDataChange }: Props) => {
    return (
        <div>
            <div className="flex justify-end w-[100%]">
                <img
                    className="w-24 "
                    src={companyOne?.companyLogo}
                    alt="Company Logo"
                />
            </div>
            <h1 className="font-medium text-2xl">Consent Form</h1>
            <EditForm clientDetail={clientDetail} participantConsentForm={participantConsentForm}
                handleInputDataChange={handleInputDataChange} handleRadioDataChange={handleRadioDataChange}
            />
        </div>
    );
};

export default EditHero;
