import React from "react";
import Form from "./Form";
import { CompanyDetail } from "../../../types/AuthData";
import { Client } from "../../../store/slices/clientSlice";

interface Props {
  companyOne: CompanyDetail | null | undefined;
  clientDetail: Client | null;
  formData: {
    date: string;
    participantProvidingConsent: boolean;
    authorisedPersonProvidingConsent: boolean;
    nameOfAuthorisedPerson: string;
    relationshipToParticipant: string;
  }
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleRadioChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const Hero = ({ companyOne, clientDetail, formData, handleChange, handleRadioChange }: Props) => {
  return (
    <div>
      <div className="flex justify-end w-[100%]">
        <img
          className="w-24 "
          src={companyOne?.companyLogo}
          alt="Company Logo"
        />
      </div>
      <h1 className="font-medium text-2xl">Consent Form</h1>
      <Form clientDetail={clientDetail} formData={formData}
        handleChange={handleChange} handleRadioChange={handleRadioChange}
      />
    </div>
  );
};

export default Hero;
