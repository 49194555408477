import React, { FormEvent, useEffect, useRef, useState } from "react";
import PrintComponent from "../PrintTab";
import Breadcrumb from "../../Components/label/BreadCrumb";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchClientInfo,
  fetchClientRepresentativeData,
  fetchCompany,
  fetchSupportPladoc,
} from "../../services/fetchServices";
import { Client } from "../../store/slices/clientSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { CompanyDetail } from "../../types/AuthData";
import Spinner from "../../Components/Spinner";
import Date from "./__component/Date";
import axiosInstance from "../../store/axiosInstance";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import { Representative, SupportPlaData } from "../../types/DataType";
import SupportPlan from "./__component/SupportPlan";
import EditSupportPlanForm from "./EditSupportPlanForm";

export interface FormDataType {
  contactSituations: string;
  isGuardian: boolean;
  decisionMakingFunctions: string;
  details: string;
  hasCourtOrder: boolean;
  communication: string;
  consentForm: boolean;
  identity: string;
  religiousNeeds: string;
  culturalNeeds: string;
  independent: string;
  independentDetails: string;
  supported: string;
  supportedDetails: string;
  guardianship: string;
  guardianshipDetails: string;
  financial: string;
  financialDetails: string;
  healthIssueSummary: string;
  communications: string;
  bodyLanguage: string;
  responseRequired: string;
  notifyPeople: string;
  contactImmediately: string;
  evacuationLocation: string;
  medications: string;
  evacuationProcedures: string;
  changesToRoutine: string;
  noResponseProtocols: string;
  serviceDisruptionProtocols: string;
}

export type RowKeys = "workingWell" | "notWorkingWell";

export interface Row {
  workingWell: string;
  notWorkingWell: string;
}

const SupportPla = () => {
  const componentRef = useRef<HTMLDivElement>(null);
  const { uid } = useParams();
  const [clientDetail, setClientDetail] = useState<Client | null>(null);
  const [careManager, setCareManager] = useState<Representative | null>(null);
  const [loadingClient, setLoadingClient] = useState<boolean>(true);
  const user = useSelector((state: RootState) => state?.auth?.user);
  const [companyOne, setCompanyOne] = useState<
    CompanyDetail | null | undefined
  >(null);

  useEffect(() => {
    fetchClientData();
    handleCompanyFetch();
    fetchClientManager();
    fetchDoc();
  }, [uid, clientDetail?.careManager]);

  const handleCompanyFetch = async () => {
    // setLoading(true);
    if (user?.companyId) {
      const data = await fetchCompany(user?.companyId);
      setCompanyOne(data);
    }
    // setLoading(false);
  };
  const fetchClientData = async () => {
    const data = await fetchClientInfo(uid);
    setClientDetail(data);
    setLoadingClient(false);
  };

  //   const fetchClientManager = async () => {
  //     if (clientDetail?.careManager) {
  //       const data = await fetchClientManagerProfle(
  //         Number(user?.companyId),
  //         clientDetail?.careManager
  //       );
  //       setCareManager(data);
  //     }
  //   };

  const fetchClientManager = async () => {
    const data = await fetchClientRepresentativeData(Number(uid));
    setCareManager(data[0]);
  };

  const [values, setValues] = useState({
    disability: "",
    secondaryDisability: "",
    meetingDate: "",
    staffingRequest: "",
  });

  const handleInputDataChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [checker, setChecker] = useState<SupportPlaData | null>(null);
  const [supportForm, setSupportForm] = useState<SupportPlaData>({
    supportPlanId: 0,
    attendees: "",
    meetingDate: "",
    situationOfContact: "",
    isAppointedGuardian: false,
    decisionMakingFunctions: "",
    courtOrder: "",
    nonCommunicablePerson: "",
    otherCommunication: "",
    culturalIdentity: "",
    religiousNeeds: "",
    culturalNeeds: "",
    independent: "",
    supported: "",
    guardianship: "",
    financial: "",
    supportRequirements: "",
    risk1: "",
    strategiesToManageRisk1: "",
    risk2: "",
    strategiesToManageRisk2: "",
    risk3: "",
    strategiesToManageRisk3: "",
    risk4: "",
    strategiesToManageRisk4: "",
    risk5: "",
    strategiesToManageRisk5: "",
    isConsentFormAttached: false,
    healthIssueSummary: "",
    healthIssueCommunication: "",
    response: "",
    staffNotify: "",
    peopleOfContact: "",
    locationOfEvacuation: "",
    thingsToBeTakenAlong: "",
    evacuationProceedure: "",
    routineChanges: "",
    noResponseProtocols: "",
    distuptedSeriveProtocols: "",
    supportPlanReview: "",
    profileId: 0,
    document_Name: "",
    implementationDate: "",
    expirationDate: "",
    companyId: 0,
  });
  const [editAttendee, setEditAttendee] = useState<string[]>([]);
  const [editSupport, setEditSupport] = useState<string[]>([]);

  const fetchDoc = async () => {
    const data = await fetchSupportPladoc(
      Number(uid),
      Number(user?.companyId),
      "PCD-106 P07 Support plan"
    );
    setSupportForm({ ...data });
    setChecker(data);
    data?.attendees &&
      setEditAttendee(
        data?.attendees.split(",").map((activity: string) => activity.trim())
      );
    data?.supportRequirements &&
      setEditSupport(
        data?.supportRequirements
          .split(",")
          .map((activity: string) => activity.trim())
      );
  };

  const handleInputEditChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newActivities = [...editAttendee];
    newActivities[index] = event.target.value;
    setEditAttendee(newActivities);
  };

  const handleAddRowEdit = () => {
    setEditAttendee([...editAttendee, ""]);
  };

  const handleInputEditChangeSupport = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newActivities = [...editSupport];
    newActivities[index] = event.target.value;
    setEditSupport(newActivities);
  };

  const handleAddRowEditSupport = () => {
    setEditSupport([...editSupport, ""]);
  };

  const handleRadioDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSupportForm((prevState) => ({
      ...prevState,
      [name]: value === "true",
    }));
  };

  const handleInputDataEditChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value, type } = e.target;

    if (type === "checkbox") {
      const checked = (e.target as HTMLInputElement).checked;
      setSupportForm((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setSupportForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    // const { name, value } = e.target;
    // setSupportForm((prevState) => ({
    //     ...prevState,
    //     [name]: value,
    // }));
  };
  const [loading, setLoading] = useState<boolean>(false);
  const ImplementationDate = useRef<HTMLInputElement>(null);
  const ExpirationDate = useRef<HTMLInputElement>(null);
  const [attendeeValues, setAttendeeValues] = useState<string[]>([""]);

  const handleInputChange = (index: number, value: string) => {
    const newValues = [...attendeeValues];
    newValues[index] = value;
    setAttendeeValues(newValues);
  };

  const handleAddRow = () => {
    setAttendeeValues([...attendeeValues, ""]);
  };

  const [supportRequired, setSupportRequired] = useState<string[]>([""]);

  const handleSupportInputChange = (index: number, value: string) => {
    const newValues = [...supportRequired];
    newValues[index] = value;
    setSupportRequired(newValues);
  };

  const handleSupportAddRow = () => {
    setSupportRequired([...supportRequired, ""]);
  };

  const [otherValues, setOtherValues] = useState({
    isNDISPlanAttached: false,
    isConsentSigned: false,
    staffingRequest: false,
  });

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setOtherValues((prevState) => ({
      ...prevState,
      [name]: value === "true",
    }));
  };

  const [formData, setFormData] = useState<FormDataType>({
    contactSituations: "",
    isGuardian: false,
    consentForm: false,
    decisionMakingFunctions: "",
    details: "",
    hasCourtOrder: false,
    communication: "",
    identity: "",
    religiousNeeds: "",
    culturalNeeds: "",
    independent: "false",
    independentDetails: "",
    supported: "false",
    supportedDetails: "",
    guardianship: "false",
    guardianshipDetails: "",
    financial: "false",
    financialDetails: "",
    healthIssueSummary: "",
    communications: "",
    bodyLanguage: "",
    responseRequired: "",
    notifyPeople: "",
    contactImmediately: "",
    evacuationLocation: "",
    medications: "",
    evacuationProcedures: "",
    changesToRoutine: "",
    noResponseProtocols: "",
    serviceDisruptionProtocols: "",
  });

  const handleInputFormChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value, type } = e.target;

    if (type === "checkbox") {
      const checked = (e.target as HTMLInputElement).checked;
      setFormData((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    // const { name, value } = e.target;
    // setFormData((prevState) => ({
    //     ...prevState,
    //     [name]: value,
    // }));
  };

  const handleRadioFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value === "true",
    }));
  };

  const [rows, setRows] = useState<Row[]>([
    { workingWell: "", notWorkingWell: "" },
    { workingWell: "", notWorkingWell: "" },
    { workingWell: "", notWorkingWell: "" },
    { workingWell: "", notWorkingWell: "" },
    { workingWell: "", notWorkingWell: "" },
  ]);

  const handleCellChange = (
    rowIndex: number,
    cellName: RowKeys,
    value: string
  ) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex][cellName] = value;
    setRows(updatedRows);
  };

  const navigate = useNavigate();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const expirationDateValue = ExpirationDate.current?.value;

    const info = {
      attendees: attendeeValues.join(", "),
      // disability: values.disability,
      meetingDate: values.meetingDate,
      situationOfContact: formData.contactSituations,
      isAppointedGuardian: formData.isGuardian,
      decisionMakingFunctions: formData.decisionMakingFunctions,
      courtOrder: formData.details,
      otherCommunication: formData.communication,
      culturalIdentity: formData.identity,
      religiousNeeds: formData.religiousNeeds,
      culturalNeeds: formData.culturalNeeds,
      independent: formData.independentDetails,
      supported: formData.supportedDetails,
      guardianship: formData.guardianshipDetails,
      financial: formData.financialDetails,
      supportRequirements: supportRequired.join(", "),
      risk1: rows[0].workingWell,
      strategiesToManageRisk1: rows[0].notWorkingWell,
      risk2: rows[1].workingWell,
      strategiesToManageRisk2: rows[1].notWorkingWell,
      risk3: rows[2].workingWell,
      strategiesToManageRisk3: rows[2].notWorkingWell,
      risk4: rows[3].workingWell,
      strategiesToManageRisk4: rows[3].notWorkingWell,
      risk5: rows[4].workingWell,
      strategiesToManageRisk5: rows[4].notWorkingWell,
      isConsentFormAttached: formData.consentForm,
      healthIssueSummary: formData.healthIssueSummary,
      healthIssueCommunication: formData.communications,
      response: formData.responseRequired,
      staffNotify: formData.notifyPeople,
      peopleOfContact: formData.contactImmediately,
      locationOfEvacuation: formData.evacuationLocation,
      thingsToBeTakenAlong: formData.medications,
      evacuationProceedure: formData.evacuationProcedures,
      routineChanges: formData.changesToRoutine,
      noResponseProtocols: formData.noResponseProtocols,
      distuptedSeriveProtocols: formData.serviceDisruptionProtocols,
      profileId: uid,
      document_Name: "PCD-106 P07 Support plan",
      implementationDate: ImplementationDate.current?.value,
      ...(expirationDateValue ? { expirationDate: expirationDateValue } : {}),
      companyId: user?.companyId,
    };

    try {
      setLoading(true);

      const { data } = await axiosInstance.post(
        `/SupportPlans/fill_form?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      setLoading(false);
      navigate(
        `/app/user/clients/${clientDetail?.profileId}/${clientDetail?.firstName}`
      );
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleEditSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const info = {
      supportPlanId: supportForm.supportPlanId,
      attendees: editAttendee.join(", "),
      meetingDate: supportForm.meetingDate,
      situationOfContact: supportForm.situationOfContact,
      isAppointedGuardian: supportForm.isAppointedGuardian,
      decisionMakingFunctions: supportForm.decisionMakingFunctions,
      courtOrder: supportForm.courtOrder,
      otherCommunication: supportForm.otherCommunication,
      culturalIdentity: supportForm.culturalIdentity,
      religiousNeeds: supportForm.religiousNeeds,
      culturalNeeds: supportForm.culturalNeeds,
      independent: supportForm.independent,
      supported: supportForm.supported,
      guardianship: supportForm.guardianship,
      financial: supportForm.financial,
      supportRequirements: editSupport.join(", "),
      risk1: supportForm.risk1,
      risk2: supportForm.risk2,
      risk3: supportForm.risk3,
      risk4: supportForm.risk4,
      risk5: supportForm.risk5,
      strategiesToManageRisk1: supportForm.strategiesToManageRisk1,
      strategiesToManageRisk2: supportForm.strategiesToManageRisk2,
      strategiesToManageRisk3: supportForm.strategiesToManageRisk3,
      strategiesToManageRisk4: supportForm.strategiesToManageRisk4,
      strategiesToManageRisk5: supportForm.strategiesToManageRisk5,
      isConsentFormAttached: supportForm.isConsentFormAttached,
      healthIssueSummary: supportForm.healthIssueSummary,
      healthIssueCommunication: supportForm.healthIssueCommunication,
      response: supportForm.response,
      staffNotify: supportForm.staffNotify,
      peopleOfContact: supportForm.peopleOfContact,
      locationOfEvacuation: supportForm.locationOfEvacuation,
      thingsToBeTakenAlong: supportForm.thingsToBeTakenAlong,
      evacuationProceedure: supportForm.evacuationProceedure,
      routineChanges: supportForm.routineChanges,
      noResponseProtocols: supportForm.noResponseProtocols,
      distuptedSeriveProtocols: supportForm.distuptedSeriveProtocols,
      profileId: uid,
      document_Name: "PCD-106 P07 Support plan",
      implementationDate: supportForm.implementationDate,
      expirationDate: supportForm.expirationDate,
      companyId: user?.companyId,
    };

    try {
      setLoading(true);

      const { data } = await axiosInstance.post(
        `/SupportPlans/edit/${supportForm.supportPlanId}?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      setLoading(false);
      navigate(
        `/app/user/clients/${clientDetail?.profileId}/${clientDetail?.firstName}`
      );
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Breadcrumb />
      {loadingClient && (
        <div className="fixed  inset-0 z-[9999]  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-auto max-w-lg mx-auto">
            <div
              className="relative z-[999] flex justify-center items-center p-4  duration-500 ease-in-out bg-white
border-0 rounded-lg shadow-lg outline-none focus:outline-none"
            >
              <Spinner color="#071952" />
            </div>
          </div>

          <div className="fixed inset-0 z-40 bg-gray-300 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
        </div>
      )}

      {checker ? (
        <EditSupportPlanForm
          companyOne={companyOne}
          clientDetail={clientDetail}
          careManager={careManager}
          handleAddRowEdit={handleAddRowEdit}
          handleInputEditChange={handleInputEditChange}
          handleRadioDataChange={handleRadioDataChange}
          handleEditSubmit={handleEditSubmit}
          loading={loading}
          handleAddRowEditSupport={handleAddRowEditSupport}
          handleInputEditChangeSupport={handleInputEditChangeSupport}
          editAttendee={editAttendee}
          editSupport={editSupport}
          supportForm={supportForm}
          handleInputDataEditChange={handleInputDataEditChange}
        />
      ) : (
        <form onSubmit={handleSubmit}>
          <PrintComponent
            componentRef={componentRef}
            triggerLabel="Print / Download"
            documentTitle="Support Plan Form"
          />
          <div
            ref={componentRef}
            className="mx-auto  max-w-3xl p-4 md:p-4 lg:p-8 bg-white"
          >
            <div className="flex justify-end">
              <img
                className="w-24  mb-4 lg:mb-4"
                src={companyOne?.companyLogo}
                alt="Company Logo"
              />
            </div>

            <SupportPlan
              clientDetail={clientDetail}
              careManager={careManager}
              values={values}
              handleInputDataChange={handleInputDataChange}
              handleInputChange={handleInputChange}
              attendeeValues={attendeeValues}
              handleAddRow={handleAddRow}
              otherValues={otherValues}
              handleRadioChange={handleRadioChange}
              formData={formData}
              handleInputFormChange={handleInputFormChange}
              handleRadioFormChange={handleRadioFormChange}
              handleSupportAddRow={handleSupportAddRow}
              handleSupportInputChange={handleSupportInputChange}
              supportRequired={supportRequired}
              handleCellChange={handleCellChange}
              rows={rows}
              companyOne={companyOne}
            />

            <Date
              ExpirationDate={ExpirationDate}
              ImplementationDate={ImplementationDate}
            />
          </div>
          <div className="mx-auto text-center mt-3 lg:col-span-2">
            <button
              type="submit"
              disabled={loading}
              className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"
            >
              {loading ? <Spinner /> : "Submit"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default SupportPla;
