import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

export interface AllUserPolicy {
    consent: boolean;
    policyDocument: {
        category: string;
        categoryCode: string;
        companyId: number;
        policyDocumentId: number;
        policyName: string;
        policyUrl: string;
        policyUrlFile: string;
    }
    policyDocumentId: number;
    user: {
        fullName: string;
        id: string;
        role: string;
        userName: string;
    }
    userCreated: string;
    userId: string;
    userModified: string;
    userPolicyConsentId: number;
}

export interface AllPolicyState {
    loading: boolean;
    data: Array<AllUserPolicy>;
    error: string | undefined;
}
const initialState: AllPolicyState = {
    loading: false,
    data: [],
    error: undefined,
};
export const fetchUserAllPolicy = createAsyncThunk(
    "AllUserPolicy/fetchUserAllPolicy",
    async ({
        companyId,
        role,
        consent
    }: {
        companyId: number,
        role: string, consent: boolean
    }) => {
        try {
            const response = await api.fetchUserAllPolicy(companyId, role, consent);
            return response;
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                console.log(error);
            } else {
                console.log(error);
            }
        }
    }
);

const allUserPolicySlice = createSlice({
    name: "allUserPolicy",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchUserAllPolicy.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            fetchUserAllPolicy.fulfilled,
            (state, action: PayloadAction<Array<AllUserPolicy>>) => {
                state.loading = false;
                state.data = action.payload;
            }
        );
        builder.addCase(fetchUserAllPolicy.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
    reducers: {},
});
export const userSelector = (state: RootState) => state.allUserPolicy;
export default allUserPolicySlice.reducer;

export const allUserPolicyData = (state: RootState) => state.allUserPolicy.data;
export const allUserPolicyLoading = (state: RootState) => state.allUserPolicy.loading;
export const allUserPolicyError = (state: RootState) => state.allUserPolicy.error;