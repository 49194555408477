import React from 'react'


interface Props {
    content: string;
    category: string;
    onView: () => void;
    onAgreed: () => void;
    agreed?: boolean;
}
const UserPolicyCard = ({ onView, content, category, onAgreed, agreed }: Props) => {


    return (
        <div className=" bg-white rounded-md shadow p-2 border-s-4 border-brand overflow-clip">

            {/* <p className='font-bold text-lg'>{content}</p> */}
            <tr className='text-sm truncate ...'>
                <td className="whitespace-nowrap px-2 py-1  inline-flex items-center gap-1 font-bold">
                    Policy Category:
                </td>
                <td className="whitespace-nowrap px-2 py-1 text-gray-700 font-bold">{category}</td>

            </tr>
            <tr className='text-sm truncate ...'>
                <td className="whitespace-nowrap px-2 py-1  inline-flex items-center gap-1 font-bold">
                    Policy Name:
                </td>
                <td className="whitespace-nowrap px-2 py-1 text-gray-700 font-bold">{content}</td>

            </tr>
            <div className='flex gap-3 items-center py-2 pl-2'>
                <button className='bg-gray-300 px-6 py-2 rounded-md text-primary border border-primary text-xs font-normal 
                    transition duration-300 transform active:scale-95 ease-in-out
                ' onClick={onView} >Read Policy</button>

                <button className={`${agreed ? "bg-green-500" : "bg-primary"
                    } px-6 py-2 rounded-md text-white border border-gray-300 text-xs font-normal transition duration-300 transform active:scale-95 ease-in-out`}
                    onClick={onAgreed} >
                    {agreed ? "Agreed" : "I Agree"}</button>

            </div>

        </div>
    )
}

export default UserPolicyCard