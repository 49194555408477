import React, { ChangeEvent, useEffect, useState } from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import DataTable, { TableColumn } from "react-data-table-component";
import { FaFileCsv, FaFileExcel, FaFilePdf } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import ExportDropdown from "../../../Components/menu/ExportDropdown";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import Spinner from "../../../Components/Spinner";
import IconButton from "../../../Components/Buttons/IconButton";
import {
    downloadCSV,
    exportToExcel,
    handlePDFDownload,
} from "../../../services/reusableFunc";
import { Column } from "../../../types/DataType";
import {
    fetchServiceProviderClient,
    ServiceProviderClient,
    serviceProviderClientData,
    serviceProviderClientLoading,
} from "../../../store/slices/serviceProviderClientSlice";
import { Link, useParams } from "react-router-dom";

const ProviderClient = () => {
    const columns: TableColumn<ServiceProviderClient>[] = [
        {
            name: "Full Name",
            selector: (row) => row.fullName,
            sortable: true,
            cell: (row) => (
                <Link
                    style={{ overflow: "hidden" }}
                    to={`/app/provider/clients-profile/${row.email}/profile`}
                    className={`font-bold text-black`}
                >
                    <span>{row.fullName} </span>
                </Link>
            ),
        },
        {
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: 'Gender',
            selector: row => row.gender,
            sortable: true,

        },

        {
            name: "Phone",
            selector: (row) => row.contactNumber,
            sortable: true,
        },

        {
            name: "Status",
            selector: (row) => row.isAccepted,
            sortable: true,
            cell: (row) => (
                <div
                    style={{ overflow: "hidden" }}
                    className={`font-bold text-white py-1 px-2 rounded ${row.isAccepted
                        ? "bg-green-500"
                        : "bg-yellow-500"
                        }`}
                >
                    <span>{row.isAccepted ? 'Accepted' : 'Pending'} </span>
                </div>
            ),
        },
    ];

    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    const companyId = user?.companyId;
    const { uid } = useParams();
    useEffect(() => {
        if (uid) {
            dispatch(fetchServiceProviderClient(uid));
        }
    }, [dispatch, companyId]);
    const providerClient = useSelector(serviceProviderClientData);
    const loading = useSelector(serviceProviderClientLoading);

    const [searchText, setSearchText] = useState<string>("");

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const filteredData = providerClient?.filter((item) =>
        item?.fullName?.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <>
            <SEO
                title="Referred Client's"
                description="Referred Client's"
                name="Promax-Care"
            />
            <Breadcrumb />
            <div className="mt-10">
                {providerClient.length <= 0 && loading && <Spinner color="#071952" />}

                <h2 className="font-bold text-xl text-black w-auto">
                    Referred Client's by {uid}
                </h2>
                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
                    <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
                        <div className="relative w-full lg:col-span-3">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <BsSearch />
                            </div>
                            <input
                                type="text"
                                id="simple-search"
                                onChange={handleSearch}
                                className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                placeholder="Search By Full Name"
                            />
                        </div>
                    </div>
                    <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                        <div className="flex items-center space-x-3 w-full md:w-auto">
                            <ExportDropdown>
                                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                    <IconButton
                                        icon={<FaFilePdf className="text-red-500" />}
                                        title="Export as PDF"
                                        onClick={() =>
                                            handlePDFDownload(
                                                providerClient,
                                                columns as unknown as Column<ServiceProviderClient>[],
                                                "ServiceProviderClient.pdf"
                                            )
                                        }
                                    />
                                    <IconButton
                                        icon={<FaFileExcel className="text-green-600" />}
                                        title="Export as Excel Sheet"
                                        onClick={() =>
                                            exportToExcel(
                                                providerClient,
                                                columns as unknown as Column<ServiceProviderClient>[],
                                                "ServiceProviderClient"
                                            )
                                        }
                                    />
                                    <IconButton
                                        icon={<FaFileCsv className="text-green-500" />}
                                        title="Export as CSV"
                                        onClick={() =>
                                            downloadCSV({
                                                data: providerClient,
                                                filename: "ServiceProviderClient.csv",
                                            })
                                        }
                                    />
                                </div>
                            </ExportDropdown>
                        </div>
                        <a href={`https://www.promaxcare.com.au/Account/ClientReferral?companyId=${companyId}`} target='_blank' rel="noreferrer">
                            <button
                                type="button"
                                //   onClick={() => setAddUserModal(true)}
                                className="px-4 py-2 bg-primary text-white  disabled:bg-slate-400
                            transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm 
                           "
                            >
                                Refer a Client
                            </button>
                        </a>
                    </div>
                </div>

                <DataTable
                    columns={columns}
                    data={filteredData}
                    pagination
                    paginationTotalRows={providerClient?.length}
                // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}
                />
            </div>
        </>
    );
};


export default ProviderClient;
