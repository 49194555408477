import React from 'react'
interface Props {
    clientPercentage: number | undefined
}
const ProgressBar = ({ clientPercentage }: Props) => {
    let bgColor: string;
    if (clientPercentage && clientPercentage >= 70) {
        bgColor = 'bg-green-500';
    } else if (clientPercentage && clientPercentage >= 60) {
        bgColor = 'bg-primary';
    } else if (clientPercentage && clientPercentage >= 50) {
        bgColor = 'bg-secondary';
    } else {
        bgColor = 'bg-red-500';
    }

    return (
        // <div className="w-full lg:w-[30%] bg-gray-200 rounded-full dark:bg-gray-700">
        //     <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{ width: '45%' }}> </div>
        // </div>
        <div className={`w-9 p-2 h-9 text-sm font-semibold rounded-full text-white flex justify-center items-center ${bgColor}`}>
            {clientPercentage}%
        </div>


    )
}

export default ProgressBar