import React from 'react';
import { FormDataType } from '..';


interface Props {
    formData: FormDataType
    handleInputFormChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;

}

const OthersForm: React.FC<Props> = ({ formData, handleInputFormChange }) => {

    return (
        <div>
            <h2 className="text-xl font-bold mb-4">Supporting documents completed and attached (tick all that apply)</h2>
            <table className="w-full border-collapse mb-4">
                <tbody>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Consent Form:</td>
                        <td className="border px-4 py-2">
                            <label>
                                <input
                                    type="checkbox"
                                    name="consentForm"
                                    className="mr-2"
                                    checked={formData.consentForm}
                                    onChange={handleInputFormChange}
                                />
                            </label>
                        </td>
                    </tr>
                </tbody>
            </table>

            <h2 className="text-xl font-bold mb-4">Urgent medical emergencies</h2>
            <table className="w-full border-collapse">
                <tbody>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Summary of health issue (what happens in these situations?):</td>
                        <td className="border px-4 py-2">
                            <textarea
                                name="healthIssueSummary"
                                className="w-full p-2 border border-gray-300 rounded mt-2"
                                value={formData.healthIssueSummary}
                                onChange={handleInputFormChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 font-bold">How do you communicate an emerging health issue to the people around you:</td>
                        <td className="border px-4 py-2">
                            <textarea
                                name="communications"
                                className="w-full p-2 border border-gray-300 rounded mt-2"
                                value={formData.communications}
                                onChange={handleInputFormChange}
                            />
                        </td>
                    </tr>
                    {/* <tr>
                        <td className="border px-4 py-2 font-bold">(this can include changes in body language, skin tone, perspiration etc.?):</td>
                        <td className="border px-4 py-2">
                            <textarea
                                name="bodyLanguage"
                                className="w-full p-2 border border-gray-300 rounded mt-2"
                                value={formData.bodyLanguage}
                                onChange={handleInputFormChange}
                            />
                        </td>
                    </tr> */}
                    <tr>
                        <td className="border px-4 py-2 font-bold">Response required from us:</td>
                        <td className="border px-4 py-2">
                            <textarea
                                name="responseRequired"
                                className="w-full p-2 border border-gray-300 rounded mt-2"
                                value={formData.responseRequired}
                                onChange={handleInputFormChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Staff are to notify the following people:</td>
                        <td className="border px-4 py-2">
                            <textarea
                                name="notifyPeople"
                                className="w-full p-2 border border-gray-300 rounded mt-2"
                                value={formData.notifyPeople}
                                onChange={handleInputFormChange}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>

            <h2 className="text-xl font-bold mb-4">Emergency management and evacuation plan</h2>
            <table className="w-full border-collapse">
                <tbody>
                    <tr>
                        <td className="border px-4 py-2 font-bold">These people need to be contacted immediately (as soon as it is safe to do so):</td>
                        <td className="border px-4 py-2">
                            <textarea
                                name="contactImmediately"
                                className="w-full p-2 border border-gray-300 rounded mt-2"
                                value={formData.contactImmediately}
                                onChange={handleInputFormChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 font-bold">I will be supported to evacuate to the following location:</td>
                        <td className="border px-4 py-2">
                            <textarea
                                name="evacuationLocation"
                                className="w-full p-2 border border-gray-300 rounded mt-2"
                                value={formData.evacuationLocation}
                                onChange={handleInputFormChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 font-bold">I need to take the following medications, equipment or information with me (if it is safe to do so):</td>
                        <td className="border px-4 py-2">
                            <textarea
                                name="medications"
                                className="w-full p-2 border border-gray-300 rounded mt-2"
                                value={formData.medications}
                                onChange={handleInputFormChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Evacuation procedures: e.g. safe procedures for use of wheelchairs etc.</td>
                        <td className="border px-4 py-2">
                            <textarea
                                name="evacuationProcedures"
                                className="w-full p-2 border border-gray-300 rounded mt-2"
                                value={formData.evacuationProcedures}
                                onChange={handleInputFormChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 font-bold">These changes to my usual routine will be put into place e.g. supports, employment etc:</td>
                        <td className="border px-4 py-2">
                            <textarea
                                name="changesToRoutine"
                                className="w-full p-2 border border-gray-300 rounded mt-2"
                                value={formData.changesToRoutine}
                                onChange={handleInputFormChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Protocols when there is no response to a scheduled visit:</td>
                        <td className="border px-4 py-2">
                            <textarea
                                name="noResponseProtocols"
                                className="w-full p-2 border border-gray-300 rounded mt-2"
                                value={formData.noResponseProtocols}
                                onChange={handleInputFormChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Protocols when our services are disrupted or when we are unable to provide our usual services due to an emergency:</td>
                        <td className="border px-4 py-2">
                            <textarea
                                name="serviceDisruptionProtocols"
                                className="w-full p-2 border border-gray-300 rounded mt-2"
                                value={formData.serviceDisruptionProtocols}
                                onChange={handleInputFormChange}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default OthersForm;
