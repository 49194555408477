import React, { ChangeEvent } from 'react';
import ClientTabForm from "../../components/ClientTabForm";
import { FormData } from '../CommunitySupport';


interface Props {
    formData: FormData;
    handleChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

const CommunitySupportCom: React.FC<Props> = ({ formData, handleChange }) => {

    return (
        <div className='mt-10'>
            <h2 className='font-bold text-xl text-black w-auto'>Community Support Needs</h2>
            <form>
                <div className="mt-5">
                    <ClientTabForm title='Community Support Needs'>
                        <div className='grid grid-cols-1 gap-16 lg:grid-cols-2'>
                            {/* Additional support detail textarea */}
                            <div>
                                <label htmlFor="additionalSupportDetail" className="font-bold">Is additional support for these issues? If so, please provide detail</label>
                                <textarea
                                    id="additionalSupportDetail"
                                    name="additionalSupportDetail"
                                    rows={3}
                                    autoComplete="off"
                                    value={formData.additionalSupportDetail}
                                    onChange={handleChange}
                                    className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                />
                            </div>
                            {/* Transportation details textarea */}
                            <div>
                                <label htmlFor="transportationDetails" className="font-bold">Do you need assistance to use transport? If so, please provide detail</label>
                                <textarea
                                    id="transportationDetails"
                                    name="transportationDetails"
                                    rows={3}
                                    autoComplete="off"
                                    value={formData.transportationDetails}
                                    onChange={handleChange}
                                    className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                />
                            </div>
                            {/* Transport type textarea */}
                            <div>
                                <label htmlFor="transportType" className="font-bold">What type of transport do you mainly use</label>
                                <textarea
                                    id="transportType"
                                    name="transportType"
                                    rows={3}
                                    autoComplete="off"
                                    value={formData.transportType}
                                    onChange={handleChange}
                                    className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                />
                            </div>
                            {/* Activities detail textarea */}
                            <div>
                                <label htmlFor="activitiesDetail" className="font-bold">Do you participate in any activities (such as employment, training or community activities)? If yes, please provide detail</label>
                                <textarea
                                    id="activitiesDetail"
                                    name="activitiesDetail"
                                    rows={3}
                                    autoComplete="off"
                                    value={formData.activitiesDetail}
                                    onChange={handleChange}
                                    className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                />
                            </div>
                            {/* Access assistance detail textarea */}
                            <div>
                                <label htmlFor="accessAssistanceDetail" className="font-bold">Do you need assistance to access these activities? If yes, please provide detail</label>
                                <textarea
                                    id="accessAssistanceDetail"
                                    name="accessAssistanceDetail"
                                    rows={3}
                                    autoComplete="off"
                                    value={formData.accessAssistanceDetail}
                                    onChange={handleChange}
                                    className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                />
                            </div>
                        </div>
                    </ClientTabForm>
                </div>
            </form>
        </div>
    );
};

export default CommunitySupportCom;
