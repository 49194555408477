import React, { FormEvent, useEffect, useState } from "react";
import SEO from "../../../constants/SEO";
import CardHeader from "./components/CardHeader";
import man from "../../../images/user/man.png";
import { Button, InputPicker, Modal, Tooltip, Whisper } from "rsuite";
import CardNameTag from "./components/CardNameTag";
import axiosInstance from "../../../store/axiosInstance";
import { useParams } from "react-router-dom";
import FallBack from "../../../Pages/FallBack";
import Spinner from "../../../Components/Spinner";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import {
  careManagerData,
  fetchCareManager,
} from "../../../store/slices/careManagerSlice";
import {
  fetchServiceProvider,
  serviceProviderData,
} from "../../../store/slices/serviceProviderSlice";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import ProfileNameTag from "./components/ProfileNameTag";
import dayjs from "dayjs";
import ClientDocument from "./clientTab/Document";
import ClientSchedule from "./clientTab/Schedule";
import ClientShiftRoster from "./clientTab/ShiftRoaster";
import ClientProgressReport from "./clientTab/ProgressNote";
import DisabilitySupport from "./clientTab/DisabilitySupport";
import ClientDailyLiving from "./clientTab/DailyLiving";
import ClientAidAndEquip from "./clientTab/Aid&Equiment";
import EditDropDown from "../../../Components/menu/EditDropDown";
import WebcamCapture from "./components/CapturePicture";
import ClientEditComp from "./components/ClientEditComp";
import { claimsTypes, ClientDataRow } from "../../../types/AuthData";
import ClientEmergComp from "./components/ClientEmergComp";
import AllClientDocument from "./clientTab/AllDoc";
import CropUploader from "../../../Components/PictureUpload/CropUploader";
import ClientSupportSchedule from "./clientTab/ScheduleSupport";
import ParticipantExpenditure from "./clientTab/Expenditure";
import HealthSupport from "./clientTab/HealthSupport";
import CommunitySupport from "./clientTab/CommunitySupport";
import BehaviorSupport from "./clientTab/BehaviorSupport";
import ClientShiftReport from "./clientTab/ShiftReport";
import ClientRep from "./clientTab/Representative";
import ClientOtherComp from "./components/ClientOtherComp";
import { Coordinates } from "../../../types/DataType";

const ClientDetails = () => {
  const { uid } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [deactivateModal, setDeactivateModal] = useState<boolean>(false);
  const [removeServiceModal, setRemoveServiceModal] = useState<boolean>(false);
  const [deapproveAuditModal, setDeapproveAuditModal] =
    useState<boolean>(false);
  const [ApproveAuditModal, setApproveAuditModal] = useState<boolean>(false);
  const [activateModal, setActivateModal] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state?.auth?.user);
  const [serviceProModal, setServiceProModal] = useState<boolean>(false);
  const [careModal, setCareModal] = useState<boolean>(false);
  const [profileId, setProfileId] = useState<number>(0);
  const [editedProfile, setEditedProfile] = useState<ClientDataRow>({
    address: "",
    city: "",
    clientId: 0,
    contactId: "",
    country: "",
    email: "",
    firstName: "",
    gender: "",
    imageFile: "",
    imageUrl: "",
    middleName: "",
    phoneNumber: "",
    profileId: 0,
    state: "",
    surName: "",
    ndisNo: "",
    referral: "",
    careManager: "",
    isActive: false,
    auditApproved: false,
    xerolink: "",
    culturalBackground: "",
    dateOfBirth: "",
    financialArrangement: "",
    fullName: "",
    indigenousSatatus: "",
    kinAddress: "",
    kinCity: "",
    kinCountry: "",
    kinEmail: "",
    kinSuburb: "",
    kinPhoneNumber: "",
    kinPostcode: "",
    kinState: "",
    ndisPlan: "",
    nextOfKin: "",
    preferredLanguage: "",
    privacyPreferences: "",
    relationship: "",
    requireInterpreter: false,
    suburb: "",
    agreementEndDate: "",
    agreementStartDate: "",
    signature: "",
    signatureFile: "",
    ndisPlanNote: "",
  });
  const companyId = user?.companyId;
  const dispatch: AppDispatch = useAppDispatch();
  useEffect(() => {
    if (companyId) {
      dispatch(fetchCareManager(companyId));
      dispatch(fetchServiceProvider(companyId));
    }
  }, [dispatch, companyId]);
  const careManager = useSelector(careManagerData);
  const serviceProvider = useSelector(serviceProviderData);

  const staffClaimsString = localStorage.getItem("claims");
  const claims: claimsTypes[] = staffClaimsString
    ? JSON.parse(staffClaimsString)
    : [];
  const hasRequiredClaims = (claimType: string): boolean => {
    return claims.some((claim) => claim.value === claimType);
  };

  const FetchClient = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(`/Profiles/${uid}`);

      setEditedProfile({ ...data });
      setLoading(false);
    } catch (error) {
      // console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    FetchClient();
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setEditedProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleActivate = () => {
    setActivateModal(true);
  };

  const handleDeactivate = () => {
    setDeactivateModal(true);
  };

  const handleRemoveServicePro = () => {
    setRemoveServiceModal(true);
  };

  const handleActivateClient = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `/Profiles/activate_client?userId=${user?.uid}&clientid=${uid}`
      );
      toast.success(data.message);
      setActivateModal(false);
      FetchClient();
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
      setActivateModal(false);
    } finally {
      setLoading(false);
    }
  };

  const handleDeactivateClient = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `/Profiles/deactivate_client?userId=${user?.uid}&clientid=${uid}`
      );
      toast.success(data.message);
      setDeactivateModal(false);
      FetchClient();
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
      setDeactivateModal(false);
    } finally {
      setLoading(false);
    }
  };

  const handleDeapproveAudit = () => {
    setDeapproveAuditModal(true);
  };
  const handleApproveAudit = () => {
    setApproveAuditModal(true);
  };
  const handleView = (xerolink: string) => {
    window.open(xerolink, "_blank");
  };
  const handleDisApproveAudit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `/Profiles/disapprove_audit?userId=${user?.uid}&id=${uid}`
      );
      toast.success(data.message);
      setDeapproveAuditModal(false);
      FetchClient();
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
      setDeapproveAuditModal(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSubApproveAudit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `/Profiles/approve_audit?userId=${user?.uid}&id=${uid}`
      );
      toast.success(data.message);
      setApproveAuditModal(false);
      FetchClient();
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
      setApproveAuditModal(false);
    } finally {
      setLoading(false);
    }
  };

  const handleCare = () => {
    setCareModal(true);
  };

  const handleServicePro = () => {
    setServiceProModal(true);
  };

  const handleCareSave = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const info = {
      id: profileId,
      profileId: uid,
      companyId: companyId,
    };
    try {
      const { data } = await axiosInstance.post(
        `/ClientCareManagers/assign_client_to_caremanager?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      setCareModal(false);
      FetchClient();
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
      setCareModal(false);
    } finally {
      setLoading(false);
    }
  };

  const handleServiceProSave = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const info = {
      serviceProvidersId: profileId,
      clientId: uid,
      companyId: companyId,
    };
    try {
      const { data } = await axiosInstance.post(
        `/ClientServiceProviders/assign_client_serviceprovider?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      setServiceProModal(false);
      FetchClient();
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
      setServiceProModal(false);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveServiceClient = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const info = {
      serviceProvidersId: profileId,
      clientId: uid,
      companyId: companyId,
    };

    try {
      const { data } = await axiosInstance.post(
        `/ClientServiceProviders/delete/${profileId}?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      setRemoveServiceModal(false);
      FetchClient();
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
      setRemoveServiceModal(false);
    } finally {
      setLoading(false);
    }
  };
  const [activeTab, setActiveTab] = useState<string>("Documents");
  const handleTab = (tab: string) => {
    setActiveTab(tab);
  };

  const tabsArray: string[] = [
    "Documents",
    "Schedule Support",
    "Daily Support",
    "Shift Roster",
    "Shift Report",
    "Progress Report",
    "Participant Expenditure",
    "Representative",
    "Disablilty Support Needs",
    "Daily Living & Night Support",
    "Aids & Equipment",
    "Health Support Needs",
    "Community Support Needs",
    "Behaviour Support Needs",
  ];

  const [loading1, setLoading1] = useState<boolean>(false);
  const [isCameraModalOpen, setCameraModalOpen] = useState(false);
  const [photoModal, setPhotoModal] = useState(false);
  const [personalInfoModal, setPersonalInfoModal] = useState<boolean>(false);
  const [emergencyModal, setEmergencyModal] = useState<boolean>(false);
  const [othersModal, setOthersModal] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<Coordinates | null>(null);
  const [address, setAddress] = useState<string>(editedProfile.address);
  const [emerAddress, setEmerAddress] = useState<string>(
    editedProfile.kinAddress
  );

  useEffect(() => {
    // Initialize address with the value from editedProfile when component mounts or editedProfile changes
    setAddress(editedProfile.address);
    setEmerAddress(editedProfile.kinAddress);
  }, [editedProfile]);

  const handleCapture = async (imageBlob: Blob | null) => {
    if (imageBlob) {
      // Convert Blob to File
      const imageFile = new File([imageBlob], "captured-image.jpg", {
        type: imageBlob.type, // Preserving the MIME type
        lastModified: Date.now(), // Setting last modification time
      });

      if (imageFile) {
        setLoading1(true);
        const formData = new FormData();

        if (imageFile) {
          formData.append("imageFile", imageFile);
        }

        for (const key in editedProfile) {
          const value = editedProfile[key as keyof typeof editedProfile];
          if (value === null) {
            formData.append(key, ""); // Pass empty string if value is null
          } else {
            formData.append(key, value.toString());
          }
        }

        try {
          const { data } = await axiosInstance.post(
            `/Profiles/edit/${uid}?userId=${user?.uid}`,
            formData
          );
          toast.success(data.message);
          setLoading1(false);
          setCameraModalOpen(false);
          FetchClient();
        } catch (error: unknown) {
          if (isAxiosError(error)) {
            toast.error(error.response?.data?.message);
            toast.error(error.response?.data?.title);
            // console.log(error);;
          } else {
            toast.error("An error occurred");
          }
          setLoading1(false);
        } finally {
          setLoading1(false);
        }
      }

      // Close modal
    } else {
      console.log("No image captured");
      setCameraModalOpen(false);
    }
  };

  const handleCroppedImage = async (croppedImage: Blob) => {
    const formData = new FormData();
    formData.append("imageFile", croppedImage, "cropped_image.jpg");

    for (const key in editedProfile) {
      const value = editedProfile[key as keyof typeof editedProfile];
      if (value === null) {
        formData.append(key, ""); // Pass empty string if value is null
      } else {
        formData.append(key, value.toString());
      }
    }

    try {
      setLoading1(true);
      const { data } = await axiosInstance.post(
        `/Profiles/edit/${uid}?userId=${user?.uid}`,
        formData
      );
      toast.success(data.message);
      setLoading1(false);
      FetchClient();
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
    } finally {
      setLoading1(false);
    }
  };

  const submitData = async (e?: FormEvent) => {
    e?.preventDefault();
    setLoading1(true);
    const formData = new FormData();
    if (address) {
      formData.append("address", address);
    }

    if (emerAddress) {
      formData.append("kinAddress", emerAddress);
    }

    if (coordinates?.lat !== undefined && coordinates?.lng !== undefined) {
      formData.append("latitude", coordinates.lat.toString());
      formData.append("longitude", coordinates.lng.toString());
    }

    for (const key in editedProfile) {
      const value = editedProfile[key as keyof typeof editedProfile];
      if (value === null) {
        formData.append(key, ""); // Pass empty string if value is null
      } else {
        formData.append(key, value.toString());
      }
    }

    try {
      const { data } = await axiosInstance.post(
        `/Profiles/edit/${uid}?userId=${user?.uid}`,
        formData
      );
      toast.success(data.message);
      setLoading1(false);
      setPersonalInfoModal(false);
      setEmergencyModal(false);
      setOthersModal(false);
      FetchClient();
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
    } finally {
      setLoading1(false);
    }
  };

  return (
    <>
      <SEO title="Clients" description="Client" name="Promax-Care" />
      <div className="flex justify-between items-center flex-wrap">
        <Breadcrumb showNavigation={false} />
        <h1 className="font-bold text-black text-2xl">Client</h1>
      </div>

      <div>
        {loading ? (
          <FallBack />
        ) : (
          <div className="space-y-4 py-12">
            <div className="flex gap-2 items-center horizontal-scroll px-4 md:px-0">
              {user?.role === "CompanyAdmin" ||
              user?.role === "Administrator" ? (
                <Whisper
                  placement="auto"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      Click to deactivate <i>client</i> .
                    </Tooltip>
                  }
                >
                  {editedProfile.isActive ? (
                    <button
                      onClick={handleDeactivate}
                      className="bg-red-500 text-white rounded text-xs p-2 whitespace-nowrap"
                    >
                      Deactivate Client
                    </button>
                  ) : (
                    <button
                      onClick={handleActivate}
                      className="bg-primary text-white rounded text-xs p-2 whitespace-nowrap"
                    >
                      Activate Client
                    </button>
                  )}
                </Whisper>
              ) : (
                ""
              )}
              {user?.role === "CompanyAdmin" ||
              user?.role === "Administrator" ? (
                <div>
                  {editedProfile.auditApproved ? (
                    <button
                      onClick={handleDeapproveAudit}
                      className="bg-red-500 text-white rounded text-xs p-2"
                    >
                      Disapprove for Auditing
                    </button>
                  ) : (
                    <button
                      onClick={handleApproveAudit}
                      className="bg-green-500 text-white rounded text-xs p-2 whitespace-nowrap"
                    >
                      Approve for Auditing
                    </button>
                  )}
                </div>
              ) : (
                ""
              )}
              {user?.role === "CompanyAdmin" ||
              user?.role === "Administrator" ? (
                <div>
                  {!editedProfile.clientId && (
                    <button
                      onClick={() => handleView(editedProfile.xerolink)}
                      className="bg-brand text-white rounded text-xs p-2 col-span-2 whitespace-nowrap"
                    >
                      Update Record to Xero
                    </button>
                  )}
                </div>
              ) : (
                ""
              )}
              {user?.role === "CompanyAdmin" ||
              user?.role === "Administrator" ? (
                <div className="flex gap-2">
                  <Whisper
                    placement="auto"
                    controlId="control-id-hover"
                    trigger="hover"
                    speaker={<Tooltip>Assign client to care manager</Tooltip>}
                  >
                    <button
                      className="bg-gray-300  rounded text-xs p-2 whitespace-nowrap"
                      onClick={handleCare}
                    >
                      Assign client to Care Manager
                    </button>
                  </Whisper>
                  <Whisper
                    placement="auto"
                    controlId="control-id-hover"
                    trigger="hover"
                    speaker={
                      <Tooltip>Assign client to Service Provider</Tooltip>
                    }
                  >
                    <button
                      className="bg-gray-300  rounded text-xs p-2 whitespace-nowrap"
                      onClick={handleServicePro}
                    >
                      Assign client to Service Provider
                    </button>
                  </Whisper>

                  <Whisper
                    placement="auto"
                    controlId="control-id-hover"
                    trigger="hover"
                    speaker={
                      <Tooltip>Remove client from Service Provider</Tooltip>
                    }
                  >
                    <button
                      onClick={handleRemoveServicePro}
                      className="bg-gray-300 rounded text-xs p-2 whitespace-nowrap"
                    >
                      Remove client from Service Provider
                    </button>
                  </Whisper>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-3 xl:grid-cols-4">
              <div className="border-2 rounded-lg">
                <CardHeader label="Profile" />
                <div className=" space-y-2 py-4">
                  <div className="flex flex-col gap-2 justify-center items-center">
                    <span className="h-16 w-16 flex justify-center items-center rounded-full bg-transparent cursor-pointer  overflow-hidden">
                      <img
                        src={editedProfile.imageUrl || man}
                        alt="img"
                        className="w-full h-full object-cover"
                        onClick={() => setPhotoModal(true)}
                      />
                    </span>

                    <EditDropDown>
                      <div className="z-[999] w-max rounded bg-white  p-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                        <button
                          onClick={() => setCameraModalOpen(true)}
                          className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
                        >
                          Take Photo
                        </button>

                        <CropUploader
                          onCroppedImage={handleCroppedImage}
                          loading={loading1}
                        />
                      </div>
                    </EditDropDown>
                    <p className="font-bold text-sm text-black">
                      {editedProfile.fullName}
                    </p>
                  </div>
                  {/* <Avatar size="xxl" circle /> */}
                  <div className="px-4 space-y-2">
                    <ProfileNameTag
                      label="Client ID"
                      name={editedProfile.contactId}
                    />
                    <ProfileNameTag
                      label="NDIS No"
                      name={editedProfile.ndisNo}
                    />
                    <ProfileNameTag
                      label="Referral"
                      name={editedProfile.referral}
                    />
                    <ProfileNameTag
                      label="Care Manager"
                      name={editedProfile.careManager}
                    />
                  </div>
                </div>
              </div>
              <div className=" lg:col-span-2 space-y-2 border-2 rounded-lg">
                {user?.role === "CompanyAdmin" ||
                user?.role === "Administrator" ||
                hasRequiredClaims("Edit Client") ? (
                  <CardHeader
                    label="Personal Information"
                    showEditIcon
                    onEdit={() => setPersonalInfoModal(true)}
                  />
                ) : (
                  ""
                )}
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 p-4">
                  <CardNameTag label="Phone" name={editedProfile.phoneNumber} />
                  <CardNameTag label="Email" name={editedProfile.email} />
                  <CardNameTag label="Gender" name={editedProfile.gender} />
                  <CardNameTag
                    label="Date of Birth"
                    name={
                      dayjs(editedProfile.dateOfBirth).format("DD-MM-YYYY") ||
                      "N/A"
                    }
                  />
                  <CardNameTag
                    label="Nationality"
                    name={editedProfile.country}
                  />
                  <CardNameTag label="State" name={editedProfile.state} />
                  <CardNameTag label="Address" name={editedProfile.address} />
                  {/* <CardNameTag label="City" name={editedProfile.city} /> */}
                  <CardNameTag label="Suburb" name={editedProfile.suburb} />
                  {user?.role === "CompanyAdmin" ||
                  user?.role === "Administrator" ||
                  hasRequiredClaims("Edit Client") ? (
                    <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:col-span-3 border-t-4 py-2">
                      <CardNameTag
                        label="Agreement Start Date"
                        name={dayjs(editedProfile.agreementStartDate).format(
                          "MMM D, YYYY"
                        )}
                      />
                      <CardNameTag
                        label="Agreement End Date"
                        name={dayjs(editedProfile.agreementEndDate).format(
                          "MMM D, YYYY"
                        )}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="border-2 rounded-lg">
                {user?.role === "CompanyAdmin" ||
                user?.role === "Administrator" ||
                hasRequiredClaims("Edit Client") ? (
                  <CardHeader
                    label="Emergency Contact"
                    showEditIcon
                    onEdit={() => setEmergencyModal(true)}
                  />
                ) : (
                  ""
                )}

                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 p-2">
                  <CardNameTag label="Name" name={editedProfile.nextOfKin} />
                  <CardNameTag
                    label="Phone"
                    name={editedProfile.kinPhoneNumber}
                  />
                  {/* <CardNameTag label="City" name={editedProfile.kinCity} /> */}
                  <CardNameTag
                    label="Relationship"
                    name={editedProfile.relationship}
                  />
                  <CardNameTag
                    label="Country"
                    name={editedProfile.kinCountry}
                  />
                  <CardNameTag
                    label="Postal Code"
                    name={editedProfile.kinPostcode}
                  />
                  <CardNameTag label="Email" name={editedProfile.kinEmail} />
                  <CardNameTag label="State" name={editedProfile.kinState} />
                  <CardNameTag label="Suburb" name={editedProfile.kinCity} />
                  <CardNameTag
                    label="Address"
                    name={editedProfile.kinAddress}
                  />
                </div>
              </div>

              <div className="border-2 rounded-lg md:col-span-2 lg:col-span-4">
                {user?.role === "CompanyAdmin" ||
                user?.role === "Administrator" ||
                hasRequiredClaims("Edit Client") ? (
                  <CardHeader
                    label="Others"
                    showEditIcon
                    onEdit={() => setOthersModal(true)}
                  />
                ) : (
                  ""
                )}

                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 p-2">
                  <CardNameTag
                    label="Cultural Background"
                    name={editedProfile.culturalBackground}
                  />
                  <CardNameTag
                    label="Preferred Language"
                    name={editedProfile.preferredLanguage}
                  />
                  <CardNameTag
                    label="Privacy Preferences"
                    name={editedProfile.privacyPreferences}
                  />
                  <CardNameTag
                    label="Do you have an NDIS plan"
                    name={editedProfile.ndisPlan}
                  />
                  <CardNameTag
                    label="NDIS Plan Note"
                    name={editedProfile.ndisPlanNote}
                  />
                  <CardNameTag
                    label="Indigenous Status"
                    name={editedProfile.indigenousSatatus}
                  />
                  <CardNameTag
                    label="Financial Arrangement"
                    name={editedProfile.financialArrangement}
                  />
                </div>
              </div>
            </div>

            {user?.role === "CompanyAdmin" ||
            user?.role === "Administrator" ||
            hasRequiredClaims("Edit Client") ? (
              <div className="flex gap-2 items-center horizontal-scroll scroll-smoothscrollbar-hide  border-t border-b py-4 px-4 md:px-0">
                {tabsArray.map((category, index) => (
                  <button
                    key={index}
                    className={`${
                      activeTab === category
                        ? "bg-gray-500 text-white"
                        : "border-gray-400 border"
                    }  rounded text-xs p-2 whitespace-nowrap`}
                    onClick={() => handleTab(category)}
                  >
                    {category}
                  </button>
                ))}
              </div>
            ) : (
              ""
            )}

            <div>
              {activeTab === "Documents" && uid !== undefined && (
                <ClientDocument
                  uid={uid}
                  clientName={editedProfile.fullName}
                  handleTab={handleTab}
                />
              )}
              {activeTab === "Schedule Support" && uid !== undefined && (
                <ClientSupportSchedule uid={uid} />
              )}
              {activeTab === "Daily Support" && uid !== undefined && (
                <ClientSchedule uid={uid} />
              )}
              {activeTab === "Shift Roster" && uid !== undefined && (
                <ClientShiftRoster uid={uid} />
              )}
              {activeTab === "Shift Report" && uid !== undefined && (
                <ClientShiftReport uid={uid} />
              )}
              {activeTab === "Progress Report" && uid !== undefined && (
                <ClientProgressReport uid={uid} />
              )}
              {activeTab === "Participant Expenditure" && uid !== undefined && (
                <ParticipantExpenditure uid={uid} />
              )}
              {activeTab === "Representative" && uid !== undefined && (
                <ClientRep uid={uid} />
              )}
              {activeTab === "Disablilty Support Needs" &&
                uid !== undefined && <DisabilitySupport uid={uid} />}
              {activeTab === "Health Support Needs" && uid !== undefined && (
                <HealthSupport uid={uid} />
              )}
              {activeTab === "Daily Living & Night Support" &&
                uid !== undefined && <ClientDailyLiving uid={uid} />}
              {activeTab === "Aids & Equipment" && uid !== undefined && (
                <ClientAidAndEquip uid={uid} />
              )}
              {activeTab === "Community Support Needs" && uid !== undefined && (
                <CommunitySupport uid={uid} />
              )}
              {activeTab === "Behaviour Support Needs" && uid !== undefined && (
                <BehaviorSupport uid={uid} />
              )}
              {activeTab === "All Documents" && uid !== undefined && (
                <AllClientDocument
                  uid={uid}
                  clientName={editedProfile.fullName}
                />
              )}
            </div>
          </div>
        )}
      </div>

      <Modal
        open={photoModal}
        onClose={() => setPhotoModal(false)}
        role="alertdialog"
        autoFocus
        size={"xs"}
      >
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <div className="w-56 h-56 overflow-hidden mx-auto">
            <img
              src={editedProfile.imageUrl || man}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        open={isCameraModalOpen}
        onClose={() => setCameraModalOpen(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Take photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WebcamCapture onCapture={handleCapture} loading={loading1} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={activateModal}
        onClose={() => setActivateModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Activate Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="mb-4 font-bold">
              You are about to activate this Client. Do you wish to proceed?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <Button
                onClick={() => setActivateModal(false)}
                className="bg-gray-500 text-white"
              >
                No, cancel
              </Button>
              <Button
                onClick={handleActivateClient}
                className="bg-primary text-white hover:bg-primary hover:text-white"
              >
                {loading ? <Spinner /> : "Yes, I'm sure"}
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={deactivateModal}
        onClose={() => setDeactivateModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Deactivate Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="mb-4 font-bold">
              You are about to deactivate this Client. Do you wish to proceed?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <Button
                onClick={() => setDeactivateModal(false)}
                className="bg-gray-500 text-white"
              >
                No, cancel
              </Button>
              <Button
                onClick={handleDeactivateClient}
                className="bg-primary text-white hover:bg-primary hover:text-white"
              >
                {loading ? <Spinner /> : "Yes, I'm sure"}
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={deapproveAuditModal}
        onClose={() => setDeapproveAuditModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="mb-4 font-bold">
              This will disapprove client for auditing. Do you wish to proceed?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <Button
                onClick={() => setDeapproveAuditModal(false)}
                className="bg-gray-500 text-white"
              >
                No, cancel
              </Button>
              <Button
                onClick={handleDisApproveAudit}
                className="bg-primary text-white hover:bg-primary hover:text-white"
              >
                {loading ? <Spinner /> : "Yes, I'm sure"}
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={ApproveAuditModal}
        onClose={() => setApproveAuditModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="mb-4 font-bold">
              This will approve client for auditing. Do you wish to proceed?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <Button
                onClick={() => setApproveAuditModal(false)}
                className="bg-gray-500 text-white"
              >
                No, cancel
              </Button>
              <Button
                onClick={handleSubApproveAudit}
                className="bg-primary text-white hover:bg-primary hover:text-white"
              >
                {loading ? <Spinner /> : "Yes, I'm sure"}
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={careModal}
        onClose={() => setCareModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">
            Assign Client To Care Manger
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form action="" className="space-y-2">
              <div>
                <label className=" text-xs text-gray-600 font-semibold">
                  Care Manger
                </label>
                <InputPicker
                  size="lg"
                  placeholder={
                    editedProfile?.careManager
                      ? editedProfile?.careManager
                      : "--Select a care manager--"
                  }
                  onChange={(value) => setProfileId(value)}
                  data={careManager.map((client) => ({
                    label: client.fullName,
                    value: client.id,
                  }))}
                  className="w-full focus:outline-none focus:border-primary text-gray-600"
                  appearance="subtle"
                />
              </div>

              <div className="mx-auto text-center mt-3">
                <button
                  type="submit"
                  onClick={handleCareSave}
                  disabled={loading ? true : false}
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                >
                  {loading ? <Spinner /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={serviceProModal}
        onClose={() => setServiceProModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">
            Assign Service Provider To Client
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form action="" className="space-y-2">
              <div>
                <label className=" text-xs text-gray-600 font-semibold">
                  Service Provider
                </label>
                <InputPicker
                  size="lg"
                  placeholder={
                    editedProfile?.referral
                      ? editedProfile?.referral
                      : "--Select a service provider--"
                  }
                  onChange={(value) => setProfileId(value)}
                  data={serviceProvider.map((client) => ({
                    label: client.fullName,
                    value: client.serviceProvidersId,
                  }))}
                  className="w-full focus:outline-none focus:border-primary text-gray-600"
                  appearance="subtle"
                />
              </div>

              <div className="mx-auto text-center mt-3">
                <button
                  type="submit"
                  onClick={handleServiceProSave}
                  disabled={loading ? true : false}
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                >
                  {loading ? <Spinner /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={removeServiceModal}
        onClose={() => setRemoveServiceModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="mb-4 font-bold">
              This will remove client from service provider. Do you wish to
              proceed?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <Button
                onClick={() => setRemoveServiceModal(false)}
                className="bg-gray-500 text-white"
              >
                No, cancel
              </Button>
              <Button
                onClick={handleRemoveServiceClient}
                className="bg-primary text-white hover:bg-primary hover:text-white"
              >
                {loading ? <Spinner /> : "Yes, I'm sure"}
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <ClientEditComp
        personalInfoModal={personalInfoModal}
        setPersonalInfoModal={setPersonalInfoModal}
        editedProfile={editedProfile}
        handleInputChange={handleInputChange}
        submitData={submitData}
        address={address}
        setAddress={setAddress}
        loading1={loading1}
        setCoordinates={setCoordinates}
      />
      <ClientEmergComp
        emergencyModal={emergencyModal}
        setEmergencyModal={setEmergencyModal}
        editedProfile={editedProfile}
        handleInputChange={handleInputChange}
        submitData={submitData}
        loading1={loading1}
        emerAddress={emerAddress}
        setEmerAddress={setEmerAddress}
      />
      <ClientOtherComp
        othersModal={othersModal}
        setOthersModal={setOthersModal}
        editedProfile={editedProfile}
        handleInputChange={handleInputChange}
        submitData={submitData}
        loading1={loading1}
      />
    </>
  );
};

export default ClientDetails;
