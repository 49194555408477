import React from 'react'
interface Props {
    title: string;
    content: string;
    bg: string;
    actionButton?: JSX.Element
}
const Card = ({ title, content, bg, actionButton }: Props) => {
    return (
        <div className="p-2 rounded-md border-2 " style={{ backgroundColor: `${bg}` }}>
            <h4 className="font-bold text-gray-700">{title}</h4>
            <p className='text-xs'>
                {content}
            </p>

            <div className='mt-2'>
                {actionButton}
            </div>
        </div>
    )
}

export default Card