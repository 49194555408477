import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

interface TimeSheet {
  attendanceId: number;
  clockIn: string;
  clockInCheck: boolean;
  clockOut: string;
  clockOutCheck: boolean;
  companyID: number;
  dateCreated: string;
  dateModified: string;
  duration: number;
  startKm: number;
  endKm: number;
  imageFile: string | null;
  imageURL: string | null;
  inLatitude: number;
  inLongitude: number;
  isSplitted: boolean;
  outLatitude: number;
  outLongitude: number;
  report: string;
  staff: StaffData;
}

interface StaffData {
  aboutMe: string;
  accountName: string | null;
  accountNumber: string | null;
  address: string;
  adm_Designations: string | null;
  adm_DesignationsId: string | null;
  auditApproved: boolean;
  australianCitizen: string | null;
  bankName: string | null;
  branch: string | null;
  bsb: string | null;
  city: string | null;
  company: string | null;
  companyId: number;
  country: string | null;
  dateCreated: string;
  dateJoined: string | null;
  dateModified: string;
  dateOfBirth: string;
  email: string;
  employeeId: string | null;
  employmentType: string | null;
  facebook: string | null;
  firstName: string;
  fullName: string;
  gender: string;
  github: string | null;
  handbook: string | null;
  imageFile: string | null;
  imageUrl: string | null;
  instagram: string | null;
  isActive: boolean;
  isAdmin: boolean;
  isCompleted: boolean;
  isDocumentUploaded: boolean;
  kinAddress: string;
  kinCity: string | null;
  kinCountry: string;
  kinEmail: string | null;
  kinPhoneNumber: string | null;
  kinPostcode: string | null;
  kinState: string;
  level: string | null;
  linkedIn: string | null;
  maxStaffId: string;
  middleName: string;
  nextOfKin: string;
  offerLetter: string | null;
  payDay: number;
  payRate: string | null;
  phoneNumber: string;
  position: string | null;
  postcode: string | null;
  profession: string | null;
  relationship: string;
  salary: number;
  staffId: number;
  state: string | null;
  suburb: string | null;
  superForm: string | null;
  supportPosition: string | null;
  surName: string;
  taxFile: string | null;
  twitter: string | null;
  userCreated: string | null;
  userModified: string | null;
  xerolink: string | null;
  youtube: string | null;
}

export interface TimeSheetState {
  loading: boolean;
  data: Array<TimeSheet>;
  error: string | undefined;
}
const initialState: TimeSheetState = {
  loading: false,
  data: [],
  error: undefined,
};
export const fetchTimeSheet = createAsyncThunk(
  "TimeSheet/fetchTimeSheet",
  async (companyId: number) => {
    try {
      const response = await api.fetchTimeSheet(companyId);
      return response;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

export const filterTimeSheet = createAsyncThunk(
  "TimeSheet/filterTimeSheet",
  async ({
    company,
    fromDate,
    toDate,
    staff,
  }: {
    company: number;
    fromDate: string;
    toDate: string;
    staff: string;
  }) => {
    try {
      const response = await api.filterTimeSheetData(
        company,
        fromDate,
        toDate,
        staff
      );
      return response;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

const timeSheetSlice = createSlice({
  name: "timeSheet",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchTimeSheet.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchTimeSheet.fulfilled,
      (state, action: PayloadAction<Array<TimeSheet>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(fetchTimeSheet.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(filterTimeSheet.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      filterTimeSheet.fulfilled,
      (state, action: PayloadAction<Array<TimeSheet>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(filterTimeSheet.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {},
});
export const userSelector = (state: RootState) => state.timeSheet;
export default timeSheetSlice.reducer;

// Define selectors to access the profile slice state
export const timeSheetData = (state: RootState) => state.timeSheet.data;
export const timeSheetLoading = (state: RootState) => state.timeSheet.loading;
export const timeSheetError = (state: RootState) => state.timeSheet.error;
