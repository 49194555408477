import React, {
    ChangeEvent,
    FormEvent,
    useEffect,
    useRef,
    useState,
} from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import DataTable, {
    TableColumn,
    ExpanderComponentProps,
} from "react-data-table-component";
import {
    FaFileCsv,
    FaFileExcel,
    FaFilePdf,
    FaFilter,
    FaRegEdit,
} from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import ExportDropdown from "../../../Components/menu/ExportDropdown";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import {
    Staff,
    fetchStaff,
    filterStaff,
    staffData,
    staffLoading,
} from "../../../store/slices/staffSlice";
import { Link } from "react-router-dom";
import Spinner from "../../../Components/Spinner";
import { InputPicker, Modal, Tooltip, Whisper } from "rsuite";
import CustomInput from "../../../Components/Input/CustomInput";
import axiosInstance from "../../../store/axiosInstance";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import { MultiSelect } from "react-multi-select-component";
import {
    fetchFormTemplate,
    formTemplateData,
} from "../../../store/slices/formTemplateSlice";
import IconButton from "../../../Components/Buttons/IconButton";
import {
    downloadCSV,
    exportToExcel,
    handlePDFDownload,
} from "../../../services/reusableFunc";
import { Column } from "../../../types/DataType";
import CreateDropdown from "../../../Components/menu/CreateDropDown";
import { claimsTypes } from "../../../types/AuthData";

type Option = {
    label: string;
    value: string;
};

const StaffAuditor = () => {
    const staffClaimsString = localStorage.getItem("claims");
    const claims: claimsTypes[] = staffClaimsString
        ? JSON.parse(staffClaimsString)
        : [];
    const hasRequiredClaims = (claimType: string): boolean => {
        return claims.some((claim) => claim.value === claimType);
    };

    const columns: TableColumn<Staff>[] = [
        {
            name: "Staff ID",
            selector: (row) => (row.maxStaffId === "null" ? "N/A" : row.maxStaffId),
            sortable: true,
        },
        {
            name: "Full Name",
            selector: (row) => row.fullName,
            sortable: true,
            cell: (row) => (
                <Link
                    style={{ overflow: "hidden" }}
                    to={`/app/user/staff/${row.staffId}/${row.firstName}`}
                    className={`font-bold text-black `}
                >
                    {row.firstName} {row.surName}
                </Link>
            ),
        },
        {
            name: "Address",
            selector: (row) => row.address,
            sortable: true,
        },
        {
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: "Phone Number",
            selector: (row) => row.phoneNumber,
            sortable: true,
        },
        {
            name: "Action",
            cell: (row) => (
                <div className="flex gap-1">
                    {user?.role === "CompanyAdmin" ||
                        user?.role === "Administrator" ||
                        hasRequiredClaims("Edit Staff") ? (
                        <div className="col-md-4">
                            <Whisper
                                placement="top"
                                controlId="control-id-hover"
                                trigger="hover"
                                speaker={<Tooltip>Edit Staff</Tooltip>}
                            >
                                <button className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline">
                                    <Link to={`/app/user/staff/${row.staffId}/${row.firstName}`}>
                                        <FaRegEdit className="text-base" />
                                    </Link>
                                </button>
                            </Whisper>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            ),
        },
    ];

    const [loading1, setLoading1] = useState<boolean>(false);
    const firstName = useRef<HTMLInputElement>(null);
    const lastName = useRef<HTMLInputElement>(null);
    const middleName = useRef<HTMLInputElement>(null);
    const email = useRef<HTMLInputElement>(null);
    const phoneNumber = useRef<HTMLInputElement>(null);
    const address = useRef<HTMLInputElement>(null);
    const [offerLetter, setOfferLetter] = useState<File | null>(null);
    const [addUserModal, setAddUserModal] = useState<boolean>(false);
    const [status, setStatus] = useState<string>("");
    const [filterModal, setFilterModal] = useState<boolean>(false);
    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    const companyId = user?.companyId;
    // useEffect(() => {
    //   if (companyId) {
    //     dispatch(fetchStaff(companyId));
    //     dispatch(fetchFormTemplate(companyId));
    //   }
    // }, [dispatch, companyId]);
    const staff = useSelector(staffData);
    const formTemplate = useSelector(formTemplateData);
    const loading = useSelector(staffLoading);

    const approvedStaff = staff.filter((staffMember) => staffMember.auditApproved);

    useEffect(() => {
        if (companyId && !staff.length) {
            dispatch(fetchStaff(companyId));
            dispatch(fetchFormTemplate(companyId));
        }
    }, [dispatch, staff, companyId]);

    const filterActivity = (e: FormEvent) => {
        e.preventDefault();
        setLoading1(true);

        if (companyId && status) {
            dispatch(
                filterStaff({
                    companyId: companyId,
                    status: status,
                })
            );
        }
        setLoading1(false);
        setFilterModal(false);
    };

    const resetFilter = (e: FormEvent) => {
        e.preventDefault();
        companyId && dispatch(fetchStaff(companyId));
        setFilterModal(false);
        setStatus("");
    };

    interface Props extends ExpanderComponentProps<Staff> {
        // currently, props that extend ExpanderComponentProps must be set to optional.
        someTitleProp?: string;
    }

    const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
        return (
            <div className="p-2 flex flex-col gap-2 text-xs">
                <div>
                    <span className="font-bold">Full Name: </span> {data.fullName}
                </div>
                <div>
                    <span className="font-bold">Email: </span> {data.email}
                </div>
                <div>
                    <span className="font-bold">Date Created: </span>{" "}
                    {dayjs(data.dateCreated).format("DD/MM/YYYY HH:mm:ss")}
                </div>
                <div>
                    <Link
                        to={`/app/user/staff/${data.staffId}/${data.firstName}`}
                        className="font-bold text-primary"
                    >
                        View Profile{" "}
                    </Link>
                </div>
            </div>
        );
    };

    const [searchText, setSearchText] = useState<string>("");

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const filteredData = approvedStaff?.filter(
        (item) =>
            item?.fullName?.toLowerCase().includes(searchText.toLowerCase()) ||
            item?.email?.toLowerCase().includes(searchText.toLowerCase())
    );

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setOfferLetter(file);
        }
    };

    const filteredTemplates = formTemplate.filter(
        (template) => template.templateType === "Non-Editable"
    );
    const formattedData = filteredTemplates.map((item) => ({
        label: item.templateName,
        value: item.templateName,
    }));
    const [selectedSupport, setSelectedSupport] = useState<Option[]>([]);
    const handleSupportSelectionChange = (selectedOptions: Option[]) => {
        setSelectedSupport(selectedOptions);
    };
    const selectedSupportString = selectedSupport
        .map((option) => option.label)
        .join(",");

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (
            firstName?.current?.value === "" ||
            lastName?.current?.value === "" ||
            phoneNumber?.current?.value === "" ||
            email?.current?.value === ""
        ) {
            return toast.error("Marked Fields must be filled");
        }

        setLoading1(true);

        const formData = new FormData();
        formData.append("CompanyId", companyId?.toString() ?? ""); // Using optional chaining and nullish coalescing
        formData.append("FirstName", firstName?.current?.value ?? "");
        formData.append("SurName", lastName?.current?.value ?? "");
        formData.append("MiddleName", middleName.current?.value ?? "");
        formData.append("Address", address.current?.value ?? "");
        formData.append("Email", email?.current?.value ?? "");
        formData.append("PhoneNumber", phoneNumber?.current?.value ?? "");
        offerLetter && formData.append("OfferLetter", offerLetter);

        try {
            const { data } = await axiosInstance.post(
                `/Staffs/add_staff?userId=${user?.uid}&attachedforms=${selectedSupportString}`,
                formData
            );
            toast.success(data.message);
            setLoading1(false);
            companyId && dispatch(fetchStaff(companyId));
            setAddUserModal(false);
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // console.log(error);;
            } else {
                toast.error("An error occurred");
            }
            setLoading1(false);
            setAddUserModal(false);
        } finally {
            setLoading1(false);
        }
    };

    return (
        <>
            <SEO title="Staffs" description="Staff" name="Promax-Care" />
            <Breadcrumb />
            <div className="mt-10">
                {approvedStaff.length <= 0 && loading && <Spinner color="#071952" />}
                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
                    <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
                        <h2 className="font-bold text-xl text-black w-auto">Staffs</h2>

                        <div className="relative w-full lg:col-span-2">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <BsSearch />
                            </div>
                            <input
                                type="text"
                                id="simple-search"
                                onChange={handleSearch}
                                className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                placeholder="Search By Full Name or Email"
                            />
                        </div>
                    </div>
                    <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                        <div className="flex items-center space-x-3 w-full md:w-auto">
                            <button
                                onClick={() => setFilterModal(true)}
                                id="actionsDropdownButton"
                                className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                type="button"
                            >
                                <FaFilter />
                                Filter
                            </button>
                            <ExportDropdown>
                                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                    <IconButton
                                        icon={<FaFilePdf className="text-red-500" />}
                                        title="Export as PDF"
                                        onClick={() =>
                                            handlePDFDownload(
                                                approvedStaff,
                                                columns as unknown as Column<Staff>[],
                                                "Staff.pdf"
                                            )
                                        }
                                    />
                                    <IconButton
                                        icon={<FaFileExcel className="text-green-600" />}
                                        title="Export as Excel Sheet"
                                        onClick={() =>
                                            exportToExcel(
                                                approvedStaff,
                                                columns as unknown as Column<Staff>[],
                                                "Staff"
                                            )
                                        }
                                    />
                                    <IconButton
                                        icon={<FaFileCsv className="text-green-500" />}
                                        title="Export as CSV"
                                        onClick={() =>
                                            downloadCSV({ data: approvedStaff, filename: "Staff.csv" })
                                        }
                                    />
                                </div>
                            </ExportDropdown>
                        </div>

                        {user?.role === "CompanyAdmin" ||
                            user?.role === "Administrator" ||
                            hasRequiredClaims("Add Staff") ? (
                            <CreateDropdown text="New Staff">
                                <div className="z-[999] w-max rounded bg-white  p-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                    <Link
                                        to={"/app/forms/create-staff"}
                                        className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
                                    >
                                        Create New Staff
                                    </Link>
                                    <Link
                                        to={"/app/forms/upload-staff-via-excel"}
                                        className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
                                    >
                                        Upload Via Excel
                                    </Link>
                                    <Link
                                        to={"/app/forms/upload-staff-via-xero"}
                                        className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
                                    >
                                        Import From Xero
                                    </Link>
                                </div>
                            </CreateDropdown>
                        ) : (
                            ""
                        )}
                        {/* <button type="button"
                              onClick={() => setAddUserModal(true)}
                              className="px-4 py-2 bg-primary text-white  disabled:bg-slate-400
                              transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm 
                             ">
  
                              Add New Staff
                          </button> */}
                    </div>
                </div>

                <DataTable
                    columns={columns}
                    data={filteredData}
                    expandableRows
                    expandableRowsComponent={ExpandableRowComponent}
                    pagination
                    paginationTotalRows={approvedStaff?.length}
                // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}
                />
            </div>

            <Modal
                open={filterModal}
                onClose={() => setFilterModal(false)}
                backdrop="static"
                autoFocus
                size={"sm"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold"></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form action="" className="space-y-2">
                            <div>
                                <label className=" text-xs text-gray-600 font-semibold">
                                    Select Staff
                                </label>
                                <InputPicker
                                    size="lg"
                                    placeholder="--All Staff--"
                                    // onChange={value => setUsers(value)}
                                    data={staff.map((user) => ({
                                        label: user.fullName,
                                        value: user.staffId,
                                    }))}
                                    className="w-full focus:outline-none focus:border-primary text-gray-600"
                                    appearance="subtle"
                                />
                            </div>

                            <div>
                                <label className=" text-xs text-gray-600 font-semibold">
                                    Select Status
                                </label>
                                <InputPicker
                                    size="lg"
                                    placeholder="--Select Status--"
                                    onChange={(value) => setStatus(value)}
                                    data={[
                                        { label: "Active", value: "true" },
                                        { label: "InActive", value: "false" },
                                    ]}
                                    className="w-full focus:outline-none focus:border-primary text-gray-600"
                                    appearance="subtle"
                                />
                            </div>

                            <div className="flex items-center justify-center mt-3">
                                <div className="text-center">
                                    <button
                                        type="submit"
                                        onClick={resetFilter}
                                        className="text-black bg-white border border-primary hover:bg-white/45 focus:ring-4 focus:ring-primary 
  font-medium rounded-md text-md px-8 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
  dark:focus:ring-primary"
                                    >
                                        Reset Filter
                                    </button>
                                </div>

                                <div className="text-center">
                                    <button
                                        type="submit"
                                        onClick={filterActivity}
                                        disabled={loading1 ? true : false}
                                        className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
  font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
  dark:focus:ring-primary"
                                    >
                                        {loading1 ? <Spinner /> : "Load"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>

            <Modal
                open={addUserModal}
                onClose={() => setAddUserModal(false)}
                backdrop="static"
                autoFocus
                size={"sm"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Add New Staff</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form action="">
                            <div className="py-2 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
                                <CustomInput
                                    label="First Name"
                                    type="text"
                                    ref={firstName}
                                    required
                                    placeholder="First Name"
                                />

                                <CustomInput
                                    label="Last Name"
                                    type="text"
                                    ref={lastName}
                                    required
                                    placeholder="Last Name"
                                />

                                <CustomInput
                                    label="Middle Name"
                                    type="text"
                                    ref={middleName}
                                    placeholder="Middle Name"
                                />
                                <CustomInput
                                    label="Email"
                                    type="email"
                                    ref={email}
                                    required
                                    placeholder="Email"
                                />

                                <CustomInput
                                    label="Phone Number"
                                    type="text"
                                    ref={phoneNumber}
                                    required
                                    placeholder="Phone Number"
                                />

                                <CustomInput
                                    label="Address"
                                    type="text"
                                    ref={address}
                                    placeholder="Address"
                                />
                            </div>
                            <CustomInput
                                label="Offer Letter"
                                type="file"
                                accept=".pdf,.doc,.docx"
                                onChange={handleFileChange}
                                placeholder="Offer Letter"
                            />
                            <div className="mt-2">
                                <label
                                    htmlFor="support"
                                    className="block mb-2 text-xs  text-gray-600 font-semibold dark:text-white"
                                >
                                    List of Documents to be sent as an attachment to staffs invite
                                    mail <span className="text-red-500">*</span>
                                </label>
                                <MultiSelect
                                    className="rounded-none h-11 multi-select"
                                    options={formattedData}
                                    value={selectedSupport}
                                    onChange={handleSupportSelectionChange}
                                    labelledBy={"Select multiple"}
                                    isCreatable={true}
                                />
                            </div>
                        </form>
                        <div className="mx-auto text-center mt-3">
                            <button
                                type="submit"
                                onClick={handleSubmit}
                                disabled={loading1 ? true : false}
                                className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                      font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                      dark:focus:ring-primary"
                            >
                                {loading1 ? <Spinner /> : "Create"}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        </>
    );
};

export default StaffAuditor;
