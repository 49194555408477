import React from 'react'
import { CompanyDetail } from '../../../types/AuthData';
import { ParticipantConsentForm } from '../../../types/DataType';

interface Props {
    companyOne: CompanyDetail | null | undefined;
    participantConsentForm: ParticipantConsentForm;
    handleCheckboxDataChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const EditCheck2: React.FC<Props> = ({ companyOne, participantConsentForm, handleCheckboxDataChange }) => {
    return (
        <div>
            <div className="my-7">
                <p className="flex items-center my-2 gap-3">
                    <input
                        type="checkbox"
                        name="consent_to_Access_Home"
                        checked={participantConsentForm.consent_to_Access_Home}
                        onChange={handleCheckboxDataChange}
                    />
                    <label htmlFor="supp">
                        <strong>I give consent for </strong> {companyOne?.companyName} to provide the
                        following assistance with my property/belongings:
                    </label>
                </p>
                <div className="flex items-center my-2">
                    <span>1.</span>
                    <input
                        className="w-[100%] outline-none p-1 border border-gray-300"
                        type="text"
                    />
                </div>
                <div className="flex items-center my-2">
                    <span>2.</span>
                    <input
                        className="w-[100%] outline-none p-1 border border-gray-300"
                        type="text"
                    />
                </div>
                <div className="flex items-center my-2">
                    <span>3.</span>
                    <input
                        className="w-[100%] outline-none p-1 border border-gray-300"
                        type="text"
                    />
                </div>
            </div>
        </div>
    )
}

export default EditCheck2