import React, { FormEvent, useEffect, useState } from "react";
import SEO from "../../../constants/SEO";
import { StaffProfileTypes } from "../../../types/AuthData";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import {
  StaffShift,
  fetchStaffShift,
  staffShiftData,
  staffShiftLoading,
} from "../../../store/slices/staffShiftSlice";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { toast } from "react-toastify";
import {
  fetchStaffProfile,
  selectProfileData,
} from "../../../store/slices/staffProfileSlice";
import Notification from "./components/Notification";
import Upcoming from "./components/Upcoming";
import TodayShift from "./components/TodayShift";
import Modals from "./components/Modals";
import ProfileCard from "./components/ProfileCard";
// import TrackingCard from "./components/TrackingCard";
import EmptyShift from "./components/EmptyShift";
import axiosInstance from "../../../store/axiosInstance";
import { isAxiosError } from "axios";
import { fetchRosterInfo } from "../../../services/fetchServices";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../../../Components/Spinner";
import { ConfirmModal } from "../../Admin/RosteringManagement/ShiftRoster/components/ConfirmModal";
import NoReportShift from "./components/NoReportShift";
import { FaArrowAltCircleDown } from "react-icons/fa";
import { Button, Modal } from "rsuite";
// import MileageCard from "./components/Mileage";
// import { CiStickyNote } from 'react-icons/ci';

const Dashboard = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault("Australia/Sydney");
  const nowInAustraliaTime = dayjs().tz().format("YYYY-MM-DD");
  const staffProfileString = localStorage.getItem("staffProfile");
  const staffProfile: StaffProfileTypes = staffProfileString
    ? JSON.parse(staffProfileString)
    : null;

  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();
  useEffect(() => {
    if (staffProfile.staffId) {
      dispatch(fetchStaffShift(Number(staffProfile?.staffId)));
      dispatch(fetchStaffProfile(staffProfile?.staffId.toString()));
    }
  }, [dispatch, staffProfile.staffId]);

  const staffShift = useSelector(staffShiftData);
  const profile = useSelector(selectProfileData);
  const loading = useSelector(staffShiftLoading);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [reasonModal, setReasonModal] = useState<boolean>(false);
  const [clockOutModal, setClockOutModal] = useState<boolean>(false);
  const [showMessageModal, setShowMessageModal] = useState<boolean>(false);
  const [showKmModal, setShowKmModal] = useState<boolean>(false);
  const [selectedShift, setSelectedShift] = useState<StaffShift | null>(null);
  const [saveShiftId, setSaveShiftId] = useState<number>(0);
  const tomorrow = dayjs().tz().add(1, "day").startOf("day");
  const [reason, setReason] = useState<string>("");
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  // Filtering and sorting the shifts
  const filteredAndSortedShifts = staffShift
    .filter((shift) =>
      dayjs(shift.dateFrom).isAfter(tomorrow.subtract(1, "second"))
    )
    .sort((a, b) => dayjs(a.dateFrom).diff(dayjs(b.dateFrom)));

  //todayShift
  const allTodayShifts = staffShift
    .filter(
      (shift: StaffShift) =>
        dayjs(shift.dateFrom).format("YYYY-MM-DD") === nowInAustraliaTime ||
        (shift.attendance && shift.attendId > 0 && !shift.isEnded)
    )
    .sort(
      (a: StaffShift, b: StaffShift) =>
        new Date(b.dateFrom).getTime() - new Date(a.dateFrom).getTime()
    );

  const handleShiftClick = (shift: StaffShift) => {
    setSelectedShift(shift);
    setShowModal(true);
  };

  useEffect(() => {
    if (profile) {
      if (!profile?.imageUrl || !profile?.signatureUrl) {
        setModalOpen(true); // Open the modal if any data is missing
      }
    }
  }, [profile]);


  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editLoading, setEditLoading] = useState<boolean>(false);

  const handleClockIn = async (shift: StaffShift) => {
    setIsLoading(true);
    setSelectedShift(shift);
    setSaveShiftId(shift.shiftRosterId);
    let latitude = 0; // Default latitude
    let longitude = 0; // Default longitude

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position: GeolocationPosition) => {
          latitude = position.coords.latitude;
          longitude = position.coords.longitude;

          try {
            // Now you have valid coordinates or default 0, proceed to clock in
            const { data } = await axiosInstance.get(
              `/Attendances/clock_in?userId=${user?.uid}&shiftId=${shift.shiftRosterId}&lat=${latitude}&lng=${longitude}`
            );
            toast.success(data.message);
            setShowMessageModal(true); // Only show modal on successful location fetch and API call
          } catch (error: unknown) {
            console.log(error);
            completeClockIn(shift.shiftRosterId, latitude, longitude);
          }

          setIsLoading(false); // End loading once everything is done
        },
        (error: GeolocationPositionError) => {
          console.log(error);
          // toast.error(`Error getting location: ${error.message}`);
          completeClockIn(shift.shiftRosterId, latitude, longitude); // Try to clock in with default coordinates
        }
      );
    } else {
      // toast.error("Geolocation is not supported by this browser.");
      completeClockIn(shift.shiftRosterId, latitude, longitude); // Try to clock in with default coordinates
    }
  };

  const completeClockIn = async (
    shiftId: number,
    latitude: number,
    longitude: number
  ) => {
    try {
      const { data } = await axiosInstance.get(
        `/Attendances/clock_in?userId=${user?.uid}&shiftId=${shiftId}&lat=${latitude}&lng=${longitude}`
      );
      toast.success(data.message);
      setShowModal(true);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
    }

    setIsLoading(false); // Ensure loading state is cleared regardless of outcome
  };

  const closeModal = () => {
    setShowMessageModal(false);
    navigate(`/app/staff/report-form/${saveShiftId}`);
    // setShowKmModal(true);
  };

  const navigate = useNavigate();

  const EditDetail = async (shiftId: number) => {
    setSaveShiftId(shiftId);
    setEditLoading(true);
    setReasonModal(true);
    try {
      const { reason }: StaffShift = await fetchRosterInfo(shiftId);
      setReason(reason);
      setEditLoading(false);
    } catch (error) {
      console.error("Failed to fetch roster info:", error);
    } finally {
      setEditLoading(false);
    }
  };

  const ClockOut = async (shiftId: number) => {
    setSaveShiftId(shiftId);
    setClockOutModal(true);
  };

  const [details, setDetails] = useState({
    startKm: "",
    endKm: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleModalClose = () => {
    setModalOpen(false); // Close modal
  };

  const handleSetKm = async (shiftId: number) => {
    setSaveShiftId(shiftId);
    setShowKmModal(true);
    setEditLoading(true);
    try {
      const { attendId }: StaffShift = await fetchRosterInfo(shiftId);

      try {
        const { data } = await axiosInstance.get(`/Attendances/${attendId}`);
        setDetails(data);
        setEditLoading(false);
      } catch (error) {
        console.log(error);
        setEditLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch roster info:", error);
      setEditLoading(false);
    } finally {
      setEditLoading(false);
    }
  };

  const submitKm = async (e: FormEvent) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      const { data } = await axiosInstance.get(
        `/ShiftRosters/fill_mileage?shiftId=${saveShiftId}&startKm=${details.startKm}&endKm=${details.endKm}`
      );
      toast.success(data.message);
      dispatch(fetchStaffShift(Number(staffProfile?.staffId)));
      setIsLoading(false);
      setShowKmModal(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const HandleClockOut = async () => {
    setEditLoading(true);

    try {
      const { data } = await axiosInstance.get(
        `/Attendances/clock_out?userId=${user?.uid}&shiftId=${saveShiftId}`
      );
      toast.success(data.message);
      setEditLoading(false);
      setClockOutModal(false);
      dispatch(fetchStaffShift(Number(staffProfile?.staffId)));
      // setShowKmModal(true);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setEditLoading(false);
    } finally {
      setEditLoading(false);
    }
  };

  const submitReason = async (e: FormEvent) => {
    e.preventDefault();

    if (reason === "") {
      return toast.error("Input Fields cannot be empty");
    }

    setIsLoading(true);

    try {
      const { data } = await axiosInstance.get(
        `/ShiftRosters/shift_cancellation?userId=${user?.uid}&reason=${reason}&shiftid=${saveShiftId}`
      );
      toast.success(data.message);
      setIsLoading(false);
      setReasonModal(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  function getActivityStatus(activity: StaffShift): string {
    const nowInAustraliaTime = dayjs()
      .tz("Australia/Sydney")
      .format("YYYY-MM-DD HH:mm:ss");
    const activityDateFrom = dayjs(activity.dateFrom)
      .subtract(10, "minutes")
      .format("YYYY-MM-DD HH:mm:ss");
    const activityDateTo = dayjs(activity.dateTo).format("YYYY-MM-DD HH:mm:ss");

    if (activityDateFrom > nowInAustraliaTime) {
      return "Upcoming";
    } else if (activity.status === "Cancelled") {
      return "Cancelled";
    } else if (
      activityDateTo < nowInAustraliaTime &&
      activity.attendance &&
      activity.isEnded
    ) {
      return "Present";
    } else if (activityDateTo < nowInAustraliaTime && !activity.attendance) {
      return "Absent";
    } else if (
      activityDateTo < nowInAustraliaTime ||
      (activity.attendance && !activity.isEnded)
    ) {
      return "Shift In progress";
    } else if (activity.attendance && activity.isEnded) {
      return "Present";
    } else {
      return "Clock-In";
    }
  }

  return (
    <>
      <SEO title="Dashboard" description="Dashboard" name="Promax-Care" />
      <div className="my-5 md:my-10 grid grid-cols-1 gap-4 lg:gap-8 md:gap-6 md:grid-cols-2 xl:grid-cols-3">
        <div className="space-y-4 order-last md:order-first">
          <ProfileCard profile={profile} />

          {/* <MileageCard /> */}
        </div>

        {loading && (
          <div className="fixed  inset-0 z-[9999]  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative w-auto max-w-lg mx-auto">
              <div
                className="relative z-[999] flex flex-col gap-2 justify-center items-center p-4  duration-500 ease-in-out bg-white
border-0 rounded-lg shadow-lg outline-none focus:outline-none"
              >
                <Spinner color="#071952" />
                <span className="font-semibold">Updating Shift Status</span>
              </div>
            </div>

            <div className="fixed inset-0 z-40 bg-gray-300 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
          </div>
        )}

        {allTodayShifts.length > 0 ? (
          allTodayShifts.map((activity, index) => (
            <TodayShift
              key={index}
              activity={activity}
              getActivityStatus={getActivityStatus}
              handleClockIn={handleClockIn}
              isLoading={isLoading}
              loading={loading}
              EditDetail={EditDetail}
              ClockOut={ClockOut}
              handleSetKm={handleSetKm}
            />
          ))
        ) : (
          <EmptyShift />
        )}

        <Notification />
        {filteredAndSortedShifts.length > 0 && (
          <Upcoming
            filteredAndSortedShifts={filteredAndSortedShifts}
            handleShiftClick={handleShiftClick}
            displayNumber={0}
          />
        )}
        {filteredAndSortedShifts.length > 1 && (
          <Upcoming
            filteredAndSortedShifts={filteredAndSortedShifts}
            handleShiftClick={handleShiftClick}
            displayNumber={1}
          />
        )}
        {filteredAndSortedShifts.length > 2 && (
          <Upcoming
            filteredAndSortedShifts={filteredAndSortedShifts}
            handleShiftClick={handleShiftClick}
            displayNumber={2}
          />
        )}
      </div>

      <div>
        <div className="px-2 py-4 font-bold bg-white w-full rounded text-black inline-flex gap-4 items-center text-sm md:text-base">
          Shift With Pending Report(s) will be displayed here{" "}
          <FaArrowAltCircleDown className="text-xl" />
        </div>
        <div className="my-5 md:my-10 grid grid-cols-1 gap-4 lg:gap-8 md:gap-6 md:grid-cols-2 xl:grid-cols-3">
          {staffShift.length > 0 &&
            staffShift.map((activity, index) => (
              <NoReportShift
                key={activity.shiftRosterId}
                num={index}
                activity={activity}
                getActivityStatus={getActivityStatus}
              />
            ))}
        </div>
      </div>
      <Modals
        selectedShift={selectedShift}
        setShowModal={setShowModal}
        showModal={showModal}
        loading={isLoading}
        // startKm={startKm}
        // endKm={endKm}
        staffProfile={staffProfile}
        setShowKmModal={setShowKmModal}
        setShowMessageModal={setShowMessageModal}
        showKmModal={showKmModal}
        showMessageModal={showMessageModal}
        submitKm={submitKm}
        closeModal={closeModal}
        setReason={setReason}
        reason={reason}
        setReasonModal={setReasonModal}
        reasonModal={reasonModal}
        submitReason={submitReason}
        editLoading={editLoading}
        handleInputChange={handleInputChange}
        details={details}
      />

      <ConfirmModal
        open={clockOutModal}
        onClose={() => setClockOutModal(false)}
        onConfirm={HandleClockOut}
        title="Clock Out"
        content={
          <p className="mb-4 font-bold text-center">
            This will automatically clock you out. Do you wish to proceed?
          </p>
        }
        isLoading={editLoading}
      />

      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        backdrop="static"
        autoFocus
        size="xs"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-4 font-bold text-center">
            Please upload your profile picture and add your signature to complete your profile.
          </p>
          <div className="flex justify-center items-center space-x-4">
            <Button className='bg-gray-500 text-white'>
              <Link
                to="/app/staff/profile"
              >
                My Profile
              </Link>
            </Button>

          </div>
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>

    </>
  );
};

export default Dashboard;
