import React from 'react'

const Instructions = () => {
    return (
        <div className='mx-auto my-10'>
            <p className='font-semibold text-[25px] text-black'>1.	Instructions</p>

            <div className='border border-gray-200  divide-y-2 text-black'>
                <div className='p-3'>
                    <p className='py-2'><strong>Step 1:</strong> Use the Guided Questions to identify any safety risks. If responses to the Guided Questions indicates a safety risk, proceed to the Safety Checklist at Step 2 to rate and describe each risk identified. You can find information about possible risks by: </p>
                    <ul className='list-disc pl-5'>
                        <li>talking to the participant, their family and carers,</li>
                        <li>reviewing information provided on the Participant Information Form,</li>
                        <li>reviewing support plans and other medical/behavioural reports provided by the participant, their families/carer or service provider (with the participant’s consent), and</li>
                        <li>by reviewing incident reports, the participant’s support plan and progress notes.</li>
                    </ul>
                </div>

                <div className='p-3'>
                    <p><strong>Step 2:</strong> Tick the checkbox <input type="checkbox" name="" id="" /> in the relevant column to indicate whether the risk is to the person or to others (including support workers) and provide a description of the risk.</p>
                </div>

                <div className='p-3'>
                    <p>Use the <i className='font-semibold'>Risk Category Table</i> and the <i className='font-semibold'>Consequence Rating Table</i> assess and select the unmanaged risk category by considering the level of risk if nothing is done about it. Enter the category into the “Unmanaged Risk Category” column.</p>
                </div>

                <div className='p-3'>
                    <p>Include a brief description of the risk and how it needs to be managed.</p>
                </div>

                <div className='p-3'>
                    <p><strong>Step 3:</strong> Transfer information about the <strong>identified</strong> risks to the <strong>Safety Management Plan.</strong></p>
                </div>
            </div>
        </div>
    )
}

export default Instructions