import React from "react";
import ServiceTable from "./ServiceTable";
import FirstForm from "./FirstForm";
import Details from "./Details";
import RepContact from "./RepContact";
import Summary from "./Summary";
import { Client } from "../../../store/slices/clientSlice";
import { Representative } from "../../../types/DataType";

interface Props {
  clientDetail: Client | null;
  careManager: Representative | null;
  values: {
    disability: string;
    secondaryDisability: string;
    meetingDate: string;
    staffingRequest: string;
  };
  handleInputDataChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  attendeeValues: string[];
  handleInputChange: (index: number, value: string) => void;
  handleAddRow: () => void;
  otherValues: {
    isNDISPlanAttached: boolean;
    isConsentSigned: boolean;
    staffingRequest: boolean;
  };
  handleRadioChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ReferralForme: React.FC<Props> = ({
  clientDetail,
  values,
  handleInputDataChange,
  attendeeValues,
  handleAddRow,
  handleInputChange,
  careManager,
  handleRadioChange,
  otherValues,
}) => {
  return (
    <div className="max-w-6xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Referral Form</h1>

      <FirstForm
        values={values}
        handleAddRow={handleAddRow}
        handleInputChange={handleInputChange}
        handleInputDataChange={handleInputDataChange}
        attendeeValues={attendeeValues}
      />

      <h2 className="text-xl font-bold mb-4">Participant Details</h2>

      <Details
        clientDetail={clientDetail}
        values={values}
        handleInputDataChange={handleInputDataChange}
      />

      <h2 className="text-xl font-bold mb-4">
        Primary Family or Representative Contact
      </h2>

      <RepContact careManager={careManager} />

      <h2 className="text-xl font-bold mb-4">
        Summary of Registration Groups Requested
      </h2>

      <Summary
        otherValues={otherValues}
        handleRadioChange={handleRadioChange}
        values={values}
        handleInputDataChange={handleInputDataChange}
      />

      <h2 className="text-xl font-bold mb-4">Services</h2>
      {clientDetail?.profileId && (
        <ServiceTable uid={clientDetail?.profileId.toString()} />
      )}
    </div>
  );
};

export default ReferralForme;
