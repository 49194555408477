import React from "react";
import { Schedule } from "../../../store/slices/ClientSlice/clientSupport";

interface Props {
  clientSupportSchedule: Schedule[];
}

const AttachmentForm = ({ clientSupportSchedule }: Props) => {
  return (
    <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border mt-2 border-gray-300 ">
      <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
        <thead className=" bg-[#b9b9b95e]">
          <tr className="divide-x-2 divide-gray-300">
            <th className="  px-4 py-2 font-semibold text-gray-900 w-96 ">
              Description of supports
            </th>
            <th className=" px-4 divide-x-2 py-2 font-semibold w-40 text-gray-900">
              Frequency/hours
            </th>
            <th className=" px-4 divide-x-2 py-2 font-semibold w-40  text-gray-900">
              Cost per hour
            </th>
            <th className=" px-4 divide-x-2 py-2 font-semibold w-40  text-gray-900">
              Total cost
            </th>
            <th className=" px-4 divide-x-2 py-2 font-semibold w-96  text-gray-900">
              Details of where and how the support will be provided
            </th>
          </tr>
        </thead>

        <tbody className="divide-y divide-gray-200">
          {clientSupportSchedule.map((item, key) => (
            <tr className="divide-x-2" key={key}>
              <td className=" px-4 py-2 text-gray-700 w-96 outline-none">
                {" "}
                {item?.supportType}({item?.itemNumber})
              </td>
              <td className=" px-4 py-2 text-gray-700 w-40 outline-none">
                {item?.quantity}
              </td>
              <td className=" px-4 py-2 text-gray-700 w-40 outline-none">
                {item?.cost}
              </td>
              <td className=" px-4 py-2 text-gray-700 w-40 outline-none">
                {item?.cost * item?.quantity}
              </td>
              <td className=" px-4 py-2 text-gray-700 w-96 outline-none">-</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AttachmentForm;
