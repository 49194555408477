import React from 'react';
import { FormDataType, Row, RowKeys } from '..';
import OthersForm from './OthersForm';


interface Props {
    rows: Row[];
    handleCellChange: (rowIndex: number, cellName: RowKeys, value: string) => void;
    formData: FormDataType;
    handleInputFormChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
}

const Risk: React.FC<Props> = ({ handleCellChange, rows, formData, handleInputFormChange }) => {


    return (
        <div className="p-4">
            <table className="w-full border-collapse mb-4">
                <tbody>
                    <tr className=' bg-gray-200 font-semibold'>
                        <td className="border p-2">Risk area</td>
                        <td className="border p-2">Strategy to manage the risk</td>
                    </tr>
                    {rows.map((row, index) => (
                        <tr key={index} className="divide-x-2">
                            <td className="whitespace-nowrap border p-2 text-gray-700 w-[40%]">
                                <input
                                    type="text"
                                    value={row.workingWell}
                                    onChange={(e) => handleCellChange(index, "workingWell", e.target.value)}
                                    className="border-none text-gray-900 text-sm outline-none block w-full bg-white h-full px-2.5"
                                />
                            </td>
                            <td className="whitespace-nowrap border p-2 text-gray-700 w-full">
                                <input
                                    type="text"
                                    value={row.notWorkingWell}
                                    onChange={(e) => handleCellChange(index, "notWorkingWell", e.target.value)}
                                    className="border-none text-gray-900 text-sm outline-none block w-full bg-white h-full px-2.5"
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <OthersForm formData={formData} handleInputFormChange={handleInputFormChange} />

        </div >
    );
};

export default Risk;


