import React, { useState } from "react";
// import customer from "../images/icon/customer-care.png";
import ChatInterface from "./Chat";
import { LiaTimesSolid } from "react-icons/lia";

const Support: React.FC = () => {
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  return (
    <div className=" z-[300]">
      {/* <div onClick={toggleChat} className="cursor-pointer fixed bottom-10  right-0 md:right-4 rounded-full p-2">
                    <img src={customer} alt="customer" className="w-12 lg:w-16 " />
                </div> */}

      <div className="relative">
        <button
          onClick={toggleChat}
          className="z-20 text-white flex flex-col shrink-0 grow-0 justify-around 
            fixed bottom-8 md:bottom-0 right-2 rounded-lg 
            mr-1 mb-5 lg:mr-5 lg:mb-5 xl:mr-5 xl:mb-5"
        >
          {!isChatOpen && (
            <span className="absolute top-0 right-0 z-1 h-4 w-4 flex justify-center items-center p-3 text-white text-sm rounded-full bg-red-500">
              !
            </span>
          )}
          <div className="p-3 shadow-lg rounded-full border-4 border-white bg-brand">
            {!isChatOpen ? (
              <svg
                className="w-8 h-8 lg:w-12 lg:h-12 "
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <LiaTimesSolid className="w-10 h-10 lg:w-12 lg:h-12 text-white" />
            )}
          </div>
        </button>
      </div>

      {isChatOpen && <ChatInterface onClose={toggleChat} />}
    </div>
  );
};

export default Support;

// import React, { useState, useRef } from "react";
// import ChatInterface from "./Chat";
// import { LiaTimesSolid } from "react-icons/lia";
// import Draggable from 'react-draggable';

// const Support: React.FC = () => {
//   const [isChatOpen, setIsChatOpen] = useState<boolean>(false);
//   const [dragging, setDragging] = useState<boolean>(false);
//   const clickTimeoutRef = useRef<number | null>(null);

//   const toggleChat = () => {
//     if (!dragging) {
//       setIsChatOpen(!isChatOpen);
//     }
//   };

//   const handleStart = () => {
//     setDragging(true);
//   };

//   const handleStop = () => {
//     setDragging(false);
//     // Set a timeout to delay the click action slightly to avoid toggling on drag end
//     clickTimeoutRef.current = window.setTimeout(() => {
//       setDragging(false);
//     }, 0);
//   };

//   // const handleClick = () => {
//   //   if (!dragging) {
//   //     toggleChat();
//   //   }
//   // };

//   return (
//     <Draggable onStart={handleStart} onStop={handleStop}>
//       <div className="z-[300]">
//         <div className="relative">
//           <button
//             // onClick={handleClick}
//             className="z-20 text-white flex flex-col shrink-0 grow-0 justify-around
//             fixed bottom-8 md:bottom-0 right-2 rounded-lg
//             mr-1 mb-5 lg:mr-5 lg:mb-5 xl:mr-5 xl:mb-5"
//           >
//             {!isChatOpen && (
//               <span className="absolute top-0 right-0 z-1 h-4 w-4 flex justify-center items-center p-3 text-white text-sm rounded-full bg-red-500">
//                 !
//               </span>
//             )}
//             <div className="p-3 shadow-lg rounded-full border-4 border-white bg-brand">
//               {!isChatOpen ? (
//                 <svg
//                   className="w-8 h-8 lg:w-12 lg:h-12"
//                   fill="currentColor"
//                   viewBox="0 0 20 20"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path
//                     fillRule="evenodd"
//                     d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z"
//                     clipRule="evenodd"
//                   />
//                 </svg>
//               ) : (
//                 <LiaTimesSolid className="w-10 h-10 lg:w-12 lg:h-12 text-white" />
//               )}
//             </div>
//           </button>
//         </div>

//         {isChatOpen && <ChatInterface onClose={toggleChat} />}
//       </div>
//     </Draggable>
//   );
// };

// export default Support;


