import React, { FormEvent } from 'react';
import Spinner from '../../../../../Components/Spinner';
import ClientTabForm from "../../components/ClientTabForm";
import { EditCommunitySupportDataType } from '../CommunitySupport';


interface Props {
    handleInputDataChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    handleSubmitEdit: (e: FormEvent) => Promise<void>;
    editComSupport: EditCommunitySupportDataType;
    loading: boolean;
}

const EditCommunitySupportCom: React.FC<Props> = ({ editComSupport, loading, handleSubmitEdit, handleInputDataChange }) => {

    return (
        <form className='mt-10' onSubmit={handleSubmitEdit}>
            <h2 className='font-bold text-xl text-black w-auto'>Community Support Needs</h2>
            <div>
                <div className="mt-5">
                    <ClientTabForm title='Community Support Needs'>
                        <div className='grid grid-cols-1 gap-16 lg:grid-cols-2'>
                            {/* Additional support detail textarea */}
                            <div>
                                <label htmlFor="additionalSupportDetail" className="font-bold">Is additional support for these issues? If so, please provide detail</label>
                                <textarea
                                    id="additionalSupportDetail"
                                    name="communityAssistance"
                                    rows={3}
                                    autoComplete="off"
                                    value={editComSupport.communityAssistance || ""}
                                    onChange={handleInputDataChange}
                                    className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                />
                            </div>
                            {/* Transportation details textarea */}
                            <div>
                                <label htmlFor="transportationDetails" className="font-bold">Do you need assistance to use transport? If so, please provide detail</label>
                                <textarea
                                    id="transportationDetails"
                                    name="transportAssistance"
                                    rows={3}
                                    autoComplete="off"
                                    value={editComSupport.transportAssistance || ""}
                                    onChange={handleInputDataChange}
                                    className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                />
                            </div>
                            {/* Transport type textarea */}
                            <div>
                                <label htmlFor="transportType" className="font-bold">What type of transport do you mainly use</label>
                                <textarea
                                    id="transportType"
                                    name="transportType"
                                    rows={3}
                                    autoComplete="off"
                                    value={editComSupport.transportType || ""}
                                    onChange={handleInputDataChange}
                                    className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                />
                            </div>
                            {/* Activities detail textarea */}
                            <div>
                                <label htmlFor="activitiesDetail" className="font-bold">Do you participate in any activities (such as employment, training or community activities)? If yes, please provide detail</label>
                                <textarea
                                    id="activitiesDetail"
                                    name="activitiesParticipation"
                                    rows={3}
                                    autoComplete="off"
                                    value={editComSupport.activitiesParticipation}
                                    onChange={handleInputDataChange}
                                    className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                />
                            </div>
                            {/* Access assistance detail textarea */}
                            <div>
                                <label htmlFor="accessAssistanceDetail" className="font-bold">Do you need assistance to access these activities? If yes, please provide detail</label>
                                <textarea
                                    id="accessAssistanceDetail"
                                    name="activitiesAssistance"
                                    rows={3}
                                    autoComplete="off"
                                    value={editComSupport.activitiesAssistance}
                                    onChange={handleInputDataChange}
                                    className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                />
                            </div>
                        </div>
                    </ClientTabForm>
                </div>
                <div className="mx-auto text-center mt-3">
                    <button
                        type="submit"
                        disabled={loading}
                        className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                               font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                               dark:focus:ring-primary"
                    >
                        {loading ? <Spinner /> : "Update"}
                    </button>
                </div>
            </div>
        </form>
    );
};

export default EditCommunitySupportCom;
