import React, { SelectHTMLAttributes, forwardRef } from "react";

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
    label: string;
    error?: string | undefined; // New error prop
    options: { value: string | number; label: string }[];
    firstOption?: string;
}

const CustomSelect: React.ForwardRefRenderFunction<
    HTMLSelectElement,
    SelectProps
> = ({ label, error, firstOption, options, ...props }, ref) => {
    return (
        <div>
            <label
                htmlFor={props.id || props.name}
                className="mb-2 text-xs flex gap-1 text-gray-600 font-semibold dark:text-white"
            >
                {label}
                {props.required && <small className="text-red-500">*</small>}
            </label>
            <div className="relative block overflow-hidden  border border-gray-300 h-11  otline-none rounded">
                <select
                    {...props}
                    ref={ref}
                    className={`border-none text-gray-900 text-sm outline-none   block bg-white w-full h-full px-2.5 ${props.disabled ? "bg-gray-200" : "bg-white"
                        } w-full p-2.5 focus:border-primary`}
                >
                    <option value="" hidden>{firstOption ? firstOption : "Select...."}</option>
                    {options.map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
            {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
        </div>
    );
};

export default forwardRef(CustomSelect);