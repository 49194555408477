import React from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import { Client } from "../../../store/slices/clientSlice";
import BehaviorSupport from "../../Admin/Profiles/clientTab/BehaviorSupport";

const ClientBehaviourSupport = () => {

  const clientProfileString = localStorage.getItem("clientProfile");
  const clientProfile: Client = clientProfileString
    ? JSON.parse(clientProfileString)
    : null;

  return (
    <>
      <SEO title={`Behavior Support Needs`} description="Client" name="Promax-Care" />
      <Breadcrumb showNavigation={false} />

      <div>
        <BehaviorSupport uid={clientProfile.profileId.toString()} />
      </div>
    </>
  );
};

export default ClientBehaviourSupport;
