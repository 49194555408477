import React, { useRef, useState, useCallback, useEffect } from "react";
import Webcam from "react-webcam";
import { FaCamera, FaSync } from "react-icons/fa";
import Spinner from "../../../../Components/Spinner";

interface WebcamCaptureProps {
  onCapture: (imageBlob: Blob | null) => void;
  loading?: boolean;
}

const WebcamCapture: React.FC<WebcamCaptureProps> = ({
  onCapture,
  loading,
}) => {
  const webcamRef = useRef<Webcam>(null);
  const [image, setImage] = useState<string | null | undefined>(null);
  const [camera, setCamera] = useState<"user" | "environment">("user");
  const [hasCamera, setHasCamera] = useState<boolean>(true);

  useEffect(() => {
    const checkForCamera = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const hasVideoInput = devices.some(
          (device) => device.kind === "videoinput"
        );
        setHasCamera(hasVideoInput);
      } catch (error) {
        console.error("Error checking camera:", error);
      }
    };
    checkForCamera();
  }, []);

  const videoConstraints: MediaTrackConstraints = {
    facingMode: { exact: camera },
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    setImage(imageSrc);
  }, [webcamRef]);

  const sendImage = async () => {
    if (image) {
      const blob = await base64ToBlob(image);
      onCapture(blob);
    }
  };

  const switchCamera = () => {
    setCamera((prevCamera) => (prevCamera === "user" ? "environment" : "user"));
  };

  return (
    <div>
      {!hasCamera && (
        <p className="text-xl font-bold">
          No camera detected. Please check your device settings.
        </p>
      )}
      {!image ? (
        <>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            mirrored={camera === "user"}
            onUserMediaError={(error) => console.error("Webcam error:", error)}
          />
          <div className="flex justify-center gap-2 items-center pt-4">
            <button
              className="bg-primary p-4 rounded text-white text-xs"
              onClick={capture}
            >
              <FaCamera />
            </button>
            <button
              className="bg-gray-500 p-4 rounded text-white text-xs"
              onClick={switchCamera}
            >
              <FaSync />
            </button>
          </div>
        </>
      ) : (
        <>
          <img src={image} alt="Captured" />
          <div className="flex justify-center gap-2 items-center pt-4">
            <button
              className="bg-gray-500 p-2 rounded text-white text-xs"
              onClick={() => setImage(null)}
            >
              Retake Photo
            </button>
            <button
              className="bg-green-600 p-2 rounded text-white text-xs"
              onClick={sendImage}
            >
              {loading ? <Spinner /> : "Submit Photo"}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default WebcamCapture;

// Helper function
async function base64ToBlob(base64: string): Promise<Blob> {
  const response = await fetch(base64);
  const blob = await response.blob();
  return blob;
}
