// import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { fetchCompany } from "../../services/fetchServices";
// import { RootState } from "../../store/store";
// import { CompanyDetail } from "../../types/AuthData";
// import Spinner from "../Spinner";

// const DaysLeft = () => {
//   const user = useSelector((state: RootState) => state?.auth?.user);
//   const [company, setCompany] = useState<CompanyDetail | null>(null);
//   const [loading, setLoading] = useState<boolean>(true);

//   useEffect(() => {
//     const handleCompanyFetch = async () => {
//       setLoading(true);
//       if (user?.companyId) {
//         const data = await fetchCompany(user?.companyId);
//         setCompany(data);
//       }
//       setLoading(false);
//     };
//     handleCompanyFetch();
//   }, [user?.companyId]);

//   if (loading) {
//     return <Spinner />;
//   }

//   const companyOneDate =
//     company?.expirationDate && new Date(company?.expirationDate);

//   // Get the current date in Australia (assuming Australian Eastern Standard Time)
//   const australiaTimeZoneOffset = 10; // UTC+10 for Australian Eastern Standard Time

//   // Get the current date in Australia
//   const currentDateInAustralia = new Date();
//   currentDateInAustralia.setHours(
//     currentDateInAustralia.getHours() + australiaTimeZoneOffset
//   );

//   const currentDateInAustraliaNumeric = currentDateInAustralia.getTime();
//   const companyOneDateNumeric: number = companyOneDate
//     ? companyOneDate.getTime()
//     : 0; // Ensure companyOneDate is not undefined

//   // Calculate the difference in milliseconds
//   const timeDifference = currentDateInAustraliaNumeric - companyOneDateNumeric;

//   const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

//   if (loading) {
//     return <Spinner />;
//   }
//   // Return only the days left as a number, ensuring a non-negative value
//   const daysLeft = daysDifference <= 0 ? Math.abs(daysDifference) : 0;

//   return daysLeft;
// };

// export default DaysLeft;

import React from "react";
import Spinner from "../Spinner";
import useFetchExpiredDate from "./components/FetchExpiredDate";

const DaysLeft = () => {
  const { daysDifference, loading } = useFetchExpiredDate();

  if (loading) {
    return <Spinner />;
  }

  // Provide a default value of 0 if daysDifference is null
  const daysLeft = daysDifference !== null ? Math.max(daysDifference, 0) : 0;

  return daysLeft;
};

export default DaysLeft;
