import React from "react";
import { StaffShift } from "../../../../store/slices/staffShiftSlice";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
interface Props {
  num: number;
  activity: StaffShift;
  getActivityStatus(activity: StaffShift): string;
}

const NoReportShift = ({ activity, getActivityStatus }: Props) => {
  const navigate = useNavigate();

  const fillReport = (shiftId: number) => {
    navigate(`/app/staff/report-form/${shiftId}`);
  };
  return (
    <>
      {activity.status !== "Cancelled" &&
        getActivityStatus(activity) === "Present" &&
        !activity.isShiftReportSigned && (
          <div className="border rounded-2xl bg-white shadow-sm">
            <div className="bg-gray-100 text-center p-2 flex justify-between items-center text-sm font-semibold  rounded-t-xl ">
              <span className="rounded px-2 bg-gray-100 justify-center items-center flex text-primary">
                #{activity.shiftRosterId}
              </span>{" "}
            </div>

            <div className="flex gap-2 flex-col   px-2 py-4 overflow-y-auto border-t border-b">
              <div className="text-sm flex justify-between items-center">
                <span className="font-bold">
                  {" "}
                  {activity
                    ? dayjs(activity?.dateFrom).format("dddd, MMMM D, YYYY")
                    : "-- : --"}
                </span>
                {activity && (
                  <button
                    onClick={() => fillReport(activity.shiftRosterId)}
                    type="button"
                    className="text-black font-semibold bg-secondary hover:bg-secondary/80 rounded-full text-xs  px-3 py-2  "
                  >
                    Fill Report
                  </button>
                )}
              </div>
              <div className="text-sm flex gap-10 items-center">
                <span className="font-bold">Client:</span>
                <span>{activity?.clients}</span>
              </div>
              <div className="text-sm flex gap-10 items-center">
                <span className="font-bold">Start Time:</span>
                <span>
                  {activity
                    ? dayjs(activity?.dateFrom).format("hh:mm A")
                    : "--"}
                </span>
              </div>
              <div className="text-sm flex gap-10 items-center">
                <span className="font-bold">End Time:</span>
                <span>
                  {activity ? dayjs(activity?.dateTo).format("hh:mm A") : "--"}
                </span>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default NoReportShift;
