import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import { AppDispatch, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import {
  fetchStaffAttendance,
  StaffAtendance,
  staffAttendanceData,
  staffAttendanceLoading,
} from "../../../store/slices/staffAttendaceSlice";
import { StaffProfileTypes } from "../../../types/AuthData";
import AttendanceCard from "./components/Card";
import dayjs from "dayjs";
import { formatDuration } from "../../../services/reusableValue";
import Spinner from "../../../Components/Spinner";
import PaginationComponent from "../../../Components/CustomPagination";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../store/axiosInstance";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import { Modal } from "rsuite";

const Attendance = () => {
  // const user = useSelector((state: RootState) => state?.auth?.user);
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const staffProfileString = localStorage.getItem("staffProfile");
  const staffProfile: StaffProfileTypes = staffProfileString
    ? JSON.parse(staffProfileString)
    : null;
  const dispatch: AppDispatch = useAppDispatch();
  // const companyId = user?.companyId;
  useEffect(() => {
    dispatch(fetchStaffAttendance(staffProfile.staffId));
  }, [dispatch]);

  const [attenDetails, setAttenDetails] = useState<StaffAtendance>({
    attendanceId: 0,
    clockIn: "",
    duration: 0,
    clockOut: "",
    dateCreated: "",
    startKm: 0,
    endKm: 0,
    report: "",
    imageUrl: "",
    staff: {
      staffId: 0,
      maxStaffId: "",
      firstName: "",
      surName: "",
      middleName: "",
      fullName: "",
      dateCreated: "",
      userCreated: "",
      dateModified: "",
      userModified: "",
    },
  });

  const handleDetails = async (id: number) => {
    // setSelectedShift(id);
    setLoading(true);
    setShowModal(true);
    try {
      const { data } = await axiosInstance.get(`/Attendances/${id}`);
      setAttenDetails({ ...data });
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
        setLoading(false);
      } else {
        toast.error("An error occurred");
        setLoading(false);
      }
    } finally {
      setLoading(false);
    }
  };
  const handleAddReport = (id: number) => {
    navigate(`/app/staff/attendance-report/${id}`);
    // Additional edit logic...
  };

  const staffAttendance = useSelector(staffAttendanceData);
  const loadingData = useSelector(staffAttendanceLoading);

  const itemsPerPage = 12;
  const pageCount = Math.ceil(staffAttendance.length / itemsPerPage);
  const displayData = staffAttendance.slice(
    pageNumber * itemsPerPage,
    (pageNumber + 1) * itemsPerPage
  );

  const topOfPageRef = useRef<HTMLInputElement>(null);

  const handlePageClick = (selectedItem: { selected: number }) => {
    setPageNumber(selectedItem.selected);
    topOfPageRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <>
      <SEO title={` Staff Attendance`} description="Staff" name="Promax-Care" />
      <Breadcrumb showNavigation={false} />

      <div
        className="flex justify-between items-center flex-wrap"
        ref={topOfPageRef}
      >
        <h2 className="font-bold text-2xl text-black w-auto">Attendance</h2>
      </div>

      {staffAttendance.length <= 0 && loadingData && (
        <Spinner color="#071952" />
      )}
      <div className="my-5">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 ">
          {staffAttendance.length > 0 &&
            displayData.map((item) => (
              <AttendanceCard
                key={item.attendanceId}
                staff={item.staff.fullName}
                clockIn={dayjs(item.clockIn).format("D/M/YYYY h:mm A")}
                clockOut={
                  !item.clockOut
                    ? "In Shift"
                    : dayjs(item.clockOut).format("D/M/YYYY h:mm A")
                }
                duration={formatDuration(item.duration)}
                onDetails={() => handleDetails(item.attendanceId)}
                onAddReport={() => handleAddReport(item.attendanceId)}
              />
            ))}
        </div>

        {pageCount > 1 && (
          <PaginationComponent
            pageCount={pageCount}
            onPageChange={handlePageClick}
          />
        )}
        <br />
      </div>

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Attendance Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Spinner />
          ) : (
            <div className="">
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Clock In:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {dayjs(attenDetails.clockIn).format("MMMM D, YYYY h:mm A")}
                </td>
              </tr>
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Clock Out:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {dayjs(attenDetails.clockOut).format("MMMM D, YYYY h:mm A")}
                </td>
              </tr>
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Duration:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {" "}
                  {formatDuration(attenDetails.duration)}
                </td>
              </tr>
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Kilometre:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {" "}
                  {attenDetails.endKm - attenDetails.startKm} Km
                </td>
              </tr>
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Report:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {" "}
                  {attenDetails.report}
                </td>
              </tr>
              {attenDetails.imageUrl && (
                <tr className="text-sm truncate ...">
                  <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                    Image:
                  </td>
                  <td className=" px-2 py-1 text-gray-700">
                    {" "}
                    <div style={{ width: "300px", height: "200px" }}>
                      <img src={attenDetails.imageUrl} alt="" width={"100%"} />
                    </div>
                  </td>
                </tr>
              )}
              {/* {
                                attenDetails.imageUrl && (
                                    <p>
                                        <strong>Image:</strong>  <div style={{ width: "300px", height: "200px" }}><img src={attenDetails.imageUrl} alt="" width={"100%"} /></div>
                                    </p>
                                )
                            } */}
              <tr className="text-sm truncate ...">
                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                  Date Created:
                </td>
                <td className=" px-2 py-1 text-gray-700">
                  {" "}
                  {dayjs(attenDetails.dateCreated).format(
                    "MMMM D, YYYY h:mm A"
                  )}
                </td>
              </tr>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default Attendance;
