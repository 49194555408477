import React, {
    ChangeEvent,
    useEffect,
    useState,
    FormEvent
} from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import DataTable, { TableColumn } from "react-data-table-component";
import { BsSearch } from "react-icons/bs";
import ExportDropdown from "../../../Components/menu/ExportDropdown";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import Spinner from "../../../Components/Spinner";
import {
    FaEye,
    FaFileCsv,
    FaFileExcel,
    FaFilePdf,
    FaFilter,
} from "react-icons/fa";
import IconButton from "../../../Components/Buttons/IconButton";
import { Tooltip, Whisper, Modal, Button } from "rsuite";
import {
    downloadCSV,
    exportToExcel,
    handlePDFDownload,
} from "../../../services/reusableFunc";
import { Column } from "../../../types/DataType";
import { allUserPolicyData, allUserPolicyLoading, AllUserPolicy, fetchUserAllPolicy } from "../../../store/slices/allUserPolicySlice";


const AllUserPolic = () => {
    const columns: TableColumn<AllUserPolicy>[] = [
        {
            name: "Role",
            selector: (row) => row.user?.role,
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => row.user?.fullName,
            sortable: true,
        },
        {
            name: "Policy Document",
            selector: (row) => row.policyDocument.policyName,
            sortable: true,
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className="flex gap-3 overflow-hidden cursor-pointer">
                    <Whisper
                        placement="auto"
                        controlId="control-id-hover"
                        trigger="hover"
                        speaker={<Tooltip>View Policy</Tooltip>}
                    >
                        <button
                            onClick={() => ViewPolicy(row.policyDocument.policyUrl)}
                            className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
                        >
                            <FaEye className="text-base cursor-pointer" />
                        </button>
                    </Whisper>
                </div>
            ),
        },
    ];

    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    const company = user?.companyId;
    useEffect(() => {
        if (company) {
            dispatch(fetchUserAllPolicy({ companyId: company, role: "", consent: true }));
        }
    }, [dispatch, company]);
    const allPolicy = useSelector(allUserPolicyData);
    const loadingData = useSelector(allUserPolicyLoading);
    const [searchText, setSearchText] = useState<string>("");
    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const filteredData = allPolicy?.filter(
        (item) =>
            item?.policyDocument.category.toLowerCase().includes(searchText.toLowerCase())
    );

    const [filterModal, setFilterModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [docModal, setDocModal] = useState<boolean>(false);
    const [docUrlModal, setDocUrlModal] = useState<string>("");
    // const ViewPolicy = (documentUrl: string): void => {
    //     window.open(documentUrl, "_blank");
    // };

    const ViewPolicy = (documentUrl: string): void => {
        setDocUrlModal(documentUrl); // Set the document URL in the state
        setIsLoading(true); // Start loading spinner
        setDocModal(true); // Open the modal
    };

    const getFileExtension = (url: string) => {
        return url.split('.').pop()?.toLowerCase(); // Get the file extension
    };


    const [role, setRole] = useState<string>("");
    const [consent, setConsent] = useState<boolean>(false);

    const resetFilter = (e: FormEvent) => {
        e.preventDefault();
        company && dispatch(fetchUserAllPolicy({ companyId: company, role: "", consent: true }));
        setFilterModal(false);
        setConsent(false)
        setRole("")
    };

    const filterActivity = (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        if (company) {
            dispatch(
                fetchUserAllPolicy({ companyId: company, role: role, consent: consent })
            );
        }
        setLoading(false);
        setFilterModal(false);
        setConsent(consent)
        setRole(role)
    };

    return (
        <>
            <SEO
                title="User Policy Consent"
                description="User Policy Consent"
                name="Promax-Care"
            />
            <Breadcrumb />

            <div className="mt-10">
                {allPolicy.length <= 0 && loadingData && <Spinner color="#071952" />}
                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
                    <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
                        <h2 className="font-bold text-xl text-black w-auto">
                            User Policy Consent
                        </h2>

                        <div className="relative w-full lg:col-span-2">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <BsSearch />
                            </div>
                            <input
                                type="text"
                                id="simple-search"
                                onChange={handleSearch}
                                className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                placeholder="Search By Name"
                            />
                        </div>
                    </div>
                    <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                        <div className="flex items-center space-x-3 w-full md:w-auto">
                            <button
                                onClick={() => setFilterModal(true)}
                                id="actionsDropdownButton"
                                className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                type="button"
                            >
                                <FaFilter />
                                Filter
                            </button>
                            <ExportDropdown>
                                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                    <IconButton
                                        icon={<FaFilePdf className="text-red-500" />}
                                        title="Export as PDF"
                                        onClick={() =>
                                            handlePDFDownload(
                                                allPolicy,
                                                columns as unknown as Column<AllUserPolicy>[],
                                                "Policies.pdf"
                                            )
                                        }
                                    />
                                    <IconButton
                                        icon={<FaFileExcel className="text-green-600" />}
                                        title="Export as Excel Sheet"
                                        onClick={() =>
                                            exportToExcel(
                                                allPolicy,
                                                columns as unknown as Column<AllUserPolicy>[],
                                                "Policies"
                                            )
                                        }
                                    />
                                    <IconButton
                                        icon={<FaFileCsv className="text-green-500" />}
                                        title="Export as CSV"
                                        onClick={() =>
                                            downloadCSV({
                                                data: allPolicy,
                                                filename: "Policies.csv",
                                            })
                                        }
                                    />
                                </div>
                            </ExportDropdown>
                        </div>
                    </div>
                </div>

                <DataTable
                    columns={columns}
                    data={filteredData}
                    pagination
                    paginationTotalRows={allPolicy?.length}
                />
            </div>

            <Modal
                open={docModal}
                onClose={() => setDocModal(false)}
                backdrop="static"
                autoFocus
                size={"lg"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">View Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        {isLoading && (
                            <div className="text-center">
                                <Spinner /> {/* Show spinner while iframe is loading */}
                                <p>Loading document...</p>
                            </div>
                        )}

                        {getFileExtension(docUrlModal) === 'pdf' ? (
                            <iframe
                                src={docUrlModal}
                                width="100%"
                                height="500px"
                                title="PDF Viewer"
                                style={{ display: isLoading ? "none" : "block" }} // Hide iframe until loaded
                                onLoad={() => setIsLoading(false)} // Stop loading spinner when iframe loads
                            />
                        ) : (
                            <iframe
                                src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(docUrlModal)}`}
                                width="100%"
                                height="500px"
                                title="Office Online Viewer"
                                style={{ display: isLoading ? "none" : "block" }} // Hide iframe until loaded
                                onLoad={() => setIsLoading(false)} // Stop loading spinner when iframe loads
                            />
                        )}
                    </>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setDocModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                open={filterModal}
                onClose={() => setFilterModal(false)}
                backdrop="static"
                autoFocus
                size={"sm"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold"></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form action="" className="space-y-2">
                            <div>
                                <div>
                                    <label className="mb-2 text-xs flex gap-1 text-gray-600 font-semibold dark:text-white">Role</label>
                                    <div className='relative block overflow-hidden  border border-gray-300 h-11  otline-none rounded'>
                                        <select className="border-none text-gray-900 text-sm outline-none   block bg-white w-full h-full px-2.5"
                                            onChange={e => setRole(e.target.value)}
                                        >
                                            <option value={""}>All</option>
                                            <option value={"Staff"}>Staff</option>
                                            <option value={"Client"}>Client</option>

                                        </select>
                                    </div>
                                </div>

                                <div className="mt-2">
                                    <label className="mb-2 text-xs flex gap-1 text-gray-600 font-semibold dark:text-white">Consent</label>
                                    <div className='relative block overflow-hidden  border border-gray-300 h-11  otline-none rounded'>
                                        <select
                                            className="border-none text-gray-900 text-sm outline-none block bg-white w-full h-full px-2.5"
                                            onChange={e => setConsent(e.target.value === "true")}  // Convert string to boolean
                                        >
                                            <option value="false">No</option>
                                            <option value="true">Yes</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="flex items-center justify-center mt-3">
                                <div className="text-center">
                                    <button
                                        type="submit"
                                        onClick={resetFilter}
                                        className="text-black bg-white border border-primary hover:bg-white/45 focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-8 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                                    >
                                        Reset Filter
                                    </button>
                                </div>

                                <div className="text-center">
                                    <button
                                        type="submit"
                                        onClick={filterActivity}
                                        disabled={loading ? true : false}
                                        className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                                    >
                                        {loading ? <Spinner /> : "Load"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>

        </>
    );
};

export default AllUserPolic;