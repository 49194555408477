import React from "react";
import { CompanyDetail } from "../../../types/AuthData";

interface Props {
  companyOne: CompanyDetail | null | undefined;
  // clientDetail: Client | null
}
const Hero = ({ companyOne }: Props) => {
  return (
    <div>
      {/* <div className="flex justify-end">
        <img
          className="w-24  mb-4 lg:mb-4"
          src="https://profitmax-001-site10.ctempurl.com/Uploads/botosoft-300x183-1-300x183235551864.png"
          alt="Promaxcare Logo"
        />
      </div> */}
      <div className="flex justify-end">
        <img
          className="w-24  mb-4 lg:mb-4"
          src={companyOne?.companyLogo}
          alt="Company Logo"
        />
      </div>
      <div>
        <h1 className="font-medium text-3xl">Support goal plan</h1>
        <p className="mt-4 text-[17px]">
          Refer to the Participant Information Form for additional information
          about the person, including the supports they want from us. This
          template is developed at the commencement of services and reviewed at
          a minimum of twelve months. A separate sheet should be completed for
          each goal. Participants are the drivers and owners of their plan.
          Participants can update and change their goals at any time.{" "}
        </p>
      </div>
    </div>
  );
};

export default Hero;
