import { isAxiosError } from "axios";
import axiosInstance from "../store/axiosInstance";
import { toast } from "react-toastify";

export const fetchPackages = async () => {
  try {
    const { data } = await axiosInstance.get("/Packages/get_all_packages");

    return data || [];
  } catch (error: unknown) {
    if (isAxiosError(error)) {
      toast.error(error.response?.data?.message);
      toast.error(error.response?.data?.title);
      // console.log(error);;
    } else {
      toast.error("Error fetching  company");
    }
  }
};

export const changeCompanyPackage = async (
  companyId: number,
  packageId: number,
  userNo: number
) => {
  try {
    const { data } = await axiosInstance.get(
      `/Companies/subscribe?companyId=${companyId}&packageId=${packageId}&noofuser=${userNo}`
    );

    return data || null;
  } catch (error: unknown) {
    if (isAxiosError(error)) {
      toast.error(error.response?.data?.message);
      toast.error(error.response?.data?.title);
      // console.log(error);;
    } else {
      toast.error("An error occurred");
    }
  }
};

export const upClient = async (
  companyId: number,
  packageId: number,
  userNo: number
) => {
  try {
    const { data } = await axiosInstance.get(
      `/Companies/update_client_payment?companyId=${companyId}&packageId=${packageId}&noofuser=${userNo}`
    );

    return data || null;
  } catch (error: unknown) {
    if (isAxiosError(error)) {
      toast.error(error.response?.data?.message);
      toast.error(error.response?.data?.title);
      // console.log(error);;
    } else {
      toast.error("An error occurred");
    }
  }
};
export const fetchCompany = async (companyId: number) => {
  try {
    const { data } = await axiosInstance.get(
      `/Companies/get_company/${companyId}`
    );

    return data.company || null;
  } catch (error: unknown) {
    if (isAxiosError(error)) {
      toast.error(error.response?.data?.message);
      toast.error(error.response?.data?.title);
      // console.log(error);;
    } else {
      toast.error("Error fetching  company");
    }
  }
};

export const fetchStaffInfo = async (uid: string | undefined) => {
  try {
    const { data } = await axiosInstance.get(`/Staffs/${uid}`);
    return data || null;
  } catch (error: unknown) {
    if (isAxiosError(error)) {
      toast.error(error.response?.data?.message);
      toast.error(error.response?.data?.title);
      // console.log(error);;
    } else {
      toast.error("Error fetching  staff");
    }
  }
};
export const fetchClientInfo = async (uid: string | undefined) => {
  try {
    const { data } = await axiosInstance.get(`/Profiles/${uid}`);
    return data || null;
  } catch (error: unknown) {
    if (isAxiosError(error)) {
      toast.error(error.response?.data?.message);
      toast.error(error.response?.data?.title);
      // console.log(error);;
    } else {
      toast.error("Error fetching  Client");
    }
  }
};
export const FetchClientSupportSchedule = async (uid: string | undefined) => {
  try {
    const { data } = await axiosInstance.get(
      `/Invoice/get_client_support_schedules?clientId=${uid}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchRosterInfo = async (uid: number | undefined) => {
  try {
    const { data } = await axiosInstance.get(`/ShiftRosters/${uid}`);
    return data || null;
  } catch (error: unknown) {
    if (isAxiosError(error)) {
      toast.error(error.response?.data?.message);
      toast.error(error.response?.data?.title);
      // console.log(error);;
    } else {
      toast.error("Error fetching  roster");
    }
  }
};
export const fetchReportInfo = async (id: number, uid: number | undefined) => {
  try {
    const { data } = await axiosInstance.get(
      `/ShiftReports/get_shiftreport_details/${id}?shiftId=${uid}`
    );
    return data || null;
  } catch (error: unknown) {
    console.log(error);

    return null;
  }
};
export const fetchDocumentByName = async (
  name: string,
  clientId: number,
  companyId: number
) => {
  try {
    const { data } = await axiosInstance.get(
      `/Documents/get_document_by_name?name=${name}&clientId=${clientId}&companyId=${companyId}`
    );

    return data.staffDocument || null;
  } catch (error: unknown) {
    return null;
  }
};
export const fetchNDISdoc = async (
  clientId: number,
  companyId: number,
  name: string
) => {
  try {
    const { data } = await axiosInstance.get(
      `/NDISForms/get_ndisform_info?clientId=${clientId}&companyId=${companyId}&name=${name}`
    );

    return data || null;
  } catch (error: unknown) {
    return null;
  }
};

export const fetchReferraldoc = async (
  clientId: number,
  companyId: number,
  name: string
) => {
  try {
    const { data } = await axiosInstance.get(
      `/ParticipantReferralForms/get_referral_form?clientId=${clientId}&companyId=${companyId}&name=${name}`
    );

    return data || null;
  } catch (error: unknown) {
    return null;
  }
};

export const fetchSupportPladoc = async (
  clientId: number,
  companyId: number,
  name: string
) => {
  try {
    const { data } = await axiosInstance.get(
      `/SupportPlans/get_details?clientId=${clientId}&companyId=${companyId}&name=${name}`
    );

    return data || null;
  } catch (error: unknown) {
    return null;
  }
};

export const fetchParticipantConsentdoc = async (
  clientId: number,
  companyId: number,
  name: string
) => {
  try {
    const { data } = await axiosInstance.get(
      `/ConsentForms/get_consent_form?clientId=${clientId}&companyId=${companyId}&name=${name}`
    );

    return data || null;
  } catch (error: unknown) {
    return null;
  }
};

export const fetchParticipantSkilldoc = async (
  clientId: number,
  companyId: number,
  name: string
) => {
  try {
    const { data } = await axiosInstance.get(
      `/SkillDevelopmentPlans/get_skill_development_plan?clientId=${clientId}&companyId=${companyId}&name=${name}`
    );

    return data || null;
  } catch (error: unknown) {
    return null;
  }
};

export const fetchSupportGoaldoc = async (
  clientId: number,
  companyId: number,
  name: string
) => {
  try {
    const { data } = await axiosInstance.get(
      `/SupportGoalPlans/get_supportgoalplan?clientId=${clientId}&companyId=${companyId}&name=${name}`
    );
    return data || null;
  } catch (error: unknown) {
    return null;
  }
};

export const fetchSupportPlandoc = async (
  clientId: number,
  companyId: number,
  name: string
) => {
  try {
    const { data } = await axiosInstance.get(
      `/SupportPlanReviews/get_details?clientId=${clientId}&companyId=${companyId}&name=${name}`
    );

    return data || null;
  } catch (error: unknown) {
    return null;
  }
};
export const fetchSupportRoutinedoc = async (clientId: number) => {
  try {
    const { data } = await axiosInstance.get(
      `SupportRoutines/get_client_supportroutine_form?clientId=${clientId}`
    );

    return data || null;
  } catch (error: unknown) {
    return null;
  }
};

export const fetchParticipantProfiledoc = async (
  clientId: number,
  companyId: number,
  name: string
) => {
  try {
    const { data } = await axiosInstance.get(
      `/ParticipantProfiles/get_profile?clientId=${clientId}&companyId=${companyId}&name=${name}`
    );

    return data || null;
  } catch (error: unknown) {
    return null;
  }
};

export const fetchParticipantChecklistdoc = async (
  clientId: number,
  companyId: number,
  name: string
) => {
  try {
    const { data } = await axiosInstance.get(
      `/ParticipantFileChecklists/get_checklist?clientId=${clientId}&companyId=${companyId}&name=${name}`
    );

    return data || null;
  } catch (error: unknown) {
    return null;
  }
};

export const fetchClientManagerProfle = async (
  companyId: number,
  name: string
) => {
  try {
    const { data } = await axiosInstance.get(
      `/Profiles/get_care_manager?companyId=${companyId}&name=${name}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchClientRepresentativeData = async (id: number) => {
  try {
    const { data } = await axiosInstance.get(
      `/Representatives/get_all?clientId=${id}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const fetchStaffDocument = async (id: number | string) => {
  try {
    const { data } = await axiosInstance.get(
      `/Documents/get_all_staff_documents?staffId=${id}`
    );
    return data.staffDocuments;
  } catch (error) {
    console.log(error);
  }
};
export const fetchClientDocument = async (id: number | string) => {
  try {
    const { data } = await axiosInstance.get(
      `/Documents/get_all_client_documents?clientId=${id}`
    );
    return data.clientDocuments;
  } catch (error) {
    console.log(error);
  }
};
export const fetchTicketDetails = async (id: number | string) => {
  try {
    const { data } = await axiosInstance.get(`/Tickets/${id}`);
    return data || null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const fetchClientPercentage = async (id: number | string) => {
  try {
    const data = await axiosInstance.get(
      `/Profiles/get_client_progress_score?profileId=${id}`
    );
    return data.data.percentage;
  } catch (error) {
    console.log(error);
    return 0;
  }
};
