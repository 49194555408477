import React from 'react'

const Section2 = () => {
  return (
    <div className=' m-auto text-black'>
      <p>This Safety Plan records current information about how to keep a person and all those around them safe. We aim to identify all available information about risks to participants and work with them to plan to manage those risks in a way that promotes dignity of risk in decision making. The plan should be reviewed at least annually, as part of support plan review meetings, and when the person’s circumstances change. All identified risks and management strategies should be transferred to the support plan and included as part of the participant’s ‘alerts’.</p>
      <p className='mt-5'>There are six sections in this document:</p>
      <ol className='px-6 list-decimal'>
        <li>Instructions</li>
        <li>Risk categories and consequence rating tables</li>
        <li>Guided questions</li>
        <li>Safety checklist</li>
        <li>Home visits risk assessment</li>
        <li>Safety management plan</li>
      </ol>
    </div>
  )
}

export default Section2