import * as React from "react";
import SEO from "../constants/SEO";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  const goHome = () => {
    navigate("/");
  };
  return (
    <>
      <SEO
        title="Error-404 - Not Found - Promax Care"
        description="Not found"
        name="Promax-Care"
      />
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm text-center mt-20">
            <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary dark:text-primary">
              404
            </h1>
            <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
              Something's missing.
            </p>
            <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
              Sorry, we can't find that page.{" "}
            </p>
            <button
              onClick={goHome}
              className="inline-flex text-white bg-primary hover:bg-primary focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-lg text-sm p-5 text-center dark:focus:ring-primary my-4"
            >
              Back to Homepage
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default PageNotFound;
