// import React, { useState } from "react";
// import PlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng,
// } from "react-places-autocomplete";
// import CustomInput from "../Input/CustomInput";
// import Spinner from "../Spinner";

// interface Coordinates {
//   lat: number;
//   lng: number;
// }

// interface AutocompleteLocationProps {
//   address: string;
//   setAddress: (address: string) => void;
//   setCoordinates: (coordinates: Coordinates) => void;
// }

// const AutocompleteLocation: React.FC<AutocompleteLocationProps> = ({
//   address,
//   setAddress,
//   setCoordinates,
// }) => {
//   const [errorMessage, setErrorMessage] = useState<string>("");

//   const handleSelect = async (value: string) => {
//     try {
//       const results = await geocodeByAddress(value);
//       const latLng = await getLatLng(results[0]);
//       setAddress(value);
//       setCoordinates(latLng);
//       setErrorMessage(""); // Clear error message if valid address is selected
//     } catch (error) {
//       console.error("Error", error);
//     }
//   };

//   return (
//     <div>
//       <PlacesAutocomplete
//         value={address}
//         onChange={setAddress}
//         onSelect={handleSelect}
//       >
//         {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
//           <div className="relative">
//             <CustomInput
//               label="Address"
//               {...getInputProps({ placeholder: "Enter location" })}
//               error={errorMessage}
//             />
//             <div>
//               {loading && <Spinner />}
//               {suggestions.map((suggestion) => {
//                 const style = suggestion.active
//                   ? { backgroundColor: "#a8dadc", cursor: "pointer" }
//                   : { backgroundColor: "#fff", cursor: "pointer" };
//                 return (
//                   <div
//                     className="overflow-y-auto z-10 top-[4.5rem] shadow-md bg-white p-2 rounded border"
//                     {...getSuggestionItemProps(suggestion, { style })}
//                     key={suggestion.placeId}
//                   >
//                     <small className="hover:bg-gray-100 py-2 px-1">
//                       {suggestion.description}
//                     </small>
//                   </div>
//                 );
//               })}
//             </div>
//           </div>
//         )}
//       </PlacesAutocomplete>
//     </div>
//   );
// };

// export default AutocompleteLocation;

import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import CustomInput from "../Input/CustomInput";
import Spinner from "../Spinner";

interface Coordinates {
  lat: number;
  lng: number;
}

interface AutocompleteLocationProps {
  address: string;
  setAddress: (address: string) => void;
  setCoordinates?: (coordinates: Coordinates) => void;
}

const AutocompleteLocation: React.FC<AutocompleteLocationProps> = ({
  address,
  setAddress,
  setCoordinates,
}) => {
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleSelect = async (value: string) => {
    try {
      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);
      setAddress(value);
      if (setCoordinates) {
        setCoordinates(latLng);
      }
      setErrorMessage(""); // Clear error message if valid address is selected
    } catch (error) {
      console.error("Error", error);
      setErrorMessage("Failed to retrieve location.");
    }
  };

  return (
    <div>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="relative">
            <CustomInput
              label="Address"
              {...getInputProps({ placeholder: "Enter location" })}
              error={errorMessage}
            />
            <div>
              {loading && <Spinner />}
              {suggestions.map((suggestion) => {
                const style = suggestion.active
                  ? { backgroundColor: "#a8dadc", cursor: "pointer" }
                  : { backgroundColor: "#fff", cursor: "pointer" };
                return (
                  <div
                    className="overflow-y-auto z-10 top-[4.5rem] shadow-md bg-white p-2 rounded border"
                    {...getSuggestionItemProps(suggestion, { style })}
                    key={suggestion.placeId}
                  >
                    <small className="hover:bg-gray-100 py-2 px-1">
                      {suggestion.description}
                    </small>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default AutocompleteLocation;


