import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  setShiftRosterCount,
  setAttendanceCount,
  setProgressNoteCount,
  setShiftForMonth,
  setShiftForDay,
  setMonth,
  setMonthPercentage,
  setFromWeek,
  setToWeek,
  setWeekCount,
  setDayPercentage,
  setWeekPercentage,
  setLoading,
  setError,
} from "../slices/DashboardSlice";
import axiosInstance from "../axiosInstance";
import { isAxiosError } from "axios";

export const fetchShiftRosterCount = createAsyncThunk(
  "attendance/fetchShiftRosterCount",
  async (companyId: string | number, { dispatch }) => {
    try {
      const response = await axiosInstance.get(
        `/CompanyAdmins/get_all_shift_rosters_count?companyId=${companyId}`
      );
      dispatch(setShiftRosterCount(response.data));
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        dispatch(setError(error.message));
        console.log(error);
      }
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const fetchAttendanceCount = createAsyncThunk(
  "attendance/fetchAttendanceCount",
  async (companyId: string | number, { dispatch }) => {
    try {
      const response = await axiosInstance.get(
        `/CompanyAdmins/get_all_attendances_count?companyId=${companyId}`
      );
      dispatch(setAttendanceCount(response.data));
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        dispatch(setError(error.message));
        console.log(error);
      }
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const fetchProgressNoteCount = createAsyncThunk(
  "attendance/fetchProgressNoteCount",
  async (companyId: string | number, { dispatch }) => {
    try {
      const response = await axiosInstance.get(
        `/CompanyAdmins/get_all_progressnote_count?companyId=${companyId}`
      );
      dispatch(setProgressNoteCount(response.data));
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        dispatch(setError(error.message));
        console.log(error);
      }
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const fetchShiftAnalysisCount = createAsyncThunk(
  "analysis/fetchShiftAnalysisCount",
  async (companyId: string | number, { dispatch }) => {
    try {
      const response = await axiosInstance.get(
        `/CompanyAdmins/get_shift_analysis?companyId=${companyId}`
      );
      const { shiftAnalysis } = response.data;
      if (shiftAnalysis) {
        dispatch(setShiftForMonth(shiftAnalysis.monthCount));
        dispatch(setShiftForDay(shiftAnalysis.dayCount));
        dispatch(setMonth(shiftAnalysis.month));
        dispatch(setMonthPercentage(shiftAnalysis.monthPercentage));
        dispatch(setFromWeek(shiftAnalysis.fromWeek));
        dispatch(setToWeek(shiftAnalysis.toWeek));
        dispatch(setWeekPercentage(shiftAnalysis.weekPercentage));
        dispatch(setDayPercentage(shiftAnalysis.dayPercentage));
        dispatch(setWeekCount(shiftAnalysis.weekCount));
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        dispatch(setError(error.message));
        console.log(error);
      }
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

const attendanceSlice = createSlice({
  name: "attendance",
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchShiftRosterCount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAttendanceCount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProgressNoteCount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchShiftAnalysisCount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addMatcher(
        (action) =>
          [
            fetchShiftRosterCount.fulfilled,
            fetchAttendanceCount.fulfilled,
            fetchProgressNoteCount.fulfilled,
            fetchShiftAnalysisCount.fulfilled,
          ].includes(action.type),
        (state) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) =>
          [
            fetchShiftRosterCount.rejected,
            fetchAttendanceCount.rejected,
            fetchProgressNoteCount.rejected,
            fetchShiftAnalysisCount.rejected,
          ].includes(action.type),
        (state) => {
          state.loading = false;
          state.error = null; // Set error to null
        }
      );
  },
});

export default attendanceSlice.reducer;
