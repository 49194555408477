import React from "react";
import SEO from "../../../constants/SEO";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import { Client } from "../../../store/slices/clientSlice";
import HealthSupport from "../../Admin/Profiles/clientTab/HealthSupport";

const ClientHealthSupport = () => {
  const clientProfileString = localStorage.getItem("clientProfile");
  const clientProfile: Client = clientProfileString
    ? JSON.parse(clientProfileString)
    : null;

  return (
    <>
      <SEO title={`Health Support Needs`} description="Client" name="Promax-Care" />
      <Breadcrumb showNavigation={false} />

      <div>
        <HealthSupport uid={clientProfile.profileId.toString()} />
      </div>
    </>
  );
};

export default ClientHealthSupport;
