import React from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import { Client } from "../../../store/slices/clientSlice";
import ClientAidAndEquip from "../../Admin/Profiles/clientTab/Aid&Equiment";

const Aids = () => {
  const clientProfileString = localStorage.getItem("clientProfile");
  const clientProfile: Client = clientProfileString
    ? JSON.parse(clientProfileString)
    : null;

  return (
    <>
      <SEO
        title={` Aids & Equipments`}
        description="Client"
        name="Promax-Care"
      />
      <Breadcrumb showNavigation={false} />

      <div>
        <ClientAidAndEquip uid={clientProfile.profileId.toString()} />
      </div>
    </>
  );
};

export default Aids;
