import React, { useEffect, useState } from "react";
import DataTable, {
  TableColumn,
  ExpanderComponentProps,
} from "react-data-table-component";
import { FaEye } from "react-icons/fa";
import { Modal, Tooltip, Whisper } from "rsuite";
import moment from "moment";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { fetchClientDocument } from "../../../../services/fetchServices";
import {
  Document,
  filterDocument,
} from "../../../../store/slices/documentSlice";
import SEO from "../../../../constants/SEO";
import Spinner from "../../../../Components/Spinner";
import CustomInput from "../../../../Components/Input/CustomInput";
import { isAxiosError } from "axios";
import axiosInstance from "../../../../store/axiosInstance";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../../store/store";

interface ClientProps {
  uid: string;
  clientName: string;
}

const AllClientDocument = ({ uid, clientName }: ClientProps) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault("Australia/Sydney");
  const nowInAustraliaTime = dayjs().tz().format("YYYY-MM-DD");
  const [loading, setLoading] = useState(true);
  const [documentList, setDocumentList] = useState<Document[]>([]);
  const fetchDocuments = async () => {
    setLoading(true);
    try {
      const result: Document[] = await fetchClientDocument(Number(uid));

      setDocumentList(result);

      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch documents:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDocuments();
  }, [uid]);

  const columns: TableColumn<Document>[] = [
    {
      name: "User",
      selector: (row) => row.user,
      sortable: true,
    },
    {
      name: "Document",
      selector: (row) => row.documentName,
      sortable: true,
      cell: (row) => (
        <div className="truncate " style={{ overflow: "hidden" }}>
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>{row.documentName}</Tooltip>}
          >
            <div className="" style={{ overflow: "hidden" }}>
              <span> {row.documentName}</span>

              {/* <span className='flex'>
                                  <span className='bg-primary text-white pointer px-2 py-1 rounded d-flex justify-content-center align-items-center'
                                      title='View'
  
                                      onClick={() => handleView(row.documentUrl)}
                                  >
  
                                      <FaEye />
                                  </span>
  
                              </span> */}
            </div>
          </Whisper>
        </div>
      ),
    },
    {
      name: "Implementation Date",
      selector: (row) =>
        !row.implementationDate
          ? "No Implementation Date"
          : moment(row.implementationDate).format("ll"),
      cell: (row) => (
        <span>
          {!row.implementationDate
            ? "Not Applicable"
            : moment(row.implementationDate).format("ll")}
        </span>
      ),
    },
    {
      name: "Expiration Date",
      selector: (row) =>
        !row.expirationDate
          ? "Not Applicable"
          : moment(row.expirationDate).format("ll"),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => {
        const isExpired =
          dayjs(row.expirationDate).format("YYYY-MM-DD") < nowInAustraliaTime;
        let status;
        let statusClasses = "px-8 py-2 rounded font-bold";

        if (isExpired) {
          status = "Expired";
          statusClasses += " bg-red-500 text-white";
        } else if (row.status === "Rejected") {
          status = "Rejected";
          statusClasses += " bg-gray-300 text-black";
        } else if (row.status === "Pending") {
          status = "Pending";
          statusClasses += " bg-secondary text-black";
        } else if (row.status === "Accepted") {
          status = "Accepted";
          statusClasses += " bg-green-500 text-white";
        } else {
          status = row.status;
          statusClasses += " bg-transparent";
        }

        return (
          <span
            className={statusClasses}
            style={{ fontSize: "10px", whiteSpace: "nowrap" }}
          >
            {status}
          </span>
        );
      },
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-2 justify-center">
          <Whisper
            placement="auto"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Preview Document</Tooltip>}
          >
            <button
              onClick={() => handleView(row.documentUrl)}
              className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
            >
              <FaEye className="text-base" />
            </button>
          </Whisper>
        </div>
      ),
    },
  ];

  const handleView = (documentUrl: string): void => {
    window.open(documentUrl, "_blank");
  };

  const user = useSelector((state: RootState) => state?.auth?.user);
  const companyId = user?.companyId;
  const dispatch: AppDispatch = useAppDispatch();

  const [docuName, setDocuName] = useState("");
  const [uploadModal, setUploadModal] = useState(false);

  const [docFile, setDocFile] = useState<File | null>(null);
  const [expirationDate, setExpirationDate] = useState("");
  const [implementationDate, setImplementationDate] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setDocFile(file);
    }
  };

  const submitData = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!docFile) {
      toast.error("No File Selected");
      return;
    }
    setLoadingSubmit(true);
    const formData = new FormData();
    user && formData.append("CompanyId", user.companyId?.toString() ?? ""); // Using optional chaining and nullish coalescing
    formData.append("DocumentName", docuName);
    formData.append("ImplementationDate", implementationDate);
    formData.append("ExpirationDate", expirationDate);
    formData.append("User", clientName);
    formData.append("Status", "Pending");
    formData.append("UserRole", "Client");
    formData.append("UserId", uid);
    docFile && formData.append("DocumentFile", docFile);

    try {
      const { data } = await axiosInstance.post(
        `/Documents/add_document?userId=${user?.uid}`,
        formData
      );

      if (data.status === "Success") {
        toast.success(data?.message);
        if (companyId) {
          dispatch(
            filterDocument({
              companyId: companyId,
              dateFrom: "",
              dateTo: "",
              sta: "",
              admin: "",
              status: "",
              role: "Client",
              archive: false,
            })
          );
        }
        setUploadModal(false);
        setLoadingSubmit(false);
      } else {
        toast.error(data.message);
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoadingSubmit(false);
    } finally {
      setLoadingSubmit(false);
    }
  };

  interface Props extends ExpanderComponentProps<Document> {
    // currently, props that extend ExpanderComponentProps must be set to optional.
    someTitleProp?: string;
  }

  const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
    return (
      <div className="p-2 flex flex-col gap-2 text-xs">
        <div>
          <span className="font-bold">Document Name: </span> {data.documentName}
        </div>
        <div>
          <span className="font-bold">Date Created: </span>{" "}
          {moment(data.dateCreated).format("lll")}
        </div>
        <div>
          <span className="font-bold">Date Modified: </span>
          {moment(data.dateModified).format("lll")}
        </div>
      </div>
    );
  };

  return (
    <>
      <SEO
        title="Client Document"
        description="Client Document"
        name="Promax-Care"
      />
      <div className="mt-10">
        {documentList?.length <= 0 && loading && <Spinner color="#071952" />}
        <div className="flex justify-between items-center flex-wrap my-4">
          <h2 className="font-bold text-xl text-black w-auto">
            {clientName}'s Documents
          </h2>
          <button
            onClick={() => {
              setUploadModal(true);
              setDocuName("");
            }}
            className="px-4 py-2 hover:text-white hover:no-underline bg-primary text-white  disabled:bg-slate-400 transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm 
   "
          >
            Upload other document
          </button>
        </div>
        <DataTable
          columns={columns}
          data={documentList}
          expandableRows
          expandableRowsComponent={ExpandableRowComponent}
          pagination
          paginationTotalRows={documentList?.length}
        />
      </div>

      <Modal
        open={uploadModal}
        onClose={() => setUploadModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Upload Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={submitData}>
              <div className="p-2 grid grid-cols-1 gap-4">
                <CustomInput
                  label="Document Name"
                  type="text"
                  value={docuName}
                  onChange={(e) => setDocuName(e.target.value)}
                  autoComplete="off"
                  required
                  minLength={2}
                />

                <CustomInput
                  label="Implementation Date"
                  type="date"
                  onChange={(e) => setImplementationDate(e.target.value)}
                  value={implementationDate}
                  required
                  autoComplete="off"
                />
                <CustomInput
                  label="Expiration Date"
                  type="date"
                  onChange={(e) => setExpirationDate(e.target.value)}
                  value={expirationDate}
                  autoComplete="off"
                />
                <CustomInput
                  label="Select Document"
                  type="file"
                  onChange={handleFileChange}
                  error={!docFile ? `Upload ${docuName} (.pdf,.doc,.docx)` : ""}
                />
              </div>
              <div className="mx-auto text-center mt-3 lg:col-span-2">
                <button
                  type="submit"
                  disabled={loadingSubmit ? true : false}
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                    font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                    dark:focus:ring-primary"
                >
                  {loadingSubmit ? <Spinner /> : "Upload"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default AllClientDocument;
