import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

interface ClientDailyLiving {
  activities: string;
  dailyLivingId: number;
  dateCreated: string;
  dateModified: string;
  details: string;
  profile: {
    profileId: number;
    firstName: string;
    surName: string;
    middleName: string;
    clientId: number;
    email: string;
    phoneNumber: string;
    gender: string;
    fullName: string;
  };
  profileId: number;
  support: string;
  supportLevel: string;
  userCreated: string;
  userModified: string;
}

export interface ClientDailyLivingState {
  loading: boolean;
  data: Array<ClientDailyLiving>;
  error: string | undefined;
}
const initialState: ClientDailyLivingState = {
  loading: false,
  data: [],
  error: undefined,
};
export const fetchClientDailyLiving = createAsyncThunk(
  "ClientDailyLiving/fetchClientDailyLiving",
  async (uid: number) => {
    try {
      const response = await api.fetchClientDailyLiving(uid);
      return response;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

const clientDailyLivingSlice = createSlice({
  name: "clientDailyLiving",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchClientDailyLiving.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchClientDailyLiving.fulfilled,
      (state, action: PayloadAction<Array<ClientDailyLiving>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(fetchClientDailyLiving.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {},
});
export const userSelector = (state: RootState) => state.clientDailyLiving;
export default clientDailyLivingSlice.reducer;

// Define selectors to access the profile slice state
export const clientDailyLivingData = (state: RootState) =>
  state.clientDailyLiving.data;
export const clientDailyLivingLoading = (state: RootState) =>
  state.clientDailyLiving.loading;
export const clientDailyLivingError = (state: RootState) =>
  state.clientDailyLiving.error;
