import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

interface AllTimeSheet {
  staff: boolean;
  client: boolean;
  totalDuration: number;
  totalAgreedDuration: number;
  totalAmount: number;
  normalDuration: number;
  eveningDuration: number;
  exceptionalDuration: number;
  satDuration: number;
  sunDuration: number;
  phDuration: number;
  totalKm: number;
  nightShift: number;
  staffName: string;
  fromDate: string;
  toDate: string;
  clientName: string;
  xeroUploadLink: string;
}

export interface AllTimeSheetState {
  loading: boolean;
  data: AllTimeSheet | null;
  error: string | undefined;
}
const initialState: AllTimeSheetState = {
  loading: false,
  data: null,
  error: undefined,
};
export const fetchAllTimeSheet = createAsyncThunk(
  "AllTimeSheet/fetchAllTimeSheet",
  async ({
    user,
    dateFrom,
    dateTo,
  }: {
    user: string;
    dateFrom: string;
    dateTo: string;
  }) => {
    try {
      const response = await api.fetchAllTimeSheet(user, dateFrom, dateTo);

      return response.timesheet;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

const allTimeSheetSlice = createSlice({
  name: "allTimeSheet",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchAllTimeSheet.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllTimeSheet.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchAllTimeSheet.rejected, (state, action) => {
      state.loading = false;
      state.data = null;
      state.error = action.error.message;
    });
  },
  reducers: {},
});
export const userSelector = (state: RootState) => state.allTimeSheet;
export default allTimeSheetSlice.reducer;

export const allTimeSheetData = (state: RootState) => state.allTimeSheet.data;
export const allTimeSheetLoading = (state: RootState) =>
  state.allTimeSheet.loading;
export const allTimeSheetError = (state: RootState) => state.allTimeSheet.error;
