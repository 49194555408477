import React from "react";
import { CompanyDetail } from "../../../types/AuthData";
import { ParticipantConsentForm } from "../../../types/DataType";
import EditConsent from "./EditConsent";
import EditBox2 from "./EditBox2";
import EditContact from "./EditContact";
import EditForm2 from "./EditForm2";

interface SectionProps {
    companyOne: CompanyDetail | null | undefined;
    alertValues: string[];
    handleInputDataChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    handleAddRow: () => void;
    participantConsentForm: ParticipantConsentForm;
    handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleRadioDataChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleInputImportantChange: (index: number, event: React.ChangeEvent<HTMLInputElement>) => void;
    handleInputNotChangeEdit: (index: number, event: React.ChangeEvent<HTMLInputElement>) => void;
    handleAddNotRowEdit: () => void;
    notImportant: string[];
}

const EditSection: React.FC<SectionProps> = ({
    companyOne, alertValues, handleRadioDataChange, handleInputImportantChange, notImportant,
    handleAddRow, participantConsentForm, handleCheckboxChange, handleInputNotChangeEdit, handleAddNotRowEdit, }) => {
    return (
        <div className="mt-5">
            <strong className="italic">
                Please tick the following boxes to indicate where you give your consent
            </strong>
            <EditConsent participantConsentForm={participantConsentForm} handleCheckboxChange={handleCheckboxChange} />

            <EditBox2 companyOne={companyOne} participantConsentForm={participantConsentForm} handleCheckboxChangeBox={handleCheckboxChange} />

            <div className=" p-4 max-[1024px]:w-[100%]  my-5 border border-black ">
                <p className="text-[16px] mb-3">
                    Consent for us to share information about you to other providers or
                    individuals
                </p>
                <div className="flex items-center gap-3 mt-4">
                    <input
                        type="radio"
                        id="consent_to_Share_Information"
                        name="consentOption"
                        value="true"
                        checked={participantConsentForm.consent_to_Share_Information}
                        onChange={handleRadioDataChange}
                    />
                    <label htmlFor="consent_to_Share_Information" className="text-[16px]">
                        <strong>I give consent</strong> for {companyOne?.companyName} to share my
                        information with the following people or services. I understand that
                        sharing information may include discussing my support needs, sharing
                        support plans and reviewing any incidents that have occurred.
                    </label>
                </div>
                <EditContact alertValues={alertValues} handleAddRow={handleAddRow} handleInputImportantChange={handleInputImportantChange} />

                <div className="flex items-center gap-3 mt-4">
                    <input
                        type="radio"
                        id="consent_Not_to_Share_Information"
                        name="consentOption"
                        value="false"
                        checked={participantConsentForm.consent_Not_to_Share_Information}
                        onChange={handleRadioDataChange}
                    />
                    <label htmlFor="consent_Not_to_Share_Information" className="text-[16px]">
                        <strong>I do not give consent</strong> for {companyOne?.companyName} to share my
                        information with the following people or services:
                    </label>
                </div>
                <EditForm2 alertValues={notImportant} handleAddRow={handleAddNotRowEdit} handleInputImportantChange={handleInputNotChangeEdit} />
                <br />
                <br />
                <strong className="my-5">
                    Note that may have to disclose some information without your permission when we are required to by law. For example, when we need to ensure your safety or that of others, or when directed to do so by a court of law.
                </strong>
            </div>
        </div>
    );
};

export default EditSection;
