import React, { FormEvent } from 'react'
import { Modal } from 'rsuite'
import dayjs from 'dayjs';
import { ClientShift } from '../../../../store/slices/clientShiftSlice';
import { Id } from 'react-toastify';
import Spinner from '../../../../Components/Spinner';

interface Props {
    loading: boolean;
    editLoading: boolean;
    showModal: boolean;
    reasonModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    setReasonModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedShift: ClientShift | null;
    setReason: React.Dispatch<React.SetStateAction<string>>;
    reason: string;
    appointment: string;
    submitReason: (e: FormEvent) => Promise<Id | undefined>;
    setAppointment: React.Dispatch<React.SetStateAction<string>>;
    setAppointmentModal: React.Dispatch<React.SetStateAction<boolean>>;
    appointmentModal: boolean;
    submitAppointment: (e: FormEvent) => Promise<Id | undefined>
}
const Modals = ({ selectedShift, setShowModal, showModal, editLoading, reason, reasonModal, setReason, submitAppointment,
    setReasonModal, submitReason, loading, setAppointmentModal, appointmentModal, appointment, setAppointment

}: Props) => {


    return (
        <>

            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                backdrop="static"
                autoFocus
                size={"sm"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Shift Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedShift && (

                        <table >

                            <tbody >
                                <tr className='text-sm truncate ...'>
                                    <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                                        Status:
                                    </td>
                                    <td className=" px-2 py-1 text-gray-700"> <span
                                        className={`px-3 py-1 rounded font-bold text-white ${selectedShift.status === "Pending"
                                            ? "bg-secondary"
                                            : selectedShift.status === "Cancelled"
                                                ? "bg-red-500"
                                                : "bg-primary"
                                            }`}
                                    >
                                        {selectedShift.status}
                                    </span></td>
                                </tr>

                                <tr className='text-sm truncate ...'>
                                    <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                                        Staff:
                                    </td>
                                    <td className=" px-2 py-1 text-gray-700">{selectedShift.staff.fullName}</td>

                                </tr>
                                <tr className='text-sm truncate ...'>
                                    <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                                        Clients:
                                    </td>
                                    <td className=" px-2 py-1 text-gray-700">{selectedShift.clients}</td>

                                </tr>
                                <tr className='text-sm truncate ...'>
                                    <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                                        From:
                                    </td>
                                    <td className=" px-2 py-1 text-gray-700">{dayjs(selectedShift?.dateFrom).format('D MMMM, YYYY h:mm A')} </td>

                                </tr>
                                <tr className='text-sm truncate ...'>
                                    <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                                        To:
                                    </td>
                                    <td className=" px-2 py-1 text-gray-700"> {dayjs(selectedShift?.dateTo).format('D MMMM, YYYY h:mm A')} </td>

                                </tr>
                                <tr className='text-sm truncate ...'>
                                    <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                                        Activities:
                                    </td>
                                    <td className="px-2 py-1 whitespace-normal text-gray-700"> {selectedShift.activities}</td>

                                </tr>
                                {selectedShift.reason && <tr className='text-sm truncate ...'>
                                    <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                                        Reason:
                                    </td>
                                    <td className=" px-2 py-1 text-gray-700">  {selectedShift.reason}</td>

                                </tr>
                                }

                            </tbody>
                        </table>

                    )}
                </Modal.Body>
            </Modal>

            <Modal
                open={appointmentModal} onClose={() => setAppointmentModal(false)}
                backdrop="static" autoFocus size={"sm"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Add Appointment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {editLoading ? (
                        <Spinner />
                    ) : (

                        <form action="" onSubmit={submitAppointment}>
                            <div className="py-2">
                                <label htmlFor="appointment" className='font-bold'>Provide Appointment Details </label>
                                <textarea id="appointment" rows={3}
                                    name='appointment'
                                    autoComplete="off"
                                    value={appointment || ""}
                                    onChange={(e) => setAppointment(e.target.value)}
                                    className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400" defaultValue={""} />
                            </div>
                            <div className='mx-auto text-center mt-3'>
                                <button type="submit"
                                    disabled={loading}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                    font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                    dark:focus:ring-primary">{
                                        loading ? <Spinner /> : "Submit"
                                    }</button>

                            </div>
                        </form>

                    )}

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            <Modal
                open={reasonModal} onClose={() => setReasonModal(false)}
                backdrop="static" autoFocus size={"sm"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Cancel Shift</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {editLoading ? (
                        <Spinner />
                    ) : (

                        <form action="" onSubmit={submitReason}>
                            <div className="py-2">
                                <label htmlFor="report" className='font-bold'>Provide reasons for cancelling shift </label>
                                <textarea id="report" rows={3}
                                    name='report'
                                    autoComplete="off"
                                    value={reason || ""}
                                    onChange={(e) => setReason(e.target.value)}
                                    className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400" defaultValue={""} />
                            </div>
                            <div className='mx-auto text-center mt-3'>
                                <button type="submit"
                                    disabled={loading}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                    font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                    dark:focus:ring-primary">{
                                        loading ? <Spinner /> : "Submit"
                                    }</button>

                            </div>
                        </form>

                    )}

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Modals