import React, { FormEvent, useEffect, useRef, useState } from "react";
import SupportPlanReview from "./__component/Main";
import Breadcrumb from "../../Components/label/BreadCrumb";
import PrintComponent from "../PrintTab";
import Hero from "./__component/Hero";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchClientInfo,
  fetchClientManagerProfle,
  fetchCompany,
  fetchSupportPlandoc,
} from "../../services/fetchServices";
import { CompanyDetail } from "../../types/AuthData";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Client } from "../../store/slices/clientSlice";
import Spinner from "../../Components/Spinner";
import { Staff } from "../../store/slices/staffSlice";
import Date from "./__component/Date";
import axiosInstance from "../../store/axiosInstance";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import { SupportPlanReviewData } from "../../types/DataType";
import EditSupportPlan from "./EditSupportPlan";

type RowKeys = "workingWell" | "notWorkingWell";

interface Row {
  workingWell: string;
  notWorkingWell: string;
}

const SupportPlan = () => {
  const { uid } = useParams();
  const [clientDetail, setClientDetail] = useState<Client | null>(null);
  const [careManager, setCareManager] = useState<Staff | null>(null);
  const [loadingClient, setLoadingClient] = useState<boolean>(true);
  const user = useSelector((state: RootState) => state?.auth?.user);
  const [companyOne, setCompanyOne] = useState<
    CompanyDetail | null | undefined
  >(null);
  const [checker, setChecker] = useState<SupportPlanReviewData | null>(null);
  const [planReviewForm, setPlanReviewForm] = useState<SupportPlanReviewData>({
    supportPlanReviewId: 0,
    attendees: "",
    meetingDate: "",
    supportPlanDate: "",
    workingWell: "",
    notWorkingWell: "",
    achievedGoals: "",
    goalsInProgress: "",
    newGoals: "",
    profileId: 0,
    document_Name: "",
    implementationDate: "",
    expirationDate: "",
    companyId: 0,
  });

  useEffect(() => {
    fetchClientData();
    handleCompanyFetch();
    fetchClientManager();
    fetchDoc();
  }, [uid, clientDetail?.careManager]);

  const handleCompanyFetch = async () => {
    // setLoading(true);
    if (user?.companyId) {
      const data = await fetchCompany(user?.companyId);
      setCompanyOne(data);
    }
    // setLoading(false);
  };
  const fetchClientData = async () => {
    const data = await fetchClientInfo(uid);
    setClientDetail(data);
    setLoadingClient(false);
  };

  const fetchClientManager = async () => {
    if (clientDetail?.careManager) {
      const data = await fetchClientManagerProfle(
        Number(user?.companyId),
        clientDetail?.careManager
      );
      setCareManager(data);
    }
  };

  const [editAttendee, setEditAttendee] = useState<string[]>([]);

  const fetchDoc = async () => {
    const data = await fetchSupportPlandoc(
      Number(uid),
      Number(user?.companyId),
      "PCD-109 P10 Support plan review"
    );
    setPlanReviewForm({ ...data });
    setChecker(data);
    setEditAttendee(
      data?.attendees.split(",").map((activity: string) => activity.trim())
    );
  };

  const handleInputEditChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newActivities = [...editAttendee];
    newActivities[index] = event.target.value;
    setEditAttendee(newActivities);
  };

  const handleAddRowEdit = () => {
    setEditAttendee([...editAttendee, ""]);
  };

  const ImplementationDate = useRef<HTMLInputElement>(null);
  const ExpirationDate = useRef<HTMLInputElement>(null);
  const componentRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [attendeeValues, setAttendeeValues] = useState<string[]>([""]);

  const handleInputChange = (index: number, value: string) => {
    const newValues = [...attendeeValues];
    newValues[index] = value;
    setAttendeeValues(newValues);
  };

  const handleAddRow = () => {
    setAttendeeValues([...attendeeValues, ""]);
  };

  const [rows, setRows] = useState<Row[]>([
    { workingWell: "", notWorkingWell: "" },
    { workingWell: "", notWorkingWell: "" },
    { workingWell: "", notWorkingWell: "" },
    { workingWell: "", notWorkingWell: "" },
    { workingWell: "", notWorkingWell: "" },
  ]);

  const [allValue, setAllValue] = useState({
    value1: "",
    value2: "",
    value3: "",
    value4: "",
    value5: "",
    value6: "",
  });

  const handleInputDataEditChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setPlanReviewForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputDataChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setAllValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCellChange = (
    rowIndex: number,
    cellName: RowKeys,
    value: string
  ) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex][cellName] = value;
    setRows(updatedRows);
  };

  const getWorkingWellValues = () => {
    return rows.map((row) => row.workingWell).join(", ");
  };

  const getNotWorkingWellValues = () => {
    return rows.map((row) => row.notWorkingWell).join(", ");
  };
  const navigate = useNavigate();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const expirationDateValue = ExpirationDate.current?.value;
    const workingWellString = getWorkingWellValues();
    const notWorkingWellString = getNotWorkingWellValues();

    const info = {
      attendees: attendeeValues.join(", "),
      meetingDate: allValue.value1,
      supportPlanDate: allValue.value3,
      workingWell: workingWellString,
      notWorkingWell: notWorkingWellString,
      achievedGoals: allValue.value4,
      goalsInProgress: allValue.value5,
      newGoals: allValue.value6,
      profileId: uid,
      document_Name: "PCD-109 P10 Support plan review",
      implementationDate: ImplementationDate.current?.value,
      ...(expirationDateValue ? { expirationDate: expirationDateValue } : {}),
      companyId: user?.companyId,
    };

    try {
      setLoading(true);

      const { data } = await axiosInstance.post(
        `/SupportPlanReviews/fill_form?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      setLoading(false);
      navigate(
        `/app/user/clients/${clientDetail?.profileId}/${clientDetail?.firstName}`
      );
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Breadcrumb />
      {loadingClient && (
        <div className="fixed  inset-0 z-[9999]  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-auto max-w-lg mx-auto">
            <div
              className="relative z-[999] flex justify-center items-center p-4  duration-500 ease-in-out bg-white
border-0 rounded-lg shadow-lg outline-none focus:outline-none"
            >
              <Spinner color="#071952" />
            </div>
          </div>

          <div className="fixed inset-0 z-40 bg-gray-300 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
        </div>
      )}

      {checker ? (
        <EditSupportPlan
          companyOne={companyOne}
          clientDetail={clientDetail}
          careManager={careManager}
          handleInputDataEditChange={handleInputDataEditChange}
          planReviewForm={planReviewForm}
          editAttendee={editAttendee}
          handleInputEditChange={handleInputEditChange}
          handleAddRowEdit={handleAddRowEdit}
        />
      ) : (
        <form onSubmit={handleSubmit}>
          <PrintComponent
            componentRef={componentRef}
            triggerLabel="Print / Download"
            documentTitle="Support Plan Review Form"
          />
          <div
            ref={componentRef}
            className="mx-auto  max-w-3xl p-4 md:p-4 lg:p-8 bg-white"
          >
            <Hero companyOne={companyOne} />
            <SupportPlanReview
              attendeeValues={attendeeValues}
              handleInputChange={handleInputChange}
              handleAddRow={handleAddRow}
              clientDetail={clientDetail}
              careManager={careManager}
              allValue={allValue}
              handleCellChange={handleCellChange}
              handleInputDataChange={handleInputDataChange}
              rows={rows}
            />
            <Date
              ExpirationDate={ExpirationDate}
              ImplementationDate={ImplementationDate}
            />
            <div className="mx-auto text-center mt-3 lg:col-span-2">
              <button
                type="submit"
                disabled={loading}
                className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"
              >
                {loading ? <Spinner /> : " Submit"}
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default SupportPlan;
