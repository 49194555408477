import React from 'react';
import { Routes, Route } from 'react-router-dom';
import FallBack from '../FallBack';
import SEO from '../../constants/SEO';
import SetupBreak from './Pages/setupBreak';

// Import other admin routes as needed
const SetupLayout = () => {
    const SetupPage = React.lazy(() => import("./SetupPage"));
    const BusinessPage = React.lazy(() => import("./Pages"));

    return (
        <div>
            <SEO
                title='Setup - Promax Care'
                description='Get started'
                name='Promax-Care'
            />
            {/* Auth layout components */}
            <React.Suspense fallback={<FallBack />}>
                <Routes>
                    <Route path='/' element={<SetupPage />} />
                    <Route path='/business/:uid' element={<BusinessPage />} />
                    <Route path='/business-complete' element={<SetupBreak />} />

                </Routes>
            </React.Suspense>
        </div>
    );
};

export default SetupLayout;
