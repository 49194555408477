import React from "react";
// import SignatureComponent from "../../../Components/Signature";
import { Client } from "../../../store/slices/clientSlice";
// import dayjs from "dayjs";
import { FaTimes } from "react-icons/fa";
import { CompanyDetail } from "../../../types/AuthData";
import { Representative } from "../../../types/DataType";

interface Props {
  clientDetail: Client | null;
  setSignature: React.Dispatch<React.SetStateAction<Blob | null>>;
  handleSignatureSave: (signatureBlob: Blob) => void;
  signature: Blob | null;
  companyOne: CompanyDetail | null | undefined;
  clientRep: Representative | null;
  careManager: Representative | null;
  careManagerName: string;
  setCareManagerName: React.Dispatch<React.SetStateAction<string>>;
}

const AgreementSignature = ({
  // handleSignatureSave,
  // signature,
  clientDetail,
  setSignature,
  companyOne,
  careManager,
}: // careManagerName,
// setCareManagerName
Props) => {
  return (
    <div className="rounded-lg mt-2">
      <strong className="text-black">Agreement</strong>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border mt-5 p-2">
        <div>
          <p>Name of Participant/Participant’s representative</p>
          <p>
            <input
              type="text"
              value={clientDetail?.fullName || ""}
              className="w-80 border-b border-0 border-b-black text-black font-bold outline-none"
              readOnly
            />
            {/* Date: {dayjs().format("D, MMM YYYY")} */}
          </p>
        </div>
        <div>
          <p>Signature of Participant/Participant’s representative</p>
          {/* {!signature && (
                        <SignatureComponent
                            onSignatureSave={handleSignatureSave}
                            text="Click to sign here"
                        />
                    )}
                    {!signature &&
                        <p className="text-red-500">signature is required</p>
                    } */}
          <div className="flex gap-2">
            {clientDetail?.signature && (
              <img
                src={clientDetail?.signature as string}
                alt="Participant Signature"
                className="img-thumbnail block"
                style={{
                  display: "block",
                  width: "150px",
                }}
              />
            )}
            {clientDetail?.signature && (
              <button
                className="text-base text-red-600"
                type="button"
                onClick={() => setSignature(null)}
              >
                <FaTimes className="text-lg border p-1" />
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border mt-5 p-2">
        <div>
          <p>Name of {companyOne?.companyName} representative</p>
          <p>
            <input
              type="text"
              value={careManager?.fullName}
              className="w-80 border-b border-0 border-b-black text-black font-bold outline-none"
              readOnly
            />
            {/* Date: {dayjs().format("D, MMM YYYY")} */}
          </p>
        </div>
        <div>
          <p>Signature of {companyOne?.companyName} representative</p>
          <div className="flex gap-2">
            {/* {careManager?.signatureUrl && (
                            <img
                                src={careManager?.signatureUrl as string}
                                alt="CompanyRep Signature"
                                className="img-thumbnail block"
                                style={{
                                    display: "block",
                                    width: "150px",
                                }}
                            />
                        )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgreementSignature;
