import React from "react";
import { Shift } from "../../../../../store/slices/shiftRosterSlice";
interface Props {
  signature: string | null;
  isMealManagementPlan: boolean;
  setIsMealManagementPlan: React.Dispatch<React.SetStateAction<boolean>>;
  isDrinkingProblem: boolean;
  setIsDrinkingProblem: React.Dispatch<React.SetStateAction<boolean>>;
  isHealthIssues: boolean
  setIsHealthIssues: React.Dispatch<React.SetStateAction<boolean>>;
  isIncident: boolean;
  setIsIncident: React.Dispatch<React.SetStateAction<boolean>>;
  isBehaviourConcerned: boolean;
  setIsBehaviourConcerned: React.Dispatch<React.SetStateAction<boolean>>;
  details_IfNotMealMaganementPlan: React.RefObject<HTMLTextAreaElement>;
  details_IfProblemExist: React.RefObject<HTMLTextAreaElement>
  details_IfHealthIssuesExistt: React.RefObject<HTMLTextAreaElement>
  details_IfIsIncident: React.RefObject<HTMLTextAreaElement>
  details_ifIsBehaviourConcerned: React.RefObject<HTMLTextAreaElement>;
  goal_Progress: React.RefObject<HTMLTextAreaElement>;
  contactFamily: React.RefObject<HTMLTextAreaElement>;
  shiftDetail: Shift | null
}
const FormPartB = ({
  isMealManagementPlan,
  setIsMealManagementPlan,
  isDrinkingProblem,
  setIsDrinkingProblem,
  isHealthIssues,
  setIsHealthIssues,
  isBehaviourConcerned,
  isIncident,
  setIsBehaviourConcerned,
  setIsIncident,
  details_IfNotMealMaganementPlan,
  details_IfHealthIssuesExistt,
  details_IfIsIncident,
  details_IfProblemExist,
  details_ifIsBehaviourConcerned,
  contactFamily,
  goal_Progress,
  shiftDetail
}: Props) => {
  const handleMealManagementChange = (value: boolean) => {
    setIsMealManagementPlan(value);
  };

  return (
    <table className=" divide-y-2 divide-gray-300 bg-white text-sm  w-full rounded-lg border  border-gray-300 mb-6">
      <tbody className="divide-y divide-gray-200 w-full py-2">
        <tr className="flex flex-col w-full ">
          <td className="px-4 py-2 font-medium text-gray-900">
            <span className="font-bold text-black">Meals:</span> Were they given
            in line with the participant’s mealtime management plan?
          </td>
          <td className="px-4 py-2 text-gray-700 outline-none flex gap-4 items-center">
            <label className="flex gap-1 items-center text-sm">
              <input
                type="checkbox"
                checked={isMealManagementPlan}
                onChange={() => handleMealManagementChange(true)}
                className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
              />
              Yes
            </label>
            <label className="flex gap-1 items-center text-sm">
              <input
                type="checkbox"
                checked={!isMealManagementPlan}
                onChange={() => handleMealManagementChange(false)}
                className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
              />
              No
            </label>
            <span>If No, Provide Details:</span>
          </td>
          {!isMealManagementPlan && (
            <td className="px-4 py-2 text-gray-700 outline-none flex gap-4 items-center">
              <textarea ref={details_IfNotMealMaganementPlan}
                required={!isMealManagementPlan}
                className="border w-full p-2"></textarea>
            </td>
          )}
        </tr>
        <tr className="flex flex-col w-full ">
          <td className=" px-4 py-2 font-medium  text-gray-900">
            Did the participant experience any eating or drinking problems?
          </td>
          <td className=" px-4 py-2 text-gray-700 outline-none flex gap-4 items-center">
            <label className={`flex gap-1 items-center text-sm  `}>
              <input
                type="checkbox"
                checked={isDrinkingProblem}
                onChange={() => setIsDrinkingProblem(true)}
                className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
              />
              Yes
            </label>
            <label className={`flex gap-1 items-center text-sm  `}>
              <input
                type="checkbox"
                checked={!isDrinkingProblem}
                onChange={() => setIsDrinkingProblem(false)}
                className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
              />
              No
            </label>
            <span>If Yes, Provide Details:</span>
          </td>
          {isDrinkingProblem && <td className="px-4 py-2 text-gray-700 outline-none flex gap-4 items-center">
            <textarea name="" id=""
              ref={details_IfProblemExist}
              required={isDrinkingProblem}
              className="border w-full p-2"></textarea>
          </td>}
        </tr>
        <tr className="flex flex-col w-full ">
          <td className=" px-4 py-2 font-medium  text-gray-900">
            Health and well-being issues:
          </td>
          <td className=" px-4 py-2 text-gray-700 outline-none flex gap-4 items-center">
            <label className={`flex gap-1 items-center text-sm  `}>
              <input
                type="checkbox"
                checked={isHealthIssues}
                onChange={() => setIsHealthIssues(true)}
                className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
              />
              Yes
            </label>
            <label className={`flex gap-1 items-center text-sm  `}>
              <input
                type="checkbox"
                checked={!isHealthIssues}
                onChange={() => setIsHealthIssues(false)}
                className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
              />
              No
            </label>
            <span>If Yes, Provide Details:</span>
          </td>
          {isHealthIssues && <td className="px-4 py-2 text-gray-700 outline-none flex gap-4 items-center">
            <textarea name="" id="" className="border w-full p-2"
              ref={details_IfHealthIssuesExistt}
              required={isHealthIssues}
            ></textarea>
          </td>}
        </tr>
        <tr className="flex flex-col w-full ">
          <td className=" px-4 py-2 font-medium  text-gray-900">
            Support plan progress and activities (provide details of goal
            progress and activities)
          </td>

          <td className="px-4 py-2 text-gray-700 outline-none flex gap-4 items-center">
            <textarea
              ref={goal_Progress}
              name="" id="" className="border w-full p-2"></textarea>
          </td>
        </tr>
        <tr className="flex flex-col w-full ">
          <td className=" px-4 py-2 font-medium  text-gray-900">
            Provide any details of contact with family and friends
          </td>

          <td className="px-4 py-2 text-gray-700 outline-none flex gap-4 items-center">
            <textarea name="" id="" className="border w-full p-2"
              ref={contactFamily}
            ></textarea>
          </td>
        </tr>
        <tr className="flex flex-col w-full ">
          <td className=" px-4 py-2 font-medium  text-gray-900">Incidents</td>
          <td className=" px-4 py-2 text-gray-700 outline-none flex gap-4 items-center">
            <label className={`flex gap-1 items-center text-sm  `}>
              <input
                type="checkbox"
                checked={isIncident}
                onChange={() => setIsIncident(true)}
                className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
              />
              Yes
            </label>
            <label className={`flex gap-1 items-center text-sm  `}>
              <input
                type="checkbox"
                checked={!isIncident}
                onChange={() => setIsIncident(false)}
                className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
              />
              No
            </label>
            <span>If Yes, Provide Details:</span>
          </td>
          {isIncident && <td className="px-4 py-2 text-gray-700 outline-none flex gap-4 items-center">
            <textarea name="" id="" className="border w-full p-2"
              ref={details_IfIsIncident}
              required={isIncident}
            ></textarea>
          </td>}
        </tr>
        <tr className="flex flex-col w-full ">
          <td className=" px-4 py-2 font-medium  text-gray-900">
            Behaviours of concern?
          </td>
          <td className=" px-4 py-2 text-gray-700 outline-none flex gap-4 items-center">
            <label className={`flex gap-1 items-center text-sm  `}>
              <input
                type="checkbox"
                checked={isBehaviourConcerned}
                onChange={() => setIsBehaviourConcerned(true)}
                className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
              />
              Yes
            </label>
            <label className={`flex gap-1 items-center text-sm  `}>
              <input
                type="checkbox"
                checked={!isBehaviourConcerned}
                onChange={() => setIsBehaviourConcerned(false)}
                className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded"
              />
              No
            </label>
            <span>If Yes, Provide Details:</span>
          </td>
          {isBehaviourConcerned && <td className="px-4 py-2 text-gray-700 outline-none flex gap-4 items-center">
            <textarea
              ref={details_ifIsBehaviourConcerned}
              required={isBehaviourConcerned}
              name="" id="" className="border w-full p-2"></textarea>
          </td>}
        </tr>

        <tr className="flex flex-col w-full py-4">
          <td className=" px-4 py-1 font-medium flex gap-4 items-center text-gray-900">
            Staff Signature
          </td>

          <td className="px-4 py-1 text-gray-700 outline-none w-full">
            {shiftDetail?.staff.signatureUrl && (
              <img
                src={shiftDetail?.staff.signatureUrl}
                alt="Signature"
                className="img-thumbnail  block border rounded bg-light  border-gray-300 bg-gray-100 p-1"
                style={{
                  display: "block",
                  width: "120px",
                }}
              />
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default FormPartB;
