import React from 'react'
import { Tooltip, Whisper } from 'rsuite';
interface Props {
    label: string | undefined;
    name: string | null | undefined;
}
const CardNameTag = ({ label, name }: Props) => {
    return (
        <div className='text-sm flex flex-col'>
            <span className='text-brand font-bold '>{label}:</span>
            <Whisper placement="auto" controlId="control-id-hover" trigger="hover" speaker={
                <Tooltip>
                    {name || "...."}
                </Tooltip>
            }>


                {
                    label === "Email" ?
                        <a href={`mailto:${name}`} className='truncate ... '>{name || "...."}</a > :
                        label === "Phone" ?
                            <a href={`tel:${name}`} className='truncate ... '>{name || "...."}</a > :
                            <span className='truncate ... '>{name || "...."}</span>
                }
            </Whisper>
        </div>

    )
}

export default CardNameTag