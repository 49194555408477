// import { LoadScript } from '@react-google-maps/api';
// import React, { useState } from 'react';
// import { Coordinates } from '../../types/DataType';
// import AutocompleteLocation from '../Autocomplete';

// interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
//     address: string;
//     setAddress: React.Dispatch<React.SetStateAction<string>>;
//     setCoordinates: React.Dispatch<React.SetStateAction<Coordinates | null>>;
// }

// const AddressComponent: React.FC<Props> = ({ address, setAddress, setCoordinates }) => {
//     const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;
//     const libraries: "places"[] = ["places"];
//     const [isApiLoaded, setIsApiLoaded] = useState(false);

//     const handleLoad = () => {
//         setIsApiLoaded(true);
//     };

//     return (
//         <div>
//             {!isApiLoaded && <div>
//                 <LoadScript
//                     googleMapsApiKey={googleMapsApiKey}
//                     libraries={libraries}
//                     onLoad={handleLoad}
//                 >
//                     <AutocompleteLocation
//                         address={address}
//                         setAddress={setAddress}
//                         setCoordinates={setCoordinates}
//                     />
//                 </LoadScript>
//             </div>}

//             {isApiLoaded && (
//                 <div>
//                     <AutocompleteLocation
//                         address={address}
//                         setAddress={setAddress}
//                         setCoordinates={setCoordinates}
//                     />
//                 </div>
//             )}
//         </div>
//     );
// };

// export default AddressComponent;

import React from 'react';
import { useLoadScript } from '@react-google-maps/api';
import AutocompleteLocation from '../Autocomplete';
import Spinner from '../Spinner'; // Assume you have a Spinner component

interface Coordinates {
    lat: number;
    lng: number;
}

interface Props {
    address: string;
    setAddress: React.Dispatch<React.SetStateAction<string>>;
    setCoordinates?: React.Dispatch<React.SetStateAction<Coordinates | null>>;
}

const AddressComponent: React.FC<Props> = ({ address, setAddress, setCoordinates }) => {
    const libraries: "places"[] = ["places"];
    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: googleMapsApiKey,
        libraries: libraries,
    });

    if (loadError) {
        return <div>Error loading Google Maps API</div>;
    }

    if (!isLoaded) {
        return <Spinner />; // Display a loading spinner while the API is loading
    }

    return (
        <div>
            <AutocompleteLocation
                address={address}
                setAddress={setAddress}
                setCoordinates={setCoordinates}
            />
        </div>
    );
};

export default AddressComponent;

