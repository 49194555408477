import React, { FormEvent } from 'react'
import { Modal } from 'rsuite';
import CustomInput from '../../../../Components/Input/CustomInput';
import CustomSelect from '../../../../Components/Input/CustomSelect';
import Spinner from '../../../../Components/Spinner';
import { AdminDataRow } from '../../../../types/AuthData';
interface Props {
    personalInfoModal: boolean;
    setPersonalInfoModal: React.Dispatch<React.SetStateAction<boolean>>
    editedProfile: AdminDataRow
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
    submitData: (e: FormEvent) => Promise<void>
    loading1: boolean;
}
const AdminEditComp = ({ personalInfoModal, setPersonalInfoModal, editedProfile, handleInputChange, submitData, loading1 }: Props) => {
    return (
        <>
            <Modal
                open={personalInfoModal}
                onClose={() => setPersonalInfoModal(false)}
                backdrop="static"
                autoFocus
                size={"lg"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Edit Personal Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form action="" onSubmit={submitData}>
                            <div className="p-2 grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8 ">
                                <CustomInput
                                    label="Last Name"
                                    type="text"
                                    value={editedProfile.surName}
                                    readOnly
                                />
                                <CustomInput
                                    label="First Name"
                                    type="text"
                                    value={editedProfile.firstName}
                                    readOnly
                                />
                                <CustomInput
                                    label="Middle Name"
                                    type="text"
                                    name='middleName'
                                    value={editedProfile.middleName || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="Phone Number"
                                    type="text"
                                    name='phoneNumber'
                                    value={editedProfile.phoneNumber || ""}
                                    required
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="Date Of Birth"
                                    type="date"
                                    name='dateOfBirth'
                                    value={editedProfile.dateOfBirth || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="Email"
                                    type="email"
                                    name='email'
                                    value={editedProfile.email || ""}
                                    readOnly
                                    onChange={handleInputChange}
                                />
                                <CustomSelect
                                    label="Gender"
                                    name="gender"
                                    options={[
                                        { value: '', label: 'Select Gender' },
                                        { value: 'Male', label: 'Male' },
                                        { value: 'Female', label: 'Female' },
                                        { value: 'Transgender', label: 'Transgender' },
                                        { value: 'Non-binary/non-conforming', label: 'Non-binary/non-conforming' },
                                        { value: 'Prefer not to respond', label: 'Prefer not to respond' }
                                    ]}
                                    value={editedProfile.gender || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="Country"
                                    type="text"
                                    name='country'
                                    value={editedProfile.country || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="State"
                                    type="text"
                                    name='state'
                                    value={editedProfile.state || ""}
                                    onChange={handleInputChange}
                                />
                                <div className='lg:col-span-3'>
                                    <CustomInput
                                        label="Address"
                                        type="text"
                                        name='address'
                                        value={editedProfile.address || ""}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>

                            <div className='flex items-center justify-center'>
                                <button type="submit"
                                    disabled={loading1 ? true : false}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">{
                                        loading1 ? <Spinner /> : "Update"
                                    }</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AdminEditComp