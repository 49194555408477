import React, { FormEvent } from 'react';
import CustomSelect from "../../../../../Components/Input/CustomSelect";
import Spinner from '../../../../../Components/Spinner';
import ClientTabForm from "../../components/ClientTabForm";
import { DisabilityDataType } from '../DisabilitySupport';


interface Props {
    editDisabilitySupport: DisabilityDataType
    handleInputDataChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    handleSubmitEdit: (e: FormEvent) => Promise<void>;
    loading: boolean;
}

const EditDisabilitySupportCom: React.FC<Props> = ({ editDisabilitySupport, loading, handleSubmitEdit, handleInputDataChange }) => {

    return (
        <form className='mt-10'
            onSubmit={handleSubmitEdit}
        >
            <h2 className='font-bold text-xl text-black w-auto'>Disability Support Needs</h2>
            <div className='mt-5 grid grid-cols-1 gap-16 lg:grid-cols-2'>
                <ClientTabForm title='Mobility Related Issues'>
                    <CustomSelect
                        label="Need Mobility Assistance?"
                        options={[
                            { label: "Need Mobility Assistance", value: "Need Mobility Assistance" },
                            { label: "Mobility Independency", value: "Mobility Independency" }
                        ]}
                        name="mobilityIndependent"
                        value={editDisabilitySupport.mobilityIndependent || ''}
                        onChange={handleInputDataChange}
                    />
                    <label htmlFor="mobilityDescription" className="font-bold">Mobility Description</label>
                    <textarea
                        id="mobilityDescription"
                        rows={3}
                        autoComplete="off"
                        name="mobilityDescription"
                        value={editDisabilitySupport.mobilityDescription || ''}
                        onChange={handleInputDataChange}
                        className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"

                    />
                </ClientTabForm>

                <ClientTabForm title='Hearing Related Issues'>
                    <CustomSelect
                        label="Hearing Issues"
                        options={[
                            { label: "No Issues", value: "No Issues" },
                            { label: "Some Issues", value: "Some Issues" },
                            { label: "Hearing Impaired", value: "Hearing Impaired" }
                        ]}
                        name="hearingIssues"
                        value={editDisabilitySupport.hearingIssues || ''}
                        onChange={handleInputDataChange}
                    />
                    <label htmlFor="hearingDescription" className="font-bold">Hearing Description</label>
                    <textarea
                        id="hearingDescription"
                        rows={3}
                        autoComplete="off"
                        className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                        name="hearingDescription"
                        value={editDisabilitySupport.hearingDescription || ''}
                        onChange={handleInputDataChange}
                    />
                </ClientTabForm>

                <ClientTabForm title='Vision Related Issues'>
                    <CustomSelect
                        label="Vision Related Issues"
                        options={[
                            { label: "No Issues", value: "No Issues" },
                            { label: "Some Issues", value: "Some Issues" },
                            { label: "Vision Impaired", value: "Vision Impaired" }
                        ]}
                        name="visionIssues"
                        value={editDisabilitySupport.visionIssues || ''}
                        onChange={handleInputDataChange}
                        required
                    />
                    <label htmlFor="visionDescription" className="font-bold">Vision Description</label>
                    <textarea
                        id="visionDescription"
                        rows={3}
                        autoComplete="off"
                        className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                        name="visionDescription"
                        value={editDisabilitySupport.visionDescription || ''}
                        onChange={handleInputDataChange}
                    />
                </ClientTabForm>

                <ClientTabForm title='Memory Related Issues'>
                    <CustomSelect
                        label="Memory Related Issues"
                        options={[
                            { label: "No Issues", value: "No Issues" },
                            { label: "Some Issues", value: "Some Issues" },
                            { label: "Memory Impaired", value: "Memory Impaired" }
                        ]}
                        name="memoryIssues"
                        value={editDisabilitySupport.memoryIssues || ''}
                        onChange={handleInputDataChange}
                        required
                    />
                    <label htmlFor="memoryDescription" className="font-bold">Memory Description</label>
                    <textarea
                        id="memoryDescription"
                        rows={3}
                        autoComplete="off"
                        className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                        name="memoryDescription"
                        value={editDisabilitySupport.memoryDescription || ''}
                        onChange={handleInputDataChange}
                    />
                </ClientTabForm>

                <ClientTabForm title='Communication'>
                    <CustomSelect
                        label="Need Communication Assistance?"
                        options={[
                            { label: "Need Communication Assistance", value: "Need Communication Assistance" }
                        ]}
                        name="communicationAssistance"
                        // value={editDisabilitySupport.communicationAssistance=== "Need Communication Assistance" || ''}
                        onChange={handleInputDataChange}
                    />
                    <CustomSelect
                        label="Communication Preferences"
                        options={[
                            { label: "Verbally", value: "Verbally" },
                            { label: "Ausian", value: "Ausian" },
                            { label: "Makaton", value: "Makaton" },
                            { label: "Combination of Ausian / Makaton", value: "Combination of Ausian / Makaton" }
                        ]}
                        name="communicationMeans"
                        value={editDisabilitySupport.communicationMeans || ''}
                        onChange={handleInputDataChange}
                    />
                    <label htmlFor="communicationDescription" className="font-bold">Communication Description</label>
                    <textarea
                        id="communicationDescription"
                        rows={3}
                        autoComplete="off"
                        className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                        name="communicationDescription"
                        value={editDisabilitySupport.communicationDescription || ''}
                        onChange={handleInputDataChange}
                    />
                </ClientTabForm>
            </div>
            <div className="mx-auto text-center mt-3">
                <button
                    type="submit"
                    disabled={loading}
                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                               font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                               dark:focus:ring-primary"
                >
                    {loading ? <Spinner /> : "Update"}
                </button>
            </div>
        </form>
    );
};

export default EditDisabilitySupportCom;