import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

export interface ExpenditureType {
    amountProvidedByParticipant: number;
    amountReturnedToParticipant: number;
    amountSpent: number;
    companyId: number;
    dateCreated: string;
    dateModified: string;
    participantExpenditureId: number;
    profile: {
        profileId: number;
        firstName: string;
        surName: string;
        middleName: string;
        fullName: string;
    };
    staff: {
        staffId: number;
        firstName: string;
        surName: string;
        middleName: string;
        fullName: string;
    };
    profileId: number;
    purchaseDate: string;
    purchasedItem: string;
    reasonIfNoReciept: string;
    receiptFile: string;
    receiptUrl: string;
    staffId: number;
    userCreated: string;
    userModified: string;
}

export interface ExpenditureState {
    loading: boolean;
    data: Array<ExpenditureType>;
    error: string | undefined;
}
const initialState: ExpenditureState = {
    loading: false,
    data: [],
    error: undefined,
};
export const fetchExpenditure = createAsyncThunk(
    "ExpenditureType/fetchExpenditure",
    async (uid: number) => {
        try {
            const response = await api.fetchExpenditure(uid);
            return response;
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                console.log(error);
            } else {
                console.log(error);
            }
        }
    }
);

const expenditureSlice = createSlice({
    name: "expenditure",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchExpenditure.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            fetchExpenditure.fulfilled,
            (state, action: PayloadAction<Array<ExpenditureType>>) => {
                state.loading = false;
                state.data = action.payload;
            }
        );
        builder.addCase(fetchExpenditure.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
    reducers: {},
});
export const userSelector = (state: RootState) => state.expenditure;
export default expenditureSlice.reducer;

// Define selectors to access the profile slice state
export const expenditureData = (state: RootState) =>
    state.expenditure.data;
export const expenditureLoading = (state: RootState) =>
    state.expenditure.loading;
export const expenditureError = (state: RootState) =>
    state.expenditure.error;
