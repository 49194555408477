import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { CompanyDetail } from "../../types/AuthData";
import { fetchCompany } from "../../services/fetchServices";
import Spinner from "../../Components/Spinner";

const UserDashboard = () => {
  const user = useSelector((state: RootState) => state?.auth?.user);
  const [companyOne, setCompanyOne] = useState<
    CompanyDetail | null | undefined
  >(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleCompanyFetch = async () => {
    setLoading(true);
    if (user?.companyId) {
      const data = await fetchCompany(user?.companyId);
      setCompanyOne(data);
    }
    setLoading(false);
  };
  useEffect(() => {
    handleCompanyFetch();
  }, []);
  return (
    <>
      <section className="dark:bg-gray-800 dark:text-gray-100">
        <div className="container flex flex-col justify-center p-6 mx-auto sm:py-12 lg:py-24 lg:flex-row lg:justify-between">
          <div className="flex flex-col justify-center p-6 text-center rounded-sm lg:max-w-md xl:max-w-lg lg:text-left">
            <h1 className="text-2xl font-bold  sm:text-4xl">
              Welcome to
              <span className="dark:text-violet-400">
                {loading ? <Spinner /> : <> {companyOne?.companyName}</>}
              </span>
            </h1>

            <div className="flex flex-col space-y-4 mt-6 sm:items-center sm:justify-center sm:flex-row sm:space-y-0 sm:space-x-4 lg:justify-start">
              <a
                rel="noopener noreferrer"
                href="#"
                className="px-4 py-2 border border-primary hover:text-primary hover:no-underline text-sm font-semibold rounded dark:bg-violet-400 dark:text-gray-900"
              >
                CONTACT ADMINISTRATOR
              </a>
            </div>
          </div>
          <div className="flex items-center overflow-hidden justify-center p-6 mt-8 lg:mt-0 h-72 sm:h-80 lg:h-96 ">
            <img
              src="/media/office.jpeg"
              alt="img"
              className="object-contain w-full h-full"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default UserDashboard;
