import React from 'react';
import { Routes, Route } from 'react-router-dom';
import FallBack from '../FallBack';
import SEO from '../../constants/SEO';

// Import other admin routes as needed
const PaymentLayout = () => {
    const PaymentPage = React.lazy(() => import("./Payment"));
    const PaymentComplete = React.lazy(() => import("./PaymentComplete"));


    return (
        <div>
            <SEO
                title='Payment - Promax Care'
                description='Pay'
                name='Promax-Care'
            />
            {/* Auth layout components */}
            <React.Suspense fallback={<FallBack />}>
                <Routes>
                    <Route path='/' element={<PaymentPage />} />
                    <Route path='/completed' element={<PaymentComplete />} />

                </Routes>
            </React.Suspense>
        </div>
    );
};

export default PaymentLayout;
