import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

interface SupportSchedule {
  companyID: number;
  cost: number;
  dateCreated: string;
  dateModified: string;
  frequency: string;
  itemNumber: string;
  profile: {
    address: string;
    agreementEndDate: string;
    agreementStartDate: string;
    auditApproved: boolean;
    careManager: string;
    clientId: string | null;
    companyId: number | null;
    contactId: string;
    country: string | null;
    culturalBackground: string | null;
    dateCreated: string;
    dateModified: string;
    dateOfBirth: string;
    email: string;
    financialArrangement: string | null;
    firstName: string;
    fullName: string;
    gender: string;
    homePhone: string | null;
    imageFile: string | null;
    imageUrl: string | null;
    indigenousSatatus: string | null;
    isActive: boolean;
    isCompleted: boolean;
    kinAddress: string | null;
    kinCity: string | null;
    kinCountry: string | null;
    kinEmail: string | null;
    kinPhoneNumber: string | null;
    kinPostcode: string | null;
    kinState: string | null;
    middleName: string | null;
    ndisNo: string;
    ndisPlan: string | null;
    ndisPlanNote: string | null;
    nextOfKin: string | null;
    phoneNumber: string;
    preferredLanguage: string | null;
    privacyPreferences: string | null;
    profession: string | null;
    profileId: number;
    referral: string;
    relationship: string | null;
    requireInterpreter: string | null;
    state: string | null;
    suburb: string | null;
    surName: string;
    userCreated: string | null;
    userModified: string | null;
    workPhone: string | null;
    xerolink: string | null;
  };
  profileId: number;
  quantity: number;
  scheduleId: number;
  supportType: string;
  userCreated: string | null;
  userModified: string | null;
}

export interface SupportScheduleState {
  loading: boolean;
  data: Array<SupportSchedule>;
  error: string | undefined;
}
const initialState: SupportScheduleState = {
  loading: false,
  data: [],
  error: undefined,
};
export const fetchSupportSchedule = createAsyncThunk(
  "SupportSchedule/fetchSupportSchedule",
  async (companyId: number) => {
    try {
      const response = await api.fetchSupportSchedule(companyId);
      return response;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

const supportScheduleSlice = createSlice({
  name: "supportSchedule",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchSupportSchedule.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchSupportSchedule.fulfilled,
      (state, action: PayloadAction<Array<SupportSchedule>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(fetchSupportSchedule.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {},
});
export const userSelector = (state: RootState) => state.supportSchedule;
export default supportScheduleSlice.reducer;

export const supportScheduleData = (state: RootState) =>
  state.supportSchedule.data;
export const supportScheduleLoading = (state: RootState) =>
  state.supportSchedule.loading;
export const supportScheduleError = (state: RootState) =>
  state.supportSchedule.error;
