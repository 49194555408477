import React from 'react';
import { SkillDevPlan } from '../../../types/DataType';

interface Props {
    participantSkillDevForm: SkillDevPlan;
    formData: {
        goals: string[];
        supports: string[];
        deadlines: string[];
        completionDates: string[];
        workingOnIt: boolean[];
        canDo: boolean[];
        comments: string[];
    }
    handleTextAreaEditChange: (value: string, index: number, field: "comments" | "workingOnIt" | "canDo" | "completionDates" | "deadlines" | "goals" | "supports") => void;
    handleCheckboxEditChange: (checked: boolean, index: number, field: 'workingOnIt' | 'canDo') => void;
    handleDateEditChange: (value: string, index: number) => void;
}

const EditSkillForm = ({ formData, handleCheckboxEditChange, handleDateEditChange,
    handleTextAreaEditChange }: Props) => {


    return (
        <form className="overflow-x-auto max-w-full rounded-lg border border-gray-300">
            <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
                <tbody className="divide-y divide-gray-200">
                    {formData.goals.map((_, index) => (
                        <tr key={index} className="divide-x-2">
                            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Step {index + 1}</td>
                            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                                <p>My learning goal:</p>
                                <textarea
                                    className="w-full h-28 border border-gray-300 outline-none"
                                    value={formData.goals[index]}
                                    onChange={(e) => handleTextAreaEditChange(e.target.value, index, 'goals')}
                                />
                                <p>Support or equipment I need:</p>
                                <textarea
                                    className="w-full h-28 border border-gray-300 outline-none"
                                    value={formData.supports[index]}
                                    onChange={(e) => handleTextAreaEditChange(e.target.value, index, 'supports')}
                                />
                                <p>When I want to achieve this step:</p>
                                <textarea
                                    className="w-full h-28 border border-gray-300 outline-none"
                                    value={formData.deadlines[index]}
                                    onChange={(e) => handleTextAreaEditChange(e.target.value, index, 'deadlines')}
                                />
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                                <input
                                    type="checkbox"
                                    checked={formData.workingOnIt[index]}
                                    onChange={(e) => handleCheckboxEditChange(e.target.checked, index, 'workingOnIt')}
                                /> I am still working on it <br />
                                <input
                                    type="checkbox"
                                    checked={formData.canDo[index]}
                                    onChange={(e) => handleCheckboxEditChange(e.target.checked, index, 'canDo')}
                                /> I can do this!
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                                <textarea
                                    className="w-full h-28 border border-gray-300 outline-none"
                                    value={formData.comments[index]}
                                    onChange={(e) => handleTextAreaEditChange(e.target.value, index, 'comments')}
                                />
                                <p>Date:</p>
                                <input
                                    type="date"
                                    className="p-1 border border-gray-300 outline-none"
                                    value={formData.completionDates[index]}
                                    onChange={(e) => handleDateEditChange(e.target.value, index)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </form>
    );
};

export default EditSkillForm;


