// import React from "react";

// const SkillForm = () => {
//   return (
//     <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border  border-gray-300 ">
//       <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
//         <tbody className="divide-y divide-gray-200">
//           <tr className="divide-x-2">
//             <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
//               Step 1
//             </td>
//             <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
//               <p>My learning goal:</p>
//               <div className="w-full mt-2">
//                 <textarea className="w-[100%] h-28 border border-gray-300 outline-none"></textarea>
//               </div>
//               <p>Support or equipment I need: </p>
//               <div className="w-full mt-2">
//                 <textarea className="w-[100%] h-28 border border-gray-300 outline-none"></textarea>
//               </div>
//               <p>When I want to achieve this step: </p>
//               <div className="w-full mt-2">
//                 <textarea className="w-[100%] h-28 border border-gray-300 outline-none"></textarea>
//               </div>
//             </td>
//             <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
//               <p>
//                 <input type="checkbox" /> I am still working on it
//               </p>
//               <p className="mt-4">
//                 <input type="checkbox" /> I can do this! 
//               </p>
//             </td>
//             <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
//               <textarea className="w-[100%] h-28 border border-gray-300 outline-none"></textarea>
//               <p>
//                 Date:{" "}
//                 <input
//                   type="date"
//                   className="p-1 border border-gray-300 outline-none"
//                 />
//               </p>
//             </td>
//           </tr>
//           <tr className="divide-x-2">
//             <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
//               Step 2
//             </td>
//             <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
//               <p>My learning goal:</p>
//               <div className="w-full mt-2">
//                 <textarea className="w-[100%] h-28 border border-gray-300 outline-none"></textarea>
//               </div>
//               <p>Support or equipment I need: </p>
//               <div className="w-full mt-2">
//                 <textarea className="w-[100%] h-28 border border-gray-300 outline-none"></textarea>
//               </div>
//               <p>When I want to achieve this step: </p>
//               <div className="w-full mt-2">
//                 <textarea className="w-[100%] h-28 border border-gray-300 outline-none"></textarea>
//               </div>
//             </td>
//             <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
//               <p>
//                 <input type="checkbox" /> I am still working on it
//               </p>
//               <p className="mt-4">
//                 <input type="checkbox" /> I can do this! 
//               </p>
//             </td>
//             <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
//               <textarea className="w-[100%] h-28 border border-gray-300 outline-none"></textarea>
//               <p>
//                 Date:{" "}
//                 <input
//                   type="date"
//                   className="p-1 border border-gray-300 outline-none"
//                 />
//               </p>
//             </td>
//           </tr>
//           <tr className="divide-x-2">
//             <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
//               Step 3
//             </td>
//             <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
//               <p>My learning goal:</p>
//               <div className="w-full mt-2">
//                 <textarea className="w-[100%] h-28 border border-gray-300 outline-none"></textarea>
//               </div>
//               <p>Support or equipment I need: </p>
//               <div className="w-full mt-2">
//                 <textarea className="w-[100%] h-28 border border-gray-300 outline-none"></textarea>
//               </div>
//               <p>When I want to achieve this step: </p>
//               <div className="w-full mt-2">
//                 <textarea className="w-[100%] h-28 border border-gray-300 outline-none"></textarea>
//               </div>
//             </td>
//             <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
//               <p>
//                 <input type="checkbox" /> I am still working on it
//               </p>
//               <p className="mt-4">
//                 <input type="checkbox" /> I can do this! 
//               </p>
//             </td>
//             <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
//               <textarea className="w-[100%] h-28 border border-gray-300 outline-none"></textarea>
//               <p>
//                 Date:{" "}
//                 <input
//                   type="date"
//                   className="p-1 border border-gray-300 outline-none"
//                 />
//               </p>
//             </td>
//           </tr>
//           <tr className="divide-x-2">
//             <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
//               Step 4
//             </td>
//             <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
//               <p>My learning goal:</p>
//               <div className="w-full mt-2">
//                 <textarea className="w-[100%] h-28 border border-gray-300 outline-none"></textarea>
//               </div>
//               <p>Support or equipment I need: </p>
//               <div className="w-full mt-2">
//                 <textarea className="w-[100%] h-28 border border-gray-300 outline-none"></textarea>
//               </div>
//               <p>When I want to achieve this step: </p>
//               <div className="w-full mt-2">
//                 <textarea className="w-[100%] h-28 border border-gray-300 outline-none"></textarea>
//               </div>
//             </td>
//             <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
//               <p>
//                 <input type="checkbox" /> I am still working on it
//               </p>
//               <p className="mt-4">
//                 <input type="checkbox" /> I can do this! 
//               </p>
//             </td>
//             <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
//               <textarea className="w-[100%] h-28 border border-gray-300 outline-none"></textarea>
//               <p>
//                 Date:{" "}
//                 <input
//                   type="date"
//                   className="p-1 border border-gray-300 outline-none"
//                 />
//               </p>
//             </td>
//           </tr>
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default SkillForm;

import React from 'react';

interface Props {
  goals: string[]
  supports: string[]
  deadlines: string[]
  completionDates: string[]
  workingOnIt: boolean[]
  canDo: boolean[]
  comment: string[]
  handleTextAreaChange: (value: string, index: number, type: 'goal' | 'support' | 'deadline' | 'comments') => void;
  handleDateChange: (value: string, index: number) => void;
  handleCheckboxChange: (checked: boolean, index: number, type: 'workingOnIt' | 'canDo') => void;
}
const SkillForm = ({ canDo, completionDates, deadlines, goals, handleCheckboxChange, handleDateChange,
  handleTextAreaChange, supports, workingOnIt, comment
}: Props) => {

  return (
    <form className="overflow-x-auto max-w-full rounded-lg border border-gray-300">
      <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
        <tbody className="divide-y divide-gray-200">
          {new Array(4).fill(null).map((_, index) => (
            <tr key={index} className="divide-x-2">
              <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Step {index + 1}</td>
              <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                <p>My learning goal:</p>
                <textarea
                  className="w-full h-28 border border-gray-300 outline-none"
                  value={goals[index]}
                  onChange={(e) => handleTextAreaChange(e.target.value, index, 'goal')}
                />
                <p>Support or equipment I need:</p>
                <textarea
                  className="w-full h-28 border border-gray-300 outline-none"
                  value={supports[index]}
                  onChange={(e) => handleTextAreaChange(e.target.value, index, 'support')}
                />
                <p>When I want to achieve this step:</p>
                <textarea
                  className="w-full h-28 border border-gray-300 outline-none"
                  value={deadlines[index]}
                  onChange={(e) => handleTextAreaChange(e.target.value, index, 'deadline')}
                />
              </td>
              <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                <input
                  type="checkbox"
                  checked={workingOnIt[index]}
                  onChange={(e) => handleCheckboxChange(e.target.checked, index, 'workingOnIt')}
                /> I am still working on it <br />
                <input
                  type="checkbox"
                  checked={canDo[index]}
                  onChange={(e) => handleCheckboxChange(e.target.checked, index, 'canDo')}
                /> I can do this!
              </td>
              <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                <textarea
                  className="w-full h-28 border border-gray-300 outline-none"
                  value={comment[index]}
                  onChange={(e) => handleTextAreaChange(e.target.value, index, 'comments')}
                />
                <p>Date:</p>
                <input
                  type="date"
                  className="p-1 border border-gray-300 outline-none"
                  value={completionDates[index]}
                  onChange={(e) => handleDateChange(e.target.value, index)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </form>
  );
};

export default SkillForm;




