import React, { ChangeEvent } from "react";
import { FaPlus } from "react-icons/fa";
import { RowData } from "..";


interface Props {
    alertValues: RowData[];
    handleAddRow: () => void;
    handleInputChange: (index: number, field: keyof RowData, value: string) => void;
}

const Main = ({ alertValues, handleAddRow, handleInputChange }: Props) => {


    return (
        <div>
            <div className='flex justify-between items-center'>
                <h1 className="text-2xl font-bold mb-4">Support Routine</h1>
            </div>
            <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border mt-9 border-gray-300">
                <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
                    <thead className="ltr:text-left rtl:text-right bg-[#b9b9b95e]">
                        <tr className="divide-x-2">
                            <th className="px-4 py-2 font-semibold text-gray-900">Day</th>
                            <th className="px-4 py-2 font-semibold text-gray-900">Time/s</th>
                            <th className="px-4 py-2 font-semibold text-gray-900">NDIS goal: What support is needed? Where is it to be provided? </th>
                            <th className="px-4 py-2 font-semibold text-gray-900">How is the support provided? Is there equipment needed?</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {alertValues.map((row, index) => (
                            <tr key={index} className="divide-x-2">
                                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                                    <input
                                        className="w-full h-[20px] outline-none"
                                        type="text"
                                        value={row.days}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(index, 'days', e.target.value)}
                                    />
                                </td>
                                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                                    <input
                                        className="w-full h-[20px] outline-none"
                                        type="time"
                                        value={row.time}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(index, 'time', e.target.value)}
                                    />
                                </td>
                                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                                    <textarea
                                        className="w-full p-2 border border-gray-300 rounded mt-2"
                                        value={row.ndisGoal}
                                        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleInputChange(index, 'ndisGoal', e.target.value)}
                                    />
                                </td>
                                <td className="whitespace-nowrap px-4 py-2 text-gray-700">

                                    <textarea
                                        className="w-full p-2 border border-gray-300 rounded mt-2"
                                        value={row.supportProvided}
                                        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleInputChange(index, 'supportProvided', e.target.value)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <button
                type="button"
                onClick={handleAddRow}
                className="mt-4 w-10 h-10 bg-gray-200 text-white rounded-full flex justify-center items-center"
            >
                <FaPlus className="text-lg text-black" />
            </button>
        </div>
    );
};

export default Main;
