import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

interface Splitted {
  attendanceSplitId: number;
  staffId: number;
  clockIn: string;
  clockInCheck: boolean;
  clockOutCheck: boolean;
  clockOut: string;
  shiftId: number;
  shiftRoster: string;
  attendanceId: number;
  attendance: number;
  shift: string;
  duration: number;
  totalAgreedDuration: number;
  normalDuration: number;
  eveningDuration: number;
  exceptionalDuration: number;
  satDuration: number;
  sunDuration: number;
  phDuration: number;
  totalKm: number;
  nightShift: number;
  companyID: number;
  dateCreated: string;
  userCreated: string;
  dateModified: string;
  userModified: string;
}

export interface TimeState {
  loading: boolean;
  data: Array<Splitted>;
  error: string | undefined;
}
const initialState: TimeState = {
  loading: false,
  data: [],
  error: undefined,
};
export const fetchsplittedAttendance = createAsyncThunk(
  "Splitted/fetchsplittedAttendance",
  async (value: number | string) => {
    try {
      const response = await api.fetchsplittedAttendance(value);
      return response.splittedAttendance;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

const splittedAttendanceSlice = createSlice({
  name: "splittedAttendance",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchsplittedAttendance.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchsplittedAttendance.fulfilled,
      (state, action: PayloadAction<Array<Splitted>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(fetchsplittedAttendance.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {},
});
export const userSelector = (state: RootState) => state.splittedAttendance;
export default splittedAttendanceSlice.reducer;

export const splittedAttendanceData = (state: RootState) =>
  state.splittedAttendance.data;
export const splittedAttendanceLoading = (state: RootState) =>
  state.splittedAttendance.loading;
export const splittedAttendanceError = (state: RootState) =>
  state.splittedAttendance.error;
