// import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { fetchCompany } from "../../services/fetchServices";
// import { RootState } from "../../store/store";
// import { CompanyDetail } from "../../types/AuthData";
// import Spinner from "../Spinner";

// const PackageExpiredDate = () => {
//   const user = useSelector((state: RootState) => state?.auth?.user);
//   const [company, setCompany] = useState<CompanyDetail | null>(null);
//   const [loading, setLoading] = useState<boolean>(true);

//   useEffect(() => {
//     const handleCompanyFetch = async () => {
//       setLoading(true);
//       if (user?.companyId) {
//         const data = await fetchCompany(user?.companyId);
//         setCompany(data);
//       }
//       setLoading(false);
//     };
//     handleCompanyFetch();
//   }, [user?.companyId]);

//   if (loading) {
//     return <Spinner />;
//   }

//   const companyOneDate =
//     company?.expirationDate && new Date(company?.expirationDate);

//   // Get the current date in Australia (assuming Australian Eastern Standard Time)
//   const australiaTimeZoneOffset = 10; // UTC+10 for Australian Eastern Standard Time

//   // Get the current date in Australia
//   const currentDateInAustralia = new Date();
//   currentDateInAustralia.setHours(
//     currentDateInAustralia.getHours() + australiaTimeZoneOffset
//   );

//   const currentDateInAustraliaNumeric = currentDateInAustralia.getTime();
//   const companyOneDateNumeric: number = companyOneDate
//     ? companyOneDate.getTime()
//     : 0; // Ensure companyOneDate is not undefined

//   // Calculate the difference in milliseconds
//   const timeDifference = companyOneDateNumeric - currentDateInAustraliaNumeric; // Switch order for correct result
//   const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24)); // Convert milliseconds to days

//   // Format the expiration date as "27 Sep 2025"
//   const formatExpirationDate = (date: Date) => {
//     const options: Intl.DateTimeFormatOptions = {
//       day: "numeric",
//       month: "short",
//       year: "numeric",
//     };
//     return date.toLocaleDateString("en-AU", options);
//   };

//   let displayText;
//   const formattedExpirationDate = companyOneDate
//     ? formatExpirationDate(companyOneDate)
//     : "";

//   if (daysDifference > 0) {
//     // Replacing the original "days left" with the expiration date
//     displayText = `Auto Renew on  ${formattedExpirationDate}`;
//   } else {
//     displayText = `Expired on ${formattedExpirationDate}`;
//   }

//   return (
//     <div>
//       <p>{displayText}</p>
//     </div>
//   );
// };

// export default PackageExpiredDate;

import React from "react";
import Spinner from "../Spinner";
import useFetchExpiredDate from "./components/FetchExpiredDate";

const PackageExpiredDate = () => {
  const { daysDifference, company, loading } = useFetchExpiredDate();

  if (loading) {
    return <Spinner />;
  }

  // Format the expiration date as "27 Sep 2025"
  const formatExpirationDate = (date: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    return date.toLocaleDateString("en-AU", options);
  };

  const expirationDate = company?.expirationDate
    ? new Date(company.expirationDate)
    : null;

  const formattedExpirationDate = expirationDate
    ? formatExpirationDate(expirationDate)
    : "";

  let displayText;
  if (daysDifference !== null && daysDifference > 0) {
    displayText = `Auto Renew on ${formattedExpirationDate}`;
  } else {
    displayText = `Expired on ${formattedExpirationDate}`;
  }

  return (
    <div>
      <p>{displayText}</p>
    </div>
  );
};

export default PackageExpiredDate;
