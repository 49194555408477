import React from 'react'



interface Props {
  values: {
    disability: string;
    secondaryDisability: string;
    meetingDate: string;
    staffingRequest: string;
  }
  handleInputDataChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
  otherValues: {
    isNDISPlanAttached: boolean;
    isConsentSigned: boolean;
    staffingRequest: boolean;
  }
  handleRadioChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const Summary: React.FC<Props> = ({ handleRadioChange, otherValues, handleInputDataChange, values }) => {
  return (
    <div>
      <table className="w-full mb-6">
        <tbody>
          {/* {[...Array(3)].map((_, i) => (
            <tr key={i}>
              <td className="border px-4 py-2"><input type="checkbox" className="mr-2" /> List as relevant</td>
            </tr>
          ))} */}
          <tr>
            <td className="border px-4 py-2 font-bold">Is a copy of the participant’s NDIS Plan attached?</td>
            <td className="border px-4 py-2">
              <div className="flex items-center">
                <label className="mr-4">
                  <input type="radio" name="isNDISPlanAttached" className="mr-2" value="true"
                    checked={otherValues.isNDISPlanAttached === true}
                    onChange={handleRadioChange}
                  /> Yes
                </label>
                <label>
                  <input type="radio" name="isNDISPlanAttached" className="mr-2" value="false"
                    checked={otherValues.isNDISPlanAttached === false}
                    onChange={handleRadioChange}
                  /> No
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2 font-bold">Has the consent form to obtain and share information been signed?</td>
            <td className="border px-4 py-2">
              <div className="flex items-center">
                <label className="mr-4">
                  <input type="radio" name="isConsentSigned" className="mr-2" value="true"
                    checked={otherValues.isConsentSigned === true}
                    onChange={handleRadioChange}
                  /> Yes
                </label>
                <label>
                  <input type="radio" name="isConsentSigned" className="mr-2" value="false"
                    checked={otherValues.isConsentSigned === false}
                    onChange={handleRadioChange}
                  /> No
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2 font-bold">Are there staffing requests or other requirements?</td>
            <td className="border px-4 py-2">
              <div className="flex items-center">
                <label className="mr-4">
                  <input type="radio" name="staffingRequest" className="mr-2" value="true"
                    checked={otherValues.staffingRequest === true}
                    onChange={handleRadioChange}
                  /> Yes
                </label>
                <label>
                  <input type="radio" name="staffingRequest" className="mr-2" value="false"
                    checked={otherValues.staffingRequest === false}
                    onChange={handleRadioChange}
                  /> No
                </label>
              </div>
              {otherValues.staffingRequest &&
                <textarea className="w-full p-2 border border-gray-300 rounded mt-2"
                  placeholder="If yes, please list"
                  name="staffingRequest"
                  value={values.staffingRequest}
                  onChange={handleInputDataChange}>
                </textarea>}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Summary