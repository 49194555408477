import React from 'react'
import { SupportPlaData } from '../../../types/DataType';


interface Props {
    supportForm: SupportPlaData
    handleInputFormChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
}

const EditCommunication: React.FC<Props> = (
    { supportForm, handleInputFormChange }
) => {


    return (
        <div>
            <table className="w-full mb-6">
                <tbody>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Other Communication: e.g. Auslan, Compic, AAC device, body language:</td>
                        <td className="border px-4 py-2">

                            <textarea className="w-full p-2 border border-gray-300 rounded"
                                name="otherCommunication"
                                value={supportForm.otherCommunication}
                                onChange={handleInputFormChange}
                            />
                        </td>
                    </tr>

                </tbody>
            </table>
        </div >
    )
}

export default EditCommunication