import React from "react";
import { SupportGoalPlanData } from "../../../types/DataType";

interface Props {
    details: SupportGoalPlanData;
    handleInputDataChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
}

const EditDate: React.FC<Props> = ({ details, handleInputDataChange }) => {
    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    return (
        <div className="  mt-9 border-gray-300 py-2 ">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div>
                    <p className="font-semibold">Implementation Date</p>

                    <div>
                        <input
                            type="date"
                            required
                            id="impleDate"
                            name="implementationDate"
                            value={details?.implementationDate ? formatDate(details.implementationDate) : ''}
                            onChange={handleInputDataChange}
                            className="w-full rounded-md border p-2.5 border-gray-200 shadow-sm sm:text-sm"
                        />
                    </div>
                </div>
                <div>
                    <p className="font-semibold">Expiration Date</p>

                    <div>
                        <input
                            type="date"
                            id="expDate"
                            name="expirationDate"
                            value={details?.expirationDate ? formatDate(details.expirationDate) : ''}
                            onChange={handleInputDataChange}
                            className="w-full rounded-md border p-2.5 border-gray-200 shadow-sm sm:text-sm"
                        />
                    </div>
                </div>
            </div>
            <div></div>
        </div>
    );
};

export default EditDate;