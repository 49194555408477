import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchCompany } from "../../../services/fetchServices";
import { RootState } from "../../../store/store";
import { CompanyDetail } from "../../../types/AuthData";

const useFetchExpiredDate = () => {
  const user = useSelector((state: RootState) => state?.auth?.user);
  const [daysDifference, setDaysDifference] = useState<number | null>(null);
  const [companyPackage, setCompanyPackage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [company, setCompany] = useState<CompanyDetail | null>(null);

  useEffect(() => {
    const handleCompanyFetch = async () => {
      setLoading(true);
      if (user?.companyId) {
        const data = await fetchCompany(user.companyId);

        // Retrieve package and expiration date details
        setCompany(data);
        const packageInfo = data?.packages?.package ?? null;
        const companyExpirationDate = data?.expirationDate
          ? new Date(data.expirationDate)
          : null;

        setCompanyPackage(packageInfo);

        if (companyExpirationDate) {
          // Get the current date in Australia (Sydney timezone)
          const currentDateInAustralia = new Date(
            new Date().toLocaleString("en-US", { timeZone: "Australia/Sydney" })
          );

          // Zero out the time components
          const resetTime = (date: Date) => {
            date.setHours(0, 0, 0, 0);
            return date;
          };

          const normalizedCurrentDate = resetTime(currentDateInAustralia);
          const normalizedCompanyDate = resetTime(
            new Date(companyExpirationDate)
          );

          const timeDifference =
            normalizedCompanyDate.getTime() - normalizedCurrentDate.getTime();
          const calculatedDaysDifference = Math.round(
            timeDifference / (1000 * 3600 * 24)
          );

          setDaysDifference(calculatedDaysDifference);
        }
      }
      setLoading(false);
    };
    handleCompanyFetch();
  }, [user?.companyId]);

  return { daysDifference, companyPackage, loading, company };
};

export default useFetchExpiredDate;
