import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

interface Activity {
  activity: string;
  activityLogId: string;
  company: string;
  companyId: string;
  dateCreated: string;
  dateModified: string;
  ipAddress: string;
  macAddress: string;
  medium: string;
  user: string;
  userCreated: string;
  userModified: string;
}

export interface ActivityState {
  loading: boolean;
  data: Array<Activity>;
  error: string | undefined;
}
const initialState: ActivityState = {
  loading: false,
  data: [],
  error: undefined,
};
export const fetchActivity = createAsyncThunk(
  "Activity/fetchActivity",
  async (companyId: number) => {
    try {
      const response = await api.fetchActivity(companyId);
      return response.activityLogs;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

export const filterActivityLogs = createAsyncThunk(
  "Activity/filterActivity",
  async ({
    companyId,
    fromDate,
    toDate,
    user,
  }: {
    companyId: number;
    fromDate: string;
    toDate: string;
    user: string;
  }) => {
    try {
      const response = await api.filterActivityLogs(
        companyId,
        fromDate,
        toDate,
        user
      );
      return response.activityLogs;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

const activitySlice = createSlice({
  name: "activity",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchActivity.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchActivity.fulfilled,
      (state, action: PayloadAction<Array<Activity>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(fetchActivity.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(filterActivityLogs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      filterActivityLogs.fulfilled,
      (state, action: PayloadAction<Array<Activity>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(filterActivityLogs.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {},
});
export const userSelector = (state: RootState) => state.activity;
export default activitySlice.reducer;

export const activityData = (state: RootState) => state.activity.data;
export const activityLoading = (state: RootState) => state.activity.loading;
export const activityError = (state: RootState) => state.activity.error;
