import React from "react";
import Form from "./Form";
import Active from "./Active";
import { CompanyDetail } from "../../../types/AuthData";
import { Client } from "../../../store/slices/clientSlice";
import { Checkboxes } from "..";
interface Props {
  companyOne: CompanyDetail | null | undefined;
  clientDetail: Client | null;
  dateChecked: React.RefObject<HTMLInputElement>;
  staffName: React.RefObject<HTMLTableCellElement>;
  checkboxes: Checkboxes;
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Hero = ({ companyOne, clientDetail, dateChecked, staffName, handleCheckboxChange, checkboxes }: Props) => {
  return (
    <div>
      <div className="flex justify-end">
        <img
          className="w-24  mb-4 lg:mb-4"
          src={companyOne?.companyLogo}
          alt="Company Logo"
        />
      </div>
      <div>
        <p className="text-[20px] text-black font-semibold">Participant file checklist</p>
        <Form clientDetail={clientDetail} staffName={staffName} dateChecked={dateChecked} />
        <p className="mt-3 text-black">Instructions: current participant records are to be stored in their active paper-based and e-folders. Documents that have been superseded are to be marked ‘superseded’. Hard copies of superseded documents are to be destroyed once an e-copy has been uploaded into the participant’s e-file.</p>
        <Active checkboxes={checkboxes} handleCheckboxChange={handleCheckboxChange} />
      </div>
    </div>
  );
};

export default Hero;
