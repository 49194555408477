import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

export interface Shift {
  shiftRosterId: number;
  staffId: number;
  reportId: number;
  staff: {
    staffId: number;
    maxStaffId: string | null;
    firstName: string;
    surName: string;
    middleName: string | null;
    aboutMe: string | null;
    address: string;
    postcode: string | null;
    email: string;
    phoneNumber: string;
    profession: string | null;
    gender: string;
    imageUrl: string | null;
    imageFile: string | null;
    country: string;
    state: string | null;
    city: string | null;
    isCompleted: boolean;
    isActive: boolean;
    dateOfBirth: string;
    facebook: string | null;
    twitter: string | null;
    instagram: string | null;
    youtube: string | null;
    linkedIn: string | null;
    github: string | null;
    employmentType: string | null;
    taxFile: string | null;
    position: string | null;
    level: string | null;
    australianCitizen: boolean | null;
    dateJoined: string | null;
    salary: number;
    payDay: number;
    payRate: string | null;
    bankName: string | null;
    bsb: string | null;
    branch: string | null;
    accountName: string | null;
    accountNumber: string | null;
    nextOfKin: string | null;
    relationship: string | null;
    kinPostcode: string | null;
    kinAddress: string | null;
    kinCountry: string | null;
    kinCity: string | null;
    kinEmail: string | null;
    suburb: string | null;
    kinState: string | null;
    kinPhoneNumber: string | null;
    isDocumentUploaded: boolean;
    isAdmin: boolean;
    adm_DesignationsId: number | null;
    adm_Designations: string | null;
    offerLetter: string | null;
    handbook: string | null;
    superForm: string | null;
    supportPosition: string | null;
    companyId: number;
    company: string | null;
    fullName: string;
    dateCreated: string;
    userCreated: string | null;
    dateModified: string;
    userModified: string | null;
    signatureUrl: string | null;
    signatureFile: string | null;
  };
  dateFrom: string;
  dateTo: string;
  activities: string;
  profileId: number;
  profile: {
    profileId: number;
    firstName: string;
    surName: string;
    middleName: string | null;
    clientId: string | null;
    address: string;
    email: string;
    phoneNumber: string;
    gender: string;
    imageUrl: string | null;
    imageFile: string | null;
    country: string;
    state: string | null;
    city: string | null;
    isCompleted: boolean;
    isActive: boolean;
    dateOfBirth: string;
    homePhone: string | null;
    workPhone: string | null;
    profession: string | null;
    culturalBackground: string;
    preferredLanguage: string;
    requireInterpreter: string;
    indigenousSatatus: string;
    ndisPlan: string;
    ndisPlanNote: string;
    privacyPreferences: string;
    financialArrangement: string;
    ndisNo: string | null;
    agreementStartDate: string | null;
    agreementEndDate: string | null;
    nextOfKin: string;
    relationship: string | null;
    kinPostcode: string;
    kinAddress: string | null;
    kinCountry: string;
    kinCity: string | null;
    kinEmail: string;
    suburb: string | null;
    kinState: string | null;
    kinPhoneNumber: string | null;
    companyId: number;
    company: string | null;
    fullName: string;
    dateCreated: string;
    userCreated: string | null;
    dateModified: string;
    userModified: string | null;
  };
  reason: string;
  clients: string;
  status: string;
  appointment: string | null;
  attendance: boolean;
  requestCancellation: boolean;
  isNightShift: boolean;
  isCurrent: boolean;
  isEnded: boolean;
  isExceptionalShift: boolean;
  isShiftReportSigned: boolean;
  repeat: boolean;
  attendId: number;
  companyID: number;
  dateCreated: string;
  userCreated: string | null;
  dateModified: string;
  userModified: string | null;
}

export interface StaffState {
  loading: boolean;
  data: Array<Shift>;
  error: string | undefined;
}
const initialState: StaffState = {
  loading: false,
  data: [],
  error: undefined,
};
export const fetchShiftRoster = createAsyncThunk(
  "Shift/fetchShiftRoster",
  async (companyId: string | number) => {
    try {
      const response = await api.fetchShiftRoaster(companyId);
      return response;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

export const filterShiftRoster = createAsyncThunk(
  "Shift/filterShiftRoster",
  async ({
    companyId,
    dateFrom,
    dateTo,
    staff,
    client,
  }: {
    companyId: number;
    dateFrom: string;
    dateTo: string;
    staff: string | number;
    client: string | number;
  }) => {
    try {
      const response = await api.filterShiftRoster(
        companyId,
        dateFrom,
        dateTo,
        staff,
        client
      );
      return response;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

const shiftRosterSlice = createSlice({
  name: "shift",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchShiftRoster.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchShiftRoster.fulfilled,
      (state, action: PayloadAction<Array<Shift>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(fetchShiftRoster.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(filterShiftRoster.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      filterShiftRoster.fulfilled,
      (state, action: PayloadAction<Array<Shift>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(filterShiftRoster.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },

  reducers: {},
});
export const userSelector = (state: RootState) => state.shiftRoster;
export default shiftRosterSlice.reducer;

// Define selectors to access the profile slice state
export const shiftData = (state: RootState) => state.shiftRoster.data;
export const shiftLoading = (state: RootState) => state.shiftRoster.loading;
export const shiftError = (state: RootState) => state.shiftRoster.error;
