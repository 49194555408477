import React from 'react';
import { Routes, Route } from 'react-router-dom';
import FallBack from '../FallBack';

// Import other admin routes as needed
const SubLayout = () => {
    const SubPage = React.lazy(() => import("./SubscriptionPlan"));

    return (
        <div>
            {/* Auth layout components */}
            <React.Suspense fallback={<FallBack />}>
                <Routes>
                    <Route path='/:comp' element={<SubPage />} />

                </Routes>
            </React.Suspense>
        </div>
    );
};

export default SubLayout;
