import React, { useCallback, useState } from "react";
import Breadcrumb from "../../label/BreadCrumb";
import SEO from "../../../constants/SEO";
import fileImg from "../../../images/icon/EmptyState.png";
import activeImg from "../../../images/icon/ActiveState.png";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../../store/axiosInstance";
import { isAxiosError } from "axios";
import Spinner from "../../Spinner";

const UploadStaffViaExcel = () => {
  const [file, setFile] = React.useState<File | null>(null);
  const [loading1, setLoading1] = useState<boolean>(false);
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Handler for file input changes
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const onDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    // Prevent default behavior (Prevent file from being opened)
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      setFile(event.dataTransfer.files[0]);
      event.dataTransfer.clearData();
    }
  }, []);

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    // Prevent default behavior (Prevent file from being opened)
    event.preventDefault();
  };

  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state?.auth?.user);
  const companyId = user?.companyId;

  const submitData = async () => {
    if (!file) {
      return toast.error("Select File");
    }

    setLoading1(true);
    const formData = new FormData();
    formData.append("CompanyId", companyId?.toString() ?? "");
    file && formData.append("excelfile", file);

    try {
      const { data } = await axiosInstance.post(
        `/Staffs/upload_staff_records?userId=${user?.uid}`,
        formData
      );
      toast.success(data.message);
      navigate("/app/user/staffs");
      setLoading1(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
    } finally {
      setLoading1(false);
    }
  };

  return (
    <>
      <SEO title="Upload Staffs" description="Staff" name="Promax-Care" />
      <Breadcrumb />
      <div className="mt-5 space-y-3">
        <h2 className="font-bold text-xl text-black w-auto">
          Upload Via Excel
        </h2>
        <p className="text-sm text-black">
          Please use the provided template to ensure correct data formatting
          before uploading your Excel file. Fill out the template with staff
          details including First Name, Last Name, Email, Phone Number, and
          Role. Once your file is ready, drag and drop it into the designated
          area or click to browse and select your file. Ensure all required
          fields are completed to avoid upload errors.
        </p>
        <a
          // href={`${process.env.PUBLIC_URL}/template.xlsx`} // Use the PUBLIC_URL environment variable
          href={`/templates/Staff Template.xlsx`} // Use the PUBLIC_URL environment variable
          className="underline text-blue-700 font-bold"
          download="Staff template.xlsx" // Specify the name for the downloaded file
        >
          Click Me To Download Excel Template
        </a>
        <div
          className=" border-2 border-purple-700 border-dashed flex justify-center flex-col items-center rounded py-10 space-y-2"
          onDrop={onDrop}
          onDragOver={onDragOver}
        >
          <img
            src={file ? activeImg : fileImg}
            alt="file upload icon"
            width={200}
            height={300}
          />

          <strong>Drag and Drop to Upload</strong>
          <strong>or</strong>
          <input
            type="file"
            onChange={onFileChange}
            style={{ display: "none" }}
            id="fileInput"
            accept=".xlsx, .xls"
          />
          <label
            htmlFor="fileInput"
            className="bg-gray-300 text-black px-12 rounded shadow-sm py-2 cursor-pointer"
          >
            Browse
          </label>

          {file && (
            <div className="bg-white flex justify-center items-center p-2 rounded relative">
              <button
                onClick={() => setFile(null)}
                className="absolute w-5 h-5  rounded-full bg-red-500 text-white -top-3 -right-2"
              >
                &times;
              </button>
              <p>File ready to be uploaded: {file.name}</p>
            </div>
          )}
        </div>
        <div className="mx-auto text-center mt-3 lg:col-span-2">
          <button
            type="submit"
            onClick={submitData}
            disabled={loading1}
            className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"
          >
            {loading1 ? <Spinner /> : "Submit"}
          </button>
        </div>
      </div>
    </>
  );
};

export default UploadStaffViaExcel;
