import React from "react";
import { Navigate, Route, Routes } from "react-router";
import ServiceAgreement from "./serviceAgreement";
import ParticipantProfile from "./participantProfile";
import ParticipantChecklist from "./participantChecklist";
import ReferralForm from "./referralForm";
import SafetyPlan from "./safetyPlan";
import ConsentForm from "./consentForm";
import SkillDevelopment from "./skillDevelopment";
import SupportPlan from "./supportPlan";
import SupportGoal from "./supportGoal";
import SupportPla from "./supportPla";
import SupportRoutine from "./SupportRoutine";


const ParticipantDocumentLayout = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Navigate to="/" replace />} />
                <Route path="/service-agreement/:uid" element={<ServiceAgreement />} />
                <Route path="/page-participant-profile/:uid" element={<ParticipantProfile />} />
                <Route path="/participant-checklist/:uid" element={<ParticipantChecklist />} />
                <Route path="/referral-form/:uid" element={<ReferralForm />} />
                <Route path="/safety-plan/:uid" element={<SafetyPlan />} />
                <Route path="/consent-form/:uid" element={<ConsentForm />} />
                <Route path="/skill-development/:uid" element={<SkillDevelopment />} />
                <Route path="/support-plan-review/:uid" element={<SupportPlan />} />
                <Route path="/support-plan/:uid" element={<SupportPla />} />
                <Route path="/goal-plan/:uid" element={<SupportGoal />} />
                <Route path="/support-routine/:uid" element={<SupportRoutine />} />

            </Routes>
        </div>
    );
};

export default ParticipantDocumentLayout;
