import React, { useState } from "react";
import SEO from "../../../constants/SEO";
import Breadcrumb from "../../label/BreadCrumb";
import CustomInput from "../../Input/CustomInput";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axiosInstance from "../../../store/axiosInstance";
import { isAxiosError } from "axios";
import Spinner from "../../Spinner";
import { ZodType, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AddressComponent from "../../Address";
import { Coordinates } from "../../../types/DataType";

type DataType = {
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  phoneNumber: string;
  agreementStart: string;
  agreementEnd: string;
  ndisNo: string;
};

const CreateClient = () => {
  const navigate = useNavigate();
  const schema: ZodType<DataType> = z.object({
    firstName: z.string().min(1, "Required Field"),
    lastName: z.string().min(1, "Required Field"),
    middleName: z.string(),
    email: z.string().email(),
    agreementStart: z.string().min(1, "Required Field"),
    agreementEnd: z.string().min(1, "Required Field"),
    ndisNo: z.string(),
    phoneNumber: z.string().min(1, "Required Field"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DataType>({
    resolver: zodResolver(schema),
  });

  const [loading1, setLoading1] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state?.auth?.user);
  const [address, setAddress] = useState<string>("")
  const [coordinates, setCoordinates] = useState<Coordinates | null>(null);

  const companyId = user?.companyId;

  const submitData = async (data: DataType) => {
    setLoading1(true);
    const formData = new FormData();

    if (coordinates?.lat !== undefined && coordinates?.lng !== undefined) {
      formData.append("Latitude", coordinates.lat.toString());
      formData.append("Longitude", coordinates.lng.toString());
    }

    if (address) {
      formData.append("Address", address);
    }
    formData.append("CompanyId", companyId?.toString() ?? ""); // Using optional chaining and nullish coalescing
    formData.append("FirstName", data.firstName);
    formData.append("SurName", data.lastName);
    formData.append("MiddleName", data.middleName);
    formData.append("Email", data.email);
    formData.append("PhoneNumber", data.phoneNumber);
    formData.append("AgreementStartDate", data.agreementStart);
    formData.append("AgreementEndDate", data.agreementEnd);
    formData.append("NDISNo", data.ndisNo);

    try {
      const { data } = await axiosInstance.post(
        `/Profiles/add_client?userId=${user?.uid}`,
        formData
      );
      toast.success(data.message);
      navigate(
        `/app/user/clients/${data.client.profileId}/${data.client.firstName}`
      );
      setLoading1(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
    } finally {
      setLoading1(false);
    }
  };

  return (
    <>
      <SEO title="Create Client" description="Client" name="Promax-Care" />
      <Breadcrumb />

      <div className="mt-5">
        <h2 className="font-bold text-xl text-black w-auto">Add New Client</h2>
        <div className="mt-10">
          <form onSubmit={handleSubmit(submitData)}>
            <div className="p-2 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6">
              <CustomInput
                label="First Name"
                type="text"
                required
                placeholder="First Name"
                {...register("firstName")}
                error={errors.firstName?.message}
              />

              <CustomInput
                label="Last Name"
                type="text"
                {...register("lastName")}
                required
                placeholder="Last Name"
                error={errors.lastName?.message}
              />

              <CustomInput
                label="Middle Name"
                type="text"
                {...register("middleName")}
                placeholder="Middle Name"
                error={errors.middleName?.message}
              />
              <CustomInput
                label="Email"
                type="email"
                {...register("email")}
                required
                placeholder="Email"
                error={errors.email?.message}
              />

              <CustomInput
                label="Phone Number"
                type="text"
                {...register("phoneNumber")}
                required
                placeholder="Phone Number"
                error={errors.phoneNumber?.message}
              />
              {/* <CustomInput
                label="Address"
                type="text"
                {...register("address")}
                required
                placeholder="Address"
                error={errors.address?.message}
              /> */}
              <CustomInput
                label="NDIS NO"
                type="text"
                {...register("ndisNo")}
                required
                placeholder="NDIS NO"
                error={errors.ndisNo?.message}
              />

              <CustomInput
                label="Agreement start date"
                type="date"
                {...register("agreementStart")}
                required
                placeholder="Agreement start date"
                error={errors.agreementStart?.message}
              />
              <CustomInput
                label="Agreement end date"
                type="date"
                {...register("agreementEnd")}
                required
                placeholder="Agreement end date"
                error={errors.agreementEnd?.message}
              />
              <div className="lg:col-span-2">
                <AddressComponent
                  address={address}
                  setAddress={setAddress}
                  setCoordinates={setCoordinates}
                />
              </div>
            </div>

            <div className="mx-auto text-center mt-3 lg:col-span-2">
              <button
                type="submit"
                disabled={loading1 ? true : false}
                className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"
              >
                {loading1 ? <Spinner /> : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateClient;
