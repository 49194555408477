

import React from "react";


interface Props {
    ImplementationDate: React.RefObject<HTMLInputElement>;
    ExpirationDate: React.RefObject<HTMLInputElement>;
}

const Date: React.FC<Props> = ({ ImplementationDate, ExpirationDate }) => {
    return (
        <div className="  mt-9 border-gray-300 py-2 ">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div>
                    <p className="font-semibold">Implementation Date</p>

                    <div>
                        <input
                            type="date"
                            required
                            id="impleDate"
                            ref={ImplementationDate}
                            className="w-full rounded-md border p-2.5 border-gray-200 shadow-sm sm:text-sm"
                        />
                    </div>
                </div>
                <div>
                    <p className="font-semibold">Expiration Date</p>

                    <div>
                        <input
                            type="date"
                            id="expDate"
                            ref={ExpirationDate}
                            className="w-full rounded-md border p-2.5 border-gray-200 shadow-sm sm:text-sm"
                        />
                    </div>
                </div>
            </div>
            <div></div>
        </div>
    );
};

export default Date;