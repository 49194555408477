import React from "react";
type Props = {
    text: JSX.Element;
    key: number
}
const PricingFeatures = ({ text, key }: Props) => {
    return (
        <li className="flex items-center space-x-2" key={key}>
            {/* Icon */}
            <span className=" p-1 bg-green-500 rounded-full w-4 h-4 flex justify-center items-center">
                <svg className="flex-shrink-0 w-3 h-3 text-white dark:text-green-400 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>

                {/* <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.44225 15.7868C12.7663 15.7868 16.2717 12.2815 16.2717 7.95738C16.2717 3.6333 12.7663 0.12793 8.44225 0.12793C4.11816 0.12793 0.612793 3.6333 0.612793 7.95738C0.612793 12.2815 4.11816 15.7868 8.44225 15.7868ZM7.17691 5.30799C6.79472 4.92579 6.17505 4.92579 5.79285 5.30799C5.41065 5.69019 5.41065 6.30985 5.79285 6.69205L7.05818 7.95738L5.79285 9.22271C5.41065 9.60491 5.41065 10.2246 5.79285 10.6068C6.17505 10.989 6.79472 10.989 7.17691 10.6068L8.44225 9.34145L9.70758 10.6068C10.0898 10.989 10.7094 10.989 11.0916 10.6068C11.4738 10.2246 11.4738 9.60491 11.0916 9.22271L9.82631 7.95738L11.0916 6.69205C11.4738 6.30985 11.4738 5.69019 11.0916 5.30799C10.7094 4.92579 10.0898 4.92579 9.70758 5.30799L8.44225 6.57332L7.17691 5.30799Z" fill="#D1D5DB" />
                </svg> */}

            </span>
            <span className="text-sm">{text}</span>
        </li>
    )
}

export default PricingFeatures