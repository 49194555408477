import React, { useEffect, useRef, useState } from "react";
import { StaffProfileTypes } from "../../../types/AuthData";
import { AppDispatch, useAppDispatch } from "../../../store/store";
import {
  fetchStaffShiftReport,
  staffShiftReportData,
  staffShiftReportLoading,
} from "../../../store/slices/StaffSlices/staffReportSlice";
import { useSelector } from "react-redux";
import SEO from "../../../constants/SEO";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import Spinner from "../../../Components/Spinner";
import dayjs from "dayjs";
import PaginationComponent from "../../../Components/CustomPagination";
import ReportCard from "./components/Card";
import { useNavigate } from "react-router-dom";
import fileImg from "../../../images/icon/EmptyState.png";

const ShiftReportList = () => {
  const navigate = useNavigate();

  const staffProfileString = localStorage.getItem("staffProfile");
  const staffProfile: StaffProfileTypes = staffProfileString
    ? JSON.parse(staffProfileString)
    : null;
  // const user = useSelector((state: RootState) => state?.auth?.user);
  const [pageNumber, setPageNumber] = useState(0);
  const dispatch: AppDispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchStaffShiftReport(staffProfile.staffId));
  }, [dispatch]);

  const shiftReport = useSelector(staffShiftReportData);
  const loadingData = useSelector(staffShiftReportLoading);
  // console.log("data", shiftReport, "status", loadingData);

  const itemsPerPage = 12;
  const pageCount = Math.ceil(shiftReport.length / itemsPerPage);
  const displayData = shiftReport.slice(
    pageNumber * itemsPerPage,
    (pageNumber + 1) * itemsPerPage
  );
  const topOfPageRef = useRef<HTMLInputElement>(null);

  const handlePageClick = (selectedItem: { selected: number }) => {
    setPageNumber(selectedItem.selected);
    topOfPageRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const FillReport = (shiftId: number, id: number) => {
    navigate(`/app/staff/report-form/${shiftId}`);
    localStorage.setItem("staffReportId", id.toString());
  };

  return (
    <>
      <SEO
        title={` Staff Shift Report`}
        description="Staff"
        name="Promax-Care"
      />
      <Breadcrumb showNavigation={false} />

      <div
        className="flex justify-between items-center flex-wrap"
        ref={topOfPageRef}
      >
        <h2 className="font-bold text-2xl text-black w-auto">Shift Report</h2>
      </div>
      {shiftReport.length <= 0 && loadingData && <Spinner color="#071952" />}
      {!loadingData && shiftReport.length <= 0 && (
        <div className=" border border-dashed flex justify-center flex-col items-center my-5 rounded py-10 space-y-2">
          <img src={fileImg} alt="file upload icon" width={200} height={300} />

          <strong className="text-xl">No Data Available</strong>
        </div>
      )}
      <div className="my-5">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 ">
          {shiftReport.length > 0 &&
            displayData.map((item) => (
              <ReportCard
                key={item.shiftReportId}
                client={item?.shiftRoster?.clients}
                dateFrom={dayjs(item?.shiftRoster?.dateFrom).format(
                  "D/M/YYYY h:mm A"
                )}
                dateTo={dayjs(item.shiftRoster?.dateTo).format(
                  "D/M/YYYY h:mm A"
                )}
                dateModified={dayjs(item.dateModified).format(
                  "D/M/YYYY h:mm A"
                )}
                onView={() => {}}
                onEdit={() =>
                  FillReport(item.shiftRosterId, item.shiftReportId)
                }
              />
            ))}
        </div>
        {pageCount > 1 && (
          <PaginationComponent
            pageCount={pageCount}
            onPageChange={handlePageClick}
          />
        )}
        <br />
      </div>
    </>
  );
};

export default ShiftReportList;
