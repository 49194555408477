import React, { InputHTMLAttributes, forwardRef, useState } from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string;
    showPasswordIcon?: boolean;
    error?: string | undefined; // New error prop
}

const CustomInput: React.ForwardRefRenderFunction<
    HTMLInputElement,
    InputProps
> = (
    { label, type = "text", error, showPasswordIcon = false, readOnly = false, ...props },
    ref
) => {
        const [showPassword, setShowPassword] = useState(false);

        const toggleShowPassword = () => {
            setShowPassword(!showPassword);
        };

        return (
            <div>
                <label
                    htmlFor={props.id || props.name}
                    className=" mb-2 text-xs flex gap-1  text-gray-600 font-semibold dark:text-white"
                >
                    {label}

                    {props.required && <small className="text-red-500">*</small>}
                </label>
                <div className="relative block overflow-hidden  border border-gray-300   otline-none rounded ">

                    <input
                        {...props}
                        id={props.id}
                        ref={ref}
                        autoComplete="off"
                        type={type === "password" && showPassword ? "text" : type}
                        placeholder={label}
                        readOnly={readOnly}
                        className={`border-none text-gray-900 text-sm outline-none   block ${readOnly ? "bg-gray-200" : "bg-white"}  w-full  p-2.5 focus:border-primary`}
                    />
                    {type === "password" && showPasswordIcon && (
                        <div
                            className="absolute inset-y-0 right-0 flex items-center pr-3.5 cursor-pointer"
                            onClick={toggleShowPassword}
                        >
                            {!showPassword ? (
                                <FaRegEyeSlash className="text-xl text-gray-400" />
                            ) : (
                                <FaRegEye className="text-xl text-gray-400" />
                            )}
                        </div>
                    )}
                </div>
                {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
            </div>
        );
    };

export default forwardRef(CustomInput);
