import React, { useRef, useState } from "react";
import CustomInput from "../../../Components/Input/CustomInput";
import Spinner from "../../../Components/Spinner";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import axiosInstance from "../../../store/axiosInstance";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import SEO from "../../../constants/SEO";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import { useNavigate } from "react-router-dom";
import { StaffProfileTypes } from "../../../types/AuthData";

const NewDailyReport = () => {
  const user = useSelector((state: RootState) => state?.auth?.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [upLoadDoc, setUpLoad] = useState<File | null>(null);
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setUpLoad(file);
    }
  };

  const staffProfileString = localStorage.getItem("staffProfile");
  const staffProfile: StaffProfileTypes = staffProfileString
    ? JSON.parse(staffProfileString)
    : null;
  const startKm = useRef<HTMLInputElement>(null);
  const endKm = useRef<HTMLInputElement>(null);
  const clockIn = useRef<HTMLInputElement>(null);
  const clockOut = useRef<HTMLInputElement>(null);
  const [report, setReport] = useState<string>("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setLoading(true);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position: GeolocationPosition) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          const formData = new FormData();
          formData.append("StartKm", startKm.current?.value ?? "");
          formData.append("EndKm", endKm.current?.value ?? "");
          formData.append("Report", report);
          formData.append("ClockIn", clockIn.current?.value ?? "");
          formData.append("ClockOut", clockOut.current?.value ?? "");
          formData.append("InLongitude", longitude.toString());
          formData.append("InLatitude", latitude.toString());
          upLoadDoc && formData.append("ImageFile", upLoadDoc);
          user?.companyId &&
            formData.append("companyId", user.companyId.toString());
          formData.append("staffId", staffProfile.staffId.toString());

          try {
            // Now you have valid coordinates or default 0, proceed to clock in
            const { data } = await axiosInstance.post(
              `/StaffAttendances/add_report?userId=${user?.uid}`,
              formData
            );
            toast.success(data.message);
            navigate("/app/staff/new-daily-report");
            setLoading(false);
          } catch (error: unknown) {
            if (isAxiosError(error)) {
              toast.error(error.response?.data?.message);
              toast.error(error.response?.data?.title);
              // console.log(error);;
            } else {
              toast.error("An error occurred");
            }
            setLoading(false);
          } finally {
            setLoading(false);
          }
        }
      );
    }
  };

  return (
    <>
      <SEO
        title={` Staff Daily Report`}
        description="Staff"
        name="Promax-Care"
      />
      <Breadcrumb showNavigation={false} />

      <div className="flex justify-between items-center flex-wrap">
        <h2 className="font-bold text-2xl text-black w-auto">Daily Report</h2>
      </div>
      <div className="my-5">
        <form
          action=""
          onSubmit={handleSubmit}
          className="space-y-1  items-center shadow-md rounded-md  bg-white"
        >
          <div className="p-4 grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8 ">
            <CustomInput label="Clock In" type="datetime-local" ref={clockIn} />
            <CustomInput
              label="Clock Out"
              type="datetime-local"
              ref={clockOut}
            />
            <CustomInput
              label="Starting KiloMetre (Km)"
              type="text"
              name="startKm"
              ref={startKm}
            />
            <CustomInput
              label="Ending KiloMetre (Km)"
              type="text"
              name="endKm"
              ref={endKm}
            />

            <div className="lg:col-span-2">
              <CustomInput
                label="Image"
                type="file"
                accept=".pdf,.doc,.docx"
                onChange={handleFileChange}
                placeholder="Image"
              />
            </div>

            <div className="lg:col-span-3">
              <label htmlFor="report" className="font-bold">
                Day Report{" "}
              </label>
              <textarea
                id="report"
                rows={5}
                autoComplete="off"
                name="report"
                className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                // defaultValue={""}
                onChange={(e) => setReport(e.target.value)}
              />
            </div>

            <div className="flex flex-wrap lg:col-span-3">
              <button
                type="submit"
                disabled={loading}
                className="text-white bg-primary h 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
              >
                {loading ? <Spinner /> : "Add"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default NewDailyReport;
