import React from "react";
import dayjs from "dayjs";
import { FaPlus } from "react-icons/fa";
import ShiftCard from "./ShiftCard";
import { ClientShift } from "../../../../store/slices/clientShiftSlice";
interface Props {
    today: string;
    daysOfWeek: dayjs.Dayjs[];
    shift: ClientShift[];
    handleShiftClick: (shift: ClientShift) => void;

}

const WeekView = ({
    today,
    daysOfWeek,
    shift,
    handleShiftClick,

}: Props) => {
    const activitiesByDay =
        daysOfWeek.map((day) =>
            shift.filter(
                (activity) =>
                    dayjs(activity.dateFrom).format("YYYY-MM-DD") ===
                    day.format("YYYY-MM-DD")
            )
        ) || [];

    return (
        <div className="grid grid-cols-1 md:grid-cols-4 xl:grid-cols-7  ">
            {daysOfWeek.map((day, i) => (
                <div className={`border border-gray-300 `} key={i}>
                    <div
                        className={` text-center p-1 flex justify-between items-center text-xs font-semibold text-black border-b border-t border-gray-300`}
                    >
                        <span>{day.format("ddd")}.</span>{" "}
                        <span className="text-xs p-1  text-green-700 border rounded border-green-700 cursor-pointer">
                            Shifts:
                            {activitiesByDay[i].length}
                        </span>{" "}
                        <button
                            //   onClick={() => setAddModal(true)}
                            className="text-xs text-white p-1 bg-brand rounded"
                        >
                            {" "}
                            <FaPlus />
                        </button>
                    </div>
                    <div className="p-1">
                        <small
                            className={`font-bold text-brand ${day.format("ddd, MMM D, YYYY") === today
                                ? "border bg-secondary text-white py-1 px-2 rounded"
                                : ""
                                }`}
                        >
                            {day.format("MMM D")}
                        </small>
                    </div>

                    <div className="p-1 flex flex-col gap-1 h-[72vh] overflow-y-auto">
                        {activitiesByDay[i].length > 0 &&
                            activitiesByDay[i].map((activity) => (
                                <ShiftCard
                                    key={activity.shiftRosterId}
                                    activity={activity}
                                    onShiftClick={() => handleShiftClick(activity)}
                                />
                            ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default WeekView;
