import { isAxiosError } from 'axios';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Spinner from '../../../../Components/Spinner';
import axiosInstance from '../../../../store/axiosInstance';
import EditHealthSupportCom from './Components/EditHealthSupport';
import HealthSupportCom from './Components/HealthSupport';

interface Props {
    uid: string; // Define the type of uid as string
}

export interface HealthSupportDataType {
    healthSupportId: number;
    medicationRequired: boolean;
    medicationFrequency: string;
    additionalSupportDetail: string;
    medicationDetails: string;
    healthIssues: string;
}

export interface EditHealthSupportDataType {
    healthIssues: string
    healthPlan: string;
    healthSupportId: number;
    profileId: number;
    requireMedication: boolean;
    support: string;
    supportDetails: string;
}


const HealthSupport: React.FC<Props> = ({ uid }) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingClient, setLoadingClient] = useState<boolean>(true);
    const [checker, setChecker] = useState<HealthSupportDataType | null>(null)
    const [editHealthSupport, setEditHealthSupport] = useState<EditHealthSupportDataType>({
        healthIssues: '',
        healthPlan: '',
        healthSupportId: 0,
        profileId: 0,
        requireMedication: false,
        support: '',
        supportDetails: '',
    })

    const FetchData = async () => {
        setLoadingClient(true)
        try {
            const { data } = await axiosInstance.get(
                `HealthSupports/get_all?clientId=${uid}`
            );

            if (data && data.length > 0) {
                setChecker(data)
                const healthSupportId = data[0].healthSupportId;
                const { data: secondData } = await axiosInstance.get(`/HealthSupports/${healthSupportId}`);
                setEditHealthSupport(secondData)
            }
            setLoadingClient(false)
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // console.log(error);;
            } else {
                toast.error("An error occurred");
            }
            setLoadingClient(false)
        }
    };

    useEffect(() => {
        FetchData()
    }, [uid]);

    const handleInputDataChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        const { name, value } = e.target;
        if (name === "requireMedication") {
            setEditHealthSupport(prevState => ({
                ...prevState,
                [name]: value === "Yes" // Converts "Yes" to true, "No" to false
            }));
        } else {
            setEditHealthSupport(prevState => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const [healthSupport, setHealthSupport] = useState<HealthSupportDataType>({
        healthSupportId: 0,
        medicationRequired: false,
        medicationFrequency: '',
        additionalSupportDetail: '',
        medicationDetails: '',
        healthIssues: '',
    });

    const handleChange = (e: ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        if (name === "medicationRequired") {
            setHealthSupport(prevState => ({
                ...prevState,
                [name]: value === "Yes" // Converts "Yes" to true, "No" to false
            }));
        } else {
            setHealthSupport(prevState => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const info = {
            profileId: uid,
            healthIssues: healthSupport.healthIssues,
            supportDetails: healthSupport.medicationDetails,
            requireMedication: healthSupport.medicationRequired,
            support: healthSupport.additionalSupportDetail,
            healthPlan: healthSupport.medicationFrequency
        };
        try {
            setLoading(true);
            const { data } = await axiosInstance.post(`/HealthSupports`, info);

            if (data.status === "Success") {
                toast.success(data.message);
            } else {
                toast.error(data.message);
            }
            setLoading(false);
        } catch (error: unknown) {
            console.log(error)
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);
        }
    };

    const handleSubmitEdit = async (e: FormEvent) => {
        e.preventDefault();
        const info = {
            healthSupportId: editHealthSupport.healthSupportId,
            profileId: uid,
            healthIssues: editHealthSupport.healthIssues,
            supportDetails: editHealthSupport.supportDetails,
            requireMedication: editHealthSupport.requireMedication,
            support: editHealthSupport.support,
            healthPlan: editHealthSupport.healthPlan
        };
        try {
            setLoading(true);
            const { data } = await axiosInstance.post(`/HealthSupports/edit/${editHealthSupport.healthSupportId}`, info);

            if (data.status === "Success") {
                toast.success(data.message);
            } else {
                toast.error(data.message);
            }
            setLoading(false);
        } catch (error: unknown) {
            console.log(error);
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);
        }
    };

    return (
        <div>

            {loadingClient && (
                <div className="fixed  inset-0 z-[9999]  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                    <div className="relative w-auto max-w-lg mx-auto">
                        <div
                            className="relative z-[999] flex justify-center items-center p-4  duration-500 ease-in-out bg-white
border-0 rounded-lg shadow-lg outline-none focus:outline-none"
                        >
                            <Spinner color="#071952" />
                        </div>
                    </div>

                    <div className="fixed inset-0 z-40 bg-gray-300 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
                </div>
            )}
            {checker ? (
                <EditHealthSupportCom editHealthSupport={editHealthSupport} handleSubmitEdit={handleSubmitEdit}
                    handleChange={handleChange} handleInputDataChange={handleInputDataChange} loading={loading}
                />
            ) : (
                <form className='mt-10'
                    onSubmit={handleSubmit}
                >
                    <HealthSupportCom
                        healthSupport={healthSupport}
                        handleChange={handleChange}
                    />
                    <div className="mx-auto text-center mt-3">
                        <button
                            type="submit"
                            disabled={loading}
                            className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                               font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                               dark:focus:ring-primary"
                        >
                            {loading ? <Spinner /> : "Add"}
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default HealthSupport;


