import React, { ChangeEvent, useEffect, useState } from "react";
import DataList from "./components/DataList";
import Detaills from "./components/Detaills";
import IconList from "./components/IconList";
import { useSelector } from "react-redux";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../../store/store";
import {
  fetchInbox,
  inboxData,
  inboxLoading,
  Inbox,
} from "../../../../store/slices/inboxSlice";
import {
  fetchSent,
  sentData,
  sentLoading,
} from "../../../../store/slices/sentMessageSlice";
import SentList from "./components/SentList";
import AddMessage from "./components/AddMessage";
import { fetchUser, userData } from "../../../../store/slices/userSlice";

const Message = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("inbox");

  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
  };

  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();

  const fetchData = () => {
    const userId = user?.uid;
    if (userId) {
      dispatch(fetchInbox(userId));
      dispatch(fetchSent(userId));
      dispatch(fetchUser(user?.companyId));
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const userDat = useSelector(userData);
  const inboxList = useSelector(inboxData);
  const sentList = useSelector(sentData);
  const loadingData = useSelector(inboxLoading);
  const loadingSent = useSelector(sentLoading);
  const [selectedMessage, setSelectedMessage] = useState<Inbox | null>(null);

  const filteredInbox = inboxList?.filter((item) => item.status === false);

  const [searchText, setSearchText] = useState<string>("");

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const inbox = inboxList.filter((item) =>
    item.subject.toLowerCase().includes(searchText.toLowerCase())
  );
  const sent = sentList.filter((item) =>
    item.subject.toLowerCase().includes(searchText.toLowerCase())
  );

  const [loadingDetail, setLoadingDetail] = useState(false);

  const [addModal, setAddModal] = useState(false);




  return (
    <>
      <main className="grid grid-cols-6 lg:grid-cols-9  h-screen shadow-sm rounded-2xl relative">
        <IconList
          filteredInbox={filteredInbox}
          activeTab={activeTab}
          handleActiveTab={handleActiveTab}
          setAddModal={setAddModal}
        />
        {activeTab === "inbox" && (
          <DataList
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            inbox={inbox}
            loadingData={loadingData}
            setSelectedMessage={setSelectedMessage}
            handleSearch={handleSearch}
            searchText={searchText}
            loadingDetail={loadingDetail}
            setLoadingDetail={setLoadingDetail}
          />
        )}

        {activeTab === "sent" && (
          <SentList
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            inbox={sent}
            loadingData={loadingSent}
            setSelectedMessage={setSelectedMessage}
            handleSearch={handleSearch}
            searchText={searchText}
            loadingDetail={loadingDetail}
            setLoadingDetail={setLoadingDetail}
          />
        )}
        <Detaills
          fetchData={fetchData}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          selectedMessage={selectedMessage}
          loadingDetail={loadingDetail}
          setLoadingDetail={setLoadingDetail}
          activeTab={activeTab}
        />
      </main>

      <AddMessage
        addModal={addModal}
        setAddModal={setAddModal}
        userDat={userDat}
        fetchData={fetchData}
      />
    </>
  );
};

export default Message;
