import React from 'react';
import { SupportPlaData } from '../../../types/DataType';

interface Props {
    supportForm: SupportPlaData;
    handleInputFormChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    handleRadioFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const EditCourtOrder: React.FC<Props> = ({ supportForm, handleInputFormChange, handleRadioFormChange }) => {

    return (
        <div>
            <table className="w-full mb-6">
                <tbody>
                    <tr>
                        <td className="border px-4 py-2 font-bold">
                            Are there any court orders in place e.g. family court orders? What do we need to know to keep you safe?
                        </td>
                        <td className="border px-4 py-2">
                            <div className="flex items-center">
                                <label className="mr-4">
                                    <input
                                        type="radio"
                                        name="courtOrder"
                                        className="mr-2"
                                        value={supportForm.courtOrder}
                                        checked={supportForm.courtOrder !== ''}
                                        onChange={handleRadioFormChange}
                                    />{' '}
                                    Yes
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="courtOrder"
                                        className="mr-2"
                                        value=""
                                        checked={supportForm.courtOrder === ''}
                                        onChange={handleRadioFormChange}
                                    />{' '}
                                    No
                                </label>
                            </div>

                            {supportForm.courtOrder && (
                                <textarea
                                    className="w-full p-2 border border-gray-300 rounded mt-2"
                                    placeholder="If yes, provide details"
                                    name="courtOrder"
                                    value={supportForm.courtOrder}
                                    onChange={handleInputFormChange}
                                />
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default EditCourtOrder;
