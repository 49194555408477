import React from "react";

interface Props {
  goal: string;
  handleGoalChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  strategies: string[];
  handleStrategyChange: (index: number, event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Section1 = ({ goal, handleGoalChange, handleStrategyChange, strategies }: Props) => {


  return (
    <form >
      <div className="border mt-7 border-gray-300 p-3">
        <div>
          <p className="font-semibold">
            I want to <i>(insert goal):</i>
          </p>
          <input
            className="w-full border border-gray-300 outline-none rounded p-1.5"
            type="text"
            value={goal}
            onChange={handleGoalChange}
          />
        </div>
      </div>
      <div className="w-full border mt-7 border-gray-300 p-3">
        <p className="font-semibold">Strategies to achieve my goal:</p>
        <ol className="list-decimal p-4 space-y-2">
          {strategies.map((strategy, index) => (
            <li key={index}>
              <input
                className="w-full border border-gray-300 outline-none rounded p-1.5"
                type="text"
                value={strategy}
                onChange={(event) => handleStrategyChange(index, event)}
              />
            </li>
          ))}
        </ol>
      </div>
    </form>
  );
};

export default Section1;

