import dayjs from "dayjs";
import React, { useState } from "react";
import { ClientShift } from "../../../../store/slices/clientShiftSlice";
import ShiftCard from "./ShiftCard";
import calendarIcon from "../../../../images/icon/calendar.png";
interface Props {
  today: string;
  daysOfWeek: dayjs.Dayjs[];
  shift: ClientShift[];
}

const DayView = ({ daysOfWeek, today, shift }: Props) => {
  const [currentDay, setCurrentDay] = useState<string>(
    dayjs().tz().format("YYYY-MM-DD")
  );

  const activitiesByDay = shift
    .filter(
      (activity) => dayjs(activity.dateFrom).format("YYYY-MM-DD") === currentDay
    )
    .slice(0, 2);

  const handleDayClick = (day: string) => {
    // Handle click event for each day
    setCurrentDay(day);
    // alert(day)
  };

  return (
    <>
      <div className="grid grid-cols-7">
        {daysOfWeek.map((day, i) => (
          <div className={` `} key={i}>
            <div
              onClick={() => handleDayClick(day.format("YYYY-MM-DD"))}
              className={` text-center  flex justify-center gap-1 cursor-pointer py-2 flex-col items-center text-sm font-semibold text-black `}
            >
              <span>{day.format("ddd")}</span>{" "}
              <small
                className={`font-bold text-brand justify-center flex items-center ${
                  day.format("ddd, MMM D, YYYY") === today
                    ? "border bg-primary text-white w-8 text-sm h-8  rounded-full text-center"
                    : ""
                }
                 ${
                   day.format("YYYY-MM-DD") === currentDay
                     ? "border bg-secondary text-black w-8 text-sm h-8 rounded-full text-center"
                     : ""
                 }
                       `}
              >
                {day.format("D")}
              </small>
            </div>
          </div>
        ))}
      </div>
      <div className="p-2 h-[70vh] overflow-y-auto">
        {activitiesByDay?.length > 0 ? (
          <div>
            {activitiesByDay.length > 0 &&
              activitiesByDay.map((activity, index) => (
                <ShiftCard
                  activity={activity}
                  onShiftClick={() => {}}
                  key={index}
                />
              ))}
          </div>
        ) : (
          <div className="flex flex-col gap-2 items-center justify-center mx-auto pt-20">
            <img src={calendarIcon} alt="calendar icon" width={100} />

            <strong className="text-center">
              No Shift Available for today
            </strong>
          </div>
        )}
      </div>
    </>
  );
};

export default DayView;
