import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

interface ProgressNote {
  companyID: number;
  date: string;
  dateCreated: string;
  dateModified: string;
  endKm: number;
  followUp: string;
  imageFIle: string;
  imageURL: string;
  isCompleted: boolean;
  position: string;
  profile: {
    profileId: number;
    firstName: string;
    surName: string;
    middleName: string;
    clientId: number;
    contactId: string;
    address: string;
    email: string;
    phoneNumber: string;
    gender: string;
    imageUrl: string;
    imageFile: string;
    country: string;
    state: string;
    city: string;
    isCompleted: boolean;
    isActive: boolean;
    dateOfBirth: string; // You may want to use Date type if it's a date string
    homePhone: string;
    workPhone: string;
    profession: string;
    culturalBackground: string;
    preferredLanguage: string;
    requireInterpreter: boolean;
    indigenousSatatus: string;
    ndisPlan: string;
    ndisPlanNote: string;
    privacyPreferences: string;
    financialArrangement: string;
    ndisNo: string;
    agreementStartDate: string; // You may want to use Date type if it's a date string
    agreementEndDate: string; // You may want to use Date type if it's a date string
    nextOfKin: string;
    relationship: string;
    kinPostcode: string;
    kinAddress: string;
    kinCountry: string;
    kinCity: string;
    kinEmail: string;
    suburb: string;
    kinState: string;
    kinPhoneNumber: string;
    fullName: string;
    dateCreated: string; // You may want to use Date type if it's a date string
    userCreated: string;
    dateModified: string; // You may want to use Date type if it's a date string
    userModified: string;
  };
  profileId: number;
  progress: string;
  progressNoteId: number;
  report: string;
  staff: string;
  staffId: number;
  startKm: number;
  userCreated: string;
  userModified: string;
}

export interface ProgressNoteState {
  loading: boolean;
  data: Array<ProgressNote>;
  error: string | undefined;
}
const initialState: ProgressNoteState = {
  loading: false,
  data: [],
  error: undefined,
};
export const fetchProgressNote = createAsyncThunk(
  "progressNote/fetchProgressNote",
  async (companyId: number) => {
    try {
      const response = await api.fetchProgressNote(companyId);
      return response;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

export const filterProgressNote = createAsyncThunk(
  "progressNote/filterProgressNote",
  async ({ staff, client }: { staff: string; client: string }) => {
    try {
      const response = await api.filterProgressNote(staff, client);
      return response.progressNote;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

const progressNoteSlice = createSlice({
  name: "progressNote",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchProgressNote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchProgressNote.fulfilled,
      (state, action: PayloadAction<Array<ProgressNote>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(fetchProgressNote.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(filterProgressNote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      filterProgressNote.fulfilled,
      (state, action: PayloadAction<Array<ProgressNote>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(filterProgressNote.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {},
});
export const userSelector = (state: RootState) => state.progressNote;
export default progressNoteSlice.reducer;

// Define selectors to access the profile slice state
export const progressNoteData = (state: RootState) => state.progressNote.data;
export const progressNoteLoading = (state: RootState) =>
  state.progressNote.loading;
export const progressNoteError = (state: RootState) => state.progressNote.error;
