import dayjs from "dayjs";
import React from "react";
import parse from "html-react-parser";
import { Inbox, fetchInbox } from "../../../../../store/slices/inboxSlice";
import Spinner from "../../../../../Components/Spinner";
import axiosInstance from "../../../../../store/axiosInstance";
import { useSelector } from "react-redux";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../../../store/store";

interface Props {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  inbox: Inbox[];
  loadingData: boolean;
  setSelectedMessage: React.Dispatch<React.SetStateAction<Inbox | null>>;
  searchText: string;
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setLoadingDetail: React.Dispatch<React.SetStateAction<boolean>>;
  loadingDetail: boolean;
}

const SentList = ({
  setSidebarOpen,
  inbox,
  loadingData,
  setSelectedMessage,
  handleSearch,
  searchText,
  setLoadingDetail,
  loadingDetail,
}: Props) => {
  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();

  const handleClick = async (item: Inbox) => {
    setSidebarOpen(true);
    setLoadingDetail(true);
    const { data } = await axiosInstance.get(`/Messages/${item.messageId}`);
    setSelectedMessage(data);
    const userId = user?.uid;
    if (userId) {
      dispatch(fetchInbox(userId));
    }
    setLoadingDetail(false);
  };

  return (
    <section className="flex flex-col pt-3  bg-gray-50 h-full overflow-y-scroll col-span-5 lg:col-span-3 relative">
      <div className="px-3  font-bold text-base">Sent Message</div>
      <label className="px-3 py-3">
        <input
          className="rounded-lg p-3 bg-gray-100 transition duration-200 focus:outline-none focus:ring-2 w-full"
          placeholder="Search..."
          type="search"
          onChange={handleSearch}
          value={searchText}
        />
      </label>
      {inbox.length <= 0 && loadingData && <Spinner />}
      {inbox.length <= 0 && !loadingData && (
        <div className="rounded-2xl ">
          <div className="flex flex-col gap-2 items-center justify-center mx-auto pt-5 px-4 md:px-4 xl:px-10 my-3">
            {/* <img src={notiIcon} alt="notification icon" width={120} /> */}

            <strong className="text-center">
              There's currently no sent messages.
              {/* <small className="font-normal">You will be notified here once there is any activity or anything that needs your attention.</small> */}
            </strong>
          </div>
        </div>
      )}
      {inbox.length > 0 && (
        <ul className="flex  flex-col overflow-y-auto h-screen">
          {inbox.map((item, index) => (
            <li key={index} onClick={() => handleClick(item)}>
              <div className="flex bg-white border rounded-2xl flex-col gap-1 border-t border-gray-200 px-4 py-3 hover:bg-gray-200 dark:border-strokedark dark:hover:bg-meta-4">
                <h4
                  className={`${
                    item.status ? " text-gray-500" : "text-black"
                  } font-bold text-base line-clamp-1`}
                >
                  {item.subject}
                </h4>
                <p className="text-xs line-clamp-2">{parse(item.content)}</p>
                <p className="text-xs font-bold text-gray-400">
                  {dayjs(item.dateCreated).format("D, MMM. YYYY")}
                </p>
              </div>
            </li>
          ))}
        </ul>
      )}

      {loadingDetail && (
        <div className="p-4 top-0 shadow-md right-0 z-10 absolute bg-white rounded-md flex justify-center items-center">
          <Spinner color="#000" />
        </div>
      )}
    </section>
  );
};

export default SentList;
