import React from "react";
import { CompanyDetail } from "../../../types/AuthData";
import { NdisForm } from "../../../types/DataType";
interface Props {
  fundingAvailability: boolean;
  setFundingAvailability: React.Dispatch<React.SetStateAction<boolean>>;
  companyOne: CompanyDetail | null | undefined;
  ndisForm?: NdisForm | null;
  availability?: boolean;
  setAvailability?: React.Dispatch<React.SetStateAction<boolean>>;

}

const Pricing = ({ setFundingAvailability, fundingAvailability, companyOne, ndisForm, availability, setAvailability }: Props) => {
  return (
    <section className="mt-6">
      <div className="mt-5">
        <h3 className="text-black font-bold"><strong>PRICING</strong></h3>
        <p className="text-black leading-8">
          Fees are charged in accordance with the NDIS price guide. You agree
          that we can claim fees in accordance with any price increases, as
          published by the National Disability Insurance Agency, within the
          duration of this agreement. You agree that there is funding available
          to cover the amount that is detailed in the agreed schedule of
          supports:
        </p>
      </div>
      {ndisForm ?
        <div className="mt-5">
          <label className="flex cursor-pointer items-center gap-1">
            <input type="checkbox" id="confirmFunding"
              checked={availability}
              onChange={(e) => setAvailability && setAvailability(e.target.checked)}
              className="mr-2"

            />
            <span className="text-gray-900 font-medium">
              Tick to confirm funding is available.
            </span>
          </label>
        </div> :
        <div className="mt-5">
          <label className="flex cursor-pointer items-center gap-1">
            <input type="checkbox" id="confirmFunding"
              // value={fundingAvailability}
              onChange={() => setFundingAvailability(!fundingAvailability)}
              className="mr-2"

            />
            <span className="text-gray-900 font-medium">
              Tick to confirm funding is available.
            </span>
          </label>
        </div>


      }
      <div className="mt-5">
        <h3 className="text-black font-bold">ESTABLISHMENT FEES</h3>
        <p>
          {companyOne?.companyName} is entitled to claim the following establishment fees:
          Insert as relevant or delete.
        </p>
      </div>
      <div className="mt-5">
        <h3 className="text-black font-bold">INSUFFICIENT FUNDS AND LATE PAYMENTS</h3>
        <p className="text-black leading-8">
          If you have confirmed that funding is available, and we are unable to
          bill for services due to insufficient funds, you agree that you are
          personally responsible for paying the outstanding invoice by the due
          date. Overdue invoices: {companyOne?.companyName} will contact you as a friendly
          reminder one day after the invoice due date has expired to let you
          know your account is overdue and that payment is immediately required.
          In the event your account is overdue by 14 days we will contact you
          again to let you know that payment is immediately required. If you
          have confirmed that funding is available, and we are unable to bill
          for services due to insufficient funds, you (the participant)
          understand that you are responsible for paying the outstanding invoice
          by the due date. In the event that payment is not made after 60 days
          you understand that it may be referred to a collection agency. You
          will be liable for all costs which would be incurred as if the debt is
          collected in full, including any legal demand costs.
        </p>
      </div>
      <div className="mt-5">
        <h3 className="text-black font-bold uppercase">RESPONSIBILITIES OF {companyOne?.companyName}</h3>
        <ul className="list-disc pl-5 text-black leading-8">
          <li>Communicate openly and honestly in a timely manner.</li>
          <li>Treat you with courtesy and respect.</li>
          <li>Consult you on decisions about how services are provided.</li>
          <li>Provide the services that you have asked for.</li>
          <li>Inform you of our complaints and feedback procedure, and address
            concerns in a timely manner.</li>
          <li>Make sure your information is accurate, current, stored securely and
            only accessed by people who need to know it.</li>
          <li>Adhere to the National Disability Insurance Scheme Act 2013 and other
            relevant Australian laws.</li>
          <li>Provide regular invoices and statements for your supports.</li>
          <li>Discuss with you how often you want your agreement and support plan to
            be reviewed.</li>
          <li>Give you a minimum of 24 hours’ notice if there is a change to the
            scheduled appointment.</li>
          <li>Give you the required notice if we need to end the service agreement
            (see below for more information).</li>
        </ul>
      </div>
      <div className="mt-5">
        <h3 className="text-black font-bold">YOUR RESPONSIBILITIES</h3>
        <ul className="list-disc pl-5 text-black leading-8">
          <li>Tell us about your needs so we can provide quality services to you.</li>
          <li>Tell us if for any reason you have exhausted the funding in your NDIS
            plan and will be unable to make payment.</li>
          <li>Ensure that payment for services is made by the invoice due date.</li>
          <li>Treat us with courtesy and respect.</li>
          <li>Talk to us if you or your representative has any concerns about the
            supports being provided.</li>
          <li>Give us a minimum of 48 hours’ notice if you cannot make a scheduled
            appointment. The cancellation policy will apply if the required notice is not provided –
            refer ‘Cancellation Policy’ below for more information.</li>
          <li>Let us know immediately if your NDIS plan is suspended or replaced by a
            new NDIS plan or if you stop being a participant in the NDIS.</li>
        </ul>
      </div>
    </section>
  );
};

export default Pricing;
