import React, { FormEvent } from "react";
import { Modal } from "rsuite";
import { StaffShift } from "../../../../store/slices/staffShiftSlice";
import dayjs from "dayjs";
import CustomInput from "../../../../Components/Input/CustomInput";
import Spinner from "../../../../Components/Spinner";
import { StaffProfileTypes } from "../../../../types/AuthData";
import { Id } from "react-toastify";
interface Props {
  loading: boolean;
  editLoading: boolean;
  showModal: boolean;
  reasonModal: boolean;
  showKmModal: boolean;
  showMessageModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setReasonModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowMessageModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowKmModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedShift: StaffShift | null;
  staffProfile: StaffProfileTypes;
  submitKm: (e: FormEvent) => Promise<void>;
  closeModal: () => void;
  setReason: React.Dispatch<React.SetStateAction<string>>;
  reason: string;
  submitReason: (e: FormEvent) => Promise<Id | undefined>;
  handleInputChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  details: {
    startKm: string;
    endKm: string;
  };
}
const Modals = ({
  showModal,
  setShowModal,
  selectedShift,
  showMessageModal,
  setShowKmModal,
  handleInputChange,
  details,
  setReason,
  reason,
  reasonModal,
  setReasonModal,
  showKmModal,
  loading,
  staffProfile,
  submitKm,
  closeModal,
  submitReason,
  editLoading,
}: Props) => {
  const start = dayjs(selectedShift?.dateFrom);
  const end = dayjs(selectedShift?.dateTo);

  // Calculate total minutes and hours
  const totalMinutes = end.diff(start, "minute");
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  // Format the duration as a string
  let formattedDuration = `${hours} hr ${minutes} min`;
  if (hours > 0 && minutes > 0) {
    formattedDuration = `${hours} hr ${minutes} min`;
  } else if (hours > 0) {
    formattedDuration = `${hours} hr`;
  } else {
    formattedDuration = `${minutes} min`;
  }

  return (
    <>
      <Modal
        open={showMessageModal}
        // onClose={() => setShowMessageModal(false)}
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="my-4 text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-16 shrink-0 fill-[#333] inline"
              viewBox="0 0 512 512"
            >
              <path
                d="M383.841 171.838c-7.881-8.31-21.02-8.676-29.343-.775L221.987 296.732l-63.204-64.893c-8.005-8.213-21.13-8.393-29.35-.387-8.213 7.998-8.386 21.137-.388 29.35l77.492 79.561a20.687 20.687 0 0 0 14.869 6.275 20.744 20.744 0 0 0 14.288-5.694l147.373-139.762c8.316-7.888 8.668-21.027.774-29.344z"
                data-original="#000000"
              />
              <path
                d="M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 470.487c-118.265 0-214.487-96.214-214.487-214.487 0-118.265 96.221-214.487 214.487-214.487 118.272 0 214.487 96.221 214.487 214.487 0 118.272-96.215 214.487-214.487 214.487z"
                data-original="#000000"
              />
            </svg>
            <h4 className="text-2xl text-[#333] font-semibold mt-6">
              Good Job, {staffProfile.firstName}
            </h4>
            <p className="text-sm text-gray-500 mt-4">
              You have successfully clocked into the shift that lasts for{" "}
              {formattedDuration}. Please remember to fill out your shift report
              form before clocking out to avoid errors or being unable to clock
              into your next shift.
            </p>
          </div>
          <div className="mx-auto text-center mt-3">
            <button
              type="button"
              onClick={closeModal}
              className="px-4 py-2.5 rounded text-white text-sm font-semibold border-none outline-none bg-primary hover:bg-primary"
            >
              Proceed
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={showKmModal}
        onClose={() => setShowKmModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Enter Mileage Details</Modal.Title>
        </Modal.Header>
        <p style={{ fontSize: "11px" }} className="mt-2">
          allows you to input the starting and ending kilometers of your
          journey. This information is essential for tracking the distance
          traveled, which is important for various purposes such as
          reimbursement claims, vehicle maintenance, and travel expense records
        </p>
        <Modal.Body>
          {editLoading ? (
            <Spinner />
          ) : (
            <div>
              <form action="" className="space-y-2">
                <CustomInput
                  label="Starting Km"
                  type="text"
                  name="startKm"
                  value={details.startKm}
                  onChange={handleInputChange}
                />
                <CustomInput
                  label="Ending Km"
                  type="text"
                  name="endKm"
                  value={details.endKm}
                  onChange={handleInputChange}
                />

                <div className="mx-auto text-center mt-3">
                  <button
                    onClick={submitKm}
                    disabled={loading}
                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                  >
                    {loading ? <Spinner /> : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">
            Shift Details <span>(#{selectedShift?.shiftRosterId})</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedShift && (
            <table>
              <tbody>
                <tr className="text-sm truncate ...">
                  <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                    Status:
                  </td>
                  <td className=" px-2 py-1 text-gray-700">
                    {" "}
                    <span
                      className={`px-3 py-1 rounded font-bold text-white ${
                        selectedShift.status === "Pending"
                          ? "bg-secondary"
                          : selectedShift.status === "Cancelled"
                          ? "bg-red-500"
                          : "bg-primary"
                      }`}
                    >
                      {selectedShift.status}
                    </span>
                  </td>
                </tr>

                <tr className="text-sm truncate ...">
                  <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                    Staff:
                  </td>
                  <td className=" px-2 py-1 text-gray-700">
                    {selectedShift.staff.fullName}
                  </td>
                </tr>
                <tr className="text-sm truncate ...">
                  <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                    Clients:
                  </td>
                  <td className=" px-2 py-1 text-gray-700">
                    {selectedShift.clients}
                  </td>
                </tr>
                <tr className="text-sm truncate ...">
                  <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                    From:
                  </td>
                  <td className=" px-2 py-1 text-gray-700">
                    {dayjs(selectedShift?.dateFrom).format(
                      "D MMMM, YYYY h:mm A"
                    )}{" "}
                  </td>
                </tr>
                <tr className="text-sm truncate ...">
                  <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                    To:
                  </td>
                  <td className=" px-2 py-1 text-gray-700">
                    {" "}
                    {dayjs(selectedShift?.dateTo).format(
                      "D MMMM, YYYY h:mm A"
                    )}{" "}
                  </td>
                </tr>
                <tr className="text-sm truncate ...">
                  <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                    Activities:
                  </td>
                  <td className="px-2 py-1 whitespace-normal text-gray-700">
                    {" "}
                    {selectedShift.activities}
                  </td>
                </tr>
                {selectedShift.reason && (
                  <tr className="text-sm truncate ...">
                    <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                      Reason:
                    </td>
                    <td className=" px-2 py-1 text-gray-700">
                      {" "}
                      {selectedShift.reason}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        open={reasonModal}
        onClose={() => setReasonModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">
            Request to Cancel Shift
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editLoading ? (
            <Spinner />
          ) : (
            <form action="" onSubmit={submitReason}>
              <div className="py-2">
                <label htmlFor="report" className="font-bold">
                  Provide reasons for cancelling shift{" "}
                </label>
                <textarea
                  id="report"
                  rows={3}
                  name="report"
                  autoComplete="off"
                  value={reason || ""}
                  onChange={(e) => setReason(e.target.value)}
                  className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                  defaultValue={""}
                />
              </div>
              <div className="mx-auto text-center mt-3">
                <button
                  type="submit"
                  disabled={loading}
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                    font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                    dark:focus:ring-primary"
                >
                  {loading ? <Spinner /> : "Submit"}
                </button>
              </div>
            </form>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default Modals;
