import React, { useEffect, useRef, useState } from "react";
import { Shift } from "../../../../store/slices/shiftRosterSlice";
import { ShiftReport } from "../../../../store/slices/StaffSlices/staffReportSlice";
import { fetchCompany, fetchReportInfo, fetchRosterInfo } from "../../../../services/fetchServices";
import { useParams } from "react-router-dom";
import FormHero from "./ShiftReportForm/FormHero";
import FormPartA from "./ShiftReportForm/FormReportPartA";
import FormPartB from "./ShiftReportForm/FormReportPartB";
import SEO from "../../../../constants/SEO";
import Breadcrumb from "../../../../Components/label/BreadCrumb";
import Spinner from "../../../../Components/Spinner";
import PrintComponent from "../../../../participantDocument/PrintTab";
import { CompanyDetail } from "../../../../types/AuthData";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";


const ShiftReportView = () => {
    const [shiftReportDetail, setShiftReportDetail] = useState<ShiftReport>({
        urgentMatters: "",
        medicationGiven: "",
        medicationSigned: "",
        medicationAvailable: "",
        medicatioErrors: "",
        isMealManagementPlan: true,
        details_IfNotMealMaganementPlan: "",
        isDrinkingProblem: true,
        details_IfProblemExist: "",
        isHealthIssues: true,
        details_IfHealthIssuesExist: "",
        goal_Progress: "",
        contactFamily: "",
        isIncident: true,
        details_IfIsIncident: "",
        isBehaviourConcerned: true,
        details_ifIsBehaviourConcerned: "",
        shiftRosterId: 0,
        companyID: 0,
        dateCreated: "",
        dateModified: "",
        shiftReportId: 0,
        userCreated: "",
        userModified: "",
    });

    const { id, uid } = useParams();
    const user = useSelector((state: RootState) => state?.auth?.user);

    const [shiftDetail, setShiftDetail] = useState<Shift | null>(null);
    const [loadingReport, setLoadingReport] = useState<boolean>(false);
    const handleFetchRoster = async () => {
        const data = await fetchRosterInfo(Number(uid));
        setShiftDetail(data);
    };
    const handleFetchReportInfo = async () => {
        setLoadingReport(true)
        const data = await fetchReportInfo(Number(id), Number(uid));
        setShiftReportDetail({ ...data });
        setLoadingReport(false)
    };

    useEffect(() => {
        handleFetchReportInfo();
        handleFetchRoster();
        handleCompanyFetch();
    }, [id, uid]);

    const [companyOne, setCompanyOne] = useState<
        CompanyDetail | null | undefined
    >(null);
    const handleCompanyFetch = async () => {
        // setLoading(true);
        if (user?.companyId) {
            const data = await fetchCompany(user?.companyId);
            setCompanyOne(data);
        }
        // setLoading(false);
    };


    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
        const target = e.target as HTMLInputElement; // Treat all inputs as HTMLInputElement for simplicity
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setShiftReportDetail((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const componentRef = useRef<HTMLDivElement>(null);
    return (
        <>
            <SEO title="Shift Report Form" description="Report Form" name="Promax-Care" />
            <div className="flex justify-between items-center flex-wrap">
                <Breadcrumb />
                <h1 className="font-bold text-black text-2xl"></h1>
            </div>

            {loadingReport && (
                <div className="fixed  inset-0 z-[9999]  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                    <div className="relative w-auto max-w-lg mx-auto">
                        <div
                            className="relative z-[999] flex justify-center items-center p-4  duration-500 ease-in-out bg-white
border-0 rounded-lg shadow-lg outline-none focus:outline-none"
                        >
                            <Spinner color="#071952" />
                        </div>
                    </div>

                    <div className="fixed inset-0 z-40 bg-gray-300 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
                </div>
            )}
            <PrintComponent
                componentRef={componentRef}
                triggerLabel="Print this Document"
                documentTitle="Shift Report"
            />
            <form action="">
                <div className="mx-auto  max-w-3xl p-4 md:p-4 lg:p-8 bg-white mt-10" ref={componentRef}>
                    <FormHero
                        shiftDetail={shiftDetail}
                        companyOne={companyOne}
                    />
                    <FormPartA
                        handleInputChange={handleInputChange}
                        shiftReportDetail={shiftReportDetail}
                    />
                    <FormPartB
                        handleInputChange={handleInputChange}
                        shiftReportDetail={shiftReportDetail}
                        shiftDetail={shiftDetail}
                    />
                </div>

            </form>
        </>
    );
};

export default ShiftReportView;
