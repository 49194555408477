export const DocumentList = [
    // {
    //     docName: "PCD-100 P01 Service agreement",
    //     docUrl: "service-agreement",
    //     isAvailable: true,
    // },
    // {
    //     docName: "PCD-101 P02 Page Participant profile",
    //     docUrl: "page-participant-profile",
    //     isAvailable: true,
    // },
    // {
    //     docName: "PCD-102 P03 Participant file checklist",
    //     docUrl: "participant-checklist",
    //     isAvailable: true,
    // },
    // {
    //     docName: "PCD-103 P04 Referral form",
    //     docUrl: "referral-form",
    //     isAvailable: false,
    // },
    // {
    //     docName: "PCD-104 P05 Participant Safety Plan",
    //     docUrl: "safety-plan",
    //     isAvailable: false,
    // },
    // {
    //     docName: "PCD-105 P06 Participant consent",
    //     docUrl: "consent-form",
    //     isAvailable: true,
    // },
    {
        docName: "PCD-106 P07 Support Plan",
        docUrl: "support-plan",
        isAvailable: false,
    },
    // {
    //     docName: "PCD-107 P08 Skill development Plan",
    //     docUrl: "skill-development",
    //     isAvailable: true,
    // },
    // {
    //     docName: "PCD-108 P09 Support Goal Plan",
    //     docUrl: "goal-plan",
    //     isAvailable: true,
    // },
    // {
    //     docName: "PCD-109 P10 Support plan review",
    //     docUrl: "support-plan-review",
    //     isAvailable: false,
    // },
    {
        docName: "PCD-110 P11 Mealtime Management",
        docUrl: "mealtime-management",
        isAvailable: false,
    },
    {
        docName: "PCD-111 P12 Medication Plan",
        docUrl: "medication-plan",
        isAvailable: false,
    },
];
