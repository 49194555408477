import React from 'react'
import SEO from '../../../../constants/SEO'
import Breadcrumb from '../../../../Components/label/BreadCrumb'
import Card from './components/Card'

const TicketTemplate = () => {
    return (
        <>
            <SEO
                title='Ticket Template'
                description='Ticket'
                name='Promax-Care'
            />
            <Breadcrumb />
            <div className='mt-5'>
                <div className=''>
                    <h2 className=' text-xl text-black w-auto font-bold'>Ticketing Template</h2>
                    <div className='mt-5 grid grid-cols-1 gap-4 lg:grid-cols-2 '>

                        <Card
                            bg="#fff"
                            title="Subject: Can’t Sign in into my Staff Account"
                            content="Once you've sent us your ticket, our support team gets to work. We'll look over what you've told us, figure out the best way to help, and get back to you as soon as we can ..........
                            "
                            actionButton={<button className='px-2 py-1 rounded bg-primary text-white text-sm'>
                                Load Template
                            </button>}
                        />
                        <Card
                            bg="#fff"
                            title="Subject: Trouble Resetting my Password"
                            content="Once you've sent us your ticket, our support team gets to work. We'll look over what you've told us, figure out the best way to help, and get back to you as soon as we can .......... "
                            actionButton={<button className='px-2 py-1 rounded bg-primary text-white text-sm'>
                                Load Template
                            </button>}
                        />
                        <Card
                            bg="#fff"
                            title="Subject: Can’t Sign in into my Staff Account"
                            content="Once you've sent us your ticket, our support team gets to work. We'll look over what you've told us, figure out the best way to help, and get back to you as soon as we can ..........
                            "
                            actionButton={<button className='px-2 py-1 rounded bg-primary text-white text-sm'>
                                Load Template
                            </button>}
                        />
                        <Card
                            bg="#fff"
                            title="Subject: Can’t Sign in into my Staff Account"
                            content="Once you've sent us your ticket, our support team gets to work. We'll look over what you've told us, figure out the best way to help, and get back to you as soon as we can ..........
                            "
                            actionButton={<button className='px-2 py-1 rounded bg-primary text-white text-sm'>
                                Load Template
                            </button>}
                        />
                        <Card
                            bg="#fff"
                            title="Subject: Can’t Sign in into my Staff Account"
                            content="Once you've sent us your ticket, our support team gets to work. We'll look over what you've told us, figure out the best way to help, and get back to you as soon as we can ..........
                            "
                            actionButton={<button className='px-2 py-1 rounded bg-primary text-white text-sm'>
                                Load Template
                            </button>}
                        />
                        <Card
                            bg="#fff"
                            title="Subject: Can’t Sign in into my Staff Account"
                            content="Once you've sent us your ticket, our support team gets to work. We'll look over what you've told us, figure out the best way to help, and get back to you as soon as we can ..........
                            "
                            actionButton={<button className='px-2 py-1 rounded bg-primary text-white text-sm'>
                                Load Template
                            </button>}
                        />
                    </div>
                </div>
            </div>

        </>
    )
}

export default TicketTemplate