import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import DataTable, {
  TableColumn,
  ExpanderComponentProps,
} from "react-data-table-component";
import { FaFileCsv, FaFileExcel, FaFilePdf } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import ExportDropdown from "../../../Components/menu/ExportDropdown";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import Spinner from "../../../Components/Spinner";
import axiosInstance from "../../../store/axiosInstance";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import CustomInput from "../../../Components/Input/CustomInput";
import { Modal } from "rsuite";
import IconButton from "../../../Components/Buttons/IconButton";
import {
  downloadCSV,
  exportToExcel,
  handlePDFDownload,
} from "../../../services/reusableFunc";
import { Column } from "../../../types/DataType";
import dayjs from "dayjs";
import moment from "moment";
import Editor from "../../../Components/Editor/ReactQuill";
import {
  allAdminAttendanceData,
  allAdminAttendanceLoading,
  fetchAllAdminAttendance,
} from "../../../store/slices/allAdminAttendanceSlice";
import LocationMapModal from "../../../Components/Map/MapModal";

interface DataRow {
  clockIn: string;
  clockInCheck: boolean;
  clockOut: string;
  clockOutCheck: boolean;
  companyId: number;
  dateCreated: string;
  dateModified: string;
  duration: number;
  endKm: number;
  imageFIle: string;
  imageURL: string;
  inLatitude: number;
  inLongitude: number;
  report: string;
  adminAttendanceId: number;
  staffId: number;
  startKm: number;
  userCreated: string;
  userModified: string;
  administrator: {
    fullName: string;
  };
}

function formatDuration(duration: number | undefined | null): string {
  if (duration) {
    const durationInMilliseconds = duration / 10000; // Convert ticks to milliseconds
    const durationInMinutes = Math.floor(durationInMilliseconds / (1000 * 60));
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;

    return `${hours} Hrs ${minutes} min`;
  }

  return "0 Hrs 0 min"; // Return "0 Hrs 0 min" if duration is not available
}

const AdminDailyReport = () => {
  const columns: TableColumn<DataRow>[] = [
    {
      name: "Administrator",
      selector: (row) => row.administrator.fullName,
      sortable: true,
      cell: (row) => (
        <span
          className="font-bold"
          style={{ overflow: "hidden", cursor: "pointer" }}
        >
          {row.administrator.fullName}
        </span>
      ),
    },
    {
      name: "Clock-In",
      selector: (row) => dayjs(row.clockIn).format("DD/MM/YYYY h:mm A"),
      sortable: true,
    },
    {
      name: "Duration",
      selector: (row) => formatDuration(row.duration),
      sortable: true,
    },
    {
      name: "Clock-Out",
      selector: (row) => dayjs(row.clockOut).format("DD/MM/YYYY h:mm A"),
      sortable: true,
    },
    {
      name: "Location",
      sortable: true,
      cell: (row) => (
        <span style={{ overflow: "hidden" }}>
          <LocationMapModal
            latitude={row.inLatitude}
            longitude={row.inLongitude}
          />
        </span>
      ),
    },
    {
      name: "Total Km",
      selector: (row) => row.endKm - row.startKm || 0,
      sortable: true,
    },
  ];

  const [image, setImage] = useState<File | null>(null);
  const [loading1, setLoading1] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [details, setDetails] = useState<DataRow>({
    clockIn: "",
    clockInCheck: false,
    clockOut: "",
    clockOutCheck: false,
    companyId: 0,
    dateCreated: "",
    dateModified: "",
    duration: 0,
    endKm: 0,
    imageFIle: "",
    imageURL: "",
    inLatitude: 0,
    inLongitude: 0,
    report: "",
    adminAttendanceId: 0,
    staffId: 0,
    startKm: 0,
    userCreated: "",
    userModified: "",
    administrator: {
      fullName: "",
    },
  });
  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();
  const companyId = user?.companyId;
  useEffect(() => {
    if (companyId) {
      dispatch(fetchAllAdminAttendance(companyId));
    }
  }, [dispatch, companyId]);
  const adminReports = useSelector(allAdminAttendanceData);

  const loading = useSelector(allAdminAttendanceLoading);
  interface Props extends ExpanderComponentProps<DataRow> {
    // currently, props that extend ExpanderComponentProps must be set to optional.
    someTitleProp?: string;
  }

  const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
    return (
      <div className="p-2 flex flex-col gap-2 text-xs">
        <span>
          <span className="font-bold">Administrator: </span>
          <span> {data.administrator.fullName}</span>
        </span>
        <span>
          <span className="font-bold">Report: </span>
          <span> {data.report}</span>
        </span>

        <span>
          <span className="font-bold">Date Created: </span>
          <span>{dayjs(data.dateCreated).format("DD/MM/YYYY h:mm A")}</span>
        </span>
        <span>
          <span className="font-bold">Date Modified: </span>
          <span>{dayjs(data.dateModified).format("DD/MM/YYYY h:mm A")}</span>
        </span>
        <span>
          <span className="font-bold">Date Modified: </span>
          <span>{dayjs(data.dateModified).format("DD/MM/YYYY h:mm A")}</span>
        </span>
        <span>
          <span className="font-bold">Actions: </span>
          <span>
            <span
              className="font-bold text-secondary"
              title="Edit"
              onClick={() => handleEditClick(data.adminAttendanceId)}
            >
              Edit
            </span>{" "}
            &nbsp; | &nbsp;
            <span
              className="font-bold text-info"
              title="Details"
              // to={`/app/reports/administratorReport-details/${data.administratorId}`}
            >
              Details
            </span>
          </span>
        </span>
      </div>
    );
  };

  const handleEditClick = async (id: number) => {
    setEditModal(true);
    try {
      const { data } = await axiosInstance.get(
        `/StaffAttendances/get_attendance_record/${id}`
      );
      setDetails({ ...data });
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImage(file);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [searchText, setSearchText] = useState<string>("");

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredData = adminReports?.filter((item) =>
    item?.administrator?.fullName
      ?.toLowerCase()
      .includes(searchText.toLowerCase())
  );

  const HandleEdit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading1(true);

    const formData = new FormData();
    formData.append("attendanceId", details.adminAttendanceId.toString());
    formData.append("StartKm", details.startKm.toString());
    formData.append("EndKm", details.endKm.toString());
    formData.append("Report", details.report);
    formData.append("ClockIn", details.clockIn);
    formData.append("ClockOut", details.clockOut);
    formData.append("InLongitude", details.inLatitude.toString());
    formData.append("InLatitude", details.inLongitude.toString());
    image && formData.append("ImageFile", image);
    formData.append("companyId", details.companyId.toString());
    formData.append("staffId", details.staffId.toString());

    try {
      const { data } = await axiosInstance.post(
        `/StaffAttendances/edit/${details.adminAttendanceId}?userId=${user?.uid}`,
        formData
      );
      toast.success(data.message);
      companyId && dispatch(fetchAllAdminAttendance(companyId));
      setEditModal(false);
      setLoading1(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
    }
  };

  return (
    <>
      <SEO
        title="Admin Daily Report"
        description="Admin Daily Report"
        name="Promax-Care"
      />
      <Breadcrumb />
      <div className="mt-10">
        {adminReports.length <= 0 && loading && <Spinner color="#071952" />}
        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
          <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
            <h2 className="font-bold text-xl text-black w-auto">
              Admin Daily Report
            </h2>

            <div className="relative w-full lg:col-span-2">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <BsSearch />
              </div>
              <input
                type="text"
                id="simple-search"
                onChange={handleSearch}
                className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Search By Staff Name"
              />
            </div>
          </div>
          <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
            <div className="flex items-center space-x-3 w-full md:w-auto">
              <ExportDropdown>
                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <IconButton
                    icon={<FaFilePdf className="text-red-500" />}
                    title="Export as PDF"
                    onClick={() =>
                      handlePDFDownload(
                        adminReports,
                        columns as unknown as Column<DataRow>[],
                        "AdminReport.pdf"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileExcel className="text-green-600" />}
                    title="Export as Excel Sheet"
                    onClick={() =>
                      exportToExcel(
                        adminReports,
                        columns as unknown as Column<DataRow>[],
                        "AdminReport"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileCsv className="text-green-500" />}
                    title="Export as CSV"
                    onClick={() =>
                      downloadCSV({
                        data: adminReports,
                        filename: "AdminReport.csv",
                      })
                    }
                  />
                </div>
              </ExportDropdown>
            </div>
          </div>
        </div>

        <DataTable
          columns={columns}
          data={filteredData}
          expandableRows
          expandableRowsComponent={ExpandableRowComponent}
          pagination
          paginationTotalRows={adminReports?.length}
        />
      </div>

      <Modal
        open={editModal}
        onClose={() => setEditModal(false)}
        backdrop="static"
        autoFocus
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Edit Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form action="">
              <div className="p-2 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
                <CustomInput
                  label="Clock In"
                  type="text"
                  name="clockIn"
                  value={moment(details?.clockIn).format("LLL")}
                  onChange={handleInputChange}
                  placeholder="Clock In"
                  readOnly
                />

                <CustomInput
                  label="Clock Out"
                  type="text"
                  name="clockOut"
                  value={moment(details?.clockOut).format("LLL")}
                  onChange={handleInputChange}
                  placeholder="Clock Out"
                  readOnly
                />

                <CustomInput
                  label="Starting Kilometre (km)"
                  type="number"
                  name="startKm"
                  value={details?.startKm}
                  onChange={handleInputChange}
                  placeholder="Starting Kilometre (km)"
                />

                <CustomInput
                  label="Ending Kilometre (km)"
                  type="number"
                  name="endKm"
                  value={details?.endKm}
                  onChange={handleInputChange}
                  required
                  placeholder="Ending Kilometre (km)"
                />

                <div className="lg:col-span-2">
                  <label htmlFor="">
                    Additional Report{" "}
                    <span
                      className="text-green-500"
                      style={{ fontSize: "10px" }}
                    >
                      This could be reasons why you were late or information you
                      want your admin to be aware of
                    </span>
                  </label>

                  <Editor
                    placeholder="Write something..."
                    onChange={() => ""}
                    value={details?.report}
                  ></Editor>
                  <br />
                </div>

                <div className="lg:col-span-2">
                  <CustomInput
                    label="Image"
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    onChange={handleFileChange}
                    placeholder="Image"
                  />
                </div>
              </div>

              <div className="mx-auto text-center mt-3">
                <button
                  type="submit"
                  onClick={HandleEdit}
                  disabled={loading1 ? true : false}
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                >
                  {loading1 ? <Spinner /> : "Update"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminDailyReport;
