import React, { useState, ChangeEvent, useEffect, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { fetchSupportRoutinedoc } from "../../services/fetchServices";
import { CompanyDetail } from "../../types/AuthData";
import { SupportRoutineData } from "../../types/DataType";
import PrintComponent from "../PrintTab";
import Hero from "./_component/Hero";

interface RowData {
  companyId: number;
  profileId: number;
  days: string;
  time: string;
  ndisGoal: string;
  supportProvided: string;
  supportRoutineId: number;
}

interface Props {
  companyOne: CompanyDetail | null | undefined;
}

const EditSupportRoutine = ({ companyOne }: Props) => {
  const { uid } = useParams();
  const [supportRoutineForm, setSupportRoutineForm] =
    useState<SupportRoutineData | null>(null);
  const [alertValues, setAlertValues] = useState<RowData[]>([]);
  const componentRef = useRef<HTMLDivElement>(null);

  const fetchDoc = async () => {
    const data = await fetchSupportRoutinedoc(Number(uid));
    setSupportRoutineForm({ ...data });
    setAlertValues([data]); // Initialize alertValues with fetched data
  };

  useEffect(() => {
    fetchDoc();
  }, [uid, companyOne]);

  const handleLocalInputChange = (
    index: number,
    field: keyof RowData,
    value: string
  ) => {
    const newValues = [...alertValues];
    newValues[index] = {
      ...newValues[index],
      [field]: value,
    };
    setAlertValues(newValues);
  };

  const handleAddRow = () => {
    const newRow: RowData = {
      companyId: supportRoutineForm?.companyId || 0,
      profileId: supportRoutineForm?.profileId || 0,
      days: "",
      time: "",
      ndisGoal: "",
      supportProvided: "",
      supportRoutineId: 0,
    };
    setAlertValues([...alertValues, newRow]);
  };

  return (
    <form>
      <PrintComponent
        componentRef={componentRef}
        triggerLabel="Print / Download"
        documentTitle="Support Routine"
      />
      <div
        ref={componentRef}
        className="mx-auto max-w-3xl p-4 md:p-4 lg:p-8 bg-white"
      >
        <Hero companyOne={companyOne} />
        <div>
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold mb-4">Support Routine</h1>
          </div>
          <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border mt-9 border-gray-300">
            <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
              <thead className="ltr:text-left rtl:text-right bg-[#b9b9b95e]">
                <tr className="divide-x-2">
                  <th className="px-4 py-2 font-semibold text-gray-900">Day</th>
                  <th className="px-4 py-2 font-semibold text-gray-900">
                    Time/s
                  </th>
                  <th className="px-4 py-2 font-semibold text-gray-900">
                    NDIS goal: What support is needed? Where is it to be
                    provided?
                  </th>
                  <th className="px-4 py-2 font-semibold text-gray-900">
                    How is the support provided? Is there equipment needed?
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {alertValues.map((row, index) => (
                  <tr key={index} className="divide-x-2">
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      <input
                        className="w-full h-[20px] outline-none"
                        type="text"
                        value={row.days}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleLocalInputChange(index, "days", e.target.value)
                        }
                      />
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      <input
                        className="w-full h-[20px] outline-none"
                        type="time"
                        value={row.time}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleLocalInputChange(index, "time", e.target.value)
                        }
                      />
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      <textarea
                        className="w-full p-2 border border-gray-300 rounded mt-2"
                        value={row.ndisGoal}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                          handleLocalInputChange(
                            index,
                            "ndisGoal",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      <textarea
                        className="w-full p-2 border border-gray-300 rounded mt-2"
                        value={row.supportProvided}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                          handleLocalInputChange(
                            index,
                            "supportProvided",
                            e.target.value
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button
            type="button"
            onClick={handleAddRow}
            className="mt-4 w-10 h-10 bg-gray-200 text-white rounded-full flex justify-center items-center"
          >
            <FaPlus className="text-lg text-black" />
          </button>
        </div>
        <div className="mx-auto text-center mt-3 lg:col-span-2">
          <button
            type="submit"
            className="text-white bg-primary hover:bg-primary focus:ring-4 
                            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                            dark:focus:ring-primary"
          >
            Update
          </button>
        </div>
      </div>
    </form>
  );
};

export default EditSupportRoutine;
