import React, { FormEvent, useEffect, useRef, useState } from "react";
import Hero from "./___component/Hero";
import Section from "./___component/Section";
import Section2 from "./___component/Section2";
import Section3 from "./___component/Section3";
import SignatureForm from "./___component/SignatureForm";
import Breadcrumb from "../../Components/label/BreadCrumb";
import PrintComponent from "../PrintTab";
import { CompanyDetail } from "../../types/AuthData";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  fetchClientInfo,
  fetchClientManagerProfle,
  fetchClientRepresentativeData,
  fetchCompany,
  fetchParticipantConsentdoc,
} from "../../services/fetchServices";
import { useNavigate, useParams } from "react-router-dom";
import { Client } from "../../store/slices/clientSlice";
import Spinner from "../../Components/Spinner";
import axiosInstance from "../../store/axiosInstance";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import Date from "./___component/Date";
import { ParticipantConsentForm, Representative } from "../../types/DataType";
import EditConsentForm from "./EditedConsentForm";
import { Staff } from "../../store/slices/staffSlice";

const ConsentForm = () => {
  const { uid } = useParams();
  const user = useSelector((state: RootState) => state?.auth?.user);
  const [clientDetail, setClientDetail] = useState<Client | null>(null);
  const [loadingClient, setLoadingClient] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [companyOne, setCompanyOne] = useState<
    CompanyDetail | null | undefined
  >(null);
  const handleCompanyFetch = async () => {
    // setLoading(true);
    if (user?.companyId) {
      const data = await fetchCompany(user?.companyId);
      setCompanyOne(data);
    }
    // setLoading(false);
  };
  const [important, setImportant] = useState<string[]>([]);
  const [notImportant, setNotImportant] = useState<string[]>([]);
  const [checker, setChecker] = useState<ParticipantConsentForm | null>(null);
  const [participantConsentForm, setParticipantConsentForm] =
    useState<ParticipantConsentForm>({
      consentFormId: 0,
      isParticipantProvidingConsent: false,
      isAuthorisedPersonProvidingConsent: false,
      authorisedPerson_Name: "",
      relationship: "",
      consent_to_Take_Photos: false,
      consent_to_Record: false,
      consent_to_Provide_Feedback: false,
      consent_to_Collect_Information: false,
      consent_to_Share_Information: false,
      consent_to_Share_Information_with_Person: "",
      consent_Not_to_Share_Information: false,
      consent_Not_to_Share_Information_with_Person: "",
      consent_for_Services: false,
      consent_for_Medical_Treatment: false,
      consent_to_Travel: false,
      consent_to_Manage_Money: false,
      consent_to_Manage_Property: false,
      consent_to_Access_Home: false,
      profileId: 0,
      document_Name: "",
      implementationDate: "",
      expirationDate: "",
      companyId: 0,
      date: "",
      representativeName: "",
      clientSignatureUrl: "",
      representativeSignatureUrl: "",
    });

  const fetchClientData = async () => {
    const data = await fetchClientInfo(uid);
    setClientDetail(data);
    setLoadingClient(false);
  };

  const fetchClientManager = async () => {
    if (clientDetail?.careManager) {
      const data = await fetchClientManagerProfle(
        Number(user?.companyId),
        clientDetail?.careManager
      );
      setCareManager(data);
    }
  };

  const fetchClientRep = async () => {
    const data = await fetchClientRepresentativeData(Number(uid));
    setClientRep(data[0]);
  };

  useEffect(() => {
    fetchClientManager();
    fetchClientRep();
    fetchClientData();
    handleCompanyFetch();
    fetchDoc();
  }, [uid, clientDetail?.careManager]);

  const handleInputDataChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setParticipantConsentForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleRadioDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setParticipantConsentForm((prevState) => ({
      ...prevState,
      [name]: value === "true",
    }));
  };
  const handleCheckboxDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setParticipantConsentForm((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const fetchDoc = async () => {
    const data = await fetchParticipantConsentdoc(
      Number(uid),
      Number(user?.companyId),
      "PCD-105 P06 Participant consent"
    );
    setParticipantConsentForm({ ...data });
    setChecker(data);
    setImportant(
      data?.consent_to_Share_Information_with_Person
        .split(",")
        .map((activity: string) => activity.trim())
    );
    setNotImportant(
      data?.consent_Not_to_Share_Information_with_Person
        .split(",")
        .map((activity: string) => activity.trim())
    );
    // console.log(data);
  };

  const ImplementationDate = useRef<HTMLInputElement>(null);
  const ExpirationDate = useRef<HTMLInputElement>(null);
  const [formDat, setFormData] = useState({
    date: "",
    participantProvidingConsent: false,
    authorisedPersonProvidingConsent: false,
    nameOfAuthorisedPerson: "",
    relationshipToParticipant: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value === "true",
    }));
  };

  const [formDataConsent, setFormDataConsent] = useState({
    consentAudioVisual: false,
    consentFeedback: false,
  });

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormDataConsent((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const [formDataBox, setFormDataBox] = useState({
    consentInfoCollection: false,
    consentPhotoId: false,
  });

  const handleCheckboxChangeBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormDataBox((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const [formDataShare, setFormDataShare] = useState({
    consentShareInfo: false,
    noConsentShareInfo: false,
  });

  const handleRadioChangeShare = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFormDataShare({
      consentShareInfo: value === "consentShareInfo",
      noConsentShareInfo: value === "noConsentShareInfo",
    });
  };
  const [alertValues, setAlertValues] = useState<string[]>([""]);
  const handleInputChange = (index: number, value: string) => {
    const newValues = [...alertValues];
    newValues[index] = value;
    setAlertValues(newValues);
  };
  const handleAddRow = () => {
    setAlertValues([...alertValues, ""]);
  };

  const [notPerson, setNotPerson] = useState<string[]>([""]);
  const handleInputNotChange = (index: number, value: string) => {
    const newValues = [...notPerson];
    newValues[index] = value;
    setNotPerson(newValues);
  };
  const handleAddNotRow = () => {
    setNotPerson([...notPerson, ""]);
  };

  const handleInputImportantChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newActivities = [...important];
    newActivities[index] = event.target.value;
    setImportant(newActivities);
  };
  const handleAddRowEdit = () => {
    setImportant([...important, ""]);
  };

  const handleInputNotChangeEdit = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newActivities = [...notImportant];
    newActivities[index] = event.target.value;
    setNotImportant(newActivities);
  };
  const handleAddNotRowEdit = () => {
    setNotImportant([...notImportant, ""]);
  };

  const [formDataYesNo, setFormDataYesNo] = useState({
    serviceConsent: false,
    medicalConsent: false,
    travelConsent: false,
  });

  const handleRadioChangeYesNo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormDataYesNo((prevState) => ({
      ...prevState,
      [name]: value === "Yes",
    }));
  };

  const [formDataManage, setFormDataManage] = useState({
    consentManageMoney: false,
    consentManageProperty: false,
    consentAccessHome: false,
  });

  const handleCheckboxChangeManage = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = e.target;
    setFormDataManage((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const [careManager, setCareManager] = useState<Staff | null>(null);
  const [signature, setSignature] = useState<Blob | null>(null);
  const [signatureRep, setRepSignature] = useState<Blob | null>(null);
  const [clientRep, setClientRep] = useState<Representative | null>(null);
  const [repName, setRepName] = useState<string>("");

  const handleSignatureSave = (signatureBlob: Blob) => {
    setSignature(signatureBlob);
  };
  const handleRepSignatureSave = (signatureBlob: Blob) => {
    setRepSignature(signatureBlob);
  };
  const navigate = useNavigate();
  const componentRef = useRef<HTMLDivElement>(null);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const expirationDateValue = ExpirationDate.current?.value;
    const formattedValues = alertValues.join(",");
    const notPersonValue = notPerson.join(",");

    const formData = new FormData();
    formData.append(
      "IsParticipantProvidingConsent",
      formDat.participantProvidingConsent.toString()
    );
    formData.append(
      "IsAuthorisedPersonProvidingConsent",
      formDat.authorisedPersonProvidingConsent.toString()
    );
    formData.append("AuthorisedPerson_Name", formDat.nameOfAuthorisedPerson);
    formData.append("Date", formDat.date);
    formData.append("Relationship", formDat.relationshipToParticipant);
    formData.append(
      "Consent_to_Take_Photos",
      formDataBox.consentPhotoId.toString()
    );
    formData.append(
      "Consent_to_Record",
      formDataConsent.consentAudioVisual.toString()
    );
    formData.append(
      "Consent_to_Provide_Feedback",
      formDataConsent.consentFeedback.toString()
    );
    formData.append(
      "Consent_to_Collect_Information",
      formDataBox.consentInfoCollection.toString()
    );
    formData.append(
      "Consent_to_Share_Information",
      formDataShare.consentShareInfo.toString()
    );
    formData.append(
      "Consent_to_Share_Information_with_Person",
      formattedValues
    );
    formData.append(
      "Consent_Not_to_Share_Information",
      formDataShare.noConsentShareInfo.toString()
    );
    formData.append(
      "Consent_Not_to_Share_Information_with_Person",
      notPersonValue
    );
    formData.append(
      "Consent_for_Services",
      formDataYesNo.serviceConsent.toString()
    );
    formData.append(
      "Consent_for_Medical_Treatment",
      formDataYesNo.medicalConsent.toString()
    );
    formData.append(
      "Consent_to_Travel",
      formDataYesNo.travelConsent.toString()
    );
    formData.append(
      "Consent_to_Manage_Money",
      formDataManage.consentAccessHome.toString()
    );
    formData.append(
      "Consent_to_Manage_Property",
      formDataManage.consentManageMoney.toString()
    );
    formData.append(
      "Consent_to_Access_Home",
      formDataManage.consentManageProperty.toString()
    );
    formData.append("Document_Name", "PCD-105 P06 Participant consent");
    formData.append("RepresentativeName", repName);
    formData.append(
      "ImplementationDate",
      ImplementationDate.current?.value as string
    );
    uid && formData.append("ProfileId", uid);
    formData.append("CompanyId", user?.companyId.toString() || "");
    if (expirationDateValue) {
      formData.append("ExpirationDate", expirationDateValue);
    }
    if (signature) {
      formData.append("ClientSignatureFile", signature, "signature.png");
    }
    if (signatureRep) {
      formData.append(
        "RepresentativeSignatureFile",
        signatureRep,
        "signature.png"
      );
    }

    try {
      setLoading(true);

      const { data } = await axiosInstance.post(
        `/ConsentForms/fill_consent_form?userId=${user?.uid}`,
        formData
      );
      toast.success(data.message);
      setLoading(false);
      navigate(
        `/app/user/clients/${clientDetail?.profileId}/${clientDetail?.firstName}`
      );
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleEditSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const formattedValues = important.join(",");
    const notPersonValue = notImportant.join(",");

    setLoading(true);
    const formData = new FormData();
    if (notPersonValue) {
      formData.append(
        "Consent_Not_to_Share_Information_with_Person",
        notPersonValue
      );
    }
    if (formattedValues) {
      formData.append(
        "Consent_to_Share_Information_with_Person",
        formattedValues
      );
    }
    if (signature) {
      formData.append("ClientSignatureFile", signature, "signature.png");
    }
    if (signatureRep) {
      formData.append(
        "RepresentativeSignatureFile",
        signatureRep,
        "signature.png"
      );
    }

    for (const key in participantConsentForm) {
      const value =
        participantConsentForm[key as keyof typeof participantConsentForm];
      if (value === null) {
        formData.append(key, ""); // Pass empty string if value is null
      } else {
        formData.append(key, value.toString());
      }
    }
    try {
      const { data } = await axiosInstance.post(
        `/ConsentForms/edit/${participantConsentForm.consentFormId}?userId=${user?.uid}`,
        formData
      );
      toast.success(data.message);
      setLoading(false);
      navigate(
        `/app/user/clients/${clientDetail?.profileId}/${clientDetail?.firstName}`
      );
    } catch (error: unknown) {
      console.log(error);
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Breadcrumb />
      {loadingClient && (
        <div className="fixed  inset-0 z-[9999]  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-auto max-w-lg mx-auto">
            <div
              className="relative z-[999] flex justify-center items-center p-4  duration-500 ease-in-out bg-white
border-0 rounded-lg shadow-lg outline-none focus:outline-none"
            >
              <Spinner color="#071952" />
            </div>
          </div>

          <div className="fixed inset-0 z-40 bg-gray-300 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
        </div>
      )}

      {checker ? (
        <EditConsentForm
          companyOne={companyOne}
          clientDetail={clientDetail}
          participantConsentForm={participantConsentForm}
          handleInputDataChange={handleInputDataChange}
          handleRadioDataChange={handleRadioDataChange}
          handleCheckboxDataChange={handleCheckboxDataChange}
          handleAddRowEdit={handleAddRowEdit}
          important={important}
          handleInputImportantChange={handleInputImportantChange}
          handleEditSubmit={handleEditSubmit}
          loading={loading}
          handleAddNotRowEdit={handleAddNotRowEdit}
          handleInputNotChangeEdit={handleInputNotChangeEdit}
          notImportant={notImportant}
          careManager={careManager}
          handleSignatureSave={handleSignatureSave}
          setSignature={setSignature}
          signature={signature}
          clientRep={clientRep}
          handleRepSignatureSave={handleRepSignatureSave}
          setRepSignature={setRepSignature}
          signatureRep={signatureRep}
          setRepName={setRepName}
          repName={repName}
        />
      ) : (
        <form onSubmit={handleSubmit}>
          <PrintComponent
            componentRef={componentRef}
            triggerLabel="Print / Download"
            documentTitle="Consent Form"
          />
          <div
            ref={componentRef}
            className="mx-auto  max-w-3xl p-4 md:p-4 lg:p-8 bg-white"
          >
            <Hero
              companyOne={companyOne}
              clientDetail={clientDetail}
              formData={formDat}
              handleChange={handleChange}
              handleRadioChange={handleRadioChange}
            />
            <Section
              formDataBox={formDataBox}
              handleCheckboxChangeBox={handleCheckboxChangeBox}
              formDataShare={formDataShare}
              companyOne={companyOne}
              formDataConsent={formDataConsent}
              handleCheckboxChange={handleCheckboxChange}
              handleRadioChangeShare={handleRadioChangeShare}
              alertValues={alertValues}
              handleAddRow={handleAddRow}
              notPerson={notPerson}
              handleInputChange={handleInputChange}
              handleAddNotRow={handleAddNotRow}
              handleInputNotChange={handleInputNotChange}
            />
            <Section2
              companyOne={companyOne}
              formDataYesNo={formDataYesNo}
              handleRadioChangeYesNo={handleRadioChangeYesNo}
            />
            <Section3
              companyOne={companyOne}
              handleCheckboxChangeManage={handleCheckboxChangeManage}
              formDataManage={formDataManage}
            />
            <SignatureForm
              companyOne={companyOne}
              clientDetail={clientDetail}
              careManager={careManager}
              handleSignatureSave={handleSignatureSave}
              handleRepSignatureSave={handleRepSignatureSave}
              setSignature={setSignature}
              setRepSignature={setRepSignature}
              signatureRep={signatureRep}
              signature={signature}
              clientRep={clientRep}
              setRepName={setRepName}
              repName={repName}
            />
            <Date
              ExpirationDate={ExpirationDate}
              ImplementationDate={ImplementationDate}
            />
            <div className="my-5">
              <p className="font-semibold">
                Other ways of seeking your consent
              </p>
              <p className="my-3">
                Please note that there are other ways we might seek your consent
                e.g. verbally via email, or text. Examples include:
              </p>
              <ul>
                <li>
                  • Seeking consent verbally before commencing personal care
                  routines
                </li>
                <li>
                  • Seeking consent over email or text to share your information
                  with another provider that is not listed on the above form.
                </li>
              </ul>
              <p className="my-3">
                In the instance we obtain your consent verbally via email or
                text we will record your consent in your progress notes.
              </p>
            </div>
          </div>
          <div className="mx-auto text-center mt-3 lg:col-span-2">
            <button
              type="submit"
              disabled={loading}
              className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"
            >
              {loading ? <Spinner /> : "Submit"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ConsentForm;
