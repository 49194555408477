// import React from "react";

// const ServiceTable = () => {
//   return (
//     <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border mt-9 border-gray-300">
//       <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
//         <thead className="ltr:text-left rtl:text-right bg-[#b9b9b95e]">
//           <tr className="divide-x-2">
//             <th className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900"></th>
//             <th className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
//               Time/s
//             </th>
//             <th className="whitespace-nowrap px-4 divide-x-2 py-2 font-semibold text-gray-900">
//               What, when and how support is to be provided
//             </th>
//           </tr>
//         </thead>

//         <tbody className="divide-y divide-gray-200">
//           <tr className="divide-x-2">
//             <td className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
//               Monday
//             </td>
//             <td
//               className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
//               contentEditable
//             ></td>
//             <td
//               className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
//               contentEditable
//             ></td>
//           </tr>
//           <tr className="divide-x-2">
//             <td className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
//               Tuesday
//             </td>
//             <td
//               className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
//               contentEditable
//             ></td>
//             <td
//               className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
//               contentEditable
//             ></td>
//           </tr>
//           <tr className="divide-x-2">
//             <td className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
//               Wednesday
//             </td>
//             <td
//               className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
//               contentEditable
//             ></td>
//             <td
//               className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
//               contentEditable
//             ></td>
//           </tr>
//           <tr className="divide-x-2">
//             <td className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
//               Thursday
//             </td>
//             <td
//               className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
//               contentEditable
//             ></td>
//             <td
//               className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
//               contentEditable
//             ></td>
//           </tr>
//           <tr className="divide-x-2">
//             <td className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
//               Friday
//             </td>
//             <td
//               className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
//               contentEditable
//             ></td>
//             <td
//               className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
//               contentEditable
//             ></td>
//           </tr>
//           <tr className="divide-x-2">
//             <td className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
//               Saturday
//             </td>
//             <td
//               className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
//               contentEditable
//             ></td>
//             <td
//               className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
//               contentEditable
//             ></td>
//           </tr>
//           <tr className="divide-x-2">
//             <td className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
//               Sunday
//             </td>
//             <td
//               className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
//               contentEditable
//             ></td>
//             <td
//               className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
//               contentEditable
//             ></td>
//           </tr>
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default ServiceTable;


import React, {
  useEffect,
} from "react";
import DataTable, {
  TableColumn,
} from "react-data-table-component";
import { useSelector } from "react-redux";
import { AppDispatch, useAppDispatch } from "../../../store/store";
import { clientScheduleData, clientScheduleLoading, fetchClientSchedule } from "../../../store/slices/clientScheduleSlice";
import Spinner from "../../../Components/Spinner";

interface DataRow {
  activities: string;
  clientScheduleId: number;
  companyID: number;
  dateCreated: string;
  dateModified: string;
  days: string;
  fromTimeOfDay: string;
  profileId: number;
  toTimeOfDay: string;
  userCreated: string;
  userModified: string;
}

interface ClientScheduleProps {
  uid: string; // Define the type of uid as string
}

const ServiceTable: React.FC<ClientScheduleProps> = ({ uid }) => {
  const convertTo12HourFormat = (time24h: string): string => {
    const [hoursStr, minutesStr] = time24h.split(":");
    let hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);
    let suffix = "AM";

    if (hours >= 12) {
      suffix = "PM";
      hours = hours - 12;
    }

    if (hours === 0) {
      hours = 12;
    }

    return `${hours}:${minutes < 10 ? "0" + minutes : minutes} ${suffix}`;
  };

  const columns: TableColumn<DataRow>[] = [
    {
      name: "Days",
      selector: (row) => row.days,
      sortable: true,
    },
    {
      name: "Time/s",
      selector: (row) => convertTo12HourFormat(row.fromTimeOfDay),
      sortable: true,
    },

    {
      name: "What, when and how support is to be provided",
      selector: (row) => row.activities,
      sortable: true,
    },

  ];

  const dispatch: AppDispatch = useAppDispatch();
  useEffect(() => {
    if (uid) {
      dispatch(fetchClientSchedule(Number(uid)));
    }
  }, [dispatch, uid]);
  const clientSchedule = useSelector(clientScheduleData);
  const loadingData = useSelector(clientScheduleLoading);


  return (
    <div className="">
      {clientSchedule.length <= 0 && loadingData && <Spinner color="#071952" />}
      {/* <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">

        <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">

          <button
            type="button"
            onClick={() => setAddModal(true)}
            className="px-4 py-2 bg-primary text-white  disabled:bg-slate-400
    transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm 
   "
          >
            Add New Daily Activity
          </button>
        </div>
      </div> */}

      <DataTable
        columns={columns}
        data={clientSchedule}
      // expandableRows
      // expandableRowsComponent={ExpandableRowComponent}
      // pagination
      // paginationTotalRows={clientSchedule?.length}
      // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}
      />


    </div>
  );
};

export default ServiceTable;
