import React from "react";

const GuidedQuestions = () => {
  return (
    <div className="mx-auto my-10">
      <p className="font-semibold text-[25px] text-black">
        3. Step 1: Guided Questions (initial assessment)
      </p>

      <div className="overflow-x-auto border my-4 ">
        <div className=" p-2 font-medium text-[16px]  bg-[gainsboro] text-black">
          Use the Guided Questions when talking with participants about any
          safety risks. Tick the box to indicate a ‘yes’ response for each
          guided question. If responses to the Guided Questions indicates a
          safety risk, proceed to Step 3 for each risk area identified.
        </div>
        <table className="min-w-full  divide-y-2 divide-gray-200 bg-white text-sm">
          <tbody className="divide-y divide-gray-200">
            <tr className="divide-x-2 ">
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                1.
              </td>
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                <input type="checkbox" />
              </td>
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Are there any <strong>relationships</strong> in your personal life that are
                unsafe for you?
                <br />
                For example domestic violence or exploitation in your personal
                life?
              </td>
            </tr>
            <tr className="divide-x-2 ">
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                2.
              </td>
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                <input type="checkbox" />
              </td>
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Do you have any <strong>lifestyle</strong> choices that present risks for you?
                <br />
                This could include smoking, and/or misuse of alcohol or other
                drugs, illegal behaviour
              </td>
            </tr>
            <tr className="divide-x-2 ">
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                3.
              </td>
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                <input type="checkbox" />
              </td>
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Do you have any <strong>behaviours</strong> that place yourself at risk of harm?
                <br />
                This could include self-harm
              </td>
            </tr>
            <tr className="divide-x-2 ">
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                4.
              </td>
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                <input type="checkbox" />
              </td>
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Do you have any <strong>behaviours</strong> that place others at risk of harm?
                <br />
                This could include physical abuse, verbal abuse, sexual abuse
              </td>
            </tr>
            <tr className="divide-x-2 ">
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                5.
              </td>
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                <input type="checkbox" />
              </td>
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Do you have any <strong>behaviours</strong> that cause damage to property, including your own property or that of others?
              </td>
            </tr>
            <tr className="divide-x-2 ">
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                6.
              </td>
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                <input type="checkbox" />
              </td>
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Do you have any issues associated with <strong>eating or drinking</strong>?
                <br />
                For example, do you have any problems with swallowing? Do you have allergies, food intolerances or need a modified diet?
              </td>
            </tr>
            <tr className="divide-x-2 ">
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                7.
              </td>
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                <input type="checkbox" />
              </td>
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Do you have any <strong>health or medical</strong> issues that we need to know about?
                <br />
                For example prone to infections, allergies, diabetes etc.
              </td>
            </tr>
            <tr className="divide-x-2 ">
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                8.
              </td>
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                <input type="checkbox" />
              </td>
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Do you have any <strong>personal care risks</strong> that we need to know about?
                <br />
                For example, do you need assistance with shaving, so you don’t cut yourself, or supervision in the shower?

              </td>
            </tr>
            <tr className="divide-x-2 ">
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                9.
              </td>
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                <input type="checkbox" />
              </td>
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Do you have any <strong>mobility or accidental movement</strong> risks?
                <br />
                This could include accidental movement, slips, trips or falls, transfers etc.
              </td>
            </tr>
            <tr className="divide-x-2 ">
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                10.
              </td>
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                <input type="checkbox" />
              </td>
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Do you have any risks associated with <strong>managing your property or money</strong>?
                <br />
                For example, do you give money away when you don’t want to or have difficulty budgeting for all the things you need?
              </td>
            </tr>
            <tr className="divide-x-2 ">
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                11.
              </td>
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                <input type="checkbox" />
              </td>
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Are there any known <strong>hazards or environmental</strong> risks to your safety and well-being we need to consider e.g. safety around roads, water, electricity etc.?
              </td>
            </tr>
            <tr className="divide-x-2 ">
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                12.
              </td>
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                <input type="checkbox" />
              </td>
              <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                <span>Are there any other risks or issues that we should know to provide a safe service to you? Please summarise: </span>
                <input type="text" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GuidedQuestions;
