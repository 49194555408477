import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import PrintComponent from "../PrintTab";
import { CompanyDetail } from "../../types/AuthData";
import { Client } from "../../store/slices/clientSlice";
import { ParticipantCheckList } from "../../types/DataType";
import EditHero from "./__component/EditHero";
import EditPlanForm from "./__component/EditPlanForm";
import EditDate from "./__component/EditDate";
import Spinner from "../../Components/Spinner";
import { fetchParticipantChecklistdoc } from "../../services/fetchServices";
import axiosInstance from "../../store/axiosInstance";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export interface Checkboxes {
  [key: string]: boolean;
}

export interface Plan {
  name: string;
  checked: boolean;
}

interface Props {
  clientDetail: Client | null;
  companyOne: CompanyDetail | null | undefined;
}

const EditParticipantChecklist: React.FC<Props> = ({
  companyOne,
  clientDetail,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state?.auth?.user);
  const [participantCheck, setParticipantCheck] =
    useState<ParticipantCheckList>({
      participantFileChecklistId: 0,
      staff_Member: "",
      service_Argreement: false,
      consent_Form: false,
      support_plan: false,
      safety_plan: false,
      medication_Plan: false,
      medication_Chart: false,
      other_plan: false,
      plan_1: "",
      check_Plan_1: false,
      plan_2: "",
      check_Plan_2: false,
      plan_3: "",
      check_Plan_3: false,
      plan_4: "",
      check_Plan_4: false,
      plan_5: "",
      check_Plan_5: false,
      profileId: 0,
      document_Name: "",
      implementationDate: "",
      expirationDate: "",
      companyId: 0,
    });

  const fetchDoc = async () => {
    const data = await fetchParticipantChecklistdoc(
      Number(clientDetail?.profileId),
      Number(companyOne?.companyId),
      "PCD-102 P03 Participant file checklist"
    );
    setParticipantCheck(data);
  };

  useEffect(() => {
    fetchDoc();
  }, []);

  const handleEditCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { id, checked } = event.target;
    setEditCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [id]: checked as boolean,
    }));
  };

  const handleInputDataChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setParticipantCheck((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [editCheckboxes, setEditCheckboxes] = useState({
    // sign: true,
    // consent: true,
    // support: true,
    // safety: true,
    // manage: true,
    // chart: true,
    // plan: true
    sign: participantCheck.service_Argreement,
    consent: participantCheck.consent_Form,
    support: participantCheck.support_plan,
    safety: participantCheck.safety_plan,
    manage: participantCheck.medication_Plan,
    chart: participantCheck.medication_Chart,
    plan: participantCheck.other_plan,
  });

  const [editPlans, setEditPlans] = useState<Plan[]>([]);

  useEffect(() => {
    if (participantCheck) {
      // Only update if participantCheck is not null
      setEditCheckboxes({
        sign: participantCheck.service_Argreement,
        consent: participantCheck.consent_Form,
        support: participantCheck.support_plan,
        safety: participantCheck.safety_plan,
        manage: participantCheck.medication_Plan,
        chart: participantCheck.medication_Chart,
        plan: participantCheck.other_plan,
      });

      setEditPlans([
        {
          name: participantCheck.plan_1,
          checked: participantCheck.check_Plan_1,
        },
        {
          name: participantCheck.plan_2,
          checked: participantCheck.check_Plan_2,
        },
        {
          name: participantCheck.plan_3,
          checked: participantCheck.check_Plan_3,
        },
        {
          name: participantCheck.plan_4,
          checked: participantCheck.check_Plan_4,
        },
        {
          name: participantCheck.plan_5,
          checked: participantCheck.check_Plan_5,
        },
      ]);
    } else {
      // Handle a null participantCheck appropriately
      setEditCheckboxes({
        sign: false,
        consent: false,
        support: false,
        safety: false,
        manage: false,
        chart: false,
        plan: false,
      });

      setEditPlans(new Array(5).fill({ name: "", checked: false }));
    }
  }, [participantCheck]);

  const handleInputEditChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newPlans = [...editPlans];
    newPlans[index].name = event.target.value;
    setEditPlans(newPlans);
  };

  const handlePlanCheckboxEditChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newPlans = [...editPlans];
    newPlans[index].checked = event.target.checked;
    setEditPlans(newPlans);
  };

  const componentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const handleEditSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const info = {
      participantFileChecklistId: participantCheck.participantFileChecklistId,
      staff_Member: participantCheck.staff_Member,
      service_Argreement: participantCheck.service_Argreement,
      consent_Form: participantCheck.consent_Form,
      support_plan: participantCheck.support_plan,
      safety_plan: participantCheck.safety_plan,
      medication_Plan: participantCheck.medication_Plan,
      medication_Chart: participantCheck.medication_Chart,
      other_plan: participantCheck.other_plan,
      plan_1: editPlans[0].name,
      check_Plan_1: editPlans[0].checked,
      plan_2: editPlans[1].name,
      check_Plan_2: editPlans[1].checked,
      plan_3: editPlans[2].name,
      check_Plan_3: editPlans[2].checked,
      plan_4: editPlans[3].name,
      check_Plan_4: editPlans[3].checked,
      plan_5: editPlans[4].name,
      check_Plan_5: editPlans[4].checked,
      profileId: clientDetail?.profileId,
      document_Name: "PCD-102 P03 Participant file checklist",
      implementationDate: participantCheck.implementationDate,
      expirationDate: participantCheck.expirationDate,
      companyId: companyOne?.companyId,
    };

    try {
      setLoading(true);

      const { data } = await axiosInstance.post(
        `/ParticipantFileChecklists/edit/${participantCheck.participantFileChecklistId}?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      setLoading(false);
      navigate(
        `/app/user/clients/${clientDetail?.profileId}/${clientDetail?.firstName}`
      );
    } catch (error: unknown) {
      console.log(error);
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleEditSubmit}>
        <PrintComponent
          componentRef={componentRef}
          triggerLabel="Print / Download"
          documentTitle="Participant Checklist"
        />
        <div
          ref={componentRef}
          className="mx-auto  max-w-3xl p-4 md:p-4 lg:p-8 bg-white"
        >
          <EditHero
            clientDetail={clientDetail}
            companyOne={companyOne}
            handleInputDataChange={handleInputDataChange}
            participantCheck={participantCheck}
            handleEditCheckboxChange={handleEditCheckboxChange}
            editCheckboxes={editCheckboxes}
          />
          <EditPlanForm
            participantCheck={participantCheck}
            editPlans={editPlans}
            handlePlanCheckboxEditChange={handlePlanCheckboxEditChange}
            handleInputEditChange={handleInputEditChange}
          />

          <EditDate
            handleInputDataChange={handleInputDataChange}
            participantCheck={participantCheck}
          />
        </div>

        <div className="mx-auto text-center mt-3 lg:col-span-2">
          <button
            type="submit"
            disabled={loading}
            className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"
          >
            {loading ? <Spinner /> : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditParticipantChecklist;
