import dayjs from 'dayjs';
import React from 'react'
// import { MdOutlineDateRange, MdPerson } from 'react-icons/md'
// import { AiOutlineClockCircle } from 'react-icons/ai'

interface Props {
    fullName: string;
    position: string;
    phone: number;
    email: string;
    date: string;
    onAddReport: () => void;
    onDetails: () => void;
}
const ClientRepCard = ({ fullName, position, phone, email, date, onAddReport, onDetails }: Props) => {


    return (
        <div className=" bg-white rounded-md shadow p-2 border-s-4 border-brand overflow-x-hidden">

            <table >

                <tbody >
                    <tr className='text-sm truncate ...'>
                        <td className="whitespace-nowrap px-2 py-1  inline-flex items-center gap-1 font-bold">
                            {/* <MdPerson className='text-gray-400' /> */}
                            FullName:
                        </td>
                        <td className="whitespace-nowrap px-2 py-1 text-gray-700">{fullName}</td>
                    </tr>

                    <tr className='text-sm truncate ...'>
                        <td className="whitespace-nowrap px-2 py-1  inline-flex items-center gap-1 font-bold">
                            {/* <AiOutlineClockCircle className='text-gray-400' /> */}
                            Position:
                        </td>
                        <td className="whitespace-nowrap px-2 py-1 text-gray-700">{position}</td>

                    </tr>

                    <tr className='text-sm truncate ...'>
                        <td className="whitespace-nowrap px-2 py-1  inline-flex items-center gap-1 font-bold">
                            {/* <AiOutlineClockCircle className='text-gray-400' /> */}
                            Phone:
                        </td>
                        <td className="whitespace-nowrap px-2 py-1 text-gray-700">{phone}
                        </td>

                    </tr>
                    <tr className='text-sm truncate ...'>
                        <td className="whitespace-nowrap px-2 py-1  inline-flex items-center gap-1 font-bold">
                            {/* <MdOutlineDateRange className='text-gray-400' /> */}
                            Email:
                        </td>
                        <td className="whitespace-nowrap px-2 py-1 text-gray-700">{email}
                        </td>

                    </tr>
                    <tr className='text-sm truncate ...'>
                        <td className="whitespace-nowrap px-2 py-1  inline-flex items-center gap-1 font-bold">
                            {/* <MdOutlineDateRange className='text-gray-400' /> */}
                            Date Created:
                        </td>
                        <td className="whitespace-nowrap px-2 py-1 text-gray-700">{dayjs(date).format('ddd, MMM DD, h:mm A')}
                        </td>

                    </tr>
                </tbody>
            </table>
            <div className='flex  gap-3 items-center py-2 pl-2'>
                <button className='bg-red-500 px-6 py-2 rounded-md text-white border border-red-400 text-xs font-normal' onClick={onDetails} >Delete</button>
                <button className='bg-primary px-6 py-2 rounded-md text-white text-xs fornt-normal' onClick={onAddReport}>Edit </button>
            </div>

        </div>
    )
}

export default ClientRepCard