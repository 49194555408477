import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

export interface Client {
  profileId: number;
  firstName: string;
  surName: string;
  middleName: string;
  clientId: number;
  careManager: string;
  contactId: string;
  address: string;
  email: string;
  phoneNumber: string;
  gender: string;
  imageUrl: string;
  auditApproved: boolean;
  imageFile: string | null;
  country: string;
  state: string;
  city: string;
  isCompleted: boolean;
  isActive: boolean;
  kinSuburb: string;
  dateOfBirth: string; // You may want to use Date type if it's a date string
  homePhone: string | null;
  workPhone: string | null;
  profession: string | null;
  culturalBackground: string;
  preferredLanguage: string;
  requireInterpreter: boolean;
  indigenousSatatus: string;
  ndisPlan: string;
  ndisPlanNote: string;
  privacyPreferences: string;
  financialArrangement: string;
  ndisNo: string;
  agreementStartDate: string; // You may want to use Date type if it's a date string
  agreementEndDate: string; // You may want to use Date type if it's a date string
  nextOfKin: string | null;
  relationship: string | null;
  kinPostcode: string | null;
  kinAddress: string | null;
  kinCountry: string | null;
  kinCity: string | null;
  kinEmail: string | null;
  suburb: string | null;
  kinState: string | null;
  kinPhoneNumber: string | null;
  signature: string | null,
  signatureFile: string | null,
  fullName: string;
  dateCreated: string; // You may want to use Date type if it's a date string
  userCreated: string;
  dateModified: string; // You may want to use Date type if it's a date string
  userModified: string;
}
export interface FormattedClient {
  label: string;
  value: string;
}

export interface ClientState {
  loading: boolean;
  data: Client[];
  formattedData: FormattedClient[];
  error: string | undefined;
}

const initialState: ClientState = {
  loading: false,
  data: [],
  formattedData: [],
  error: undefined,
};

export const fetchClient = createAsyncThunk(
  "Client/fetchClient",
  async (companyId: string | number) => {
    try {
      const response = await api.fetchClientData(companyId);
      return response;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);

export const filterClient = createAsyncThunk(
  "admin/filterClient",
  async ({ companyId, status }: { companyId: number; status: string }) => {
    try {
      const response = await api.filterClientData(companyId, status);
      return response;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        console.log(error);
      } else {
        console.log(error);
      }
    }
  }
);
export const formatClient = createAsyncThunk(
  "Client/formatClient",
  async (companyId: string | number, { rejectWithValue }) => {
    try {
      const response: Client[] = await api.fetchClientData(companyId);
      const formattedData = response.map((item) => ({
        label: item.fullName,
        value: item.fullName,
      }));
      return formattedData;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue("An unknown error occurred");
    }
  }
);

const clientSlice = createSlice({
  name: "client",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchClient.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchClient.fulfilled,
      (state, action: PayloadAction<Array<Client>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(fetchClient.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(formatClient.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      formatClient.fulfilled,
      (state, action: PayloadAction<FormattedClient[]>) => {
        state.loading = false;
        state.formattedData = action.payload; // Store formatted data
      }
    );
    builder.addCase(formatClient.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });

    builder.addCase(filterClient.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      filterClient.fulfilled,
      (state, action: PayloadAction<Array<Client>>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(filterClient.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {},
});
export const userSelector = (state: RootState) => state.client;
export default clientSlice.reducer;

// Define selectors to access the profile slice state
export const clientData = (state: RootState) => state.client.data;
export const formattedClientData = (state: RootState) =>
  state.client.formattedData;
export const clientLoading = (state: RootState) => state.client.loading;
export const clientError = (state: RootState) => state.client.error;
