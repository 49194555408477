import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
// import dayjs from "dayjs";
// import { useNavigate } from "react-router-dom";
import { Modal } from "rsuite";
import { fetchKnowledgebase, knowledgeBaseData, knowledgeBaseLoading, KnowledgeBaseType } from "../../../../store/slices/knowledgeBaseSlice";
import { AppDispatch, RootState, useAppDispatch } from "../../../../store/store";
import SEO from "../../../../constants/SEO";
import Breadcrumb from "../../../../Components/label/BreadCrumb";
import Spinner from "../../../../Components/Spinner";
import KnowledgeCard from "./components/Card";
import PaginationComponent from "../../../../Components/CustomPagination";
import VideoPopup from "../../../../Components/Media/VideoPopUp";
import axiosInstance from "../../../../store/axiosInstance";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";


const Attendance = () => {
  // const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(0);
  const [showModal, setShowModal] = useState<boolean>(false);


  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();
  const companyId = user?.companyId;
  useEffect(() => {
    if (companyId) {
      dispatch(fetchKnowledgebase());
    }
  }, [dispatch, companyId]);
  const knowledgeBase = useSelector(knowledgeBaseData);
  const loading = useSelector(knowledgeBaseLoading);

  const itemsPerPage = 12;
  const pageCount = Math.ceil(knowledgeBase.length / itemsPerPage);
  const displayData = knowledgeBase.slice(
    pageNumber * itemsPerPage,
    (pageNumber + 1) * itemsPerPage
  );

  const topOfPageRef = useRef<HTMLInputElement>(null);

  const handlePageClick = (selectedItem: { selected: number }) => {
    setPageNumber(selectedItem.selected);
    topOfPageRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const [loading1, setLoading1] = useState<boolean>(false);
  const [loading2, setLoading2] = useState<boolean>(false);
  const [details, setDetails] = useState<KnowledgeBaseType | null>(null)

  const ViewDetails = async (id: number) => {
    // setSaveId(id);
    setShowModal(true)
    setLoading1(true)
    try {
      const { data } = await axiosInstance.get(`/Tickets/get_knowledgebase_details/${id}`);
      setDetails(data.knowledgeBase);
      setLoading1(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
    } finally {
      setLoading1(false);
    }
  };

  const HandleUpvote = async (id: number) => {
    // e.preventDefault();
    setLoading1(true)
    try {
      const { data } = await axiosInstance.get(
        `/Tickets/upvote_knowledgebase?knowledgeId=${id}`
      );
      toast.success(data.message);
      dispatch(fetchKnowledgebase());
      setLoading1(false)
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false)
    }
  };

  const HandleDownvote = async (id: number) => {
    // e.preventDefault();
    setLoading2(true)
    try {
      const { data } = await axiosInstance.get(
        `/Tickets/downvote_knowledgebase?knowledgeId=${id}`
      );
      toast.success(data.message);
      dispatch(fetchKnowledgebase());
      setLoading2(false)
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading2(false)
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const [vidId, setVidId] = useState<string>('');

  const handleVideo = (id: string) => {
    setVidId(id)
    setIsOpen(true)
  }

  return (
    <>
      <SEO title={`KnowledgeBase`} description="KnowledgeBase" name="Promax-Care" />

      <Breadcrumb />
      <VideoPopup videoId={vidId} isOpen={isOpen} description="my description" setIsOpen={setIsOpen} />
      <div
        className="flex justify-between items-center my-10 flex-wrap"
        ref={topOfPageRef}
      >
        <h2 className="font-bold text-2xl text-black w-auto">KnowledgeBase</h2>
      </div>

      {knowledgeBase.length <= 0 && loading && (
        <Spinner color="#071952" />
      )}
      <div className="my-5">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 ">
          {knowledgeBase.length > 0 &&
            displayData.map((item) => (
              <KnowledgeCard
                key={item.knowledgeBaseId}
                subject={item.subject}
                vote={item.vote}
                loading={loading1}
                loading1={loading2}
                dateCreated={item.dateCreated}
                onUpVote={() => HandleUpvote(item.knowledgeBaseId)}
                onDownVote={() => HandleDownvote(item.knowledgeBaseId)}
                onDetails={() => ViewDetails(item.knowledgeBaseId)}
                {...(item.videoUrl && { onAddReport: () => handleVideo(item.videoUrl) })}
              />
            ))}
        </div>

        {pageCount > 1 && (
          <PaginationComponent
            pageCount={pageCount}
            onPageChange={handlePageClick}
          />
        )}
        <br />
      </div>

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">KnowledgeBase Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading1 ? (
            <Spinner />
          ) : (
            <div className="">
              <div >
                <div className='font-bold text-black'>Title: {details?.subject}</div> <br />
                <div dangerouslySetInnerHTML={{ __html: details?.description || "" }} />
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default Attendance;
