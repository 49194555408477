import React, { useEffect, useState } from 'react'
import icon from '../../../images/icon/premium.svg';
import icon2 from '../../../images/icon/enterprise.svg';
import icon3 from '../../../images/icon/proffessional.svg';
import { CompanyDetail, PackageProps } from '../../../types/AuthData';
import { fetchCompany, changeCompanyPackage, fetchPackages } from '../../../services/fetchServices';
import SEO from '../../../constants/SEO';
import PricingControl from '../../../Components/Pricing/PricingControl';
import PricingCard from '../../../Components/Pricing/PricingCard';
import PricingFeatures from '../../../Components/Pricing/PricingFeatures';
import PriceButton from '../../../Components/Pricing/PricingButton';
import Spinner from '../../../Components/Spinner';
import { FaArrowRightLong } from "react-icons/fa6";
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isAxiosError } from 'axios';
import { ZodType, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from 'react-hook-form';
import { Modal } from 'rsuite';
import CustomInput from '../../../Components/Input/CustomInput';
import Breadcrumb from '../../../Components/label/BreadCrumb';

type DataType = {
    userNo: string;
};

const SelectPackages = () => {
    const [activeTab, setActiveTab] = useState('monthly');
    // const navigate = useNavigate();
    const [packages, setPackages] = useState<PackageProps[]>([])
    const [loading, setLoading] = useState<boolean>(true);
    const [loading1, setLoading1] = useState<boolean>(false);
    const [companyOne, setCompanyOne] = useState<CompanyDetail | null>(null);
    const [modal, setModal] = useState<boolean>(false);
    const { uid } = useParams()
    const handleTabChange = (tab: string) => {
        setActiveTab(tab);
    };

    const [saveId, setSaveId] = useState<number>(0);
    const handleSelectUser = async (id: number) => {
        setSaveId(id)
        setModal(true);

    }



    const schema: ZodType<DataType> = z.object({
        userNo: z
            .string()
            .regex(/^\d+$/, "Must be a number")
            .min(1, "User's number is required")
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<DataType>({
        resolver: zodResolver(schema),
    });

    const handleFetch = async () => {
        const data = await fetchPackages();
        setPackages(data)
        setLoading(false)
    }

    const handleCompanyFetch = async () => {
        // setLoading(true);
        if (uid) {
            const data = await fetchCompany(Number(uid));
            setCompanyOne({ ...data });
        }
        // setLoading(false);
    };

    const handleCompanySub = async (data: DataType) => {
        setModal(true);
        setLoading1(true)
        try {
            const res = await changeCompanyPackage(Number(uid), saveId, Number(data.userNo))

            if (res.status === "Success") {
                toast.success(res.status);
                window.location.href = res.checkoutUrl;
                setLoading1(false);
                setModal(false);
            } else {
                toast.error("Redirect link not found");
                setLoading1(false);
                setModal(false);
            }

            // navigate(`/setup`, { replace: true });
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                // console.log(error);
            } else {
                toast.error("An error occurred");
            }
            setLoading1(false);
            setModal(false);
        } finally {
            setLoading1(false);
            setModal(false);
        }

    }

    useEffect(() => {
        handleFetch();
        handleCompanyFetch();
    }, [uid]);


    if (loading) {
        return <div className="fixed  inset-0 z-[9999]  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative w-auto max-w-lg mx-auto">
                <div
                    className="relative z-[999] flex justify-center items-center p-4  duration-500 ease-in-out bg-white
border-0 rounded-lg shadow-lg outline-none focus:outline-none"
                >
                    <Spinner color="#071952" />
                </div>
            </div>

            <div className="fixed inset-0 z-40 bg-gray-300 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
        </div>
    }


    return (
        <section className="dark:bg-gray-900">
            <SEO
                title='Subscription - Promax Care'
                description='Select Package'
                name='Promax-Care'
            />

            <Breadcrumb showNavigation={false} />

            <div className="py-8 mt-5 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-4 bg-white">

                <div className='mx-auto mb-8 lg:mb-8 text-center'>
                    <PricingControl activeTab={activeTab} handleTabChange={handleTabChange} />
                </div>


                <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-4 xl:gap-4 lg:space-y-0 ">

                    {

                        packages?.length > 0 && packages.map((d, i) =>
                            <>
                                <PricingCard
                                    bg={d.packagesId === 3 ? "primary" : ""}
                                    col={d.packagesId === 3 ? "white" : ""}
                                    key={i}
                                    icon={d.packagesId === 4 ? icon3 : d.packagesId === 3 ? icon2 : icon}
                                    title={d.package}
                                    subtext={d.packageDetails}
                                    price={activeTab === 'yearly' ? (d.amount * 12) : d.amount}
                                    duration={activeTab === 'yearly' ? 'year' : 'month'}
                                >
                                    {companyOne?.packages.package === d.package && (
                                        <div className="mb-2 text-base font-bold text-secondary">
                                            Current Plan
                                        </div>
                                    )}

                                    {d.features.split(/,\s*/).map((feature, index) => (
                                        <PricingFeatures
                                            key={index}
                                            text={<span>
                                                {feature}
                                            </span>}

                                        />
                                    ))}

                                    <PriceButton
                                        className={`text-[#4B4EFC] ${d.packagesId === 3 ? "bg-secondary" : "bg-gray-200"}`}
                                        disabled
                                    >
                                        {`${d.packagesId === 2 ? "Start for free" : "Buy Now"}`}
                                    </PriceButton>
                                    <button
                                        disabled={d.packagesId === 2}
                                        onClick={() => handleSelectUser(d.packagesId)}
                                        className={`w-full hover:bg-primary-700 flex gap-2 justify-center items-center flex-wrap
    focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 border border-brand hover:bg-brand transition duration-300 
    transform active:scale-95 ease-in-out
    text-center dark:text-white  dark:focus:ring-primary-900 ${d.packagesId === 3 ? "text-white" : "text-[#4B4EFC] hover:text-white"} `}>
                                        Select <FaArrowRightLong />
                                    </button>
                                </PricingCard>
                            </>


                        )
                    }



                </div>
            </div>

            <Modal
                open={modal}
                size={"sm"}
                onClose={() => setModal(false)}
            >
                <Modal.Header>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(handleCompanySub)}>
                        <CustomInput
                            label="Number of Client's"
                            type="text"
                            {...register("userNo")}
                            error={errors?.userNo?.message}
                        />
                        <div className="mx-auto text-center mt-3">
                            <button
                                type="submit"
                                disabled={loading1}
                                className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary transition duration-300 
transform active:scale-95 ease-in-out"
                            >
                                {loading1 ? <Spinner /> : "Continue"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </section>

    )
}

export default SelectPackages