import React from "react";
import { Client } from "../../../store/slices/clientSlice";
import { ParticipantCheckList } from "../../../types/DataType";
interface Props {
    clientDetail: Client | null
    dateChecked: string;
    participantCheck: ParticipantCheckList;
    handleInputDataChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
}
const EditForm = ({ clientDetail, dateChecked, participantCheck, handleInputDataChange }: Props) => {
    return (
        <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border mt-5 border-gray-300">
            <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">

                <tbody className="divide-y divide-gray-200">
                    <tr className="divide-x-2">
                        <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                            Participant name:
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none" >{clientDetail?.fullName}</td>
                    </tr>

                    <tr className="divide-x-2">
                        <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                            Date checked:
                        </td>
                        <input
                            className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
                            type="date"
                            value={dateChecked}
                        // onChange={(event) => handleInputDislikeChange(index, event)}
                        />
                    </tr>

                    <tr className="divide-x-2">
                        <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                            Staff member name:
                        </td>
                        <input
                            className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
                            type="text"
                            name="staff_Member"
                            value={participantCheck.staff_Member}
                            onChange={handleInputDataChange}
                        // onChange={(event) => handleInputDislikeChange(index, event)}
                        />
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default EditForm;
