import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import DataTable, {
  TableColumn,
  ExpanderComponentProps,
} from "react-data-table-component";
// import { FaFilter } from 'react-icons/fa';
import { BsSearch } from "react-icons/bs";
import ExportDropdown from "../../../Components/menu/ExportDropdown";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import {
  fetchRefferal,
  refferalData,
  refferalLoading,
} from "../../../store/slices/refferalSlice";
import moment from "moment";
import Spinner from "../../../Components/Spinner";
import { toast } from "react-toastify";
import IconButton from "../../../Components/Buttons/IconButton";
import { FaFileCsv, FaFileExcel, FaFilePdf } from "react-icons/fa";
import {
  downloadCSV,
  exportToExcel,
  handlePDFDownload,
} from "../../../services/reusableFunc";
import { Column } from "../../../types/DataType";
import { Button, Modal } from "rsuite";
import { isAxiosError } from "axios";
import axiosInstance from "../../../store/axiosInstance";

interface DataRow {
  aboriginal_Torres: null | string;
  activity_group: null | string;
  aggression_History: null | string;
  allergies: null | string;
  behavior_Plan: null | string;
  clientReferralId: number;
  company: null | string;
  companyId: number;
  contactNumber: string;
  culturally_Linguistically: null | string;
  currentResidence: string;
  dateCreated: string;
  dateModified: string;
  de_escalation: string;
  dob: string;
  email: string;
  emergency_Contact: null | string;
  extra_Support: null | string;
  falls_Risk: string;
  fullName: string;
  gender: string;
  guardianDetails: null | string;
  illicit_Substances: string;
  isAccepted: boolean;
  lastName: string;
  lineItem: null | string;
  name: string;
  ndisEndDate: string;
  ndisGoals: null | string;
  ndisNo: string;
  ndisStartDate: string;
  nominatedCarer: null | string;
  participant_Engagement: null | string;
  participant_Interraction: string;
  participant_Providers: null | string;
  pets: null | string;
  physical_Illnesses: string;
  planManager: null | string;
  potential_Risk: null | string;
  providerTravel: null | string;
  psychiatric_Diagnosis: string;
  pto: string;
  public_Trustee: string;
  quote: null | string;
  referralCompany: null | string;
  referralEmail: string;
  referralFullName: string;
  referralLastName: string;
  referralName: string;
  referralPhone: string;
  referralSignature: null | string;
  referralSignatureFile: null | string;
  self_Harm: string;
  staff_Preference: null | string;
  supportCoordinator: string;
  support_Details: string;
  support_Holiday: string;
  support_Time: null | string;
  transport: null | string;
  triggers: null | string;
  ttpRate: string;
  unwell_Presentation: null | string;
  userCreated: null | string;
  userModified: null | string;
}

const Refferal = () => {
  const columns: TableColumn<DataRow>[] = [
    {
      name: "Full Name",
      selector: (row) => row.fullName,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.isAccepted,
      sortable: true,
      cell: (row) => (
        <span
          className={`px-2 py-1 rounded font-bold ${
            row.isAccepted
              ? "bg-green-500 text-white"
              : "bg-secondary text-white"
          }`}
          style={{ fontSize: "10px" }}
        >
          {row.isAccepted ? "Accepted" : "Pending"}
        </span>
      ),
    },

    {
      name: "Current Residence",
      selector: (row) => row.currentResidence,
      sortable: true,
    },

    {
      name: "Contact Number",
      selector: (row) => row.contactNumber,
      sortable: true,
    },
    {
      name: "NDIS No",
      selector: (row) => row.ndisNo,
      sortable: true,
    },
  ];

  const [saveIds, setSaveIds] = useState<number>(0);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [acceptModal, setAcceptModal] = useState<boolean>(false);
  const [loading1, setLoading1] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();
  const companyId = user?.companyId;
  useEffect(() => {
    if (companyId) {
      dispatch(fetchRefferal(companyId));
    }
  }, [dispatch, companyId]);
  const refferal = useSelector(refferalData);
  const loading = useSelector(refferalLoading);

  interface Props extends ExpanderComponentProps<DataRow> {
    // currently, props that extend ExpanderComponentProps must be set to optional.
    someTitleProp?: string;
  }
  const handleDetails = (e: number) => {
    setLoading1(true);
    setTimeout(() => {
      const url = `/pdf/refferal-pdf/${e}`;
      window.open(url, "_blank");
      setLoading1(false);
    }, 2000);
  };

  const delUser = (id: number) => {
    setSaveIds(id);
    setDeleteModal(true);
  };

  const handleAccept = (id: number) => {
    setSaveIds(id);
    setAcceptModal(true);
  };

  const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
    return (
      <div className="p-2 flex flex-col gap-2 text-xs">
        <div>
          <span className="fw-bold">NDIS Start Date: </span>{" "}
          {moment(data.ndisStartDate).format("ll")}
        </div>
        <div>
          <span className="fw-bold">NDIS End Date: </span>{" "}
          {moment(data.ndisEndDate).format("ll")}
        </div>
        <div>
          <span className="fw-bold">Date Modified: </span>
          {moment(data.dateModified).format("lll")}
        </div>
        <div className="flex gap-2">
          <a
            href={`https://www.promaxcare.com.au/Account/ClientReferral/${data.clientReferralId}?page=1&companyId=${companyId}`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn text-secondary font-bold"
            style={{ fontSize: "12px" }}
          >
            Edit Form
          </a>{" "}
          |
          <button
            className="text-secondary font-bold"
            style={{ fontSize: "12px" }}
            onClick={() => handleDetails(data.clientReferralId)}
          >
            {loading1 ? <Spinner /> : "View Form"}
          </button>{" "}
          |
          <button
            onClick={() => delUser(data.clientReferralId)}
            className="btn text-red-500 font-bold"
            style={{ fontSize: "12px" }}
          >
            Delete
          </button>{" "}
          |
          {!data.isAccepted && (
            <button
              onClick={() => handleAccept(data.clientReferralId)}
              className="btn text-primary font-bold"
              style={{ fontSize: "12px" }}
            >
              Accept
            </button>
          )}
        </div>
      </div>
    );
  };

  const handleAcceptSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading1(true);
    try {
      const { data } = await axiosInstance.get(
        `/ClientReferrals/accept_client_referral?id=${saveIds}&userId=${user?.uid}`
      );
      toast.success(data.message);
      setAcceptModal(false);
      companyId && dispatch(fetchRefferal(companyId));
      setLoading1(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
      setAcceptModal(false);
    } finally {
      setLoading1(false);
    }
  };
  const handleDelete = async (e: FormEvent) => {
    e.preventDefault();
    setLoading1(true);
    try {
      const { data } = await axiosInstance.post(
        `/ClientReferrals/delete/${saveIds}`
      );
      toast.success(data.message);
      setDeleteModal(false);
      companyId && dispatch(fetchRefferal(companyId));
      setLoading1(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // console.log(error);;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
      setDeleteModal(false);
    } finally {
      setLoading1(false);
    }
  };

  const [searchText, setSearchText] = useState<string>("");

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredData = refferal?.filter(
    (item) =>
      item?.fullName?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.email?.toLowerCase().includes(searchText.toLowerCase())
  );

  const [textToCopy] = useState<string>(
    `https://www.promaxcare.com.au/Account/ClientReferral?companyId=${user?.companyId}`
  );
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success("Referral Link copied to clipboard");
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };

  return (
    <>
      <SEO title="Refferals" description="Refferal" name="Promax-Care" />
      <Breadcrumb />
      <div className="mt-10">
        {refferal.length <= 0 && loading && <Spinner color="#071952" />}
        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
          <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">
            <h2 className="font-bold text-xl text-black w-auto">Refferals</h2>

            <div className="relative w-full lg:col-span-2">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <BsSearch />
              </div>
              <input
                type="text"
                id="simple-search"
                onChange={handleSearch}
                className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Search By Full Name or Email"
              />
            </div>
          </div>
          <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
            <div className="flex items-center space-x-3 w-full md:w-auto">
              {/* <button id="actionsDropdownButton" className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                                <FaFilter />
                                Filter
                            </button> */}
              <ExportDropdown>
                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <IconButton
                    icon={<FaFilePdf className="text-red-500" />}
                    title="Export as PDF"
                    onClick={() =>
                      handlePDFDownload(
                        refferal,
                        columns as unknown as Column<DataRow>[],
                        "Refferal.pdf"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileExcel className="text-green-600" />}
                    title="Export as Excel Sheet"
                    onClick={() =>
                      exportToExcel(
                        refferal,
                        columns as unknown as Column<DataRow>[],
                        "Refferal"
                      )
                    }
                  />
                  <IconButton
                    icon={<FaFileCsv className="text-green-500" />}
                    title="Export as CSV"
                    onClick={() =>
                      downloadCSV({ data: refferal, filename: "Refferal.csv" })
                    }
                  />
                </div>
              </ExportDropdown>
            </div>
            <button
              type="button"
              onClick={copyToClipboard}
              className="px-4 py-2 bg-primary text-white  disabled:bg-slate-400
                            transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm 
                           "
            >
              Copy Refferal Link
            </button>
          </div>
        </div>

        <DataTable
          columns={columns}
          data={filteredData}
          expandableRows
          expandableRowsComponent={ExpandableRowComponent}
          pagination
          paginationTotalRows={refferal?.length}
          // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}
        />
      </div>

      <Modal
        open={acceptModal}
        onClose={() => setAcceptModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Accept Refferal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="mb-4 font-bold">
              Are you sure you want to Accept this?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <Button
                onClick={handleAcceptSubmit}
                className="bg-primary text-white hover:bg-primary hover:text-white"
              >
                {loading1 ? <Spinner /> : "Yes, I'm sure"}
              </Button>
              <Button
                onClick={() => setAcceptModal(false)}
                className="bg-gray-500 text-white"
              >
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Delete Refferal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="mb-4 font-bold">
              Are you sure you want to delete this?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <Button
                onClick={handleDelete}
                className="bg-red-500 text-white hover:bg-red-700 hover:text-white"
              >
                {loading1 ? <Spinner /> : "Yes, I'm sure"}
              </Button>
              <Button
                onClick={() => setDeleteModal(false)}
                className="bg-gray-500 text-white"
              >
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default Refferal;
