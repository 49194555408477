import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import { Area, Point } from "react-easy-crop/types";
import { Modal } from "rsuite";
import getCroppedImg from "../../Pages/utils/cropImage";
import CustomInput from "../Input/CustomInput";
import Spinner from "../Spinner";

interface CropUploaderProps {
  onCroppedImage: (croppedImage: Blob) => void;
  loading: boolean;
}

const CropUploader: React.FC<CropUploaderProps> = ({
  onCroppedImage,
  loading,
}) => {
  const [isFileModalOpen, setFileModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [croppedImage, setCroppedImage] = useState<Blob | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setSelectedFile(reader.result as string);
      };
    }
  };

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const handleSave = async () => {
    if (selectedFile && croppedAreaPixels) {
      const croppedImageBlob = await getCroppedImg(
        selectedFile,
        croppedAreaPixels
      );
      if (croppedImageBlob) {
        setCroppedImage(croppedImageBlob);
        onCroppedImage(croppedImageBlob);
      }
    }
  };

  const openModal = () => {
    setSelectedFile(null);
    setCroppedImage(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setFileModalOpen(true);
  };

  return (
    <div>
      <button
        onClick={openModal}
        className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
        type="button"
      >
        Upload Photo
      </button>

      <Modal
        open={isFileModalOpen}
        onClose={() => setFileModalOpen(false)}
        backdrop="static"
        autoFocus
        size="xs"
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && <Spinner color="#000" />}
          <p className="text-xs font-bold">Please upload an image file (JPG, PNG) with a maximum size of 2MB</p>
          {!selectedFile && (
            <CustomInput
              label=""
              type="file"
              accept=".png,.jpg,.jpeg"
              onChange={handleFileChange}
              required
            />
          )}
          {selectedFile && !croppedImage && (
            <>
              <div className="controls">
                <input
                  type="range"
                  min="1"
                  max="3"
                  step="0.1"
                  value={zoom}
                  onChange={(e) => setZoom(Number(e.target.value))}
                  className="slider bg-primary accent-primary"
                />
              </div>
              <div className="crop-container">
                <Cropper
                  image={selectedFile}
                  crop={crop}
                  zoom={zoom}
                  aspect={4 / 3}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
            </>
          )}
          {croppedImage && (
            <div className="cropped-image-preview">
              <img
                src={URL.createObjectURL(croppedImage)}
                alt="Cropped Preview"
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!croppedImage ? (
            <button
              type="button"
              onClick={handleSave}
              className="text-white bg-primary hover:bg-primary disabled:bg-gray-500 font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2"
            >
              {loading ? <Spinner /> : "Save"}
            </button>
          ) : (
            <button
              type="button"
              onClick={() => setFileModalOpen(false)}
              className="text-black bg-secondary hover:bg-secondary disabled:bg-gray-500 font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2"
            >
              Close
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CropUploader;
