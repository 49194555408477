import React from 'react';
import { Modal, Button, Loader, ModalProps } from 'rsuite';

interface ConfirmModalProps extends ModalProps {
    onConfirm: () => void;
    title: string;
    content: JSX.Element;
    isLoading: boolean

}


export const ConfirmModal = (props: ConfirmModalProps) => {
    const { title, content, isLoading, onClose, open, onConfirm, ...rest } = props;
    return (
        <Modal open={open} onClose={onClose} size="xs" {...rest}>
            <Modal.Header>
                <Modal.Title className='font-bold'>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {content}
                <div className="flex justify-center items-center space-x-4">
                    <Button onClick={onClose} className='bg-gray-500 text-white'>
                        Cancel
                    </Button>
                    <Button onClick={onConfirm} className='bg-red-500 text-white hover:bg-red-700 hover:text-white' disabled={isLoading}>
                        {isLoading ? <Loader size="sm" /> : "Yes, I'm sure"}
                    </Button>

                </div>
            </Modal.Body>

        </Modal>
    );
};
