// import React, { ReactNode } from 'react';
// import { ErrorBoundary } from 'react-error-boundary';
// import ErrorComponent from './ErrorComponent';

// interface Props {
//   children: ReactNode;
// }

// const ErrorBoundaryWrapper: React.FC<Props> = ({ children }) => {
//   return (
//     <ErrorBoundary
//       FallbackComponent={ErrorComponent}
//       onError={() => console.log('Error happened!')}
//     >
//       {children}
//     </ErrorBoundary>
//   );
// };

// export default ErrorBoundaryWrapper;

import React, { Component, ErrorInfo } from "react";
import ErrorComponent from "./ErrorComponent";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("ErrorBoundary caught an error: ", error, errorInfo);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorComponent />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

