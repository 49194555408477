import axios, { AxiosError, AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

// Define the structure of your API response data
interface ApiResponse<T> {
  status: number;
  message: string;
  data: T;
  // Add other fields if needed
}

// Define the structure of your specific response data
interface DataStructure {
  // Define the structure based on your API response
  // For example:
  id: number;
  name: string;
  // Add other fields as per your response data
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use((config) => {
  const authToken = Cookies.get("authToken");
  if (authToken) {
    config.headers["Authorization"] = `Bearer ${authToken}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response: AxiosResponse<ApiResponse<DataStructure>>) => response,
  (error: AxiosError) => {
    const role = Cookies.get("role");
    const { response } = error;
    if (response) {
      switch (response.status) {
        case 401:
          localStorage.clear();
          localStorage.removeItem("persist:root");
          localStorage.removeItem("auth");
          // toast.error("Session Time Out!!");
          localStorage.removeItem("staffProfile");
          localStorage.removeItem("adminProfile");
          localStorage.removeItem("clientProfile");
          if (role === "CompanyAdmin") {
            localStorage.setItem("redirectPath", window.location.pathname);
          }
          if (role === "Staff") {
            localStorage.setItem("staffRedirectPath", window.location.pathname);
          }
          Cookies.remove("authToken");
          window.location.replace("/");
          break;
        case 403:
          // Perform actions to handle the 403 error
          // window.location.href = '/forbidden';
          break;
        case 404:
          // Perform actions to handle the 404 error
          // window.location.href = '/not-found';
          break;
        case 500:
          toast.error("Ooops! An error occured ");
          // Perform actions to handle the 500 error
          // window.location.href = '/error';
          break;
        default:
          // Perform actions for other errors
          // window.location.href = '/error-page';
          break;
      }
    } else {
      console.log(error);
      // window.location.href = '/network-error';
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
