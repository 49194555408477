import React, { useEffect, useState } from "react";
import { CompanyDetail, PackageProps } from "../../../types/AuthData";
import {
  fetchCompany,
  changeCompanyPackage,
  fetchPackages,
  upClient,
} from "../../../services/fetchServices";
import SEO from "../../../constants/SEO";
import Spinner from "../../../Components/Spinner";
import icon from "../../../images/icon/premium.svg";
import icon2 from "../../../images/icon/enterprise.svg";
import icon3 from "../../../images/icon/proffessional.svg";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import PricingFeatures from "../../../Components/Pricing/PricingFeatures";
import PricingCard from "../../../Components/Pricing/PricingCard";
import PriceButton from "../../../Components/Pricing/PricingButton";
import { FaArrowRightLong } from "react-icons/fa6";
import { ZodType, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button, Modal } from "rsuite";
import CustomInput from "../../../Components/Input/CustomInput";
import { AppDispatch, useAppDispatch } from "../../../store/store";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import PackageExpiredDate from "../../../Components/PackageStatus/ExpiredDate";

type DataType = {
  userNo: string;
};
const ChangePlan = () => {
  const [activeTab] = useState("monthly");
  const [packages, setPackages] = useState<PackageProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loading1, setLoading1] = useState<boolean>(false);
  const [companyOne, setCompanyOne] = useState<CompanyDetail | null>(null);
  const { uid } = useParams();
  const dispatch: AppDispatch = useAppDispatch();

  const handleFetch = async () => {
    const data = await fetchPackages();
    setPackages(data);
    setLoading(false);
  };

  const handleCompanyFetch = async () => {
    if (uid) {
      const data = await fetchCompany(Number(uid));
      setCompanyOne({ ...data });
    }
  };

  useEffect(() => {
    handleFetch();
    handleCompanyFetch();
  }, [dispatch]);

  const [modal, setModal] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const changeSub = () => {
    navigate(`/app/setup/selct-new-package/${uid}`);
  };

  const paymentHistory = () => {
    navigate(`/app/setup/payment-history`);
  };

  const [saveId, setSaveId] = useState<number>(0);
  const handleSelectUser = (id: number) => {
    setSaveId(id);
    setModal(true);
  };

  const Confirm = async (id: number) => {
    setSaveId(id);
    setConfirmModal(true);
  };

  const reNewSubscription = async () => {
    try {
      setLoading1(true);

      const res = await changeCompanyPackage(
        Number(uid),
        saveId,
        Number(companyOne?.noOfUsers)
      );

      if (res.status === "Success") {
        toast.success(res.status);
        if (res.checkoutUrl) {
          window.location.href = res.checkoutUrl;
          setLoading1(false);
        }
      } else {
        toast.error("Redirect link not found");
        setLoading1(false);
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        // console.log(error);
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
    } finally {
      setLoading1(false);
    }
  };

  const handleCompanySub = async (data: DataType) => {
    setModal(true);

    try {
      setLoading(true);

      const res = await upClient(Number(uid), saveId, Number(data.userNo));

      if (res.status === "Success") {
        toast.success(res.status);
        if (res.checkoutUrl) {
          window.location.href = res.checkoutUrl;
          setLoading(false);
          setModal(false);
        }
      } else {
        toast.error("Redirect link not found");
        setLoading(false);
        setModal(false);
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        // console.log(error);
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
      setModal(false);
    } finally {
      setLoading(false);
      setModal(false);
    }
  };

  const schema: ZodType<DataType> = z.object({
    userNo: z
      .string()
      .regex(/^\d+$/, "Must be a number")
      .min(1, "User's number is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DataType>({
    resolver: zodResolver(schema),
  });

  if (loading) {
    return (
      <div className="fixed inset-0 z-[9999] flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="relative w-auto max-w-lg mx-auto">
          <div className="relative z-[999] flex justify-center items-center p-4 duration-500 ease-in-out bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
            <Spinner color="#071952" />
          </div>
        </div>
        <div className="fixed inset-0 z-40 bg-gray-300 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
      </div>
    );
  }

  const currentPackage = packages.find(
    (pkg) => pkg.package === companyOne?.packages.package
  );

  return (
    <section className="bg-gray-100 dark:bg-gray-900">
      <SEO
        title="Subscription - Promax Care"
        description="Manage Subscription"
        name="Promax-Care"
      />
      <Breadcrumb showNavigation={false} />

      <div className="flex flex-col md:flex-row mt-5 items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
        <div className="w-full grid grid-cols-1 gap-2 lg:grid-cols-3 lg:gap-2 items-center ">
          <h2 className="font-bold text-xl text-black w-auto">
            Manage Subscription
          </h2>
        </div>
        <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
          {currentPackage?.packagesId && (
            <div>
              <button
                onClick={() => Confirm(currentPackage.packagesId)}
                disabled={currentPackage?.packagesId === 2}
                className={`hover:bg-primary-700 flex gap-2 justify-center items-center flex-wrap 
        focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 border border-brand
        hover:bg-brand text-center dark:text-white bg-brand
        dark:focus:ring-primary-900 text-white transition 
        duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 `}
              >
                Renew Subscription
              </button>
            </div>
          )}

          <button
            type="button"
            onClick={changeSub}
            className={`hover:bg-primary-700 flex gap-2 justify-center items-center flex-wrap 
focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 border border-brand
hover:bg-brand text-center dark:text-white bg-brand
dark:focus:ring-primary-900 text-white transition duration-300 
transform active:scale-95 ease-in-out hover:bg-primary/80 `}
          >
            Change Subscription
          </button>

          <div className="flex items-center space-x-2 w-full md:w-auto">
            <button
              type="button"
              onClick={paymentHistory}
              className={`hover:bg-primary-700 flex gap-2 justify-center items-center flex-wrap 
focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 border border-brand
hover:bg-brand text-center dark:text-white bg-brand
dark:focus:ring-primary-900 text-white transition duration-300 
transform active:scale-95 ease-in-out hover:bg-primary/80 `}
            >
              Payment History
            </button>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-1 lg:gap-8">
        <div className="h-32 rounded-lg">
          {currentPackage && (
            <PricingCard
              bg={currentPackage.packagesId === 3 ? "primary" : ""}
              col={currentPackage.packagesId === 3 ? "white" : ""}
              icon={
                currentPackage.packagesId === 4
                  ? icon3
                  : currentPackage.packagesId === 3
                  ? icon2
                  : icon
              }
              title={currentPackage.package}
              key={currentPackage.packagesId}
              subtext={currentPackage.packageDetails}
              price={
                activeTab === "yearly"
                  ? currentPackage.amount * 12
                  : currentPackage.amount
              }
              duration={activeTab === "yearly" ? "year" : "month"}
            >
              <div className="mb-2 text-base font-bold text-secondary">
                Current Plan
              </div>
              <div className="font-semibold">
                <p>
                  <PackageExpiredDate />
                </p>
              </div>
              {currentPackage.features.split(/,\s*/).map((feature, index) => (
                <PricingFeatures key={index} text={<span>{feature}</span>} />
              ))}
              {/* <div className='w-fit md:block lg:block font-bold'>
        <span> <PackageStatus /></span>
    </div> */}
              <PriceButton
                className={`hidden text-[#4B4EFC] ${
                  currentPackage.packagesId === 3
                    ? "bg-secondary"
                    : "bg-gray-200"
                }`}
                disabled
              >
                {`${
                  currentPackage.packagesId === 2 ? "Start for free" : "Buy Now"
                }`}
              </PriceButton>
              <div className="flex justify-center items-center">
                <button
                  disabled={currentPackage.packagesId === 2}
                  onClick={() => handleSelectUser(currentPackage.packagesId)}
                  className={`w-60 hover:bg-primary-700 flex gap-2 justify-center items-center flex-wrap focus:ring-4 
                                focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 border border-brand bg-brand text-center
                                text-white transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80
                                 dark:text-white  dark:focus:ring-primary-900 ${
                                   currentPackage.packagesId === 3
                                     ? "text-white"
                                     : "text-[#4B4EFC] hover:text-white"
                                 } `}
                >
                  Update Client <FaArrowRightLong />
                </button>
              </div>
            </PricingCard>
          )}
        </div>
      </div>

      <Modal open={modal} size={"sm"} onClose={() => setModal(false)}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(handleCompanySub)}>
            <CustomInput
              label="Number of Client's"
              type="text"
              {...register("userNo")}
              error={errors?.userNo?.message}
            />
            <div className="mx-auto text-center mt-3">
              <button
                type="submit"
                disabled={loading}
                className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary transition duration-300 
transform active:scale-95 ease-in-out"
              >
                {loading ? <Spinner /> : "Continue"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        open={confirmModal}
        onClose={() => setConfirmModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="mb-4 font-bold">
              Your subscription is about to renew. Do you wish to proceed?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <Button
                onClick={() => setConfirmModal(false)}
                className="bg-gray-500 text-white"
              >
                No, cancel
              </Button>
              <Button
                onClick={reNewSubscription}
                className="bg-primary text-white hover:bg-primary hover:text-white"
              >
                {loading1 ? <Spinner /> : "Yes, I'm sure"}
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </section>
  );
};

export default ChangePlan;
