import React from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';

type MainLinkProps = {
    icon: JSX.Element;
    title: string;
    open: boolean;
    link: string;
    subOpen?: boolean;
    handleClick?: () => void;
};

const MainLinkDropDown = ({ icon, title, open, link, subOpen, handleClick }: MainLinkProps) => {
    const location = useLocation();
    const { pathname } = location;
    return (
        <li>
            <Link
                onClick={(e) => {
                    e.preventDefault();
                    handleClick?.();
                }}
                to={link}

                title={title}
                className={`text-white text-sm flex items-center gap-x-4 cursor-pointer p-2 
            hover:bg-lightbg rounded-md hover:no-underline hover:text-gray-400 focus:no-underline focus:text-gray-400 focus:text-bold
            ${pathname && pathname === link && 'bg-lightbg text-brand font-bold'}
            `}

            >
                <span className='block float-left focus:text-white hover:text-gray-400'>
                    {icon}
                </span>
                <span className={`text-xs flex-1 duration-200  ${!open && 'hidden'}
                
                ${pathname && pathname === link && ' text-brand font-bold'}
                `}

                >
                    {title}
                </span>
                {subOpen !== undefined && (
                    <BsChevronDown

                        className={`${subOpen ? 'rotate-180 duration-300' : 'duration-300 '} ${!open && 'hidden'}  `}
                    />
                )}
            </Link>
        </li>
    );
};

export default MainLinkDropDown;
