import React from 'react';
import { FormDataType } from '..';

interface Props {
    formData: FormDataType
    handleInputFormChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;

}

const Decision: React.FC<Props> = ({ formData, handleInputFormChange }) => {

    return (
        <div>
            <table className="w-full mb-6">
                <tbody>
                    <tr>
                        <td className="border px-4 py-2 font-bold">Independent</td>
                        <td className="border px-4 py-2">
                            <div className="flex items-center">
                                <label className="mr-4">
                                    <input
                                        type="radio"
                                        name="independent"
                                        className="mr-2"
                                        value="true"
                                        checked={formData.independent === 'true'}
                                        onChange={handleInputFormChange}
                                    /> Yes
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="independent"
                                        className="mr-2"
                                        value="false"
                                        checked={formData.independent === 'false'}
                                        onChange={handleInputFormChange}
                                    /> No
                                </label>
                            </div>
                            {formData.independent === 'true' && (
                                <textarea
                                    className="w-full p-2 border border-gray-300 rounded mt-2"
                                    placeholder="If yes, provide details"
                                    name="independentDetails"
                                    value={formData.independentDetails}
                                    onChange={handleInputFormChange}
                                />
                            )}
                        </td>
                    </tr>

                    <tr>
                        <td className="border px-4 py-2 font-bold">Supported</td>
                        <td className="border px-4 py-2">
                            <div className="flex items-center">
                                <label className="mr-4">
                                    <input
                                        type="radio"
                                        name="supported"
                                        className="mr-2"
                                        value="true"
                                        checked={formData.supported === 'true'}
                                        onChange={handleInputFormChange}
                                    /> Yes
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="supported"
                                        className="mr-2"
                                        value="false"
                                        checked={formData.supported === 'false'}
                                        onChange={handleInputFormChange}
                                    /> No
                                </label>
                            </div>
                            {formData.supported === 'true' && (
                                <textarea
                                    className="w-full p-2 border border-gray-300 rounded mt-2"
                                    placeholder="If yes, provide details"
                                    name="supportedDetails"
                                    value={formData.supportedDetails}
                                    onChange={handleInputFormChange}
                                />
                            )}
                        </td>
                    </tr>

                    <tr>
                        <td className="border px-4 py-2 font-bold">Guardianship</td>
                        <td className="border px-4 py-2">
                            <div className="flex items-center">
                                <label className="mr-4">
                                    <input
                                        type="radio"
                                        name="guardianship"
                                        className="mr-2"
                                        value="true"
                                        checked={formData.guardianship === 'true'}
                                        onChange={handleInputFormChange}
                                    /> Yes
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="guardianship"
                                        className="mr-2"
                                        value="false"
                                        checked={formData.guardianship === 'false'}
                                        onChange={handleInputFormChange}
                                    /> No
                                </label>
                            </div>
                            {formData.guardianship === 'true' && (
                                <textarea
                                    className="w-full p-2 border border-gray-300 rounded mt-2"
                                    placeholder="If yes, provide details"
                                    name="guardianshipDetails"
                                    value={formData.guardianshipDetails}
                                    onChange={handleInputFormChange}
                                />
                            )}
                        </td>
                    </tr>

                    <tr>
                        <td className="border px-4 py-2 font-bold">Financial</td>
                        <td className="border px-4 py-2">
                            <div className="flex flex-wrap">
                                <label className="mr-4">
                                    <input
                                        type="radio"
                                        name="financial"
                                        className="mr-2"
                                        value="Self-managed"
                                        checked={formData.financial === 'Self-managed'}
                                        onChange={handleInputFormChange}
                                    /> Self-managed
                                </label>
                                <label className="mr-4">
                                    <input
                                        type="radio"
                                        name="financial"
                                        className="mr-2"
                                        value="Financial trustee"
                                        checked={formData.financial === 'Financial trustee'}
                                        onChange={handleInputFormChange}
                                    /> Financial trustee (if ticked provide details)
                                </label>
                                <label className="mr-4">
                                    <input
                                        type="radio"
                                        name="financial"
                                        className="mr-2"
                                        value="Support from family"
                                        checked={formData.financial === 'Support from family'}
                                        onChange={handleInputFormChange}
                                    /> Support from family member or carer (if ticked provide details)
                                </label>
                            </div>
                            {(formData.financial === 'Financial trustee' || formData.financial === 'Support from family') && (
                                <textarea
                                    className="w-full p-2 border border-gray-300 rounded mt-2"
                                    placeholder="If yes, provide details"
                                    name="financialDetails"
                                    value={formData.financialDetails}
                                    onChange={handleInputFormChange}
                                />
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Decision;
