import React from 'react';
import ClientTabForm from "../../components/ClientTabForm";
import { FormData } from '../BehaviorSupport';

interface Props {
    formData: FormData;
    handleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const BehaviorSupportCom: React.FC<Props> = ({ formData, handleChange }) => {


    return (
        <div className='mt-10'>
            <h2 className='font-bold text-xl text-black w-auto'>Behaviour Support Needs</h2>
            <form
            // onSubmit={handleSubmit}
            >
                <div className="mt-5">
                    <ClientTabForm title='Behaviour Support Needs'>
                        <div className='grid grid-cols-1 gap-16 lg:grid-cols-2'>
                            {/* Text areas and their labels */}
                            {[
                                { label: "Describe how you would react if someone you lived with did something you found disruptive or upsetting", id: "additionalSupportDetail" },
                                { label: "Do you have any behaviours of concern that require specific support? If so, please provide detail", id: "transportationDetails" },
                                { label: "Do you do anything that others might find disruptive", id: "transportType" },
                                { label: "Behaviour Plan", id: "activitiesDetail" },
                                { label: "Risk Assessment", id: "accessAssistanceDetail" },
                            ].map(field => (
                                <div key={field.id}>
                                    <label htmlFor={field.id} className="font-bold">{field.label}</label>
                                    <textarea
                                        id={field.id}
                                        name={field.id}
                                        rows={3}
                                        autoComplete="off"
                                        value={formData[field.id as keyof FormData]}
                                        onChange={handleChange}
                                        className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                    />
                                </div>
                            ))}
                        </div>
                    </ClientTabForm>
                </div>
            </form>
        </div>
    );
};

export default BehaviorSupportCom;
