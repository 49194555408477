// import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { fetchCompany } from "../../services/fetchServices";
// import { RootState } from "../../store/store";
// import { CompanyDetail } from "../../types/AuthData";
// import Spinner from "../Spinner";

// const PackageStatus = () => {
//   const user = useSelector((state: RootState) => state?.auth?.user);
//   const [company, setCompany] = useState<CompanyDetail | null>(null);
//   const [loading, setLoading] = useState<boolean>(true);

//   useEffect(() => {
//     const handleCompanyFetch = async () => {
//       setLoading(true);
//       if (user?.companyId) {
//         const data = await fetchCompany(user?.companyId);
//         setCompany(data);
//       }
//       setLoading(false);
//     };
//     handleCompanyFetch();
//   }, [user?.companyId]);

//   if (loading) {
//     return <Spinner />;
//   }

//   const companyOneDate = company?.expirationDate
//     ? new Date(company.expirationDate)
//     : null;

//   // Get the current date in Australia (Sydney timezone)
//   const currentDateInAustralia = new Date(
//     new Date().toLocaleString("en-US", { timeZone: "Australia/Sydney" })
//   );

//   // Zero out the time components
//   const resetTime = (date: Date) => {
//     date.setHours(0, 0, 0, 0);
//     return date;
//   };

//   const normalizedCurrentDate = resetTime(currentDateInAustralia);
//   const normalizedCompanyDate = companyOneDate
//     ? resetTime(new Date(companyOneDate))
//     : null;

//   const timeDifference = normalizedCompanyDate
//     ? normalizedCompanyDate.getTime() - normalizedCurrentDate.getTime()
//     : 0;

//   const daysDifference = Math.round(timeDifference / (1000 * 3600 * 24));
//   // Define display text and color based on days left
//   let displayText;
//   let textColor = "black";

//   if (daysDifference > 0) {
//     displayText = `${company?.packages.package}: ${daysDifference} days left`;
//   } else {
//     displayText = `${company?.packages.package}: Expired`;
//   }

//   // Change color based on days left
//   if (daysDifference <= 0) {
//     textColor = "red"; // Expired or 0 days or less
//   } else if (daysDifference <= 3) {
//     textColor = "red"; // 3 days or less
//   } else if (daysDifference <= 7) {
//     textColor = "yellow"; // 7 days or less
//   }

//   return (
//     <div>
//       <p style={{ color: textColor }}>{displayText}</p>
//     </div>
//   );
// };

// export default PackageStatus;

import React from "react";
import Spinner from "../Spinner";
import useFetchExpiredDate from "./components/FetchExpiredDate";

const PackageStatus = () => {
  const { daysDifference, companyPackage, loading } = useFetchExpiredDate();

  if (loading) {
    return <Spinner />;
  }

  let displayText = companyPackage ? `${companyPackage}: ` : "";
  let textColor = "black";

  if (daysDifference !== null) {
    if (daysDifference <= 0) {
      displayText += "Expired";
      textColor = "red";
    } else {
      displayText += `${daysDifference} days left`;
      if (daysDifference <= 3) {
        textColor = "red";
      } else if (daysDifference <= 7) {
        textColor = "yellow";
      }
    }
  } else {
    displayText += "No expiration date available";
  }

  return <p style={{ color: textColor }}>{displayText}</p>;
};

export default PackageStatus;
